import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Bookmark, BookmarkType } from '../_models';
import { map } from 'rxjs/operators';
import { environment } from '@purplefront/shared';

@Injectable()
export class BookmarksApi {
  private apiUrl: string = environment.purpleApi;

  constructor(private http: HttpClient) {}
  findBookmarksBySlug(bookmarkType: BookmarkType): Observable<Bookmark[]> {
    const allowedBookmarkType = ['dashboard', 'search', 'radar', 'reader', 'relax', 'agenda', 'article'];
    let _url = `${this.apiUrl}/bookmarks`;
    if (allowedBookmarkType.includes(bookmarkType)) {
      _url += `?type=${bookmarkType}`;
    }

    return this.http.get<Bookmark[]>(_url).pipe(map((res) => res['data']));
  }

  findAllBookmarks(): Observable<Bookmark[]> {
    return this.http.get<Bookmark[]>(`${this.apiUrl}/bookmarks/`).pipe(map((res) => res['data']));
  }

  saveBookmark(body: Partial<Bookmark>) {
    return this.http.post(`${this.apiUrl}/bookmarks/`, body).pipe(map((res) => res['data'][0]));
  }

  removeBookmark(payload) {
    return this.http.delete(`${this.apiUrl}/bookmarks/${payload}`);
  }

  removeAllBookmark() {
    return this.http.delete(`${this.apiUrl}/bookmarks`);
  }
}
