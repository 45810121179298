import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PlaylistContainerComponent } from './containers/playlist-container/playlist-container.component';
import { PlaylistUiModule } from '@purplefront/playlist/ui';
import { PlaylistDataAccessModule } from '@purplefront/playlist/data-access';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([{ path: '', pathMatch: 'full', component: PlaylistContainerComponent }]),
    PlaylistUiModule,
    PlaylistDataAccessModule
  ],
  declarations: [PlaylistContainerComponent],
  exports: [PlaylistContainerComponent]
})
export class PlaylistFeatureShellModule {}
