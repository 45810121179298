import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';

import { TranslateService } from '@ngx-translate/core';
import { ArticleParams, Langs, Page, UtilsService } from '@purplefront/shared';
import {
  goToAddFeedPage,
  goToBookmarksPage,
  goToOrderOnDemand,
  goToPreferencesPage,
  goToPublishPage,
  goToFeedPage,
  redirectToLogin,
  redirectToHome,
  goToFeedList,
  goToFeed,
  goToSearchPage,
  goToFolderPage,
  goToDashboardPage,
  goToWorkspace,
  goToFlashBriefing,
  goToEditor,
  goToMyAudioContents,
  goToCalendar,
  goToDiscover,
  goToArticlePage
} from '../_store/actions';
import { AppRouterState } from '../_store/reducers';
import { InterfaceLanguageService, PreferencesState, selectContentLang } from '@purplefront/preferences/data-access';
import { combineLatest } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { CatalogState } from '../../../../../catalog/data-access/src/lib/_store/reducers/catalog.reducers';
import { userFeeds } from '../../../../../catalog/data-access/src/lib/_store/selectors/catalog.selectors';
import { selectTag, selectSearchString } from '../_store/selectors';

@Injectable()
export class AppRouterFacadeService {
  private _fromUrl: string;

  constructor(
    private _appRouterStore: Store<AppRouterState>,
    private _router: Router,
    private _route: ActivatedRoute,
    private _translate: TranslateService,
    private _interfaceLangService: InterfaceLanguageService,
    private _prefStore: Store<PreferencesState>,
    private _catalogStore: Store<CatalogState>,
    private _utilService: UtilsService
  ) {}

  public redirectToLogin(fromUrl: string = ''): void {
    this._appRouterStore.dispatch(
      redirectToLogin({
        payload: {
          fromUrl: fromUrl
        }
      })
    );
  }

  public redirectToHome(fromUrl: string): void {
    this._appRouterStore.dispatch(
      redirectToHome({
        payload: {
          fromUrl: fromUrl
        }
      })
    );
  }

  public goToFeedList(keepQueryParams: boolean = true): void {
    const currentUrl = this._router.url;
    this._appRouterStore.dispatch(
      goToFeedList({
        payload: {
          fromUrl: currentUrl,
          keepQueryParams: keepQueryParams
        }
      })
    );
  }

  public goToFeed(
    toParams: {
      feed: string;
    },
    keepQueryParams: boolean = true
  ): void {
    const currentUrl = this._router.url;
    this._appRouterStore.dispatch(
      goToFeed({
        payload: {
          toParams: toParams,
          fromUrl: currentUrl,
          keepQueryParams: keepQueryParams
        }
      })
    );
  }

  public goToFeed404(fromUrl: string): void {}

  public goToFeedPage(feedSlug, tag?): void {
    this._appRouterStore.dispatch(goToFeedPage({ payload: { feedSlug: [feedSlug], tag } }));
  }

  public goToCalendar() {
    const lang = this._interfaceLangService.getBrowserLang();
    this._appRouterStore.dispatch(goToCalendar({ payload: { path: ['calendar', lang] } }));
  }

  public goToDashboardPage(page: Page) {
    this._appRouterStore.dispatch(goToDashboardPage({ payload: { page: page } }));
  }

  public goToSearchPage(terms): void {
    this._appRouterStore.dispatch(goToSearchPage({ payload: { terms: terms } }));
  }

  public gotoBookmarksPage() {
    const lang = this._interfaceLangService.getBrowserLang();
    this._appRouterStore.dispatch(
      goToBookmarksPage({
        payload: {
          path: ['bookmarks', 'all', lang]
        }
      })
    );
  }

  public gotoAddFeedPage() {
    const lang = this._interfaceLangService.getBrowserLang();
    this._appRouterStore.dispatch(
      goToAddFeedPage({
        payload: {
          path: ['add-feed', lang]
        }
      })
    );
  }

  public gotoPreferencesPage(lang, queryParam) {
    this._appRouterStore.dispatch(
      goToPreferencesPage({
        payload: {
          path: ['preferences', queryParam.section, lang]
        }
      })
    );
  }

  public gotoPublishPage() {
    this._appRouterStore.dispatch(
      goToPublishPage({
        payload: {
          path: ['publish']
        }
      })
    );
  }

  public goToOrderOnDemandPage(): void {
    const lang = this._interfaceLangService.getBrowserLang();
    this._appRouterStore.dispatch(
      goToOrderOnDemand({
        payload: {
          path: ['on-demand', lang]
        }
      })
    );
  }

  public goToAccountSettings() {}

  public goToFolderPage(folderId: string) {
    this._appRouterStore.dispatch(goToFolderPage({ payload: { folderId: folderId } }));
  }

  public goToWorkspace(tag?) {
    this._appRouterStore.dispatch(goToWorkspace({ payload: { path: ['workspace'], extras: tag } }));
  }

  public goToEditor(lang: Langs) {
    this._appRouterStore.dispatch(goToEditor({ payload: { path: ['workspace/editor', lang] } }));
  }

  public goToFlashBriefing() {
    this._appRouterStore.dispatch(goToFlashBriefing({ payload: { path: ['workspace/flash-briefing'] } }));
  }

  public goToMyAudioContents(lang) {
    this._appRouterStore.dispatch(goToMyAudioContents({ payload: { path: ['workspace/my-audio-storys', lang] } }));
  }

  public searchTag(tag?) {
    this._appRouterStore.dispatch(goToWorkspace({ payload: { path: ['workspace'], extras: tag } }));
  }

  public goToArticlePage(article: any) {
    const type = article.object_source_type || article.sourceType || article.type;
    const payload: ArticleParams = {
      page: 'articles',
      type: type,
      lang: article.lang,
      id: article.id,
      mainCategory: this._utilService.normalizeArticleTitle(article.mainCategory),
      title: this._utilService.normalizeArticleTitle(article.title)
    };
    this._appRouterStore.dispatch(goToArticlePage({ payload }));
  }

  // complicated logic to manage redirects on a page requested by the user after login. TODO improve me
  public goToDiscoverPage(tag: string = undefined, search: string = undefined, folder: string = undefined) {
    const lang$ = this._prefStore.pipe(select(selectContentLang));
    const feeds$ = this._catalogStore.pipe(select(userFeeds));
    const tag$ = this._appRouterStore.pipe(select(selectTag));
    const search$ = this._appRouterStore.pipe(select(selectSearchString));
    this._fromUrl = this._route.snapshot.queryParams['fromUrl'] || '/';

    combineLatest([feeds$, lang$, tag$, search$])
      .pipe(debounceTime(0), take(1))
      .subscribe(([_feeds, _lang, _tag, _search]) => {
        const currentUrl = this._route.snapshot['_routerState'].url;
        let queryParams: any = {};
        let activeFeedSlug = [];
        if (!_feeds.length) {
          activeFeedSlug = [];
        } else {
          if (_feeds.length === 1 && _feeds[0].slug === 'all') {
            activeFeedSlug = [];
          } else {
            activeFeedSlug = _feeds.map((f) => f.slug);
          }
        }

        queryParams = {
          feed: activeFeedSlug.length ? [...activeFeedSlug] : null,
          search: search || _search,
          tag: tag || _tag
        };

        if (folder) {
          queryParams.folder = folder;
        }

        const navigationExtras: NavigationExtras = {
          queryParams
        };
        if (
          this._fromUrl &&
          this._fromUrl !== '/' &&
          !this._fromUrl.includes('sign-in') &&
          !this._fromUrl.includes('sign-up')
        ) {
          this._router.navigateByUrl(this._fromUrl);
        } else if (
          (currentUrl &&
            (currentUrl === '/' || currentUrl.indexOf('auth') !== -1 || currentUrl.indexOf('discover') !== -1)) ||
          queryParams.tag
        ) {
          this._appRouterStore.dispatch(
            goToDiscover({ payload: { path: ['discover', _lang], extras: navigationExtras } })
          );
        }
      });
  }
}
