import { Injectable } from '@angular/core';

import { TokenCredentials, isTokenCredentials } from '../_models';
import { PersistentStorageService, StorageRead } from '@purplefront/shared';

export { TokenCredentials };

const CREDENTIAL_KEY = 'sessionCredentials';

@Injectable()
export class SessionService {
  constructor(private storage: PersistentStorageService) {}

  public loadCredentials(): TokenCredentials | null {
    const storedCredentials: StorageRead = this.storage.get(CREDENTIAL_KEY);
    if (!storedCredentials.ok) {
      return null;
    }

    const data: any = storedCredentials.data;
    return isTokenCredentials(data) ? (data as TokenCredentials) : null;
  }

  public saveCredentials(credentials: TokenCredentials): void {
    this.storage.set(CREDENTIAL_KEY, credentials);
  }

  public clearCredentials(): void {
    this.storage.clear(CREDENTIAL_KEY);
  }
}
