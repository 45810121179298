<!-- NEW LOGIN PAGE-->
<div class="container-fluid login-page">
  <div class="row justify-content-center">
    <div class="col-md-9 col-lg-12">
      <div class="card border-0 my-0">
        <div class="card-body p-0">
          <div class="row height-100">
            <div class="col-lg-7 d-lg-flex p-0">
              <div class="flex-grow-1 bg-login-image">
                <picture>
                  <source media="(max-width: 991px)" srcset="assets/images/logos/revolusound-etxstudio-logo.png" />
                  <source media="(min-width: 992px)" srcset="assets/images/logos/logo-etx-studio-revolusound.png" />
                  <img
                    class="d-block img-fluid-logo"
                    src="assets/images/logos/revolusound-etx-studio-logo.png"
                    alt="ETX RevoluSOUND"
                  />
                </picture>
                <div class="text-center">
                  <h4 class="hide-on-mobile">{{'UNIQUE_PLATFORM' | translate}}</h4>
                </div>
                <div class="d-flex">
                  <iframe
                    class="d-none d-lg-block"
                    src="https://player.vimeo.com/video/502616902"
                    width="475"
                    height="270"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                  ></iframe>
                </div>
              </div>
            </div>
            <div class="col-lg-5 shadow-left d-flex flex-column">
              <ul class="d-flex ps-0 mb-0">
                <li class="ms-auto d-flex header-page-lang dropdown">
                  <app-etx-icons class="lang-icon" name="language"></app-etx-icons>
                  <div ngbDropdown class="d-inline-block">
                    <button *ngIf="interfaceLang === 'fr'" class="btn" id="auth_lang_fr" ngbDropdownToggle>FR</button>
                    <button *ngIf="interfaceLang === 'en'" class="btn" id="auth_lang_en" ngbDropdownToggle>EN</button>
                    <div ngbDropdownMenu aria-labelledby="auth_lang">
                      <button *ngIf="interfaceLang !== 'fr'" ngbDropdownItem (click)="changeInterfaceLang('fr')">
                        FR
                      </button>
                      <button *ngIf="interfaceLang !== 'en'" ngbDropdownItem (click)="changeInterfaceLang('en')">
                        EN
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="p-0 p-sm-3">
                <div class="text-center mt-2 mb-2 mt-sm-2 mt-xl-5 mb-sm-4 mb-xl-5">
                  <h2>{{'WELCOME' | translate}}</h2>
                  <h5 class="mb-5">{{'LOGIN_MESSAGE' | translate}}</h5>
                </div>
                <!-- LOGIN PAGE -->
                <div class="row">
                  <p class="error alert alert-danger" *ngIf="(loginErrorMsg$ | async)">
                    {{(loginErrorMsg$ | async) | translate}}
                  </p>
                  <div class="col-6">
                    <h5 class="auth-tab-title" (click)="setAuthTab('sign-in')">
                      <span class="">{{'AUTH_LOGIN' | translate}}</span>
                    </h5>
                  </div>
                  <div class="col-6">
                    <h5 class="auth-tab-title" (click)="setAuthTab('sign-up')">
                      <span class="signup-txt">{{'SIGN_UP' | translate}}</span>
                    </h5>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 log-in">
                    <div class="progress">
                      <div
                        class="progress-bar"
                        [ngClass]="{'active-sign-up': isSetAuthTab('sign-up'),'active-sign-in': isSetAuthTab('sign-in')}"
                        role="progressbar"
                        style="width: 50%"
                      ></div>
                    </div>
                  </div>
                </div>
                <form id="sign-in" *ngIf="isSetAuthTab('sign-in')" class="user" method="POST">
                  <div class="form-group mb-2 mt-0 mb-sm-4 mt-sm-4">
                    <label for="login-email">{{'AUTH_EMAIL_ADDRESS' | translate}}</label>
                    <input
                      class="form-control form-control-user"
                      type="email"
                      id="login-email"
                      aria-describedby="emailHelp"
                      placeholder="{{'AUTH_EMAIL_ADDRESS' | translate}}"
                      [(ngModel)]="email"
                      name="login-email"
                    />
                  </div>
                  <div class="form-group mt-1 mt-sm-3">
                    <label for="login-password">{{'AUTH_PASSWORD' | translate}}</label>
                    <input
                      autocomplete="false"
                      class="form-control form-control-user"
                      type="password"
                      id="login-password"
                      placeholder="{{'AUTH_PASSWORD' | translate}}"
                      [(ngModel)]="password"
                      name="login-password"
                    />
                  </div>
                  <div class="form-group mb-3 mb-sm-5">
                    <label class="connected-register"
                      ><span class="connected-checkbox"><input type="checkbox" /></span>
                      <span class="connected-text">{{'AUTH_STAY_CONNECTED' | translate}} </span> </label
                    >
                    <div class="register-link text-end">
                      <span
                        ><a id="forgot-pass-link" routerLink="/auth/forgot-pass"
                          >{{"AUTH_FORGOT_PASSWORD" | translate}}</a
                        ></span
                      >
                    </div>
                  </div>
                  <button
                    (click)="login()"
                    [disabled]="loading$ | async"
                    class="btn btn-login btn-block text-white btn-user"
                    type="submit"
                  >
                    {{"AUTH_LOGIN" | translate}}
                    <i *ngIf="loading$ | async" class="fa fa-spinner fa-pulse fa-1x" aria-hidden="true"></i>
                    <i *ngIf="logged$ | async" class="fa fa-check"></i>
                  </button>
                </form>
                <!-- END LOGIN PAGE -->
                <app-registration-container *ngIf="isSetAuthTab('sign-up')"></app-registration-container>
              </div>
              <div class="login-footer mt-auto">
                <div class="text-center pb-2">
                  <a href="https://etxstudio.com" target="_blank"> {{"CORPORATE_WEBSITE" | translate}}</a> |
                  <a href="https://blog.etxstudio.com" target="_blank">Blog</a> |
                  <a href="https://parismodes.studio" target="_blank">ParisModes Studio</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
