<ngx-smart-modal identifier="session-expired-modal" #sessionExpiredModal>
  <div
    class="
      session-expired-modal
      flex flex-column
      align-items-center
      justify-content-center
      pt-4
      pe-4
      ps-4
      pb-2
      text-center
    "
    tabindex="-1"
    role="dialog"
  >
    <div class="session-body mb-4">
      <h1>{{ 'SESSION_EXPIRED' | translate }}</h1>
      <p class="">
        {{ 'SESSION_EXPIRED_BODY' | translate }}
      </p>
    </div>
    <div class="session-footer">
      <button (click)="sessionExpiredModal.close()" [routerLink]="['auth/login']">
        {{ 'AUTH_LOGIN' | translate }}
      </button>
    </div>
  </div>
</ngx-smart-modal>
