<div id="relax-calendar-container">
  <div id="full-calendar-wrapper" class="full-calendar-wrapper">
    <full-calendar #relaxCalendar [options]="calendarOptions" id="full-calendar-container"> </full-calendar>
  </div>

  <div *ngIf="pastEvents.length" class="relax-calendar-events" id="relax-calendar-past-events">
    <div class="event-list-header">
      <h2>Past Events</h2>
    </div>

    <ng-template [ngForOf]="pastEvents | slice: 0:15" [ngForTrackBy]="'id' | trackByProperty" let-event ngFor>
      <div class="event-list-content-wrapper">
        <div class="event-bookmark">
          <app-bookmark-button
            [appBookmark]="event"
            [contextType]="contextType"
            [icon]="icon"
            [iconActive]="iconActive"
          ></app-bookmark-button>
        </div>
        <div class="event-list-content" (click)="onEventClick(event.data)">
          <div class="event-list-date">{{ event.start | date }}</div>
          <div class="event-list-title">
            <span>{{ event.title }}</span>
          </div>
          <div [innerHTML]="event.data.content | safe: 'html'" class="event-list-description"></div>
        </div>
      </div>
    </ng-template>
  </div>

  <div *ngIf="isLoaded && upComingEvents.length" class="relax-calendar-events" id="relax-calendar-upcoming-events">
    <div class="event-list-header">
      <h2>Upcoming Event</h2>
    </div>

    <ng-template [ngForOf]="upComingEvents | slice: 0:10" [ngForTrackBy]="'id' | trackByProperty" let-event ngFor>
      <div class="event-list-content-wrapper">
        <div class="event-bookmark">
          <app-bookmark-button
            [appBookmark]="event"
            [contextType]="contextType"
            [icon]="icon"
            [iconActive]="iconActive"
          ></app-bookmark-button>
        </div>
        <div class="event-list-content event-text" (click)="onEventClick(event.data)">
          <div class="event-list-date">{{ event.start | date }} - {{ event.end | date }}</div>
          <div class="event-list-title">
            <span>{{ event.title }}</span>
          </div>
          <div [innerHTML]="event.data.content | safe: 'html'" class="event-list-description"></div>
        </div>
      </div>
    </ng-template>
  </div>

  <div *ngIf="isLoading" class="relax-calendar-events" id="ghost-events">
    <app-ghosts-elements [nbElements]="nbElements"></app-ghosts-elements>
  </div>
</div>
