<div class="reset-pass-view" id="reset-pass-container">
  <div class="row justify-content-sm-center">
    <div class="reset-pass-view__logo__block col-md-5">
      <img src="assets/images/logos/etx-dailyup-color-logo.png" alt="ETX Daily UP" />
    </div>
  </div>

  <div class="reset-pass-view__reset-pass__block column">
    <h2>{{ 'AUTH_RESET_NEW_PASSWORD' | translate }}</h2>
    <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword(resetPasswordForm.value)" method="POST">
      <div class="form-group">
        <div class="mb-3">
          <input
            class="form-control"
            id="password"
            type="password"
            placeholder="{{ 'AUTH_PASSWORD' | translate }}"
            formControlName="password"
          />
          <div
            class="error"
            *ngIf="!resetPasswordForm.controls['password'].valid && resetPasswordForm.controls['password'].touched"
          >
            <div *ngIf="resetPasswordForm.controls['password'].errors.required">
              * {{ 'AUTH_PASSWORD_IS_REQUIRED' | translate }}
            </div>
            <div *ngIf="resetPasswordForm.controls['password'].errors.minlength">
              * {{ 'AUTH_PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS' | translate }}
            </div>
          </div>
        </div>

        <div class="mb-3">
          <input
            class="form-control"
            id="password_confirmation"
            type="password"
            placeholder="{{ 'AUTH_VERIFY_PASSWORD' | translate }}"
            formControlName="password_confirmation"
          />
          <div
            class="error"
            *ngIf="
              !resetPasswordForm.controls['password_confirmation'].valid &&
              resetPasswordForm.controls['password_confirmation'].touched
            "
          >
            <div *ngIf="resetPasswordForm.controls['password_confirmation'].errors.required">
              * {{ 'AUTH_PASSWORD_IS_REQUIRED' | translate }}
            </div>
            <div *ngIf="resetPasswordForm.controls['password_confirmation'].errors.minlength">
              * {{ 'AUTH_PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS' | translate }}
            </div>
            <div *ngIf="resetPasswordForm.controls['password_confirmation'].errors.not_match">
              * {{ 'AUTH_PASSWORDS_NOT_MATCH' | translate }}
            </div>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary mt-5" [disabled]="!resetPasswordForm.valid">
        {{ 'SUBMIT' | translate }}
      </button>
    </form>
  </div>
</div>
