import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromPreferencesStore from '@purplefront/preferences/data-access';
import { TrackingService } from '../../../../../shared/src/lib/_services/tracking.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-content-filters-menu',
  templateUrl: './content-filters-menu.component.html',
  styleUrls: ['./content-filters-menu.component.scss']
})
export class ContentFiltersMenuComponent implements OnInit {
  @Input() nbSelectFilters$;
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public _preferencesStore: Store<fromPreferencesStore.PreferencesState>,
    private _trackingService: TrackingService
  ) {}

  ngOnInit(): void {}

  toggleFilterMenu(): void {
    this._trackingService.trackEvent({ type: 'panel-results' }).pipe(takeUntil(this._ngUnsubscribe$)).subscribe();
    this._preferencesStore.dispatch(fromPreferencesStore.toggleFilterMenu());
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
