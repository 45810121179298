import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioStoryComponent } from './components/audio-story/audio-story.component';
import { PlyrModule } from 'ngx-plyr';
import { ModalAudioStoryComponent } from './components/modal-audio-story/modal-audio-story.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { AudioStoryGenerationComponent } from './components/audio-story-generation/audio-story-generation.component';
import { SharedModule } from '@purplefront/shared';
import { EtxIconsRegistryService } from '../../../../shared/src/lib/components/etx-icons/etx-icons-registry.service';
import {
  etxIconCopy,
  etxIconDownload,
  etxIconEdit,
  etxIconPlayWhite,
  etxIconShare,
  etxIconWave,
  etxIconDownloadWhite,
  etxIconPortrait,
  etxIconSquare
} from '../../../../shared/src/lib/components/etx-icons/etx-icons.model';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    PlyrModule,
    NgxSmartModalModule,
    SharedModule,
    NgbProgressbarModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [AudioStoryComponent, ModalAudioStoryComponent, AudioStoryGenerationComponent],
  exports: [AudioStoryComponent, ModalAudioStoryComponent, AudioStoryGenerationComponent]
})
export class AudioStoryUiModule {
  constructor(private _etxIconsRegistry: EtxIconsRegistryService) {
    this._etxIconsRegistry.registerIcons([
      etxIconEdit,
      etxIconDownload,
      etxIconCopy,
      etxIconShare,
      etxIconPlayWhite,
      etxIconWave,
      etxIconDownloadWhite,
      etxIconPortrait,
      etxIconSquare
    ]);
  }
}
