import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import Plyr from 'plyr';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AudioPlayerService } from '../_services';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';

import {
  AudioStoryState,
  selectAudioStoryPlaying,
  selectGeneratedStoryItemUrl
} from '@purplefront/audio-story/data-access';
import * as fromPlaylistStore from '@purplefront/playlist/data-access';
import * as fromAudioStoryStore from '@purplefront/audio-story/data-access';
import { EditorState, selectPlyrAudioSource } from '@purplefront/editor/data-access';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player-container.component.html',
  styleUrls: ['./audio-player-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('showHide', [
      state(
        'active',
        style({
          position: 'fixed',
          bottom: 0,
          transform: 'translateY(0%)'
        })
      ),
      state(
        'inactive',
        style({
          position: 'fixed',
          bottom: 0,
          transform: 'translateY(100%)'
        })
      ),
      transition('inactive => active', animate('0.3s ease-in')),
      transition('active => inactive', animate('0.3s ease-out'))
    ])
  ]
})
export class AudioPlayerContainer implements OnInit, AfterViewInit {
  public player: Plyr;
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();
  public showPlayer = 'inactive';
  public hasValidSource: boolean;
  private defaultSource = [
    {
      src: '',
      type: 'audio/mp3'
    }
  ];
  public source$ = new BehaviorSubject(this.defaultSource);
  private _playlistArticles: any;
  public playerStatus = false;
  private _currentPlayingArticleIndex: number;
  private _defaultVoiceId: number;
  public isPlaying: boolean;

  constructor(
    public _audioPlayerService: AudioPlayerService,
    private _audioStoryStore: Store<AudioStoryState>,
    private _playlistStore: Store<fromPlaylistStore.State>,
    private _editorStore: Store<EditorState>
  ) {}

  ngOnInit(): void {
    this.getAudioPlayerStatus();
    this.getPlaylist();
    this.getCurrentlyPlayingArticle();
  }

  ngAfterViewInit() {
    this.getGeneratedStoryItemUrl();
    this.getSource();
    this.isAudioPlaying();
  }

  getAudioPlayerStatus() {
    this._audioPlayerService.status$.pipe(debounceTime(0), takeUntil(this._ngUnsubscribe$)).subscribe((val) => {
      this.playerStatus = val;
      this.showPlayer = val ? 'active' : 'inactive';
    });
  }

  getSource() {
    this._editorStore.pipe(select(selectPlyrAudioSource), takeUntil(this._ngUnsubscribe$)).subscribe((audioUrl) => {
      if (audioUrl) {
        const mappedAudio = this._audioPlayerService.mapAudioSource(audioUrl);
        this.source$.next(mappedAudio);
        this._audioPlayerService.status$.next(true);
        this.play();
      }
    });
  }

  getGeneratedStoryItemUrl() {
    this._audioStoryStore
      .pipe(select(selectGeneratedStoryItemUrl), takeUntil(this._ngUnsubscribe$))
      .subscribe((audioUrl) => {
        const mappedAudio = this._audioPlayerService.mapAudioSource(audioUrl);
        this.source$.next(mappedAudio);
        const sourceObj = this.source$.getValue();
        this.hasValidSource = !!sourceObj[0].src;
        // this.play();
      });
  }

  isAudioPlaying() {
    this._audioStoryStore
      .pipe(select(selectAudioStoryPlaying), takeUntil(this._ngUnsubscribe$))
      .subscribe((playing) => {
        if (!playing) {
          this.pause();
        } else {
          this.play();
        }
      });
  }

  getPlaylist() {
    this._playlistStore
      .pipe(select(fromPlaylistStore.getPlaylistArticles), takeUntil(this._ngUnsubscribe$))
      .subscribe((playlistArticles) => {
        this._playlistArticles = playlistArticles;
      });
  }

  getCurrentlyPlayingArticle() {
    this._playlistStore
      .pipe(select(fromPlaylistStore.getCurrentPlayingArticleIndex), takeUntil(this._ngUnsubscribe$))
      .subscribe((index) => {
        this._currentPlayingArticleIndex = index;
      });
  }

  generateNextArticle() {
    let nextArticleIndex = null;
    if (this._currentPlayingArticleIndex + 1 === this._playlistArticles.length) {
      return;
    } else {
      nextArticleIndex = this._currentPlayingArticleIndex + 1;
    }
    const nextArticle = this._playlistArticles[nextArticleIndex];
    nextArticle.lang === 'fr' ? (this._defaultVoiceId = 2) : (this._defaultVoiceId = 1);
    const payload = {
      voiceId: this._defaultVoiceId,
      text: nextArticle.header
    };
    this._playlistStore.dispatch(fromPlaylistStore.loadCurrentPlayingArticleIndex({ index: nextArticleIndex }));
    this._audioStoryStore.dispatch(fromAudioStoryStore.generateAudioStoryItem({ payload }));
  }

  onPlaybackEnded(event?: Plyr.PlyrEvent) {
    if (this.player && this._playlistArticles && this._currentPlayingArticleIndex !== undefined) {
      this.generateNextArticle();
    }
  }

  play(event?: Plyr.PlyrEvent): void {
    if (this.player) {
      this.player.on('canplay', (e) => {
        this.player = e.detail.plyr;
        if (this.player) {
          (this.player.play() as any).then(
            function () {},
            function () {}
          );
        }
      });
    }

    if (!this.player.playing) {
      (this.player.play() as any).then(
        function () {},
        function () {}
      );
    }
  }

  pause(event?: Plyr.PlyrEvent): void {
    if (this.player) {
      this.player.pause();
    }
  }

  handlePlay(event?: Plyr.PlyrEvent) {
    this._audioStoryStore.dispatch(fromAudioStoryStore.playPauseAudio({ payload: { playing: true } }));
  }
  handlePause(event?: Plyr.PlyrEvent) {
    this._audioStoryStore.dispatch(fromAudioStoryStore.playPauseAudio({ payload: { playing: false } }));
  }

  stop(): void {
    this.player.stop();
  }

  destroy(): void {
    this.player.destroy();
  }

  togglePlayer(): void {
    this.playerStatus = !this.playerStatus;
    this._audioPlayerService.status$.next(this.playerStatus);
  }

  /**
   * Prevent memory leaks unsubscribe when destroy component
   */
  ngOnDestroy(): void {
    this.destroy();
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
