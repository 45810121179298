import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LatestArticlesComponent } from './components/latest-articles/latest-articles.component';
import { KeywordsComponent } from './components/keywords/keywords.component';
import { SuggestListComponent } from './components/suggest-list/suggest-list.component';
import { SharedModule } from '@purplefront/shared';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SearchNotFoundComponent } from './components/search-not-found/search-not-found.component';
import { ArticleUiModule } from '@purplefront/article/ui';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ArticleUiModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [LatestArticlesComponent, KeywordsComponent, SuggestListComponent, SearchNotFoundComponent],
  exports: [LatestArticlesComponent, KeywordsComponent, SuggestListComponent, SearchNotFoundComponent]
})
export class SearchUiModule {}
