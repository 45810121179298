<div class="file-drop-area d-flex flex-column justify-content-center align-content-center position-relative">
  <app-loading *ngIf="isLoading" [inline]="true"></app-loading>
  <ng-container *ngIf="!isLoading">
    <span *ngIf="file" (click)="deleteAudio()" title="delete image" class="edit-upload-img-delete"
      ><i class="fa fa-times" aria-hidden="true"></i
    ></span>
    <span class="file-icon">
      <app-etx-icons name="upload_sound"></app-etx-icons>
    </span>

    <span class="file-title" [ngClass]="{ 'text-ellipsis': file?.name?.length > 25 }"
      >{{ file ? file.name : fileNameFromUrl }}
    </span>
    <span *ngIf="!file && !fileNameFromUrl" class="file-title">{{ label | translate }} </span>
    <span class="file-support">{{ 'EDITOR_FILE_SUPPORT' | translate }}: {{ supportedFileFormat.join('/') }}</span>
    <input class="file-input" type="file" />
  </ng-container>
</div>
<app-progress-audio></app-progress-audio>
