import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';

@Component({
  selector: 'app-suggest-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './suggest-list.component.html',
  styleUrls: ['./suggest-list.component.scss']
})
export class SuggestListComponent implements OnInit {
  @Input() searchResponse: {};
  @Input() searchString: string;
  @Input() searchLoaded: boolean;
  @Output() search$ = new EventEmitter<{ query: string; event: any; searchEntity: string }>();
  nbElements = [1, 2, 3, 4, 5, 6, 7, 8];

  constructor(private renderer: Renderer2, private _elementRef: ElementRef) {}

  ngOnInit() {}

  searchSuggestQuery(query: string, $event, searchEntity): void {
    this.search$.emit({ query: query, event: $event, searchEntity: searchEntity });
  }
}
