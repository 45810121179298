import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Langs } from '@purplefront/shared';

import { AppRouterFacadeService } from '@purplefront/app-router/data-access';
import * as fromAuthStore from '@purplefront/auth/data-access';
import * as fromPreferencesStore from '@purplefront/preferences/data-access';

@Injectable()
export class HeaderFacade {
  constructor(
    private _authStore: Store<fromAuthStore.AuthState>,
    private _appRouterFacade: AppRouterFacadeService,
    private _preferencesStore: Store<fromPreferencesStore.PreferencesState>
  ) {}

  login() {
    const fromUrl = 'header';
    this._appRouterFacade.redirectToLogin(fromUrl);
  }

  logout() {
    this.login();
  }

  isLogged(): Observable<boolean> {
    return this._authStore.pipe(select(fromAuthStore.isLogged));
  }

  changeContentLang(lang: Langs) {
    this._preferencesStore.dispatch(fromPreferencesStore.changeContentLang({ payload: { contentLang: lang } }));
  }

  loadContentLang(): Observable<Langs> {
    return this._preferencesStore.pipe(select(fromPreferencesStore.selectContentLang));
  }

  goToBookmarksPage() {
    this._appRouterFacade.gotoBookmarksPage();
  }
  // TODO: send lang and section
  // goToPreferencesPage() {
  //   this.appRouter.gotoPreferencesPage();
  // }

  goToPublishPage() {
    this._appRouterFacade.gotoPublishPage();
  }

  goToAccountSettings() {
    this._appRouterFacade.goToAccountSettings();
  }
}
