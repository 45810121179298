import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-main-category-btn',
  templateUrl: './main-category-btn.component.html',
  styleUrls: ['./main-category-btn.component.scss']
})
export class MainCategoryBtnComponent implements OnInit {
  @Input() mainCategory: string;
  @Output() searchString$ = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  public search() {
    if (this.mainCategory) {
      this.searchString$.emit(this.mainCategory);
    }
  }
}
