import { Injectable } from '@angular/core';
import { Theme, defaultTheme, defaultMobisationTheme } from '../_models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private active: Theme = defaultTheme;
  private activeTheme$ = new BehaviorSubject<Theme>(defaultTheme);

  getActiveTheme(): Observable<Theme> {
    return this.activeTheme$.asObservable();
  }

  setDefaultTheme(): void {
    this.setActiveTheme(defaultTheme);
  }

  setDefaultMobisationTheme(): void {
    this.setActiveTheme(defaultMobisationTheme);
  }

  setActiveTheme(theme: Theme): void {
    this.active = theme;
    this.activeTheme$.next(theme);

    Object.keys(this.active.properties).map((property) => {
      document.documentElement.style.setProperty(property, this.active.properties[property]);
    });
  }
}
