import { createSelector } from '@ngrx/store';

import * as fromAppRouter from '../reducers';

import { intersection } from 'lodash-es';

export const selectContentLang = createSelector(fromAppRouter.getAppRouterState, (urlState: any) =>
  urlState ? urlState.state.params.lang : null
);

export const selectActiveFeeds = createSelector(fromAppRouter.getAppRouterState, (urlState: any) =>
  urlState && urlState.state.queryParams.feed ? urlState.state.queryParams.feed : []
);

export const selectCatalogSlug = createSelector(fromAppRouter.getAppRouterState, (urlState: any) =>
  urlState ? urlState.state.params.catalogSlug : undefined
);

export const selectFeedSlug = createSelector(fromAppRouter.getAppRouterState, (urlState: any) =>
  urlState ? urlState.state.params.feedSlug : undefined
);

export const selectSearchString = createSelector(fromAppRouter.getAppRouterState, (urlState: any) =>
  urlState ? urlState.state.queryParams.search : undefined
);

export const selectTag = createSelector(fromAppRouter.getAppRouterState, (urlState: any) =>
  urlState ? urlState.state.queryParams.tag : undefined
);

export const selectDocType = createSelector(fromAppRouter.getAppRouterState, (urlState: any) =>
  urlState ? urlState.state.params.docType : undefined
);

export const selectPage = createSelector(fromAppRouter.getAppRouterState, (urlState: any) => {
  const urlsSegments = urlState ? urlState.state.url.split('/') : undefined;
  const pages = [
    'dashboard',
    'radar',
    'afprelax',
    'reader',
    'dataviz',
    'calendar',
    'folders',
    'bookmarks',
    'preferences',
    'add-feed',
    'on-demand'
  ];

  const currentPage = intersection(pages, urlsSegments);
  return currentPage[0];
});

export const selectCurrentSection = createSelector(
  fromAppRouter.getAppRouterState,
  (urlState: any) => urlState.state.params.section
);

export const selectFolderId = createSelector(
  fromAppRouter.getAppRouterState,
  (urlState: any) => urlState.state.params.id
);

export const selectCurrentUrl = createSelector(fromAppRouter.getAppRouterState, (urlState: any) => urlState.state.url);

export const selectModal = createSelector(
  fromAppRouter.getAppRouterState,
  (urlState: any) => urlState?.state.queryParams.modal
);

export const selectArticleId = createSelector(
  fromAppRouter.getAppRouterState,
  (urlState: any) => urlState?.state.queryParams.articleId || urlState?.state.params.id
);

export const selectArticleLang = createSelector(
  fromAppRouter.getAppRouterState,
  (urlState: any) => urlState?.state.queryParams.articleLang || urlState?.state.params.lang
);

export const selectArticleSourceType = createSelector(
  fromAppRouter.getAppRouterState,
  (urlState: any) => urlState?.state.queryParams.sourceType || urlState?.state.params.sourceType
);

export const selectAudioStoryId = createSelector(
  fromAppRouter.getAppRouterState,
  (urlState: any) => urlState?.state.queryParams.audioStoryId || urlState?.state.params.audioStoryId
);

export const selectBookmarkType = createSelector(
  fromAppRouter.getAppRouterState,
  (urlState: any) => urlState?.state.params.bookmarkType
);

export const selectSavedSearchId = createSelector(
  fromAppRouter.getAppRouterState,
  (urlState: any) => urlState?.state.queryParams.searchId
);
