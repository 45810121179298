import { Injectable } from '@angular/core';
import { environment } from '@purplefront/shared';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Assets, AudioStory } from '../_models';
import { Observable } from 'rxjs';

@Injectable()
export class AudioStoryApi {
  private audioStoryApiUrl: string = `${environment.purpleApi}/audiostories`;
  private voiceApiUrl: string = `${environment.purpleApi}/voices`;

  constructor(private _http: HttpClient) {}

  getAllAudioStories(page: number | string): Observable<any> {
    return this._http
      .get(`${this.audioStoryApiUrl}?page=${page}&perPage=15`)
      .pipe(map((res) => this.audioStoriesMapping(res)));
  }

  getAudioStory(id): Observable<any> {
    return this._http.get(`${this.audioStoryApiUrl}/${id}`).pipe(map((res) => res['data']));
  }

  private audioStoriesMapping(res) {
    return {
      lastPage: res.lastPage,
      total: res.total,
      status: res.status,
      data: res.data.map((audioStory: any) => {
        return {
          assets: this.assetsMapping(audioStory.assets),
          id: audioStory.id,
          createdAt: audioStory.created_at,
          updatedAt: audioStory.updated_at,
          name: audioStory.name,
          title: audioStory.title,
          image: audioStory.image,
          items: audioStory.items
        };
      })
    };
  }

  private mapAudioStory(audioStory): AudioStory {
    return {
      assets: this.assetsMapping(audioStory.assets),
      id: audioStory.id,
      createdAt: audioStory.created_at,
      updatedAt: audioStory.updated_at,
      status: audioStory.status,
      name: audioStory.name,
      title: audioStory.title,
      image: audioStory.image,
      items: audioStory.items
    };
  }

  private assetsMapping(assets): Assets {
    let mappedAssets: Assets = {
      audioAttachment: null,
      audioStream: null,
      videoAttachment: null,
      videoStream: null,
      videoMobileAttachment: null,
      videoMobileStream: null,
      videoLandscapeAttachment: null,
      videoLandscapeStream: null
    };
    assets.forEach((asset) => {
      if (asset.format === 'audio') {
        mappedAssets.audioAttachment = asset.url_attachment;
        mappedAssets.audioStream = asset.url_stream;
      } else if (asset.format === 'video') {
        mappedAssets.videoAttachment = asset.url_attachment;
        mappedAssets.videoStream = asset.url_stream;
      } else if (asset.format === 'video_mobile') {
        mappedAssets.videoMobileAttachment = asset.url_attachment;
        mappedAssets.videoMobileStream = asset.url_stream;
      } else if (asset.format === 'video_16_9') {
        mappedAssets.videoLandscapeAttachment = asset.url_attachment;
        mappedAssets.videoLandscapeStream = asset.url_stream;
      }
    });
    return mappedAssets;
  }

  addAudioStory(content: AudioStory): Observable<any> {
    return this._http.post(this.audioStoryApiUrl, content).pipe(
      map((response: any) => {
        return this.mapAudioStory(response.data);
      })
    );
  }

  removeAllAudioStories(): Observable<any> {
    return this._http.delete(this.audioStoryApiUrl);
  }

  removeMultipleAudioStories(contentIds: number[]) {
    return this._http.patch(this.audioStoryApiUrl, contentIds);
  }

  removeAudioStory(contentId: number) {
    return this._http.delete(`${this.audioStoryApiUrl}/${contentId}`);
  }

  editAudioStory(content: AudioStory, id: number): Observable<any> {
    return this._http.put(`${this.audioStoryApiUrl}/${id}`, content);
  }

  renderAudioStory(params): Observable<any> {
    return this._http.post(`${this.audioStoryApiUrl}/render/${params.id}/${params.format}`, null);
  }

  getGeneratedAudioStoryUrl(id): Observable<any> {
    return this._http.get(`${this.audioStoryApiUrl}/status/${id}`);
  }

  generateAudio(data): Observable<any> {
    return this._http.post(this.voiceApiUrl, data).pipe(map((res: any) => res['data']));
  }
}
