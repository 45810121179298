import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { take, takeUntil, tap } from 'rxjs/operators';
import * as preferencesStore from '@purplefront/preferences/data-access';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { AuthState } from '@purplefront/auth/data-access';
import { Langs, TrackingService } from '@purplefront/shared';
import { NgxSmartModalService } from 'ngx-smart-modal';
import pkg from '../../../../../../../package.json';

@Component({
  selector: 'app-general-tab',
  templateUrl: './general-tab.component.html',
  styleUrls: ['./general-tab.component.scss']
})
export class GeneralTab implements OnInit {
  readonly version = pkg.version;
  languageForm: FormGroup;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();
  @Input() catalogs;
  @Input() interfaceLang$;

  constructor(
    private fb: FormBuilder,
    private preferencesStore: Store<preferencesStore.PreferencesState>,
    private _authStore: Store<AuthState>,
    private _trackingService: TrackingService,
    private _ngxSmartModalService: NgxSmartModalService
  ) {}

  ngOnInit() {
    this.initForm();
    this.onLanguageFormValueChange();
  }
  initForm() {
    this.languageForm = this.fb.group({
      interfaceLang: ['']
    });
    this.interfaceLang$.subscribe((lang) => {
      this.languageForm.setValue({
        interfaceLang: lang
      });
    });
  }
  public openCguCgvModal() {
    this.interfaceLang$.pipe(take(1)).subscribe((lang: Langs) => {
      if (lang === 'en') {
        this._ngxSmartModalService.getModal('cguEnModal').open();
      } else if (lang === 'fr') {
        this._ngxSmartModalService.getModal('cguFrModal').open();
      }
    });
  }

  onLanguageFormValueChange() {
    this.languageForm.valueChanges
      .pipe(
        takeUntil(this._ngUnsubscribe$),
        tap((form) => {
          this.preferencesStore.dispatch(
            preferencesStore.changeInterfaceLang({
              payload: { interfaceLang: form.interfaceLang }
            })
          );
        })
      )
      .subscribe();
    this._trackingService
      .trackEvent({ type: 'preferences/change-interface-lang' })
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe();
  }
  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
