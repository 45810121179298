import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@purplefront/shared';
import { BookmarksDataAccessModule } from '@purplefront/bookmarks/data-access';
import * as fromSearchModalStore from '@purplefront/saved-search/data-access';

import { SearchModalComponent } from './components/search-modal/search-modal.component';
import { SearchButtonComponent } from './components/search-btn/search-btn.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    BookmarksDataAccessModule,
    StoreModule.forFeature('searchModal', fromSearchModalStore.reducer),
    EffectsModule.forFeature([fromSearchModalStore.SearchModalEffects]),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [SearchModalComponent, SearchButtonComponent],
  exports: [SearchModalComponent, SearchButtonComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SavedSearchUiModule {}
