import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {
  private _subject = new Subject<any>();

  confirmThis(message: string, yesFn: () => void, noFn: () => void): any {
    this.setConfirmation(message, yesFn, noFn);
  }

  constructor() {}

  setConfirmation(message: string, yesFn: () => void, noFn: any): any {
    this._subject.next({
      type: 'confirm',
      text: message,
      yesFn: (): any => {
        this._subject.next(null);
        yesFn();
      },
      noFn: (): any => {
        this._subject.next(null);
        noFn();
      }
    });
  }

  getMessage(): Observable<any> {
    return this._subject.asObservable();
  }
}
