import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError, mergeMap, tap, withLatestFrom, concatMap } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';

import * as fromAuthStore from '../../../../../../../libs/auth/data-access/src/lib/_store';
import { CatalogState } from '../reducers';
import * as fromCatalogAction from '../actions';

import { CatalogService } from '../../_services';
import { Catalog, Feed } from '../../_models';
import { CatalogApi } from '../../_api';

import { uniqBy, flatten } from 'lodash-es';
import * as fromPreferencesStore from '@purplefront/preferences/data-access';
import { AppRouterService, AppRouterFacadeService } from '@purplefront/app-router/data-access';
import { BaseUrlService } from '@purplefront/shared';
import { Router } from '@angular/router';
import { loadUserPreferences } from '@purplefront/preferences/data-access';
import { userFeeds } from '../selectors';
import { AuthService } from '@purplefront/auth/data-access';

@Injectable()
export class CatalogEffects {
  catalog$: Observable<Catalog>;
  feed$: Observable<Feed>;
  feedSlug: string;

  constructor(
    private _actions$: Actions<fromCatalogAction.CatalogActionsUnion>,
    private _store: Store<CatalogState>,
    private _preferencesStore: Store<fromPreferencesStore.PreferencesState>,
    private _catalogService: CatalogService,
    private _appRouterFacade: AppRouterFacadeService,
    private _routerEffectsService: AppRouterService,
    private _catalogApi: CatalogApi,
    private _baseUrlService: BaseUrlService,
    private _router: Router,
    private _location: Location,
    private _authService: AuthService
  ) {}

  getCatalog$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromAuthStore.sessionInitialized),
      switchMap((action) => {
        if (action.payload.anonymousSession) {
          //   this._routerEffectsService.goToLogin();
          this._authService.navigateToAuthPage();
          return of(
            fromCatalogAction.catalogError({
              payload: { error: 'User not logged in' }
            })
          );
        } else {
          return this._catalogService.getCatalogs().pipe(
            map((_catalogs) => {
              let _feeds: any[] = this._catalogApi.formatCatalogFeeds(_catalogs);
              _feeds = uniqBy(flatten(_feeds), (f) => f.slug);
              return fromCatalogAction.catalogInitialized({
                payload: {
                  catalogs: _catalogs,
                  feeds: _feeds
                }
              });
            })
          );
        }
      })
    )
  );

  catalogInitialized$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromCatalogAction.catalogInitialized),
      switchMap(() => [fromCatalogAction.getUserFeeds(), loadUserPreferences()])
    )
  );

  getUserFeedsId$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromCatalogAction.getUserFeeds),
      mergeMap(() =>
        this._catalogService.getUserFeeds().pipe(
          map((payload) =>
            fromCatalogAction.getUserFeedsSuccess({
              payload: { userFeeds: payload }
            })
          ),
          catchError((err: any) => {
            return of(fromCatalogAction.getUserFeedsFail({ payload: { error: err } }));
          })
        )
      )
    )
  );

  getUserFeedsSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromCatalogAction.getUserFeedsSuccess),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this._store.pipe(select(userFeeds))),
          tap(([action, feeds]) => this._appRouterFacade.goToDiscoverPage())
        )
      ),
      map(() => fromPreferencesStore.getCurrentFeedSlug())
    )
  );

  getFolders$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromCatalogAction.getFolders),
      mergeMap((action) => {
        return this._catalogService.getFolders(action.payload.contentLang, action.payload.currentFeed).pipe(
          map((payload) => {
            return fromCatalogAction.getFoldersSuccess({
              payload: {
                currentFeed: action.payload.currentFeed,
                folders: payload.data
              }
            });
          }),
          catchError((err: any) => {
            return of(fromCatalogAction.getFoldersFail({ payload: { error: err } }));
          })
        );
      })
    )
  );

  setCurrentFeed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromPreferencesStore.setCurrentFeed),
      map((action) =>
        fromPreferencesStore.setCurrentFeedSlug({
          payload: {
            feedSlug: action.payload.feed.slug
          }
        })
      )
    )
  );

  setCurrentFeedSlug$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromPreferencesStore.setCurrentFeedSlug),
      switchMap((action) =>
        this._catalogService.setCurrentFeedSlug(action.payload.feedSlug).pipe(
          map(() =>
            fromPreferencesStore.setCurrentFeedSlugSuccess({
              payload: action.payload
            })
          ),
          catchError((err: any) => {
            return of(
              fromPreferencesStore.setCurrentFeedSlugFail({
                payload: { error: err }
              })
            );
          })
        )
      )
    )
  );

  /*
  getCurrentFeedSluge$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromPreferencesStore.getCurrentFeedSlug),
      mergeMap((action) =>
        this._catalogService.getCurrentFeedSlug().pipe(
          map((res) => {
            this.feedSlug = res || 'all';
            return fromPreferencesStore.setCurrentFeedSlugSuccess({
              payload: { feedSlug: res }
            });
          }),
          tap(() => {
            this._appRouterService.goToFeedPage([this.feedSlug]);
          }),
          catchError((err: any) => {
            return of(
              fromPreferencesStore.setCurrentFeedSlugFail({
                payload: { error: err }
              })
            );
          })
        )
      )
    )
  );
*/

  addRemoveFeed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(
          fromCatalogAction.addFeedAll,
          fromCatalogAction.deleteFeedAll,
          fromCatalogAction.addFeed,
          fromCatalogAction.deleteFeed
          // fromCatalogAction.getUserFeedsSuccess
        ),
        concatMap((action) =>
          of(action).pipe(
            withLatestFrom(this._store.pipe(select(userFeeds))),
            tap(([action, feeds]) => this._appRouterFacade.goToDiscoverPage())
          )
        )
      ),
    { dispatch: false }
  );

  addFeed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromCatalogAction.addFeed),
      mergeMap((action) =>
        this._catalogService.addFeed(action.payload.feed.slug).pipe(
          map(() => {
            return fromCatalogAction.addFeedSuccess();
          }),
          catchError((err: any) => {
            return of(fromCatalogAction.addFeedFail({ payload: { error: err } }));
          })
        )
      )
    )
  );

  deleteFeed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromCatalogAction.deleteFeed),
      mergeMap((action) =>
        this._catalogService.deleteFeed(action.payload.feed.slug).pipe(
          map(() => {
            return fromCatalogAction.deleteFeedSuccess();
          }),
          catchError((err: any) => {
            return of(fromCatalogAction.deleteFeedFail({ payload: { error: err } }));
          })
        )
      )
    )
  );

  addFeedAll$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromCatalogAction.addFeedAll),
      mergeMap((action) =>
        this._catalogService.addFeedAll(action.payload.feed).pipe(
          map(() => {
            return fromCatalogAction.addFeedSuccess();
          }),
          catchError((err: any) => {
            return of(fromCatalogAction.addFeedFail({ payload: { error: err } }));
          })
        )
      )
    )
  );

  deleteFeedAll$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromCatalogAction.deleteFeedAll),
      mergeMap((action) =>
        this._catalogService.deleteFeedAll(action.payload.feed).pipe(
          map(() => {
            return fromCatalogAction.deleteFeedSuccess();
          }),
          catchError((err: any) => {
            return of(fromCatalogAction.deleteFeedFail({ payload: { error: err } }));
          })
        )
      )
    )
  );
}
