import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ArticleModalEffects } from './_store/effects';
import { reducer } from './_store/reducers';
import { ArticleApi } from './_api';
import { ArticleService } from './_services';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('article', reducer), EffectsModule.forFeature([ArticleModalEffects])],
  providers: [ArticleApi, ArticleService]
})
export class ArticleModalDataAccessModule {}
