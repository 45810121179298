import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as preferencesStore from '@purplefront/preferences/data-access';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-advanced-features-tab',
  templateUrl: './advanced-features-tab.component.html',
  styleUrls: ['./advanced-features-tab.component.scss']
})
export class AdvancedFeaturesTab implements OnInit {
  @Input() advancedFeatures;
  public advancedFeaturesForm: FormGroup;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder, private preferencesStore: Store<preferencesStore.PreferencesState>) {}

  ngOnInit() {
    this.initForm();
    this.onAdvancedFeaturesFormChange();
  }

  initForm() {
    this.advancedFeaturesForm = this.fb.group({
      machineLearning: ['']
    });

    if (this.advancedFeatures.includes('machine-learning'))
      this.advancedFeaturesForm.setValue({
        machineLearning: 'machine-learning'
      });
  }

  onAdvancedFeaturesFormChange() {
    this.advancedFeaturesForm.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        // debounceTime(0),
        tap((form) => {
          if (form.machineLearning) {
            this.preferencesStore.dispatch(
              preferencesStore.changeAdvancedFeatures({
                payload: { advanced: ['machine-learning'] }
              })
            );
          } else {
            this.preferencesStore.dispatch(
              preferencesStore.changeAdvancedFeatures({
                payload: { advanced: ['default'] }
              })
            );
          }
        })
      )
      .subscribe();
  }
}
