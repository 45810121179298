import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private _apiUrl: string = environment.purpleApi;
  public progressImage$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public progressAudio$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private _http: HttpClient) {}

  public uploadAudio(formData: FormData, isAudioBackground = false) {
    const url = isAudioBackground ? `${this._apiUrl}/audios?type=background` : `${this._apiUrl}/audios`;
    return this._http.post<any>(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public deleteUploadedFileAudio(id) {
    return this._http.delete<string>(`${this._apiUrl}/audios/${id}`);
  }

  public uploadImage(formData) {
    return this._http.post<any>(`${this._apiUrl}/images`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }
}
