import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
@Component({
  selector: 'app-audio-background-dropdown',
  templateUrl: './audio-background-dropdown.component.html',
  styleUrls: ['./audio-background-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioBackgroundDropdownComponent implements OnInit {
  @Input() selectedBackgroundValueFromForm: any;
  @Output() onAudioBackgroundValueChange: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('audioBackgroundDropdown') audioBackgroundDropdown: NgbDropdown;
  audioForm: FormGroup;
  selectedBackground: any;
  customAudio: any = {
    src: '',
    filename: ''
  };
  musics: Array<any> = [
    {
      translateKey: 'BACKGROUND_MUSIC_NONE'
    },
    { translateKey: 'BACKGROUND_MUSIC_SOFT', src: this.apiUrl('CHILL_horizon_of_hope_by_sam_joseph_delves') },
    {
      translateKey: 'BACKGROUND_MUSIC_ENERGETIC',
      src: this.apiUrl('DYNAMIC_contagious_optimism_by_jean_philippe_ichard')
    },
    { translateKey: 'BACKGROUND_MUSIC_CORPORATE', src: this.apiUrl('CORPO_building_momentum_by_remi_koudenov') },
    { translateKey: 'BACKGROUND_MUSIC_CUSTOM' }
  ];
  observableMedia: any;
  private gridByBreakpoint = { xl: 'desktop', lg: 'desktop', md: 'tablet', sm: 'tablet', xs: 'tablet' };
  gridCols = 'desktop';

  constructor(private _formBuilder: FormBuilder, private mediaObserver: MediaObserver) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.audioForm &&
      changes.selectedBackgroundValueFromForm &&
      changes.selectedBackgroundValueFromForm.currentValue
    ) {
      const defaultOption = this.findKeyByUrl(changes.selectedBackgroundValueFromForm.currentValue.data);
      this.audioForm.controls.selectedBackground.setValue(defaultOption);
      const player: any = document.querySelector('.audioPreviewBackgroundAudio');
      this.resetPlayer(player);
    }
  }

  ngOnInit() {
    this.audioForm = this._formBuilder.group({
      selectedBackground: [this.musics[0]]
    });
    this.onAudioBackgroundValueChange.emit('');
    this.audioForm.controls.selectedBackground.valueChanges.pipe(distinctUntilChanged()).subscribe((value: any) => {
      this.onAudioBackgroundValueChange.emit(value.src || '');
    });
    this.observableMedia = this.mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
      this.gridCols = this.gridByBreakpoint[changes[0].mqAlias];
    });
  }

  findKeyByUrl(src) {
    if (!src) return { translateKey: 'BACKGROUND_MUSIC_NONE' };
    const founded = this.musics.find((music) => music?.src === src);
    if (!founded) {
      return { translateKey: 'BACKGROUND_MUSIC_CUSTOM' };
    }
    return founded;
  }

  apiUrl(filename) {
    return `https://audio-assets.etx.studio/audio-backgrounds/${filename}.mp3`;
  }

  resetPlayer(player) {
    if (!player) {
      player = document.querySelector('.audioPreviewBackgroundAudio');
    }
    if (player) {
      player.pause();
      player.currentTime = 0;
      const event = document.querySelector('.backgroundAudioPlayer-animation');
      if (event) {
        event.classList.remove('paused');
      }
      if (this.audioForm.controls.selectedBackground.value.src) {
        player.src = this.audioForm.controls.selectedBackground.value.src;
      }
    }
  }

  togglePlayPause(player) {
    const event = document.querySelector('.backgroundAudioPlayer-animation');
    if (!event) return;
    if (player.paused) {
      player.play();
      event.classList.add('paused');
    } else {
      player.pause();
      event.classList.remove('paused');
    }
  }

  updateCustomBackgroundAudio(player, obj) {
    if (!player || !obj.src || !obj.filename) return;
    const src = obj.src;
    const filename = obj.filename;
    this.customAudio.src = src;
    this.audioForm.controls.selectedBackground.value.src = src;
    this.audioForm.controls.selectedBackground.value.filename = filename;
    player.src = this.audioForm.controls.selectedBackground.value.src;
    this.onAudioBackgroundValueChange.emit(src);
  }

  close() {
    this.audioBackgroundDropdown.close();
  }

  ngOnDestroy() {
    this.observableMedia.unsubscribe();
  }
}
