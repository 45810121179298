import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

import { select, Store } from '@ngrx/store';
import { tap, map, take } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { includes } from 'lodash-es';

import { AppRouterState } from '../reducers';
import { Feed } from '@purplefront/catalog/data-access';
import { AppRouterService } from '../../_services';
import { selectActiveFeeds, selectPage, selectSavedSearchId, selectSearchString } from '../selectors';
import { CatalogState } from '../../../../../../catalog/data-access/src/lib/_store/reducers';
import { userFeeds } from '../../../../../../catalog/data-access/src/lib/_store/selectors';
import * as fromPreferencesStore from '@purplefront/preferences/data-access';
import {
  goToFeed,
  goToFeedList,
  redirectToLogin,
  RouterActionsUnion,
  goToFeedPage,
  goToOrderOnDemand,
  goToBookmarksPage,
  goToAddFeedPage,
  goToPreferencesPage,
  goToSearchPage,
  goToFolderPage,
  goToDashboardPage,
  goToWorkspace,
  goToFlashBriefing,
  goToMyAudioContents,
  redirectToHome,
  goToEditor,
  goToCalendar,
  goToDiscover,
  goToArticlePage
} from '../actions';
import { selectContentLang } from '@purplefront/preferences/data-access';
import { ArticleParams, Langs } from '@purplefront/shared';

@Injectable()
export class AppRouterEffects {
  public currentFeedSlug$: Observable<string>;
  public searchString$: Observable<string>;
  public searchId$: Observable<string>;
  public page$: Observable<string>;
  public langFromUrl$: Observable<Langs>;
  public langFromPreferences$: Observable<Langs>;
  public userFeeds$: Observable<Feed[]>;
  public currentUrl: string;
  public activeFeeds: string[];

  constructor(
    private _actions$: Actions<RouterActionsUnion>,
    private _routerEffectsService: AppRouterService,
    private _router: Router,
    private _catalogStore: Store<CatalogState>,
    private _routerStore: Store<AppRouterState>,
    private _preferencesStore: Store<fromPreferencesStore.PreferencesState>,
    private _location: Location
  ) {
    this.currentUrl = this._router.url;
  }

  redirectToLogin$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(redirectToLogin),
        tap((action) => {
          const fromUrl = action.payload.fromUrl || this._location.path();
          this._routerEffectsService.goToLogin(fromUrl);
        })
      ),
    { dispatch: false }
  );

  goToArticlePage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(goToArticlePage),
        map((action) => action.payload),
        tap((payload: ArticleParams) => {
          this._routerEffectsService.goToArticlePage(payload);
        })
      ),
    { dispatch: false }
  );

  redirectToHome$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(redirectToHome),
        tap((action) => {
          this._routerEffectsService.goToHome();
        })
      ),
    { dispatch: false }
  );

  /*redirectToDefaultPath$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(sessionInitialized),
        tap((action) => {
          if (action.payload.anonymousSession) {
            this._routerEffectsService.goToLogin();
            //this._routerEffectsService.goToHome();
          }
        })
      ),
    { dispatch: false }
  );*/

  goToFeedList$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(goToFeedList),
        tap((action) => {
          const payload = action.payload;
          this._routerEffectsService.goToFeedList(payload.fromUrl, payload.keepQueryParams);
        })
      ),
    { dispatch: false }
  );

  goToFeed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(goToFeed),
        tap((action) => {
          const payload = action.payload;
          this._routerEffectsService.goToFeed(payload.toParams, payload.fromUrl, payload.keepQueryParams);
        })
      ),
    { dispatch: false }
  );

  goToFeedPage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(goToFeedPage),
        tap((action) => {
          // const page = action.payload.page;
          const currentFeedSlug = action.payload.feedSlug;
          this._catalogStore.pipe(select(selectActiveFeeds)).subscribe((feeds) => {
            this.activeFeeds = feeds;
          });
          const tag = action.payload.tag;

          this.langFromUrl$ = this._preferencesStore.pipe(select(selectContentLang));

          this.searchString$ = this._routerStore.pipe(select(selectSearchString));

          this.searchId$ = this._routerStore.pipe(select(selectSavedSearchId));

          this.userFeeds$ = this._catalogStore.pipe(select(userFeeds));

          combineLatest([this.langFromUrl$, this.searchString$, this.searchId$, this.userFeeds$])
            .pipe(take(1))
            .subscribe(([_lang, _searchString, _searchId, _userFeeds]) => {
              if (_userFeeds.length) {
                const payload = {
                  toParams: {
                    // page: page === 'add-feed' ? 'dashboard' : page,
                    feed: this.activeFeeds,
                    lang: _lang,
                    search: _searchString,
                    searchId: _searchId,
                    tag
                  },
                  fromUrl: this.currentUrl,
                  keepQueryParams: true
                };

                this._routerEffectsService.goToFeedPage(payload.toParams, payload.fromUrl, payload.keepQueryParams);
              }
            });
        })
      ),
    { dispatch: false }
  );

  goDashboardPage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(goToDashboardPage),
        tap((action) => {
          const page = action.payload.page;
          const tag = '';

          this.currentFeedSlug$ = this._preferencesStore.pipe(select(fromPreferencesStore.currentFeedSlug));
          this.langFromPreferences$ = this._preferencesStore.pipe(select(selectContentLang));
          this.langFromUrl$ = this._routerStore.pipe(select(selectContentLang));

          this.searchString$ = this._routerStore.pipe(select(selectSearchString));
          this.searchId$ = this._routerStore.pipe(select(selectSavedSearchId));
          this.userFeeds$ = this._catalogStore.pipe(select(userFeeds));

          combineLatest([
            this.currentFeedSlug$,
            this.langFromPreferences$,
            this.langFromUrl$,
            this.searchString$,
            this.searchId$,
            this.userFeeds$
          ])
            .pipe(take(1))
            .subscribe(([_currentFeedSlug, _langFromUrl, _langFromPref, _searchString, _searchId, _userFeeds]) => {
              if (_userFeeds.length) {
                const payload = {
                  toParams: {
                    feed: [this.getCurrentFeedSlug(_currentFeedSlug, _userFeeds)],
                    lang: _langFromUrl || _langFromPref,
                    search: _searchString,
                    searchId: _searchId,
                    tag
                  },
                  fromUrl: this.currentUrl,
                  keepQueryParams: true
                };

                this._routerEffectsService.goToFeedPage(payload.toParams, payload.fromUrl, payload.keepQueryParams);
              }
            });
        })
      ),
    { dispatch: false }
  );

  getCurrentFeedSlug(currentSlug, userFeeds): string {
    return currentSlug && includes(userFeeds, currentSlug) ? currentSlug : userFeeds[0].slug;
  }

  goToSearchPage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(goToSearchPage),
        tap((action) => {
          this.page$ = this._routerStore.pipe(select(selectPage));
          const tag = '';

          this.currentFeedSlug$ = this._catalogStore.pipe(select(fromPreferencesStore.currentFeedSlug));

          this.langFromUrl$ = this._routerStore.pipe(select(selectContentLang));

          this.searchId$ = this._routerStore.pipe(select(selectSavedSearchId));

          this.userFeeds$ = this._catalogStore.pipe(select(userFeeds));

          combineLatest([this.currentFeedSlug$, this.langFromUrl$, this.page$, this.searchId$, this.userFeeds$])
            .pipe(take(1))
            .subscribe(([_currentFeedSlug, _lang, _page, _searchId, _userFeeds]) => {
              if (_userFeeds.length) {
                const payload = {
                  toParams: {
                    feed: [this.getCurrentFeedSlug(_currentFeedSlug, _userFeeds)],
                    lang: _lang,
                    search: action.payload.terms,
                    searchId: _searchId,
                    tag: tag
                  },
                  fromUrl: this.currentUrl,
                  keepQueryParams: true
                };

                this._routerEffectsService.goToFeedPage(payload.toParams, payload.fromUrl, payload.keepQueryParams);
              }
            });
        })
      ),
    { dispatch: false }
  );

  goToFolderPage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(goToFolderPage),
        tap((action) => {
          this.currentFeedSlug$ = this._catalogStore.pipe(select(fromPreferencesStore.currentFeedSlug));

          this.page$ = this._routerStore.pipe(select(selectPage));

          this.langFromUrl$ = this._routerStore.pipe(select(selectContentLang));

          this.searchString$ = this._routerStore.pipe(select(selectSearchString));

          this.searchId$ = this._routerStore.pipe(select(selectSavedSearchId));

          this.userFeeds$ = this._catalogStore.pipe(select(userFeeds));

          combineLatest([
            this.currentFeedSlug$,
            this.langFromUrl$,
            this.page$,
            this.searchString$,
            this.searchId$,
            this.userFeeds$
          ])
            .pipe(take(1))
            .subscribe(([_currentFeedSlug, _lang, _page, _searchString, _searchId, _userFeeds]) => {
              if (_userFeeds.length) {
                const payload = {
                  toParams: {
                    feed: [_currentFeedSlug] || ['all'],
                    lang: _lang,
                    search: _searchString,
                    searchId: _searchId,
                    folderId: action.payload.folderId
                  },
                  fromUrl: this.currentUrl,
                  keepQueryParams: true
                };

                this._routerEffectsService.goToFolderPage(payload.toParams, payload.fromUrl, payload.keepQueryParams);
              }
            });
        })
      ),
    { dispatch: false }
  );

  goToPreferencesPage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(goToPreferencesPage),
        map((action) => action.payload),
        tap(({ path, query: queryParams, extras }) => {
          this._router.navigate(path, { queryParams, ...extras });
        })
      ),
    { dispatch: false }
  );

  goToAddFeedPage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(goToAddFeedPage),
        map((action) => action.payload),
        tap(({ path, query: queryParams, extras }) => {
          this._router.navigate(path, { queryParams, ...extras });
        })
      ),
    { dispatch: false }
  );

  goToBookmarksPage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(goToBookmarksPage),
        map((action) => action.payload),
        tap(({ path, query: queryParams, extras }) => {
          this._router.navigate(path, { queryParams, ...extras });
        })
      ),
    { dispatch: false }
  );

  goToOrderOnDemandPage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(goToOrderOnDemand),
        map((action) => action.payload),
        tap(({ path, query: queryParams, extras }) => {
          this._router.navigate(path, { queryParams, ...extras });
        })
      ),
    { dispatch: false }
  );

  goToWorkspace$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(goToWorkspace),
        map((action) => action.payload),
        tap(({ path, query: queryParams, extras }) => {
          this._router.navigate(path, { queryParams, ...extras });
        })
      ),
    { dispatch: false }
  );

  goToDiscover$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(goToDiscover),
        map((action) => action.payload),
        tap(({ path, query: queryParams, extras }) => {
          this._router.navigate(path, { queryParams, ...extras });
        })
      ),
    { dispatch: false }
  );

  goToCalendar$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(goToCalendar),
        map((action) => action.payload),
        tap(({ path, query: queryParams, extras }) => {
          this._router.navigate(path, { queryParams, ...extras });
        })
      ),
    { dispatch: false }
  );

  goToFlashBriefing$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(goToFlashBriefing),
        map((action) => action.payload),
        tap(({ path, query: queryParams, extras }) => {
          this._router.navigate(path, { queryParams, ...extras });
        })
      ),
    { dispatch: false }
  );
  goToEditor$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(goToEditor),
        map((action) => action.payload),
        tap(({ path, query: queryParams, extras }) => {
          this._router.navigate(path, { queryParams, ...extras });
        })
      ),
    { dispatch: false }
  );

  goToMyAudioContents$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(goToMyAudioContents),
        map((action) => action.payload),
        tap(({ path, query: queryParams, extras }) => {
          this._router.navigate(path, { queryParams, ...extras });
        })
      ),
    { dispatch: false }
  );
}
