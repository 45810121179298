import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuRioComponent } from './components/menu-rio/menu-rio.component';
import { SharedModule } from '@purplefront/shared';
import { EtxIconsRegistryService } from '../../../../shared/src/lib/components/etx-icons/etx-icons-registry.service';
import {
  etxIconBurger,
  etxIconSearch,
  etxIconSearchWhite
} from '../../../../shared/src/lib/components/etx-icons/etx-icons.model';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: true
    })
  ],
  declarations: [MenuRioComponent],
  exports: [MenuRioComponent]
})
export class MenuRioUiModule {
  constructor(private _etxIconsRegistry: EtxIconsRegistryService) {
    this._etxIconsRegistry.registerIcons([etxIconSearch, etxIconSearchWhite, etxIconBurger]);
  }
}
