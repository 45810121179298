import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '@purplefront/shared';
import { CalendarEvent } from '../_models';
import { InterfaceLanguageService } from '@purplefront/preferences/data-access';

@Injectable()
export class CalendarApi {
  private apiUrl: string = environment.purpleApi;

  constructor(private http: HttpClient, private interfaceLangService: InterfaceLanguageService) {}

  getCalendarEvents(params): Observable<CalendarEvent[]> {
    const options: { [key: string]: any } = {};
    if (params.startDate) {
      options['startDate'] = params.startDate;
    }
    if (params.endDate) {
      options['endDate'] = params.endDate;
    }
    if (params.searchString) {
      options['q'] = params.searchString.trim();
    }
    if (params.lang) {
      options['lang'] = this.interfaceLangService.formatLang(params.lang.trim());
    }
    if (params.size || params.size === 0) {
      options['size'] = params.size;
    }
    // add filters[feeds][] params only for others feeds
    if (params.feeds.slug.trim() !== 'all') {
      options['filters[feeds][]'] = params.feeds.slug.trim();
    }

    return this.http
      .get<CalendarEvent[]>(`${this.apiUrl}/calendar/events`, {
        params: options
      })
      .pipe(map((res) => res['data']));
  }
}
