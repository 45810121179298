import { Component, OnInit, Renderer2, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';

import { debounceTime, takeUntil } from 'rxjs/operators';

import * as fromSearchModalStore from '@purplefront/saved-search/data-access';
import * as fromRouterStore from '@purplefront/app-router/data-access';

import { Bookmark, BookmarksState, selectSearchBookmarks } from '@purplefront/bookmarks/data-access';
import { Langs } from '@purplefront/shared';
import { Feed } from '@purplefront/catalog/data-access';

@Component({
  selector: 'app-search-modal',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss']
})
export class SearchModalComponent implements OnInit, OnDestroy {
  public searchString: string;
  public searchName: string;
  public feed$: Observable<Feed>;
  public lang$: Observable<Langs>;
  public searchId$: Observable<string>;
  public searchBookmarks$: Observable<Bookmark[]>;
  public page: string;
  public lang: Langs;
  public savedSearch: Bookmark;
  public isSearchModalOpen$: Observable<boolean>;
  public currentFeedSlug: string;
  private _searchId: string;
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _renderer: Renderer2,
    private _searchModalStore: Store<fromSearchModalStore.SearchModalState>,
    private _bookmarkStore: Store<BookmarksState>,
    private _routerStore: Store<fromRouterStore.AppRouterState>
  ) {}

  ngOnInit() {
    this.searchName = null;
    /**
     * Check if search modal is open
     */

    this.isSearchModalOpen$ = this._searchModalStore.pipe(select(fromSearchModalStore.isModalOpen));

    /**
     * Get current lang (string)
     */
    this._routerStore
      .pipe(select(fromRouterStore.selectContentLang), takeUntil(this._ngUnsubscribe$))
      .subscribe((res) => {
        this.lang = res;
      });
    this.searchName = null;

    /**
     * Get current search (string)
     */
    this._routerStore
      .pipe(select(fromRouterStore.selectSearchString), takeUntil(this._ngUnsubscribe$))
      .subscribe((search) => {
        this.searchString = search;
      });

    /**
     * Current saved search id
     */
    this.searchId$ = this._routerStore.pipe(select(fromRouterStore.selectSavedSearchId));

    /**
     * Check if current search is saved & get datas
     */

    this.searchBookmarks$ = this._bookmarkStore.pipe(select(selectSearchBookmarks), takeUntil(this._ngUnsubscribe$));

    /**
     * Get current feedSlug
     */
    this._routerStore
      .pipe(takeUntil(this._ngUnsubscribe$), select(fromRouterStore.selectFeedSlug))
      .subscribe((slug) => (this.currentFeedSlug = slug));

    /**
     * Init saved search
     */
    combineLatest([this.searchBookmarks$, this.searchId$])
      .pipe(debounceTime(0), takeUntil(this._ngUnsubscribe$))
      .subscribe(([_searchBookmarks, _searchId]) => {
        this._searchId = _searchId;
        this.savedSearch = _searchBookmarks.find((_bookmark) => String(_bookmark.id) === _searchId);
        if (this.savedSearch && this.savedSearch.id) {
          this.searchName = this.savedSearch.title;
        }
      });

    /*
     * get current page
     */

    this._routerStore
      .pipe(select(fromRouterStore.selectPage), takeUntil(this._ngUnsubscribe$))
      .subscribe((page) => (this.page = page));
  }

  /**
   * Toggle search modal state (open / close)
   */

  toggleSearchModalState(isOpen) {
    if (isOpen) {
      this.closeModal();
    } else {
      this.searchName = this.savedSearch ? null : this.savedSearch.title;
      this.openModal();
    }
  }

  /**
   * Open search modal
   */

  openModal() {
    this._renderer.addClass(document.body, 'open');
    this._searchModalStore.dispatch(fromSearchModalStore.openSearchModal({ payload: { open: true } }));
  }

  /**
   * Close search modal
   */
  closeModal() {
    this._searchModalStore.dispatch(fromSearchModalStore.closeSearchModal({ payload: { open: false } }));
  }

  /**
   * Submit search
   */

  submitSearch() {
    const payload = {
      title: this.searchName,
      lang: this.lang,
      parent: this.currentFeedSlug,
      filter: this.searchString,
      persistent: false,
      type: 'search',
      context: {
        contentType: 'search',
        feed: this.currentFeedSlug,
        source: this._router.url,
        page: this.page
      },
      visible: true
    };
    this._searchModalStore.dispatch(fromSearchModalStore.saveSearchModal({ payload: payload }));
  }

  /**
   * Delete search
   */

  deleteSearch() {
    this._searchModalStore.dispatch(fromSearchModalStore.deleteSearchModal({ payload: this._searchId }));
  }

  /**
   * Prevent memory leaks
   */

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
