import { Component, OnDestroy, OnInit } from '@angular/core';
import Plyr from 'plyr';
import { select, Store } from '@ngrx/store';
import { EditorState, selectPlyrVideoSource } from '@purplefront/editor/data-access';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-modal-audio-story',
  templateUrl: './modal-audio-story.component.html',
  styleUrls: ['./modal-audio-story.component.scss']
})
export class ModalAudioStoryComponent implements OnInit, OnDestroy {
  player: Plyr;
  type: 'video';
  title: string;
  sources: Object;
  waiting$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  options: Plyr.Options = {
    autoplay: false,
    controls: ['play', 'current-time', 'progress', 'download', 'settings', 'fullscreen']
  };
  _ngUnsubscribe$ = new Subject<void>();

  constructor(private _editorStore: Store<EditorState>) {}

  ngOnInit() {
    this.getVideoSource();
  }

  getVideoSource() {
    this._editorStore.pipe(select(selectPlyrVideoSource), takeUntil(this._ngUnsubscribe$)).subscribe((videoUrl) => {
      if (videoUrl && this.player) {
        this.player.source = {
          type: 'video',
          title: 'test',
          sources: [
            {
              src: videoUrl,
              type: 'video/mp4'
            }
          ]
        };
        this.play();
      }
    });
  }

  played(event: Plyr.PlyrEvent) {}

  play(): void {
    if (this.player) {
      this.player.on('canplay', (e) => {
        this.player = e.detail.plyr;
        if (this.player) {
          (this.player.play() as any)
            .then(
              function () {},
              function () {}
            )
            .catch((error: any) => console.error(error));
        }
      });
    }
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
    this.player.destroy();
  }

  waiting($event: Plyr.PlyrEvent) {
    this.waiting$.next(true);
  }

  canPlayThrough($event: Plyr.PlyrEvent) {
    this.waiting$.next(false);
  }
}
