import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Observable, Subject } from 'rxjs';

import { Store, select } from '@ngrx/store';

import * as fromAuthStore from '@purplefront/auth/data-access';
import { TranslateService } from '@ngx-translate/core';
import { Langs, TrackingService } from '@purplefront/shared';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './forgot-password.container.html',
  styleUrls: ['./forgot-password.container.scss']
})
export class ForgotPasswordContainer implements OnInit, OnDestroy {
  public sendEmailForm: FormGroup;
  public email: string;
  private emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private interfaceLang: Langs;
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private _store: Store<fromAuthStore.AuthState>,
    private fb: FormBuilder,
    private _translate: TranslateService,
    private _trackingService: TrackingService
  ) {
    this.sendEmailForm = fb.group({
      email: [null, Validators.compose([Validators.required, Validators.pattern(this.emailRegEx)])]
    });
  }

  ngOnInit() {
    this._initLang();
  }

  public sendEmail(values): void {
    this._trackingService
      .trackEvent({ type: 'auth-forgot-password' })
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe();
    this.email = values.email;
    this._store.dispatch(fromAuthStore.sendEmail({ payload: { email: this.email } }));
  }

  /**
   * Init default interface lang
   * @private
   */
  private _initLang() {
    this.interfaceLang = <Langs>this._translate.getBrowserLang();
    if (this.interfaceLang === 'fr') {
      this._translate.use('fr');
    } else {
      this._translate.use('en');
    }
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
