export interface Theme {
  name: string;
  properties: any;
  logoPath: string;
}

export const defaultTheme: Theme = {
  name: 'defaultTheme',
  logoPath: './assets/images/logos/etx-dailyup-color-logo.png',
  properties: {
    '--base-bgcolor-relaxnews': '#5f247b',
    '--base-bgcolor-relaxnews-darkened': '#501E67',
    '--base-bgcolor-relaxnews-lightened': '#7E2FA2',
    '--base-bgcolor-relaxnews-active': '#501e67',
    '--accent-color-relaxnews': '#e6e6e6',
    '--base-color-relaxnews': '#5f247b',
    '--base-color-orange': '#f0ad4e',
    '--base-color-orange-darkened': '#EB961E',
    '--base-color-black': '#000000',
    '--base-color-grey': '#555555',
    '--base-color-grey-light': '#999999',
    '--base-color-white': '#ffffff',
    '--base-menu-bgcolor': '#ededed',
    '--base-submenu-bgcolor': '#ededed',
    '--base-hover-menu-color': '#e6e6e6',
    '--base-hover-submenu-color': '#e0e0e0',
    '--base-active-menu': '#e0e0e0',
    '--facet-color': 'hsla(281, 55%, 31%, 0.9)',
    '--facet-background-color': 'rgba(99, 24, 134, 0.09)',
    '--facet-border-color': 'rgba(220, 189, 235, 0.9)',
    '--menu-title-color': '#000000',
    '--header-element': '#fff',
    '--generate-audio-color': '#36CBA2',
    '--editor-title-input': '#160a36'
  }
};

export const defaultMobisationTheme: Theme = {
  name: 'defaultMobisationTheme',
  logoPath: './assets/images/logos/my_editorializer_logo_blanc_ss_fond.svg',
  properties: {
    '--base-bgcolor-relaxnews': '#342265',
    '--base-bgcolor-relaxnews-darkened': '#342265',
    '--base-bgcolor-relaxnews-lightened': '#342265',
    '--base-bgcolor-relaxnews-active': '#342265',
    '--accent-color-relaxnews': '#ffffff',
    '--base-color-relaxnews': '#342265',
    '--base-color-orange': '#f0ad4e',
    '--base-color-orange-darkened': '#EB961E',
    '--base-color-black': '#000000',
    '--base-color-grey': '#555555',
    '--base-color-grey-light': '#999999',
    '--base-color-white': '#ffffff',
    '--base-menu-bgcolor': '#ededed',
    '--base-submenu-bgcolor': '#ededed',
    '--base-hover-menu-color': '#e6e6e6',
    '--base-hover-submenu-color': '#e0e0e0',
    '--base-active-menu': '#e0e0e0',
    '--facet-color': 'hsla(281, 55%, 31%, 0.9)',
    '--facet-background-color': 'rgba(99, 24, 134, 0.09)',
    '--facet-border-color': 'rgba(220, 189, 235, 0.9)',
    '--menu-title-color': '#000000',
    '--header-element': '#fff',
    '--generate-audio-color': '#36CBA2',
    '--editor-title-input': '#160a36'
  }
};
