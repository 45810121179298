import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SEARCH_FEATURE_KEY, State, SearchPartialState, searchAdapter } from './search.reducer';

// Lookup the 'Search' feature state managed by NgRx
export const getSearchState = createFeatureSelector<SearchPartialState, State>(SEARCH_FEATURE_KEY);

const { selectAll, selectEntities } = searchAdapter.getSelectors();

export const getSearchLoaded = createSelector(getSearchState, (state: State) => state.loaded);

export const getSearchError = createSelector(getSearchState, (state: State) => state.error);

export const getAllSearch = createSelector(getSearchState, (state: State) => selectAll(state));

export const getSearchEntities = createSelector(getSearchState, (state: State) => selectEntities(state));

export const getSelectedId = createSelector(getSearchState, (state: State) => state.selectedId);

export const getSelected = createSelector(
  getSearchEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId]
);

export const selectSearchString = createSelector(getSearchState, (state: State) => state.searchString);

export const selectSearchResponse = createSelector(getSearchState, (state: State) => state.searchResponse);

export const selectSearchLoaded = createSelector(getSearchState, (state: State) => state.loaded);
