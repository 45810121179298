import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GeneralTab } from './components/general-tab/general-tab.component';
import { LanguageTab } from './components/language-tab/language-tab.component';
import { SocialTab } from './components/socials-tab/socials-tab.component';
import { VersionTab } from './components/version-tab/version-tab.component';
import { AdvancedFeaturesTab } from './components/advanced-features-tab/advanced-features-tab.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CguFrModalComponent } from './components/cgu-modal/cgu-fr-modal.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { CguEnModalComponent } from './components/cgu-modal/cgu-en-modal.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    GeneralTab,
    LanguageTab,
    SocialTab,
    VersionTab,
    AdvancedFeaturesTab,
    CguFrModalComponent,
    CguEnModalComponent
  ],
  exports: [
    GeneralTab,
    LanguageTab,
    SocialTab,
    VersionTab,
    AdvancedFeaturesTab,
    CguFrModalComponent,
    CguEnModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSmartModalModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ]
})
export class PreferencesUiModule {}
