import { Injectable } from '@angular/core';
import { EditorService } from '../../../../../editor/data-access/src/lib/services';

@Injectable({
  providedIn: 'root'
})
export class FlashBriefingService {
  constructor(private _editorService: EditorService) {}

  mapArticlesToPlaylistItems(articles) {
    return articles.map((article) => ({
      header: this._editorService.strip(article.header),
      title: article.title,
      id: article.id,
      publicationDate: article.publication_date,
      lang: article.lang,
      type: article.type,
      sourceType: article.object_source_type,
      mainCategory: article.mainCategory
    }));
  }
}
