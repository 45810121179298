import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UploadService } from '../../../../../../../shared/src/lib/_services/upload.service';

@Component({
  selector: 'app-progress-image',
  templateUrl: './progress-image.component.html',
  styleUrls: ['./progress-image.component.scss']
})
export class ProgressImageComponent implements OnInit {
  progressImageVal$: Observable<number>;

  constructor(private _uploadService: UploadService) {}

  ngOnInit() {
    this.progressImageVal$ = this._uploadService.progressImage$;
  }
}
