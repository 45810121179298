import { createReducer, on } from '@ngrx/store';
import { BroadcastMessage } from '../../models/broadcast-message.model';
import { closeInfoBanners, InfoBannerActions, loadInfoBannersSuccess } from '../actions';

export const infoBannerFeatureKey = 'infoBanner';

export interface InfoBannerState {
  messages: BroadcastMessage[];
  isOpen: boolean | null;
}

export const initialState: InfoBannerState = {
  messages: [],
  isOpen: true
};

export const infoBannerReducer = createReducer(
  initialState,
  on(loadInfoBannersSuccess, (state, { messages }) => {
    return {
      ...state,
      messages: messages,
      isOpen: state.isOpen !== false
    };
  }),
  on(closeInfoBanners, (state) => {
    return {
      ...state,
      isOpen: false
    };
  })
);

export function reducer(state: InfoBannerState, action: InfoBannerActions): InfoBannerState {
  return infoBannerReducer(state, action);
}
