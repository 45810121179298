import { Inject, Injectable } from '@angular/core';
import { kebabCase } from 'lodash-es';
import { WINDOW } from './window.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor(@Inject(WINDOW) private window: Window) {}

  /**
   * Define query filters according to current page
   */

  public getSearchString(searchString, folder): string {
    if (searchString && folder) {
      return `(${folder['filters']}) AND (${searchString})`;
    } else if (searchString) {
      return searchString;
    } else if (folder) {
      return folder['filters'];
    } else {
      return null;
    }
  }

  /**
   * Dispatch a resize event to recalculate the graph container
   */
  public resizeFn() {
    setTimeout((_) => {
      this.window.dispatchEvent(new Event('resize'));
    });
  }

  /**
   * Converts string to kebab case.
   * @param urlSegment
   */
  public normalizeArticleTitle(urlSegment: string): string {
    if (!urlSegment) {
      console.warn(`UrlSegment is undefined`);
      return;
    }
    return kebabCase(urlSegment);
  }
}
