import { Component, Input, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-cgu-fr-modal',
  templateUrl: './cgu-fr-modal.component.html',
  styleUrls: ['./cgu-fr-modal.component.scss']
})
export class CguFrModalComponent implements OnInit {
  constructor(private _ngxSmartModalService: NgxSmartModalService) {}

  ngOnInit(): void {}

  private closeModal() {
    this._ngxSmartModalService.close('cguFrModal');
  }
}
