export const defaultStepOptions = {
  classes: 'daily-up-guided-tour',
  scrollTo: false,
  cancelIcon: {
    enabled: true
  }
};

export const steps = [
  {
    id: 'menu-discover-step',
    selector: 'menu-discover',
    text: '',
    title: 'Welcome',
    arrow: true,
    attachTo: {
      element: '#menu-discover',
      on: 'right'
    },
    class: 'menu-discover-step',
    buttons: [
      {
        text: 'Next',
        type: 'next'
      }
    ]
  },
  {
    id: 'menu-workspace-step',
    selector: 'menu-workspace',
    text: '',
    title: 'Welcome',
    arrow: true,
    attachTo: {
      element: '#menu-workspace',
      on: 'right'
    },
    class: 'menu-workspace-step',
    buttons: [
      {
        text: 'done'
      }
    ]
  }
];
