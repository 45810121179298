import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as fromFlashBriefingStore from '@purplefront/flash-briefing/data-access';
import * as fromPlaylistStore from '@purplefront/playlist/data-access';
import { select, Store } from '@ngrx/store';
import {
  removeSelectFlashBriefing,
  toggleFlashSelectMenu
} from 'libs/flash-briefing/data-access/src/lib/store/flash-briefing.actions';
import {
  getMenuToggled,
  getSelectedArticles
} from 'libs/flash-briefing/data-access/src/lib/store/flash-briefing.selectors';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AppRouterFacadeService } from '@purplefront/app-router/data-access';
import { loadPlaylist } from '@purplefront/playlist/data-access';
import { FlashBriefingService } from '../../../../../data-access/src/lib/service/flash-briefing.service';
import { AudioPlayerService } from '../../../../../../audio-player/feature-shell/src/lib/_services';
import { Observable, Subject } from 'rxjs';
import { Langs, TrackingService } from '@purplefront/shared';
import { PreferencesState, selectContentLang } from '@purplefront/preferences/data-access';
import { map, takeUntil } from 'rxjs/operators';
import { Event, NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { CatalogState, userFeeds } from '@purplefront/catalog/data-access';
import { navigatedFromFlashBriefing } from '@purplefront/flash-briefing/data-access';

@Component({
  selector: 'rio-flash-briefing-selection',
  templateUrl: './flash-briefing-selection.component.html',
  styleUrls: ['./flash-briefing-selection.component.scss'],
  animations: [
    trigger('showHide', [
      state(
        'active',
        style({
          position: 'fixed',
          bottom: 0,
          transform: 'translateX(0%)'
        })
      ),
      state(
        'inactive',
        style({
          position: 'fixed',
          bottom: 0,
          transform: 'translateX(100%)'
        })
      ),
      transition('inactive => active', animate('0.3s ease-in')),
      transition('active => inactive', animate('0.3s ease-out'))
    ])
  ]
})
export class FlashBriefingSelectionComponent implements OnInit, OnDestroy {
  showSidebar = 'inactive';
  selectedArticles: any;
  showFeatureBox = false;
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();
  contentLang: Langs;
  userFeeds: any;
  @ViewChild('selectedArticlesMenu') selectedArticlesMenu;
  constructor(
    private _flashBriefingStore: Store<fromFlashBriefingStore.State>,
    private _playlistStore: Store<fromPlaylistStore.State>,
    private _appRouterFacade: AppRouterFacadeService,
    private _flashBriefingService: FlashBriefingService,
    private _audioPlayerService: AudioPlayerService,
    private _preferencesStore: Store<PreferencesState>,
    private _router: Router,
    private elementRef: ElementRef,
    private _trackingService: TrackingService,
    private _catalogStore: Store<CatalogState>
  ) {}

  /**
   * Close menu when click outside
   */

  clickedInsideFeatureBox($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  @HostListener('document:click', ['$event'])
  clickedOutsideFeatureBox() {
    this.showFeatureBox = false;
  }

  ngOnInit(): void {
    this.getUserFeeds();
    this.getMenuToggledStatus();
    this.getSelectedArticles();
    this.getContentLang();
    this._router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEventAndCLoseFlashBriefingSelection(routerEvent);
    });
  }
  getUserFeeds() {
    this._catalogStore
      .pipe(
        select(userFeeds),
        takeUntil(this._ngUnsubscribe$),
        map((feeds) => (this.userFeeds = feeds))
      )
      .subscribe();
  }

  getContentLang() {
    this._preferencesStore.pipe(select(selectContentLang), takeUntil(this._ngUnsubscribe$)).subscribe((lang) => {
      this.contentLang = lang;
    });
  }

  closeSidebar() {
    this._flashBriefingStore.dispatch(toggleFlashSelectMenu());
  }

  getSelectedArticles() {
    this._flashBriefingStore
      .pipe(select(getSelectedArticles), takeUntil(this._ngUnsubscribe$))
      .subscribe((articles: any) => {
        this.selectedArticles = articles;
      });
  }

  getMenuToggledStatus() {
    this._flashBriefingStore
      .pipe(select(getMenuToggled), takeUntil(this._ngUnsubscribe$))
      .subscribe((menuToggled: boolean) => {
        menuToggled === true ? (this.showSidebar = 'active') : (this.showSidebar = 'inactive');
      });
  }

  goToEditor() {
    this._flashBriefingStore.dispatch(navigatedFromFlashBriefing({ navigationFromFlashBriefing: true }));
    this.trackEvent('panel-add-to-editor');
    this.closeSidebar();
    this._appRouterFacade.goToEditor(this.contentLang);
  }

  removeArticleFromSelection(id: string) {
    this.trackEvent('panel-delete-selected-article');
    this._flashBriefingStore.dispatch(removeSelectFlashBriefing({ id }));
  }

  addToPlaylist() {
    this.trackEvent('panel-add-to-playlist');
    const playlist = this._flashBriefingService.mapArticlesToPlaylistItems(this.selectedArticles);
    this.toggleFeatureBox();
    this.closeSidebar();
    this._playlistStore.dispatch(loadPlaylist({ articles: playlist }));
    this._audioPlayerService.status$.next(true);
  }

  toggleFeatureBox() {
    this.showFeatureBox = !this.showFeatureBox;
  }

  checkRouterEventAndCLoseFlashBriefingSelection(routerEvent: Event): void {
    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.showSidebar = 'inactive';
    }
  }
  trackEvent(type: string) {
    const payload = {
      feeds: this.userFeedSlugs,
      lang: this.selectedArticles[0].lang
    };
    const value = this.articleTitles.join();
    this._trackingService
      .trackEvent({ type: type, value: value, payload: payload })
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe();
  }
  get userFeedSlugs() {
    return this.userFeeds.map((feed) => feed.slug);
  }

  get articleTitles() {
    return this.selectedArticles.map((article) => article.title);
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }

  shareArticles() {
    this.trackEvent('panel-add-to-socialmedia');
  }

  bookmarkArticles() {
    this.trackEvent('panel-add-to-bookmark');
  }
}
