import { Bookmark } from '../../_models';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import { BookmarksActionsUnion } from '../actions';
import { createReducer, on } from '@ngrx/store';
import * as bookmarkActions from '../actions';

export interface BookmarksState extends EntityState<Bookmark> {
  allBookmarksLoaded: boolean;
  isLoadingBookmarks: boolean;
  error: any | null;
}

export const adapter: EntityAdapter<Bookmark> = createEntityAdapter<Bookmark>();

export const initialBookmarksState = adapter.getInitialState({
  allBookmarksLoaded: false,
  isLoadingBookmarks: false,
  error: null
});
const bookmarkReducer = createReducer(
  initialBookmarksState,
  on(bookmarkActions.loadBookmarks, (state, action) => {
    return {
      ...state,
      isLoadingBookmarks: true
    };
  }),
  on(bookmarkActions.loadBookmarksSuccess, (state, action) => {
    return adapter.setAll(action.payload.bookmarks, {
      ...state,
      isLoadingBookmarks: false
    });
  }),
  on(bookmarkActions.loadBookmarksFailure, (state, action) => {
    return {
      ...state,
      isLoadingBookmarks: false
    };
  }),
  on(bookmarkActions.addBookmark, (state, action) => {
    return {
      ...state,
      isLoadingBookmarks: true
    };
  }),
  on(bookmarkActions.addBookmarkSuccess, (state, action) => {
    return adapter.addOne(action.payload, {
      ...state,
      isLoadingBookmarks: false
    });
  }),
  on(bookmarkActions.addBookmarkFailure, (state, action) => {
    return { ...state, isLoadingBookmarks: false, error: action.payload };
  }),
  on(bookmarkActions.removeBookmark, (state, action) => {
    return adapter.removeOne(action.payload, {
      ...state,
      isLoadingBookmarks: true
    });
  }),
  on(bookmarkActions.removeBookmarkSuccess, (state, action) => {
    return {
      ...state,
      isLoadingBookmarks: false
    };
  }),
  on(bookmarkActions.removeBookmarkFail, (state, action) => {
    return { ...state, isLoadingBookmarks: false, error: action.payload };
  }),
  on(bookmarkActions.removeAllBookmark, (state, action) => {
    return adapter.removeAll(state);
  })
);

export function reducer(state: BookmarksState, action: BookmarksActionsUnion) {
  return bookmarkReducer(state, action);
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
