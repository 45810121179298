import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentFiltersContainer } from './containers/content-filters.container/content-filters.container';
import { ContentFiltersUiModule } from '@purplefront/content-filters/ui';

@NgModule({
  imports: [CommonModule, ContentFiltersUiModule],
  declarations: [ContentFiltersContainer],
  exports: [ContentFiltersContainer]
})
export class ContentFiltersFeatureShellModule {}
