import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { of as observableOf } from 'rxjs';
import { mergeMap, map, catchError, switchMap } from 'rxjs/operators';

import { BookmarksApi } from '../../_api';
import {
  removeAllBookmarkSuccess,
  removeAllBookmarkFail,
  addBookmarkSuccess,
  removeBookmarkSuccess,
  loadBookmarksSuccess,
  loadBookmarksFailure,
  BookmarksActionsUnion
} from '../actions';
import * as bookmarkActions from '../actions';

@Injectable()
export class BookmarksEffects {
  constructor(private _actions$: Actions<BookmarksActionsUnion>, private _bookmarksServices: BookmarksApi) {}

  loadBookmark$ = createEffect(() =>
    this._actions$.pipe(
      ofType(bookmarkActions.loadBookmarks),
      switchMap((action) =>
        this._bookmarksServices.findBookmarksBySlug(action.payload.bookmarkType).pipe(
          map((bookmarks) => loadBookmarksSuccess({ payload: { bookmarks } })),
          catchError((err: any) => {
            return observableOf(loadBookmarksFailure({ payload: { error: err } }));
          })
        )
      )
    )
  );

  addBookmark$ = createEffect(() =>
    this._actions$.pipe(
      ofType(bookmarkActions.addBookmark),
      map((bookmark) => addBookmarkSuccess({ payload: bookmark }))
    )
  );

  removeBookmark$ = createEffect(() =>
    this._actions$.pipe(
      ofType(bookmarkActions.removeBookmark),
      map(() => removeBookmarkSuccess())
    )
  );

  removeAllBookmark$ = createEffect(() =>
    this._actions$.pipe(
      ofType(bookmarkActions.removeAllBookmark),
      mergeMap(() =>
        this._bookmarksServices.removeAllBookmark().pipe(
          map(() => {
            // TODO handle notification
            return removeAllBookmarkSuccess();
          }),
          catchError((err: any) => {
            return observableOf(removeAllBookmarkFail({ payload: { error: err } }));
          })
        )
      )
    )
  );
}
