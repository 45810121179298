import { AfterViewInit, Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { Voice } from '@purplefront/editor/data-access';
import { EditorService } from '../../../../../data-access/src/lib/services';

@Component({
  selector: 'app-voice-modal',
  templateUrl: './voice-modal.component.html',
  styleUrls: ['./voice-modal.component.scss']
})
export class VoiceModalComponent implements OnInit {
  @Input() voices: Voice[];
  @Input() selectedText: any;
  @Input() parentNode: any;
  @Input() selectedItem: any;
  @Output() formattedSsmlText$ = new EventEmitter();
  @Output() listenVoicePreview$ = new EventEmitter();
  private selectedVoice: Voice;

  constructor(private _editorService: EditorService) {}

  ngOnInit(): void {}

  setSelectedVoice(voice: Voice, $event): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.selectedVoice = voice;
  }

  listenPronunciation(): void {
    this.listenVoicePreview$.emit({ text: this.selectedText.textContent, voice: this.selectedVoice });
  }

  addVoiceToText(): void {
    const result = this.textToSsml(this.selectedText);
    this.formattedSsmlText$.emit({
      pronunciationEvent: result
    });
  }

  textToSsml(initialSelectedText: string) {
    const articleText = this.replaceHtmlEntities(this.selectedItem.value.articleText);
    const ssml = `<voice name="${this.selectedVoice.id}">${initialSelectedText}</voice>&zwnj;`;

    if (this.parentNode.nodeName === 'VOICE') {
      if (this.parentNode.innerText !== initialSelectedText) {
        const removedSSML = this.removeSSML();
        const replacedText = removedSSML.formattedArticleText;
        return {
          formattedArticleText: VoiceModalComponent.replaceOccurrences(replacedText, initialSelectedText, ssml),
          initialSelectedText,
          ssml,
          elem: { type: 'voice', id: this.selectedVoice.id }
        };
      }
      this.parentNode.setAttribute('name', this.selectedVoice.id);
      return {
        formattedArticleText: this.parentNode.parentNode.innerHTML,
        initialSelectedText,
        ssml: null,
        elem: { type: 'voice', id: this.selectedVoice.id }
      };
    }

    return {
      formattedArticleText: VoiceModalComponent.replaceOccurrences(articleText, initialSelectedText, ssml),
      initialSelectedText,
      ssml,
      elem: { type: 'voice', id: this.selectedVoice.id }
    };
  }

  private static replaceOccurrences(articleText: string, initialSelectedText: string, ssml: string): string {
    return articleText.replaceAll(initialSelectedText, ssml);
  }

  private replaceHtmlEntities(str: string): string {
    return this._editorService.replaceHtmlEntities(str);
  }

  removeSSML() {
    //check if parentNode.innerText is the same as selectedText, if it is, delete name attribute
    const articleText = this.replaceHtmlEntities(this.selectedItem.value.articleText);
    const voiceId = this.parentNode.getAttribute('name');
    const ssml = `</voice>${this.selectedText}<voice name="${voiceId}">`;
    let replacedText = '';
    if (this.parentNode.innerHTML === this.selectedText) {
      replacedText = VoiceModalComponent.replaceOccurrences(
        articleText,
        this.parentNode.outerHTML,
        this.parentNode.innerHTML
      );
    } else {
      replacedText = VoiceModalComponent.replaceOccurrences(articleText, this.selectedText, ssml);
    }

    return {
      formattedArticleText: replacedText,
      initialSelectedText: this.selectedText,
      ssml: ssml
    };
  }

  resetVoiceChange() {
    const voiceChangeEvent = this.removeSSML();
    this.formattedSsmlText$.emit({
      pronunciationEvent: voiceChangeEvent
    });
  }
}
