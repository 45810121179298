import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AudioStory, StoryItem } from '../../_models';
import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import {
  addAudioStory,
  addAudioStoryFailure,
  AudioStoryActions,
  updateAudioStory,
  updateAudioStoryFailure,
  updateAudioStorySuccess,
  loadAudioStories,
  loadAudioStoriesFailure,
  loadAudioStoriesSuccess,
  playPauseAudio,
  removeAllAudioStories,
  removeAllAudioStoriesFail,
  removeAllAudioStoriesSuccess,
  removeAudioStory,
  removeAudioStoryFail,
  removeAudioStorySuccess,
  removeMultipleAudioStories,
  removeMultipleAudioStoriesFail,
  removeMultipleAudioStoriesSuccess,
  setCurrentAudioStory,
  setCurrentStoryItem,
  generateAudioStoryItem,
  generateAudioStoryItemSuccess,
  openAudioStoryOverlay,
  resetGeneratedUrls,
  generateAudioFormatSuccess,
  generateAudioFormat,
  generateMobileFormatSuccess,
  generateSquareFormatSuccess,
  generateSquareFormat,
  generateMobileFormat,
  addAudioStorySuccess,
  cloneAudioStory,
  cloneAudioStorySuccess,
  cloneAudioStoryFail,
  setMobileRenderStatus,
  setSquareRenderStatus,
  resetAudiostoriesEntities,
  generateAudioFormatFail,
  resetGenerationError,
  generateLandscapeFormat,
  generateLandscapeFormatSuccess,
  generateAudioStoryItemFail
} from '../actions';

export interface AudioStoryState extends EntityState<AudioStory> {
  isLoading: boolean;
  error: any | null;
  audioStories: [];
  playing: boolean;
  generatedStoryItemUrl: string | null;
  currentStoryItem: StoryItem;
  currentAudioStory: AudioStory;
  openAudioStoryOverlay: boolean;
  audioStatus: number;
  audioFormatUrls: any;
  squareFormatStatus: any;
  squareFormatUrls: any;
  mobileFormatStatus: any;
  mobileFormatUrls: any;
  landscapeFormatStatus: any;
  landscapeFormatUrls: any;
  currentGeneratedArticleId: any;
  isAudioStoryPageUpdated: boolean;
  lastPage: number;
  totalAudioStories;
  generationLoading: boolean;
  generationLoaded: boolean;
  generationError: any;
  voicePreviewError: any;
}

export function sortByDate(a, b) {
  return <any>new Date(b.updatedAt) - <any>new Date(a.updatedAt);
}
export const adapter: EntityAdapter<AudioStory> = createEntityAdapter<AudioStory>({
  sortComparer: sortByDate
});

export const initialAudioStoryState = adapter.getInitialState({
  isLoading: false,
  error: null,
  playing: false,
  audioStories: [],
  generatedStoryItemUrl: null,
  currentStoryItem: null,
  currentAudioStory: null,
  openAudioStoryOverlay: false,
  audioStatus: 0,
  audioFormatUrls: null,
  squareFormatStatus: 0,
  squareFormatUrls: null,
  mobileFormatStatus: 0,
  mobileFormatUrls: null,
  landscapeFormatStatus: 0,
  landscapeFormatUrls: null,
  currentGeneratedArticleId: null,
  isAudioStoryPageUpdated: true,
  lastPage: null,
  totalAudioStories: null,
  generationLoading: false,
  generationLoaded: false,
  generationError: null,
  voicePreviewError: null
});

const reducer = createReducer(
  initialAudioStoryState,
  on(loadAudioStories, (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(loadAudioStoriesSuccess, (state, action) => {
    return adapter.addMany(action.payload.audioStories, {
      ...state,
      isLoading: false,
      isAudioStoryPageUpdated: true,
      lastPage: action.payload.lastPage,
      totalAudioStories: action.payload.total
    });
  }),
  on(loadAudioStoriesFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: action.payload.error
    };
  }),
  on(resetAudiostoriesEntities, (state) => {
    return adapter.removeAll(state);
  }),
  on(addAudioStory, (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(addAudioStorySuccess, (state, action) => {
    return adapter.addOne(action.payload, {
      ...state,
      isLoading: false
    });
  }),
  on(addAudioStoryFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: action.payload.error
    };
  }),
  on(cloneAudioStory, (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(cloneAudioStorySuccess, (state, action) => {
    return adapter.addOne(action.payload, {
      ...state,
      isLoading: false,
      totalAudioStories: state.totalAudioStories + 1
    });
  }),
  on(cloneAudioStoryFail, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: action.payload.error
    };
  }),
  on(removeAllAudioStories, (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(removeAllAudioStoriesSuccess, (state) => {
    return adapter.removeAll(state);
  }),
  on(removeAllAudioStoriesFail, (state, action) => {
    return {
      ...state,
      error: action.payload.error
    };
  }),
  on(removeMultipleAudioStories, (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(removeMultipleAudioStoriesSuccess, (state, action) => {
    return adapter.removeMany(action.payload, {
      ...state,
      isLoading: false
    });
  }),
  on(removeMultipleAudioStoriesFail, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: action.payload.error
    };
  }),
  on(removeAudioStory, (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(removeAudioStorySuccess, (state, action) => {
    return adapter.removeOne(action.payload, {
      ...state,
      isLoading: false,
      totalAudioStories: state.totalAudioStories - 1
    });
  }),
  on(removeAudioStoryFail, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: action.payload.error
    };
  }),
  on(updateAudioStory, (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(updateAudioStorySuccess, (state, action) => {
    return adapter.updateOne(action.payload, {
      ...state,
      isLoading: false
    });
  }),
  on(updateAudioStoryFailure, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: action.payload.error
    };
  }),

  on(generateAudioStoryItem, (state, action) => {
    return {
      ...state,
      isLoading: true,
      currentGeneratedArticleId: action.payload.articleId,
      generationLoading: true,
      generationLoaded: false
    };
  }),
  on(generateAudioStoryItemSuccess, (state, action) => {
    return {
      ...state,
      generatedStoryItemUrl: action.payload.url,
      isLoading: false,
      currentGeneratedArticleId: action.payload.articleId,
      generationLoading: false,
      generationLoaded: true
    };
  }),
  on(playPauseAudio, (state, action) => {
    return {
      ...state,
      playing: action.payload.playing
    };
  }),
  on(setCurrentStoryItem, (state, action) => {
    return {
      ...state,
      currentStoryItem: action.payload
    };
  }),
  on(setCurrentAudioStory, (state, action) => {
    return {
      ...state,
      currentAudioStory: action.payload
    };
  }),
  on(openAudioStoryOverlay, (state, action) => {
    return {
      ...state,
      openAudioStoryOverlay: action.payload.open
    };
  }),
  on(resetGeneratedUrls, (state) => {
    return {
      ...state,
      audioFormatUrls: null,
      squareFormatUrls: null,
      mobileFormatUrls: null,
      audioStatus: 0,
      squareFormatStatus: 0,
      mobileFormatStatus: 0,
      generationLoading: false,
      generationLoaded: false
    };
  }),
  on(resetGenerationError, (state) => {
    return {
      ...state,
      generationError: null
    };
  }),
  on(generateAudioFormat, (state, action) => {
    return {
      ...state,
      audioStatus: 25,
      generationLoading: true,
      generationLoaded: false
    };
  }),
  on(generateAudioFormatSuccess, (state, action) => {
    return {
      ...state,
      audioFormatUrls: {
        download: action.payload.url_attachment,
        stream: action.payload.url_stream
      },
      audioStatus: 100,
      isAudioStoryPageUpdated: false,
      generationLoading: false,
      generationLoaded: true
    };
  }),
  on(generateAudioFormatFail, (state, action) => {
    return {
      ...state,
      generationError: action.error,
      generationLoading: false,
      generationLoaded: false
    };
  }),
  on(setMobileRenderStatus, (state, action) => {
    return {
      ...state,
      generationLoading: true,
      generationLoaded: false
    };
  }),
  on(setSquareRenderStatus, (state, action) => {
    return {
      ...state,
      generationLoading: true,
      generationLoaded: false
    };
  }),
  on(generateMobileFormat, (state, action) => {
    return {
      ...state,
      mobileFormatStatus: 25,
      generationLoading: true,
      generationLoaded: false
    };
  }),
  on(generateMobileFormatSuccess, (state, action) => {
    return {
      ...state,
      mobileFormatUrls: {
        download: action.payload.url_attachment,
        stream: action.payload.url_stream
      },
      mobileFormatStatus: 100,
      isAudioStoryPageUpdated: false,
      generationLoading: false,
      generationLoaded: true
    };
  }),
  on(generateSquareFormat, (state, action) => {
    return {
      ...state,
      squareFormatStatus: 25,
      generationLoading: true,
      generationLoaded: false
    };
  }),
  on(generateSquareFormatSuccess, (state, action) => {
    return {
      ...state,
      squareFormatUrls: {
        download: action.payload.url_attachment,
        stream: action.payload.url_stream
      },
      squareFormatStatus: 100,
      isAudioStoryPageUpdated: false,
      generationLoading: false,
      generationLoaded: true
    };
  }),
  on(generateLandscapeFormat, (state, action) => {
    return {
      ...state,
      landscapeFormatStatus: 25,
      generationLoading: true,
      generationLoaded: false
    };
  }),
  on(generateLandscapeFormatSuccess, (state, action) => {
    return {
      ...state,
      landscapeFormatUrls: {
        download: action.payload.url_attachment,
        stream: action.payload.url_stream
      },
      landscapeFormatStatus: 100,
      isAudioStoryPageUpdated: false,
      generationLoading: false,
      generationLoaded: true
    };
  }),
  on(generateAudioStoryItemFail, (state, action) => {
    return {
      ...state,
      voicePreviewError: action.payload.error
    };
  })
);

export function audioStoryReducer(state: AudioStoryState, action: AudioStoryActions) {
  return reducer(state, action);
}
export const selectAudioStoryState = createFeatureSelector<AudioStoryState>('audioStory');
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors(selectAudioStoryState);
