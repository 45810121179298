import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { environment } from '@purplefront/shared';
import { Catalog, Feed } from '../_models';

@Injectable()
export class CatalogApi {
  private apiUrl: string = environment.purpleApi;
  constructor(private http: HttpClient) {}

  static feedsMapping(feed: any): Feed {
    return {
      id: feed.id,
      slug: feed.slug,
      possibleSlugs: [feed.slug],
      backendFeeds: [feed.slug],
      name: feed.name,
      isActive: false
    };
  }

  static catalogMapping(catalog: any): Catalog {
    return {
      id: catalog.id,
      slug: catalog.slug,
      possibleSlugs: [catalog.slug],
      label: catalog.name,
      objectType: catalog.object_type,
      type: catalog.type,
      feeds: catalog.feeds
    };
  }

  formatCatalogFeeds(catalogs: Catalog[]): any {
    return catalogs.map((catalog) => catalog.feeds.map((feed) => CatalogApi.feedsMapping(feed)));
  }

  getCatalogs(): Observable<Catalog[]> {
    return this.http.get<Catalog>(`${this.apiUrl}/catalogs/`).pipe(
      map((res: any) => {
        return res.data.map((catalogData) => CatalogApi.catalogMapping(catalogData));
      }),
      catchError((error: any) => throwError(error))
    );
  }

  getFolders(lang, feed): Observable<any> {
    const params = {
      lang: lang
    };
    // TODO: investigate impact of leaving this up
    // add filters[feeds][] params only for other feed
    // if (feed !== 'all') {
    //   params['filters[feeds][]'] = feed;
    // }
    return this.http.get(`${this.apiUrl}/content/active-folders`, {
      params: params
    });
  }

  addFeed(feedSlug: string): Observable<any> {
    this.getUserFeeds();
    return this.http.post<string>(`${this.apiUrl}/feeds/usercatalog`, {
      feed: feedSlug
    });
  }

  addFeedAll(feeds: Feed[]): Observable<any> {
    this.getUserFeeds();
    const arrayOfFeedSlug = feeds
      .filter((feed) => feed.slug !== 'all')
      .map((f) => {
        return {
          feed: f.slug
        };
      });

    return this.http.post<string>(`${this.apiUrl}/feeds/usercatalog`, {
      feeds: arrayOfFeedSlug
    });
  }

  deleteFeedAll(feeds: Feed[]): Observable<any> {
    this.getUserFeeds();
    const arrayOfFeedSlug = feeds
      .filter((feed) => feed.slug !== 'all')
      .map((f) => {
        return {
          feed: f.slug
        };
      });

    return this.http.post<string>(`${this.apiUrl}/feeds/usercatalog/deleteFeeds`, {
      feeds: arrayOfFeedSlug
    });
  }

  deleteFeed(feedId: any): Observable<any> {
    return this.http.delete<string>(`${this.apiUrl}/feeds/usercatalog/${feedId}`);
  }

  getUserFeeds(): Observable<any> {
    return this.http.get(`${this.apiUrl}/feeds/usercatalog`).pipe(
      map((res: any) => res.data.map((_feeds) => CatalogApi.feedsMapping(_feeds))),
      catchError((error: any) => throwError(error))
    );
  }
}
