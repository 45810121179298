import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { BaseUrlService } from './../_services/base-url.service';
import { takeUntil } from 'rxjs/operators';
import { AudioPlayerService } from '../../../../audio-player/feature-shell/src/lib/_services';
import { Subject } from 'rxjs';

@Directive({
  selector: '[layoutContainer]'
})
export class LayoutDirective implements OnInit, OnDestroy {
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    private _renderer: Renderer2,
    private _el: ElementRef,
    private _baseUrlService: BaseUrlService,
    private _audioPlayerService: AudioPlayerService
  ) {}

  ngOnInit(): void {
    if (this._baseUrlService.isMobisationApp()) {
      //this._renderer.setStyle(this._el.nativeElement, 'margin-left', '7rem');
      this._renderer.setStyle(this._el.nativeElement, 'margin-top', '0rem');
    }
    this.getAudioPlayerStatus();
  }
  getAudioPlayerStatus(): void {
    const audioPlayerHeight = '3.25rem';
    this._audioPlayerService.status$
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe((isAudioPlayerToggled: boolean) => {
        isAudioPlayerToggled && this.setMarginBottom(audioPlayerHeight);
      });
  }

  setMarginBottom(remValue: string): void {
    this._renderer.setStyle(this._el.nativeElement, 'margin-bottom', remValue);
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
