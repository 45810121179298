import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

import * as fromCatalogStore from '../_store';
import { Catalog, Feed } from '../_models';
import { CatalogApi } from '../_api';
import { feeds } from '../_store/selectors/catalog.selectors';
import { switchMap } from 'rxjs/operators';
import { PreferencesApi } from '@purplefront/preferences/data-access';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  constructor(
    private catalogStore: Store<fromCatalogStore.CatalogState>,
    private router: Router,
    private api: CatalogApi,
    private preferencesService: PreferencesApi
  ) {}
  private currentCatalog$ = new BehaviorSubject<Catalog | null>(null);
  private currentFeed$ = new BehaviorSubject<Feed | null>(null);

  /**
   * Check if feed should be displayed or not
   * @param userFeeds
   * @param feedSlug
   */
  public isVisible(userFeeds: Feed[], feedSlug: string): boolean {
    if (feedSlug === 'all') {
      return userFeeds.length > 2;
    } else {
      return true;
    }
  }

  public getCatalogs(): Observable<Catalog[]> {
    return this.api.getCatalogs();
  }

  public getFolders(lang: string, feed: string): Observable<any> {
    return this.api.getFolders(lang, feed);
  }

  public getUserFeeds(): Observable<any> {
    return this.api.getUserFeeds();
  }

  public addFeed(feedId): Observable<any> {
    return this.api.addFeed(feedId);
  }

  public addFeedAll(feed: Feed): Observable<any> {
    return this.catalogStore.pipe(
      select(feeds),
      switchMap((_feeds) => this.api.addFeedAll(_feeds))
    );
  }

  public deleteFeedAll(feed: Feed): Observable<any> {
    return this.catalogStore.pipe(
      select(feeds),
      switchMap((_feeds) => this.api.deleteFeedAll(_feeds))
    );
  }

  public deleteFeed(feedId): Observable<any> {
    return this.api.deleteFeed(feedId);
  }

  public setCurrentFeedSlug(feedSlug): Observable<any> {
    return this.preferencesService.setCurrentSlug(feedSlug);
  }

  public goToFeed(feed: Feed, toFeedRoot: boolean = false): void {
    const catalog = this.currentCatalog$.getValue();
    const currentFeed = this.currentFeed$.getValue();
    const currentUrl = this.router.url;

    if (catalog === null) {
      // TODO
    } else if (toFeedRoot || currentFeed === null) {
      // on va à la racine du feed
      const newUrl = `/catalog/${catalog.slug}/select/feed/${feed.slug}`;
      this.router.navigate([newUrl]);
    } else if (currentFeed.id !== feed.id) {
      // on garde la même page, mais sur un feed différent
      const newUrl = currentUrl.replace(/\/feed\/[^\/]*/, '/feed/' + feed.slug);
      this.router.navigate([newUrl]);
    } else {
      // La navigation renvoie sur place, on ne fait rien.
    }
  }

  public goToPage(feed: Feed, page: any): void {
    const currentUrl = this.router.url;
    const newUrl = currentUrl.replace(/\/feed\/[^\/]*\/[^\/]*/, `/feed/${feed.slug}/${page.slug}`);
    // TODO: voir pb de navigation avec les queryparams
    this.router.navigate([newUrl]);
  }
}
