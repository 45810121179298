import { Component, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { requiredFileType } from '../file-upload/required-file-validators';
import { UploadService } from '../../../../../../shared/src/lib/_services/upload.service';
import { filter, map, tap } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { Store } from '@ngrx/store';
import { deleteUploadedFileAudio, EditorState, uploadFileAudioSuccess } from '@purplefront/editor/data-access';
import { fileSize } from '../file-upload/file-size-validators';

export function uploadProgress<T>(cb: (progress: number) => void) {
  return tap((event: HttpEvent<T>) => {
    if (event.type === HttpEventType.UploadProgress) {
      cb(Math.round((100 * event.loaded) / event.total));
    }
  });
}

export function markAllAsDirty(form: FormGroup) {
  for (const control of Object.keys(form.controls)) {
    form.controls[control].markAsDirty();
  }
}

export function toFormData<T>(formValue: T) {
  const formData = new FormData();
  for (const key of Object.keys(formValue)) {
    const value = formValue[key];
    formData.append(key, value);
  }
  return formData;
}

@Component({
  selector: 'app-form-upload',
  templateUrl: './form-upload.component.html',
  styleUrls: ['./form-upload.component.scss']
})
export class FormUploadComponent {
  @Input() isAudioStoryBackground: boolean;
  @Input() selectedItem: any;
  @Output() url: EventEmitter<any> = new EventEmitter();
  label = 'EDITOR_DRAG_DROP_AUDIO_CONTENT';
  availableFormats = ['mp3', 'wav'];
  maxFileSize = 25 * 1024 * 1024;
  progress = 0;
  success = false;
  formUpload = new FormGroup({
    audio: new FormControl(null, [
      Validators.required,
      requiredFileType(this.availableFormats),
      fileSize(this.maxFileSize)
    ])
  });

  constructor(private http: HttpClient, private _uploadService: UploadService, private _store: Store<EditorState>) {}

  toResponseBody<T>() {
    return pipe(
      filter((event: HttpEvent<T>) => event.type === HttpEventType.Response),
      map((res: HttpResponse<T>) => {
        if (!this.isAudioStoryBackground) {
          this._store.dispatch(uploadFileAudioSuccess({ payload: { body: res.body } }));
        }
        return res.body;
      })
    );
  }
  submit() {
    this.success = false;
    if (!this.formUpload.valid) {
      markAllAsDirty(this.formUpload);
      return;
    }

    this._uploadService
      .uploadAudio(toFormData(this.formUpload.value), this.isAudioStoryBackground)
      .pipe(
        uploadProgress((progress) => {
          this.progress = progress;
          this._uploadService.progressAudio$.next(progress);
        }),
        this.toResponseBody()
      )
      .subscribe((event) => {
        this.progress = 0;
        this._uploadService.progressAudio$.next(this.progress);
        this.success = true;
        const filename = this.formUpload.value.audio.name.split('.')[0];
        this.formUpload.reset();
        if (event?.data.path && filename) {
          const url = {
            src: event.data.path,
            filename
          };
          this.url.emit(url);
        }
      });
  }

  deleteAudio(id) {
    if (id) {
      this._store.dispatch(deleteUploadedFileAudio({ payload: { id } }));
    }
    this.success = false;
    this.progress = 0;
    this._uploadService.progressAudio$.next(this.progress);
    this.formUpload.reset();
  }

  hasError(field: string, error: string) {
    const control = this.formUpload.get(field);
    return control.dirty && control.hasError(error);
  }
}
