import { createAction, props, union } from '@ngrx/store';
import { CalendarEvent } from '../../_models';

/*
 * load agenda events
 * */

export enum CalendarActionTypes {
  LOAD_CALENDAR_EVENTS = '[Calendar] Load Calendar Events',
  LOAD_CALENDAR_EVENTS_FAIL = '[Calendar] Load Calendar Events Fail',
  LOAD_CALENDAR_EVENTS_SUCCESS = '[Calendar] Load Calendar Events Success'
}

export const loadCalendarEvents = createAction(
  CalendarActionTypes.LOAD_CALENDAR_EVENTS,
  props<{ payload: { params: any } }>()
);

export const loadCalendarEventsFail = createAction(
  CalendarActionTypes.LOAD_CALENDAR_EVENTS_FAIL,
  props<{ payload: { error: any } }>()
);

export const loadCalendarEventsSuccess = createAction(
  CalendarActionTypes.LOAD_CALENDAR_EVENTS_SUCCESS,
  props<{ payload: { calendarEvents: CalendarEvent[] } }>()
);

const all = union({
  loadCalendarEvents,
  loadCalendarEventsFail,
  loadCalendarEventsSuccess
});

export type CalendarActionUnion = typeof all;
