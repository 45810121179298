import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClient } from '@angular/common/http';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import * as fromArticleModalStore from '@purplefront/article-modal/data-access';
import { ArticleService, ArticleApi, ArticleModalDataAccessModule } from '@purplefront/article-modal/data-access';
import { ArticleModalUiModule } from '@purplefront/article-modal/ui';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    ArticleModalUiModule,
    ArticleModalDataAccessModule,
    StoreModule.forFeature('articleModal', fromArticleModalStore.reducer),
    EffectsModule.forFeature([fromArticleModalStore.ArticleModalEffects])
  ],
  providers: [ArticleService, ArticleApi]
})
export class ArticleModalFeatureShellModule {}
