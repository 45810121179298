import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AppRouterState, selectAudioStoryId } from '@purplefront/app-router/data-access';
import { AudioStoryApi } from '../../../../../audio-story/data-access/src/lib/_api';
import { tap } from 'rxjs/operators';
import { loadCurrentAudioStorySuccess } from '../../../../../editor/data-access/src/lib/_store/actions';
import { EditorState } from '../../../../../editor/data-access/src/lib/_store/reducers';

@Injectable({
  providedIn: 'root'
})
export class AudioStoryResolverService implements Resolve<any> {
  private audioStoryId: string;

  constructor(
    private store: Store<AppRouterState>,
    private _audioStoryApi: AudioStoryApi,
    private _editorStore: Store<EditorState>,
    private _router: Router
  ) {
    store.pipe(select(selectAudioStoryId)).subscribe((audioStoryId) => (this.audioStoryId = audioStoryId));
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    if (this.audioStoryId) {
      this._audioStoryApi
        .getAudioStory(this.audioStoryId)
        .pipe(
          tap((res) => {
            this._editorStore.dispatch(loadCurrentAudioStorySuccess({ payload: res }));
          })
        )
        .subscribe();
    }
    return null;
  }
}
