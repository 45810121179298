import * as actions from '../actions/preferences.actions';
import { createReducer, on } from '@ngrx/store';
import { Langs } from '@purplefront/shared';

export interface PreferencesState {
  currentPage: string;
  contentLang: Langs;
  interfaceLang: Langs;
  radarTimeRange: string;
  readerDisplayMode: string;
  afpRelaxDisplayMode: string;
  currentFeedSlug: string;
  isLoading: boolean;
  error: any;
  cookieBanner: boolean;
  features: string[];
  userPref: any;
  viewModes: string;
  sortDates: string;
  contentTypes: string[];
  frequencies: string;
  seasons: string[];
  sources: string;
  filterMenuToggle: boolean;
  mobileMenuToggle: boolean;
  lastSelectedVoice: any;
}

export const preferencesInitialState: PreferencesState = {
  currentPage: 'general',
  contentLang: 'fr',
  interfaceLang: 'fr',
  radarTimeRange: '48h',
  readerDisplayMode: 'magazine',
  afpRelaxDisplayMode: 'magazine',
  currentFeedSlug: 'beauty',
  isLoading: false,
  error: undefined,
  cookieBanner: true,
  features: [''],
  userPref: null,
  viewModes: 'magazine',
  sortDates: 'date',
  contentTypes: [],
  frequencies: null,
  seasons: [],
  sources: 'All',
  filterMenuToggle: false,
  mobileMenuToggle: false,
  lastSelectedVoice: null
};

const preferencesReducer = createReducer(
  preferencesInitialState,
  on(actions.loadUserPreferences, (state, action) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(actions.loadUserPreferencesFail, (state, action) => {
    return {
      ...state,
      isLoading: false,
      error: action.payload.error
    };
  }),
  on(actions.loadUserPreferencesSuccess, (state, action) => {
    return {
      ...state,
      isLoading: false,
      userPref: action.payload,
      interfaceLang: action.payload.languages.interface,
      contentLang: action.payload.languages.content,
      viewModes:
        action.payload.advanced && action.payload.advanced.filters
          ? action.payload.advanced.filters['view-mode']
          : 'magazine',
      sortDates:
        action.payload.advanced && action.payload.advanced.filters ? action.payload.advanced.filters.sortBy : 'date',
      // contentTypes: filters.content,
      // frequencies: filters.frequency,
      // seasons: filters.season,
      sources:
        action?.payload?.advanced?.filters?.source ? action.payload.advanced.filters.source : 'All'
    };
  }),

  on(actions.setCurrentPage, (state, action) => {
    return {
      ...state,
      currentPage: action.payload || 'general'
    };
  }),
  on(actions.changeInterfaceLang, (state, action) => {
    return {
      ...state,
      interfaceLang: action.payload.interfaceLang
    };
  }),
  on(actions.changeContentLang, (state, action) => {
    return {
      ...state,
      contentLang: action.payload.contentLang
    };
  }),
  on(actions.changeContentLangFail, (state, action) => {
    return {
      ...state
    };
  }),

  on(actions.changeAdvancedFeatures, (state, action) => {
    return {
      ...state,
      features: action.payload.advanced.features,
      isLoading: true
    };
  }),
  on(actions.changeAdvancedFeaturesSuccess, (state) => {
    return { ...state, isLoading: false };
  }),
  on(actions.changeAdvancedFeaturesFail, (state, action) => {
    return { ...state, error: action.payload.error, isLoading: true };
  }),
  on(actions.setCookieBannerSuccess, (state) => {
    return { ...state, cookieBanner: true };
  }),

  on(actions.setCurrentFeed, (state, action) => {
    const feedSlug: string = action.payload.feed.slug;
    return {
      ...state,
      latestFeedSlug: feedSlug,
      currentFeedSlug: feedSlug
    };
  }),
  on(actions.setCurrentFeedSlugSuccess, (state, action) => {
    const feedSlug: string = action.payload.feedSlug;
    return {
      ...state,
      latestFeedSlug: feedSlug,
      currentFeedSlug: feedSlug
    };
  }),
  on(actions.unsetCurrentFeed, (state, action) => {
    return {
      ...state,
      currentFeedSlug: null
    };
  }),

  on(actions.setFilterViewMode, (state, action) => {
    return {
      ...state,
      viewModes: action.payload.value
    };
  }),

  on(actions.setFilterContentType, (state, action) => {
    return {
      ...state,
      contentTypes: action.payload.value
    };
  }),

  on(actions.setFilterFrequency, (state, action) => {
    return {
      ...state,
      frequencies: action.payload.value
    };
  }),

  on(actions.setFilterSeason, (state, action) => {
    return {
      ...state,
      seasons: action.payload.value
    };
  }),

  on(actions.setFilterSortBy, (state, action) => {
    return {
      ...state,
      sortDates: action.payload.value
    };
  }),

  on(actions.setFilterSource, (state, action) => {
    return {
      ...state,
      sources: action.payload.value
    };
  }),
  on(actions.setLastSelectedVoiceSuccess, (state, action) => {
    return {
      ...state,
      lastSelectedVoice: action.payload
    };
  }),
  on(actions.toggleFilterMenu, (state) => ({ ...state, filterMenuToggle: !state.filterMenuToggle })),
  on(actions.toggleMobileMenu, (state, action) => ({ ...state, mobileMenuToggle: action.payload.open }))
);

export function reducer(state: PreferencesState, action: actions.PreferencesActionsUnion) {
  return preferencesReducer(state, action);
}
