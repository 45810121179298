<div class="import-audio">
  <form *ngIf="formUpload" [formGroup]="formUpload" (ngSubmit)="submit()" class="col-sm-12 text-center w-100 h-100">
    <span
      *ngIf="file || selectedItemImageUrl"
      (click)="deleteImage()"
      title="delete image"
      class="edit-upload-img-delete"
      ><i class="fa fa-times" aria-hidden="true"></i
    ></span>
    <div
      class="form-group mb-0 position-relative w-100 h-100 img-upload-form"
      [ngStyle]="{ border: selectedItem?.value?.images?.length ? '0' : 'solid 4px #eb7061' }"
    >
      <div
        class="file-drop-area"
        [ngStyle]="{
          position: selectedItem?.value?.images?.length ? 'absolute' : 'static',
          height: selectedItem?.value?.images?.length ? '100%' : 'inherit'
        }"
      >
        <span *ngIf="!selectedItemImageUrl" class="file-icon" style="margin: 0">
          <app-etx-icons name="upload_image"></app-etx-icons>
        </span>
        <!--        <span *ngIf="!selectedItemImageUrl" class="file-title" [ngClass]="{ 'text-ellipsis': file?.name?.length > 25 }"-->
        <!--          >{{ file ? file.name : (label | translate) }}-->
        <!--        </span>-->
        <label
          *ngIf="selectedItemImageUrl"
          (click)="openMultiImageModal($event)"
          for="image-upload-2"
          class="edit-upload-img"
          ><i class="fa fa-pencil"></i
        ></label>
        <img
          class="m-0 img-upload"
          *ngIf="selectedItemImageUrl"
          src="{{ selectedItemImageUrl }}"
          alt=""
          width="150"
          height="150"
          style="position: absolute"
        />
        <span class="file-support" *ngIf="!selectedItemImageUrl">Support: {{ fileFormat }}</span>
        <input
          multiple
          (drop)="onImageDrop($event)"
          (click)="onClick($event)"
          class="file-input edit"
          type="file"
          id="image-upload-2"
        />
        <div *ngIf="selectedItemHasMultipleImages()" class="image-counter">
          <span class="image-counter-text">{{ selectedItem.value.images.length }}</span>
          <img src="../assets/images/pictos/multi-image-icon.png" />
        </div>
      </div>
      <app-progress-image></app-progress-image>

      <div class="invalid-feedback" style="display: block">
        <ng-container *ngIf="hasError('images', 'requiredFileType')">{{
          'EDITOR_UPLOAD_IMG_ERROR_MESSAGE' | translate
        }}</ng-container>
      </div>
    </div>
  </form>
</div>
<div *ngIf="selectedItem.value?.images?.length < 8" class="images-upload-text"> 
  <!-- selectedItemImageUrl -->
  <label for="image-upload" style="margin: 0.5rem 0"
    ><span class="image-upload-decoration"
      >+ {{ 'ADD_MORE_IMAGES' | translate }}</span
    ></label
  >
  <input
    multiple
    (click)="onAddMoreImagesClick($event)"
    (drop)="onImageDrop($event)"
    [ngStyle]="{ visibility: selectedItem?.value?.images?.length ? 'hidden' : 'visible' }"
    type="file"
    id="image-upload"
    class="file-input"
  />
</div>
