import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Plyr from 'plyr';
import { Store } from '@ngrx/store';
import { EditorState } from '@purplefront/editor/data-access';

@Injectable({
  providedIn: 'root'
})
export class AudioPlayerService {
  public status$ = new BehaviorSubject(false);
  public player: Plyr; // plyrInit event
  public options: Plyr.Options = {
    controls: ['play', 'current-time', 'progress', 'duration', 'download', 'volume']
  };

  constructor(private _store: Store<EditorState>) {}

  mapAudioSource(src) {
    return [
      {
        src,
        type: 'audio/mp3'
      }
    ];
  }

  mapAudioFile(article: any) {
    return {
      type: 'audio',
      title: article.title,
      sources: [
        {
          src: article.audiosource,
          type: article.audiotype
        }
      ]
    };
  }
}
