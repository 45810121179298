import { Injectable } from '@angular/core';
import { Router, CanActivate, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { find } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, filter, tap, switchMap, first, debounceTime, take } from 'rxjs/operators';

import { CatalogService } from '../_services';

import { Feed } from '../_models';
import { select, Store } from '@ngrx/store';

import * as fromCatalogStore from '../_store';
import * as fromPreferencesStore from '@purplefront/preferences/data-access';
import { BaseUrlService } from '@purplefront/shared';

@Injectable()
export class FeedResolver implements CanActivate, Resolve<Feed> {
  constructor(
    private router: Router,
    private catalogService: CatalogService,
    private catalogStore: Store<fromCatalogStore.CatalogState>,
    private _preferencesStore: Store<fromPreferencesStore.PreferencesState>,
    private _baseUrlService: BaseUrlService
  ) {}

  static inSameHierarchy(nextUrl: string): boolean {
    const hierarchyPattern = /^\/catalog\/[^\/]+/;
    return hierarchyPattern.test(nextUrl);
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const feedSlug = route.params['feedSlug'];
    const feed$ = this.fetchFeed(feedSlug);

    return feed$.pipe(
      first(),
      tap((feed: Feed | null) => {
        feed !== null ? this.setCurrentFeed(feed) : this.navigateToFeed404(state);
      }),
      map((feed: Feed | null) => feed !== null)
    );
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Feed> {
    const feedSlug = route.params['feedSlug'];
    const feed$ = this.fetchFeed(feedSlug);

    return feed$.pipe(
      first(),
      filter((feed: Feed | null) => feed !== null)
    );
  }

  private fetchFeed(feedSlug: string): Observable<Feed | null> {
    const catalogs$ = this.catalogStore.pipe(select(fromCatalogStore.catalogs));
    const feeds$ = this._baseUrlService.isEditorializerApp()
      ? this.getUserFeedsFromStoreOrAPI()
      : this.getFeedsFromStoreOrAPI();

    return catalogs$.pipe(
      filter((_catalog) => _catalog !== null),
      switchMap(() => feeds$),
      debounceTime(0),
      map((feeds: Feed[]) => find(feeds, (f: Feed) => f.possibleSlugs.includes(feedSlug)) || null)
    );
  }

  private getUserFeedsFromStoreOrAPI(): Observable<any> {
    return this.catalogStore.pipe(
      select(fromCatalogStore.userFeeds),
      tap((feeds: any) => {
        if (!feeds.length) {
          this.catalogStore.dispatch(fromCatalogStore.getUserFeeds());
        }
      }),
      filter((feeds) => feeds.length),
      take(1)
    );
  }

  private getFeedsFromStoreOrAPI(): Observable<any> {
    return this.catalogStore.pipe(
      select(fromCatalogStore.feeds),
      filter((feeds: any) => feeds.length),
      take(1)
    );
  }

  private setCurrentFeed(feed: Feed): void {
    this.catalogStore.dispatch(
      fromPreferencesStore.setCurrentFeed({
        payload: {
          feed: feed
        }
      })
    );
  }

  private navigateToFeed404(state: RouterStateSnapshot): void {
    const newUrl = state.url.replace(/\/feed\//, '/feed-not-found/');
    this.router.navigate([newUrl]);
  }
}
