<div *ngIf="isSearchModalOpen$ | async as isOpen" class="search-modal">
  <div class="modal-container">
    <div class="modal-header">
      <button type="button" class="close-btn" aria-label="Close" (click)="toggleSearchModalState(isOpen)">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="search-title">
        <span><i class="fa fa-search"></i></span>
        <h4>{{ 'SAVE_YOUR_SEARCH' | translate }}</h4>
      </div>
    </div>
    <div class="modal-body">
      <form #formControl="ngForm">
        <div class="form-group">
          <label for="search-name">{{ 'SEARCH_NAME' | translate }}</label>
          <input
            [(ngModel)]="searchName"
            id="search-name"
            class="form-control"
            placeholder="{{ 'ENTER_A_NAME_FOR_THE_SEARCH' | translate }}"
            name="search-name"
            required
          />
          <label for="search-description">{{ 'REQUEST_DESCRIPTION' | translate }}</label>
          <input
            [(ngModel)]="searchString"
            id="search-description"
            class="form-control"
            placeholder=""
            name="search-description"
            required
          />
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn delete-btn"
        [disabled]="!formControl.form.valid || !savedSearch"
        (click)="deleteSearch()"
      >
        {{ 'DELETE' | translate }}
      </button>
      <button type="button" class="btn save-btn" [disabled]="!formControl.form.valid" (click)="submitSearch()">
        {{ 'SAVE' | translate }}
      </button>
    </div>
  </div>
</div>
