<div [hidden]="!displayToaster">
  <ngb-toast
    *ngFor="let toast of toasterService.toasts"
    [header]="toast.header | translate"
    [autohide]="true"
    [delay]="(toast.delay | async) || 8000"
    (hidden)="toasterService.remove(toast)"
    >{{ toast.body | translate }}
    <span (click)="goToAudioStories()" class="creation-link">{{ 'CREATIONS' | translate }}</span></ngb-toast
  >
</div>
