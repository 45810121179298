import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaylistComponent } from './components/playlist/playlist.component';
import { PlaylistMenuComponent } from './components/playlist-menu/playlist-menu.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { EtxIconsRegistryService } from '../../../../shared/src/lib/components/etx-icons/etx-icons-registry.service';
import {
  etxIconClose,
  etxIconClosePurple,
  etxIconCloseWhite,
  etxIconPlaylist,
  etxIconPlaylistWhite
} from '../../../../shared/src/lib/components/etx-icons/etx-icons.model';
import { SharedModule } from '@purplefront/shared';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, NgbDropdownModule, SharedModule, TranslateModule],
  declarations: [PlaylistComponent, PlaylistMenuComponent],
  exports: [PlaylistComponent, PlaylistMenuComponent]
})
export class PlaylistUiModule {
  constructor(private _etxIconsRegistry: EtxIconsRegistryService) {
    this._etxIconsRegistry.registerIcons([
      etxIconPlaylistWhite,
      etxIconPlaylist,
      etxIconClosePurple,
      etxIconCloseWhite,
      etxIconClose
    ]);
  }
}
