<ngx-smart-modal identifier="auth-nagging-modal" #authNaggingModal>
  <div
    class="auth-nagging-modal flex flex-column align-items-center justify-content-center p-4 text-center"
    tabindex="-1"
    role="dialog"
  >
    <div class="naggin-body">
      <h1>{{ 'AUTH_SIGN_UP_TO_ACCESS_THE_ENTIRE_PLATFORM' | translate }}</h1>
      <p class="text-start">
        {{ 'AUTH_NAGGING_SCREEN_TEXT' | translate }}
      </p>
    </div>
    <div class="naggin-footer mt-2">
      <button (click)="authNaggingModal.close(); redirectToLogin()">
        {{ 'AUTH_REGISTER_FOR_FREE' | translate }}
      </button>
      <span
        >{{ 'AUTH_ALREADY_HAVE_AN_ACCOUNT' | translate }}
        <a (click)="authNaggingModal.close(); redirectToLogin()">{{ 'AUTH_LOGIN_HERE' | translate }}</a></span
      >
    </div>
  </div>
</ngx-smart-modal>
