import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { map, filter, switchMap, debounceTime, take } from 'rxjs/operators';

import * as fromCatalogStore from '../_store';

import { CatalogService } from '../_services';

import { Catalog } from '../_models';

@Injectable()
export class CatalogResolver implements Resolve<Catalog[]> {
  constructor(
    private router: Router,
    private catalogService: CatalogService,
    private catalogStore: Store<fromCatalogStore.CatalogState>
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Catalog[]> {
    const initialized$ = this.catalogStore.pipe(select(fromCatalogStore.initialized));
    const catalogs$ = this.catalogStore.pipe(select(fromCatalogStore.catalogs));

    return initialized$.pipe(
      filter((initialized) => initialized === true),
      switchMap(() => catalogs$),
      debounceTime(0),
      map((_catalogs: Catalog[]) => (_catalogs.length ? _catalogs : null)),
      take(1)
    );
  }
}
