import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import * as fromAuthStore from '@purplefront/auth/data-access';
import { Langs, TrackingService } from '@purplefront/shared';
import { AuthService } from '@purplefront/auth/data-access';
import { takeUntil } from 'rxjs/operators';
import { InterfaceLanguageService } from '@purplefront/preferences/data-access';
import { Location } from '@angular/common';

@Component({
  selector: 'app-registration-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './registration.container.html',
  styleUrls: ['./registration.container.scss']
})
export class RegistrationContainer implements OnInit, OnDestroy, AfterViewInit {
  public registerForm: FormGroup;
  public registration: any;
  public first_name: string;
  public last_name: string;
  public email: string;
  public password: any;
  public password_confirmation: any;
  public company: string;
  public title: string;
  public phone: number;
  public practice = 'media';
  public agreedWithTOS: boolean;
  public agreedWithTONS: boolean;
  private utm_campaign: string;
  private utm_source: string;
  private utm_medium: string;
  private utm_content: string;
  private utm_term: string;

  private phoneRegEx = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
  private emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  public errorMessage$: Observable<any>;
  loading$: Observable<boolean>;
  interfaceLang: Langs;
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private _authStore: Store<fromAuthStore.AuthState>,
    private _trackingService: TrackingService,
    private _translate: TranslateService,
    private _authService: AuthService,
    private _interfaceLangService: InterfaceLanguageService,
    private _location: Location
  ) {
    this.registerForm = fb.group({
      first_name: [null, Validators.compose([Validators.required, Validators.minLength(2)])],
      last_name: [null, Validators.compose([Validators.required, Validators.minLength(2)])],
      email: [null, Validators.compose([Validators.required, Validators.pattern(this.emailRegEx)])],
      password: [null, Validators.compose([Validators.required, Validators.minLength(8)])],
      password_confirmation: [
        null,
        Validators.compose([Validators.required, Validators.minLength(8), this._authService.MatchPassword])
      ],
      company: [null, Validators.compose([Validators.required, Validators.minLength(2)])],
      title: [null, Validators.compose([Validators.required, Validators.minLength(2)])],
      practice: [null],
      phone: [null, Validators.compose([Validators.required, Validators.pattern(this.phoneRegEx)])],
      agreedWithTOS: [null, Validators.requiredTrue],
      agreedWithTONS: [null]
    });
  }

  ngOnInit() {
    this._initLang();
    this.errorMessage$ = this._authStore.pipe(select(fromAuthStore.getErrorMessage));
    this.loading$ = this._authStore.pipe(select(fromAuthStore.selectLoadingRegister));
  }

  ngAfterViewInit(): void {
    this.getUTMData();
  }

  getUTMData() {
    this.utm_campaign = this.getQueryParamsFromMalformedURL('utm_campaign');
    this.utm_source = this.getQueryParamsFromMalformedURL('utm_source');
    this.utm_medium = this.getQueryParamsFromMalformedURL('utm_medium');
    this.utm_content = this.getQueryParamsFromMalformedURL('utm_content');
    this.utm_term = this.getQueryParamsFromMalformedURL('utm_term');
  }

  getQueryParamsFromMalformedURL(name: string) {
    const path = this._location.path();
    const decodedURI = decodeURIComponent(path);
    const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(decodedURI);
    if (!results) {
      return null;
    }
    return results[1] || null;
  }

  addRegistration(registration): void {
    this._trackingService
      .trackEvent({ type: 'auth-sign-up', value: registration.email })
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe();
    this.first_name = registration.first_name;
    this.last_name = registration.last_name;
    this.email = registration.email;
    this.password = registration.password;
    this.password_confirmation = registration.password_confirmation;
    this.company = registration.company;
    this.title = registration.title;
    this.phone = registration.phone;
    this.practice = registration.practice;
    this.agreedWithTOS = registration.agreedWithTOS;
    this.agreedWithTONS = registration.agreedWithTONS;

    this._authStore.dispatch(
      fromAuthStore.register({
        payload: {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          company: this.company,
          title: this.title,
          phone: this.phone,
          practice: this.practice || 'media',
          realm: 'relax',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          language: this._interfaceLangService.getBrowserLang(),
          utm_campaign: this.utm_campaign,
          utm_source: this.utm_source,
          utm_content: this.utm_content,
          utm_medium: this.utm_medium,
          utm_term: this.utm_term
        }
      })
    );
  }

  /**
   * Init default interface lang
   * @private
   */
  private _initLang() {
    const lang: Langs = <Langs>this._translate.getBrowserLang();
    if (lang === 'fr') {
      this.interfaceLang = 'fr';
      this._translate.use('fr');
    } else {
      this.interfaceLang = 'en';
      this._translate.use('en');
    }
  }
  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
