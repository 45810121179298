import { Component, OnDestroy, OnInit } from '@angular/core';
import { Langs } from '@purplefront/shared';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { activateRegistrationCode, AuthState, selectActivationCodeStatus } from '@purplefront/auth/data-access';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-auth-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit, OnDestroy {
  queryState$: Observable<string>;
  private _initialStatus = 'waiting';
  private _interfaceLang: string;
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    private _translate: TranslateService,
    private _route: ActivatedRoute,
    private _authStore: Store<AuthState>
  ) {}

  ngOnInit(): void {
    this._initLang();
    this._checkActivationCode();
    this._initQueryState();
  }
  /**
   * Init default interface lang
   * @private
   */
  private _initLang() {
    const lang: Langs = <Langs>this._translate.getBrowserLang();
    if (lang === 'fr') {
      this._interfaceLang = 'fr';
      this._translate.use(this._interfaceLang);
    } else {
      this._interfaceLang = 'en';
      this._translate.use(this._interfaceLang);
    }
  }

  private _checkActivationCode() {
    this._route.queryParamMap.pipe(takeUntil(this._ngUnsubscribe$)).subscribe((routeData) => {
      const code = routeData['params'].activation_code;
      if (code) {
        this._authStore.dispatch(activateRegistrationCode({ payload: code }));
      }
    });
  }

  private _initQueryState() {
    this.queryState$ = this._authStore.pipe(select(selectActivationCodeStatus));
  }

  ngOnDestroy() {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
