import { Component, OnDestroy, OnInit } from '@angular/core';
import { Langs } from '@purplefront/shared';
import { Router } from '@angular/router';
import { PreferencesState, selectContentLang } from '@purplefront/preferences/data-access';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss']
})
export class Page404Component implements OnInit, OnDestroy {
  contentLang: Langs;
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private _router: Router, private _prefStore: Store<PreferencesState>) {}

  ngOnInit(): void {
    this._prefStore.pipe(select(selectContentLang), takeUntil(this._ngUnsubscribe$)).subscribe((lang) => {
      this.contentLang = lang;
    });
  }

  goToDiscover() {
    this._router.navigate(['discover', this.contentLang], { queryParamsHandling: 'merge' });
  }

  ngOnDestroy() {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
