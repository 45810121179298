<div title="{{ 'SOUND_DESIGN' | translate }}" ngbDropdown #audioBackgroundDropdown="ngbDropdown" placement="left-bottom left left-top" autoClose="outside">
  <i class="fa fa-lg fa-music cursor-pointer" id="audioBackgroundDropdown" style="width: 100%" ngbDropdownToggle></i>

  <div
    ngbDropdownMenu
    aria-labelledby="audioBackgroundDropdown"
    ngStyle.gt-md="width: 100% !important; width: 320px; left: -160px; top: 40px; border-radius: 10px"
    ngStyle.lt-lg="width: 100% !important; width: 320px; left: -220px; top: 40px; border-radius: 10px"
  >
    <div *ngIf="audioForm" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start start" class="mt-4 ms-4 me-4 mb-3">
      <app-etx-icons name="close" class="audio-story-close" (click)="audioBackgroundDropdown.close()"> </app-etx-icons>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" style="width: 100%">
        <h4 class="">
          {{ 'SOUND_DESIGN' | translate }}
        </h4>
        <button
          type="button"
          *ngIf="audioForm.controls.selectedBackground.value.src"
          class="backgroundAudioPlayer"
          [matTooltip]="'PREVIEW_AUDIO' | translate"
          (click)="togglePlayPause(audioPreview)"
        >
          <span #animation class="backgroundAudioPlayer-animation"></span>
        </button>
      </div>
      <form [formGroup]="audioForm" style="width: 100%">
        <mat-radio-group
          fxLayout="column"
          formControlName="selectedBackground"
          aria-labelledby="example-radio-group-label"
          class="audio-background-radio-group"
          (change)="resetPlayer(audioPreview)"
        >
          <mat-radio-button
            class="radio-button"
            *ngFor="let music of musics"
            [value]="music"
            [checked]="
              music.translateKey === audioForm.controls.selectedBackground.value.translateKey ||
              music.translateKey === 'BACKGROUND_MUSIC_NONE'
            "
          >
            {{ music.translateKey | translate }}
          </mat-radio-button>
          <div
            *ngIf="audioForm.controls.selectedBackground.value.translateKey === 'BACKGROUND_MUSIC_CUSTOM'"
            style="margin-top: 10px"
          >
            <app-form-upload
              data-toggle="tooltip"
              data-placement="right"
              title="{{ 'DRAG_AND_DROP_FILE' | translate }}"
              isAudioStoryBackground="true"
              (url)="updateCustomBackgroundAudio(audioPreview, $event)"
            ></app-form-upload>
          </div>
        </mat-radio-group>
        <audio #audioPreview class="audioPreviewBackgroundAudio" preload="none"></audio>
      </form>
    </div>
  </div>
</div>
