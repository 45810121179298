<div ngbDropdown #formatDropdown="ngbDropdown" autoClose="outside">
  <button type="button" class="btn select-label" id="formatDropdown" style="width: 100%" ngbDropdownToggle>
    <span *ngIf="!selectedFormats.length">{{ 'EDITOR_CHOOSE_FORMAT' | translate }}</span>
    <span *ngIf="selectedFormats.length"
      >{{ selectedFormats.length }}
      <span>{{
        selectedFormats.length > 1 ? ('NB_SELECTED_FORMATS' | translate) : ('NB_SELECTED_FORMAT' | translate)
      }}</span>
    </span>
  </button>
  <ul ngbDropdownMenu aria-labelledby="formatDropdown" style="width: 100% !important">
    <li *ngFor="let format of formats" ngbDropdownItem (click)="addToSelectedFormats(format)">
      <span [ngClass]="{ 'selected-item': isFormatSelected(format) }" class="dropdown-item">
        {{ format.label | translate }}
      </span>
    </li>
  </ul>
</div>
