<div class="tab">
  <div class="tab-title">
    <h3>{{ 'SOCIAL_NETWORKS' | translate }}</h3>
    <p>{{ 'REGISTER_OR_REMOVE_FACEBOOK_OR_TWITTER' | translate }}</p>
  </div>
  <div class="tab-content">
    <ul>
      <li>
        <h4>{{ 'TWITTER_ACCOUNT' | translate }}</h4>
        <button>
          <span class="social-icon"><i class="fa fa-twitter"></i></span>
          <span class="add-account">{{ 'ADD_A_TWITTER_ACCOUNT' | translate }}</span>
        </button>
      </li>
      <li>
        <h4>{{ 'FACEBOOK_ACCOUNT' | translate }}</h4>
        <button>
          <span class="social-icon"><i class="fa fa-facebook"></i></span>
          <span class="add-account">{{ 'ADD_A_FACEBOOK_ACCOUNT' | translate }}</span>
        </button>
      </li>
      <li>
        <h4>{{ 'TUMBLR_ACCOUNT' | translate }}</h4>
        <button>
          <span class="social-icon"><i class="fa fa-tumblr"></i></span>
          <span class="add-account">{{ 'ADD_A_TUMBLR_ACCOUNT' | translate }}</span>
        </button>
      </li>
    </ul>
  </div>
  <div class="tab-footer">
    <button class="save-button">{{ 'SAVE' | translate }}</button>
  </div>
</div>
