<form
  [@showHide]="showFilterMenu"
  [formGroup]="filtersForm"
  (ngSubmit)="submit()"
  class="filter-container d-flex flex-column"
>
  <div class="container d-flex flex-column">
    <div class="top">
      <div class="col">
        <div class="row mt-5 mb-4 d-flex justify-content-center">
          <div class="col">
            <h3><img src="assets/images/pictos/icon-filter.png" />{{ 'REFINE_SEARCH_RESULTS' | translate }}</h3>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center">
        <div class="col-10">
          <h6>{{ 'DISCOVER_FILTER_CONTENT_VIEW' | translate }}</h6>
          <div class="content-filter-view">
            <select formControlName="viewModes" class="selectpicker form-control" data-style="btn-info">
              <option *ngFor="let view of viewModesData; let i = index" [value]="viewModesData[i].name">
                {{ viewModesData[i].name }}
              </option>
            </select>
          </div>

          <ng-container *ngIf="search">
            <h6>{{ 'DISCOVER_FILTER_FILTER_BY' | translate }}</h6>
            <div class="content-filter-view pb-4 border-bottom">
              <select formControlName="sortDates" class="selectpicker form-control" data-style="btn-info">
                <option *ngFor="let sort of sortDatesData; let i = index" [value]="sortDatesData[i].name">
                  {{ sortDatesData[i].name }}
                </option>
              </select>
            </div>
          </ng-container>

          <!-- <h6 class="mt-4">Filtrer par date</h6>
          <div class="content-filter-view">
            <img src="assets/images/filter/fake-calendar.png" alt="" width="" />
          </div>-->

          <!-- <h6>Filtrer par type de contenu</h6>

          <div class="content-filter-view-bloc">
            <div class="btn-group btn-group-toggle" *ngFor="let type of contentTypesFormArray.controls; let i = index">
              <label
                formArrayName="contentTypes"
                class="btn content-filter-btn"
                ngbButtonLabel
                [ngClass]="{ active: type.value === contentTypesData[i].name }"
              >
                <input type="checkbox" [formControlName]="i" ngbButton />
                {{ contentTypesData[i].name }}
              </label>
            </div>
          </div>-->

          <!--<h6>Filtrer par fréquence</h6>
          <div class="content-filter-view">
            <select formControlName="frequencies" class="selectpicker form-control" data-style="btn-info">
              <option *ngFor="let frequency of frequenciesData; let i = index" [value]="frequenciesData[i].name">
                {{ frequenciesData[i].name }}
              </option>
            </select>
          </div>-->

          <!-- <h6>Filtrer par saison</h6>
          <div class="content-filter-view">
            <label
              formArrayName="seasons"
              ngbButtonLabel
              *ngFor="let season of seasonsFormArray.controls; let i = index"
            >
              <input type="checkbox" [checked]="season.value === seasonsData[i].name" [formControlName]="i" ngbButton />
              {{ seasonsData[i].name }}
            </label>
          </div>-->
          <ng-container *ngIf="isUserFromEtxStudio()">
            <h6>{{ 'DISCOVER_FILTER_FILTER_BY' | translate }} source</h6>
            <div class="content-filter-view">
              <select formControlName="sources" class="selectpicker form-control" data-style="btn-info">
                <option *ngFor="let source of sourcesData; let i = index" [value]="sourcesData[i].name">
                  {{ sourcesData[i].label }}
                </option>
              </select>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="close-sidebar-btn" (click)="toggleFilterMenu()">
        <span class="fa-stack fa-lg">
          <i class="fa fa-circle fa-stack-2x icon-background"></i>
          <i class="fa fa-arrow-right fa-stack-1x" aria-hidden="true"></i>
        </span>
      </div>
      <div class="flash-footer d-flex justify-content-center">
        <div class="row align-items-center">
          <!--<div class="col-6">
            <button type="button" (click)="reset()" class="btn filter-btn btn-sm btn-reset">Réinitialiser</button>
          </div>-->
          <div class="col-6">
            <button (click)="toggleFilterMenu()" type="button" class="btn filter-btn btn-sm btn-affiner">
              {{ 'DISCOVER_FILTER_REFINE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
