import * as actions from '../actions/search-modal.actions';
import { createReducer, on } from '@ngrx/store';

export interface SearchModalState {
  name: string;
  description: string;
  open: boolean;
}

export const initialState: SearchModalState = {
  name: null,
  description: null,
  open: false
};

const searchModalReducer = createReducer(
  initialState,
  on(actions.openSearchModal, (state, action) => {
    const isOpen = action.payload.open;
    return {
      ...state,
      open: isOpen
    };
  }),
  on(actions.closeSearchModal, (state, action) => {
    const isOpen = action.payload.open;
    return {
      ...state,
      open: isOpen
    };
  })
);

export function reducer(state: SearchModalState, action: actions.SearchModalActionsUnion) {
  return searchModalReducer(state, action);
}
// export function reducer(
//   state = initialState,
//   action: actions.SearchModalActionsUnion
// ): SearchModalState {
//   switch (action.type) {
//     case actions.SearchActionType.OPEN_SEARCH_MODAL: {
//       const isOpen = action.payload.open;
//       return {
//         ...state,
//         open: isOpen
//       };
//     }
//     case actions.SearchActionType.CLOSE_SEARCH_MODAL: {
//       const isOpen = action.payload.open;
//       return {
//         ...state,
//         open: isOpen
//       };
//     }
//     default:
//       return state;
//   }
// }
