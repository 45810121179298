import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { EtxIconsRegistryService } from './etx-icons-registry.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-etx-icons',
  template: `<ng-content></ng-content>`,
  styles: [':host::ng-deep svg{width: 2.0625rem; height: 2.0625rem}'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EtxIconsComponent {
  private svgIcon: SVGElement;
  // @Input() color: string;

  constructor(
    private element: ElementRef,
    private etxIconRegistry: EtxIconsRegistryService,
    @Optional() @Inject(DOCUMENT) private document: any
  ) {}

  @Input()
  set name(iconName: string) {
    if (this.svgIcon) {
      this.element.nativeElement.removeChild(this.svgIcon);
    }
    const svgData = this.etxIconRegistry.getIcon(iconName);
    this.svgIcon = this.svgElementFromString(svgData);
    this.element.nativeElement.appendChild(this.svgIcon);
    //   this.element.nativeElement.setAttribute('fill', this.color);
  }

  private svgElementFromString(svgContent: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgContent;
    return div.querySelector('svg') || this.document.createElementNS('http://www.w3.org/2000/svg', 'path');
  }
}
