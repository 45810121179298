<div (click)="onContainerClick($event)" class="container-fluid mb-5 p-0">
  <app-header-mobile class="d-md-none d-lg-block"></app-header-mobile>
  <form [formGroupId]="'editorFormID'" [formGroupSync]="true" [formGroup]="editorForm" (submit)="submitForm()">
    <nav style="padding: 1rem 0">
      <div
        class="navbar"
        style="width: 100%; max-width: 1380px; padding: 0.5rem 1rem"
        fxLayout="column"
        fxLayout.gt-md="row"
        fxLayoutGap="16px"
      >
        <div
          class="audio-story-name d-flex align-items-center justify-content-center"
          style="width: 100%"
          fxLayout="row"
          fxLayoutGap="20px"
        >
          <div style="position: relative; width: 100%">
            <input
              (click)="trackEvent('editor-add-name')"
              #storyName
              type="text"
              placeholder="{{ 'EDITOR_AUDIO_STORY_NAME' | translate }}"
              class="form-control title pt-1"
              formControlName="name"
              style="padding-right: 25px !important"
            />

            <i
              class="fa fa-pencil"
              style="
                position: absolute;
                right: 7px;
                top: calc(50% - 8px);
                color: rgba(0, 0, 0, 0.6);
                pointer-events: none;
              "
            ></i>
          </div>
          <div class="justify-content-center" *ngIf="gridCols === 'tablet'" fxLayout="row" fxLayoutGap="20px">
            <app-audio-background-dropdown
              [selectedBackgroundValueFromForm]="currentBackgroundValue"
              (onAudioBackgroundValueChange)="patchBackgroundValue($event)"
              class="audio-background"
            ></app-audio-background-dropdown>
            <i
              (click)="showDialog()"
              title="{{ 'DELETE' | translate }}"
              class="fa fa-lg fa-trash-o"
              aria-hidden="true"
            ></i>
            <i
              (click)="saveAudioStory()"
              data-toggle="tooltip"
              data-placement="right"
              title="{{ 'SAVE' | translate }}"
              class="fa fa-lg fa-floppy-o"
              aria-hidden="true"
            ></i>
            <ngb-alert
              class="save-alert"
              #selfClosingSuccessAlert
              [dismissible]="false"
              type="success"
              *ngIf="successMessage"
              (closed)="successMessage = ''"
              style="right: 35px; left: inherit"
              >{{ successMessage | translate }}
            </ngb-alert>
            <ngb-alert
              class="error-message"
              #selfClosingErrorAlert
              [dismissible]="false"
              *ngIf="errorMessage"
              type="danger"
              (closed)="errorMessage = ''"
              style="right: 98px; left: inherit"
              >{{ errorMessage | translate: { itemsIndex: invalidItemsCharacterCount?.join(', ') } }}
              <span *ngIf="invalidTextItems?.length && !characterCountError"> {{ invalidTextItems.join(', ') }}</span>
              <span *ngIf="invalidAudioItems?.length && !characterCountError"> {{ invalidAudioItems.join(', ') }}</span>
            </ngb-alert>
          </div>
        </div>

        <div
          class="editor-menu-container d-flex justify-content-around"
          style="width: 100%; max-width: 864px; margin: 0 auto"
          fxLayout="column"
          fxLayout.gt-xs="row"
          fxLayoutGap="20px"
        >
          <app-voices-dropdown
            (onSelectedVoiceChange$)="patchSelectedVoice($event)"
            [voices]="voices$ | async"
            [lastGeneratedVoices]="lastGeneratedVoices"
            [userPermissions]="userPermissions"
            [translatableLangs]="translatableLangs"
            formControlName="voices"
            style="width: 100%"
          >
          </app-voices-dropdown>
          <span [hidden]="!(isLoadingVoices$ | async)">
            <i class="fa fa-refresh fa-spin"></i>
          </span>
          <app-format-dropdown
            class="ms-1"
            formArrayName="format"
            (onSelectedFormatChange$)="addSelectedFormat($event)"
            (trackFormatChange)="trackEvent($event.type, $event.format)"
            style="width: 100%"
          >
          </app-format-dropdown>
        </div>

        <div
          class="separator d-md-flex justify-content-center align-items-center"
          style="width: 100%; margin: 0"
          fxLayout="row"
          fxLayoutGap="30px"
        >
          <div fxHide fxShow.gt-md fxLayout="row"></div>
          <div
            class="justify-content-center align-items-center"
            style="position: relative"
            *ngIf="gridCols === 'desktop'"
            fxLayout="row"
            fxLayoutGap="20px"
          >
            <app-audio-background-dropdown
              [selectedBackgroundValueFromForm]="currentBackgroundValue"
              (onAudioBackgroundValueChange)="patchBackgroundValue($event)"
            ></app-audio-background-dropdown>
            <i
              (click)="showDialog()"
              title="{{ 'DELETE' | translate }}"
              class="fa fa-lg fa-trash-o"
              aria-hidden="true"
            ></i>
            <i
              (click)="saveAudioStory()"
              data-toggle="tooltip"
              data-placement="right"
              title="{{ 'SAVE' | translate }}"
              class="fa fa-lg fa-floppy-o"
              aria-hidden="true"
            ></i>
            <ngb-alert
              class="save-alert"
              #selfClosingSuccessAlert
              [dismissible]="false"
              type="success"
              *ngIf="successMessage"
              (closed)="successMessage = ''"
              >{{ successMessage | translate }}
            </ngb-alert>
            <ngb-alert
              class="error-message"
              #selfClosingErrorAlert
              [dismissible]="false"
              *ngIf="errorMessage"
              type="danger"
              (closed)="errorMessage = ''"
              >{{
                errorMessage
                  | translate
                    : {
                        itemsIndex: invalidItemsCharacterCount?.join(', '),
                        limitNumber: this._editorService.itemCharacterLimit
                      }
              }}
              <span *ngIf="invalidTextItems?.length && !characterCountError"> {{ invalidTextItems.join(', ') }}</span>
              <span *ngIf="invalidAudioItems?.length && !characterCountError"> {{ invalidAudioItems.join(', ') }}</span>
            </ngb-alert>
          </div>
          <div
            class="generate-loader d-flex"
            style="width: 100%; align-items: center; max-width: 764px; margin: 0 auto"
          >
            <button
              class="btn btn-primary generate-button"
              type="submit"
              [disabled]="!selectedFormats.length"
              style="width: 100%"
              [ngStyle.gt-md]="'max-width: 9rem;'"
            >
              {{ 'EDITOR_GENERATE_BUTTON' | translate }}
            </button>
            <!-- loader generation -->
            <!--loader-->
            <div
              (click)="openAudioStoryGenerationPage()"
              *ngIf="(generationLoading$ | async) && !(generationLoaded$ | async)"
              class="loader d-flex col-3 col-md-3 col-lg-4 align-items-center justify-content-center"
              id="loader-1"
            >
              <span *ngIf="(generationLoading$ | async) && !(generationLoaded$ | async)" class="badge generate-badge">{{
                selectedFormats.length
              }}</span>
            </div>

            <!--success-->
            <div
              (click)="openAudioStoryGenerationPage()"
              *ngIf="!(generationLoading$ | async) && (generationLoaded$ | async)"
              class="success-loader d-flex col-3 col-md-3 col-lg-4 align-items-center justify-content-center"
            >
              <i class="fa fa-check check-success" aria-hidden="true"></i>
              <span
                *ngIf="!(generationLoading$ | async) && (generationLoaded$ | async)"
                class="badge generate-success-badge"
                >{{ selectedFormats.length }}</span
              >
            </div>
            <!--end loader -->
          </div>
        </div>
      </div>
    </nav>
    <div formArrayName="items">
      <div
        class="story-items-container d-flex flex-column align-items-center justify-content-center"
        (click)="onStoryItemContainerClick()"
      >
        <swiper #swiper [config]="config" class="story-items cursor-pointer swiper-slide">
          <ng-template
            swiperSlide
            *ngFor="let item of items.controls; let i = index; let last = last"
            class="position-relative story-items-list d-flex flex-column"
          >
            <span>
              <div *ngIf="i === 0" class="first-add-item" (click)="trackEvent('editor-add-a-card')">
                <div ngbDropdown #firstAddItemDropdown="ngbDropdown" placement="right" class="d-inline-block">
                  <span
                    data-toggle="tooltip"
                    data-placement="right"
                    title="{{ 'ADD_SEQUENCE' | translate }}"
                    ngbDropdownToggle
                    ><i class="fa fa-plus-circle"></i
                  ></span>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="add-item-menu">
                    <button type="button" class="add-item-list" ngbDropdownItem (click)="addArticle()">
                      <i class="fa fa-align-justify"></i><span>{{ 'EDITOR_ADD_ARTICLE' | translate }}</span>
                    </button>
                    <button type="button" class="add-item-list" ngbDropdownItem (click)="initEmptyAudioItem(0)">
                      <i class="fa fa-volume-up"></i><span>{{ 'EDITOR_ADD_AUDIO' | translate }}</span>
                    </button>
                    <button type="button" class="add-item-list" ngbDropdownItem (click)="initEmptyTextItem(0)">
                      <i class="fa fa-font"></i><span>{{ 'EDITOR_ADD_TEXT' | translate }}</span>
                    </button>
                  </div>
                </div>
              </div>

              <div class="story-item-body pb-5" (click)="selectStoryItem(i)">
                <span (click)="removeControl(i, item)" class="close position-absolute text-end"
                  ><i class="fa fa-times" aria-hidden="true"></i
                ></span>
                <div *ngIf="item.value?.images?.length > 1" class="image-counter" (click)="openMultiImageModal()">
                  <span class="image-counter-text"> {{ item.value.images.length }}</span>
                  <img class="count-img" src="../assets/images/pictos/multi-image-icon.png" />
                </div>
                <div class="position-absolute padded-multiline">
                  <p class="text position-relative" *ngIf="item.value.title">
                    {{ item.value.title }}
                  </p>
                </div>
                <p
                  *ngIf="!item.value.title && !item.value.image && !itemHasImages(item)"
                  class="story-item-placeholder text-center m-auto d-flex flex-column justify-content-center align-items-center"
                >
                  <span *ngIf="item.value.type === 'text'"
                    ><i class="fa fa-pencil-square-o" aria-hidden="true"></i
                  ></span>
                  <span *ngIf="item.value.type === 'audio'"><i class="fa fa-music" aria-hidden="true"></i></span>

                  <span *ngIf="item.value.type === 'text'">{{ 'EDITOR_NEW_TEXT_SEQUENCE' | translate }}</span>
                  <span *ngIf="item.value.type === 'audio'">{{ 'EDITOR_NEW_AUDIO_SEQUENCE' | translate }}</span>
                </p>

                <img *ngIf="itemHasImages(item)" src="{{ item.value?.images[0] }}" alt="" width="460" height="445" />
              </div>
              <div
                (click)="selectStoryItem(i)"
                class="story-item-footer d-flex align-items-center justify-content-center"
              >
                <button type="button" class="edit-btn">
                  <i class="fa fa-pencil me-2"></i>{{ 'EDIT' | translate }}
                </button>
              </div>

              <div class="add-item d-flex flex-column align-items-center align-content-center">
                <div ngbDropdown #addItemDropdown="ngbDropdown" placement="top-right" class="d-inline-block">
                  <span
                    data-toggle="tooltip"
                    data-placement="right"
                    title="{{ 'ADD_SEQUENCE' | translate }}"
                    ngbDropdownToggle
                    ><i class="fa fa-plus-circle mb-3"></i
                  ></span>

                  <div ngbDropdownMenu aria-labelledby="dropdownBasic2" class="add-item-menu">
                    <button
                      type="button"
                      class="add-item-list"
                      [class.preview]="showDescription"
                      ngbDropdownItem
                      (click)="addArticle()"
                    >
                      <i class="fa fa-align-justify"></i><span>{{ 'EDITOR_ADD_ARTICLE' | translate }}</span>
                    </button>
                    <button
                      type="button"
                      class="add-item-list"
                      [class.preview]="showDescription"
                      ngbDropdownItem
                      (click)="initEmptyAudioItem(i + 1)"
                    >
                      <i class="fa fa-volume-up"></i><span>{{ 'EDITOR_ADD_AUDIO' | translate }}</span>
                    </button>
                    <button
                      type="button"
                      class="add-item-list"
                      [class.preview]="showDescription"
                      ngbDropdownItem
                      (click)="initEmptyTextItem(i + 1)"
                    >
                      <i class="fa fa-font"></i><span>{{ 'EDITOR_ADD_TEXT' | translate }}</span>
                    </button>
                    <button
                      type="button"
                      class="add-item-list"
                      [class.preview]="showDescription"
                      ngbDropdownItem
                      (click)="openJingleModal(i)"
                    >
                      <i class="fa fa-music"></i><span>{{ 'AUDIO_TRANSITION' | translate }}</span>
                    </button>
                  </div>
                </div>
                <div *ngIf="!last" class="mt-2">
                  <img
                    [hidden]="item.value.jingle"
                    class="audio-transition"
                    src="assets/images/audiostory/icon-virgule-sonore-inactive.png"
                    alt="audio transition disabled"
                  />
                </div>
              </div>
            </span>
          </ng-template>
        </swiper>
      </div>
    </div>
    <!-- Selected Item -->
    <div
      *ngIf="selectedItem"
      [ngClass]="{
        showDescription: showDescription,
        hideDescription: !showDescription,
        expandDescription: expandDescription
      }"
      class="story-item-description d-flex align-items-stretch align-content-stretch justify-content-between"
      fxLayout="row"
      fxLayoutGap="20px"
      [ngStyle.lt-md]="{ height: '95%' }"
      [ngStyle.md]="{ height: _editorService.getScreenRatio() === 150 ? '50%' : '44%' }"
      [ngStyle.lg]="{ height: _editorService.getScreenRatio() === 150 ? '50%' : '44%' }"
    >
      <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="20px" style="padding: 40px 20px 10px; width: 100%">
        <div [formGroup]="selectedItem" class="" style="width: 100%">
          <span
            class="story-item-description-scroll d-flex"
            (click)="toggleDescription()"
            [matTooltip]="showDescription ? ('EDITOR_HIDE' | translate) : ('EDITOR_SHOW' | translate)"
            matTooltipClass="etx-tooltip"
            matTooltipPosition="below"
          >
            <i *ngIf="showDescription" class="fa fa-chevron-down" aria-hidden="true"></i>
            <i *ngIf="!showDescription" class="fa fa-chevron-up" aria-hidden="true"></i>
          </span>
          <span
            *ngIf="checkFormItemText()"
            [matTooltip]="'PREVIEW_AUDIO' | translate"
            matTooltipClass="etx-tooltip"
            matTooltipPosition="below"
            (click)="generateStoryItemAudio(selectedItem)"
            class="edit-play-icon"
            style="
              left: 100px;
              top: -19px;
              z-index: 4;
              background: white;
              border-radius: 50px;
              padding: 4px 8px 3px;
              border: 2px solid;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 40px;
              width: 40px;
            "
            [ngStyle.lt-md]="{ top: '2px', width: '35px', height: '35px', right: '14px', left: 'inherit' }"
          >
            <i *ngIf="!(generationLoading$ | async) || (generationError$ | async)" class="fa fa-volume-up"></i>
            <i
              *ngIf="(generationLoading$ | async) && !(generationError$ | async)"
              class="fa fa-spinner fa-pulse fa-1x"
              aria-hidden="true"
            ></i>
          </span>
          <label class="col title-label">
            <textarea
              (click)="trackEvent('editor-add-title-to-card')"
              placeholder="{{ 'EDITOR_TEXT_EDITOR_TITLE' | translate }}..."
              data-toggle="tooltip"
              data-placement="right"
              title="{{ 'EDITOR_TEXT_EDITOR_TITLE' | translate }}"
              type="text"
              class="form-control title"
              aria-label="Large"
              aria-describedby="inputGroup-sizing-sm"
              formControlName="title"
              cols="200"
              rows="2"
              style="padding: 0.375rem 47px 0.375rem 1.35rem"
              [ngStyle.gt-md]="{ height: '40px' }"
            >
            </textarea>
          </label>

          <div
            *ngIf="
              !isAddedEntireArticleText[selectedItem.value.id] &&
              selectedItem.value.description &&
              selectedItem.value.description.length
            "
            class="add-article-text-container"
            (click)="addEntireArticleText()"
          >
            <span class="add-text-icon d-xs-none">
              <app-etx-icons name="add_white" title="{{ 'ADD_INTEGRAL_TEXT' | translate }}"></app-etx-icons>
            </span>
            <span class="add-text">{{ 'ADD_INTEGRAL_TEXT' | translate }}</span>
          </div>

          <div
            #editor
            [ngClass]="expandDescription ? 'expandEditorDescription' : 'editor-default-height'"
            class="text-select d-flex flex-column"
            textSelect
            *ngIf="checkFormItemText()"
          >
            <div style="position: absolute; left: 0; right: 0; bottom: 0; top: 0">
              <!-- <span
              *ngIf="checkFormItemText()"
              data-toggle="tooltip"
              data-placement="right"
              title="{{ 'PREVIEW_AUDIO' | translate }}"
              (click)="generateStoryItemAudio(selectedItem)"
              class="edit-play-icon"
              style="left: 100px;top: -129px;z-index: 4;background: white;border-radius: 50px;padding: 4px 8px 3px;border: 2px solid;display: flex;justify-content: center;align-items: center;height: 40px;width: 40px;"
            >
              <i *ngIf="!(generationLoading$ | async)" class="fa fa-volume-up"></i>
              <i *ngIf="generationLoading$ | async" class="fa fa-spinner fa-pulse fa-1x" aria-hidden="true"></i>
            </span> -->
              <app-ssml-editor
                [initialValue]="selectedItem.value.articleText"
                [voices]="voices"
                [lexicons]="lexicons"
                (ssml$)="handleEditorSsml($event)"
                (currentValue)="handleCurrentValue($event)"
                (requestOpenVoice)="openVoiceModal($event)"
                (requestOpenPronunciation)="openChangePronunciationModal($event)"
                (selectedText)="selectedText$.next($event)"
                (onParentNodeNameChange$)="setSelectedTextParentNodeName($event)"
                [update]="updateEditorElement"
              ></app-ssml-editor>
            </div>
            <div
              *ngIf="voiceHover"
              class="voice-tooltip"
              [style.left.px]="voiceRectangle.left"
              [style.top.px]="voiceRectangle.top - 20"
            >
              {{ voiceTooltip?.language.substring(0, 2).toUpperCase() | translate }} ({{
                voiceTooltip?.country.toUpperCase() | translate
              }}) {{ voiceTooltip?.label }}
            </div>
          </div>

          <ngb-alert
            class="item-error-message"
            #itemAudioPreviewErrorAlert
            [dismissible]="false"
            *ngIf="itemAudioPreviewErrorMessage"
            type="danger"
            (closed)="itemAudioPreviewErrorMessage = ''"
            >{{ itemAudioPreviewErrorMessage | translate: { limitNumber: this._editorService.itemCharacterLimit } }}
          </ngb-alert>
        </div>
        <div *ngIf="checkFormItemAudio()" class="editor-upload-audio-container d-flex" style="margin: 0 20px">
          <div style="background: transparent">
            <app-form-upload
              data-toggle="tooltip"
              data-placement="right"
              title="{{ 'DRAG_AND_DROP_FILE' | translate }}"
              [selectedItem]="selectedItem"
            ></app-form-upload>
          </div>
        </div>
        <div class="editor-upload-image-container d-flex" style="margin: 0 20px">
          <div style="background: transparent">
            <app-image-upload
              data-toggle="tooltip"
              data-placement="right"
              title="{{ 'DRAG_AND_DROP_FILE' | translate }}"
              [selectedItem]="selectedItem$ | async"
              (updateForm$)="updateFormSyncValue()"
              (patchImagesValues$)="patchImagesValues($event)"
            ></app-image-upload>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="alert alert-danger mt-3" *ngIf="error$ | async">{{ (error$ | async).message }}</div>
</div>

<!--editor generation overlay-->
<app-audio-story-generation
  [displayAudioFormatUrl]="displayAudioFormatUrl | async"
  [audioFormatUrl]="audioFormatUrl$ | async"
  [audioStoryMainLang]="audioStoryMainLang"
  [selectedFormats]="selectedFormats"
></app-audio-story-generation>
<app-confirm-dialog></app-confirm-dialog>
<app-pronunciation-modal
  (formattedSsmlText$)="updateItemTextToSsml($event)"
  (listenSelectedText$)="listenSelection($event)"
  (onLexiconUpdate)="updateLexicon($event)"
  [selectedText$]="selectedText$"
  [selectedText]="selectedText$ | async"
  [alias$]="alias$"
  [parentNode$]="parentNode$"
  [selectedItem]="selectedItem"
  [itemAudioPreviewError]="_itemAudioPreviewError"
  [selectedVoice]="form.voices.value"
  [parentNodeName]="parentNodeName$ | async"
></app-pronunciation-modal>
<app-no-studio-modal> </app-no-studio-modal>
<app-jingle-modal (onFormSubmit)="onJingleChange($event)" [jingle]="selectedItem?.value?.jingle"></app-jingle-modal>
<app-voice-modal
  [selectedText]="selectedText$ | async"
  [selectedItem]="selectedItem"
  [parentNode]="parentNode$ | async"
  (formattedSsmlText$)="updateItemTextToSsml($event)"
  (listenVoicePreview$)="listenVoicePreview($event)"
  [voices]="voices$ | async"
></app-voice-modal>

<app-multi-image-modal
  (onImageChanges)="onMultiImageChanges($event)"
  [selectedItem]="selectedItem$ | async"
></app-multi-image-modal>
<app-translation-modal
  *ngIf="isTranslateModalOpen$ | async"
  [translateFailAudioStory]="translateFailAudioStory$ | async"
  [translateFailFormats]="translateFailFormats$ | async"
  [translatableLangs]="translatableLangs"
></app-translation-modal>

<!-- editor-audio-story-modal-player-->
<!--<app-modal-audio-story [audioStory]="storyVideo" *ngIf="storyVideo.video_url"></app-modal-audio-story>-->
