import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@purplefront/shared';

import { BookmarksGridComponent } from './components/bookmarks-grid/bookmarks-grid.component';
import { BookmarkButtonComponent } from './components/bookmark-button/bookmark-button.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: true
    }),
    SharedModule,
    RouterModule
  ],
  declarations: [BookmarksGridComponent, BookmarkButtonComponent],
  exports: [BookmarksGridComponent, BookmarkButtonComponent]
})
export class BookmarksUiModule {}
