import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { CalendarEvent } from '../../_models/calendar-event.model';
import { CalendarActionUnion, CalendarActionTypes } from '../actions';
import * as calendarActions from '../actions';
import { createReducer, on } from '@ngrx/store';

export interface CalendarState extends EntityState<CalendarEvent> {
  selectedEventId: number | null;
  loaded: boolean;
  loading: boolean;
}

export const adapter: EntityAdapter<CalendarEvent> = createEntityAdapter<CalendarEvent>();

export const calendarInitialState: CalendarState = adapter.getInitialState({
  selectedEventId: null,
  loaded: false,
  loading: false
});

const featureReducer = createReducer(
  calendarInitialState,
  on(calendarActions.loadCalendarEvents, (state, action) => {
    return {
      ...state,
      loading: true
    };
  }),
  on(calendarActions.loadCalendarEventsFail, (state, action) => {
    return {
      ...state,
      loading: false,
      loaded: false
    };
  }),
  on(calendarActions.loadCalendarEventsSuccess, (state, action) => {
    return adapter.setAll(action.payload.calendarEvents, {
      ...state,
      loading: false,
      loaded: true
    });
  })
);

export function CalendarReducer(state: CalendarState, action: CalendarActionUnion) {
  return featureReducer(state, action);
}

/**
 *  Get the selectors
 * */

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const getSelectedEventId = (state: CalendarState) => state.selectedEventId;
export const getSelectLoading = (state: CalendarState) => state.loading;
export const getSelectLoaded = (state: CalendarState) => state.loaded;
