import { Component, OnInit, Input, OnDestroy, ViewChild, Renderer2, AfterViewInit } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { first, take, takeUntil } from 'rxjs/operators';

import * as moment from 'moment';

import * as fromBookmarksStore from '../../../../../data-access/src/lib/_store';
import * as fromRouterStore from '../../../../../../app-router/data-access/src/lib/_store';

import { Bookmark, BookmarksApi } from '@purplefront/bookmarks/data-access';

@Component({
  selector: 'app-bookmark-button',
  templateUrl: './bookmark-button.component.html',
  styleUrls: ['./bookmark-button.component.scss']
})
export class BookmarkButtonComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() appBookmark: any;
  @Input() contextType: string;
  @Input() icon: string;
  @Input() iconActive: string;
  @Input() color?: string = 'white';
  @Input() colorActive?: string = '#7e2fa2'; //$base-bgcolor-relaxnews-lightened
  @ViewChild('button') button;
  page: string;
  feedSlug: string;
  bookmarks: Bookmark[];
  isLoading$ = new BehaviorSubject<boolean>(null);
  isActive: boolean;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private bookmarkStore: Store<fromBookmarksStore.BookmarksState>,
    private routerStore: Store<fromRouterStore.AppRouterState>,
    private bookmarksServices: BookmarksApi,
    private _renderer: Renderer2
  ) {}

  ngOnInit() {
    /**
     * Select current page
     */
    this.routerStore.pipe(take(1), select(fromRouterStore.selectPage)).subscribe((_page) => (this.page = _page));

    /**
     * Select current feed slug
     */
    this.routerStore
      .pipe(take(1), select(fromRouterStore.selectFeedSlug))
      .subscribe((_slug) => (this.feedSlug = _slug));

    /**
     * Select all bookmarks
     */
    this.bookmarkStore
      .pipe(select(fromBookmarksStore.selectAllBookmarks), takeUntil(this._ngUnsubscribe$))
      .subscribe((_bookmarks) => (this.bookmarks = _bookmarks));

    /**
     * Get bookmark state
     */
    this.isActive = this.isBookmarked(this.appBookmark);
  }

  ngAfterViewInit(): void {
    this.setIconColor();
  }

  setIconColor() {
    this._renderer.setStyle(this.button.nativeElement, 'color', this.color);
  }

  /**
   * Add or Remove bookmark
   */
  public toggleBookmark() {
    const payload = {
      title: this.appBookmark.title,
      lang: this.appBookmark.lang,
      parent: this.feedSlug,
      persistent: false,
      type: this.contextType,
      context: this.getBookmarkContext(this.appBookmark, this.contextType),
      visible: true
    };
    this.isLoading$.next(true);

    if (this.isBookmarked(this.appBookmark)) {
      // remove bookmark
      const bookmarkToRemove = this.bookmarks.find(
        (_bookmark) => _bookmark.context['id'] === this.appBookmark.id.toString()
      );

      if (bookmarkToRemove) {
        this.bookmarksServices.removeBookmark(bookmarkToRemove.id).subscribe(
          () => {
            this.bookmarkStore.dispatch(
              fromBookmarksStore.removeBookmark({
                payload: bookmarkToRemove.id
              })
            );
            this.isLoading$.next(false);
            this.isActive = false;
          },
          (error) =>
            this.bookmarkStore.dispatch(
              fromBookmarksStore.removeBookmarkFail({
                payload: { error: error }
              })
            )
        );
      }
    } else {
      // add bookmark
      this.bookmarksServices
        .saveBookmark(payload)
        .pipe(first())
        .subscribe(
          (_bookmark) => {
            this.bookmarkStore.dispatch(fromBookmarksStore.addBookmark(_bookmark));
            this.isLoading$.next(false);
            this.isActive = true;
          },
          (error) =>
            this.bookmarkStore.dispatch(
              fromBookmarksStore.addBookmarkFailure({
                payload: { error: error }
              })
            )
        );
    }
  }

  /**
   * Get a bookmark context
   * @param data article
   * @param contextType : reader|radar|relax|agenda
   */
  getBookmarkContext(data, contextType) {
    const context = {
      radar: {
        id: data.last ? data.last.id : data.id,
        image: data.last ? this.getArticleImage(data.last.images) : this.getArticleImage(data.images),
        publisher: data.last ? data.last.publisher : data.publisher,
        source: data.last ? data.last.source : data.source,
        bookmarkedDate: moment().format(),
        contextType: 'radar',
        lang: data.lang,
        publicationDate: data.publication_date,
        page: this.page,
        isSaved: false,
        sourceType: data.last ? data.last.object_source_type : data.object_source_type
      },
      reader: {
        id: data.last ? data.last.id : data.id,
        image: data.last ? this.getArticleImage(data.last.images) : this.getArticleImage(data.images),
        publisher: data.last ? data.last.publisher : data.publisher,
        source: data.last ? data.last.source : data.source,
        tags: data.last ? data.last.tags || data.last.terms : data.terms,
        bookmarkedDate: moment().format(),
        contextType: 'reader',
        lang: data.lang,
        publicationDate: data.publication_date,
        page: this.page,
        isSaved: false,
        sourceType: data.last ? data.last.object_source_type : data.object_source_type
      },
      relax: {
        id: data.id,
        articleType: data.type,
        image: this.getArticleImage(data.images),
        publisher: data.publisher,
        tags: data.tags || data.terms,
        bookmarkedDate: moment().format(),
        contextType: 'relax',
        lang: data.lang,
        publicationDate: data.publication_date,
        page: this.page,
        isSaved: false,
        sourceType: data.object_source_type
      },
      agenda: {
        id: data.id,
        source: data.url,
        beginDate: data.beginDate,
        endDate: data.endDate,
        bookmarkedDate: moment().format(),
        contextType: 'agenda',
        lang: data.lang,
        description: data.content,
        url: data.url,
        page: this.page,
        isSaved: false,
        sourceType: data.object_source_type,
        data: data.data
      }
    };

    return context[contextType];
  }

  /**
   * Return article image
   * @param images
   */
  getArticleImage = (images) => (images && images[0] ? images[0].s_medium : undefined);

  /**
   * check if article is already bookmarked
   * @param article
   */
  isBookmarked(article): boolean {
    if (article && article.id) {
      return this.bookmarks.some((bookmark) => bookmark.context['id'] === article.id.toString());
    }
  }

  /**
   * Prevent memory leaks unsubscribe when destroy component
   */
  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
