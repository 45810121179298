<ngx-smart-modal #cguFrModal identifier="cguFrModal" [customClass]="'cgu-modal'" xmlns="http://www.w3.org/1999/html">
  <div class="pt-2 pe-2 ps-2 pb-2" tabindex="-1" role="dialog">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 mt-5">
          <h2>CONDITIONS GÉNÉRALES DE VENTE</h2>
          <p>
            ETX Studio (ci-après dénommé « ETX ») commercialise des fonctionnalités applicatives accessibles en ligne
            dont elle est propriétaire ("<b>Service Saas</b>").
          </p>
          <p>
            ETX a mis à la disposition du Client une proposition commerciale et/ou de la documentation présentant le
            Service dont le Client reconnaît avoir pris connaissance. Il appartient au Client, notamment sur la base de
            ces informations, de s’assurer de l’adéquation du Service à ses besoins et contraintes propres.
          </p>
          <p>
            Les présentes Conditions Générales de Vente (ci-après entendues comme « <b>Conditions</b> ») s’appliquent au
            Contrat et définissent les droits et obligations des Parties dans le cadre de toute signature d’un Bon de
            Commande par le Client. La version actuelle des Conditions est la seule opposable aux Clients pendant toute
            la durée d’exécution du Contrat et jusqu’à ce qu’une nouvelle version la remplace. ETX se réserve le droit
            de modifier à tout moment les présentes Conditions sans en avertir au préalable le Client mais tiendra
            informé ce dernier des dernières modifications.
          </p>

          <p>
            ETX se réserve le droit de déroger à certaines clauses des présentes ou d’établir des conditions
            particulières en fonction des clients. Toute dérogation aux Conditions est inscrite sur le Bon de commande.
            Les conditions particulières prévalent sur les conditions générales. L’acceptation des Conditions est
            obligatoire avant toute passation de commande sur le Site. Le Client reconnaît et accepte que toute
            signature du Bon de Commande vaut acceptation sans réserve des dispositions et conditions qui suivent ainsi
            que des annexes.
          </p>

          <p>
            Au titre des présentes, Pause et le Client pourront être dénommés individuellement comme «
            <b>la Partie</b> » et collectivement comme « <b>les Parties</b> ».
          </p>

          <h3>Article 1 - <span class="text-decoration-underline">Définitions</span></h3>

          <p>
            Dans le présent document, les mots ou expressions commençant par une majuscule auront la signification qui
            suit :
          </p>
          <ul>
            <li>
              "<b>Anomalie</b>": désigne tout défaut de conception ou de réalisation du Service, indépendant d'une
              mauvaise utilisation, empêchant bon fonctionnement du Service ;
            </li>
            <li>
              "<b>Bon de Commande</b>" désigne le document annexé au présent contrat lors de sa signature, décrivant les
              caractéristiques du Service fourni au Client ;
            </li>
            <li>
              "<b>Client</b>" désigne la personne morale ou la personne physique, co-contractante de ETX Studio,
              intervenant dans le cadre de son activité professionnelle, commerciale, industrielle, artisanale ou
              libérale ;
            </li>
            <li>
              "<b>Compte Utilisateur </b>" désigne un accès ouvert à un Utilisateur. Un Compte Utilisateur correspond à
              un Utilisateur unique et à un Identifiant unique ;
            </li>
            <li>
              "<b>Contenu</b>" » désigne le Contenu presse et éditorial créé et/ou modifié par le Client, ainsi que le
              Contenu mis à disposition du Client via le Service et rédigé par les équipes presse d’ETX Studio ou de
              l’AFP
            </li>
            <li>
              "<b>Contrat</b>"désigne l’ensemble contractuel composé de plusieurs parties et de plusieurs documents, à
              savoir la partie « Bon de commande », les présentes Conditions Générales de Vente, ainsi que les Annexes ;
            </li>
            <li>
              "<b>Date d’entrée en vigueur</b>" désigne la date d’entrée en vigueur du Contrat telle qu’indiquée sur le
              Bon de commande ;
            </li>
            <li>
              "<b>Documentation</b>" désigne les informations mises à disposition par ETX et décrivant les modalités
              d’utilisation du Service, sous la forme d’une documentation utilisateur accompagnant le Service et/ou
              d’une aide en ligne et la liste des prérequis nécessaires à l’utilisation du Service décrits sur le site
              Internet d’ETX Studio et mentionnées dans le Bon de Commande ;
            </li>
            <li>
              "<b>Plateforme</b>"désigne le site internet www.etxstudio.com par lequel le Client peut utiliser les
              Services ;
            </li>
            <li>
              "<b>SaaS</b>" (Software as a Service) désigne le mode de mise à disposition à distance des fonctionnalités
              du Service utilisant les technologies Internet et accessible par l’intermédiaire du réseau Internet ;
            </li>
            <li>
              “<b>Service</b>” désigne les fonctionnalités applicatives standards délivrées en ligne par ETX ainsi que
              les Mises à Jour, sous forme de souscription à un abonnement ;
            </li>
            <li>
              "<b>Service(s) Complémentaire(s) </b>" désigne tout service autre que l’hébergement et la maintenance du
              Service, fourni par ETX Studio à la demande du Client, mentionné dans le Bon de Commande et faisant
              l’objet d’une facturation supplémentaire ;
            </li>
            <li>
              "<b>Utilisateur</b>" désigne toute personne physique habilitée par le Client pouvant avoir accès au
              Service, dans les limites prévues par les présentes, notamment par les Conditions Générales d’Utilisation
              et le Bon de commande.
            </li>
          </ul>

          <h3>Article 2 - <span class="text-decoration-underline">Objet</span></h3>
          <p>Les présentes conditions ont pour objet de définir les conditions dans lesquelles&nbsp;&nbsp;</p>

          <ul>
            <li>- ETX concède au Client, qui l’accepte, un droit d’utilisation du Service&nbsp; ;&nbsp;</li>
            <li>- ETX fournit au Client des prestations de service associées&nbsp; ;&nbsp;</li>
            <li>- Le client s’engage à régler la redevance contractuelle&nbsp; ;&nbsp;</li>
            <li>- Les obligations et responsabilités à charge des Parties.</li>
          </ul>

          <h3>Article 3 - <span class="text-decoration-underline">Documents contractuels</span></h3>

          <p>
            Le Contrat est formé entre les Parties par les documents contractuels suivants, présentés par ordre
            hiérarchique de valeur juridique décroissante :
          </p>
          <ul>
            <li>- Le Bon de Commande avec les Conditions Particulières&nbsp; ;&nbsp;</li>
            <li>
              - Les présentes Conditions Générales et ses annexes qui forment un tout indivisible avec les CGU&nbsp;
              ;&nbsp;
            </li>
            <li>- Les éventuelles annexes jointes le cas échéant aux Bon(s) de Commande.</li>
          </ul>

          <p>
            En cas de contradiction entre une ou plusieurs dispositions figurant dans les documents mentionnés
            ci-dessus, le document de rang supérieur prévaut.
          </p>

          <h3>Article 4 - <span class="text-decoration-underline">Durée</span></h3>

          <p>
            Sauf disposition contraire indiquée au Bon de commande, le Contrat entre en vigueur à date de sa signature
            par le Client. Il est conclu pour la durée mentionnée dans le Bon de commande. A défaut de mention dans le
            Bon de commande, il est conclu pour une durée de douze (12) mois avec une période de test de trois (3) mois
            (« <b>Période de test</b> »). Pendant la Période de Test, le Client a la possibilité de ne pas prolonger son
            contrat au-delà de la Période de test. Le Client devra pour cela avertir ETX Studio par courrier recommandé
            au plus tard 15 jours avant la fin de la Période de test.
          </p>

          <p>
            Au-delà de la période initiale indiquée sur le bon de commande, et sauf mention contraire indiquée sur le
            Bon de Commande, le Contrat sera renouvelé par tacite reconduction pour des périodes successives de douze
            (12) mois. Les Parties ont la possibilité de ne pas renouveler le Contrat, par l’envoi d’une lettre
            recommandée avec accusé de réception, moyennant le respect d’un préavis de trois (3) mois avant la date
            d’expiration du Contrat.
          </p>

          <p>
            Toute modification des conditions tarifaires et des Services pourvus au Client devra faire l’objet d’un
            nouveau Bon de Commande signé entre les Parties.
          </p>

          <h3>Article 5 - <span class="text-decoration-underline">Prérequis technique</span></h3>
          <h4>5.1 Évaluation préalable</h4>
          <p>
            En signant les présentes, le Client reconnaît et déclare avoir pu vérifier, sous son entière responsabilité
            et avant la conclusion du Contrat, avoir tous les prérequis techniques, tel qu’indiqués par ETX Studio, pour
            utiliser le Service, son interface et les technologies mises en œuvre ou requises pour son utilisation. Il a
            porté une attention particulière à vérifier que le Service et les technologies associées sont parfaitement
            compatibles avec son environnement informatique. La responsabilité d’ETX ne saurait être recherchée dans le
            cas où le Client n’aurait pas effectué ces vérifications préalables, le Client ne pouvant formuler de
            réclamation ou de demande de remboursement sur ce motif.
          </p>

          <h4>5.2 Matériel du Client</h4>

          <p>
            Le matériel de connexion au Service utilisé par le Client est sous son entière responsabilité. Il doit
            prendre toutes les mesures appropriées pour protéger son matériel et ses propres données. Le Client s’engage
            à accéder au Service en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur
            récemment mis-à-jour.
          </p>

          <p>
            ETX n’est pas responsable des dommages causés aux Clients, à des tiers et/ou à leur équipement du fait de
            leur connexion ou de leur utilisation du Service et les Clients renoncent à toute action de ce fait contre
            ETX.
          </p>

          <h3>Article 6 - <span class="text-decoration-underline">Description des services</span></h3>
          <h4>6.1 Accès</h4>
          <p>Le Service est accessible sur Internet à l’adresse (URL) via le Site Internet ou l’API.</p>
          <h4>6.2 Services fournis</h4>
          <p>
            La description des Services fournis par ETX sont indiqués sur le Bon de Commande et décrits en Annexe «
            Description et Utilisation des Services ».
          </p>
          <p>
            Les éventuelles modifications de commande sollicitées par le Client ne seront prises en compte qu’après
            signature par les Parties d’un nouveau Bon de Commande spécifique et ajustement éventuel du Prix.
          </p>
          <h4>6.3 Services complémentaires</h4>
          <p>
            A la demande du Client et selon les modalités définies dans le Bon de Commande, ETX réalisera pour le compte
            du Client des Services complémentaires. Il incombe au Client de définir ses besoins avec précision (en
            fournissant, si besoin, un cahier des charges détaillé validé entre les Parties avant toute signature d’un
            Bon de Commande).
          </p>
          <h4>6.4 Disponibilité</h4>
          <p>
            L’accès au Service est disponible 7 jours sur 7 et 24 heures sur 24. Pour la bonne gestion des Services, ETX
            pourra à tout moment se réserver le droit de :
          </p>
          <ul>
            <li>
              - Suspendre, interrompre ou limiter l’accès à tout ou partie du Service, pour quelque raison que ce soit,
              avec ou sans préavis et à sa seule discrétion ;
            </li>
            <li>
              - Supprimer toute information pouvant en perturber le fonctionnement, pour quelque raison que ce soit avec
              ou sans préavis et à sa seule discrétion ;
            </li>
            <li>- Suspendre le Service afin de procéder à des mises à jour.</li>
          </ul>

          <p>
            ETX s’efforcera au mieux de communiquer préalablement au Client les dates et heures de ses interventions,
            mais sa responsabilité ne saurait être recherchée en cas de non-communication préalable.
          </p>
          <h4>6.4 Réseau internet</h4>
          <p>
            Le Client est informé que la connexion au Service s’effectue via le réseau internet. Il est donc averti des
            aléas techniques pouvant affecter ce réseau, rendant notamment la connexion impossible temporairement. ETX
            ne pourra être tenu pour responsable en cas de dysfonctionnement du réseau internet, des lignes
            téléphoniques ou du matériel informatique et de téléphonie liée notamment à l’encombrement du réseau
            empêchant l’accès au Service.
          </p>
          <h3>Article 7 - <span class="text-decoration-underline">Assistance et Mises à Jour</span></h3>
          <h4>7.1 Disponibilité</h4>
          <p>
            L’équipe technique d’ETX assure l’assistance, par courrier électronique: customer@etxstudio.com. Cette
            assistance est disponible du lundi au vendredi de 10h à 18h hors jours fériés. Dans le cas où le Client
            serait localisé à l’étranger, ces horaires correspondent au créneau horaire applicable sur le territoire de
            France Métropolitaine. Cette assistance permet de fournir au Client des réponses aux dysfonctionnements et
            anomalies rencontrées dans son utilisation du Service. Cette assistance ne couvre pas les besoins du Client
            liés à des problèmes sur son matériel et sur tout logiciel tiers non fournis par ETX.
          </p>
          <h4>7.2 Chatbot</h4>
          <p>
            Via le Service, un Chatbot est disponible permettant au Client de bénéficier d’une assistance utilisateur
            dans son utilisation du Service. Ce Chatbot est disponible aux horaires de disponibilités indiqués à
            l’article 7.1.
          </p>
          <h4>7.3 Mises à jour</h4>
          <p>
            ETX s’engage à fournir des Mises à jour afin d’améliorer continuellement la qualité et/ou les
            fonctionnalités du Service pour ses Clients. Les Mises à Jour réalisées ne doivent pas affecter
            l’utilisation du Service par le Client ou dégrader le Service. Dans le cas contraire, ETX s’engage à
            maintenir, dans les conditions prévues aux présentes, la version antérieure du Service jusqu’à ce qu’une
            nouvelle Mise à Jour soit mise en place, répondant aux critères de qualité d’ETX.
          </p>
          <p>
            ETX prendra à sa charge la correction de toutes les éventuelles Anomalies identifiées sur le Service. Toute
            Anomalie doit être signalée par le Client à ETX dès sa découverte, avec les indications suffisantes pour
            permettre à l’équipe technique d’ETX de résoudre l’anomalie.
          </p>

          <h4>7.4 Limites d’intervention</h4>
          <p>ETX ne fournira pas ses prestations d’assistance et de maintenance pour&nbsp;&nbsp;</p>
          <ul>
            <li>
              - Un usage du Service par le Client non-conforme aux présentes et au Guide d’utilisation&nbsp; ;&nbsp;
            </li>
            <li>- Un non-respect par le Client des CGU&nbsp; ;&nbsp;</li>
            <li>- Une utilisation anormale du Service, et ce quelle qu’en soit la raison&nbsp; ;&nbsp;</li>
            <li>- Un problème de compatibilité entre le Service et le matériel informatique du Client&nbsp; ;&nbsp;</li>
            <li>- Toute contravention par le Client de ses obligations au titre du Contrat.</li>
          </ul>
          <h4>7.5 Sauvegarde</h4>
          <p>
            Les données du Client ainsi que son contenu créé/modifié via la Plateforme font l’objet d’une sauvegarde
            quotidienne via le Cloud par ETX. Sauf cas de force majeure, ETX maintiendra à disposition du Client, les
            données et contenus modifiés/créés via la Plateforme.
          </p>

          <h3>Article 8 - <span class="text-decoration-underline">Prix et modalités de paiement</span></h3>
          <h4>8.1 Prix des Services</h4>
          <p>
            Les Services sont fournis aux tarifs dans les conditions décrites au Bon de commande. ETX facture selon les
            Services souscrits par le Client mentionnés dans le Bon de commande et selon les éventuels Services
            Complémentaires commandés par le Client.
          </p>
          <p>Sauf indication contraire indiquée sur le Bon de Commande&nbsp;&nbsp;</p>
          <ul class="circle-list">
            <li>
              L’accès au Service ne comporte qu’un compte d’accès pour un seul Utilisateur. En cas d’Utilisateur
              multiples, une redevance complémentaire calculée selon le tarif en vigueur sera due ;
            </li>
            <li>
              Chaque abonnement aux Services n’est conclu que pour un seul Site internet du Client, en vue de
              transformer les contenus dudit site. En cas de plusieurs sites internet possédés par le Client, ce dernier
              devra conclure avec ETX plusieurs abonnements distincts ;
            </li>
            <li>
              Toutes les Sources et canaux de diffusion des contenus créés, adaptés et/ou modifiés par le Client via le
              Service sont indiqués sur le Bon de commande. Toute modification desdites modalités de diffusion fera
              l’objet d’une modification du Bon de commande et d’une révision du Prix.
            </li>
          </ul>
          <h4>8.2 Facturation</h4>
          <p>
            Dans le cas où aucune indication ne serait mentionnée sur le Bon de Commande, la facturation est réalisée
            selon l’une ou l’autre des modalités suivantes :
          </p>
          <ul>
            <li>
              - <span class="text-decoration-underline">Facturation trimestrielle</span> : le Client s’engage à payer
              ETX des redevances dues de façon trimestrielle, avec une facture d’acompte d’un trimestre d’avance au
              moment de la conclusion du Bon de Commande ;
            </li>
            <li>
              - <span class="text-decoration-underline">Facturation mensuelle</span> : le Client s’engage à payer ETX
              des redevances dues de façon mensuelle.
            </li>
          </ul>
          <p>
            Les frais de Set Up sont payables dès Conclusion du Bon de commande. Les factures sont payables par le
            Client, par virement bancaire, sous trente (30) jours maximum à compter de la date d’émission de la facture,
            aux coordonnées bancaires fournies par ETX. Les prix sont mentionnés hors taxe et seront par conséquent
            majorés des taxes en vigueur au moment de la facturation par ETX.
          </p>
          <h4>8.3 Retards de paiement</h4>
          <p>
            Tout retard de paiement des factures adressées par ETX ou tout incident de paiement, à compter du jour
            suivant la date d’échéance de celles-ci, doit être régularisé dans les quinze (15) jours suivant l’émission
            de la facture. Passé ce délai, tout retard entraînera de plein droit l’application de pénalités de retard
            d’un montant égal au taux d’intérêt appliqué par la Banque Centrale Européenne majoré de 10 points de
            pourcentage. Elles sont exigibles de plein droit et sans mise en demeure préalable.
          </p>
          <p>
            En outre, en application de <span class="fst-italic">l’article L. 441-10 du Code de commerce</span>, une
            indemnité forfaitaire pour frais de recouvrement de 40,00 € sera due l’une ou l’autre des Parties à la
            partie créancière, et ce même en cas de règlement partiel de la facture non régularisée, sans préjudice de
            toute autre action que la partie créancière serait en droit d’intenter à l’encontre de la partie débitrice
            et aux frais de cette dernière aux fins de recouvrement de ses factures et de tous autres dommages et
            intérêts qui pourraient lui être dus.
          </p>
          <p>
            En cas de non-paiement des factures, ETX se réserve le droit de suspendre l’accès au Service, ou l’exécution
            de toute autre Service Complémentaire, jusqu’à réception effectives des sommes dues. Cette suspension des
            Services ne saurait être considérée comme une résiliation des présentes.
          </p>
          <h4>8.4 Révision du Prix</h4>
          <p>
            Les Prix pratiqués par ETX seront révisés chaque année par ETX à compter de la date d’anniversaire du
            Contrat en fonction de l’évolution de l’indice Syntec. Conformément à l'article 1167 du Code civil, les
            Parties, en cas de disparition de l'indice retenu, utiliseront l'indice le plus proche. Le calcul du prix
            s'effectuera alors sur l'indice de remplacement en utilisant le coefficient de corrélation nécessaire. A
            défaut d'accord sur un nouvel indice, les parties attribuent compétence au Président du Tribunal judiciaire
            de Commerce de Paris statuant selon la procédure accélérée au fond afin de définir un nouvel indice qui
            s'intégrera dans la formule de révision.
          </p>
          <h3>Article 9 - <span class="text-decoration-underline">Obligations des Parties</span></h3>

          <h4>9.1 Obligations d’ETX</h4>

          <p>
            Au titre des présentes, ETX s’engage à mobiliser tous les moyens humains et techniques en sa possession et
            adaptés à ses capacités matérielles et financières pour fournir les Services objet du Contrat dans les
            conditions conformes aux présentes.
          </p>
          <p>
            ETX est tenu à une obligation générale de conseil et de renseignement vis-à-vis du Client pendant
            l’exécution du Contrat et s’engage à lui fournir toute la Documentation permettant un bon usage par le
            Client des Services.
          </p>
          <p>
            ETX s’engage à assurer la confidentialité des données à caractère personnel communiquées par le Client dans
            les conditions prévues à l’<b>Annexe Données Personnelles</b>.
          </p>
          <h4>9.2 Obligations du Client</h4>
          <p>Le Client s’engage à&nbsp;&nbsp;</p>
          <ul>
            <li>
              - S’être assuré, préalablement à la conclusion du Contrat, de l’adéquation du Service à ses besoins et à
              son environnement informatique, et à disposer de tous les prérequis techniques ;
            </li>
            <li>
              - Transmettre l’ensemble des informations nécessaires à l’exécution du Contrat et garantit l’exactitude de
              ces informations ; Toute modification de ces informations devra être signalée par le Client à ETX sous un
              délai maximal de vingt-quatre (24) heures ;
            </li>
            <li>
              - Payer la redevance due telle qu’indiquée dans le Bon de commande ou, en cas de non-indication sur le Bon
              de commande, selon les conditions de l’article 8 ;
            </li>
            <li>- Respecter les dispositions relatives à la Propriété intellectuelle d’ETX&nbsp; ;&nbsp;</li>
            <li>
              - Utiliser le Service dans le respect des CGU fournies par ETX et conformément à la documentation
              applicable ;
            </li>
            <li>
              - Ne pas porter atteinte au bon fonctionnement ou à la sécurité du Service, en ne stockant pas, notamment,
              des données pouvant contenir des virus ou présentant un caractère illicite, contraire aux bonnes mœurs ou
              pouvant porter atteinte au respect des droits d’ETX ou de tiers ;
            </li>
            <li>
              - Assurer ses obligations en matière de sécurité des Données Personnelles telles qu’indiquées à l’<b
                >Annexe Données Personnelles</b
              >
              ;
            </li>
            <li>
              - A disposer du personnel qualifié permettant l’utilisation du Service et à s'assurer de la formation de
              son personnel à l'utilisation du Service ;
            </li>
            <li>- Respecter les obligations de Confidentialité indiquées au Contrat&nbsp; ;&nbsp;</li>
            <li>
              - Ne pas utiliser le Service et notamment l’outil d’édition, à des fins de propagation de Fakes News, à
              des fins de propagande ou de diffusion de propos haineux, racistes, antisémites, xénophobes, sexistes,
              homophobes ou tout autre propos pouvant porter atteinte à l’intégrité physique et morale d’autrui. Le
              Client dégage la responsabilité d’ETX contre tout litige intenté à ce titre par un tiers.
            </li>
          </ul>
          <p>
            Le Client est responsable des dommages causés au Service et/ou à ETX et/ou à un tiers et garantit ETX contre
            tout recours qui pourrait être intenté contre elle par un tiers du fait d’une violation du Client des
            présentes dispositions.
          </p>

          <p>
            Sauf si le Client en fait expressément la demande inverse, le Client autorise ETX à lui adresser des
            courriels ou courriers promotionnels et d’information, à caractère technique et/ou commercial, pendant toute
            la durée du Contrat et pendant deux (2) ans après sa cessation. Le Client pourra à tout moment interrompre
            cette communication en en faisant expressément la demande auprès du service client d’ETX.
          </p>

          <p>
            Le Client fait son affaire personnelle et sous sa seule responsabilité, d’acquérir tout le matériel
            nécessaire ainsi que de souscrire aux abonnements d’accès internet nécessaire pour l’utilisation des
            Services. Les coûts de connexion au Service sont à la charge exclusive du Client.
          </p>

          <p>
            Aux fins de pouvoir utiliser le Service, le Client doit se créer un Compte Utilisateur sur le Site. Pour la
            création de son compte, il lui sera demandé de définir un identifiant de connexion (courriel) ainsi qu’un
            mot de passe. Le Client est seul responsable de la sécurité et de la confidentialité de ses identifiants de
            connexion et devra avertir sans délai ETX en cas de perte ou de vol de ses identifiants. La responsabilité
            d’ETX ne pourrait être recherchée en cas de perte ou de vol des identifiants du Client ayant entraîné une
            utilisation frauduleuse de son Compte.
          </p>

          <p>
            Un seul Compte Utilisateur doit être créé par Client. En cas d’utilisation d’un Compte par plusieurs
            Utilisateurs, sans que cela n’ait donné lieu à une information préalable d’ETX et par conséquent une
            révision du Prix indiqué sur le Bon de Commande, ETX se réserve le droit de suspendre l’exécution des
            Services et de procéder à la fermeture du Compte.
          </p>

          <p>
            Le Client garantit que toutes les informations renseignées lors de la signature du Bon de Commande sont
            exactes, mises à jour et sincères. Il s’engage à ce titre à mettre les informations à jour si nécessaire. La
            responsabilité d’ETX ne pourra être recherchée en cas de données tronquées, inexactes ou manquantes ayant
            entraîné un impact direct ou indirect sur l’exécution des Services.
          </p>
          <h3>Article 10 - <span class="text-decoration-underline">Responsabilités</span></h3>

          <p>
            ETX s’engage à exécuter les Services conformément aux règles de l’art et de la meilleure des manières mais
            n’est tenu qu’une obligation de moyens vis-à-vis du Client et non d’une obligation de résultat.
          </p>

          <p>
            Toutefois, ETX ne prend aucune responsabilité quant au Contenu créé et diffusé par le Client via le Service.
            Le Client est seul responsable du contenu qu’il édite et modifie, que ce soit par une transformation du
            texte, des images ou une transformation audio, que le contenu/texte soit déjà existant sur le Service, ou
            utilisé via API ou Scraping.
          </p>

          <p>
            ETX ne prend aucun engagement vis-à-vis du Client, quant à la visibilité de son contenu modifié et créé via
            le Service, pas plus que sur l’audience dudit contenu auprès du public du Client.
          </p>

          <p>
            ETX n’encourt aucune responsabilité à raison de toute perte de bénéfices, d’exploitation, de clientèles ou
            de trouble ou préjudice commercial, de perte d’image de marque, perte de données, de fichiers ou de
            logiciels que le Client subirait, pas plus que pour toute augmentation des charges internes subis par le
            Client pour utiliser le Service.
          </p>

          <p>
            ETX ne saurait être tenu pour responsable de préjudices autres que ceux résultant directement et
            exclusivement d’une faute dans l’exécution du Service. En conséquence, ETX n’est pas responsable en cas
            d’utilisation du Service par le Client de façon non-conforme aux présentes et aux CGU.
          </p>

          <p>
            Le Client est seul responsable de ses obligations légales, fiscales et administratives, y compris toute
            obligation découlant d’un texte de Loi ou d’un Règlement se rapportant à l’utilisation des Services. Il
            appartient donc au Client de s’assurer du respect de la réglementation applicable et dégage la
            responsabilité d’ETX sur ce motif.
          </p>

          <p>
            En tout état de cause, la responsabilité de ETX est limitée, toutes causes confondues, à 30% des sommes hors
            taxe payées par le Client en contrepartie du Service au cours des douze (12) derniers mois précédant le fait
            générateur du dommage.
          </p>

          <h3>Article 11 - <span class="text-decoration-underline">Confidentialité</span></h3>

          <h4>11.1 Informations confidentielles</h4>

          <p>
            Pendant la durée d’exécution du Contrat, les Parties peuvent être amenées à divulguer à l’autre Parties des
            informations confidentielles telles que des informations commerciales, techniques, marketing, financières,
            industrielles, salariales et toute autre information confidentielle relative à son activité (les
            <b>« Informations Confidentielles »</b>).
          </p>

          <p>
            Les <b>« Informations Confidentielles »</b> désignent les informations dont les Parties prennent
            connaissance dans le cadre de la négociation ou de l’exécution du Contrat, dès lors que ces informations
            portent sur un ou plusieurs éléments financiers, juridiques, industriels, déontologiques, économiques,
            techniques, commerciaux, sont déclarées comme telles par l’autre Partie ou à raison de leur caractère
            confidentiel. Les Informations confidentielles comprennent en particulier, sans limitation, les Données des
            Parties, leur Documentation, les éléments couverts par le Secret des Affaires ou par un ou des Droits de
            propriété intellectuelle.
          </p>
          <h4>11.2 Utilisation des Informations Confidentielles</h4>

          <p>
            Les Parties décideront de manière discrétionnaire de la nature des Informations confidentielles qu’elles
            peuvent être amenées à l’autre Partie pour la réalisation des Présentes. Le Contrat ne saurait entrainer
            aucune obligation pour les Parties de mettre à disposition ou octroyer un droit quelconque sur les
            Informations confidentielles.
          </p>

          <p>
            Les Parties s’engagent pendant toute la durée du Contrat, à ce que les Informations confidentielles
            auxquelles elles auraient accès :
          </p>
          <ul>
            <li>
              ▪ Soient protégées et gardées strictement confidentielles et soient traitées avec, au minimum, le même
              degré de précaution et de protection que la Partie accorde à ses propres Informations confidentielles,
              sans que ce degré puisse être inférieur à un degré de précaution raisonnable ;
            </li>
            <li>
              ▪ Ne soient pas divulguées ou laissées divulguées à un tiers, à titre onéreux ou gratuit, et sous quelque
              forme que ce soit, sans l’accord préalable et écrit de l’autre Partie ;
            </li>
            <li>
              ▪ Ne soient utilisées pendant la durée d’exécution du Contrat que pour les besoins de l’exécution des
              présentes, à l’exclusion de toute autre utilisation ou exploitation sans autorisation écrite préalable de
              l’autre Partie ;
            </li>
            <li>
              ▪ Ne soient ni copiées, ni reproduites, ni dupliquées totalement ou partiellement, lorsque de telles
              copies, reproductions ou duplications (i) ne sont pas strictement nécessaires à la réalisation des
              présentes ou (ii) n’ont pas été autorisées de manière spécifique et par écrite par l’autre Partie.
            </li>
          </ul>

          <p>
            Les Parties s’interdisent de se prévaloir, du fait de la communication des Informations Confidentielles,
            d’un quelconque droit, en ce compris tout Droit de propriété intellectuelle, sur les Informations
            confidentielles autrement que dans les conditions et pour la durée d’exécution prévues au Contrat.
          </p>
          <p>
            Les engagements de confidentialité prévus par cet article s’imposent aux Parties pendant la durée du Contrat
            et pour une durée de 5 (cinq) ans après la fin du Contrat pour quelque cause que ce soit. Elles s'engagent
            également à faire respecter cette obligation par tous les membres de leur personnel concernés, dont elles se
            portent fort.
          </p>
          <p>
            Les engagements de confidentialité prévus par cet article ne s’appliquent pas à toute information
            qui&nbsp;&nbsp;
          </p>
          <ul>
            <li>
              ▪ Était dans le domaine public préalablement à sa divulgation ou est tombée depuis lors dans le domaine
              public sans faute de la part de l’une quelconque des Parties ou d’un tiers, et/ou ;
            </li>
            <li>
              ▪ A été reçue d’un tiers de manière licite, sans restriction ni violation d’un accord de confidentialité
              auquel ce tiers serait parti ;
            </li>
            <li>
              ▪ Sont le résultat de développements internes entrepris de bonne foi une Partie, n’ayant pas eu accès aux
              dites informations, la Partie détenant des documents qui en justifient ;
            </li>
            <li>
              ▪ Doivent être nécessairement être divulguées en vertu d’un règlement particulier ou d’une injonction
              administrative ou judiciaire.
            </li>
          </ul>
          <p>
            Il est précisé que le Contrat, ses annexes et avenants sont également confidentiels et ne sauraient être
            communiqués à des tiers sans l’accord des Parties.
          </p>

          <h3>Article 12 - <span class="text-decoration-underline">Propriété intellectuelle</span></h3>

          <h4>12.1 Connaissances antérieures</h4>

          <p>
            Les Parties déclarent être titulaires de tous les droits de propriété intellectuelle ou autorisations
            nécessaires à l’exécution des présentes. A ce titre, chaque Partie garantit l’autre partie contre toute
            action en contrefaçon, en concurrence déloyale ou parasitaire et plus généralement contre tout trouble
            affectant l’utilisation des connaissances antérieures, émanant de tiers, et ainsi que les frais y afférents.
          </p>

          <p>
            Les Parties demeurent propriétaires ou titulaires de leurs connaissances antérieures et de leurs
            informations confidentielles. Le contrat ne saurait emporter un quelconque transfert de propriété entre les
            Parties ou être interprété comme concession ou licence d’un quelconque droit sur les connaissances
            antérieures ou les informations confidentielles.
          </p>

          <p>
            Chaque Partie pourra autoriser l’autre Partie à faire un usage de ses connaissances antérieures et des
            informations confidentielles qui lui seraient rendues disponibles pour les seuls besoins des présentes et
            pour la durée du contrat. Ce droit d’usage est non exclusif et non cessible et prendra fin à la fin du
            Contrat.
          </p>

          <p>Chaque Partie s’engage à ne pas porter atteinte aux connaissances antérieures de l’autre partie.</p>

          <h4>12.2 Marques</h4>

          <p>
            Les marques verbales et visuelles (<span class="fst-italic">logos</span>) d’ETX sont protégées. Leur
            utilisation sans autorisation écrite d’ETX, sur tout support, à des fins de valorisation de produits ou de
            services, ou à des fins commerciales, est interdite sous peine de poursuites pénales et civiles engagées par
            ETX à l’encontre du Client.
          </p>

          <h4>12.3 Contenu</h4>

          <p>
            Le Client déclare et garantit à ETX qu’il est titulaire et dispose de tous les droits et/ou autorisations
            nécessaires, de quelque nature qu’ils soient, aux fins d’utilisation, de quelque manière que soit, de
            l’ensemble des éléments et de leur contenu (<span class="fst-italic"
              >notamment de tous textes, images, logos, graphiques, photos, films vidéo</span
            >) utilisés, modifiés et/ou créés via le Service.
          </p>

          <p>
            Le Client s’engage à faire respecter les restrictions d’utilisation afférentes au Contenu aux utilisateurs
            finaux et/ou abonnés de ses sites et/ou le cas échéant de ses newsletters, flux RSS, réseaux sociaux et tout
            autre support indiqués sur le Bon de Commande.
          </p>

          <p>
            Le Client s’engage à ne pas reproduire ni communiquer sur leurs sites, réseaux sociaux et publications sur
            support papier, des éléments protégés au titre du droit d'auteur, déposés à titre de marque ou plus
            généralement protégés par tout autre droit de propriété intellectuelle détenu par des tiers sans le
            consentement préalable du ou des détenteurs de tels droits de propriété.
          </p>

          <p>
            Le Client garantit ETX contre toute réclamation éventuelle d’un tiers qui prétendrait avoir un droit de
            propriété, de quelque nature qu’il soit, sur l’un quelconque des éléments publiés et/ou utilisés par lui
            (<span class="fst-italic"> notamment de tous textes, images, logos, graphiques, photos, films vidéo</span>)
            sur les contenus utilisés, modifiés et/ou créés via le Service.
          </p>

          <p>
            Les contenus issus d’ETX et de l’AFP sont utilisables et librement reproductibles par le Client pour les
            supports et canaux de diffusion indiqués au Bon de Commande.
          </p>

          <p>
            En cas de cessation du Contrat, tout contenu créé/modifié par le Client pendant la durée d’exécution du
            Contrat, pourra être maintenu sur les supports et canaux de diffusion indiqués au Bon de Commande.
          </p>

          <h4>12.4 Les Services</h4>

          <p>
            ETX garantit disposer des droits de propriété intellectuelles nécessaires sur les Services proposés au
            Client sur le Bon de Commande.
          </p>

          <p>
            De convention expresse, aucune propriété intellectuelle n’est transférée pour le compte du Client par ETX
            sur les Services et notamment sur les solutions informatiques et techniques intégrées par ETX ainsi que sur
            le code source du Service, lesdites solutions informatiques et techniques faisant l’objet d’une licence
            déposée et protégée par le Droit de la Propriété intellectuelle.
          </p>

          <p>
            Le Client s’interdit tout acte ou comportement pouvant porter atteindre directement ou indirectement aux
            droits de propriété intellectuelle d’ETX.
          </p>

          <p>
            ETX concède au Client, qui l’accepte, et aux Utilisateurs, un droit d’utilisation du Service, non exclusif
            et non cessible, pour la seule durée du Contrat et pour les seuls besoins du Client. Ce droit s’exerce par
            accès distant, via le réseau internet du Client, à compter de la connexion au Service.
          </p>

          <p>
            Cette utilisation n’est autorisée que pour le nombre d’Utilisateurs et pour un seul Site internet du Client,
            tel qu’indiqué sur le bon de commande. A défaut d’indication dans le Bon de commande, l’utilisation n’est
            concédée que pour un (1) seul Utilisateur.
          </p>

          <p>
            Les canaux de diffusion et sources de contenu du Contenu créé, modifié et/ou adapté par le Client via le
            Service sont expressément indiqués sur le Bon de Commande. En conséquence de quoi, le droit d’Utilisation du
            Service est limité exclusivement aux dits Canaux et Sources indiqués sur le Bon de Commande.
          </p>

          <p>
            Ce droit d’Utilisation est subordonné au paiement de l’intégralité des sommes dues dans les conditions
            applicables aux présentes. Toute utilisation du Service de façon non prévue au Contrat est interdite,
            illicite et pourra donner lieu au versement par le Client de dommages et intérêts.
          </p>

          <p>
            Le Client s’interdit de développer un logiciel susceptible de concurrencer directement ou indirectement le
            Service.
          </p>

          <h3>Article 13 - <span class="text-decoration-underline">Garanties</span></h3>

          <p>
            ETX garantit la conformité des Services. La garantie est exclue dans le cas où le Client n’aurait pas
            respecté les conditions d’utilisation précisées par ETX ou dans la Documentation.
          </p>

          <p>
            ETX garantit que les Services ne constituent pas une violation d’un droit de la propriété intellectuelle ni
            un quelconque fait de concurrence déloyale ou parasitaire. Si tout ou partie des Services fournis sont
            reconnus constituer une contrefaçon ou une autre violation de droit d’un tiers ou ne puissent plus être
            utilisés, ETX pourra, à son choix et à ses frais, soit remplacer ou modifier la totalité ou une partie des
            Services, soit obtenir pour le Client une licence d’utilisation.
          </p>

          <p>Cette garantie ne sera acquise au Client qu’à la condition&nbsp;&nbsp;</p>
          <ul>
            <li>
              - Que le Client ait notifié sous huitaine, par écrit, l’action en contrefaçon ou la déclaration ayant
              précédé cette action ; et
            </li>
            <li>
              - Que ETX ait été en mesure d’assurer la défense de ses propres intérêts et de ceux du Client, et pour ce
              faire, que le Client ait collaboré loyalement à ladite défense en fournissant tous les éléments,
              informations et assistance nécessaires pour mener à bien une telle défense.
            </li>
          </ul>
          <p>
            Dans le cas où l’interdiction d’utilisation serait prononcée en conséquence d’une action en contrefaçon ou
            résulterait d’une transaction signée avec le demandeur de l’action en contrefaçon, ETX s’efforcera, à son
            choix et à ses frais, soit :
          </p>
          <ul>
            <li>- D’obtenir le droit pour le Client de poursuivre l’utilisation,</li>
            <li>
              - De remplacer l’élément contrefaisant par un élément équivalent ne faisant pas l’objet d’une action en
              contrefaçon, ou
            </li>
            <li>- De modifier le Service de façon à éviter ladite contrefaçon.</li>
          </ul>
          <p>
            Les dispositions ci-dessus fixent les limites de la responsabilité d’ETX en matière de contrefaçon du fait
            de l’utilisation des Services.
          </p>

          <h3>Article 14 - <span class="text-decoration-underline">Résiliation</span></h3>

          <h4>14.1 Procédure</h4>

          <p>
            En cas de manquement de l’une des Parties à l’une de ses obligations essentielles expressément prévues au
            contrat, l’autre Partie pourra notifier le manquement. Cette notification, valant mise en demeure, devra se
            référer à la présente clause, préciser le manquement considéré et être adressée par lettre recommandée avec
            accusé de réception à la Partie défaillante et indiquer le délai de quinze (15) jours à respecter par cette
            dernière de se conformer à ses obligations. Une telle notification sera irréfragablement présumée avoir été
            reçue au jour de la première présentation de la lettre recommandée précitée au domicile ou au siège de la
            Partie concernée indiqué dans les présentes.
          </p>

          <p>
            En cas de non-exécution de la part de l’autre partie et quinze (15) jours après la mise en demeure restée
            sans effet ou sans réponse de la part du débiteur de l’obligation, le créancier de l’obligation inexécutée
            sera en droit de résilier le Contrat de manière unilatérale, sans préjudice de tous dommages et intérêts et
            de tout autre recours disponible du fait du préjudice subi.
          </p>

          <p>
            La résolution du Contrat ne libérera aucune Partie de ses obligations passées qu’elle pourrait encore avoir
            vis-à-vis de l’autre Partie, dont notamment les obligations de paiement prévues aux présentes, ni ne
            supprimera une responsabilité encourue par une Partie avant ladite résiliation.
          </p>

          <h4>14.2 Dispositions en cas de résiliation</h4>
          <p>En cas de résiliation du Contrat&nbsp;&nbsp;</p>
          <ul>
            <li>
              ▪ Les dispositions concernant l’obligation de confidentialité des Parties demeureront en vigueur pour la
              durée et dans les conditions qui y sont stipulées ;
            </li>
            <li>
              ▪ Les Parties procéderont de façon contradictoire à un arrêté des comptes afin de déterminer les sommes
              éventuellement dues à ETX au jour de la cessation des relations contractuelles ;
            </li>
            <li>
              ▪ Il est expressément convenu entre les Parties que le débiteur d'une obligation de payer aux termes de la
              présente convention, sera valablement mis en demeure par la seule exigibilité de l'obligation,
              conformément aux dispositions de l'article 1344 du Code civil.
            </li>
          </ul>

          <h3>Article 15 - <span class="text-decoration-underline">Force majeure</span></h3>

          <p>
            Ni ETX ni le Client ne pourront être tenus pour responsables si la non-exécution ou le retard dans
            l’exécution de l’une quelconque de leurs obligations, telles que décrites dans les présentes découle d’un
            cas de force majeure, au sens de l’article 1218 du Code Civil.
          </p>
          <p>
            Chaque Partie devra informer l’autre partie, sans délai, de la survenance d’un tel cas lorsqu’elle estime
            qu’il est de nature à compromettre l’exécution de ses obligations contractuelles
          </p>
          <p>
            En cas de survenance d’un événement de force majeure, tel que défini par la loi et la jurisprudence des
            juridictions françaises, rendant impossible pour l’une des Parties, l’exécution de ses obligations pendant
            plus de trente (30) jours, et si le cas de force majeure perdure, chacune des Parties aura le droit de
            résoudre le présent contrat de plein droit, sans qu’aucune indemnité ne soit due par l’une ou l’autre des
            parties, par Lettre recommandée avec avis de réception adressée à l’autre partie.
          </p>
          <p>
            Les Parties ne pourront en aucun cas être tenues responsables de toutes inexécutions et/ou ruptures de
            Contrat nées d’un cas de force majeure et aucune indemnité ne pourra être réclamée par une Partie à l’autre
            à ce titre.
          </p>
          <p>
            Toutefois, dès la disparition du cas de force majeure à l’origine de la suspension de leurs obligations
            respectives, les parties devront entreprendre tous les efforts possibles pour reprendre le plus rapidement
            l’exécution normale de leurs obligations contractuelles. La partie empêchée devra avertir l’autre, par
            lettre recommandée avec avis de réception, de la reprise de son obligation.
          </p>

          <h3>Article 16 - <span class="text-decoration-underline">Déclaration d’indépendance réciproque</span></h3>

          <p>
            Les Parties déclarent expressément qu'elles sont et demeureront, pendant toute la durée du présent contrat,
            des partenaires commerciaux et professionnels indépendants.
          </p>

          <h3>Article 17 - <span class="text-decoration-underline">Assurances</span></h3>
          <p>
            Les Parties s'engagent à souscrire une police d'assurance garantissant leur responsabilité civile
            professionnelle pour toutes les activités et obligations découlant du Contrat.
          </p>
          <p>
            Elles s'engagent à maintenir cette police pendant toute la durée du Contrat et en apporter la preuve sur
            demande en fournissant à l'autre Partie une attestation de ses assureurs, énumérant les garanties
            souscrites, leur montant et leur durée de validité.
          </p>

          <h3>Article 18 - <span class="text-decoration-underline">Référence commerciale</span></h3>
          <p>
            Le Client autorise ETX à utiliser son nom, son sigle et son logo sur son site Web et sur tout autre document
            d’ETX à des fins de référence commerciale.
          </p>

          <h3>Article 19 - <span class="text-decoration-underline">Fin de Contrat et Destruction des données</span></h3>
          <p>
            En cas de cessation des relations contractuelles et pour quel que motif que ce soit, le droit d’Utilisation
            conféré au Client prendra fin. Le Client disposera d’un délai de trente (30) jours pour collecter et
            détruire tous les contenus qu’il aura créé via le Service, sans solliciter l’intervention d’ETX et sans
            pouvoir prétendre à quelque indemnisation que ce soit. Passé ce délai, le contenu sera supprimé.
          </p>

          <p>Au terme du Contrat&nbsp;&nbsp;</p>
          <ul class="circle-list">
            <li>
              L’outil « Player » installé sur les Canaux de communication et supports de diffusion du Client sera retiré
              ;
            </li>
            <li>Le Client pourra conserver les MP3 créés via le Service&nbsp; ;&nbsp;</li>
            <li>
              A la demande du Client ayant utilisé le Service via un Scraping, le contenu créé/modifié par le Client et
              stocké chez ETX pourra être transféré au Client ;
            </li>
            <li>
              Le contenu texte créé/modifié par le Client via les Services pourra être maintenu sur les supports et
              canaux de diffusion du Client indiqués au Bon de Commande ;
            </li>
            <li>
              Le Client ne pourra plus consulter le Contenu presse et éditorial auquel il avait accès au titre de
              l’exécution du Contrat. Le contenu ETX-AFP devra être supprimé dans les 30 jours suivant la résiliation du
              Contrat.
            </li>
          </ul>

          <h3>Article 20 - <span class="text-decoration-underline">Dispositions diverses</span></h3>

          <p>
            <span class="text-decoration-underline">Divisibilité</span> : Si une ou plusieurs stipulations des présentes
            sont tenues pour non valides ou déclarées nulles en application d’une loi ou d’un règlement ou à la suite
            d’une décision de justice ayant autorisé de la chose jugée, cela ne remet pas en cause les autres clauses du
            Contrat ;
          </p>

          <p>
            <span class="text-decoration-underline">Non-renonciation</span> : Le fait que les Parties ne se soient pas
            prévalues d’un manquement par l’autre partie à l’une des obligations visées dans les présentes ne sera pas
            interprété pour l’avenir comme une renonciation à l’obligation en cause ;
          </p>

          <p>
            <span class="text-decoration-underline"> Nullité partielle</span> : En cas de contradiction entre une
            stipulation des présentes et tout texte législatif, loi, ordonnance, réglementation, ordonnance judiciaire
            ou convention collective, présent ou futur, ces derniers prévaudront, sous réserve que la stipulation des
            présentes ainsi affectée ne soit limitée que dans la mesure nécessaire et qu’aucune autre stipulation ne
            soit affectée ;
          </p>

          <p>
            <span class="text-decoration-underline">Absence d’affectio societatis</span> : Les Parties conviennent que
            le Contrat exclu toute volonté de créer une société, une association ou toute autre structure ayant pour
            objet de mettre en commun des compétences ou de partager de quelconques bénéfices sous quelques formes que
            ce soit.
          </p>

          <p>
            <span class="text-decoration-underline">Non-cession</span> : Le contrat est conclu intuitu personae en
            considération de l’identité des Parties. Les Parties s’interdisent donc de céder le Contrat à un tiers sans
            l’accord préalable et écrit de l’autre Partie.
          </p>

          <h3>Article 21 - <span class="text-decoration-underline">Loi applicable et règlement des litiges</span></h3>

          <p>Le Contrat est soumis à l’application du droit français.</p>

          <p>
            Les Parties s’engagent à rechercher une solution amiable à tout différend qui pourrait naître de
            l’interprétation ou de l’exécution du contrat. A ce titre, la partie qui souhaite mettre en jeu la procédure
            amiable de conciliation devra notifier à l’autre partie, par lettre recommandée avec demande d’accusé de
            réception, son intention de mettre en jeu ladite procédure en précisant les difficultés d’application
            rencontrées ou les manquements constatés.
          </p>
          <p>
            La présente procédure de règlement amiable constitue un préalable obligatoire à l'introduction d'une action
            en justice entre les Parties. Toute action introduite en justice en violation de la présente clause serait
            déclarée irrecevable.
          </p>
          <p>
            Si les parties ne parviennent pas à trouver un accord amiable sous un délai de trente (30) jours calendaires
            suivant la première notification, chacune d’entre elles recouvrera sa pleine liberté d’action.
          </p>
          <p>
            A défaut de parvenir à une résolution amiable malgré les diligences entreprises, tout litige relatif à
            l'exécution, à l’interprétation, à la validité et à la résolution du Contrat sera soumis à la compétence
            exclusive du Tribunal de Commerce de Paris, même en cas de pluralité de défendeurs, d’appel en garantie ou
            de référé.
          </p>

          <p class="fw-bold">Annexes:</p>
          <ul>
            <li>- Annexe n°1 : Description des Services</li>
            <!--<li>- Annexe n°2 : Guide d’Utilisation</li>-->
            <li>- Annexe n°2 : Traitement des Données personnelles</li>
            <li>- Annexe n°3 : Conditions générales d’utilisation du Site</li>
          </ul>

          <h2>ANNEXE N°1 - DESCRIPTION ET CONDITION D’UTILISATION DES SERVICES</h2>
          <h3>Article 1 - <span class="text-decoration-underline">Accès à du Contenu Presse et éditorial</span></h3>
          <h4>1.1 Type de contenu</h4>
          <p>Le Client a accès à une base de données de contenus provenant&nbsp;&nbsp;</p>
          <ul class="circle-list">
            <li>De rédacteurs d’ETX Studio&nbsp; ;&nbsp;</li>
            <li>
              De l’AFP au titre d’un contrat de partenariat noué entre ETX et l’Agence Française de Presse permettant la
              diffusion de ces contenus sur le Service&nbsp; ;&nbsp;
            </li>
            <li>De diverses sources d’information externes&nbsp; ;&nbsp;</li>
            <li>De banques d’images libres de droit.</li>
          </ul>

          <p>
            Le Client peut également importer sur le Service son propre Contenu pour y apporter les modifications
            nécessaires.
          </p>

          <p>Selon les modalités inscrites sur le Bon de Commande, le Client bénéficie&nbsp;&nbsp;</p>
          <ul class="circle-list">
            <li>D’un abonnement au Contenu mis à disposition sur le Service&nbsp; ;&nbsp;</li>
            <li>D’une consultation du Contenu&nbsp; ;&nbsp;</li>
            <li>
              D’une consultation et publication du Contenu sur le Site Web et/ou les réseaux sociaux du Client et sur
              tout autre canal de diffusion indiqué sur le Bon de Commande ;
            </li>
            <li>
              D’un contenu personnalisé à la demande (réécriture optimisée pour le SEO, rédaction pure de contenu).
            </li>
          </ul>
          <p>Les options souscrites par le Client au titre de cet accès sont inscrites sur le Bon de Commande.</p>

          <h4>1.2 Publication du Contenu sur les canaux et supports de communication du Client</h4>
          <p>
            Le Client peut publier le Contenu dont il a accès via le Service sur les canaux de diffusion indiqués sur le
            Bon de commande. Il peut publier ledit contenu intégralement ou partiellement pour l’adapter à sa charte
            éditoriale. Cette fonctionnalité est exclue pour les contenus accessibles sur le Service et dont ETX ne
            détient pas les droits (tout contenu à l’exclusion du contenu rédigé par ETX et l’AFP.
          </p>
          <p>L’utilisation de cette fonctionnalité doit répondre aux critères suivants&nbsp;&nbsp;</p>

          <ul class="circle-list">
            <li>
              La publication d’une vidéo ou d’une image doit être associée à l’article original pour en conserver le
              contexte éditorial et pour illustrer un texte dans son contexte initial. Aucune autre utilisation n’est
              autorisée par ETX Studio et cette dernière ne pourra être tenue responsable de toute utilisation du
              Contenu par le Client hors de son contexte initial ;
            </li>
            <li>
              Dans le cas où une modification du Contenu est permise, le Client ne pourra le faire que sous réserve de
              ne pas porter atteinte aux droits d’ETX et de l’AFP (ou de tous tiers auprès desquels ETX aura obtenu les
              droits d’exploitation) et de ne pas dénaturer le sens de ce contenu ou de rendre celui-ci diffamatoire
              et/ou contrevenant aux droits de propriété intellectuelle d’un tiers ou tout autre droit de propriété,
              droit au respect à la vie privée ou droit à l’image ;
            </li>
            <li>
              Respect de toutes les restrictions d’utilisation accompagnant le contenu et précisées au Contrat&nbsp;
              ;&nbsp;
            </li>
            <li>
              Publication uniquement de contenus dont les droits d’auteur sont détenus par ETX et/ou l’AFP&nbsp; ;&nbsp;
            </li>
            <li>
              Le Client doit bénéficier de toutes les autorisations requises et de tous les droits relatifs à la
              Propriété intellectuelle dès lors qu’il utiliserait des images importées par lui et/ou non fournies par
              ETX.
            </li>
          </ul>
          <p>
            La rediffusion partielle ou totale du contenu à titre gratuit ou onéreux sur quelque support et par quelque
            moyen que ce soit à tout tiers est strictement interdite, sauf autorisation expresse préalable et écrite
            d’ETX. Le Client s’engage à n’exploiter le Service que dans le cadre de l’animation de ses Sites internet,
            réseaux sociaux et supports web, technologiques et informatiques.
          </p>

          <p>
            Le Client s’engage en outre à ne pas intégrer les vidéos, images ou articles sur des sites ou services
            appartenant aux catégories suivantes :
          </p>
          <ul class="circle-list">
            <li>
              Sites prônant la discrimination, la haine ou la violence en raison de la race, de l’ethnie, de l’origine,
              de la religion, du handicap, de l’orientation sexuelle, des opinions politiques, etc.
            </li>
            <li>
              Sites portant atteinte aux droits des personnes et des biens, notamment aux droits de propriété
              intellectuelle de tiers ;
            </li>
            <li>
              Sites faisant la promotion de produits stupéfiants, de l’industrie de l’alcool ciblant des mineurs et/ou
              en respectant pas la règlementation en matière de publicité, de tabac ou d’armes ;
            </li>
            <li>Sites incitant aux crimes et/ou aux délits&nbsp; ;&nbsp;</li>
            <li>Sites contrevenant à l’ordre public et aux bonnes mœurs&nbsp; ;&nbsp;</li>
            <li>Sites à caractère pornographique et pédopornographique.</li>
          </ul>
          <h4>1.3 Restrictions d’utilisation</h4>
          <p>Le Client s’engage en outre&nbsp;&nbsp;</p>
          <ul class="circle-list">
            <li>
              A maintenir le crédit photo d’ETX et de celui de ses partenaires ainsi que le copyright, tels qu’ils sont
              indiqués sur le Service sous chaque photo ;
            </li>
            <li>
              A indiquer la mention « Source ETX » ou « Source AFP », lors de la publication de textes sur les supports
              de diffusion et de communication indiqués au Bon de commande ;
            </li>
            <li>
              A indiquer, sur chaque page des sites ou autres supports du Client indiqués au Bon de Commande où sont
              publiés les contenus, la source : « @nom de l’auteur/propriétaire des droits » ;
            </li>
            <li>A publier les contenus uniquement sur internet quand la mention @webonly est indiquée&nbsp; ;&nbsp;</li>
            <li>
              A veiller à ce que le contenu partagé sur les réseaux sociaux du Client et indiqués sur le Bon de Commande
              renvoie vers le site Web du Client dans le respect des conditions énumérées ci-dessus ;
            </li>
            <li>
              A respecter les restrictions liées à la couverture de certains évènements, notamment les évènements
              culturels.
            </li>
          </ul>
          <h3>
            Article 2 -
            <span class="text-decoration-underline">Studio - Conversion d’un fichier texte en audio/vidéo</span>
          </h3>
          <h4>2.1 Fonctionnalités</h4>
          <p>
            Via le Service, le Client peut avoir accès à une fonctionnalité permettant de transformer son contenu texte,
            ainsi que le contenu mis à disposition par ETX ou l’AFP, en fichier audio ou en vidéo.
          </p>
          <p>Le nombre de transformations réalisables par le Client est indiquée sur le Bon de Commande.</p>
          <p>Au sein du studio, le Client doit suivre les étapes suivantes&nbsp;&nbsp;</p>
          <ul class="circle-list">
            <li>Insérer ses contenus texte, image et ses éléments sonores (jingle, interview…)&nbsp; ;&nbsp;</li>
            <li>Sélectionner une voix principale&nbsp; ;&nbsp;</li>
            <li>Sélectionner les formats attendus (audio, vidéo carré, vidéo portrait, vidéo paysage)&nbsp; ;&nbsp;</li>
            <li>Cliquer sur le bouton “générer”.</li>
          </ul>
          <p>Lors de la phase de conception, il est possible&nbsp;&nbsp;</p>
          <ul class="circle-list">
            <li>D’insérer plusieurs visuels&nbsp; ;&nbsp;</li>
            <li>
              De modifier toute prononciation de mots inexacte (et ainsi se constituer un lexique de prononciation qui
              participe à l’amélioration de l’expérience globale)
            </li>
            <li>
              De rythmer la cadence de l’audio en ajoutant des “pauses” ou en alternant les voix de synthèse selon les
              paragraphes ou les citations.
            </li>
          </ul>
          <p>
            Les formats générés sur le studio peuvent être téléchargés, prévisualisés, copiés collés dans un outil tiers
            (via code embed) et sont sauvegardés dans la section “créations”, afin de pouvoir être ré-exploités par la
            suite, et/ou clonés, modifiés, consultés, téléchargés.
          </p>

          <h4>2.1 Publication du Contenu sur les canaux et supports de communication du Client</h4>

          <p>
            Les conditions décrites à l’article 1.2 de la présente annexe sont applicables à tout fichier audio/vidéo
            converti par le Client via la Solution.
          </p>

          <h4>2.3 Restrictions d’utilisation</h4>

          <p>
            Les conditions décrites à l’article 1.3 de la présente annexe sont applicables à tout fichier audio/vidéo
            converti par le Client via la Solution.
          </p>

          <h3>Article 3 - <span class="text-decoration-underline">Accès gratuit aux Services</span></h3>

          <p>
            ETX peut fournir, à sa seule discrétion, un accès gratuit et limité aux Services aux Utilisateurs en faisant
            la demande. La durée d’utilisation des Services est limitée, dans le cadre de cet essai à 14 jours.
          </p>

          <p>
            Les dispositions applicables aux articles 1 et 2 des présentes sont également applicables à toute
            utilisation des Services dans le cas d’un essai gratuit.
          </p>

          <p>
            Si à la fin de la période d’essai, l’Utilisateur souhaite prolonger l’utilisation des Services, il devra
            signer un Bon de Commande avec ETX. Dans le cas où l’Utilisateur ne souhaiterait pas prolonger l’utilisation
            des Services, il dispose d’un délai de 3 (jours) pour retirer tout le contenu qu’il aurait créé/modifié via
            le Service. Passé ce délai, toutes les données et créations de l’Utilisateur seront supprimées.
          </p>

          <h2>ANNEXE N°2 - DONNEES A CARACTÈRE PERSONNEL</h2>

          <p>
            L’accès au Service nécessite la collecte et le traitement de données à caractère personnelles du Client.
          </p>

          <p>
            La politique de protection des données d’ETX a été établie en accord avec les préconisations de la
            Commission Nationale de l’informatique et des libertés. Elle a pour objectif d’informer les utilisateurs du
            Service et les Clients de la façon dont leurs données personnelles sont traitées et des engagements et
            mesures que nous avons pris afin de veiller au respect des données à caractère personnel des Clients et
            Utilisateurs.
          </p>

          <p>
            Elle a été établie conformément aux dispositions de
            <span class="fst-italic">la Loi Informatique et Liberté du 6 janvier 1978</span>, du
            <span class="fst-italic">
              Règlement Européen sur les <b>Données</b> Personnelles (R.G.P.D.) du 23 mai 2018</span
            >
            et la
            <span class="fst-italic"
              >Loi de transposition du Règlement Européen sur la Protection des Données Personnelles (R.G.P.D.) du 20
              juin 2018</span
            >.
          </p>

          <p>
            La version actuellement actuelle est la version en vigueur de cette politique. ETX se réserve le droit de la
            modifier à tout instant afin de se conformer aux obligations légales en vigueur. Le cas échéant, ETX en
            communiquera une nouvelle copie au Client. Toute question relative à cette politique peut être formulée à
            l’adresse suivante : dataprivacy@etxstudio.com.
          </p>

          <h3>Article 1 - <span class="text-decoration-underline">Définitions</span></h3>

          <p>
            Les termes ci-dessous ont la signification qui leur est attribuée au sens du R.G.P.D. (art.4)&nbsp;&nbsp;
          </p>
          <ul class="circle-list">
            <li>
              <span class="fw-bold">Consentement</span> :
              <span class="fst-italic">
                « de la personne concernée, toute manifestation de volonté, libre, spécifique, éclairée et univoque par
                laquelle la personne concernée accepte, par une déclaration ou par un acte positif clair, que des
                données à caractère personnel la concernant fassent l'objet d'un traitement; »</span
              >
            </li>

            <li>
              <span class="fw-bold">Responsable du traitement</span> :
              <span class="fst-italic"
                >« la personne physique ou morale, l'autorité publique, le service ou un autre organisme qui, seul ou
                conjointement avec d'autres, détermine les finalités et les moyens du traitement; lorsque les finalités
                et les moyens de ce traitement sont déterminés par le droit de l'Union ou le droit d'un État membre, le
                responsable du traitement peut être désigné ou les critères spécifiques applicables à sa désignation
                peuvent être prévus par le droit de l'Union ou par le droit d'un État membre; »</span
              >
            </li>

            <li>
              <span class="fw-bold">Sous-traitant</span> :
              <span class="fst-italic"
                >« la personne physique ou morale, l'autorité publique, le service ou un autre organisme qui traite des
                données à caractère personnel pour le compte du responsable du traitement; »</span
              >
            </li>

            <li>
              <span class="fw-bold">Traitement</span> :
              <span class="fst-italic"
                >« toute opération ou tout ensemble d'opérations effectuées ou non à l'aide de procédés automatisés et
                appliquées à des données ou des ensembles de données à caractère personnel, telles que la collecte,
                l'enregistrement, l'organisation, la structuration, la conservation, l'adaptation ou la modification,
                l'extraction, la consultation, l'utilisation, la communication par transmission, la diffusion ou toute
                autre forme de mise à disposition, le rapprochement ou l'interconnexion, la limitation, l'effacement ou
                la destruction; »</span
              >
            </li>

            <li>
              <span class="fw-bold">Violation de données à caractère personnel</span> :
              <span class="fst-italic"
                >« une violation de la sécurité entraînant, de manière accidentelle ou illicite, la destruction, la
                perte, l'altération, la divulgation non autorisée de données à caractère personnel transmises,
                conservées ou traitées d'une autre manière, ou l'accès non autorisé à de telles données; ».</span
              >
            </li>
          </ul>

          <h3>
            Article 2 - <span class="text-decoration-underline">Qu’est-ce qu’une donnée à caractère personnel ?</span>
          </h3>

          <p>
            Les données à caractère personnel (les « <span class="fw-bold">Données Personnelles</span> ») désignent
            toute information pouvant identifier directement ou indirectement une personne physique (nom, prénom,
            e-mail, n° de téléphone, adresse postale).
          </p>

          <p>
            A ce titre, le RGPD définit une donnée à caractère personnel comme : «
            <span class="fst-italic"
              >toute information se rapportant à une personne physique identifiée ou identifiable (ci-après dénommée
              «personne concernée») ; est réputée être une «personne physique identifiable» une personne physique qui
              peut être identifiée, directement ou indirectement, notamment par référence à un identifiant, tel qu'un
              nom, un numéro d'identification, des données de localisation, un identifiant en ligne, ou à un ou
              plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique,
              économique, culturelle ou sociale;</span
            >
            ».
          </p>

          <h3>Article 3 - <span class="text-decoration-underline">La collecte des données personnelles</span></h3>

          <p class="text-decoration-underline">Nous recueillons votre consentement.</p>

          <p>
            En utilisant nos Services, le Client et l’Utilisateur consentent à la collecte et au traitement des données
            personnelles qu’ils renseignent. Ce consentement sert de base légale au traitement des données collectées.
          </p>

          <p>
            ETX collecte et traite les données strictement nécessaires aux finalités pour lesquelles elles sont
            traitées. Nous nous engageons auprès de nos Clients à ne pas traiter les données pour des finalités
            différentes que celles mentionnées ci-dessous. Si nous devions proposer un nouveau service, nécessitant la
            collecte et le traitement de données, nous nous engageons à collecter l’accord de nos Client une nouvelle
            fois avant de lui proposer le nouveau service dont il est question.
          </p>

          <p>
            Chaque fois qu’ETX traite des données personnelles, elle prend toutes les mesures raisonnables pour
            s’assurer de l’exactitude et de la pertinence des données personnelles au regard des finalités pour
            lesquelles elle les traite.
          </p>

          <h3>Article 4 - <span class="text-decoration-underline">Finalité des traitements</span></h3>

          <p>
            Nous collectons les données des Clients uniquement pour les finalités de traitement suivantes&nbsp;&nbsp;
          </p>

          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Canal de collecte</th>
                <th scope="col">Données collectées</th>
                <th scope="col">Finalités</th>
                <th scope="col">Base légale du traitement</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Création d’un compte Client</th>
                <td>Nom, prénom, e-mail</td>
                <td>Permet de fournir aux Clients un accès au Service</td>
                <td>Exécution d’un Contrat</td>
              </tr>
              <tr>
                <th scope="row">Création d’un compte Utilisateur</th>
                <td>Nom, prénom, e-mail</td>
                <td>
                  Permet de créer et d’administrer le Compte utilisateur et d’en rajouter si une mention est faite au
                  Bon de Commande.
                </td>
                <td>Exécution d’un Contrat</td>
              </tr>
              <tr>
                <th scope="row">Assistance</th>
                <td>Nom, prénom, e-mail</td>
                <td>
                  Nous collectons les données aux fins d’assurer l’assistance à notre Service en application de
                  l’article 7 des Conditions.
                </td>
                <td>Consentement de l’Utilisateur Exécution d’un Contrat</td>
              </tr>
              <tr>
                <th scope="row">Navigation sur notre Site</th>
                <td>Cookies</td>
                <td>
                  Notre Site collecte vos informations de navigation à des fins uniquement d’études statistiques et
                  d’autres finalités évoquées dans notre politique de cookies. Nous vous invitons à vous référer à notre
                  politique correspondante en matière de cookies.
                </td>
                <td>
                  Le traitement est nécessaire aux fins des intérêts légitimes poursuivis par la Société, notamment de
                  proposer aux utilisateurs une expérience de navigation fluide et améliorée.
                </td>
              </tr>
              <tr>
                <th scope="row">
                  Prévenir et lutter contre la fraude informatique (<span class="fst-italic"
                    >spamming, hacking, etc.</span
                  >).
                </th>
                <td>Matériel informatique utilisé pour la navigation, l’adresse IP, le mot de passe.</td>
                <td>Prévenir et lutter contre la fraude informatique.</td>
                <td>La réponse à une obligation légale.</td>
              </tr>
            </tbody>
          </table>
          <h3>Article 5 - <span class="text-decoration-underline">Durée de conservation des données</span></h3>

          <p>
            Nous ne dépassons pas les délais légaux de conservation des données et vos données sont conservées
            uniquement pour la durée nécessaire aux traitements des finalités pour lesquelles elles ont été collectées.
            Dans l’établissement de notre politique de traitement des données, nous avons établi une grille de référence
            pour la durée de conservation des données, élaborées grâce aux recommandations de la CNIL.
          </p>

          <p>
            En outre, ETX est susceptible de conserver certaines données personnelles afin de remplir ses obligations
            légales ou réglementaires, de permettre l’exercice des droits des utilisateurs, ou à des fins statistiques.
          </p>

          <p>
            A l’expiration de la durée de conservation des données personnelles, celles-ci seront supprimées ou
            anonymisées.
          </p>

          <h3>Article 6 - <span class="text-decoration-underline">Accès aux données</span></h3>

          <p>Les données collectées sur le Site sont destinées exclusivement&nbsp;&nbsp;</p>
          <ul>
            <li>
              ▪ <span class="fw-bold">Au personnel habilité d’ETX</span> dans la cadre de la fourniture des Services ;
            </li>

            <li>
              ▪ <span class="fw-bold">Au personnel désigné et habilité de l’hébergeur - (AWS)</span> - dans la limite de
              leurs attributions respectives, pour
              <span class="text-decoration-underline">l’hébergement des Service</span>. Ces derniers disposent d'un
              accès limité aux données du Client et des Utilisateurs, dans le cadre de l'exécution de ces prestations et
              ont une obligation contractuelle de les utiliser en conformité avec les dispositions de la réglementation
              applicable en matière protection des données à caractère personnel
            </li>
          </ul>
          <p>
            Les données peuvent également être transmises par ETX à des tiers et autorités compétentes pour répondre à
            des obligations légales, judiciaires, fiscales ou règlementaires. ETX garantit au Client qu’aucun transfert
            de données personnelles ne sera effectué à un tiers non autorisé, sans l’accord préalable, volontaire,
            éclairé, exprès et écrit du Client.
          </p>
          <p>
            ETX s’interdit de traiter, héberger ou transférer les Informations collectées sur ses utilisateurs vers un
            pays situé en dehors de l’Union européenne ou reconnu comme « <span class="fst-italic">non adéquat</span> »
            par la Commission européenne sans en informer préalablement le propriétaire des données et sans avoir
            recueilli son accord.
          </p>

          <h3>Article 7 - <span class="text-decoration-underline">Protection des données</span></h3>

          <p>
            ETX a pris toutes les précautions utiles et nécessaires, au regard de l’état de l’art en la matière, pour
            protéger les informations dans un environnement sécurisé en vue d’éviter toute destruction, perte,
            altération, diffusion ou accès non autorisé. Quels que soient les efforts fournis, aucune méthode de
            transmission sur Internet et aucune méthode de stockage électronique n'est complètement sûre. Nous ne
            pouvons en conséquence pas garantir une sécurité absolue. Si nous prenions connaissance d'une brèche de la
            sécurité, nous avertirions les utilisateurs concernés afin qu'ils puissent prendre les mesures appropriées.
            Nos procédures de notification d’incident tiennent compte de nos obligations légales, qu'elles se situent au
            niveau national ou européen.
          </p>

          <p>
            Dans le cas où l’intégrité et la confidentialité de vos données sont compromises, le responsable du
            traitement s’engage à respecter les procédures mises en place au titre de la
            <span class="fw-bold">Loi Informatiques et Libertés du 6 janvier 1978</span> et du
            <span class="fw-bold">Règlement Européen sur la Protection des Données (« RGPD »)</span>.
          </p>

          <p>ETX n’exerce aucune revente ni externalisation des données de ses clients et utilisateurs.</p>

          <h3>Article 8 - <span class="text-decoration-underline">Droits des personnes</span></h3>

          <p>
            Au regard des dispositions légales de la
            <span class="fst-italic">Loi Informatiques et Libertés du 6 janvier 1978</span> et du
            <span class="fst-italic">Règlement Européen sur la Protection des Données (« R.G.P.D »)</span>, les
            Utilisateurs de notre Service disposent des droits suivants :
          </p>
          <ul>
            <li>
              a. <span class="fw-bold">droit d'accès</span> (article 15 RGPD) et de
              <span class="fw-bold">rectification</span> (article 16 RGPD), de <span class="fw-bold">mise à jour</span>,
              de <span class="fw-bold">complétude</span> des données des Utilisateurs, droit de
              <span class="fw-bold">verrouillage</span> ou <span class="fw-bold">d’effacement</span> des données des
              Utilisateurs à caractère personnel (article 17 du RGPD), lorsqu’elles sont inexactes, incomplètes,
              équivoques, périmées, ou dont la collecte, l'utilisation, la communication ou la conservation est interdit
            </li>
            <li>b. droit de <span class="fw-bold">retirer</span> à tout moment un consentement (article 13-2c RGPD)</li>
            <li>
              c. droit à la <span class="fw-bold">limitation</span> du traitement des données des Utilisateurs (article
              18 RGPD)
            </li>
            <li>
              d. droit <span class="fw-bold">d’opposition</span> au traitement des données des Utilisateurs (article 21
              RGPD)
            </li>
            <li>
              e. droit à la <span class="fw-bold">portabilité</span> des données que les Utilisateurs auront fournies,
              lorsque ces données font l’objet de traitements automatisés fondés sur leur consentement ou sur un contrat
              (article 20 RGPD)
            </li>
            <li>
              f. droit de <span class="fw-bold">définir le sort des données</span> des Utilisateurs après leur mort et
              de choisir à qui ETX devra communiquer (ou non) ses données à un tiers qu’il aura préalablement désigné.
            </li>
          </ul>
          <p>
            Notre site et ETX ne peuvent pas s’opposer à la demande d’exercice de leurs droits par les utilisateurs.
          </p>
          <p>Pour exercer l’un de vos droits, il vous suffit&nbsp;&nbsp;</p>
          <ul>
            <li>- D’écrire un courrier <span class="fw-bold">ETX, à l’adresse du siège social</span>&nbsp; ;&nbsp;</li>
            <li>- Ou d’écrire un courriel à l’adresse suivante : dataprivacy@etxstudio.com.</li>
          </ul>
          <p>En indiquant votre nom, prénom, adresse et en joignant un justificatif d’identité.</p>
          <p>
            Les demandes seront traitées dans un délai d’un mois, sauf motif impérieux avancé et justifié par ETX
            justifiant un rallongement du délai.
          </p>

          <h3>Article 9 - <span class="text-decoration-underline">Responsable du traitement des données</span></h3>
          <ul>
            <li>
              a) <span class="text-decoration-underline">Au sein de ETX</span> <br />Le responsable du traitement de vos
              données de ETX est joignable à l’adresse suivante : dataprivacy@etxstudio.com ou par courrier à l’adresse
              de la Société en adressant votre Courrier à Mr/Mme Le Responsable du Traitement. En tant que responsable
              du traitement des données qu’il collecte, il s’engage à respecter le cadre des dispositions légales en
              vigueur. Il lui appartient notamment de fournir aux utilisateurs, une information complète sur le
              traitement de leurs données personnelles et de maintenir un registre des traitements conforme à la
              réalité.
            </li>
            <li>
              b) <span class="text-decoration-underline">Saisine de la Cnil</span><br />
              Si ETX ne satisfait pas la demande de l’utilisateur, ce dernier est en droit de saisir la CNIL (<span
                class="fst-italic"
                >Commission Nationale de l’Informatique et des Libertés, https://www.cnil.fr</span
              >) afin de faire prévaloir ses droits.
            </li>
          </ul>

          <h3>
            Article 10 - <span class="text-decoration-underline">Modification de la politique de confidentialité</span>
          </h3>

          <p>
            ETX se réserve le droit de modifier la présente Politique à tout moment. Si une modification est apportée à
            la présente Politique, la nouvelle version sera publiée immédiatement sur le Site. Si l’Utilisateur n’est
            pas d’accord avec les termes de la nouvelle rédaction de la politique, il a la possibilité de ne plus
            utiliser les Services du Site et de ne plus naviguer sur le Site.
          </p>
          <h2>ANNEXE N°3 – CONDITIONS D’UTILISATION DE LA PLATEFORME</h2>

          <p>
            La poursuite de la navigation sur la Plateforme (ci-après la «
            <span class="fw-bold">Plateforme</span> ») vaut de la part des utilisateurs une acceptation sans réserve des
            dispositions et conditions d’utilisation qui suivent (ci-après dénommées «
            <span class="fw-bold">C.G.U.</span> »). Avant toute utilisation des fonctionnalités de la Plateforme,
            l’Utilisateur reconnaît avoir pris connaissance des présentes C.G.U. et les avoir acceptés.
          </p>

          <p>
            La version actuellement en ligne de nos C.G.U. est la seule opposable pendant toute la durée d’utilisation
            de la Plateforme et jusqu’à ce que nous la remplacions par une nouvelle version.
          </p>

          <p>
            La Plateforme est gérée par la Société <span class="fst-italic">ETX Studio</span> qui se réserve le droit de
            modifier les présentes conditions à tout moment, il est donc de la responsabilité des utilisateurs de
            consulter les C.G.U. régulièrement. Toute utilisation de notre Plateforme après modification des C.G.U. vaut
            acceptation irrévocable par l’utilisateur de la nouvelle version. En cas de désaccord avec nos C.G.U.
            l’utilisateur est invité à ne plus utiliser notre Plateforme.
          </p>

          <h3>Article 1 – <span class="text-decoration-underline">Accès au Site</span></h3>
          <p>
            L’accès au Plateforme et son utilisation sont réservés à un usage strictement personnel, par tout
            Utilisateur visitant la Plateforme ou tout Client ayant contractualisé avec ETX ou bénéficiant d’un essai
            gratuit. L’Utilisateur s’engage à ne pas utiliser la Plateforme et les informations ou données qui y
            figurent à des fins commerciales, politiques, publicitaires et pour toute forme de sollicitation commerciale
            et notamment l’envoi de courriers électroniques non sollicités.
          </p>
          <p>
            ETX ne pourra être tenue pour responsable en cas d’accès à la Plateforme par des mineurs pas plus qu’elle ne
            pourra être tenue pour responsable en cas d’informations erronées ou mensongères fournies par les
            utilisateurs de la Plateforme.
          </p>

          <h3>Article 2 – <span class="text-decoration-underline">Description des services fournis</span></h3>

          <p>
            Nous nous efforçons de fournir des informations aussi précises que possible, mais nous ne sommes pas
            responsables des oublis, inexactitudes ou carences dans la mise à jour des informations. Toutes les
            informations fournies sur notre Plateforme sont données à titre indicatif et ne sont pas exhaustives, elles
            sont pourvues sous réserve de modifications ayant été apportées depuis leur mise en ligne.
          </p>
          <p>
            L’Utilisateur est également informé que les photographies présentes sur la Plateforme ne sont pas
            contractuelles et n’engagent pas ETX.
          </p>

          <h3>Article 3 – <span class="text-decoration-underline">Propriété intellectuelle</span></h3>

          <h4>3.1 Notre Plateforme</h4>

          <p>
            Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences
            vidéo, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire
            fonctionner techniquement la Plateforme et plus généralement tous les éléments reproduits ou utilisés sur la
            Plateforme sont protégés par les lois en vigueur au titre de la propriété intellectuelle.
          </p>

          <p>
            Ils sont la propriété pleine et entière de ETX ou de ses partenaires commerciaux. Toute reproduction,
            représentation, utilisation ou adaptation, sous quelques formes que ce soit, de tout ou partie de ces
            éléments, y compris les applications informatiques, sans l'accord préalable et écrit de l'éditeur, sont
            strictement interdites. Le fait pour l'éditeur de ne pas engager de procédure dès la prise de connaissance
            de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et renonciation aux
            poursuites.
          </p>

          <p>Il est strictement interdit à l’utilisateur de&nbsp;&nbsp;</p>
          <ul class="fst-italic">
            <li>
              - Copier, reproduire, représenter, diffuser, distribuer, publier, de façon permanente ou provisoire, tout
              ou partie de l’ingénierie de la Plateforme par tout moyen et sur tout support connu ou inconnu à ce jour ;
            </li>
            <li>- Créer des œuvres dérivées à partir du contenu et/ou de la Plateforme&nbsp; ;&nbsp;</li>
            <li>- Modifier, traduire, adapter, arranger, tout ou partie de la Plateforme&nbsp; ;&nbsp;</li>
            <li>- Désassembler, décompiler, effectuer de l’ingénierie inverse sur la Plateforme.</li>
          </ul>
          <p>En tout état de cause l’Utilisateur est seul responsable de l’usage qu’il fait de la Plateforme.</p>
          <p>
            Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée
            comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et
            suivants du Code de la Propriété Intellectuelle.
          </p>
          <p>
            Ces dispositions s’appliquent sous réserve des Conditions applicables à l’Utilisation des Services d’ETX,
            notamment en matière de propriété intellectuelle liée au Contenu accessible par nos Clients et/ou créés par
            les Clients consécutivement à l’utilisation des Services.
          </p>

          <h4>3.2 Notre marque</h4>
          <p>
            Les marques verbales et visuelles (logos) de ETX sont protégées. Leur utilisation sans autorisation écrite
            de la Société ou de ses partenaires sur tout support, à des fins de valorisation de produits ou de services,
            ou à des fins commerciales, est interdite sous peine de poursuites pénales et civiles engagées par notre
            Société à l’encontre de l’utilisateur et/ou de tiers responsable.
          </p>
          <h4>3.3 Photographies</h4>
          <p>
            Les photographies et logos présents sur la Plateforme sont la propriété exclusive de ETX, de ses partenaires
            commerciaux, à l’exception des images libres de droit. Il est donc interdit à tout utilisateur de réutiliser
            ces images sans l’autorisation expresse, préalable et écrite de leur propriétaire. Aucun utilisateur ni
            Client ne peut doit prendre de mesure(s) incompatible(s) avec le respect du droit d’auteur et du droit à
            l’image. ETX ainsi que ses partenaires se réservent le droit de poursuivre tout Utilisateur pour toute
            violation de ces dispositions.
          </p>

          <h3>Article 4 – <span class="text-decoration-underline">Gestion de la Plateforme</span></h3>
          <p>
            Notre Plateforme est normalement accessible à tout moment aux utilisateurs. Pour la bonne gestion de la
            Plateforme, l’éditeur pourra à tout moment se réserver le droit de :
          </p>
          <ul class="fst-italic">
            <li>
              - Suspendre, interrompre ou limiter l’accès à tout ou partie de la Plateforme, pour quelque raison que ce
              soit, avec ou sans préavis et à sa seule discrétion ;
            </li>
            <li>
              - Supprimer toute information pouvant en perturber le fonctionnement, pour quelque raison que ce soit avec
              ou sans préavis et à sa seule discrétion ;
            </li>
            <li>- Suspendre la Plateforme afin de procéder à des mises à jour.</li>
          </ul>
          <p>
            ETX s’efforcera au mieux de communiquer préalablement aux utilisateurs les dates et heures de
            l’intervention, mais sa responsabilité ne pourrait être recherchée en cas de non-communication préalable.
          </p>
          <h3>Article 5 – <span class="text-decoration-underline">Responsabilités</span></h3>

          <h4>5.1 Accès à la Plateforme</h4>

          <p>
            ETX s’engage à faire tout son possible afin que la Plateforme soit accessible 24h/24 et 7j/7. Toutefois,
            notre Société ne prend aucun engagement quant à la disponibilité et l’accessibilité permanente de la
            Plateforme et ne garantit pas que la Plateforme soit exempte d’erreurs. La responsabilité de l’éditeur ne
            peut être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement, empêchant
            l’accès à la Plateforme ou à une de ses fonctionnalités.
          </p>

          <p>
            ETX et l’hébergeur ne pourront être tenus responsables en cas de dysfonctionnement du réseau internet, des
            lignes téléphoniques ou du matériel informatique et de téléphonie lié notamment à l’encombrement du réseau
            empêchant l’accès au serveur.
          </p>

          <p>
            L’éditeur ne pourra être tenu pour responsables en cas de poursuites judiciaires à l’encontre des
            utilisateurs :
          </p>
          <ul class="fst-italic">
            <li>
              - Du fait de l’usage de la Plateforme ou de tout autre service accessible via internet&nbsp; ;&nbsp;
            </li>
            <li>- Du fait du non-respect par l’utilisateur des présentes CGU.</li>
          </ul>

          <h4>5.2 Matériel utilisé</h4>

          <p>Le matériel de connexion à la Plateforme utilisé par l’Utilisateur est sous son entière responsabilité.</p>
          <p>
            ETX n’est pas responsable des dommages causés aux Utilisateurs, à des tiers et/ou à leur équipement du fait
            de leur connexion ou de leur utilisation à la Plateforme et les Utilisateurs renoncent à toute action de ce
            fait contre l’éditeur.
          </p>
          <p>
            L’éditeur ne pourra également être tenu pour responsables des dommages indirects (<span class="fst-italic">
              tels par exemple qu’une perte de marché ou perte de chance</span
            >) consécutifs à l’utilisation de la Plateforme.
          </p>

          <h3>Article 6 – <span class="text-decoration-underline">Liens hypertextes</span></h3>

          <p>
            La mise en place par les utilisateurs de tout lien hypertexte vers tout ou partie de la Plateforme est
            autorisée, sous réserve d’autorisation préalable et écrite d’ETX, sollicitée par courriel à l’adresse
            suivante : contact@etxstudio.com.
          </p>

          <p>
            ETX est libre de refuser cette autorisation sans avoir à justifier de quelque manière que ce soit sa
            décision. Dans le cas où ETX accorderait son autorisation, celle-ci n’est dans tous les cas que temporaire
            et pourra être retirée à tout moment, sans obligation de justification. Dans tous les cas, tout lien devra
            être retiré sur simple demande de la marque.
          </p>

          <p>
            La Plateforme contient un certain nombre de liens hypertextes vers d’autres sites, mis en place avec
            l’autorisation de l’éditeur. Cependant, ETX n’a pas la possibilité de vérifier le contenu des sites ainsi
            visités et n’assumera en conséquence aucune responsabilité de ce fait.
          </p>

          <h3>Article 7 – <span class="text-decoration-underline">Loi applicable</span></h3>

          <p>
            Les présentes conditions d’utilisation de la Plateforme sont régies par la loi française et sont – en cas de
            litige - soumises à la compétence exclusive de juridiction aux tribunaux compétents de droit commun, sous
            réserve d’une attribution de compétence spécifique découlant d’un texte de loi ou réglementaire particulier.
          </p>
        </div>
      </div>
    </div>
  </div>
</ngx-smart-modal>
