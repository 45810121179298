<div #button class="bookmark-btn" (click)="toggleBookmark()">
  <i
    [ngClass]="{
      'fa-bookmark': isBookmarked(appBookmark) && !(isLoading$ | async),
      'fa-bookmark-o': !isBookmarked(appBookmark) && !(isLoading$ | async),
      'fa-spinner fa-spin': isLoading$ | async
    }"
    class="fa"
    aria-hidden="true"
  ></i>
</div>
