import { createFeatureSelector, createSelector } from '@ngrx/store';
import { infoBannerFeatureKey, InfoBannerState } from '../reducers';

export const infoBannerState = createFeatureSelector<InfoBannerState>(infoBannerFeatureKey);

export const selectBroadcastMessages = createSelector(infoBannerState, (state) => state.messages);

export const selectIsInfoBannerOpen = createSelector(infoBannerState, (state) => state.isOpen);

export const selectStudioBroadcastMessages = createSelector(infoBannerState, (state) =>
  state.messages.filter(filterStudioMessage)
);

export const selectDiscoverBroadcastMessages = createSelector(infoBannerState, (state) =>
  state.messages.filter(filterDiscoverMessage)
);

const filterStudioMessage = (message) => message.position === 'studio' || message.position === 'all';

const filterDiscoverMessage = (message) => message.position === 'discover' || message.position === 'all';
