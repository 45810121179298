import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ArticleParams, UtilsService } from '@purplefront/shared';

@Injectable()
export class AppRouterService {
  constructor(private _router: Router, private _utilsService: UtilsService) {}

  public updateQueryParams(queryParams: any, keepParams: boolean = true): void {
    this._router.navigate([], {
      queryParams: queryParams,
      queryParamsHandling: keepParams ? 'merge' : null
    });
  }

  public goToLogin(fromUrl: string = ''): void {
    this._router.navigate(['./auth/login'], { queryParams: { fromUrl } });
  }

  public goToArticlePage(params: ArticleParams): void {
    this._router.navigate([params.page, params.type, params.lang, params.id, params.mainCategory, params.title]);
  }

  public goToHome(): void {
    this._router.navigate(['home']);
  }

  public goToCatalog(toParams: { catalog: string }): void {}

  public goToCatalog404(fromUrl: string): void {}

  public goToFeedList(fromUrl: string, keepQueryParams: boolean): void {
    /* Url to navigate
     * '/catalog/:catalog/select/feeds'
     */

    this._router.navigate(['select', 'feeds'], {
      queryParamsHandling: keepQueryParams ? 'merge' : null
    });
  }

  public goToFeed(
    toParams: {
      feed: string;
    },
    fromUrl: string,
    keepQueryParams: boolean
  ): void {
    /* Url to navigate
     * '/catalog/:catalog/select/feed/:feed'
     */

    this._router.navigate(['select', 'feed', toParams.feed], {
      queryParamsHandling: keepQueryParams ? 'merge' : null
    });
  }

  public goToFeed404(fromUrl: string): void {}

  public goToFeedPage(
    toParams: {
      feed: string[];
      lang: string;
      search: string;
      searchId: string;
      tag: string;
    },
    fromUrl: string,
    keepQueryParams: boolean
  ): void {
    const params = ['discover', toParams.lang];
    const queryParams = {
      search: toParams.search,
      searchId: toParams.searchId,
      feed: toParams.feed,
      tag: toParams.tag
    };

    this._router.navigate(params, {
      queryParamsHandling: keepQueryParams ? 'merge' : null,
      queryParams: queryParams
    });
  }

  public goToDiscover(
    toParams: {
      feed: string[];
      lang: string;
      search: string;
      searchId: string;
      tag: string;
    },
    fromUrl: string,
    keepQueryParams: boolean
  ): void {
    const params = ['discover', toParams.lang];
    const queryParams = {
      search: toParams.search,
      searchId: toParams.searchId,
      feeds: toParams.feed
    };

    this._router.navigate(params, {
      queryParamsHandling: keepQueryParams ? 'merge' : null,
      queryParams: queryParams
    });
  }

  public goToCalendar(
    toParams: {
      feed: string[];
      lang: string;
      search: string;
      searchId: string;
    },
    fromUrl: string,
    keepQueryParams: boolean
  ): void {
    const params = ['discover', toParams.lang];
    const queryParams = {
      search: toParams.search,
      searchId: toParams.searchId,
      feeds: toParams.feed
    };

    this._router.navigate(params, {
      queryParamsHandling: keepQueryParams ? 'merge' : null,
      queryParams: queryParams
    });
  }

  public goToFolderPage(
    toParams: {
      feed: string[];
      lang: string;
      folderId: string;
      search: string;
      searchId: string;
    },
    fromUrl: string,
    keepQueryParams: boolean
  ): void {
    /* Url to navigate  folders/:id/:lang
     * '/select/feed/:feed/folders/:folderId/:lang'
     */

    const params = ['select', 'feed', toParams.feed, 'folders', toParams.folderId, toParams.lang];
    const queryParams = {
      search: toParams.search,
      searchId: toParams.searchId
    };

    this._router.navigate(params, {
      queryParamsHandling: keepQueryParams ? 'merge' : null,
      queryParams: queryParams
    });
  }
}
