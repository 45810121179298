<section class="audio-augmented">
  <div class="row">
    <div class="col-lg-12 text-center">
      <h2>A unique platform to discover <br /><span class="accent-title">audio augmented content</span></h2>
    </div>
  </div>
  <div class="container mt-3 mb-3">
    <div class="row no-gutters">
      <div class="col-lg-6">
        <div class="portfolio-item">
          <div class="caption">
            <div class="caption-content">
              <h3 class="entry-title">
                <span class="accent-box">Transformez vos contenus en audio stories grace à notre éditeur</span>
              </h3>
              <div>
                <a class="btn btn-audio" role="button">
                  <i class="fa fa-play-circle base-play-color" aria-hidden="true"></i>Generate your Audio Content</a
                >
              </div>
            </div>
          </div>
          <img class="img-fluid" src="assets/images/home/audio-augmented/audio-story-editor.jpg" />
        </div>
      </div>
      <div class="col-lg-6">
        <!--Nested rows within a column-->
        <div class="row no-gutters">
          <div class="col-6 item zoom-on-hover">
            <a class="portfolio-link" data-toggle="modal">
              <div class="portfolio-hover">
                <div class="portfolio-hover-content">
                  <img src="assets/images/pictos/button-play-purple.svg" alt="" width="" />
                </div>
              </div>
              <img class="img-fluid image" src="assets/images/home/audio-augmented/1-1.jpg"
            /></a>
          </div>
          <div class="col-6 item zoom-on-hover">
            <a class="portfolio-link" data-toggle="modal">
              <div class="portfolio-hover">
                <div class="portfolio-hover-content">
                  <img src="assets/images/pictos/button-play-purple.svg" alt="" width="" />
                </div>
              </div>
              <img class="img-fluid image" src="assets/images/home/audio-augmented/2.jpg"
            /></a>
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-6 item zoom-on-hover">
            <a class="portfolio-link" data-toggle="modal">
              <div class="portfolio-hover">
                <div class="portfolio-hover-content">
                  <img src="assets/images/pictos/button-play-purple.svg" alt="" width="" />
                </div>
              </div>
              <img class="img-fluid image" src="assets/images/home/audio-augmented/4.jpg"
            /></a>
          </div>
          <div class="col-6 item zoom-on-hover">
            <a class="portfolio-link" data-toggle="modal">
              <div class="portfolio-hover">
                <div class="portfolio-hover-content">
                  <img src="assets/images/pictos/button-play-purple.svg" alt="" width="" />
                </div>
              </div>
              <img class="img-fluid image" src="assets/images/home/audio-augmented/3.jpg"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
