import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthFeatureShellModule } from '@purplefront/auth/feature-shell';
import { MenuFeatureShellModule } from '@purplefront/menu/feature-shell';
import { HeaderFeatureShellModule } from '@purplefront/header/feature-shell';
import {
  CheckForUpdateService,
  environment,
  LogUpdateService,
  SharedModule,
  UtilsService,
  WINDOW_PROVIDERS
} from '@purplefront/shared';
import { AuthApi, AuthService, SessionService, SessionGuard } from '@purplefront/auth/data-access';

import { AppRoutingMobisation } from './app-routing-mobisation.module';

import { AppShellComponent } from './app-shell/app-shell.component';

import { PreferencesFeatureShellModule } from '@purplefront/preferences/feature-shell';
import { CookieBannerFeatureShellModule } from '@purplefront/cookie-banner/feature-shell';

import {
  AppRouterEffects,
  AppRouterService,
  AppRouterFacadeService,
  CustomSerializer
} from '@purplefront/app-router/data-access';
import { Catalog404Container, CatalogContainer, CatalogFeatureShellModule } from '@purplefront/catalog/feature-shell';
import { CatalogResolver } from '@purplefront/catalog/data-access';
import { AppMobisationContainer } from './containers/app/app-mobisation-container.component';
import { EditorFeatureShellModule } from '@purplefront/editor/feature-shell';
import { ArticleModalFeatureShellModule } from '@purplefront/article-modal/feature-shell';
import { CalendarFeatureShellModule } from '@purplefront/calendar/feature-shell';
import { FlashBriefingFeatureShellModule } from '@purplefront/flash-briefing/feature-shell';
import { SearchFeatureShellModule } from '@purplefront/search/feature-shell';
import { FormSyncModule } from '@larscom/ngrx-store-storagesync';
import { storageSyncReducer } from '@purplefront/editor/data-access';
import { ToasterFeatureShellModule } from '@purplefront/toaster/feature-shell';
import { MenuRioUiModule } from '@purplefront/menu-rio/ui';
import { MenuRioFeatureShellModule } from '@purplefront/menu-rio/feature-shell';
import { AudioPlayerFeatureShellModule } from '@purplefront/audio-player/feature-shell';
import { clearStateReducer } from '../../../../libs/auth/data-access/src/lib/_store/reducers/clearState.reducer';
import { ShepherdService } from 'angular-shepherd';
import { InfoBannerFeatureShellModule } from '@purplefront/info-banner/feature-shell';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

@NgModule({
  declarations: [AppMobisationContainer, AppShellComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    NgbModule,
    BrowserAnimationsModule,
    NgbToastModule,

    // translate
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    // ngrx :
    StoreModule.forRoot({ 'purple-router': routerReducer }, { metaReducers: [storageSyncReducer] }),
    FormSyncModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    EffectsModule.forRoot([AppRouterEffects]),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],

    // modules without routing :
    MenuFeatureShellModule,
    HeaderFeatureShellModule,
    CatalogFeatureShellModule,
    FlashBriefingFeatureShellModule,
    SearchFeatureShellModule,
    ToasterFeatureShellModule,
    MenuRioFeatureShellModule,
    MenuRioUiModule,
    AudioPlayerFeatureShellModule,
    InfoBannerFeatureShellModule,
    // routing :
    AuthFeatureShellModule,
    PreferencesFeatureShellModule,
    EditorFeatureShellModule,
    ArticleModalFeatureShellModule,
    CalendarFeatureShellModule,

    // PWA

    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

    RouterModule,
    AppRoutingMobisation,
    SharedModule,
    CookieBannerFeatureShellModule
  ],
  providers: [
    AppRouterFacadeService,
    AuthService,
    AuthApi,
    SessionService,
    SessionGuard,
    CatalogResolver,
    AppRouterService,
    UtilsService,
    LogUpdateService,
    CheckForUpdateService,
    WINDOW_PROVIDERS,
    { provide: 'BASE_URL', useFactory: getBaseUrl }
  ],
  bootstrap: [AppMobisationContainer]
})
export class AppModule {}
