import {
  Component,
  EventEmitter,
  forwardRef,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-format-dropdown',
  templateUrl: './format-dropdown.component.html',
  styleUrls: ['./format-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormatDropdownComponent),
      multi: true
    }
  ]
})
export class FormatDropdownComponent implements OnInit, ControlValueAccessor, OnDestroy {
  selectedFormats$ = new BehaviorSubject(null);
  @Output() onSelectedFormatChange$ = new EventEmitter<any>();
  @Output() trackFormatChange = new EventEmitter<any>();
  @ViewChild('formatDropdown') formatDropdown;
  onChange = (_: any) => {};
  formats = [
    {
      label: 'EDITOR_AUDIO',
      value: 'audio'
    },
    {
      label: 'EDITOR_VIDEO_PORTRAIT',
      value: 'video_mobile'
    },
    {
      label: 'EDITOR_VIDEO_SQUARE',
      value: 'video'
    },
    {
      label: 'EDITOR_16_9_FORMAT',
      value: 'video_16_9'
    }
  ];

  selectedFormats = [];
  constructor() {}

  ngOnInit(): void {}

  addToSelectedFormats(format) {
    if (this.isFormatSelected(format)) {
      this.selectedFormats = this.selectedFormats.filter((value) => value !== format);
    } else {
      const event = {
        format: format.value,
        type: 'editor-select-format'
      };
      this.trackFormatChange.emit(event);
      this.selectedFormats.push(format);
    }

    this.changeSelectedFormat(this.selectedFormats);
  }

  changeSelectedFormat(formats) {
    this.selectedFormats$.next(formats);
    this.onChange(formats);
    this.onSelectedFormatChange$.emit(formats);
  }

  isFormatSelected(format) {
    return this.selectedFormats.includes(format);
  }

  close() {
    this.formatDropdown.close();
  }

  ngOnDestroy(): void {}

  registerOnChange(fn: (_: any) => {}) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  writeValue(obj: any): void {
    this.selectedFormats$.next(this.selectedFormats);
  }
}
