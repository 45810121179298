<div class="search-articles">
  <h3 class="mb-3">Articles</h3>
  <article class="card mb-3" *ngFor="let article of latestArticles">
    <div class="col-sm-12 no-gutters d-flex flex-md-column flex-lg-row px-0">
      <div class="col-4 col-md-12 col-lg-4" (click)="openArticle(article)">
        <img src="{{ article.images[0] }}" width="105" height="100px" class="card-img" alt="{{ article.title }}" />
      </div>
      <div class="col-8 col-md-12 col-lg-8">
        <div class="card-body">
          <div class="article-info">
            <app-main-category-btn
              (searchString$)="search($event)"
              mainCategory="{{ article?.mainCategory }}"
            ></app-main-category-btn>
            <span class="article-info-date">{{ article.publication_date | date: 'MMM d y' }}</span>
          </div>
          <p class="card-text pt-2" (click)="openArticle(article)">{{ article.title }}</p>
        </div>
      </div>
    </div>
  </article>
</div>
