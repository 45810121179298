import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PreferencesContainer } from './containers';
import { StoreModule } from '@ngrx/store';
import { PreferencesDataAccessModule, PreferencesEffects, reducer } from '@purplefront/preferences/data-access';
import { PreferencesUiModule } from '@purplefront/preferences/ui';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '@purplefront/shared';

const routes: Routes = [{ path: '', pathMatch: 'full', component: PreferencesContainer }];
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature('preferences', reducer),
    EffectsModule.forFeature([PreferencesEffects]),

    // libs module
    PreferencesDataAccessModule,
    PreferencesUiModule,
    SharedModule
  ],
  declarations: [PreferencesContainer]
})
export class PreferencesFeatureShellModule {}
