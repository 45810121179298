import { Component, OnInit, Renderer2, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { Langs } from '@purplefront/shared';
import { Feed } from '@purplefront/catalog/data-access';
import { Bookmark, BookmarksState } from '@purplefront/bookmarks/data-access';
import * as fromArticleModalStore from '@purplefront/article-modal/data-access';
import * as fromAuthStore from '@purplefront/auth/data-access';
import * as fromRouterStore from '@purplefront/app-router/data-access';

@Component({
  selector: 'app-article-modal',
  templateUrl: './article-modal.component.html',
  styleUrls: ['./article-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ArticleModalComponent implements OnInit, OnDestroy {
  public articleType: string;
  public contextType = 'relax';
  public articleId$: Observable<string>;
  public feed$: Observable<Feed>;
  public lang$: Observable<Langs>;
  public bookmarks$: Observable<Bookmark[]>;
  public relatedArticles$: Observable<any>;
  public moreLikeArticles$: Observable<any>;
  public activeTab$: Observable<any>;
  public article: any;
  public modal$: Observable<any>;
  public articleLang$: Observable<any>;
  public articleType$: Observable<any>;
  public articleSourceType$: Observable<any>;
  public user$: Observable<any>;
  public isLoading$: Observable<boolean>;
  public isLoaded$: Observable<boolean>;
  public isLoadingMoreLikeArticles$: Observable<boolean>;
  public isLoadedMoreLikeArticles$: Observable<boolean>;
  public activeTab = 'article';
  public heightSize = ['50', '100', '200', '300'];
  public squareSize = ['50', '80', '100', '200'];
  public widthSize = ['50', '80', '100', '200', '250', '300', '400', '600', '640', '768'];
  public articleId: string;
  public imageMap: string[] = [];
  public srcset: string;
  public nbElements = [1, 2, 3, 4, 5, 6, 7, 8];
  public icon = 'fa-bookmark';
  public iconActive = 'fa-bookmark-o';

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  @ViewChild('modalContainer') modalContainer;

  constructor(
    private _renderer: Renderer2,
    private _route: ActivatedRoute,
    private _router: Router,
    private _articleModalStore: Store<fromArticleModalStore.ArticleModalState>,
    private _bookmarkStore: Store<BookmarksState>,
    private _authStore: Store<fromAuthStore.AuthState>,
    private _articleStore: Store<fromArticleModalStore.ArticleModalState>,
    private _routerStore: Store<fromRouterStore.AppRouterState>
  ) {}

  ngOnInit() {
    /*
     * Get data from router store
     */

    this.modal$ = this._routerStore.pipe(takeUntil(this._ngUnsubscribe$), select(fromRouterStore.selectModal));
    this.articleId$ = this._routerStore.pipe(takeUntil(this._ngUnsubscribe$), select(fromRouterStore.selectArticleId));
    this.articleLang$ = this._routerStore.pipe(
      takeUntil(this._ngUnsubscribe$),
      select(fromRouterStore.selectArticleLang)
    );

    this.articleSourceType$ = this._routerStore.pipe(
      takeUntil(this._ngUnsubscribe$),
      select(fromRouterStore.selectArticleSourceType)
    );

    const modalParams$ = combineLatest([this.modal$, this.articleId$, this.articleLang$, this.articleSourceType$]);

    modalParams$.pipe(debounceTime(0)).subscribe(([_modal, _id, _lang, _sourceType]) => {
      /*
       * Dispatch an action to get modal article
       */
      if (_modal && _id && _lang && _sourceType) {
        this._articleStore.dispatch(
          fromArticleModalStore.getArticle({
            payload: {
              article: {
                modalArticle: _modal,
                articleId: _id,
                articleLang: _lang,
                sourceType: _sourceType
              }
            }
          })
        );

        /**
         * Get article
         */

        this._articleModalStore
          .pipe(takeUntil(this._ngUnsubscribe$), select(fromArticleModalStore.currentArticle))
          .subscribe((article) => {
            this.article = article;
          });

        /*
         * Get loading state
         */
        this.isLoading$ = this._articleModalStore.pipe(select(fromArticleModalStore.selectLoadingArticle));
        this.isLoaded$ = this._articleModalStore.pipe(select(fromArticleModalStore.selectLoadedArticle));
        this.isLoadingMoreLikeArticles$ = this._articleModalStore.pipe(
          select(fromArticleModalStore.selectLoadingMoreLikeArticles)
        );
        this.isLoadedMoreLikeArticles$ = this._articleModalStore.pipe(
          select(fromArticleModalStore.selectLoadedMoreLikeArticles)
        );
      }
    });

    /**
     * Check for active tab
     */

    this.activeTab$ = this._articleModalStore.pipe(
      takeUntil(this._ngUnsubscribe$),
      select(fromArticleModalStore.activeTab)
    );

    /**
     * Get user datas
     */
    this.user$ = this._authStore.pipe(select(fromAuthStore.tokenCredentials));

    /**
     * Get more like articles
     */

    this.moreLikeArticles$ = this._articleModalStore.pipe(
      takeUntil(this._ngUnsubscribe$),
      select(fromArticleModalStore.moreLikeArticles)
    );

    /**
     * Get related articles
     */

    this.relatedArticles$ = this._articleModalStore.pipe(
      takeUntil(this._ngUnsubscribe$),
      select(fromArticleModalStore.relatedArticles)
    );
  }

  isEvent = (article) => !!article.start_date || !!article.end_date;

  /**
   * toggle active tab
   */

  toggleActiveTab(tab, $event) {
    $event.preventDefault();
    this._articleModalStore.dispatch(fromArticleModalStore.displayModalTab({ payload: { activeTab: tab } }));
  }

  /**
   * Close modal
   */

  closeModal() {
    this._router.navigate(['.'], { relativeTo: this._route, queryParams: {} });
  }

  /**
   * Download Images
   */

  downloadImage(id, type, shape, size) {
    /* this._articleModalStore.dispatch(
      fromArticleModalStore.downloadImage({
        payload: { id, type, shape, size }
      })
    );*/
  }

  /*
   * Map article images srcset
   */

  getImageSrcset(): string {
    const imageSizes = {
      s_default: '200w',
      s_medium: '645w',
      s_large: '900w'
    };

    Object.keys(imageSizes).map((key) => {
      const src = this.article.images[0][key] + ' ' + imageSizes[key];
      this.imageMap.push(src);
    });
    this.srcset = this.imageMap.slice(-3).toString();
    return this.srcset;
  }

  /**
   * Send article by email
   */

  sendArticle(form, id) {
    const type = this.articleType;
    const name = form.value.name;
    const email = form.value.email;
    this._articleModalStore.dispatch(fromArticleModalStore.mailArticle({ payload: { id, type, name, email } }));
  }

  /**
   * more like articles links
   */

  changeArticle(moreLikeArticle) {
    this._router.navigate(['.'], {
      relativeTo: this._route,
      queryParams: {
        modal: 'article',
        articleId: moreLikeArticle.id,
        articleLang: moreLikeArticle.lang,
        sourceType: moreLikeArticle.object_source_type
      }
    });
  }

  /**
   * Prevent form from closing when clicking on input
   */

  preventClose($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  /**
   * Specify content type and image url for bookmark
   */

  setContentType(article) {
    const currentArticle = article;
    currentArticle.contentType = 'modal';
    currentArticle.articleType = article.type;
    currentArticle.articleLang = article.lang;
    currentArticle.sourceType = article.sourceType;
    currentArticle.image = article.images && article.images[0] ? article.images[0].url : undefined;
    return currentArticle;
  }

  /**
   * Prevent memory leaks
   */

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
