import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromReducer from '../reducers/header.reducers';

export const getHeaderState = createFeatureSelector<fromReducer.HeaderState>('header');

export const isMobile = createSelector(getHeaderState, (state) => state.screen.mobile);

export const isTablet = createSelector(getHeaderState, (state) => state.screen.tablet);

export const isDesktop = createSelector(getHeaderState, (state) => state.screen.desktop);
