<div class="generation-editor-container" [@showHide]="(openAudioStoryOverlay$ | async) ? 'active' : 'inactive'">
  <div class="generation-editor d-flex">
    <app-etx-icons class="audio-story-close" name="close" (click)="close()"></app-etx-icons>
    <h1>{{ 'GENERATION' | translate }}</h1>
    <!--<span class="subtitle"
      >{{ 'GENERATION_YOUR_GENERATED_AUDIOS_AND_VIDEOS_ARE_ACCESSIBLE_FROM_YOUR' | translate }}
      <span (click)="goToMyAudioContents()" class="link">{{ 'MENU_CREATIONS' | translate }}</span></span
    >-->

    <div class="row row-success align-items-center" *ngIf="isFormatSelected('audio')">
      <div class="col-12">
        <h4>
          <!--          <app-etx-icons name="wave"></app-etx-icons>-->
          <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g id="Groupe_668" data-name="Groupe 668">
              <path d="M12,19.5a.85.85,0,0,1-.85-.85V5.35a.85.85,0,0,1,1.7,0v13.3A.85.85,0,0,1,12,19.5Z" />
              <path d="M5.2,15.92a.85.85,0,0,1-.85-.85V8.93a.85.85,0,0,1,1.7,0v6.14A.85.85,0,0,1,5.2,15.92Z" />
              <path d="M8.6,17.27a.86.86,0,0,1-.85-.85V7.58a.85.85,0,0,1,1.7,0v8.84A.86.86,0,0,1,8.6,17.27Z" />
              <path d="M15.4,17.27a.86.86,0,0,1-.85-.85V7.58a.85.85,0,0,1,1.7,0v8.84A.86.86,0,0,1,15.4,17.27Z" />
              <path d="M18.8,15.92a.85.85,0,0,1-.85-.85V8.93a.85.85,0,0,1,1.7,0v6.14A.85.85,0,0,1,18.8,15.92Z" />
            </g>
          </svg>
          {{ 'GENERATION_AUDIO_FORMAT' | translate }}
          <span *ngIf="!audioFormatUrl" class="text-progress">{{ 'IN_PROGRESS' | translate }}</span>
          <span *ngIf="audioFormatUrl" class="completed">{{ 'GENERATION_READY' | translate }} </span>
        </h4>
        <div class="progress mb-2">
          <p>
            <ngb-progressbar
              type="success"
              [value]="audioStatus$ | async"
              [max]="100"
              [animated]="true"
              [showValue]="false"
              [striped]="(audioStatus$ | async) < 100"
            ></ngb-progressbar>
          </p>
        </div>
      </div>
      <div *ngIf="audioFormatUrl" class="col-12">
        <ul class="list-inline h-100 mb-1">
          <li (click)="setAudioPlayerSource()" class="list-inline-item me-4">
            <span class="icon-circle" data-toggle="tooltip" data-placement="right" title="{{ 'PLAY' | translate }}"
              ><!--<app-etx-icons name="play_white"></app-etx-icons
            >-->
              <svg
                data-name="Calque 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                style="position: relative; top: 1px; left: 1px"
              >
                <path
                  d="M7 12V6.78a1.34 1.34 0 012-1.15l8.52 5.12a1.46 1.46 0 010 2.5L9 18.37a1.34 1.34 0 01-2-1.15z"
                  fill="#fff"
                ></path>
              </svg>
            </span>
          </li>
          <li class="list-inline-item me-4">
            <span
              (click)="downloadSource(audioFormatUrl.download, 'audio')"
              class="icon-circle"
              data-toggle="tooltip"
              data-placement="right"
              title="{{ 'DOWNLOAD' | translate }}"
              ><!--<app-etx-icons
                (click)="downloadSource(audioFormatUrl.download, 'audio')"
                name="download_white"
              ></app-etx-icons
            >-->
              <svg
                id="Calque_1"
                data-name="Calque 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                style="position: relative; top: -1px; left: 0"
              >
                <path
                  class="cls-1"
                  d="M11.41 17.08a.26.26 0 00.11.07.91.91 0 00.16.11.88.88 0 00.64 0 .91.91 0 00.16-.11.26.26 0 00.11-.07L16 13.81a.85.85 0 10-1.18-1.22l-1.94 1.88V5.35a.85.85 0 00-1.7 0v9.12l-1.97-1.88A.85.85 0 008 13.81z"
                ></path>
                <path
                  class="cls-1"
                  d="M18.5 16.57a.85.85 0 00-.85.85v1.64H6.35v-1.64a.85.85 0 00-1.7 0v2.49a.85.85 0 00.85.85h13a.85.85 0 00.85-.85v-2.49a.85.85 0 00-.85-.85z"
                ></path>
              </svg>
            </span>
          </li>
          <li (click)="copyToClipboard('audio')" class="list-inline-item me-4" style="position: relative">
            <ng-container *ngIf="_success | async as success">
              <ng-container *ngIf="success.format === 'audio'">
                <div
                  class="copied-code-embed-msg"
                  style="position: absolute; top: 2px; left: 26px; width: 85px"
                  #selfClosingAlert
                  type="success"
                >
                  {{ 'COPIED' | translate }}
                </div>
              </ng-container>
            </ng-container>
            <span
              class="icon-circle"
              data-toggle="tooltip"
              data-placement="right"
              title="{{ 'COPY_CODE_EMBED' | translate }}"
            >
              <svg
                id="Calque_1"
                data-name="Calque 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                style="position: relative; top: 1px"
              >
                <path
                  class="cls-1"
                  d="M8.41,16a.85.85,0,0,1-.56-.21L4.14,12.64a.83.83,0,0,1-.3-.64.87.87,0,0,1,.3-.65L7.85,8.17A.85.85,0,1,1,9,9.46L6,12l3,2.54a.85.85,0,0,1,.09,1.2A.83.83,0,0,1,8.41,16Z"
                />
                <path
                  class="cls-1"
                  d="M15.59,16a.83.83,0,0,1-.64-.3.85.85,0,0,1,.09-1.2L18,12,15,9.46a.85.85,0,0,1,1.11-1.29l3.71,3.18a.87.87,0,0,1,.3.65.83.83,0,0,1-.3.64l-3.71,3.19A.85.85,0,0,1,15.59,16Z"
                />
                <path
                  class="cls-1"
                  d="M10.93,18.42h-.16a.87.87,0,0,1-.68-1L12.24,6.27a.86.86,0,0,1,1-.68.87.87,0,0,1,.68,1L11.76,17.73A.84.84,0,0,1,10.93,18.42Z"
                />
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="row row-success align-items-center" *ngIf="isFormatSelected('video_mobile')">
      <div class="col-12">
        <h4>
          <app-etx-icons name="portrait"></app-etx-icons> {{ 'GENERATION_VIDEO_PORTRAIT_FORMAT' | translate
          }}<span *ngIf="(mobileFormatStatus$ | async) !== 100" class="text-progress">{{
            'IN_PROGRESS' | translate
          }}</span>
          <span *ngIf="(mobileFormatStatus$ | async) === 100" class="completed"
            >{{ 'GENERATION_READY' | translate }}
          </span>
        </h4>
        <div class="progress mb-2">
          <p>
            <ngb-progressbar
              type="success"
              textType="white"
              [value]="mobileFormatStatus$ | async"
              [showValue]="false"
              [animated]="true"
              [striped]="(mobileFormatStatus$ | async) < 100"
            ></ngb-progressbar>
          </p>
        </div>
      </div>

      <div class="col-12">
        <ul *ngIf="(mobileFormatStatus$ | async) === 100" class="list-inline h-100 mb-1">
          <li (click)="openAudioStoryModal(mobileFormatUrls.stream, 'mobile-video')" class="list-inline-item me-4">
            <span class="icon-circle" data-toggle="tooltip" data-placement="right" title="{{ 'PLAY' | translate }}"
              ><!--<app-etx-icons name="play_white"></app-etx-icons
            >-->
              <svg data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M7 12V6.78a1.34 1.34 0 012-1.15l8.52 5.12a1.46 1.46 0 010 2.5L9 18.37a1.34 1.34 0 01-2-1.15z"
                  fill="#fff"
                ></path>
              </svg>
            </span>
          </li>
          <li class="list-inline-item me-4">
            <span
              (click)="downloadSource(mobileFormatUrls.download, 'mobile-video')"
              class="icon-circle"
              data-toggle="tooltip"
              data-placement="right"
              title="{{ 'DOWNLOAD' | translate }}"
              ><!--<app-etx-icons
                (click)="downloadSource(mobileFormatUrls.download, 'mobile-video')"
                name="download_white"
              ></app-etx-icons
            >--><svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  class="cls-1"
                  d="M11.41 17.08a.26.26 0 00.11.07.91.91 0 00.16.11.88.88 0 00.64 0 .91.91 0 00.16-.11.26.26 0 00.11-.07L16 13.81a.85.85 0 10-1.18-1.22l-1.94 1.88V5.35a.85.85 0 00-1.7 0v9.12l-1.97-1.88A.85.85 0 008 13.81z"
                ></path>
                <path
                  class="cls-1"
                  d="M18.5 16.57a.85.85 0 00-.85.85v1.64H6.35v-1.64a.85.85 0 00-1.7 0v2.49a.85.85 0 00.85.85h13a.85.85 0 00.85-.85v-2.49a.85.85 0 00-.85-.85z"
                ></path>
              </svg>
            </span>
          </li>
          <li (click)="copyToClipboard('mobile-video')" class="list-inline-item me-4" style="position: relative">
            <ng-container *ngIf="_success | async as success">
              <ng-container *ngIf="success.format === 'mobile-video'">
                <div
                  class="copied-code-embed-msg"
                  style="position: absolute; top: 2px; left: 26px; width: 85px"
                  #selfClosingAlert
                  type="success"
                >
                  {{ 'COPIED' | translate }}
                </div>
              </ng-container>
            </ng-container>
            <span
              class="icon-circle"
              data-toggle="tooltip"
              data-placement="right"
              title="{{ 'COPY_CODE_EMBED' | translate }}"
            >
              <svg
                id="Calque_1"
                data-name="Calque 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                style="position: relative; top: 1px"
              >
                <path
                  class="cls-1"
                  d="M8.41,16a.85.85,0,0,1-.56-.21L4.14,12.64a.83.83,0,0,1-.3-.64.87.87,0,0,1,.3-.65L7.85,8.17A.85.85,0,1,1,9,9.46L6,12l3,2.54a.85.85,0,0,1,.09,1.2A.83.83,0,0,1,8.41,16Z"
                />
                <path
                  class="cls-1"
                  d="M15.59,16a.83.83,0,0,1-.64-.3.85.85,0,0,1,.09-1.2L18,12,15,9.46a.85.85,0,0,1,1.11-1.29l3.71,3.18a.87.87,0,0,1,.3.65.83.83,0,0,1-.3.64l-3.71,3.19A.85.85,0,0,1,15.59,16Z"
                />
                <path
                  class="cls-1"
                  d="M10.93,18.42h-.16a.87.87,0,0,1-.68-1L12.24,6.27a.86.86,0,0,1,1-.68.87.87,0,0,1,.68,1L11.76,17.73A.84.84,0,0,1,10.93,18.42Z"
                />
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="row row-success align-items-center" *ngIf="isFormatSelected('video')">
      <div class="col-12">
        <h4>
          <app-etx-icons name="square"></app-etx-icons> {{ 'GENERATION_VIDEO_SQUARE_FORMAT' | translate
          }}<span *ngIf="(squareFormatStatus$ | async) !== 100" class="text-progress">{{
            'IN_PROGRESS' | translate
          }}</span>
          <span *ngIf="(squareFormatStatus$ | async) === 100" class="completed"
            >{{ 'GENERATION_READY' | translate }}
          </span>
        </h4>
        <div class="progress mb-2">
          <p>
            <ngb-progressbar
              type="success"
              textType="white"
              [value]="squareFormatStatus$ | async"
              [showValue]="false"
              [striped]="(squareFormatStatus$ | async) < 100"
            ></ngb-progressbar>
          </p>
        </div>
      </div>
      <div class="col-12">
        <ul *ngIf="(squareFormatStatus$ | async) === 100" class="list-inline h-100 mb-1">
          <li (click)="openAudioStoryModal(squareFormatUrls.stream, 'square-video')" class="list-inline-item me-4">
            <span class="icon-circle" data-toggle="tooltip" data-placement="right" title="{{ 'PLAY' | translate }}"
              ><!--<app-etx-icons name="play_white"></app-etx-icons
            >-->
              <svg data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M7 12V6.78a1.34 1.34 0 012-1.15l8.52 5.12a1.46 1.46 0 010 2.5L9 18.37a1.34 1.34 0 01-2-1.15z"
                  fill="#fff"
                ></path>
              </svg>
            </span>
          </li>
          <li class="list-inline-item me-4">
            <span
              (click)="downloadSource(squareFormatUrls.download, 'square-video')"
              class="icon-circle"
              data-toggle="tooltip"
              data-placement="right"
              title="{{ 'DOWNLOAD' | translate }}"
              ><!--<app-etx-icons
                (click)="downloadSource(squareFormatUrls.download, 'square-video')"
                name="download_white"
              ></app-etx-icons
            >-->
              <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  class="cls-1"
                  d="M11.41 17.08a.26.26 0 00.11.07.91.91 0 00.16.11.88.88 0 00.64 0 .91.91 0 00.16-.11.26.26 0 00.11-.07L16 13.81a.85.85 0 10-1.18-1.22l-1.94 1.88V5.35a.85.85 0 00-1.7 0v9.12l-1.97-1.88A.85.85 0 008 13.81z"
                ></path>
                <path
                  class="cls-1"
                  d="M18.5 16.57a.85.85 0 00-.85.85v1.64H6.35v-1.64a.85.85 0 00-1.7 0v2.49a.85.85 0 00.85.85h13a.85.85 0 00.85-.85v-2.49a.85.85 0 00-.85-.85z"
                ></path>
              </svg>
            </span>
          </li>
          <li (click)="copyToClipboard('square-video')" class="list-inline-item me-4" style="position: relative">
            <ng-container *ngIf="_success | async as success">
              <ng-container *ngIf="success.format === 'square-video'">
                <div
                  class="copied-code-embed-msg"
                  style="position: absolute; top: 2px; left: 26px; width: 85px"
                  #selfClosingAlert
                  type="success"
                >
                  {{ 'COPIED' | translate }}
                </div>
              </ng-container>
            </ng-container>
            <span
              class="icon-circle"
              data-toggle="tooltip"
              data-placement="right"
              title="{{ 'COPY_CODE_EMBED' | translate }}"
            >
              <svg
                id="Calque_1"
                data-name="Calque 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                style="position: relative; top: 1px"
              >
                <path
                  class="cls-1"
                  d="M8.41,16a.85.85,0,0,1-.56-.21L4.14,12.64a.83.83,0,0,1-.3-.64.87.87,0,0,1,.3-.65L7.85,8.17A.85.85,0,1,1,9,9.46L6,12l3,2.54a.85.85,0,0,1,.09,1.2A.83.83,0,0,1,8.41,16Z"
                />
                <path
                  class="cls-1"
                  d="M15.59,16a.83.83,0,0,1-.64-.3.85.85,0,0,1,.09-1.2L18,12,15,9.46a.85.85,0,0,1,1.11-1.29l3.71,3.18a.87.87,0,0,1,.3.65.83.83,0,0,1-.3.64l-3.71,3.19A.85.85,0,0,1,15.59,16Z"
                />
                <path
                  class="cls-1"
                  d="M10.93,18.42h-.16a.87.87,0,0,1-.68-1L12.24,6.27a.86.86,0,0,1,1-.68.87.87,0,0,1,.68,1L11.76,17.73A.84.84,0,0,1,10.93,18.42Z"
                />
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="row row-success align-items-center" *ngIf="isFormatSelected('video_16_9')">
      <div class="col-12">
        <h4>
          <app-etx-icons class="landscape" name="portrait"></app-etx-icons>
          {{ 'GENERATION_VIDEO_LANDSCAPE_FORMAT' | translate
          }}<span *ngIf="(landscapeFormatStatus$ | async) !== 100" class="text-progress">{{
            'IN_PROGRESS' | translate
          }}</span>
          <span *ngIf="(landscapeFormatStatus$ | async) === 100" class="completed"
            >{{ 'GENERATION_READY' | translate }}
          </span>
        </h4>
        <div class="progress mb-2">
          <p>
            <ngb-progressbar
              type="success"
              textType="white"
              [value]="landscapeFormatStatus$ | async"
              [showValue]="false"
              [striped]="(landscapeFormatStatus$ | async) < 100"
            ></ngb-progressbar>
          </p>
        </div>
      </div>
      <div class="col-12">
        <ul *ngIf="(landscapeFormatStatus$ | async) === 100" class="list-inline h-100 mb-1">
          <li
            (click)="openAudioStoryModal(landscapeFormatUrls.stream, 'landscape-video')"
            class="list-inline-item me-4"
          >
            <span class="icon-circle" data-toggle="tooltip" data-placement="right" title="{{ 'PLAY' | translate }}"
              ><!--<app-etx-icons name="play_white"></app-etx-icons
            >-->
              <svg data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  d="M7 12V6.78a1.34 1.34 0 012-1.15l8.52 5.12a1.46 1.46 0 010 2.5L9 18.37a1.34 1.34 0 01-2-1.15z"
                  fill="#fff"
                ></path>
              </svg>
            </span>
          </li>
          <li class="list-inline-item me-4">
            <span
              (click)="downloadSource(landscapeFormatUrls.download, 'landscape-video')"
              class="icon-circle"
              data-toggle="tooltip"
              data-placement="right"
              title="{{ 'DOWNLOAD' | translate }}"
              ><!--<app-etx-icons
                (click)="downloadSource(squareFormatUrls.download, 'square-video')"
                name="download_white"
              ></app-etx-icons
            >-->
              <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                  class="cls-1"
                  d="M11.41 17.08a.26.26 0 00.11.07.91.91 0 00.16.11.88.88 0 00.64 0 .91.91 0 00.16-.11.26.26 0 00.11-.07L16 13.81a.85.85 0 10-1.18-1.22l-1.94 1.88V5.35a.85.85 0 00-1.7 0v9.12l-1.97-1.88A.85.85 0 008 13.81z"
                ></path>
                <path
                  class="cls-1"
                  d="M18.5 16.57a.85.85 0 00-.85.85v1.64H6.35v-1.64a.85.85 0 00-1.7 0v2.49a.85.85 0 00.85.85h13a.85.85 0 00.85-.85v-2.49a.85.85 0 00-.85-.85z"
                ></path>
              </svg>
            </span>
          </li>
          <li (click)="copyToClipboard('landscape-video')" class="list-inline-item me-4" style="position: relative">
            <ng-container *ngIf="_success | async as success">
              <ng-container *ngIf="success.format === 'landscape-video'">
                <div
                  class="copied-code-embed-msg"
                  style="position: absolute; top: 2px; left: 26px; width: 85px"
                  #selfClosingAlert
                  type="success"
                >
                  {{ 'COPIED' | translate }}
                </div>
              </ng-container>
            </ng-container>
            <span
              class="icon-circle"
              data-toggle="tooltip"
              data-placement="right"
              title="{{ 'COPY_CODE_EMBED' | translate }}"
            >
              <svg
                id="Calque_1"
                data-name="Calque 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                style="position: relative; top: 1px"
              >
                <path
                  class="cls-1"
                  d="M8.41,16a.85.85,0,0,1-.56-.21L4.14,12.64a.83.83,0,0,1-.3-.64.87.87,0,0,1,.3-.65L7.85,8.17A.85.85,0,1,1,9,9.46L6,12l3,2.54a.85.85,0,0,1,.09,1.2A.83.83,0,0,1,8.41,16Z"
                />
                <path
                  class="cls-1"
                  d="M15.59,16a.83.83,0,0,1-.64-.3.85.85,0,0,1,.09-1.2L18,12,15,9.46a.85.85,0,0,1,1.11-1.29l3.71,3.18a.87.87,0,0,1,.3.65.83.83,0,0,1-.3.64l-3.71,3.19A.85.85,0,0,1,15.59,16Z"
                />
                <path
                  class="cls-1"
                  d="M10.93,18.42h-.16a.87.87,0,0,1-.68-1L12.24,6.27a.86.86,0,0,1,1-.68.87.87,0,0,1,.68,1L11.76,17.73A.84.84,0,0,1,10.93,18.42Z"
                />
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="row row-success align-items-center" *ngIf="error$ | async">
      <div class="col-12">
        <h4>
          {{ 'ERROR' | translate }}<span class="alert-echec">{{ 'FAILURE' | translate }}!</span>
        </h4>
        <div class="progress mb-2">
          <p>
            <ngb-progressbar
              type="danger"
              textType="white"
              [value]="25"
              [showValue]="false"
              [striped]="true"
            ></ngb-progressbar>
          </p>
        </div>
      </div>
    </div>
    <!--  <button type="submit" class="btn btn-primary cancel-button">Annuler</button>-->
  </div>
</div>

<!-- TODO : Move to app container -->
<app-modal-audio-story></app-modal-audio-story>
