import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';

import { TokenCredentials } from '../../../../auth/data-access/src/lib/_models/token-credentials.model';
import { AuthState } from '../../../../auth/data-access/src/lib/_store/reducers';
import { tokenCredentials } from '../../../../auth/data-access/src/lib/_store/selectors';
import { checkSessionFailure } from '../../../../auth/data-access/src/lib/_store/actions';
import { catchError } from 'rxjs/operators';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  private bearerToken: string | null;

  constructor(
    private authStore: Store<AuthState>,
    private _ngxSmartModal: NgxSmartModalService,
    private router: Router
  ) {
    this.bearerToken = null;
    this.authStore.pipe(select(tokenCredentials)).subscribe((tokenCredentials) => {
      this.updateBearerToken(tokenCredentials);
    });
  }

  private updateBearerToken(token: TokenCredentials | null): void {
    if (token === null) {
      this.bearerToken = null;
    } else {
      this.bearerToken = 'Bearer ' + token.accessToken;
    }
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let updatedReq: HttpRequest<any>;
    if (this.bearerToken === null) {
      updatedReq = req;
    } else {
      if (AuthorizationInterceptor.hasAuthorizationHeader(req.url)) {
        const authHeader = { Authorization: this.bearerToken };
        updatedReq = req.clone({ setHeaders: authHeader });
      } else {
        updatedReq = req;
      }
    }

    return next.handle(updatedReq).pipe(
      catchError((error) => {
        if (error.status === 401 && !AuthorizationInterceptor.isLoginPage(this.router.url)) {
          this._ngxSmartModal.open('session-expired-modal');
        }
        if (error.status === 401 && AuthorizationInterceptor.isLoginPage(this.router.url)) {
          this.authStore.dispatch(checkSessionFailure());
        }
        return throwError(error);
      })
    );
  }

  private static hasAuthorizationHeader(url) {
    return url.indexOf('login') === -1;
  }

  private static isLoginPage(url) {
    return url === '/auth/login' || url === '/login' || url === '/auth/forgot-pass' || url.includes('auth');
  }
}
