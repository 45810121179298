<div id="header-mobile" class="header-mobile justify-content-between p-2">
  <div
    id="hamburger-mobile-menu"
    class="position-relative"
    (click)="toggleNavBar(); $event.preventDefault(); $event.stopPropagation()"
  >
    <!--    <app-etx-icons name="burger" class="mt-3 burger-icon"></app-etx-icons>-->
    <span class="mt-3 burger-icon">
      <svg id="Calque_1" data-name="Calque 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <defs></defs>
        <path
          d="M18.65 12.85H5.35a.85.85 0 010-1.7h13.3a.85.85 0 010 1.7zM18.65 7.35h-8.3a.85.85 0 010-1.7h8.3a.85.85 0 010 1.7zM13.65 18.35h-8.3a.85.85 0 010-1.7h8.3a.85.85 0 110 1.7z"
        ></path>
      </svg>
    </span>
  </div>

  <img width="150px" src="assets/images/logos/revolusound-etxstudio-logo.png" alt="ETX RevoluSOUND" class="col-sm-2" />
  <app-content-filters-menu *ngIf="showFilterMenu()"></app-content-filters-menu>
</div>
