import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UploadService } from '../../../../../../shared/src/lib/_services/upload.service';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { pipe, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { EditorState } from '@purplefront/editor/data-access';
import { EtxIconsRegistryService } from '../../../../../../shared/src/lib/components/etx-icons/etx-icons-registry.service';
import { etxIconUploadImage } from '../../../../../../shared/src/lib/components/etx-icons/etx-icons.model';
import { requiredFileType } from '../file-upload/required-file-validators';
import { NgxSmartModalService } from 'ngx-smart-modal';

export function uploadProgress<T>(cb: (progress: number) => void) {
  return tap((event: HttpEvent<T>) => {
    if (event.type === HttpEventType.UploadProgress) {
      cb(Math.round((100 * event.loaded) / event.total));
    }
  });
}

export function markAllAsDirty(form: FormGroup) {
  for (const control of Object.keys(form.controls)) {
    form.controls[control].markAsDirty();
  }
}

export function toFormData<T>(formValue: T) {
  const formData = new FormData();
  const imageObject = { image: formValue };
  for (const key of Object.keys(imageObject)) {
    const value = formValue[key];
    formData.append(key, value);
  }
  return formData;
}

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploadComponent),
      multi: true
    }
  ]
})
export class ImageUploadComponent implements OnChanges, ControlValueAccessor, OnDestroy {
  @Input() selectedItem: any;
  @Output() updateForm$: EventEmitter<any> = new EventEmitter<any>();
  @Output() patchImagesValues$: EventEmitter<any> = new EventEmitter<any>();
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();
  progress = 0;
  success = false;
  label = 'EDITOR_DRAG_DROP_IMAGE_CONTENT';
  fileFormat = 'jpg/png';
  availableFormats = ['jpg', 'png', 'jpeg'];
  formUpload = this._formBuilder.group({
    images: this._formBuilder.array([null])
  });
  selectedItemImageUrl: string;
  onChange: Function;
  files: FileList = null;

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    this.files = event;
    this.onChange(this.files);
    this.patchImagesValue(this.files);
    this.submit();
  }

  constructor(
    private http: HttpClient,
    private _uploadService: UploadService,
    private _store: Store<EditorState>,
    private host: ElementRef<HTMLInputElement>,
    private _etxIconsRegistry: EtxIconsRegistryService,
    private _ngxSmartModalService: NgxSmartModalService,
    private _formBuilder: FormBuilder
  ) {
    this.onChange = (value: any) => {};
    this._etxIconsRegistry.registerIcons([etxIconUploadImage]);
  }

  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  writeValue(value: null) {
    // clear file input
    this.host.nativeElement.value = '';
    this.files = null;
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  get images(): FormArray {
    return this.formUpload.get('images') as FormArray;
  }

  patchImagesValue(files: FileList) {
    this.images.clear();
    Array.from(files).forEach((file) =>
      this.images.push(this._formBuilder.control(file, requiredFileType(this.availableFormats)))
    );
  }

  toResponseBody<T>() {
    return pipe(
      filter((event: HttpEvent<T>) => event.type === HttpEventType.Response),
      map((res: HttpResponse<T>) => {
        return res.body;
      })
    );
  }
  submit() {
    this.success = false;
    if (!this.formUpload.valid) {
      markAllAsDirty(this.formUpload);
      return;
    }

    this.images.value.forEach((image, index) => {
      this.uploadImage(image, index);
    });
  }

  uploadImage(image: File, index: number): void {
    this._uploadService
      .uploadImage(toFormData({ image }))
      .pipe(
        takeUntil(this._ngUnsubscribe$),
        uploadProgress((progress) => {
          this.progress = progress;
          this._uploadService.progressImage$.next(progress);
        }),
        this.toResponseBody()
      )
      .subscribe((res) => {
        const imageURL = res.url;
        this.patchImagesValues$.emit(imageURL);
        this.progress = 0;
        this._uploadService.progressImage$.next(this.progress);
        this.success = true;
        this.formUpload.reset();
      });
  }

  hasError(field: string, error: string) {
    const control = this.formUpload.get(field);
    return control.dirty && control.hasError(error);
  }

  selectedItemHasMultipleImages(): boolean {
    return this.selectedItem.value.images?.length > 1;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedItem && changes.selectedItem.currentValue && changes.selectedItem.currentValue.value.images) {
      this.selectedItemImageUrl = changes.selectedItem.currentValue.value.images[0];
    } else {
      this.selectedItemImageUrl = null;
    }

    this.selectedItem
      ?.get('images')
      ?.valueChanges.pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe((value) => {
        this.selectedItemImageUrl = value[0];
        this.files = null;
      });
  }

  deleteImage() {
    const imgControl = this.selectedItem.get('images') as FormArray;
    imgControl.clear();
    this.files = null;
    this.success = false;
    this.progress = 0;
    this._uploadService.progressImage$.next(this.progress);
    this.selectedItemImageUrl = null;
    this.formUpload.reset();
    this.updateForm$.emit();
  }

  ngOnDestroy() {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }

  openMultiImageModal(event) {
    event.stopPropagation();
    event.preventDefault();
    this._ngxSmartModalService.getModal('multiImage').open();
  }

  onClick(event: MouseEvent) {
    if (this.selectedItem.value?.images?.length > 0) {
      event.preventDefault();
      this.openMultiImageModal(event);
    }
    event.target['value'] = null;
  }

  onAddMoreImagesClick(event: MouseEvent) {
    event.target['value'] = null;
  }

  onImageDrop(event: DragEvent) {
    event.target['value'] = null;
  }
}
