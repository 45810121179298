<ngx-smart-modal #jingleModal identifier="jingleModal">
  <div class="d-flex flex-column justify-content-center align-items-center mt-4 jingle-modal-container">
    <h1 class="mb-3">{{ 'EDIT_AUDIO_TRANSITION' | translate }}</h1>

    <form [formGroup]="jingleForm">
      <div class="d-flex align-items-center mb-2">
        <div class="form-check">
          <input
            formControlName="jingle"
            [value]="false"
            class="form-check-input radio"
            type="radio"
            name="jingle"
            id="no-jingle"
          />
          <label class="form-check-label label" for="no-jingle">{{ 'NONE' | translate }}</label>
        </div>
      </div>
      <div class="d-flex align-items-center mb-4">
        <div class="form-check">
          <input
            formControlName="jingle"
            [value]="true"
            class="form-check-input radio"
            type="radio"
            name="jingle"
            id="default-jingle"
          />
          <label class="form-check-label label" for="default-jingle">{{ 'DEFAULT' | translate }}</label>
        </div>
      </div>
      <div class="d-flex align-items-center mb-3">
        <div class="form-check">
          <input
            (change)="onCheckboxChange($event)"
            class="form-check-input radio"
            type="checkbox"
            value="true"
            id="applyGlobally"
          />
          <label class="form-check-label label" for="applyGlobally">
            {{ 'APPLY_CHANGES_GLOBALLY' | translate }}
          </label>
        </div>
      </div>
    </form>
    <div>
      <button (click)="submitForm(); jingleModal.close()" type="submit" class="submit-button btn-primary">
        {{ 'SAVE' | translate }}
      </button>
    </div>
  </div>
</ngx-smart-modal>
