import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { InfoBannerEffects } from './store/effects/info-banner.effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './store/reducers';

@NgModule({
  imports: [CommonModule, EffectsModule.forFeature([InfoBannerEffects]), StoreModule.forFeature('infoBanner', reducer)]
})
export class InfoBannerDataAccessModule {}
