<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: none">
  <defs>
    <g id="button-bin">
      <path
        class="st1"
        d="M26,14.1h-3.4v-0.9c0-0.9-0.7-1.6-1.6-1.6h-2.9c-0.9,0-1.6,
     0.7-1.6,1.6v0.9H13c-0.5,0-0.8,0.4-0.8,0.9
	c0,0.5,0.4,0.8,0.8,0.8h0.2v10.3c0,1.2,1,2.2,2.2,2.2l0,0h8.3c1.2,0,2.2-1,2.2-2.2l0,
	0V15.9H26c0.5,0,0.9-0.4,0.9-0.8C26.9,14.5,26.5,14.2,26,14.1C26,14.2,26,14.2,26,14.1z
	M18.2,13.3h2.7v0.8h-2.7L18.2,13.3z M24.2,26.1c0,0.3-0.2,0.5-0.5,0.5h-8.4
	c-0.3,0-0.5-0.2-0.5-0.5V15.9h9.3L24.2,26.1z"
      />
      <path
        class="st1"
        d="M19.5,25.2c0.3,0,0.6-0.3,0.6-0.6l0,0v-6.8c0-0.3-0.3-0.6-0.6-0.6s-0.6,
     0.3-0.6,0.6v6.8C18.9,25,19.2,25.2,19.5,25.2z"
      />
      <path
        class="st1"
        d="M22.2,25.2c0.3,0,0.6-0.3,0.6-0.6l0,0v-6.8c0-0.3-0.3-0.6-0.6-0.6l0,
     0c-0.3,0-0.6,0.3-0.6,0.6l0,0v6.8C21.6,25,21.8,25.2,22.2,25.2z"
      />
      <path
        class="st1"
        d="M16.9,25.2c0.3,0,0.6-0.3,0.6-0.6l0,0v-6.8c0-0.3-0.3-0.6-0.6-0.6s-0.6,
     0.3-0.6,0.6l0,0v6.8C16.3,25,16.6,25.2,16.9,25.2z"
      />
    </g>
    <g id="button-download">
      <path
        class="st1"
        d="M19.4,25.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0.1,
     0.6,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1-0.1l3.4-3.3c0.4-0.3,0.4-0.8,
     0.1-1.2c-0.3-0.4-0.8-0.4-1.2-0.1c0,0,0,0-0.1,0.1l-1.9,1.9v-7.3c0-0.5-0.4-0.9-0.9-0.9s-0.9,
     0.4-0.9,0.9v7.3l-1.9-1.9c-0.3-0.3-0.9-0.3-1.2,0c-0.3,0.3-0.3,0.9,0,1.2L19.4,25.1z"
      />
      <path
        class="st1"
        d="M26.5,24.6c-0.5,0-0.9,0.4-0.9,0.9l0,
   0v1.6H14.4v-1.6c0-0.5-0.4-0.9-0.8-0.9c-0.5,0-0.9,0.4-0.9,0.8c0,0,0,0,0,0v2.5c0,0.5,0.4,
   0.9,0.8,0.9h0h13c0.5,0,0.9-0.4,0.9-0.9l0,0v-2.5C27.4,25,27,24.6,26.5,24.6L26.5,24.6z"
      />
    </g>
    <g id="button-share">
      <path
        class="st1"
        d="M23.4,21.8c-0.9,0-1.7,0.4-2.4,1l-4.4-2.2c0.1-0.4,
     0.1-0.8,0-1.3l4.4-2.2c1.2,1.3,3.2,1.3,4.4,0.1
	c1.3-1.2,1.3-3.2,0.1-4.4s-3.2-1.3-4.4-0.1c-0.8,0.7-1.1,1.8-0.9,2.9l-4.4,
	2.2c-0.6-0.7-1.5-1-2.4-1c-1.8,0-3.2,1.4-3.2,3.2
	c0,1.8,1.4,3.2,3.2,3.2c0.9,0,1.8-0.4,2.4-1l4.4,2.2c-0.3,1.7,0.8,3.4,2.5,
	3.8c1.7,0.3,3.4-0.8,3.8-2.5s-0.8-3.4-2.5-3.8
	C23.8,21.8,23.6,21.8,23.4,21.8L23.4,21.8z M23.4,13.5c0.8,0,1.5,0.7,1.5,
	1.5c0,0.8-0.7,1.5-1.5,1.5c-0.8,0-1.5-0.7-1.5-1.5
	c0,0,0,0,0,0C21.9,14.2,22.6,13.5,23.4,13.5z M13.5,21.5c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,
	0.7-1.5,1.5-1.5c0.8,0,1.5,0.6,1.5,1.5
	C15,20.8,14.3,21.5,13.5,21.5L13.5,21.5z M23.4,26.5c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,
	0.7-1.5,1.5-1.5c0.8,0,1.5,0.6,1.5,1.5
	C24.9,25.8,24.2,26.5,23.4,26.5L23.4,26.5z"
      />
    </g>
    <g id="button-add">
      <path
        class="st1"
        d="M27.5,18.7h-6.2v-6.2c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,
     0.6-1.3,1.3c0,0,0,0,0,0v6.2h-6.2
	c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c0,0,0,0,0,0h6.2v6.2c0,0.7,0.6,1.3,1.3,
	1.3s1.3-0.6,1.3-1.3c0,0,0,0,0,0v-6.2h6.2
	c0.7,0,1.3-0.6,1.3-1.3S28.2,18.7,27.5,18.7C27.5,18.7,27.5,18.7,27.5,18.7z"
      />
    </g>
    <g id="button-save">
      <path
        class="st1"
        d="M25.5,28.8c-0.2,0-0.3,0-0.5-0.1l-5-3.3l-5,3.3c-0.4,0.3-0.9,
     0.2-1.2-0.1c-0.1-0.2-0.2-0.4-0.2-0.6V13
	c0-0.5,0.4-0.9,0.8-0.9h0h11c0.5,0,0.9,0.4,0.9,0.8v0v15C26.4,28.5,26,28.8,25.5,
	28.8C25.5,28.9,25.5,28.9,25.5,28.8L25.5,28.8z
	 M20,23.6c0.2,0,0.3,0,0.5,0.1l4.2,2.7V13.8h-9.3v12.6l4.2-2.7C19.7,23.6,19.8,23.6,20,23.6z"
      />
    </g>
    <g id="icon-playlist-1" data-name="Calque 1">
      <circle cx="12" cy="12" r="12" style="fill: #702082" />
      <path d="M17.24,16.88H6.76a.72.72,0,0,0,0,1.43H17.24a.72.72,0,0,0,0-1.43Z" style="fill: #fff" />
      <path d="M17.24,13.57H6.76a.71.71,0,1,0,0,1.42H17.24a.71.71,0,0,0,0-1.42Z" style="fill: #fff" />
      <path d="M17.24,10.25H6.76a.72.72,0,0,0,0,1.43H17.24a.72.72,0,0,0,0-1.43Z" style="fill: #fff" />
      <path d="M11,8.36h6.27a.72.72,0,0,0,0-1.43H11a.72.72,0,0,0,0,1.43Z" style="fill: #fff" />
      <path
        d="M6.72,9.31,8.75,8a.43.43,0,0,0,.11-.12.4.4,0,0,0-.09-.57l0,0L6.72,6a.61.61,0,0,
    0-.24-.07.43.43,0,0,
    0-.43.42h0V9a.34.34,0,0,0,.1.23A.44.44,0,0,0,6.72,9.31Z"
        style="fill: #fff"
      />
    </g>
  </defs>
</svg>

<div class="flash-container d-flex flex-column" [@showHide]="showSidebar">
  <div class="container d-flex flex-column">
    <div class="top">
      <div class="row mt-4 mt-md-5 ms-3 d-flex justify-content-center">
        <div class="col-sm-6">
          <h3>{{ 'DISCOVER_MY_SELECTION' | translate }}</h3>
        </div>
        <!--        <div class="col-sm-6 unselect-all">Tout déselectionner</div>-->
        <!--        &ndash;&gt;-->
      </div>
      <div *ngFor="let article of selectedArticles" class="d-flex align-items-center row me-1 me-4 mt-3 mb-1">
        <div class="col-sm-10 card flash-briefing-card" style="width: 19rem">
          <div class="card-body">
            <h5 class="card-title">{{ article.title }}</h5>
            <h6 class="card-subtitle mt-3">ETX Studio • {{ article.publication_date | date }}</h6>
          </div>
          <div class="delete-card">
            <i
              (click)="removeArticleFromSelection(article.id)"
              class="fa fa-close"
              title="delete article"
              aria-hidden="true"
            ></i>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showSidebar === 'active'" class="close-sidebar-btn">
      <span class="fa-stack fa-lg">
        <i class="fa fa-circle fa-stack-2x icon-background"></i>
        <i (click)="closeSidebar()" class="fa fa-arrow-right fa-stack-1x" aria-hidden="true"></i>
      </span>
    </div>

    <div class="flash-footer d-flex flex-column justify-content-center align-items-center">
      <h6 class="mt-2">{{ selectedArticles?.length }} {{ 'DISCOVER_SELECTED_ARTICLES' | translate }}</h6>
      <button (click)="goToEditor()" type="button" class="btn btn-lg flash-briefing-btn">
        {{ 'DISCOVER_ADD_TO_EDITOR' | translate }}
      </button>
      <button
        type="button"
        (click)="toggleFeatureBox(); clickedInsideFeatureBox($event)"
        class="more-btn btn btn-lg mt-2"
      >
        {{ 'DISCOVER_MORE_BUTTON' | translate }}
      </button>
      <div *ngIf="showFeatureBox" class="">
        <ul class="nav feature-box-list shadow">
          <li class="nav-item">
            <a (click)="addToPlaylist()" class="nav-link active">
              <svg viewBox="0 0 32 32">
                <use xlink:href="#button-add"></use></svg
              ><span class="feature-box-link">{{ 'DISCOVER_ADD_TO_PLAYLIST' | translate }}</span></a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" title="Coming soon">
              <svg viewBox="0 0 32 32">
                <use xlink:href="#button-download"></use></svg
              ><span class="feature-box-link">{{ 'DISCOVER_DOWNLOAD_ARTICLES' | translate }}</span></a
            >
          </li>
          <li class="nav-item">
            <a (click)="shareArticles()" class="nav-link" title="Coming soon">
              <svg viewBox="0 0 32 32">
                <use xlink:href="#button-share"></use></svg
              ><span class="feature-box-link">{{ 'DISCOVER_SHARE_ARTICLES' | translate }}</span></a
            >
          </li>
          <li class="nav-item">
            <a (click)="bookmarkArticles()" class="nav-link" title="Coming soon">
              <svg viewBox="0 0 32 32">
                <use xlink:href="#button-save"></use></svg
              ><span class="feature-box-link">{{ 'DISCOVER_ADD_TO_BOOKMARKS' | translate }}</span></a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
