<div
  style="position: relative; height: 100%; display: flex; justify-content: center"
  (window:resize)="onResize($event)"
  (mouseleave)="tooltip.active = false"
>
  <div
    fxHide
    fxShow.gt-md
    *ngIf="isEditorFocused() || showToolbar"
    (mousenter)="showToolbar = true"
    (mouseleave)="showToolbar = false"
    class="toolbar"
    [ngStyle.xs]="{ top: '-54px', bottom: 'inherit' }"
    [ngStyle.sm]="{ top: '-54px', bottom: 'inherit' }"
  >
    <div class="toolbar_frame" [class.show]="true">
      <div class="toolbar_frame_actions show">
        <div fxHide fxShow.gt-md class="count-letters">
          <strong [style.color]="editor?.textContent?.length > 8000 ? 'red' : 'inherit'">{{
            editor?.textContent?.length
          }}</strong
          >&nbsp;
          <small [style.color]="editor?.textContent?.length > 8000 ? 'red' : 'inherit'"
            ><span class="d-xs-none">{{ 'CHARACTERS' | translate }}</span></small
          >
        </div>
        <ng-container>
          <button
            type="button"
            [disabled]="cantUseBtnVoice()"
            [matTooltip]="'TOOLTIP_VOICE' | translate"
            matTooltipClass="etx-tooltip"
            matTooltipPosition="below"
            class="btnAction voice"
            (click)="onBtnSplitVoice_Click()"
            data-toggle="tooltip"
            data-placement="right"
            title="{{ 'TOOLTIP_VOICE' | translate }}"
          >
            <span fxShow fxHide.gt-md>{{ 'VOICE' | translate }}</span>
            <span fxHide fxShow.gt-md>{{ 'CHANGE_VOICE' | translate }}</span>
          </button>
          <button
            [disabled]="cantUseBtnPrononciation()"
            class="btnAction sub"
            type="button"
            (click)="onBtnPronunciation_Click()"
            data-toggle="tooltip"
            data-placement="right"
            title="{{ 'TOOLTIP_PRONUNCIATION' | translate }}"
          >
            <span fxShow fxHide.gt-md>{{ 'PRONUNCIATION' | translate }}</span>
            <span fxHide fxShow.gt-md>{{ 'CHANGE_PRONUNCIATION' | translate }}</span>
          </button>
          <button
            type="button"
            [disabled]="cantUseBtnPause()"
            class="btnAction pause"
            data-toggle="tooltip"
            data-placement="right"
            title="{{ 'TOOLTIP_PAUSE' | translate }}"
          >
            {{ 'PAUSE' | translate }}
          </button>
          <button
            *ngIf="false"
            type="button"
            class="closeExtend"
            [class.show]="extendToolbar"
            (click)="extendToolbar = false"
          >
            X
          </button>
          <div class="undo-redo">
            <button
              type="button"
              class="btnAction undo no-decoration"
              style="padding-left: 0; padding-right: 0"
              [disabled]="history.currentIndex === 0"
              data-toggle="tooltip"
              data-placement="right"
              title="{{ 'TOOLTIP_UNDO' | translate }}"
            >
              <svg width="40" height="40" viewBox="0 0 24 24">
                <defs></defs>
                <path
                  class="cls-1"
                  d="M12.87,10.49H8L10.41,8.1a.86.86,0,0,0,0-1.21.85.85,0,0,0-1.2,0L5.37,10.73a.86.86,0,0,0,0,1.21l3.84,3.84a.87.87,0,0,0,.6.25.86.86,0,0,0,.6-1.46L8,12.19h4.84a4.32,4.32,0,0,1,4.31,4.31.85.85,0,0,0,1.7,0A6,6,0,0,0,12.87,10.49Z"
                />
              </svg>
            </button>
            <button
              type="button"
              class="btnAction redo no-decoration"
              style="padding-left: 0; padding-right: 0"
              [disabled]="history.currentIndex === history.list.length - 1"
              data-toggle="tooltip"
              data-placement="right"
              title="{{ 'TOOLTIP_REDO' | translate }}"
            >
              <svg width="40" height="40" viewBox="0 0 24 24">
                <defs></defs>
                <path
                  class="cls-1"
                  d="M11.13,10.49H16L13.59,8.1a.86.86,0,0,1,0-1.21.85.85,0,0,1,1.2,0l3.84,3.84a.86.86,0,0,1,0,1.21l-3.84,3.84a.87.87,0,0,1-.6.25.86.86,0,0,1-.6-1.46L16,12.19H11.13A4.32,4.32,0,0,0,6.82,16.5a.85.85,0,1,1-1.7,0A6,6,0,0,1,11.13,10.49Z"
                />
              </svg>
            </button>
            <button
              type="button"
              class="btnAction no-decoration"
              (click)="onRemoveStyle(); extendToolbar = false"
              [disabled]="cantUseBtnRemoveStyle()"
              data-toggle="tooltip"
              data-placement="right"
              title="{{ 'TOOLTIP_RESET' | translate }}"
            >
              <svg width="40" height="40" viewBox="0 0 24 24">
                <defs></defs>
                <path
                  class="cls-1"
                  d="M18.21,18.23H14.33l4.44-8.49A2,2,0,0,0,17.93,7L12.73,4.3A2,2,0,0,0,10,5.15L4.49,15.7a2,2,0,0,0,.84,2.71l2.3,1.2a2.55,2.55,0,0,0,1.21.3h2.59l.09,0h6.69a.85.85,0,0,0,0-1.7ZM11.52,5.94a.33.33,0,0,1,.18-.16.31.31,0,0,1,.24,0l5.2,2.72a.29.29,0,0,1,.15.18.35.35,0,0,1,0,.24l-2.78,5.31-5.75-3ZM8.42,18.11,6.12,16.9A.31.31,0,0,1,6,16.48l2-3.73,5.75,3-1.28,2.44H8.84A.9.9,0,0,1,8.42,18.11Z"
                />
              </svg>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <span
    *ngIf="!editor?.innerHTML"
    class="editor-text-editor-placeholder"
    style="position: absolute; top: 8px; left: 11px; content: attr(data-placeholder); color: #888; pointer-events: none"
  >
    {{ 'EDITOR_TEXT_EDITOR_DESCRIPTION' | translate }}...
  </span>
  <div
    #editorRef
    spellcheck="false"
    id="editor"
    contenteditable
    (paste)="handlePaste($event)"
    (keydown)="onKeyDown($event)"
    (keyup)="onKeyUp($event)"
    (onInput)="onInput($event)"
    (mousedown)="onMouseDown($event)"
    (mousemove)="onMouseMove($event)"
    (dblclick)="onDoubleClick($event)"
    (mouseleave)="onMouseLeave($event)"
    (mouseenter)="onMouseEnter($event)"
    (scroll)="onScroll()"
    style="height: 100%; width: 100%; padding: 5px 10px 5px; border: 0; overflow-y: scroll"
  ></div>

  <span
    class="etx-tooltip"
    style="position: absolute; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 320px"
    [style.left.px]="tooltip.left"
    [style.top.px]="tooltip.top"
    [style.opacity]="tooltip.active ? '1' : '0'"
    [style.transform]="tooltip.active ? 'scale(1)' : 'scale(0)'"
  >
    {{ tooltip.value }}
  </span>
</div>
