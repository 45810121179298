import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderRioComponent } from './header-rio/header-rio.component';
import { HeaderUiModule } from '@purplefront/header/ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { SharedModule } from '@purplefront/shared';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { HeaderFeatureShellModule } from '@purplefront/header/feature-shell';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    HeaderUiModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    HeaderFeatureShellModule,

    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgbDropdownModule,
    RouterModule
  ],
  declarations: [HeaderRioComponent],
  exports: [HeaderRioComponent]
})
export class HeaderRioUiModule {}
