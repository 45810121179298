import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import * as fromSearch from '@purplefront/search/data-access';
import * as fromRouterStore from '@purplefront/app-router/data-access';
import { select, Store } from '@ngrx/store';
import * as fromAuthStore from '@purplefront/auth/data-access';
import * as fromPreferencesStore from '@purplefront/preferences/data-access';
import { CatalogState, Feed, userFeeds } from '@purplefront/catalog/data-access';
import { Theme, TrackingService } from '@purplefront/shared';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DOCUMENT, Location } from '@angular/common';
import { AppRouterFacadeService, goToDiscover } from '@purplefront/app-router/data-access';
import { selectContentLang } from '@purplefront/preferences/data-access';

@Component({
  selector: 'app-search-container',
  templateUrl: './search-container.component.html',
  styleUrls: ['./search-container.component.scss'],
  animations: [
    trigger('showHide', [
      state(
        'active',
        style({
          position: 'fixed',
          bottom: 0,
          transform: 'translateX(0%)',
          'z-index': '50'
        })
      ),
      state(
        'inactive',
        style({
          position: 'fixed',
          bottom: 0,
          transform: 'translateX(-105%)',
          'z-index': '50'
        })
      ),
      transition('inactive => active', animate('0.3s ease-in')),
      transition('active => inactive', animate('0.3s ease-out'))
    ])
  ]
})
export class SearchContainerComponent implements OnInit, OnDestroy {
  @Input() navOpen: boolean;
  @Input() querySuggest: string;
  @Output() navToggled = new EventEmitter();
  @ViewChild('searchInput') searchInput: ElementRef;

  public isLogged: boolean;
  public hasExpandedSearchBox = false;
  public user$: Observable<fromAuthStore.TokenCredentials>;
  public langFromUrl: string;
  public contentLang: string;
  public currentUrl: string;
  public currentFeed: Feed;
  public searchResponse$ = new Observable<any>();
  public searchLoaded$: Observable<boolean>;
  public searchControl: FormControl;
  public searchString$ = new BehaviorSubject<string>(null);
  public searchString: string = null;
  public tablet$: Observable<boolean>;
  public page$: Observable<string>;
  public logoPath: string;
  public activeTheme: Theme;
  public latestArticles$ = new BehaviorSubject<any[]>([]);
  public keywords$ = new BehaviorSubject<any[]>([]);
  public userFeeds: any;

  public _ngUnsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _routerStore: Store<fromRouterStore.AppRouterState>,
    private _appRouterFacade: AppRouterFacadeService,
    private _searchStore: Store<fromSearch.SearchPartialState>,
    private _preferencesStore: Store<fromPreferencesStore.PreferencesState>,
    private _catalogStore: Store<CatalogState>,
    private _renderer: Renderer2,
    private _authStore: Store<fromAuthStore.AuthState>,
    private _trackingService: TrackingService,
    private _location: Location,
    @Optional() @Inject(DOCUMENT) document: any
  ) {}

  ngOnInit(): void {
    this.autocomplete();
    this.getDataFromStore();
  }

  autocomplete() {
    this.searchControl = new FormControl('');
    this.searchControl.valueChanges.pipe(takeUntil(this._ngUnsubscribe$)).subscribe((value) => {
      this.searchString$.next(value);
      return this._searchStore.dispatch(
        fromSearch.searchQuery({
          payload: {
            searchString: value,
            contentLang: this.langFromUrl
          }
        })
      );
    });
  }

  /*
   * Get data from Store
   */

  getDataFromStore(): void {
    this.searchLoaded$ = this._searchStore.select(fromSearch.selectSearchLoaded);
    this.user$ = this._authStore.pipe(select(fromAuthStore.tokenCredentials));
    this.searchResponse$ = this._searchStore.select(fromSearch.selectSearchResponse);
    this.initSearchData();

    /* this.searchNavToggled$.subscribe((val) => {
      this.handleScrollBar(val);
      if (val) {
        this.initSearchData();
      }
    });*/

    /*
     * Get current Feed
     */

    this._preferencesStore
      .select(fromPreferencesStore.currentFeed)
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe((feed) => (this.currentFeed = feed));

    /*
     * Get current content lang
     */

    this._routerStore
      .select(fromRouterStore.selectContentLang)
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe((lang) => (this.langFromUrl = lang));

    this._preferencesStore
      .select(fromPreferencesStore.selectContentLang)
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe((lang) => (this.contentLang = lang));

    this._searchStore
      .pipe(
        select(fromRouterStore.selectSearchString),
        filter((search) => !!search)
      )
      .subscribe((search) => {
        this.searchString = search;
      });
    /**
     * Check if user is logged or not
     */

    this._authStore.pipe(select(fromAuthStore.isLogged)).subscribe((status) => {
      this.isLogged = status;
    });

    this.searchResponse$.pipe(takeUntil(this._ngUnsubscribe$)).subscribe((data) => {
      if (data) {
        const articles = data['content'] ? data['content'].slice(0, 4) : [];
        this.latestArticles$.next(articles);
        this.keywords$.next([...data['people'], ...data['brands'], ...data['categories']]);
      }
    });

    this._routerStore.select(fromRouterStore.selectSearchString).subscribe((search) => this.searchString$.next(search));

    /**
     * If search term is present in url, dispatch it & get it
     */

    this.searchString$.pipe(take(1)).subscribe((terms) => {
      if (terms) {
        this._searchStore.dispatch(
          fromSearch.searchQuery({
            payload: { searchString: terms, contentLang: this.langFromUrl || this.contentLang }
          })
        );
      }
    });
    this.getUserFeeds();
  }

  getUserFeeds() {
    this._catalogStore
      .pipe(
        select(userFeeds),
        takeUntil(this._ngUnsubscribe$),
        map((feeds) => (this.userFeeds = feeds))
      )
      .subscribe();
  }

  /**
   * Init first set of search data
   */
  initSearchData() {
    const search$ = this._routerStore.select(fromRouterStore.selectSearchString);
    const lang$ = this._routerStore.select(fromRouterStore.selectContentLang);
    combineLatest([lang$, search$])
      .pipe(take(1))
      .subscribe(([lang, search]) => {
        this.searchControl.patchValue(search);
        this._searchStore.dispatch(
          fromSearch.searchQuery({
            payload: {
              searchString: search || '',
              contentLang: this.langFromUrl || this.contentLang || lang
            }
          })
        );
      });
  }

  /**
   * On submit search term
   */

  search(searchString: string): void {
    this._trackingService
      .trackEvent({ type: 'search-request', value: searchString, payload: { feeds: this.userFeedSlugs } })
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe();
    if (searchString) {
      this._searchStore.dispatch(
        fromSearch.searchQuery({
          payload: { searchString: searchString, contentLang: this.langFromUrl || this.contentLang }
        })
      );
    }

    this.goToDiscoverPage('', searchString);
  }

  public goToDiscoverPage(tag: string = undefined, search: string = undefined) {
    const lang$ = this._preferencesStore.pipe(select(selectContentLang));
    const feeds$ = this._catalogStore.pipe(select(userFeeds));

    combineLatest([feeds$, lang$])
      .pipe(debounceTime(0), take(1))
      .subscribe(([_feeds, _lang]) => {
        let queryParams: any = {};
        let activeFeedSlug = [];
        if (!_feeds.length) {
          activeFeedSlug = [];
        } else {
          if (_feeds.length === 1 && _feeds[0].slug === 'all') {
            activeFeedSlug = [];
          } else {
            activeFeedSlug = _feeds.map((f) => f.slug);
          }
        }

        queryParams = {
          feed: activeFeedSlug.length ? [...activeFeedSlug] : null,
          search
        };

        const navigationExtras: NavigationExtras = {
          queryParams
        };

        this._routerStore.dispatch(goToDiscover({ payload: { path: ['discover', _lang], extras: navigationExtras } }));
      });
  }

  /**
   * Close search & remove search bar
   */

  clearSearchTerm(): void {
    this.hasExpandedSearchBox = false;
    this.searchString = '';
    this.searchString$.next('');
    this._searchStore.dispatch(
      fromSearch.searchQuery({ payload: { searchString: null, contentLang: this.contentLang } })
    );

    this.setInputFocus();
  }

  /**
   * navigate to article page
   * @param article
   */
  openArticle(article: any) {
    this.trackArticleClick(article);
    this._appRouterFacade.goToArticlePage(article);
  }

  trackArticleClick(article: any) {
    let type = '';
    if (this.searchControl.value) {
      type = 'search-related-article';
    } else {
      type = 'search-latest-article';
    }
    this._trackingService
      .trackEvent({
        type: type,
        value: article.title,
        payload: { mainCategory: article.mainCategory, feeds: this.userFeedSlugs }
      })
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe();
  }

  trackKeywordClick(query: string, event: any) {
    this._trackingService
      .trackEvent({
        type: 'search-trending-tag',
        value: query,
        payload: {
          feed: this.userFeedSlugs
        }
      })
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe();
  }

  trackSuggestClick(query: string, event: any, searchEntity: string) {
    this._trackingService
      .trackEvent({
        type: 'search-related-tag',
        value: query,
        payload: { entityType: searchEntity, feed: this.userFeedSlugs }
      })
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe();
  }

  get userFeedSlugs() {
    return this.userFeeds.map((feed) => feed.slug);
  }

  /**
   * Hide body scrollbar when opening search overlay
   * @param searchNavToggled
   */
  handleScrollBar(searchNavToggled): void {
    searchNavToggled
      ? this._renderer.setStyle(document.body, 'overflowY', 'hidden')
      : this._renderer.setStyle(document.body, 'overflowY', 'auto');
  }

  /**
   * On opening search bar
   */

  expandSearchBox(): void {
    this.hasExpandedSearchBox = !this.hasExpandedSearchBox;
    if (this.hasExpandedSearchBox) {
      setTimeout(() => {
        this._renderer.selectRootElement(this.searchInput.nativeElement).focus();
      });
    }
  }

  setInputFocus(): void {
    this._renderer.selectRootElement(this.searchInput.nativeElement).focus();
  }

  closeModalSearch($event) {
    this._location.back();
  }

  /**
   * Prevent memory leaks unsubscribe when destroy component
   */
  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
