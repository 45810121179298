import { createAction, props, union } from '@ngrx/store';

import { TokenCredentials } from '../../_models';

export enum ActionType {
  INITIALIZE_SESSION = '[Auth] Initialize Session',
  INITIALIZE_LANG = '[Auth] Initialize Lang',
  SESSION_INITIALIZED = '[Auth] Session Initialized',
  LOGIN_AS_ANONYMOUS = '[Auth] Login as Anonymous',
  CHECK_SESSION = '[Auth] Check Session',
  CHECK_SESSION_SUCCESS = '[Auth] Check Session Success',
  CHECK_SESSION_FAILURE = '[Auth] Check Session Failure',
  CHECK_SESSION_ERROR = '[Auth] Check Session Error',
  LOGIN = '[Auth] Login',
  LOGIN_SUCCESS = '[Auth] Login Success',
  LOGIN_FAILURE = '[Auth] Login Failure',
  LOGIN_ERROR = '[Auth] Login Error',
  LOGIN_REDIRECT = '[Auth] Login Redirect',
  LOGOUT = '[Auth] Logout',
  LOGOUT_SUCCESS = '[Auth] Logout Success',
  LOGOUT_ERROR = '[Auth] Logout Error',
  LOGOUT_FAILURE = '[Auth] Logout Failure',
  SEND_EMAIL = '[Forgot Password] Send mail',
  SEND_EMAIL_SUCCESS = '[Forgot Password] Send Success',
  SEND_EMAIL_ERROR = '[Forgot Password] Send mail Error',
  SEND_EMAIL_FAILURE = '[Reset Password] Send mail Failure',
  REGISTER = '[Register] Registration',
  REGISTER_SUCCESS = '[Register] Registration Success',
  REGISTER_FAILURE = '[Register] Registration Failure',
  REGISTER_ERROR = '[Register] Registration Error',
  RESET_PASSWORD = '[Reset Password] Reset password',
  RESET_PASSWORD_FAIL = '[Reset Password] Reset password fail',
  RESET_PASSWORD_SUCCESS = '[Reset Password] Reset password success',
  ACTIVATION_REGISTER_CODE = '[Auth] Activate the registration code',
  ACTIVATION_REGISTER_CODE_FAIL = '[Auth] Activate the registration code fail',
  ACTIVATION_REGISTER_CODE_SUCCESS = '[Auth] Activate the registration code success'
}

export const initializeSession = createAction(ActionType.INITIALIZE_SESSION);

export const sessionInitialized = createAction(
  ActionType.SESSION_INITIALIZED,
  props<{
    payload: {
      anonymousSession: boolean;
      data: any;
    };
  }>()
);

export const loginAsAnonymous = createAction(ActionType.LOGIN_AS_ANONYMOUS);

export const checkSession = createAction(ActionType.CHECK_SESSION, props<{ payload: { token: TokenCredentials } }>());

export const activateRegistrationCode = createAction(
  ActionType.ACTIVATION_REGISTER_CODE,
  props<{ payload: { code: string } }>()
);
export const activateRegistrationCodeFail = createAction(
  ActionType.ACTIVATION_REGISTER_CODE_FAIL,
  props<{ payload: { status: string } }>()
);
export const activateRegistrationCodeSuccess = createAction(
  ActionType.ACTIVATION_REGISTER_CODE_SUCCESS,
  props<{ payload: { status: string; data: any } }>()
);

export const initializeLang = createAction(ActionType.INITIALIZE_LANG);

export const checkSessionSuccess = createAction(
  ActionType.CHECK_SESSION_SUCCESS,
  props<{
    payload: {
      data: any;
      token: TokenCredentials;
    };
  }>()
);

export const checkSessionFailure = createAction(ActionType.CHECK_SESSION_FAILURE);

export const checkSessionError = createAction(
  ActionType.CHECK_SESSION_ERROR,
  props<{
    payload: {
      error: any;
    };
  }>()
);

export const login = createAction(
  ActionType.LOGIN,
  props<{
    payload: {
      username: string;
      password: string;
    };
  }>()
);

export const loginSuccess = createAction(
  ActionType.LOGIN_SUCCESS,
  props<{
    payload: {
      token: TokenCredentials;
    };
  }>()
);

export const loginFailure = createAction(
  ActionType.LOGIN_FAILURE,
  props<{
    payload: { message: string };
  }>()
);

export const loginError = createAction(
  ActionType.LOGIN_ERROR,
  props<{
    payload: {
      error: any;
    };
  }>()
);

export const loginRedirect = createAction(ActionType.LOGIN_REDIRECT);

export const logout = createAction(ActionType.LOGOUT);

export const logoutError = createAction(ActionType.LOGOUT_ERROR, props<{ payload: { data: any } }>());

export const logoutSuccess = createAction(ActionType.LOGOUT_SUCCESS);

export const logoutFailure = createAction(ActionType.LOGOUT_FAILURE);

export const sendEmail = createAction(ActionType.SEND_EMAIL, props<{ payload: { email: string } }>());

export const sendEmailError = createAction(ActionType.SEND_EMAIL_ERROR, props<{ payload: { error: any } }>());

export const sendEmailSuccess = createAction(ActionType.SEND_EMAIL_SUCCESS);

export const sendEmailFailure = createAction(
  ActionType.SEND_EMAIL_FAILURE,
  props<{ payload: { sendEmailErrorMessage: any } }>()
);

export const register = createAction(ActionType.REGISTER, props<{ payload: {} }>());

export const registerError = createAction(ActionType.REGISTER_ERROR, props<{ payload: { error: any } }>());

export const registerSuccess = createAction(ActionType.REGISTER_SUCCESS);

export const registerFailure = createAction(
  ActionType.REGISTER_FAILURE,
  props<{ payload: { registerErrorMessage: any } }>()
);

export const resetPassword = createAction(ActionType.RESET_PASSWORD, props<{ payload: {} }>());

export const resetPasswordFail = createAction(
  ActionType.RESET_PASSWORD_FAIL,
  props<{ payload: { resetPasswordError: any } }>()
);

export const resetPasswordSuccess = createAction(ActionType.RESET_PASSWORD_SUCCESS);

const all = union({
  initializeSession,
  sessionInitialized,
  checkSession,
  checkSessionSuccess,
  checkSessionFailure,
  checkSessionError,
  login,
  loginSuccess,
  loginFailure,
  loginError,
  loginRedirect,
  logout,
  logoutSuccess,
  logoutError,
  logoutFailure,
  register,
  registerError,
  registerSuccess,
  registerFailure,
  sendEmail,
  sendEmailError,
  sendEmailSuccess,
  sendEmailFailure,
  resetPassword,
  resetPasswordFail,
  resetPasswordSuccess,
  activateRegistrationCode,
  activateRegistrationCodeSuccess,
  activateRegistrationCodeFail
});

export type AuthActionsUnion = typeof all;
