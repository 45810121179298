import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SelectMenuComponent } from './components/select-menu/select-menu.component';
import { PreferencesMenuComponent } from './components/preferences-menu/preferences-menu.component';
import { BookmarksMenuComponent } from './components/bookmarks-menu/bookmarks-menu.component';
import { NgbAccordionModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SavedSearchUiModule } from '@purplefront/saved-search/ui';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    NgbAccordionModule,
    NgbAlertModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RouterModule,
    SavedSearchUiModule,
    PerfectScrollbarModule
  ],
  declarations: [SelectMenuComponent, PreferencesMenuComponent, BookmarksMenuComponent],
  exports: [SelectMenuComponent, PreferencesMenuComponent, BookmarksMenuComponent]
})
export class MenuUiModule {}
