<div class="d-flex flex-column justify-content-center align-items-center">
  <img width="150px" src="assets/images/logos/logo-etx-daily-up.svg" alt="ETX Daily UP" class="col-sm-2 mx-auto mt-2" />
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-10 mt-5">
        <h3>CONDITIONS GENERALES D’UTILISATION DU SITE ETX Daily Up</h3>
        <p>Version du 30 novembre 2020</p>
        <h4>OBJET</h4>

        <p>
          Les présentes conditions générales d’utilisation (CGU) sont conclues, d’une part, entre ETX Daily Up et,
          d’autre part, l’Abonné et le cas échéant l’Utilisateur utilisant le Service ETX Daily Up.
        </p>

        <p>
          Les conditions générales d’utilisation sont accessibles en ligne à l’adresse :
          https://dailyup.etxstudio.com/preferences/general/fr
        </p>

        <p>
          L’Abonné et l’Utilisateur déclarent les avoir lues et acceptées. Leur souscription et l’accès au service
          emporte de plein droit acceptation expresse et sans réserve des présentes conditions générales d’utilisation.
        </p>

        <p>
          Ces conditions générales d’utilisation prévaudront sur toutes autres conditions générales ou particulières non
          expressément agréées par ETX Daily Up.
        </p>

        <p>
          ETX Daily Up se réserve le droit de modifier à tout moment les conditions générales d’utilisation. A ce titre,
          l’Abonné et l’Utilisateur reconnaissent et acceptent que leur utilisation et tout simplement leur accès au
          Service après la date des modifications emportera leur acceptation des CGU telles que modifiées.
        </p>

        <p>
          Dans la mesure où l’Utilisateur accède au service de ETX Daily Up, il garantit à ETX Daily Up qu’il détient
          les autorisations et les pouvoirs pour accepter les CGU au nom de l’Abonné, personne morale pour laquelle il
          utilise ou accède au service en créant un Compte.
        </p>

        <h4>ARTICLE 1 : definitions</h4>

        <h6 class="font-weight-bold">Abonné</h6>
        <p>
          Désigne l’entité juridique (société, association, personne morale, personne physique) titulaire de
          l’abonnement au service proposé par ETX Daily Up.
        </p>
        <h6 class="font-weight-bold">ETX Daily Up</h6>
        <p>
          ETX Daily Up est une agence d’information qui a pour objet la fourniture à ses abonnés, tant en France qu’à
          l’étranger, de contenus dans le domaine de l’information sur les loisirs, qu’il s’agisse de textes, de photos
          ou de vidéos. Elle est membre adhérent de la Fédération Française des Agences de Presse (FFAP). Elle est
          immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 431284835, dont le siège social
          est situé 10/12 rue du Maurice Grimaud | 75018 Paris.
        </p>
        <h6 class="font-weight-bold">Utilisateur</h6>
        <p>
          Désigne toute personne physique rattachée juridiquement par un lien de subordination à l’Abonné et
          expressément autorisée et désignée par ce dernier aux fins de bénéficier du Service et ce, dans le cadre de
          l’abonnement souscrit par l’Abonné. L’Utilisateur dispose, dans cette perspective, d’un compte individuel lui
          permettant d’accéder au service.
        </p>
        <h6 class="font-weight-bold">Compte</h6>
        <p>
          Désigne l'espace mis à la disposition de l'Utilisateur inscrit sur la plateforme ETX Daily Up, lui permettant
          d'accéder, après s'être identifié par ses codes d'accès, au service souscrit. Des paramètres propres à chaque
          Utilisateur comme le choix de la langue de l’interface et l’ajout de ses comptes et réseaux peuvent être
          personnalisés.
        </p>
        <h6 class="font-weight-bold">CGU</h6>
        <p>Désigne les présentes conditions générales d’utilisation.</p>
        <h6 class="font-weight-bold">Service</h6>
        <p></p>
        <h6 class="font-weight-bold">ETX daily UP</h6>
        <p>Désigne la plateforme en ligne mise à la disposition des abonnés au Service.</p>

        <h6 class="font-weight-bold">AFP & ETX Daily Up Content</h6>
        <p>Désigne les contenus produits par ETX Daily Up et l’AFP.</p>

        <h6 class="font-weight-bold">Contenu</h6>
        <p>
          Ensemble des informations et publications accessibles via la plateforme comprenant des articles, dépêches,
          images, vidéos (embed ou téléchargeables), sons, liens hypertextes, données chiffrées, infographies mis à
          disposition de l’Abonné et/ou de l’Utilisateur.
        </p>

        <h6 class="font-weight-bold">ETX Daily UP Pro</h6>
        <p>
          Offre d’accès payant à la Plateforme, réservée aux Abonnés ayant souscrit un abonnement. Cette offre nécessite
          une inscription avec identifiants personnels pour accéder à l’ensemble des Contenus.
        </p>

        <h4>ARTICLE 2 : description du service et obligations réciproques</h4>
        <h5>2.1. Description du Service</h5>

        <p>
          ETX Daily UP, délivre un service complet d'informations couvrant l’actualité destination des professionnels.
          Les informations et contenus sont accessibles aux utilisateurs à l’aide de leurs identifiants personnels.
        </p>

        <p>
          La plateforme en ligne ETX Studio Plateforme est mise à disposition des utilisateurs, disponible 24/24, à
          l’adresse URL sécurisée (Uniform Resource Locator) suivante : https://
        </p>

        <p>Les contenus disponibles sur la plateforme proviennent soit :</p>
        <ul>
          <li>De journalistes de la Rédaction de ETX Daily up</li>
          <li>De l’AFP</li>
          <li>De différentes sources d’informations externes</li>
          <li>De banques d’images libres de droits (Shutterstock)</li>
        </ul>

        <p>
          Le Service proposé par ETX Daily Up comprend différentes prestations, selon le type d’abonnement souscrit
          (gratuit ou payant) :
        </p>
        <ul>
          <li>Abonnement aux dépêches AFP/ETX Daily Up mises à disposition via la plateforme ou via flux RSS</li>
          <li>Consultation des contenus ETX Daily Up</li>
          <li>Consultation et publication sur le(s) site(s) et/ou réseaux sociaux de l’Abonné</li>
          <li>
            Contenu personnalisé à la demande (réécriture optimisée pour le SEO, production pure de contenu, community
            management)
          </li>
        </ul>

        <h5>2.1.1 Droit d’accès au Service</h5>

        <p></p>

        <h5>2.2 Sites de destination de la fonction « Publication »</h5>

        <p>
          L’Utilisateur s’engage en outre à ne pas intégrer les vidéos, images ou articles sur des sites ou services
          appartenant aux catégories suivantes :
        </p>
        <ul>
          <li>
            sites prônant la discrimination, la haine ou la violence en raison de la race, de l’ethnie, de l’origine, de
            la religion, du handicap, de l’orientation sexuelle, des opinions politiques, etc.,
          </li>
          <li>
            sites portant atteinte aux droits des personnes et des biens, notamment aux droits de propriété
            intellectuelle de tiers,
          </li>
          <li>
            sites faisant la promotion de produits stupéfiants, de l’industrie de l’alcool ciblant des mineurs et/ou ne
            respectant pas la réglementation en vigueur en matière de publicité, du tabac ou des armes,
          </li>
          <li>sites incitant aux crimes et/ou de délits,></li>
          <li>sites contrevenant à l’ordre public et/ou aux bonnes mœurs,</li>
          <li>sites à caractère pornographique et pédophile.</li>
        </ul>

        <h5>2.3 Restriction d’utilisation concernant certains contenus</h5>

        <p>
          L’Abonné et l’Utilisateur se conforment aux conditions et aux restrictions d’utilisation accompagnant le
          contenu, mentionnées dans les présentes CGU et/ou tout bon de commande ou conditions particulières.
        </p>

        <p>L’Abonné et l’Utilisateur s’engagent :</p>
        <ul>
          <li>
            A maintenir le crédit photo ETX Daily Up et ceux de ses partenaires ainsi que le copyright, tels qu’ils sont
            indiqués par ETX Daily Up sur chaque photo,
          </li>
          <li>
            A indiquer la mention « source ETX Daily Up » lors de la publication de textes sur les sites web et le cas
            échéant sur d’autres supports de l’Abonné,
          </li>
          <li>
            A indiquer, sur chaque page des sites ou autres supports de l’Abonné où sont publiés les Contenus issus de
            la plateforme ETX Daily Up, la source : « © nom de l’auteur/propriétaire des droits »,
          </li>
          <li>A publier les Contenus uniquement sur Internet lorsque la mention « WEB only » est indiquée,</li>
          <li>
            A veiller à ce que le contenu partagé sur les réseaux sociaux de l’Abonné (sous forme de titres pour les
            textes et/ou de vignettes cliquables pour les photos) renvoie vers le site web de l’Abonné dans le respect
            des conditions énoncées ci-dessus,
          </li>
          <li>
            A respecter les restrictions liées à la couverture de certains événements, notamment les événements
            culturels (expositions).
          </li>
        </ul>

        <h5>2.4. Obligations de l’Abonné et de l’utilisateur</h5>

        <p>
          La fourniture du Service est faite au profit exclusif de l’Abonné. La rediffusion partielle ou totale du
          Contenu à titre gratuit ou onéreux sur quelque support et par quelque moyen que ce soit à tout tiers est
          strictement interdite, sauf autorisation expresse préalable par voie écrite de ETX Daily Up.
        </p>

        <p>
          L’Abonné s’engage à n’exploiter le Service que dans le cadre de l’animation de ses sites, réseaux sociaux et
          publications papier, sur support web ou imprimables (print).
        </p>

        <p>
          L’Abonné et l’Utilisateur s’engagent à respecter toutes les restrictions d’utilisation, telles qu’indiquées
          par ETX Daily Up sur les Contenus et à l’article 2.3.3 des présentes CGU.
        </p>

        <p>
          L’Abonné s’engage à faire respecter les restrictions d’utilisation afférentes au Contenu aux utilisateurs
          finaux et/ou abonnés de ses sites et/ou le cas échéant de ses newsletters, flux RSS, réseaux sociaux et tout
          autre support. Les utilisateurs finaux ne sont autorisés à utiliser le Contenu issu de la plateforme ETX Daily
          Up qu’à des fins strictement privées et non commerciales.
        </p>

        <p>
          L’Abonné et l’Utilisateur s'engagent à ne pas reproduire ni communiquer sur leurs sites, réseaux sociaux et
          publications sur support papier, des éléments protégés au titre du droit d'auteur, déposés à titre de marque
          ou plus généralement protégés par tout autre droit de propriété intellectuelle détenu par des tiers sans le
          consentement préalable du ou des détenteurs de tels droits de propriété.
        </p>

        <p>
          Selon l'article L. 122-4 du Code de la propriété intellectuelle, toute représentation ou reproduction
          intégrale ou partielle faite sans le consentement de l'auteur ou de ses ayants droit ou ayants cause est
          illicite. Il en est de même pour la traduction, l'adaptation ou la transformation, l'arrangement ou la
          reproduction par un art ou un procédé quelconque. Toute autre utilisation est donc soumise à l’accord
          préalable écrit de ETX Daily Up.
        </p>

        <p>
          Selon l'article L335-2 du Code de la propriété intellectuelle, toute édition d'écrits, de composition
          musicale, de dessin, de peinture ou de toute autre production, imprimée ou gravée en entier ou en partie, au
          mépris des lois et règlements relatifs à la propriété des auteurs, est une contrefaçon et toute contrefaçon
          est un délit. La contrefaçon en France d'ouvrages publiés en France ou à l'étranger est punie de trois ans
          d'emprisonnement et de 300 000 euros d’amende.
        </p>

        <p>
          L’Abonné et l’Utilisateur s’engagent à ne pas utiliser le Service de ETX Daily Up aux fins d’exploitation et
          de commercialisation d’un produit d’information concurrençant les Services de ETX Daily Up.
        </p>

        <p>
          L’Abonné s’engage à indemniser ETX Daily Up et ses partenaires de tout frais (y compris les frais juridiques
          et de procédure), coûts, pertes, débours, dommages, condamnation, résultant de toute réclamation, recours,
          plainte, action ou condamnation, dès lors que ceux-ci auraient pour cause, directement ou indirectement, la
          violation par l’Abonné des présentes conditions générales d’utilisation, notamment en ce concerne
          l’utilisation non autorisée du Contenu, ou la violation des droits d’un tiers, des lois ou règlements en
          vigueur.
        </p>

        <h4>ARTICLE 3 : ACCES AU SITE ET AU SERVICE</h4>

        <p>
          Le site et le service sont accessibles 24h/24, 7/7j, sauf interruption, programmée ou non, pour les besoins de
          leur maintenance ou en cas de force majeure. Etant soumis à une obligation de moyens, ETX Daily Up ne saurait
          être tenue responsable de tout dommage, quelle qu’en soit la nature, résultant d’une indisponibilité du site
          et/ou du service.
        </p>

        <p>
          Par ailleurs, en accédant au site et au Service, l’Abonné et/ou l’Utilisateur disposent des compétences, des
          matériels et des logiciels requis pour l’utilisation d’Internet et reconnaissent que les caractéristiques de
          l’Internet et de son usage ne permettent pas de garantir la sécurité, la disponibilité et l’intégrité de
          données durant leur transit sur Internet.
        </p>

        <h4>ARTICLE 4 : RESPONSABILITÉ ET LIMITATION DE RESPONSABILITÉ</h4>

        <p>
          ETX Daily Up ne saurait être tenue pour responsable de la façon dont les Contenus pourraient être présentés,
          commentés, adaptés ou résumés par l’Abonné, qui engage sa responsabilité pleine et entière envers les
          destinataires du Contenu.
        </p>

        <p>
          ETX Daily Up décline toute responsabilité concernant les décisions de toute nature qui pourraient être prises
          sur la base du Contenu mis à disposition de l’Abonné.
        </p>

        <p>
          La responsabilité de ETX Daily Up ne saurait être engagée, ni le prix de l’abonnement diminué, au titre des
          dysfonctionnements du réseau Internet, quelles qu’en soient la nature et la durée. Plus généralement la
          responsabilité de ETX Daily Up ne pourra être engagée pour un dommage résultant de l’utilisation du réseau
          Internet tel que la perte de données, l’intrusion informatique, les virus, la rupture du service, ou d’autres
          problèmes involontaires.
        </p>

        <p>
          ETX Daily Up propose des liens hypertextes vers des sites web édités et/ou gérés par des tiers. Dans la mesure
          où aucun contrôle n’est exercé sur ces contenus externes, ETX Daily Up ne pourra être tenue responsable quant
          à la nature de leurs contenus.
        </p>

        <p>
          La responsabilité de ETX Daily Up ne pourra être engagée en cas de force majeure ou de faits indépendants de
          sa volonté.
        </p>

        <p>
          ETX Daily Up n'a qu'une obligation de moyens concernant l'accès et l'utilisation du Service. Par conséquent,
          ETX Daily Up ne sera pas tenue d’indemniser les dommages immatériels ou indirects tels que le préjudice
          d’image, le préjudice commercial, le préjudice d’exploitation, la perte de bénéfices et le manque à gagner
          subis par l’Abonné.
        </p>

        <p>
          L’Abonné et l’Utilisateur sont les seuls responsables des actions effectuées au moyen de leurs identifiants et
          mots de passe. Dans ce contexte l’Abonné garantit ETX Daily Up contre toute action, recours ou réclamation en
          rapport avec tout dommage subi du fait de l’utilisation par un tiers de son mot de passe.
        </p>

        <p>
          De manière générale, ETX Daily Up ne pourra être tenue responsable du dommage direct, personnel et certain
          subi par l’Abonné et/ou l’Utilisateur et pour lequel il aura été démontré que les agissements de ETX Daily Up
          ne sont pas à l’origine de ce préjudice.
        </p>

        <h4>ARTICLE 5 : PROPRIETE INTELLECTUELLE</h4>

        <p>
          ETX Daily Up est titulaire de tous les droits de propriété intellectuelle relatifs aux Contenus accessibles
          sur sa plateforme, notamment les textes, images, sons, photographies, vidéos et tout autre matériel ou
          logiciel lesquels sont protégés par des droits détenus par elle ou ses partenaires.
        </p>

        <p>
          ETX Daily Up ne transfère aucun droit de propriété sur le contenu. Elle concède une licence d’utilisation
          non-exclusive et non-transférable du contenu permettant de reproduire et de représenter le Contenu pour un
          usage d’information. Toute autre utilisation du Contenu est interdite sans l’accord préalable écrit de ETX
          Daily Up.
        </p>

        <p>
          L’utilisation des Contenus ETX Daily Up à d’autres fins que prévues aux présentes CGU ou dans le contrat de
          l’Abonné, est strictement interdite.
        </p>

        <h4>ARTICLE 6 : PROTECTION DES DONNÉES À CARACTERE PERSONNEL</h4>

        <p>
          ETX Daily Up collecte des données de ses clients Abonné et Utilisateur dans le but d’établir une relation
          commerciale lors de la souscription au service ETX Daily Up. Ces données personnelles sont réservées à l’usage
          exclusif de ETX Daily Up, sauf accord donné par l’Utilisateur lors de son inscription.
        </p>

        <p>
          Conformément à la loi n°78-17 du 6 janvier 1978 « informatique et liberté », l’Utilisateur dispose d'un droit
          d'accès, de rectification, de modification et de suppression concernant les données qui le concernent qui
          pourront être collectées et traitées dans le cadre de l’utilisation service, notamment à des fins de gestion
          de la clientèle et de prospection commerciale.
        </p>
        <p>Pour faire valoir ces droits adresser un courrier à l’adresse suivante :</p>

        <address>
          ETX Daily Up 10/12 rue Maurice Grimaud | 75018 Paris Ou envoyer un courrier électronique à :
          dailyup@etxstudio.com
        </address>

        <h4>ARTICLE 7 : FORCE MAJEURE</h4>

        <p>
          ETX Daily Up se réserve la faculté de suspendre ou de résilier tout ou partie de ses prestations, de plein
          droit et sans formalité, en cas d’événement de force majeure ou de cas fortuit, susceptible d’empêcher
          l’exécution du contrat.
        </p>

        <p>
          Les Parties conviennent que constituent notamment des cas de force majeure : les modifications législatives et
          réglementaires, les incendies, les tempêtes, les inondations, les grèves, les surtensions et chocs
          électriques, les pannes des systèmes de refroidissement des matériels informatiques, les blocages et
          ralentissement des réseaux informatiques et de télécommunications.
        </p>

        <h4>ARTICLE 8 : GESTION DES COOKIES</h4>

        <p>
          ETX Daily Up utilise des cookies c’est-à-dire des messages stockés par le navigateur Internet sous forme de
          fichiers texte qui renvoient les informations aux serveurs Internet chaque fois que le navigateur accède à une
          page du serveur.
        </p>

        <p>
          Ces cookies ne vous identifient pas personnellement mais conservent l’historique des activités et préférences
          de l’Utilisateur au travers du navigateur Internet. L’Utilisateur peut choisir de les conserver ou de les
          supprimer.
        </p>

        <p>
          Certains cookies sont nécessaires au bon fonctionnement du site de ETX Daily Up. Les cookies permettent de
          s’assurer que les informations fournies par l’Utilisateur sont mémorisées tout au long de la session, lors de
          la saisie du login par exemple.
        </p>

        domaine identifiant description durée avant expiration .ETX Daily Up.com _ga identifie l'utilisateur via un
        identifiant unique 2ans .ETX Daily Up.com _gat sert à limiter la fréquence des requêtes (to throttle the request
        rate) 10 minutes .ETX Daily Up.com _utma identifie l'utilisateur via un identifiant unique 2ans .ETX Daily
        Up.com _utmb identifie la session actuelle 30 minutes .ETX Daily Up.com _utmc même info que _utmb, laissé pour
        des question d'interopérabilité jusqu'à fermeture session .ETX Daily Up.com _utmz utilisé par Google Analytic
        pour stocker des informations liées à des campagnes 6 mois .ETX Daily Up.com _laravel_session_api_prod Permet
        d'authentifier les appels envoyés au serveur de ETX Daily Up 6 heures .ETX Daily Up.com APISID Sert à stocker
        des informations sur Google ReCaptcha durée session .ETX Daily Up.com HSID Sert à stocker des informations sur
        Google ReCaptcha durée session .ETX Daily Up.com NID Sert à stocker des informations sur Google ReCaptcha durée
        session .ETX Daily Up.com SAPISID Sert à stocker des informations sur Google ReCaptcha durée session .ETX Daily
        Up.com SID Sert à stocker des informations sur Google ReCaptcha durée session .ETX Daily Up.com SSID Sert à
        stocker des informations sur Google ReCaptcha durée session .ETX Daily Up.com pusherTransportEncrypted
        Information sur la connexion Pusher jusqu'à déconnexion explicite (logout) .ETX Daily Up.com role Rôle de
        l'utilisateur jusqu'à déconnexion explicite (logout) .ETX Daily Up.com tokenName sert à différencier le type
        d'environnement jusqu'à déconnexion explicite (logout) .ETX Daily Up.com tokenProduction sert à identifier et
        connecter l'utilisateur Jusqu’à déconnexion explicite (logout) .ETX Daily Up.com tokenProduction_original sert à
        identifier et connecter l'utilisateur jusqu'à déconnexion explicite (logout)

        <p>
          ETX Daily Up offre une fonctionnalité qui permet à l’Abonné et à l’Utilisateur de partager des Contenus sur
          les réseaux sociaux. Pour avoir plus d’informations sur l’utilisation des cookies par :
        </p>
        <ul>
          <li>Facebook : https://www.facebook.com/help/360595310676682/</li>
          <li>
            Twitter :
            https://support.twitter.com/articles/20170518-utilisation-des-cookies-et-des-technologies-similaires-par-twitter
            Comment gérer les cookies
          </li>
        </ul>

        <p>
          L’Abonné et l’Utilisateur peuvent gérer les cookies directement en se rendant sur les paramètres de gestion du
          navigateur Internet. Pour bloquer les cookies, suivre les instructions suivantes:
        </p>

        <h6 style="font-weight: bold">Microsoft Internet Explorer</h6>

        <ul>
          <li>>Cliquez sur le bouton ‘Outils’, puis sur ‘Options Internet’</li>
          <li>Cliquez sur l’onglet ‘Confidentialité</li>
          <li>
            Puis sous ‘Paramètres’, déplacez le curseur vers le haut pour bloquer tous les cookies puis cliquez sur OK.
          </li>
          <li>Pour des informations détaillées vous pouvez cliquer sur l’un des liens suivants:</li>
        </ul>

        <p>
          IE 9 – http://windows.microsoft.com/fr-fr/windows7/How-to-manage-cookies-in-Internet-Explorer-9<br />
          IE 7/8 – http://windows.microsoft.com/fr-fr/windows-vista/Block-or-allow-cookies
        </p>

        <h6 style="font-weight: bold">Firefox</h6>

        <ul>
          <li>Cliquez dans ‘Outils’, puis ‘Options’</li>
          <li>Sélectionnez ‘Vie privée’</li>
          <li>Dans le panneau ‘Cookies’, décochez la case ‘Accepter les cookies’</li>
        </ul>

        <h6 style="font-weight: bold">Google Chrome</h6>

        <ul>
          <li>Cliquez sur le menu Google Chrome dans la barre d’outils du navigateur et sélectionnez ‘Paramètres’</li>
          <li>Cliquez sur ‘Afficher les paramètres avancés’</li>
          <li>Dans la section ‘Confidentialité’, cliquez sur le bouton’ Paramètres de contenu’</li>
          <li>Dans la section ‘Cookies’, cliquez sur ‘Bloquer l’enregistrement des cookies tiers’</li>
          <li>Cliquez sur le bouton ‘OK’</li>
        </ul>

        <h6 style="font-weight: bold">Opera</h6>

        <ul>
          <li>Allez sur ‘Outils‘ puis ‘Préférences‘</li>
          <li>Cliquez sur l’onglet ‘Avancé’ puis allez sur la rubrique ‘Cookies’</li>
          <li>Cliquez sur ‘Jamais accepter les cookies’</li>
          <li>Cliquez sur ‘OK’</li>
        </ul>

        <h6 style="font-weight: bold">Safari</h6>

        <ul>
          <li>Choisissez ‘Préférences’ dans le menu Safari</li>
          <li>Sélectionnez l’icône ‘Sécurité’</li>
          <li>Sous ‘Accepter les cookies’ cliquer sur ‘Jamais’</li>
          <li>Fermer la fenêtre</li>
        </ul>

        <h4>ARTICLE 9 : droit applicable – compétence juridictionnelle</h4>

        <p>
          Les documents contractuels seront exclusivement régis par le droit français. Tout différend relatif à la
          validité, l’interprétation et l’exécution des documents contractuels sera de la compétence exclusive du
          Tribunal de commerce de Paris, nonobstant pluralité de parties ou appel en garantie.
        </p>

        <h4>ARTICLE 10 : divers</h4>

        <p>
          Si une ou plusieurs stipulations des présentes Conditions Générales d’Utilisation sont tenues pour non valides
          ou déclarées comme telles en application d’une loi, d’un règlement ou à la suite d’une décision définitive
          émanant d’une juridiction compétente, les autres stipulations garderont toute leur force et leur portée.
        </p>

        <p>
          Les dispositions concernant les prix, modalité de paiement, durée de souscription au service, résiliation …
          sont indiquées sur les autres documents contractuels (bon de commande, contrat …).
        </p>

        <H3>CONDITIONS GENERALES DE VENTE ETX DAILY UP</H3>
        <p>Version du 30 novembre 2020</p>

        <H4>OBJET</H4>

        <p>
          Les présentes conditions générales de vente (CGV) sont conclues, d’une part, entre ETX Daily Up et, d’autre
          part, l’Abonné et le cas échéant l’Utilisateur utilisant le service ETX Daily Up. Elles ont pour objet de
          définir les termes et les modalités de fourniture du service ETX Daily Up.
        </p>

        <p>
          Les conditions générales de vente sont accessibles en ligne à l’adresse https://editorializer.ETX Daily
          Up.com/#/terms-of-service. L’Abonné et l’Utilisateur déclarent les avoir lues et acceptées. Leur souscription
          et l’accès au service emportent de plein droit acceptation expresse et sans réserve des présentes conditions
          générales de vente.
        </p>

        <p>
          Ces conditions générales de vente prévaudront sur toutes autres conditions générales ou particulières non
          expressément agréées par ETX Daily Up.
        </p>

        <p>
          ETX Daily Up se réserve le droit de modifier à tout moment les conditions générales de vente. A ce titre,
          l’Abonné et l’Utilisateur reconnaissent et acceptent que leur utilisation et tout simplement leur accès au
          Service après la date des modifications emportera leur acceptation des CGV telles que modifiées.
        </p>

        <p>
          Dans la mesure où l’Utilisateur accède au service de ETX Daily Up, il garantit à ETX Daily Up qu’il détient
          les autorisations et les pouvoirs pour accepter les CGV au nom de l’Abonné, personne morale pour laquelle il
          utilise ou accède au service en créant un Compte.
        </p>

        <h4>ARTICLE 1 : définitions</h4>

        <h6 style="font-weight: bold">Abonné</h6>

        <p>
          Désigne l’entité juridique (société, association, personne morale, personne physique) titulaire de
          l’abonnement au service proposé par ETX Daily Up.
        </p>

        <h6 style="font-weight: bold">ETX Daily Up</h6>

        <p>
          ETX Daily Up est une agence d’information qui a pour objet la fourniture à ses abonnés, tant en France qu’à
          l’étranger, de contenus dans le domaine de l’information sur les loisirs, qu’il s’agisse de textes, de photos
          ou de vidéos. Elle est membre adhérent de la Fédération Française des Agences de Presse (FFAP). Elle est
          immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 431284835, dont le siège social
          est situé 10/12 rue Maurice Grimaud | 75018 Paris.
        </p>

        <h6 style="font-weight: bold">Utilisateur</h6>
        <p>
          Désigne toute personne physique rattachée juridiquement par un lien de subordination à l’Abonné et
          expressément autorisée et désignée par ce dernier aux fins de bénéficier du Service et ce, dans le cadre de
          l’abonnement souscrit par l’Abonné. L’Utilisateur dispose, dans cette perspective, d’un compte individuel lui
          permettant d’accéder au service.
        </p>

        <h6 style="font-weight: bold">Compte</h6>
        <p>
          Désigne l'espace mis à la disposition de l'Utilisateur inscrit sur la plateforme ETX Daily Up, lui permettant
          d'accéder, après s'être identifié par ses codes d'accès, au service souscrit. Des paramètres propres à chaque
          Utilisateur comme le choix de la langue de l’interface et l’ajout de ses comptes et réseaux peuvent être
          personnalisés.
        </p>

        <h6 style="font-weight: bold">Parties</h6>
        <p>
          Désigne, lorsque ce terme est employé au pluriel, l’Abonné et ETX Daily Up ; et lorsque ce terme est employé
          au singulier, l’une quelconque de ces parties.
        </p>

        <h6 style="font-weight: bold">CGV</h6>
        <p>Désigne les présentes conditions générales de vente.</p>

        <h6 style="font-weight: bold">Service</h6>
        <p>
          La plateforme ETX Daily Up comprenant My Radar, My AFP & ETX Daily Up Content, Relax Services, My Reader, tel
          que décrit ci-dessous et à l’article 2.
        </p>

        <h6 style="font-weight: bold">ETX daily UP</h6>
        <p>Désigne la plateforme en ligne mise à la disposition des abonnés au Service.</p>

        <h6 style="font-weight: bold">ETX Daily Up Pro</h6>
        <p>
          Offre d’accès payant à la Plateforme, réservée aux Abonnés ayant souscrit un abonnement. Cette offre nécessite
          une inscription avec identifiants personnels pour accéder à l’ensemble des Contenus.
        </p>

        <h4>ARTICLE 2 : QUALITES DES PARTIES</h4>

        <p>
          L’Abonné déclare accepter les présentes CGV en qualité de professionnel. En cas de litige, l’Abonné ne pourra
          invoquer à l’encontre de ETX Daily Up l’application de règles issues du droit de la consommation.
        </p>

        <h4>ARTICLE 3 : description du service et obligations réciproques</h4>

        <h5>3.1. Description du Service</h5>

        <p>
          ETX Daily Up, délivre un service complet d'informations couvrant l’actualité du secteur des loisirs à
          destination des professionnels. Les informations et contenus sont accessibles aux utilisateurs à l’aide de
          leurs identifiants personnels.
        </p>

        <p>
          La plateforme en ligne ETX daily UP est mise à disposition des utilisateurs, disponible 24/24, à l’adresse URL
          sécurisée (Uniform Resource Locator) suivante : https://editorializer.ETX Daily Up.com.
        </p>

        <p>Les contenus disponibles sur la plateforme proviennent soit :</p>
        <ul>
          <li>De journalistes de la Rédaction de ETX Daily Up</li>
          <li>De l’AFP</li>
          <li>De différentes sources d’informations externes</li>
          <li>De banques d’images libres de droits (Shutterstock)</li>
        </ul>

        <p>
          Le Service proposé par ETX Daily Up comprend différentes prestations, selon le type d’abonnement souscrit
          (gratuit ou payant) :
        </p>
        <ul>
          <li>Abonnement aux dépêches AFP ETX Daily Up mises à disposition via la plateforme ou via flux RSS</li>
          <li>Consultation des contenus</li>
        </ul>

        <p>
          L’Utilisateur s’engage en outre à ne pas intégrer les vidéos, images ou articles sur des sites ou services
          appartenant aux catégories suivantes :
        </p>
        <ul>
          <li>
            sites prônant la discrimination, la haine ou la violence en raison de la race, de l’ethnie, de l’origine, de
            la religion, du handicap, de l’orientation sexuelle, des opinions politiques, etc.,
          </li>
          <li>
            sites portant atteinte aux droits des personnes et des biens, notamment aux droits de propriété
            intellectuelle de tiers,
          </li>
          <li>
            sites faisant la promotion de produits stupéfiants, de l’industrie de l’alcool ciblant des mineurs et/ou ne
            respectant pas la réglementation en vigueur en matière de publicité, du tabac ou des armes,
          </li>
          <li>sites incitant aux crimes et/ou de délits,</li>
          <li>sites contrevenant à l’ordre public et/ou aux bonnes mœurs,</li>
          <li>sites à caractère pornographique et pédophile.</li>
        </ul>

        <h5>3.2 Restriction d’utilisation concernant certains contenus</h5>

        <p>
          L’Abonné et l’Utilisateur se conforment aux conditions et aux restrictions d’utilisation accompagnant le
          contenu, mentionnées dans les présentes CGV et/ou tout bon de commande ou conditions particulières.
        </p>

        <p>L’Abonné et l’Utilisateur s’engagent :</p>
        <ul>
          <li>
            A maintenir le crédit photo ETX Daily Up et de ceux de ses partenaires ainsi que le copyright, tels qu’ils
            sont indiqués par ETX Daily Up sur chaque photo,
          </li>
          <li>
            A indiquer la mention « source ETX Daily Up » lors de la publication de textes sur les sites web et le cas
            échéant sur d’autres supports de l’Abonné,
          </li>
          <li>
            A indiquer, sur chaque page des sites ou autres supports de l’Abonné où sont publiés les Contenus issus de
            la plateforme ETX Daily Up la source : « © nom de l’auteur/propriétaire des droits »,
          </li>
          <li>A publier les Contenus uniquement sur Internet lorsque la mention « WEB only » est indiquée,</li>
          <li>
            A veiller à ce que le contenu partagé sur les réseaux sociaux de l’Abonné (sous forme de titres pour les
            textes et/ou de vignettes cliquables pour les photos) renvoie vers le site web de l’Abonné dans le respect
            des conditions énoncées ci-dessus,>
          </li>
          <li>
            A respecter les restrictions liées à la couverture de certains événements, notamment les événements
            culturels (expositions).
          </li>
        </ul>

        <h5>3.3. Obligations ETX Daily Up</h5>

        <p>
          ETX Daily Up s’engage, dans le cadre de la fourniture du Service, à mettre à disposition de l’Abonné, du
          Contenu accessible 24H/24 et 7J/J sur la plateforme ETX daily UP. Le contenu peut varier en fonction de
          l’actualité, des choix rédactionnels de ETX Daily Up et du type d’abonnement.
        </p>

        <h5>3.4. Obligations de l’Abonné et de l’utilisateur</h5>

        <p>
          La fourniture du Service est faite au profit exclusif de l’Abonné. La rediffusion partielle ou totale du
          contenu à titre gratuit ou onéreux sur quelque support et par quelque moyen que ce soit à tout tiers est
          strictement interdite, sauf autorisation expresse préalable par voie écrite de ETX Daily Up.
        </p>

        <p>
          L’Abonné s’engage à n’exploiter le Service que dans le cadre de l’animation de ses sites réseau sociaux et
          publications papier, sur support web ou imprimables (print).
        </p>

        <p>
          L’Abonné et l’Utilisateur s’engagent à respecter toutes les restrictions d’utilisation, telles qu’indiquées
          par ETX Daily Up sur les Contenus et à l’article 3.3.3 des présentes CGV.
        </p>

        <p>
          L’Abonné s’engage à faire respecter les restrictions d’utilisation afférentes au Contenu aux utilisateurs
          finaux et/ou abonnés des ses sites et/ou le cas échéant de ses newsletters, flux RSS, réseaux sociaux et tout
          autre support. Les utilisateurs finaux ne sont autorisés à utiliser le Contenu issu de la plateforme ETX Daily
          Up qu’à des fins strictement privées et non commerciales.
        </p>

        <p>
          L’Abonné et l’Utilisateur s'engagent à ne pas reproduire ni communiquer sur leurs sites, réseaux sociaux et
          publications sur support papier, des éléments protégés au titre du droit d'auteur, déposés à titre de marque
          ou plus généralement protégés par tout autre droit de propriété intellectuelle détenu par des tiers sans le
          consentement préalable du ou des détenteurs de tels droits de propriété.
        </p>

        <p>
          Selon l'article L. 122-4 du Code de la propriété intellectuelle, toute représentation ou reproduction
          intégrale ou partielle faite sans le consentement de l'auteur ou de ses ayants droit ou ayants cause est
          illicite. Il en est de même pour la traduction, l'adaptation ou la transformation, l'arrangement ou la
          reproduction par un art ou un procédé quelconque. Toute autre utilisation est donc soumise à l’accord
          préalable écrit de ETX Daily Up.
        </p>

        <p>
          Selon l'article L335-2 du Code de la propriété intellectuelle, toute édition d'écrits, de composition
          musicale, de dessin, de peinture ou de toute autre production, imprimée ou gravée en entier ou en partie, au
          mépris des lois et règlements relatifs à la propriété des auteurs, est une contrefaçon et toute contrefaçon
          est un délit. La contrefaçon en France d'ouvrages publiés en France ou à l'étranger est punie de trois ans
          d'emprisonnement et de 300 000 euros d’amende.
        </p>

        <p>
          L’abonné et l’Utilisateur s’engagent à ne pas utiliser le Service de ETX Daily Up aux fins d’exploitation et
          de commercialisation d’un produit d’information concurrençant les Services de ETX Daily Up.
        </p>

        <p>
          L’Abonné s’engage à indemniser ETX Daily Up et ses partenaires de tout frais (y compris les frais juridiques
          et de procédure), coûts, pertes, débours, dommages, condamnation, résultant de toute réclamation, recours,
          plainte, action ou condamnation, dès lors que ceux-ci auraient pour cause, directement ou indirectement, la
          violation par l’Abonné des présentes conditions générales de vente, notamment en ce concerne l’utilisation non
          autorisée du Contenu, ou la violation des droits d’un tiers, des lois ou règlements en vigueur.
        </p>

        <h4>ARTICLE 4 : ACCES AU SITE ET AU SERVICE</h4>

        <p>
          Le site et le Service sont accessibles 24h/24, 7/7j, sauf interruption, programmée ou non, pour les besoins de
          leur maintenance ou en cas de force majeure. Etant soumis à une obligation de moyens, ETX Daily Up ne saurait
          être tenue responsable de tout dommage, quelle qu’en soit la nature, résultant d’une indisponibilité du site
          et/ou du Service.
        </p>

        <p>
          Par ailleurs, en accédant au site et au Service, l’Abonné et/ou l’Utilisateur disposent des compétences, des
          matériels et des logiciels requis pour l’utilisation d’Internet et reconnaissent que les caractéristiques de
          l’Internet et de son usage ne permettent pas de garantir la sécurité, la disponibilité et l’intégrité de
          données durant leur transit sur Internet.
        </p>

        <h4>ARTICLE 5 : RESPONSABILITÉ ET LIMITATION DE RESPONSABILITÉ</h4>

        <p>
          ETX Daily Up ne saurait être tenue pour responsable de la façon dont les Contenus pourraient être présentés,
          commentés, adaptés ou résumés par l’Abonné, qui engage sa responsabilité pleine et entière envers les
          destinataires du Contenu.
        </p>

        <p>
          ETX Daily Up décline toute responsabilité concernant les décisions de toute nature qui pourraient être prises
          sur la base du Contenu mis à disposition de l’Abonné.
        </p>

        <p>
          La responsabilité de ETX Daily Up ne saurait être engagée, ni le prix de l’abonnement diminué, au titre des
          dysfonctionnements du réseau Internet, quelles qu’en soient la nature et la durée. Plus généralement la
          responsabilité de ETX Daily Up ne pourra être engagée pour un dommage résultant de l’utilisation du réseau
          Internet tel que la perte de données, l’intrusion informatique, les virus, la rupture du Service, ou d’autres
          problèmes involontaires.
        </p>

        <p>
          ETX Daily Up propose des liens hypertextes vers des sites web édités et/ou gérés par des tiers. Dans la mesure
          où aucun contrôle n’est exercé sur ces contenus externes, ETX Daily Up ne pourra être tenue responsable quant
          à la nature de leurs contenus.
        </p>

        <p>
          La responsabilité de ETX Daily Up ne pourra être engagée en cas de force majeure ou de faits indépendants de
          sa volonté.
        </p>

        <p>
          ETX Daily Up n'a qu'une obligation de moyens concernant l'accès et l'utilisation du Service. Par conséquent,
          ETX Daily Up ne sera pas tenue d’indemniser les dommages immatériels ou indirects tels que le préjudice
          d’image, le préjudice commercial, le préjudice d’exploitation, la perte de bénéfices et le manque à gagner
          subis par l’Abonné.
        </p>

        <p>
          L’Abonné et l’Utilisateur sont les seuls responsables des actions effectuées au moyen de leurs identifiants et
          mots de passe. Dans ce contexte l’Abonné garantit ETX Daily Up contre toute action, recours ou réclamation en
          rapport avec tout dommage subi du fait de l’utilisation par un tiers de son mot de passe.
        </p>

        <p>
          De manière générale, ETX Daily Up ne pourra être tenue responsable du dommage direct, personnel et certain
          subi par l’Abonné et/ou l’Utilisateur et pour lequel il aura été démontré que les agissements de ETX Daily Up
          ne sont pas à l’origine de ce préjudice.
        </p>

        <h4>ARTICLE 6 : PROPRIETE INTELLECTUELLE</h4>

        <p>
          ETX Daily Up est titulaire de tous les droits de propriété intellectuelle relatifs aux Contenus accessible sur
          sa plateforme, notamment les textes, images, sons, photographies, vidéos et tout autre matériel ou logiciel
          lesquels sont protégés par des droits détenus par elle ou ses partenaires.
        </p>

        <p>
          ETX Daily Up ne transfère aucun droit de propriété sur le contenu. Elle concède une licence d’utilisation
          non-exclusive et non-transférable du contenu permettant de reproduire et de représenter le Contenu pour un
          usage d’information. Toute autre utilisation du Contenu est interdite sans l’accord préalable écrit de ETX
          Daily Up.
        </p>

        <p>
          L’utilisation des Contenus ETX Daily Up à d’autres fins que prévues aux présentes CGV ou dans le contrat de
          l’Abonné, est strictement interdite.
        </p>

        <h4>ARTICLE 7 : FORCE MAJEURE</h4>

        <p>
          ETX Daily Up se réserve la faculté de suspendre ou de résilier tout ou partie de ses prestations, de plein
          droit et sans formalité, en cas d’événement de force majeure ou de cas fortuit, susceptible d’empêcher
          l’exécution du contrat.
        </p>

        <p>
          Les Parties conviennent que constituent notamment des cas de force majeure : les modifications législatives et
          réglementaires, les incendies, les tempêtes, les inondations, les grèves, les surtensions et chocs
          électriques, les pannes des systèmes de refroidissement des matériels informatiques, les blocages et
          ralentissement des réseaux informatiques et de télécommunications.
        </p>

        <h4>ARTICLE 8 : TARIFS - PAIEMENTS - INDEMNITES DE RETARD</h4>

        <p>
          L’abonnement au Service est consenti moyennant le paiement par l’Abonné du tarif annuel forfaitaire mentionné
          sur le bon de commande signé par l’Abonné.
        </p>

        <p>
          Ce tarif annuel forfaitaire est fonction du nombre d’utilisateurs autorisés défini par l’Abonné aux Services
          de la Plateforme ETX daily UP ou du nombre de pages vues du site Web de l’Abonné à la Plateforme ETX daily UP
          ou du nombre d’exemplaires diffusés s’il s’agit de presse papier.
        </p>

        <p>
          L’Abonné est informé que le tarif annuel forfaitaire mentionné sur le bon de commande (ou contrat) ne comprend
          pas :
        </p>
        <ul>
          <li>
            les coûts d’accès à Internet, ni les coûts téléphoniques, qui demeurent entièrement à la charge de l’Abonné
            ;
          </li>
          <li>
            les coûts liés à la souscription d’Utilisateurs supplémentaires, étant précisé que la demande d’accès
            supplémentaires fera l’objet d’un nouveau bon de commande ;
          </li>
          <li>les coûts de prestations Order on demand, qui feront l’objet d’une facturation complémentaire.</li>
        </ul>

        <p>
          Sauf autres conditions de paiement stipulées sur le bon de commande, les factures doivent être réglées par
          l’Abonné au plus tard dans les trente (30) jours à compter de la date de facture, par virement bancaire.
        </p>

        <p>Aucun rabais, remise ou escompte ne sera accordé en cas de paiement anticipé.</p>

        <p>
          Les prix indiqués s’entendent Hors Taxes (H.T.) et ce quel que soit le territoire dans lequel réside l’Abonné
          ou à son siège social. Le cas échéant, le taux de TVA sera indiqué en sus, étant précisé que c’est la TVA au
          jour de la souscription au Service ou de son renouvellement qui sera applicable. Tout changement du taux
          pourra être répercuté sur le prix du Service.
        </p>

        <p>
          ETX Daily Up se réserve le droit de modifier ses prix à tout moment, étant toutefois entendu que le prix
          indiqué au jour de la souscription au Service ou de son renouvellement sera le seul applicable à l’Abonné.
        </p>

        <p>
          En cas de retard dans le paiement de sommes dues, l’Abonné sera redevable, de pénalités calculées par
          l'application à l'intégralité des sommes dues, d'un taux d'intérêt égal à 3 fois le taux d'intérêt légal
          français en vigueur au moment de leur liquidation. Ces intérêts courront jusqu'à leur encaissement intégral.
        </p>

        <p>
          Conformément à l’article L441-6 du Code du Commerce, tout retard de paiement entraîne de plein droit, outre
          les pénalités de retard, une obligation pour l’Abonné débiteur de payer une indemnité forfaitaire de 40 €
          (quarante euros) pour les frais de recouvrement.
        </p>

        <p>
          A défaut de paiement dans 30 jours calendaires suivant la première présentation d'une première lettre de
          relance restée infructueuse, ETX Daily Up pourra interrompre l'ensemble des Services fournis à l’Abonné
          défaillant. Le rétablissement de l’accès au Service ne sera effectif qu’après parfait paiement et pour la
          durée restant à courir.
        </p>

        <h4>ARTICLE 9 : DURÉE DU SERVICE, RECONDUCTION, RESILIATION</h4>

        <p>
          La durée du Service ainsi que les modalités de reconduction tacite du contrat sont fixées sur le bon de
          commande. L’Abonné pourra s’opposer à la reconduction tacite du Service moyennant respect du préavis fixé sur
          le bon de commande.
        </p>

        <p>
          En cas d'inexécution ou de violation par l'une des Parties de l’une quelconque de ses obligations au titre des
          présentes CGV, l'autre Partie pourra, de plein droit, résilier unilatéralement le contrat d’Abonnement par
          lettre recommandée avec accusé de réception, moyennant un préavis de 30 jours calendaires.
        </p>

        <h4>ARTICLE 10 : RETRACTATION</h4>

        <p>
          Dans la mesure où le service ETX Daily Up n’est pas destiné à des consommateurs, l’Abonné ne dispose pas de
          droit de rétractation tel que prévu par les dispositions en vigueur du droit de la consommation.
        </p>

        <h4>ARTICLE 11 : droit applicable – compétence juridictionnelle</h4>

        <p>
          Les documents contractuels seront exclusivement régis par le droit français. Tout différend relatif à la
          validité, l’interprétation et l’exécution des documents contractuels sera de la compétence exclusive du
          Tribunal de commerce de Paris, nonobstant pluralité de parties ou appel en garantie.
        </p>

        <h4>ARTICLE 12 : invalidité d’une disposition</h4>

        <p>
          Si une ou plusieurs stipulations des présentes Conditions générales de vente sont tenues pour non valides ou
          déclarées comme telles en application d’une loi, d’un règlement ou à la suite d’une décision définitive
          émanant d’une juridiction compétente, les autres stipulations garderont toute leur force et leur portée.
        </p>
      </div>
    </div>
  </div>
</div>
