import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { authReducer, AuthEffects } from '@purplefront/auth/data-access';

import { LoginContainer, RegistrationContainer, ForgotPasswordContainer, ResetPasswordComponent } from './containers';
import { NgbAlertModule, NgbDropdownModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@purplefront/shared';
import { TermsOrServicesComponent } from '../../../../shared/src/lib/components/terms-or-services/terms-or-services.component';
import { ActivateComponent } from './containers/activate/activate.component';

const routes: Routes = [
  { path: 'login', component: LoginContainer },
  { path: 'forgot-pass', component: ForgotPasswordContainer },
  { path: 'reset/password', component: ResetPasswordComponent },
  { path: 'activate', component: ActivateComponent },
  {
    path: 'login/terms-of-services/:lang',
    component: TermsOrServicesComponent
  },
  /*{ path: 'registration', component: RegistrationContainer },*/
  { path: '', redirectTo: 'login', pathMatch: 'full' }
];

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature('auth', authReducer),
    EffectsModule.forFeature([AuthEffects]),
    RouterModule.forChild(routes),
    NgbToastModule,
    SharedModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: true
    }),
    NgbDropdownModule,
    NgbAlertModule
  ],
  declarations: [
    LoginContainer,
    RegistrationContainer,
    ForgotPasswordContainer,
    ResetPasswordComponent,
    ActivateComponent
  ],
  exports: [LoginContainer, RegistrationContainer, ForgotPasswordContainer, ResetPasswordComponent, ActivateComponent]
})
export class AuthFeatureShellModule {}
