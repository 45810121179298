import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducer } from './_store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { PreferencesEffects } from './_store/effects';
import { PreferencesApi } from './_api/preferences.api';
import { PreferencesService } from './_services/preferences.services';
import { InterfaceLanguageService } from './_services';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('preferences', reducer),
    EffectsModule.forFeature([PreferencesEffects])
  ],
  providers: [PreferencesApi, PreferencesService, InterfaceLanguageService]
})
export class PreferencesDataAccessModule {}
