import { createAction, props, union } from '@ngrx/store';
import { Langs } from '@purplefront/shared';
import { Feed } from '../../../../../../catalog/data-access/src/lib/_models';

export enum ActionType {
  /**
   * global preferences
   */
  LOAD_USER_PREFERENCES = '[Preferences] load user preferences',
  LOAD_USER_PREFERENCES_FAIL = '[Preferences] load user preferences fail',
  LOAD_USER_PREFERENCES_SUCCESS = '[Preferences] load user preferences success',

  MOBILE_MENU_TOGGLE = '[Preferences] toggle menu toggle',

  /**
   * Feed
   */
  SET_CURRENT_FEED = '[Preferences] Set current feed',
  SET_CURRENT_FEED_SLUG = '[Preferences] Set current feed slug',
  SET_CURRENT_FEED_SLUG_SUCCESS = '[Preferences] Set current feed slug Success',
  SET_CURRENT_FEED_SLUG_FAIL = '[Preferences] Set current feed slug Fail',
  UNSET_CURRENT_FEED = '[Preferences] Unset current feed',
  GET_CURRENT_FEED_SLUG = '[Preferences] Get current feed slug',
  GET_CURRENT_FEED_SLUG_FAIL = '[Preferences] Get current feed slug fail',
  GET_CURRENT_FEED_SLUG_SUCCESS = '[Preferences] Get current feed slug success',

  /**
   * Page
   */
  SET_CURRENT_PAGE = '[Preferences] Set current page',

  /**
   * Cookie Banner
   */
  SET_COOKIE_BANNER = '[Preferences] Set cookie banner',
  SET_COOKIE_BANNER_SUCCESS = '[Preferences] Set cookie banner success',
  SET_COOKIE_BANNER_FAIL = '[Preferences] Set cookie banner fail',

  /**
   * Languages
   */
  CHANGE_INTERFACE_LANG = '[Preferences] Change interface lang',
  CHANGE_INTERFACE_LANG_SUCCESS = '[Preferences] Change interface lang success',
  CHANGE_INTERFACE_LANG_FAIL = '[Preferences] Change interface lang fail',
  CHANGE_CONTENT_LANG = '[Preferences] Change content lang',
  CHANGE_CONTENT_LANG_SUCCESS = '[Preferences] Change content lang success',
  CHANGE_CONTENT_LANG_FAIL = '[Preferences] Change content lang fail',

  /**
   * Advanced Features
   */
  CHANGE_ADVANCED_FEATURES = '[Preferences] Change advanced features',
  CHANGE_ADVANCED_FEATURES_SUCCESS = '[Preferences] Change advanced features success',
  CHANGE_ADVANCED_FEATURES_FAIL = '[Preferences] Change advanced features fail',

  /**
   * Voices
   */

  SET_DEFAULT_VOICE = '[Preferences] Set default voice',
  SET_DEFAULT_VOICE_SUCCESS = '[Preferences] Set default voice success',
  SET_DEFAULT_VOICE_FAIL = '[Preferences] Set default voice fail',

  /**
   * Filters
   */
  FILTER_MENU_TOGGLE = '[Preferences] toggle menu filter',
  SET_FILTER_VIEW_MODE = '[Preferences] Set filter view mode',
  SET_FILTER_SORT_BY = '[Preferences] Set filter sort by',
  SET_FILTER_CONTENT_TYPE = '[Preferences] Set filter content type',
  SET_FILTER_FREQUENCY = '[Preferences] Set filter frequency',
  SET_FILTER_SEASON = '[Preferences] Set filter season',
  SET_FILTER_SOURCE = '[Preferences] Set filter source',

  SET_FILTER_SUCCESS = '[Preferences] Set filter success',
  SET_FILTER_FAIL = '[Preferences] Set filter fail',

  SET_GUIDED_TOUR_COMPLETE = '[Preferences] set guided tour complete',
  SET_GUIDED_TOUR_COMPLETE_FAIL = '[Preferences] set guided tour complete fail',
  SET_GUIDED_TOUR_COMPLETE_SUCCESS = '[Preferences] set guided tour complete success'
}

export const setCurrentPage = createAction(ActionType.SET_CURRENT_PAGE, props<{ payload }>());

export const setCookieBanner = createAction(
  ActionType.SET_COOKIE_BANNER,
  props<{ payload: { hasAcceptedCookies: boolean } }>()
);

export const setCookieBannerSuccess = createAction(ActionType.SET_COOKIE_BANNER_SUCCESS);

export const setCookieBannerFail = createAction(
  ActionType.SET_COOKIE_BANNER_FAIL,
  props<{ payload: { error: string } }>()
);

export const changeInterfaceLang = createAction(
  ActionType.CHANGE_INTERFACE_LANG,
  props<{ payload: { interfaceLang: Langs } }>()
);

export const changeInterfaceLangSuccess = createAction(ActionType.CHANGE_INTERFACE_LANG_SUCCESS);

export const changeInterfaceLangFail = createAction(
  ActionType.CHANGE_INTERFACE_LANG_FAIL,
  props<{ payload: { error: any } }>()
);

export const changeContentLang = createAction(
  ActionType.CHANGE_CONTENT_LANG,
  props<{ payload: { contentLang: Langs } }>()
);
export const changeContentLangSuccess = createAction(ActionType.CHANGE_CONTENT_LANG_SUCCESS);

export const changeContentLangFail = createAction(
  ActionType.CHANGE_CONTENT_LANG_FAIL,
  props<{ payload: { error: any } }>()
);

export const changeAdvancedFeatures = createAction(
  ActionType.CHANGE_ADVANCED_FEATURES,
  props<{ payload: { advanced } }>()
);

export const changeAdvancedFeaturesSuccess = createAction(ActionType.CHANGE_ADVANCED_FEATURES_SUCCESS);

export const changeAdvancedFeaturesFail = createAction(
  ActionType.CHANGE_ADVANCED_FEATURES_FAIL,
  props<{ payload: { error: any } }>()
);

export const setLastSelectedVoice = createAction(ActionType.SET_DEFAULT_VOICE, props<{ payload: any }>());

export const setLastSelectedVoiceSuccess = createAction(
  ActionType.SET_DEFAULT_VOICE_SUCCESS,
  props<{ payload: any }>()
);

export const setGuidedTourComplete = createAction(ActionType.SET_GUIDED_TOUR_COMPLETE, props<{ payload: any }>());
export const setGuidedTourCompleteFail = createAction(
  ActionType.SET_GUIDED_TOUR_COMPLETE_FAIL,
  props<{ error: any }>()
);
export const setGuidedTourCompleteSuccess = createAction(ActionType.SET_GUIDED_TOUR_COMPLETE_SUCCESS);

export const setLastSelectedVoiceFail = createAction(ActionType.SET_DEFAULT_VOICE_FAIL, props<{ error: any }>());

export const setCurrentFeed = createAction(
  ActionType.SET_CURRENT_FEED,
  props<{
    payload: {
      feed: Feed;
    };
  }>()
);

export const setCurrentFeedSlug = createAction(
  ActionType.SET_CURRENT_FEED_SLUG,
  props<{
    payload: {
      feedSlug: string;
    };
  }>()
);

export const setCurrentFeedSlugSuccess = createAction(
  ActionType.SET_CURRENT_FEED_SLUG_SUCCESS,
  props<{
    payload: {
      feedSlug: string;
    };
  }>()
);

export const setCurrentFeedSlugFail = createAction(
  ActionType.SET_CURRENT_FEED_SLUG_FAIL,
  props<{ payload: { error: any } }>()
);

export const unsetCurrentFeed = createAction(
  ActionType.UNSET_CURRENT_FEED,
  props<{
    payload: {
      feedSlug: string;
    };
  }>()
);

export const getCurrentFeedSlug = createAction(ActionType.GET_CURRENT_FEED_SLUG);

export const getCurrentFeedSlugSuccess = createAction(
  ActionType.GET_CURRENT_FEED_SLUG_SUCCESS,
  props<{ payload: { feedSlug: string } }>()
);

export const getCurrentFeedSlugFail = createAction(
  ActionType.GET_CURRENT_FEED_SLUG_FAIL,
  props<{ payload: { error: any } }>()
);

export const setFilterViewMode = createAction(
  ActionType.SET_FILTER_VIEW_MODE,
  props<{ payload: { key: string; value: any } }>()
);
export const setFilterContentType = createAction(
  ActionType.SET_FILTER_CONTENT_TYPE,
  props<{ payload: { key: string; value: any } }>()
);
export const setFilterFrequency = createAction(
  ActionType.SET_FILTER_FREQUENCY,
  props<{ payload: { key: string; value: any } }>()
);
export const setFilterSeason = createAction(
  ActionType.SET_FILTER_SEASON,
  props<{ payload: { key: string; value: any } }>()
);
export const setFilterSource = createAction(
  ActionType.SET_FILTER_SOURCE,
  props<{ payload: { key: string; value: any } }>()
);
export const setFilterSortBy = createAction(
  ActionType.SET_FILTER_SORT_BY,
  props<{ payload: { key: string; value: any } }>()
);

export const setFilterFail = createAction(ActionType.SET_FILTER_FAIL, props<{ payload: { error: any } }>());

export const setFilterSuccess = createAction(
  ActionType.SET_FILTER_SUCCESS,
  props<{ payload: { key: any; value: any } }>()
);

export const loadUserPreferences = createAction(ActionType.LOAD_USER_PREFERENCES);

export const loadUserPreferencesFail = createAction(
  ActionType.LOAD_USER_PREFERENCES_FAIL,
  props<{ payload: { error: any } }>()
);

export const loadUserPreferencesSuccess = createAction(ActionType.LOAD_USER_PREFERENCES_SUCCESS, props<{ payload }>());

export const toggleFilterMenu = createAction(ActionType.FILTER_MENU_TOGGLE);
export const toggleMobileMenu = createAction(ActionType.MOBILE_MENU_TOGGLE, props<{ payload: { open: boolean } }>());

const all = union({
  /**
   * Global preferences
   */

  loadUserPreferences,
  loadUserPreferencesFail,
  loadUserPreferencesSuccess,

  /**
   * Language
   */
  changeInterfaceLang,
  changeInterfaceLangSuccess,
  changeInterfaceLangFail,
  changeContentLang,
  changeContentLangSuccess,
  changeContentLangFail,
  /**
   * Advance features
   */
  setCurrentPage,
  changeAdvancedFeatures,
  changeAdvancedFeaturesSuccess,
  changeAdvancedFeaturesFail,

  /**
   * Voices
   */
  setLastSelectedVoice,
  setLastSelectedVoiceSuccess,
  setLastSelectedVoiceFail,

  /**
   * CookieBanner
   */
  setCookieBanner,
  setCookieBannerSuccess,
  setCookieBannerFail,

  /**
   * Feed
   */
  setCurrentFeed,
  setCurrentFeedSlug,
  setCurrentFeedSlugSuccess,
  setCurrentFeedSlugFail,
  unsetCurrentFeed,
  getCurrentFeedSlug,
  getCurrentFeedSlugSuccess,
  getCurrentFeedSlugFail,

  /**
   * Filters
   */

  setFilterViewMode,
  setFilterSortBy,
  setFilterContentType,
  setFilterFrequency,
  setFilterSeason,
  setFilterSource,
  setFilterFail,
  setFilterSuccess,
  toggleFilterMenu,
  toggleMobileMenu,

  /**
   * Guided Tour
   */

  setGuidedTourComplete,
  setGuidedTourCompleteFail,
  setGuidedTourCompleteSuccess
});

export type PreferencesActionsUnion = typeof all;
