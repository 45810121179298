import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as fromAudioStoryStore from '@purplefront/audio-story/data-access';
import * as fromPlaylistStore from '@purplefront/playlist/data-access';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { AppRouterFacadeService } from '@purplefront/app-router/data-access';

@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss']
})
export class PlaylistComponent implements OnInit, OnDestroy, OnChanges {
  @Input() playlistArticles;
  defaultVoiceId: number;
  displayPlaylist: boolean = false;
  loading$: Observable<boolean>;
  private wasInside: boolean;
  private currentGeneratedArticleId: any;
  private plyrClassNames = [
    'plyr__controls',
    'plyr__control',
    'plyr__controls__item',
    'plyr__control--pressed',
    'feature-box-link',
    'nav-link',
    'active',
    'ng-tns-c137-0',
    'ng-tns-c73-0'
  ];

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();
  currentPlayedArticleId: string;

  constructor(
    private _audioStoryStore: Store<fromAudioStoryStore.AudioStoryState>,
    private _playlistStore: Store<fromPlaylistStore.State>,
    private _router: Router,
    private _appRouterFacade: AppRouterFacadeService
  ) {}

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event) {
    if (!this.wasInside) {
      event.target.classList.forEach((className) => {
        if (!this.plyrClassNames.includes(className)) {
          this.displayPlaylist = true;
        }
      });
    }
    this.wasInside = false;
  }
  ngOnInit(): void {
    this.loading$ = this._audioStoryStore.pipe(select(fromAudioStoryStore.selectLoadingAudioStories));
    this._audioStoryStore
      .pipe(select(fromAudioStoryStore.selectCurrentGeneratedArticleId), takeUntil(this._ngUnsubscribe$))
      .subscribe((id) => (this.currentGeneratedArticleId = id));

    if (this.playlistArticles && this.playlistArticles.length) {
      // Automatically play the first playlist article
      this.generateAudio(this.playlistArticles[0], 0);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {}

  generateAudio(article, index) {
    this.currentPlayedArticleId = article.id;
    this._playlistStore.dispatch(fromPlaylistStore.loadCurrentPlayingArticleIndex({ index }));
    article.lang === 'fr' ? (this.defaultVoiceId = 2) : (this.defaultVoiceId = 1);
    const payload = {
      voiceId: this.defaultVoiceId,
      text: article.header
    };
    if (article.id !== this.currentGeneratedArticleId) {
      this._audioStoryStore.dispatch(fromAudioStoryStore.generateAudioStoryItem({ payload }));
    } else {
      this._audioStoryStore.dispatch(fromAudioStoryStore.playPauseAudio({ payload: { playing: true } }));
    }
  }

  trackById(index: number, item: any): any {
    return item.id;
  }

  togglePlaylist() {
    this.displayPlaylist = !this.displayPlaylist;
  }

  showArticle(article) {
    this._appRouterFacade.goToArticlePage(article);
  }

  removeArticle(id: string) {
    this._playlistStore.dispatch(fromPlaylistStore.removePlaylistItem({ id: id }));
  }

  /**
   * Prevent memory leaks
   */

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
