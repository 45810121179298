import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  AudioStoryState,
  resetGenerationError,
  selectAudioFormat,
  selectGenerationError
} from '@purplefront/audio-story/data-access';
import {
  EditorState,
  isTranslateModalOpen,
  loadTranslatableLang,
  loadVoices,
  selectError,
  selectIsLoadingVoices,
  selectLastGeneratedFormats,
  selectTranslatableLangs,
  selectTranslateFailAudioStory,
  selectTranslateFailFormats,
  selectVoices,
  Voice
} from '@purplefront/editor/data-access';
import { Observable } from 'rxjs';
import * as fromFlashBriefingStore from '../../../../../flash-briefing/data-access/src/lib/store/flash-briefing.reducer';
import { getNavigationContext, getSelectedArticles } from '@purplefront/flash-briefing/data-access';
import { AuthState, getUserPermissions } from '@purplefront/auth/data-access';
import { EditorService } from '../../../../data-access/src/lib/services';
import { InfoBannerState } from '../../../../../info-banner/data-access/src/lib/store';
import { TranslatableLang } from '../../../../data-access/src/lib/_models/translatable-lang.model';

@Component({
  selector: 'app-editor-container',
  template: `<app-info-banner-container [currentPage]="currentPage"></app-info-banner-container>
    <app-workspace-editor
      [isLoadingVoices$]="isLoadingVoices$"
      [isLoadingVideo$]="isLoadingVideo$"
      [audioFormatUrl$]="audioFormatUrls$"
      [error$]="error$"
      [selectedArticles$]="selectedArticles$"
      [voices$]="voices$"
      [isTranslateModalOpen$]="isTranslateModalOpen$"
      [translateFailFormats$]="translateFailFormats$"
      [translateFailAudioStory$]="translateFailAudioStory$"
      [userPermissions]="userPermissions$ | async"
      [selectedFormats]="lastSelectedFormats$ | async"
      [generationError$]="generationError$"
      [lastGeneratedVoices]="lastGeneratedVoices$ | async"
      [shouldPatchFlashBriefing]="hasNavigatedFromFlashBriefing$ | async"
      [translatableLangs]="translatableLangs$ | async"
    ></app-workspace-editor>`,
  styleUrls: ['./editor-container.component.scss']
})
export class EditorContainerComponent implements OnInit {
  readonly currentPage = 'studio';
  isLoadingAudio$: Observable<boolean>;
  isLoadingVoices$: Observable<boolean>;
  isLoadingVideo$: Observable<any>;
  audioFormatUrls$: Observable<any[]>;
  error$: Observable<any>;
  selectedArticles$: Observable<any>;
  voices$: Observable<any>;
  userPermissions$: Observable<string[]>;
  lastSelectedFormats$: Observable<string[]>;
  lastGeneratedVoices$: Observable<Voice[]>;
  generationError$: Observable<any>;
  hasNavigatedFromFlashBriefing$: Observable<boolean>;
  translatableLangs$: Observable<TranslatableLang[]>;
  isTranslateModalOpen$: Observable<boolean>;
  translateFailAudioStory$: Observable<any>;
  translateFailFormats$: Observable<string[]>;

  constructor(
    private _audioStoryStore: Store<AudioStoryState>,
    private _authStore: Store<AuthState>,
    private _store: Store<EditorState>,
    private _flashBriefingStore: Store<fromFlashBriefingStore.State>,
    private _editorService: EditorService,
    private _infoBannerStore: Store<InfoBannerState>
  ) {}

  ngOnInit(): void {
    this.isLoadingVoices();
    this.getArticleSelection();
    this.getError();
    this.getVoices();
    this.getAudioFormatUrl();
    this.getUserPermissions();
    this.getLastSelectedFormats();
    this.getGenerationError();
    this.resetGenerationError();
    this.getLastGeneratedVoices();
    this.getNavigationContext();
    this.getTranslatableLangs();
    this.isTranslateModalOpen();
    this.getTranslateFailAudioStory();
    this.getTranslateFailFormats();
  }

  getTranslateFailFormats(): void {
    this.translateFailFormats$ = this._store.pipe(select(selectTranslateFailFormats));
  }

  getTranslateFailAudioStory(): void {
    this.translateFailAudioStory$ = this._store.pipe(select(selectTranslateFailAudioStory));
  }

  isTranslateModalOpen(): void {
    this.isTranslateModalOpen$ = this._store.pipe(select(isTranslateModalOpen));
  }

  getNavigationContext(): void {
    this.hasNavigatedFromFlashBriefing$ = this._flashBriefingStore.pipe(select(getNavigationContext));
  }

  resetGenerationError(): void {
    this._audioStoryStore.dispatch(resetGenerationError());
  }

  getGenerationError(): void {
    this.generationError$ = this._audioStoryStore.pipe(select(selectGenerationError));
  }

  getAudioFormatUrl() {
    this.audioFormatUrls$ = this._audioStoryStore.pipe(select(selectAudioFormat));
  }

  isLoadingVoices(): void {
    this.isLoadingVoices$ = this._store.pipe(select(selectIsLoadingVoices));
  }

  getVoices(): void {
    this._store.dispatch(loadVoices());
    this.voices$ = this._store.pipe(select(selectVoices));
  }

  getArticleSelection(): void {
    this.selectedArticles$ = this._flashBriefingStore.pipe(select(getSelectedArticles));
  }

  getError(): void {
    this.error$ = this._store.pipe(select(selectError));
  }

  getUserPermissions(): void {
    this.userPermissions$ = this._authStore.pipe(select(getUserPermissions));
  }

  getLastSelectedFormats(): void {
    this.lastSelectedFormats$ = this._store.pipe(select(selectLastGeneratedFormats));
  }

  getLastGeneratedVoices(): void {
    this.lastGeneratedVoices$ = this._editorService.getLastGeneratedVoices();
  }

  getTranslatableLangs(): void {
    this._store.dispatch(loadTranslatableLang());
    this.translatableLangs$ = this._store.pipe(select(selectTranslatableLangs));
  }
}
