import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromFlashBriefingStore from '../../../../../data-access/src/lib/store/flash-briefing.reducer';
import { getSelectedArticles } from 'libs/flash-briefing/data-access/src/lib/store/flash-briefing.selectors';

@Component({
  selector: 'rio-flash-briefing-container',
  template: `<rio-flash-briefing [selectedArticles]="selectedArticles"></rio-flash-briefing>`,
  styleUrls: ['./flash-briefing-container.component.scss']
})
export class FlashBriefingContainer implements OnInit {
  selectedArticles = [];
  constructor(private _flashBriefingStore: Store<fromFlashBriefingStore.State>) {}

  ngOnInit(): void {
    this.getSelectedArticles();
  }

  getSelectedArticles() {
    this._flashBriefingStore.pipe(select(getSelectedArticles)).subscribe((articles: any) => {
      this.selectedArticles = articles;
    });
  }
}
