import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppToastService } from '../../../../../data-access/src/lib/_services/toaster.service';
import { AppRouterFacadeService } from '@purplefront/app-router/data-access';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { PreferencesState, selectInterfaceLang } from '@purplefront/preferences/data-access';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-toaster-container',
  templateUrl: './toaster-container.component.html',
  styleUrls: ['./toaster-container.component.scss']
})
export class ToasterContainerComponent implements OnInit, OnDestroy {
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();
  interfaceLang: string;
  displayToaster: boolean;

  constructor(
    public toasterService: AppToastService,
    private _appRouterService: AppRouterFacadeService,
    private _prefStore: Store<PreferencesState>,
    private _router: Router,
    private _location: Location
  ) {}

  ngOnInit(): void {
    this.getInterfaceLang();
    this.getCurrentRoute();
  }

  getCurrentRoute(): void {
    this._router.events.pipe(distinctUntilChanged()).subscribe((routerEvent) => {
      if (this._location.path() != '') {
        this.displayToaster = !this._location.path().includes('editor');
      }
    });
  }

  goToAudioStories() {
    this._appRouterService.goToMyAudioContents(this.interfaceLang);
  }

  getInterfaceLang(): void {
    this._prefStore.pipe(takeUntil(this._ngUnsubscribe$), select(selectInterfaceLang)).subscribe((lang) => {
      this.interfaceLang = lang;
    });
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
