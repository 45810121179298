import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CalendarEvent } from '../_models';
import { CalendarApi } from '../_api';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  constructor(private _api: CalendarApi) {}

  static eventMap(rawEvents): CalendarEvent[] {
    return rawEvents.map(function (rawEvent) {
      return {
        id: rawEvent.id,
        title: rawEvent.title,
        start: rawEvent.start_date,
        end: rawEvent.end_date,
        lang: rawEvent.lang,
        options: {
          allDayDefault: true
        },
        data: rawEvent
      };
    });
  }

  getEvents(params): Observable<CalendarEvent[]> {
    return this._api.getCalendarEvents(params).pipe(map((events) => CalendarService.eventMap(events)));
  }
}
