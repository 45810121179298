import { Component, HostListener, OnInit } from '@angular/core';
import * as fromPlaylistStore from '@purplefront/playlist/data-access';
import { select, Store } from '@ngrx/store';
import { getPlaylistDisplayStatus, getPlaylistArticles } from '@purplefront/playlist/data-access';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-playlist-container',
  templateUrl: './playlist-container.component.html',
  styleUrls: ['./playlist-container.component.css']
})
export class PlaylistContainerComponent implements OnInit {
  playlistArticles$: Observable<any>;
  playlistDisplayStatus: boolean;

  constructor(private _playlistStore: Store<fromPlaylistStore.State>) {}

  ngOnInit(): void {
    this.getPlaylistDisplayStatus();
    this.getPlaylistArticles();
  }

  getPlaylistDisplayStatus() {
    this._playlistStore
      .pipe(
        select(getPlaylistDisplayStatus),
        map((status: boolean) => (this.playlistDisplayStatus = status))
      )
      .subscribe();
  }

  getPlaylistArticles() {
    this.playlistArticles$ = this._playlistStore.pipe(select(getPlaylistArticles));
  }
}
