import { Injectable } from '@angular/core';
import { environment } from '../environments';

export interface StorageRead {
  ok: boolean;
  data: any;
  reason?: 'storageNotAvailable' | 'DataNotFound' | 'malformedJson';
  error?: any;
}

export interface StorageWrite {
  ok: boolean;
  reason?: 'storageNotAvailable';
  error?: any;
}

@Injectable()
export class PersistentStorageService {
  private envKey = environment.name + '_';

  isAvailable(): boolean {
    try {
      const testString = '__localStorage_test__';
      localStorage.setItem(testString, testString);
      localStorage.removeItem(testString);
      return true;
    } catch (e) {
      return false;
    }
  }

  set(dataKey: string, data: any): StorageWrite {
    const jsonData = JSON.stringify(data);
    const key = this.envKey + dataKey;
    try {
      localStorage.setItem(key, jsonData);
    } catch (error) {
      return {
        ok: false,
        reason: 'storageNotAvailable',
        error: error
      };
    }

    return { ok: true };
  }

  get(dataKey: string): StorageRead {
    const key = this.envKey + dataKey;
    let storedData;
    try {
      storedData = localStorage.getItem(key);
    } catch (e) {
      return {
        ok: false,
        data: null,
        reason: 'storageNotAvailable',
        error: e
      };
    }

    if (storedData === null || storedData === 'undefined') {
      return {
        ok: false,
        data: null,
        reason: 'DataNotFound'
      };
    }

    let data;
    try {
      data = JSON.parse(storedData);
    } catch (e) {
      return {
        ok: false,
        data: storedData,
        reason: 'malformedJson',
        error: e
      };
    }

    return { ok: true, data: data };
  }

  clear(dataKey: string): void {
    const key = this.envKey + dataKey;
    localStorage.removeItem(key);
  }

  clearEditorForm(dataKey: string): void {
    localStorage.removeItem(dataKey);
  }
}
