import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject, pipe, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { UploadService } from '@purplefront/shared';
import { toFormData } from '../image-upload/image-upload.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { EditorService } from '../../../../../data-access/src/lib/services';

@Component({
  selector: 'app-multi-image-modal',
  templateUrl: './multi-image-modal.component.html',
  styleUrls: ['./multi-image-modal.component.scss']
})
export class MultiImageModalComponent implements OnChanges, OnDestroy {
  @Input() selectedItem: any;
  @Output() onImageChanges: EventEmitter<any> = new EventEmitter<any>();
  readonly availableFormats = ['jpg', 'png', 'jpeg'];
  multiImageForm: FormGroup;
  hasImage: boolean = true;
  hasNoImage: boolean = false;
  isHovered = {};
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();
  isLoading$ = new BehaviorSubject<boolean>(false);
  imgValues: string[];

  constructor(
    private _formBuilder: FormBuilder,
    private _uploadService: UploadService,
    private _cdr: ChangeDetectorRef,
    private _editorService: EditorService
  ) {
    this.multiImageForm = this._formBuilder.group({
      images: this._formBuilder.array([])
    });
  }

  get images(): FormArray {
    return this.multiImageForm.get('images') as FormArray;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.selectedItem.currentValue &&
      changes.selectedItem.currentValue !== changes.selectedItem.previousValue
    ) {
      this.imgValues = changes.selectedItem.currentValue.value.images;
      this.initFormValues(this.imgValues);
    }
  }

  initFormValues(images: string[]): void {
    this.images.clear();
    for (const image of images) {
      this.images.push(this._formBuilder.control(image));
    }
  }

  onImageChange(event, index: number, multiple: boolean = false): void {
    let lastFile = false;
    this.isLoading$.next(true);
    if (multiple === true) {
      const files = event.target.files;
      Array.from(files).forEach((file, index) => {
        lastFile = index === files.length - 1;
        this.uploadImage({ image: file }, null, lastFile);
      });
    } else {
      lastFile = true;
      const file = { image: event.target.files[0] };
      this.uploadImage(file, index, lastFile);
    }
  }

  uploadImage(file, index: number, lastFile: boolean): void {
    this._uploadService
      .uploadImage(toFormData(file))
      .pipe(takeUntil(this._ngUnsubscribe$), this.toResponseBody())
      .subscribe((res) => {
        this.patchImageUrlValue(index, res.url);
        this._cdr.detectChanges();
        if (lastFile === true) {
          this.isLoading$.next(false);
        }
      });
  }

  patchImageUrlValue(index: number, imageUrl: string): void {
    if (index === null && this.images?.value?.length < this._editorService.maxImagesPerItem) {
      this.images.push(this._formBuilder.control(imageUrl));
    } else if (index != null) {
      const imgControl: AbstractControl = this.images.at(index);
      imgControl.patchValue(imageUrl);
    }
  }

  toResponseBody<T>() {
    return pipe(
      filter((event: HttpEvent<T>) => event.type === HttpEventType.Response),
      map((res: HttpResponse<T>) => {
        return res.body;
      })
    );
  }

  saveImageChanges(): void {
    this.onImageChanges.emit(this.images.value);
  }

  deleteImage(event, index: number): void {
    event.preventDefault();
    this.images.removeAt(index);
  }

  onImageMouseOver(i: number): void {
    this.isHovered[i] = true;
  }

  onImageMouseLeave(i: number): void {
    this.isHovered[i] = false;
  }

  onInputClick(event: MouseEvent) {
    event.target['value'] = null;
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }

  drop(event: CdkDragDrop<any>) {
    const previousIndex = event.previousContainer.data.index;
    const currentIndex = event.container.data.index;
    const adjustedCurrentIndex = previousIndex + 1 < currentIndex ? currentIndex - 1 : currentIndex;

    const previousItem = this.images.controls[previousIndex];
    this.images.controls[previousIndex] = this.images.controls[adjustedCurrentIndex];
    this.images.controls[adjustedCurrentIndex] = previousItem;

    this.images.updateValueAndValidity();
    this._cdr.detectChanges();
    this._cdr.markForCheck();
  }
}
