import { createAction, props, union } from '@ngrx/store';
import { BroadcastMessage } from '../../models/broadcast-message.model';

export const loadInfoBanners = createAction('[InfoBanner] Load InfoBanners');

export const loadInfoBannersSuccess = createAction(
  '[InfoBanner] Load InfoBanners Success',
  props<{ messages: BroadcastMessage[] }>()
);

export const loadInfoBannersFailure = createAction('[InfoBanner] Load InfoBanners Failure', props<{ error: any }>());

export const closeInfoBanners = createAction('[InfoBanner] Close info banner');

const allInfoBannerActions = union({
  loadInfoBanners,
  loadInfoBannersSuccess,
  loadInfoBannersFailure,
  closeInfoBanners
});

export type InfoBannerActions = typeof allInfoBannerActions;
