import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EditorState } from '../reducers';

export const editorState = createFeatureSelector<EditorState>('editor');

export const selectVoices = createSelector(editorState, (state) => state.voices);

export const selectIsLoadingVoices = createSelector(editorState, (state) => state.loadingVoices);

export const selectError = createSelector(editorState, (state) => state.error);

export const selectUploadedAudio = createSelector(editorState, (state) => state.uploadedAudio);

export const selectCurrentAudioStory = createSelector(editorState, (state) => state.currentAudioStory);

export const selectPlyrAudioSource = createSelector(editorState, (state) => state.plyrAudioSource);

export const selectPlyrVideoSource = createSelector(editorState, (state) => state.plyrVideoSource);

export const selectLastGeneratedFormats = createSelector(editorState, (state) => state.lastSelectedFormats);

export const selectTranslatableLangs = createSelector(editorState, (state) => state.translatableLangs);

export const isTranslateModalOpen = createSelector(editorState, (state) => state.isTranslationModalOpen);

export const isPronunciationModalOpen = createSelector(editorState, (state) => state.isPronunciationModalOpen);

export const selectTranslateFailAudioStory = createSelector(editorState, (state) => state.translateFailAudioStory);

export const selectTranslateFailFormats = createSelector(editorState, (state) => state.translateFailFormats);
