<div class="tab">
  <div class="tab-content">
    <div class="tab-container">
      <img src="assets/images/logos/new-logo-stripped.svg" alt="Relaxnews" />
      <p class="current-version">201810081857_v2.1-prod_441df7e4a640</p>
      <p class="copyright">Copyright © 2018 Relaxnews My Editorializer</p>
      <a (click)="openTermsOfService($event)" class="terms">See the terms of service</a>
    </div>
  </div>
</div>
