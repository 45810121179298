import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf, of } from 'rxjs';
import { map, tap, switchMap, catchError, mergeMap } from 'rxjs/operators';

import * as fromAuthActions from '../actions/auth.actions';

import { AuthService, LoginResponse, TokenCredentials, CheckSessionResponse, SessionService } from '../../_services';
import { AppToastService } from '../../../../../../toaster/data-access/src/lib/_services/toaster.service';
import { WINDOW } from '@purplefront/shared';

@Injectable()
export class AuthEffects {
  constructor(
    private _actions$: Actions<fromAuthActions.AuthActionsUnion>,
    private _sessionService: SessionService,
    private _router: Router,
    private _toastService: AppToastService,
    public authService: AuthService,
    @Inject(WINDOW) private window: Window
  ) {}

  login$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromAuthActions.login),
      map((action) => action.payload),
      switchMap((payload) =>
        this.authService.doLogin(payload.username, payload.password).pipe(
          map((res: LoginResponse) => {
            const message: string = !res.loggedIn ? res.error.message : null;
            return res.loggedIn
              ? fromAuthActions.loginSuccess({ payload: { token: res.token } })
              : fromAuthActions.loginFailure({ payload: { message: message } });
          }),
          catchError((err: any) => observableOf(fromAuthActions.loginError({ payload: { error: err } })))
        )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromAuthActions.loginSuccess),
      map((action) => action.payload.token),
      map((token: TokenCredentials) => {
        return fromAuthActions.checkSession({ payload: { token: token } });
      })
    )
  );

  checkSession$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromAuthActions.checkSession),
      map((action) => action.payload.token),
      switchMap((token: TokenCredentials) =>
        this.authService.doCheckSession(token).pipe(
          map((res: CheckSessionResponse) => {
            return res.loggedIn
              ? fromAuthActions.checkSessionSuccess({
                  payload: {
                    data: res.data,
                    token: res.token
                  }
                })
              : fromAuthActions.checkSessionFailure();
          }),
          catchError((err: any) => observableOf(fromAuthActions.checkSessionError({ payload: { error: err } })))
        )
      )
    )
  );

  checkSessionFailure$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(fromAuthActions.checkSessionFailure),
        tap((action) => this.authService.navigateToAuthPage())
      ),
    { dispatch: false }
  );

  checkSessionSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromAuthActions.checkSessionSuccess),
      map((action) => action.payload),
      tap((payload) => this.authService.doSaveCredentials(payload.token)),
      map((payload) => {
        return fromAuthActions.sessionInitialized({
          payload: {
            anonymousSession: false,
            data: payload.data
          }
        });
      })
    )
  );

  initializeSession$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromAuthActions.initializeSession),
      map(() => {
        const savedCredentials = this.authService.doLoadCredentials();
        return savedCredentials === null
          ? fromAuthActions.loginAsAnonymous()
          : fromAuthActions.checkSession({
              payload: { token: savedCredentials }
            });
      })
    )
  );

  anonymousSessionInitialized$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromAuthActions.loginAsAnonymous),
      map(() =>
        fromAuthActions.sessionInitialized({
          payload: {
            anonymousSession: true,
            data: {}
          }
        })
      )
    )
  );

  logout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromAuthActions.logout),
      map((action) => action),
      switchMap((payload) =>
        this.authService.doLogout().pipe(
          map((res) => {
            return !res.loggedIn ? fromAuthActions.logoutSuccess() : fromAuthActions.logoutFailure();
          }),
          catchError((err: any) => observableOf(fromAuthActions.logoutError({ payload: { data: err } })))
        )
      )
    )
  );

  logoutSuccess$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(fromAuthActions.logoutSuccess),
        tap(() => {
          this._sessionService.clearCredentials();
          this._router.navigate(['/auth/login']);
        })
      ),
    { dispatch: false }
  );

  register$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromAuthActions.register),
      map((action) => action.payload),
      mergeMap((payload) =>
        this.authService.register(payload).pipe(
          map((res) => {
            const message = {
              header: 'AUTH_REGISTER',
              body: 'AUTH_THANKS_FOR_REGISTRATION'
            };
            this.authService.showSuccess(message, false);
            this.gotoLogin();
            return fromAuthActions.registerSuccess();
          }),
          catchError((err: any) => {
            if (err.status === 401) {
              const message = {
                header: 'AUTH_REGISTER',
                body: err
              };
              this.authService.showError(message, true);
              return of(
                fromAuthActions.registerFailure({
                  payload: {
                    registerErrorMessage: err
                  }
                })
              );
            }
            if (err.status === 422 && err.error.errors.email) {
              const message = {
                header: 'AUTH_REGISTER',
                body: 'AUTH_EMAIL_ALREADY_USED'
              };
              this.authService.showError(message, true);
              return of(
                fromAuthActions.registerFailure({
                  payload: {
                    registerErrorMessage: err
                  }
                })
              );
            } else {
              const message = {
                header: 'AUTH_REGISTER',
                body: err.message
              };
              this.authService.showError(message, true);
              return of(
                fromAuthActions.registerFailure({
                  payload: {
                    registerErrorMessage: err
                  }
                })
              );
            }
          })
        )
      )
    )
  );

  sendEmail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromAuthActions.sendEmail),
      map((action) => action.payload),
      mergeMap((payload) =>
        this.authService.sendEmail(payload).pipe(
          map((res) => {
            const message = {
              header: 'EMAIL',
              body: res.message
            };
            this.authService.showSuccess(message, true);
            this.gotoLogin();
            return fromAuthActions.sendEmailSuccess();
          }),
          catchError((err: any) => {
            const message = {
              header: 'EMAIL',
              body: 'EMAIL_NOT_FOUND'
            };
            this.authService.showError(message, true);
            if (err.status === 401) {
              return observableOf(
                fromAuthActions.sendEmailFailure({
                  payload: {
                    sendEmailErrorMessage: err
                  }
                })
              );
            } else {
              return observableOf(fromAuthActions.sendEmailError({ payload: { error: err } }));
            }
          })
        )
      )
    )
  );

  resetPassword$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromAuthActions.resetPassword),
      map((action) => action.payload),
      mergeMap((payload) =>
        this.authService.resetPassword(payload).pipe(
          map((res) => {
            const message = {
              header: 'AUTH_PASSWORD',
              body: res.message
            };
            this.authService.showSuccess(message, false);
            this.gotoLogin();
            return fromAuthActions.resetPasswordSuccess();
          }),
          catchError((err: any) => {
            if (err.status === 401) {
              const message = {
                header: 'AUTH_PASSWORD',
                body: err.message
              };
              this.authService.showError(message, false);
              return observableOf(
                fromAuthActions.resetPasswordFail({
                  payload: {
                    resetPasswordError: err
                  }
                })
              );
            } else {
              const message = {
                header: 'AUTH_PASSWORD',
                body: err.error.message
              };
              this.authService.showError(message, false);
              return observableOf(fromAuthActions.resetPasswordFail({ payload: { resetPasswordError: err } }));
            }
          })
        )
      )
    )
  );

  activateRegistrationCode$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromAuthActions.activateRegistrationCode),
      map((action) => action.payload),
      switchMap((payload) => {
        return this.authService.activateRegistrationCode(payload).pipe(
          map((res: any) => {
            if (res && (res.status === 'ok' || res.access_token)) {
              return fromAuthActions.activateRegistrationCodeSuccess({
                payload: {
                  status: 'ok',
                  data: res
                }
              });
            } else {
              this.gotoLogin();
              return fromAuthActions.activateRegistrationCodeFail({
                payload: { status: 'invalidCodeError' }
              });
            }
          }),
          catchError((err: any) => {
            this.gotoLogin();
            return observableOf(
              fromAuthActions.activateRegistrationCodeFail({ payload: { status: 'invalidCodeError' } })
            );
          })
        );
      })
    )
  );

  activateRegistrationCodeSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(fromAuthActions.activateRegistrationCodeSuccess),
      map((action) => action.payload),
      map((payload) => {
        return fromAuthActions.loginSuccess({
          payload: {
            token: {
              username: '',
              accessToken: payload.data.access_token,
              expiresAt: payload.data.expires_at,
              tokenType: payload.data.token_type
            }
          }
        });
      })
    )
  );

  gotoLogin(): void {
    this.window.setTimeout(() => this._router.navigateByUrl('/auth/login'), 2000);
  }
}
