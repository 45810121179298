import * as actions from '../actions/auth.actions';
import { AuthStatus, TokenCredentials } from '../../_models';
import { createReducer, on } from '@ngrx/store';

export interface AuthState {
  status: AuthStatus;
  loginError: any | null;
  checkSessionError: any | null;
  tokenCredentials: TokenCredentials | null;
  data: any;
  registerError: any | null;
  registerData: any;
  errorMessage: any | null;
  resetPasswordError: any | null;
  email: string | null;
  sendEmailError: any | null;
  loadingRegister: boolean;
  activateCodeStatus: any;
  userDetails: any;
}

export const initialState: AuthState = {
  status: AuthStatus.NOT_INITIALIZED,
  loginError: null,
  checkSessionError: null,
  tokenCredentials: null,
  data: {},
  registerError: null,
  registerData: {},
  errorMessage: null,
  resetPasswordError: null,
  email: null,
  sendEmailError: null,
  loadingRegister: false,
  activateCodeStatus: null,
  userDetails: null
};
const authFeatureReducer = createReducer(
  initialState,
  on(actions.loginAsAnonymous, (state) => {
    return {
      ...state,
      status: AuthStatus.ANONYMOUS
    };
  }),
  on(actions.loginSuccess, (state) => {
    return {
      ...state,
      loginError: null
    };
  }),
  on(actions.checkSessionSuccess, (state, action) => {
    return {
      ...state,
      status: AuthStatus.LOGGED,
      checkSessionError: null,
      data: action.payload.data,
      userDetails: {
        id: action.payload.data.data.id,
        email: action.payload.data.data.email,
        firstName: action.payload.data.data.first_name,
        lastName: action.payload.data.data.last_name,
        roles: action.payload.data.data.roles
      },
      tokenCredentials: action.payload.token
    };
  }),
  on(actions.checkSession, (state) => {
    return {
      ...state,
      status: AuthStatus.WAITING_FOR_SERVER,
      checkSessionError: null
    };
  }),
  on(actions.checkSessionFailure, (state) => {
    return {
      ...state,
      status: AuthStatus.UNLOGGED
    };
  }),
  on(actions.checkSessionError, (state, action) => {
    return {
      ...state,
      status: AuthStatus.UNLOGGED,
      checkSessionError: action.payload.error
    };
  }),
  on(actions.login, (state, action) => {
    return {
      ...state,
      status: AuthStatus.WAITING_FOR_SERVER,
      loginError: null
    };
  }),
  on(actions.logoutSuccess, (state) => {
    return {
      ...state,
      status: AuthStatus.UNLOGGED,
      tokenCredentials: null
    };
  }),
  on(actions.loginFailure, (state, action) => {
    return {
      ...state,
      status: AuthStatus.UNLOGGED,
      loginError: action.payload.message
    };
  }),
  on(actions.loginError, (state, action) => {
    return {
      ...state,
      status: AuthStatus.UNLOGGED,
      loginError: action.payload.error
    };
  }),
  on(actions.sendEmail, (state, action) => {
    return {
      ...state,
      email: action.payload.email
    };
  }),
  on(actions.sendEmailError, (state, action) => {
    return {
      ...state,
      resetPasswordError: action.payload.error
    };
  }),
  on(actions.sendEmailSuccess, (state) => {
    return {
      ...state
    };
  }),
  on(actions.sendEmailFailure, (state, action) => {
    return {
      ...state,
      sendEmailError: action.payload.sendEmailErrorMessage.error.message
    };
  }),
  on(actions.register, (state, action) => {
    return {
      ...state,
      registerData: action.payload,
      loadingRegister: true
    };
  }),
  on(actions.registerError, (state, action) => {
    return {
      ...state,
      registerError: action.payload,
      loadingRegister: false
    };
  }),
  on(actions.registerSuccess, (state) => {
    return {
      ...state,
      loadingRegister: false
    };
  }),
  on(actions.registerFailure, (state, action) => {
    return {
      ...state,
      errorMessage: action.payload.registerErrorMessage.error.message,
      loadingRegister: false
    };
  }),
  on(actions.activateRegistrationCode, (state, action) => {
    return {
      ...state,
      activateCodeStatus: 'waiting'
    };
  }),
  on(actions.activateRegistrationCodeSuccess, (state, action) => {
    return {
      ...state,
      activateCodeStatus: action.payload.status
    };
  }),
  on(actions.activateRegistrationCodeFail, (state, action) => {
    return {
      ...state,
      activateCodeStatus: action.payload.status
    };
  })
);

export function authReducer(state: AuthState, action: actions.AuthActionsUnion): AuthState {
  return authFeatureReducer(state, action);
}
