import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from '../reducers';
import { AuthStatus } from '../../_models';

export const authState = createFeatureSelector<AuthState>('auth');

export const status = createSelector(authState, (state: AuthState) => state.status);

export const isLogged = createSelector(status, (_status: AuthStatus) => _status === AuthStatus.LOGGED);

export const isAuthLoading = createSelector(status, (_status: AuthStatus) => _status === AuthStatus.WAITING_FOR_SERVER);

export const selectLoadingRegister = createSelector(authState, (state) => state.loadingRegister);

export const selectActivationCodeStatus = createSelector(authState, (state) => state.activateCodeStatus);

export const isAnonymous = createSelector(status, (_status: AuthStatus) => _status === AuthStatus.ANONYMOUS);

export const hasSession = createSelector(
  status,
  (_status: AuthStatus) => _status === AuthStatus.ANONYMOUS || _status === AuthStatus.LOGGED
);

export const tokenCredentials = createSelector(authState, (state: AuthState) => state.tokenCredentials);

export const getErrorMessage = createSelector(authState, (state: AuthState) => state.errorMessage);

export const loginErrorMsg = createSelector(authState, (state: AuthState) => state.loginError);

export const getSendEmailErrorMessage = createSelector(authState, (state: AuthState) => state.sendEmailError);

export const getUserName = createSelector(authState, (state: AuthState) => state?.data?.data?.first_name);

export const getUserEmail = createSelector(authState, (state: AuthState) => state?.data?.data?.email);

export const getUserId = createSelector(authState, (state: AuthState) => state?.data.data?.id);

export const getUserPermissions = createSelector(authState, (state: AuthState) => state.data.data.permissions);

export const getUserDetails = createSelector(authState, (state: AuthState) => state.userDetails);
