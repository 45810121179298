import { createAction, props } from '@ngrx/store';
import { PlaylistEntity } from './playlist.models';

export const loadPlaylist = createAction('[Playlist] Load Playlist', props<{ articles: any }>());

export const loadPlaylistSuccess = createAction(
  '[Playlist] Load Playlist Success',
  props<{ playlist: PlaylistEntity[] }>()
);

export const loadPlaylistFailure = createAction('[Playlist] Load Playlist Failure', props<{ error: any }>());

export const removePlaylistItem = createAction('[Playlist] Remove Playlist Item', props<{ id: any }>());

export const loadCurrentPlayingArticleIndex = createAction(
  '[Playlist] Load current playlist article index',
  props<{ index: number }>()
);
