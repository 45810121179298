import { Component, OnInit, Output } from '@angular/core';
import * as fromPreferencesStore from '@purplefront/preferences/data-access';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cookie-banner',
  template: `
    <purplefront-cookie-banner
      [hasAcceptedCookies]="cookieBanner$ | async"
      (openTermsOfService)="openTermsOfService($event)"
    ></purplefront-cookie-banner>
  `
})
export class CookieBannerContainer implements OnInit {
  @Output() cookieBanner$: any;

  constructor(
    private _preferencesStore: Store<fromPreferencesStore.PreferencesState>,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getCookiesPreferences();
  }

  getCookiesPreferences(): void {
    this.cookieBanner$ = this._preferencesStore.pipe(select(fromPreferencesStore.selectCookieBanner));
  }

  openTermsOfService($event) {
    this._router.navigate(['.'], {
      relativeTo: this._route,
      queryParams: {
        modal: 'terms-of-service'
      }
    });
  }
}
