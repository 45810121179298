<div #voiceDropdownElement="ngbDropdown" ngbDropdown class="selectVoices">
  <button type="button" class="btn select-label" id="voiceDropdown" ngbDropdownToggle>
    <span *ngIf="selectedVoice$ | async">{{ (selectedVoice$ | async).label }}</span>
    <span *ngIf="!(selectedVoice$ | async)">{{ 'EDITOR_CHOOSE_VOICE' | translate }}</span>
  </button>
  <ul class="voice-list" ngbDropdownMenu aria-labelledby="voiceDropdown" style="width: 100% !important">
    <input
      [(ngModel)]="searchText"
      type="text"
      class="form-control mb-2 input-search-voices"
      name="search"
      placeholder="&#8981;"
      autocomplete="off"
    />
    <div *ngIf="!searchText">
      <li *ngFor="let voice of lastGeneratedVoices" ngbDropdownItem (click)="changeSelectedOption(voice)">
        <span
          class="dropdown-item"
          [matTooltip]="shouldDisplayTooltip(voice) ? (unavailableTooltip | translate) : shouldDisplayTooltipFormerlyEmma(voice) ? (formerlyEmmaTooltip | translate) : null"
          matTooltipPosition="above"
        >
          <!-- handle unique overlap for UK (Ukrainian language) and UK (United Kingdom) -->
          <span style="font-weight: bold"
            >{{
              voice.language.toUpperCase() === 'UK-UA'
                ? (voice.language.toUpperCase() | translate)
                : (voice.language.substring(0, 2).toUpperCase() | translate)
            }}
            ({{ voice.country.toUpperCase() | translate }})
          </span>
          - <span>{{ voice.label }}</span>
          <span *ngIf="shouldDisplayTooltip(voice) || shouldDisplayTooltipFormerlyEmma(voice)">*</span>
        </span>
      </li>
    </div>

    <hr *ngIf="lastGeneratedVoices && lastGeneratedVoices?.length !== 0 && !searchText" class="divider" />

    <li *ngFor="let voice of voices | filterVoices: searchText" ngbDropdownItem (click)="changeSelectedOption(voice)">
      <span
        class="dropdown-item"
        [matTooltip]="shouldDisplayTooltip(voice) ? (unavailableTooltip | translate) : shouldDisplayTooltipFormerlyEmma(voice) ? (formerlyEmmaTooltip | translate) : null"
        matTooltipPosition="above"
      >
        <!-- handle unique overlap for UK (Ukrainian language) and UK (United Kingdom) -->
        <span style="font-weight: bold"
          >{{
            voice.language.toUpperCase() === 'UK-UA'
              ? (voice.language.toUpperCase() | translate)
              : (voice.language.substring(0, 2).toUpperCase() | translate)
          }}
          ({{ voice.country.toUpperCase() | translate }})
        </span>
        - <span>{{ voice.label }}</span>
        <span *ngIf="shouldDisplayTooltip(voice) || shouldDisplayTooltipFormerlyEmma(voice)"> *</span>
      </span>
    </li>
  </ul>
</div>
