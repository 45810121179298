import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { BookmarksEffects } from './_store/effects';
import { BookmarksApi } from './_api';
import { reducer } from './_store/reducers';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('bookmarks', reducer), EffectsModule.forFeature([BookmarksEffects])],
  providers: [BookmarksApi]
})
export class BookmarksDataAccessModule {}
