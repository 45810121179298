import { Inject, Injectable } from '@angular/core';

import { select, State, Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { ArticleModalState } from '../reducers';
import {
  getArticleSuccess,
  getArticleFailure,
  getMoreLikeArticlesSuccess,
  getMoreLikeArticles,
  getMoreLikeArticlesFailure,
  getRelatedArticles,
  getRelatedArticlesFailure,
  getRelatedArticlesSuccess,
  mailArticleSuccess,
  mailArticleFailure,
  ArticleModalActionsUnion,
  getLatestArticlesSuccess,
  getLatestArticlesFail
} from '../actions';
import * as ArticleModalActions from '../actions';
import { mergeMap, map, catchError, flatMap, tap, filter, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ArticleService } from '../../_services';
import { environment, WINDOW } from '@purplefront/shared';

@Injectable()
export class ArticleModalEffects {
  private basicApiUrl: string = environment.baseAPI;

  constructor(
    private _actions$: Actions<ArticleModalActionsUnion>,
    private _articleStore: Store<ArticleModalState>,
    private _articleServices: ArticleService,
    @Inject(WINDOW) private window: Window
  ) {}

  getArticle$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ArticleModalActions.getArticle),
      mergeMap((action) => {
        return this._articleServices.getArticle(action.payload.article).pipe(
          map((article) => getArticleSuccess({ payload: { article: article } })),
          catchError((err: any) => of(getArticleFailure({ payload: { error: err } })))
        );
      })
    )
  );

  getArticleSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ArticleModalActions.getArticle),
      flatMap((action) => [
        getMoreLikeArticles({
          payload: { article: action.payload.article }
        }),
        getRelatedArticles({
          payload: { id: action.payload.article.articleId }
        })
      ])
    )
  );

  getMoreLikeArticles$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ArticleModalActions.getMoreLikeArticles),
      mergeMap((action) =>
        this._articleServices.getMoreLikeArticles(action.payload).pipe(
          map((articles) => {
            return getMoreLikeArticlesSuccess({
              payload: {
                moreLikeArticles: articles
              }
            });
          }),
          catchError((err: any) => of(getMoreLikeArticlesFailure({ payload: { error: err } })))
        )
      )
    )
  );

  getRelatedArticles$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ArticleModalActions.getRelatedArticles),
      mergeMap((action) =>
        this._articleServices.getRelatedArticles(action.payload.id).pipe(
          map((articles) => {
            return getRelatedArticlesSuccess({
              payload: {
                relatedArticles: articles
              }
            });
          }),
          catchError((err: any) => of(getRelatedArticlesFailure({ payload: { error: err } })))
        )
      )
    )
  );

  getLatestArticles$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ArticleModalActions.getLatestArticles),
      mergeMap((action) =>
        this._articleServices.getLatestArticles(action.mainCategory, action.lang, action.articleId).pipe(
          map((articles) => {
            return getLatestArticlesSuccess({ articles });
          }),
          catchError((err: any) => of(getLatestArticlesFail({ error: err })))
        )
      )
    )
  );

  downloadImage$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(ArticleModalActions.downloadImage),
        tap((action) => {
          const index = action.payload.index;
          const imageDownloadUrl = `${this.basicApiUrl}/assets/v1/image/downloadOriginalImage/${action.payload.sourceType}/${action.payload.lang}/${action.payload.id}?index=${index}`;
          this.window.location.href = imageDownloadUrl;
        })
      ),
    { dispatch: false }
  );

  mailArticle$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ArticleModalActions.mailArticle),
      mergeMap((action) =>
        this._articleServices
          .mailArticle(action.payload.id, action.payload.type, action.payload.name, action.payload.email)
          .pipe(
            map((article) => {
              return mailArticleSuccess();
            }),
            catchError((err: any) => of(mailArticleFailure({ payload: { error: err } })))
          )
      )
    )
  );
}
