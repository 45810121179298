import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { Langs } from '@purplefront/shared';
import { TranslateService } from '@ngx-translate/core';
import { PreferencesApi } from '../_api/preferences.api';
import * as fromRooterStore from '@purplefront/app-router/data-access';
import { PreferencesState } from '../_store/reducers/preferences.reducers';

@Injectable({
  providedIn: 'root'
})
export class InterfaceLanguageService implements OnDestroy {
  private _ngUnsubscribe: Subject<void> = new Subject<void>();
  private _allowedLang: Array<Langs> = ['en', 'fr'];
  private _defaultApplicationLanguage: Langs;
  private _browserLang: string;

  constructor(
    private _translate: TranslateService,
    private _preferencesService: PreferencesApi,
    private _routerStore: Store<fromRooterStore.AppRouterState>,
    private _prefStore: Store<PreferencesState>
  ) {}

  // Init Default application language
  init(userPref): void {
    // Get the default lang
    this._defaultApplicationLanguage = <Langs>this._translate.getDefaultLang();

    // Get the browser lang
    this._browserLang = this._translate.getBrowserLang();
    //const interfaceLang = userPref.languages.interface;
    const interfaceLang = userPref.languages.interface;

    if (this._allowedLang.includes(interfaceLang)) {
      this._defaultApplicationLanguage = interfaceLang;
    } else if (this._allowedLang.includes(<Langs>this._browserLang)) {
      this._defaultApplicationLanguage = <Langs>this._browserLang;
    }
    this._translate.use(this._defaultApplicationLanguage);
  }

  public formatLang(lang: string): string {
    const defaultLang = 'multi';
    if (lang === 'en' || lang === 'fr') {
      return lang;
    } else {
      return defaultLang;
    }
  }

  public getBrowserLang(): Langs | string {
    const lang = this._translate.getBrowserLang();
    if (this._allowedLang.includes(<Langs>lang)) {
      return lang;
    } else {
      console.warn('Browser lang is not supported: ' + lang);
      return this._allowedLang[0];
    }
  }

  // Prevent memory leaks
  ngOnDestroy(): void {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }
}
