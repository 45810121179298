import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { EditorContainerComponent } from './editor-container/editor-container.component';
import { EditorUiModule } from '@purplefront/editor/ui';
import { EditorDataAccessModule } from '../../../data-access/src';
import { AudioStoryDataAccessModule } from '@purplefront/audio-story/data-access';
import { SharedModule } from '@purplefront/shared';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InfoBannerFeatureShellModule } from '@purplefront/info-banner/feature-shell';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([{ path: '', pathMatch: 'full', component: EditorContainerComponent }]),
    EditorDataAccessModule,
    EditorUiModule,
    AudioStoryDataAccessModule,
    SharedModule,
    DragDropModule,
    InfoBannerFeatureShellModule
  ],
  declarations: [EditorContainerComponent]
})
export class EditorFeatureShellModule {}
