<div class="desktop-menu">
  <div id="main-menu" #mainMenu class="rio-navbar navOpen d-flex flex-column" *ngIf="showMenu() && !isMobile">
    <picture (click)="goToDiscover()" id="logo-mobile-menu" class="d-none d-md-block">
      <img class="logo" src="assets/images/logos/logo-etxstudio-revolusound.png" />
    </picture>
    <ul id="navbar-nav">
      <li (click)="toggleNavbar(false)" class="mb-0 d-md-none">
        <a><app-etx-icons name="close_white"></app-etx-icons></a>
      </li>
      <li (click)="toggleSearch()" class="mb-0">
        <a id="menu-search" class="menu-search" [ngClass]="{ 'active-menu': isActiveMenu('searchPage') }"
          ><img class="icon" src="assets/icons/menu-search.png" /><span class="link-text">{{
            'MENU_SEARCH' | translate
          }}</span></a
        >
      </li>
      <li>
        <div class="accordion d-flex flex-column" id="accordionExample">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h2 class="mb-0">
                <button
                  class="btn btn-block"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  <a
                    id="menu-discover"
                    class="menu-discover"
                    (click)="goToDiscover()"
                    [ngClass]="{ 'active-menu-discover': isActiveMenu('discover') }"
                    ><span class="badge discover-badge"></span
                    ><img class="icon" src="assets/icons/menu-discover.png" />{{ 'MENU_DISCOVER' | translate }}</a
                  >
                </button>
              </h2>
            </div>

            <div
              id="collapseOne"
              class="collapse active"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div class="card-body">
                <ul class="discover-b">
                  <li (click)="goToSearchByTag(tag.value, $event)" *ngFor="let tag of tags">
                    <a [ngClass]="{ 'active-tag': isActiveTag(tag) }">#{{ tag.label }}<span class="badge"></span></a>
                  </li>
                  <li *ngFor="let folder of folders" (click)="goToSearchByFolder(folder, $event)">
                    <a> {{ folder.title }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card mt-2">
            <div class="card-header" id="headingTwo">
              <h2 class="mb-0">
                <button
                  class="btn btn-block collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <a
                    id="menu-workspace"
                    class="menu-workspace"
                    (click)="goToStudio()"
                    [ngClass]="{ 'active-menu-workspace-desktop': isActiveMenu('workspace') }"
                    ><span class="badge workspace-badge"></span>
                    <img class="icon" src="assets/icons/menu-workspace.png" />{{ 'STUDIO_AUDIO_VIDEO' | translate }}</a
                  >
                </button>
              </h2>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div class="card-body">
                <ul class="workspace-b">
                  <li>
                    <a (click)="goToEditor()">{{ 'MENU_EDITOR' | translate }}</a>
                  </li>
                  <li>
                    <a (click)="goToMyAudioContents()"
                      ><span class="badge crea-badge"></span>{{ 'MENU_CREATIONS' | translate }}</a
                    >
                  </li>
                  <!--                  <li>-->
                  <!--                    <a data-mdb-toggle="tooltip" title="Coming soon"-->
                  <!--                      ><span class="badge fav-badge"></span>{{ 'MENU_BOOKMARKS' | translate }}</a-->
                  <!--                    >-->
                  <!--                  </li>-->
                  <!--                  <li>-->
                  <!--                    <a data-mdb-toggle="tooltip" title="Coming soon"-->
                  <!--                      ><span class="badge alert-badge"></span>{{ 'MENU_ALERT' | translate }}</a-->
                  <!--                    >-->
                  <!--                  </li>-->
                  <li (click)="newAudioStory()">
                    <button class="btn btn-light btn-sm">+ {{ 'MENU_NEW' | translate }}</button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card mt-2">
            <div class="card-header" id="headingThree">
              <h2 class="mb-0">
                <button
                  class="btn btn-block collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <a class="link-icon mt-1" (click)="openNaggingModal()"
                    ><img class="icon" src="assets/icons/menu-user.png" /><span class="user-name">{{
                      userName$ | async
                    }}</span></a
                  >
                </button>
              </h2>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div class="card-body">
                <ul class="workspace-b">
                  <li>
                    <a (click)="goToPreferencesPage()">{{ 'SETTINGS' | translate }}</a>
                  </li>
                  <li>
                    <a (click)="openHelpModal()">Help</a>
                  </li>
                  <li>
                    <a (click)="logout()">{{ 'AUTH_LOG_OUT' | translate }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

<!--wwwwwwwwwwwww
     Menu Mobile
    wwwwwwwwwwwww-->
<div class="mobile-menu">
  <div
    id="main-menu-mobile"
    #mainMenu
    class="rio-navbar"
    *ngIf="showMenu() && isMobile"
    [ngClass]="{ navOpen: toggleNavBar$ | async }"
  >
    <picture (click)="goToDiscover(); toggleNavbar(false)" class="d-none d-md-block">
      <img class="logo" src="assets/images/logos/etx-dailyup-logo-color.png" />
    </picture>
    <ul id="navbar-nav-mobile">
      <li (click)="toggleNavbar(false)" class="mb-0 d-md-none">
        <a><app-etx-icons name="close_white"></app-etx-icons></a>
      </li>
      <li (click)="toggleSearch()" class="mb-0 mt-5 mt-md-0">
        <a id="menu-search-mobile" class="menu-search" [ngClass]="{ 'active-menu': isActiveMenu('searchPage') }"
          ><img class="icon" src="assets/icons/menu-search.png" /><span class="link-text">{{
            'MENU_SEARCH' | translate
          }}</span></a
        >
      </li>
      <li class="discover">
        <button
          type="button"
          class="btn dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <a
            id="menu-discover-mobile"
            class="menu-discover"
            (click)="goToDiscover()"
            [ngClass]="{ 'active-menu': isActiveMenu('discover') }"
            ><span class="badge discover-badge"></span><img class="icon" src="assets/icons/menu-discover.png" />{{
              'MENU_DISCOVER' | translate
            }}</a
          >
        </button>
        <ul class="discover-b dropdown-menu">
          <li (click)="goToSearchByTag(tag.value, $event); toggleNavbar(false)" *ngFor="let tag of tags">
            <a [ngClass]="{ 'active-tag': isActiveTag(tag) }">#{{ tag.label }}<span class="badge"></span></a>
          </li>
        </ul>
      </li>
      <li class="workspace">
        <button
          type="button"
          class="btn dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <a
            id="menu-workspace-mobile"
            class="menu-workspace"
            (click)="goToStudio()"
            [ngClass]="{ 'active-menu-workspace': isActiveMenu('workspace') }"
            ><span class="badge workspace-badge"></span>
            <img class="icon" src="assets/icons/menu-workspace.png" />Studio</a
          >
        </button>
        <ul class="workspace-b dropdown-menu dropdown-workspace">
          <li>
            <a (click)="goToEditor(); toggleNavbar(false)">{{ 'MENU_EDITOR' | translate }}</a>
          </li>
          <li>
            <a (click)="goToMyAudioContents(); toggleNavbar(false)"
              ><span class="badge crea-badge"></span>{{ 'MENU_CREATIONS' | translate }}</a
            >
          </li>

          <!--          <li>-->
          <!--            <a data-mdb-toggle="tooltip" title="Coming soon"-->
          <!--              ><span class="badge fav-badge"></span>{{ 'MENU_BOOKMARKS' | translate }}</a-->
          <!--            >-->
          <!--          </li>-->
          <!--          <li>-->
          <!--            <a data-mdb-toggle="tooltip" title="Coming soon"-->
          <!--              ><span class="badge alert-badge"></span>{{ 'MENU_ALERT' | translate }}</a-->
          <!--            >-->
          <!--          </li>-->
          <li (click)="newAudioStory(); toggleNavbar(false)">
            <button class="btn btn-light btn-sm ms-4 mt-2">+ {{ 'MENU_NEW' | translate }}</button>
          </li>
        </ul>
      </li>
      <li>
        <div class="preference">
          <button
            type="button"
            class="btn dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <a class="link-icon mt-1" (click)="openNaggingModal()"
              ><img class="icon" src="assets/icons/menu-user.png" /><span class="user-name">{{
                userName$ | async
              }}</span></a
            >
          </button>
          <ul class="workspace-b dropdown-menu dropdown-pref">
            <li>
              <a (click)="goToPreferencesPage(); toggleNavbar(false)">{{ 'SETTINGS' | translate }}</a>
            </li>
            <li>
              <a (click)="logout()">{{ 'AUTH_LOG_OUT' | translate }}</a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</div>
