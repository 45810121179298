import { Action, createAction, props, union } from '@ngrx/store';
import { Langs } from '@purplefront/shared';

export enum ArticleActionType {
  OPEN_ARTICLE_MODAL = '[Article Modal] Open article modal',
  CLOSE_ARTICLE_MODAL = '[Article Modal] Close article modal',
  DISPLAY_MODAL_TAB = '[Article Modal] Change active tab',
  GET_ARTICLE = '[Article Modal] Get article',
  GET_ARTICLE_SUCCESS = '[Article Modal] Get article success',
  GET_ARTICLE_FAIL = '[Article Modal] Get article fail',
  GET_MORE_LIKE_ARTICLES = '[Article Modal] Get more like articles',
  GET_MORE_LIKE_ARTICLES_SUCCESS = '[Article Modal] Get more like articles success',
  GET_MORE_LIKE_ARTICLES_FAIL = '[Article Modal] Get more like articles fail',
  GET_RELATED_ARTICLES = '[Article Modal] Get related articles',
  GET_RELATED_ARTICLES_SUCCESS = '[Article Modal] Get related articles success',
  GET_RELATED_ARTICLES_FAIL = '[Article Modal] Get related articles fail',
  MAIL_ARTICLE = '[Article Modal] Mail article',
  MAIL_ARTICLE_SUCCESS = '[Article Modal] Mail article success',
  MAIL_ARTICLE_FAIL = '[Article Modal] Mail article fail',
  DOWNLOAD_IMAGE = '[Article Modal] Download Image',
  DOWNLOAD_IMAGE_SUCCESS = '[Article Modal] Download Image success',
  DOWNLOAD_IMAGE_FAIL = '[Article Modal] Download Image fail',
  GET_LATEST_ARTICLES = '[Article Modal] Get latest articles',
  GET_LATEST_ARTICLES_SUCCESS = '[Article Modal] Get latest articles success',
  GET_LATEST_ARTICLES_FAIL = '[Article Modal] Get latest articles fail'
}
export const openArticleModal = createAction(
  ArticleActionType.OPEN_ARTICLE_MODAL,
  props<{ payload: { articleId: string } }>()
);

export const closeArticleModal = createAction(
  ArticleActionType.CLOSE_ARTICLE_MODAL,
  props<{ payload: { articleId: string } }>()
);

export const displayModalTab = createAction(
  ArticleActionType.DISPLAY_MODAL_TAB,
  props<{ payload: { activeTab: string } }>()
);

export const getArticle = createAction(ArticleActionType.GET_ARTICLE, props<{ payload: { article: any } }>());

export const getArticleSuccess = createAction(
  ArticleActionType.GET_ARTICLE_SUCCESS,
  props<{ payload: { article: any } }>()
);

export const getArticleFailure = createAction(ArticleActionType.GET_ARTICLE_FAIL, props<{ payload: { error: any } }>());

export const getMoreLikeArticles = createAction(
  ArticleActionType.GET_MORE_LIKE_ARTICLES,
  props<{ payload: { article: any } }>()
);

export const getMoreLikeArticlesSuccess = createAction(
  ArticleActionType.GET_MORE_LIKE_ARTICLES_SUCCESS,
  props<{ payload: { moreLikeArticles: any } }>()
);

export const getMoreLikeArticlesFailure = createAction(
  ArticleActionType.GET_MORE_LIKE_ARTICLES_FAIL,
  props<{ payload: { error: any } }>()
);

export const getRelatedArticles = createAction(
  ArticleActionType.GET_RELATED_ARTICLES,
  props<{ payload: { id: string } }>()
);

export const getRelatedArticlesSuccess = createAction(
  ArticleActionType.GET_RELATED_ARTICLES_SUCCESS,
  props<{ payload: { relatedArticles: any } }>()
);

export const getRelatedArticlesFailure = createAction(
  ArticleActionType.GET_RELATED_ARTICLES_FAIL,
  props<{ payload: { error: any } }>()
);

export const mailArticle = createAction(
  ArticleActionType.MAIL_ARTICLE,
  props<{
    payload: { id: string; type: string; name: string; email: string };
  }>()
);

export const mailArticleSuccess = createAction(ArticleActionType.MAIL_ARTICLE_SUCCESS);

export const mailArticleFailure = createAction(
  ArticleActionType.MAIL_ARTICLE_FAIL,
  props<{ payload: { error: any } }>()
);

export const downloadImage = createAction(
  ArticleActionType.DOWNLOAD_IMAGE,
  props<{
    payload: { sourceType: string; lang: string; id: string; index: number };
  }>()
);

export const downloadImageSuccess = createAction(ArticleActionType.DOWNLOAD_IMAGE_SUCCESS);

export const downloadImageFailure = createAction(
  ArticleActionType.DOWNLOAD_IMAGE_FAIL,
  props<{ payload: { error: any } }>()
);

export const getLatestArticles = createAction(
  ArticleActionType.GET_LATEST_ARTICLES,
  props<{ mainCategory: string; lang: Langs; articleId: string }>()
);

export const getLatestArticlesSuccess = createAction(
  ArticleActionType.GET_LATEST_ARTICLES_SUCCESS,
  props<{ articles: any }>()
);

export const getLatestArticlesFail = createAction(ArticleActionType.GET_LATEST_ARTICLES_FAIL, props<{ error: any }>());

const all = union({
  openArticleModal,
  closeArticleModal,
  displayModalTab,
  getArticle,
  getArticleSuccess,
  getArticleFailure,
  getMoreLikeArticles,
  getMoreLikeArticlesSuccess,
  getMoreLikeArticlesFailure,
  getRelatedArticles,
  getRelatedArticlesSuccess,
  mailArticle,
  mailArticleSuccess,
  mailArticleFailure,
  downloadImage,
  downloadImageSuccess,
  downloadImageFailure,
  getLatestArticles,
  getLatestArticlesSuccess,
  getLatestArticlesFail
});

export type ArticleModalActionsUnion = typeof all;
