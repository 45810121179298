import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Langs } from '@purplefront/shared';
import { PreferencesState, selectInterfaceLang } from '@purplefront/preferences/data-access';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss']
})
export class HelpModalComponent implements OnInit, OnDestroy {
  @Output() initGuidedTour = new EventEmitter();
  private _ngUnsubscribe$ = new Subject<void>();
  private _interfaceLang: Langs;
  readonly userGuideFr =
    'https://f.hubspotusercontent10.net/hubfs/2136919/USER%20GUIDE%20FR%20-%20ETX%20DAILY%20UP%202021.pdf';
  readonly userGuideEn =
    'https://f.hubspotusercontent10.net/hubfs/2136919/USER%20GUIDE%20EN%20-%20ETX%20DAILY%20UP%202021.pdf';
  userGuideURL: string;
  constructor(private _prefStore: Store<PreferencesState>) {}

  ngOnInit(): void {
    this.getInterfaceLang();
  }

  startGuidedTour() {
    this.initGuidedTour.emit();
  }

  getInterfaceLang() {
    this._prefStore
      .pipe(
        select(selectInterfaceLang),
        takeUntil(this._ngUnsubscribe$),
        map((lang) => (this._interfaceLang = lang))
      )
      .subscribe(() => {
        this.setUserGuideLink();
      });
  }

  setUserGuideLink() {
    if (this._interfaceLang === 'fr') {
      this.userGuideURL = this.userGuideFr;
    } else if (this._interfaceLang === 'en') {
      this.userGuideURL = this.userGuideEn;
    }
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
