import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastService } from '../../_services/toast.service';
import { Langs } from '@purplefront/shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-toasts',
  template: `
    <ngb-toast
      *ngFor="let toast of toastService.toasts"
      [header]="toast.headertext | translate"
      [class]="'toaster'"
      [autohide]="toast.autohide"
      [delay]="toast.delay || 5000"
    >
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl | translate"></ng-template>
      </ng-template>

      <ng-template #text>{{ toast.textOrTpl | translate }}</ng-template>
    </ngb-toast>
  `,
  host: { '[class.ngb-toasts]': 'true' }
})
export class ToastsComponent implements OnInit {
  interfaceLang: string;
  constructor(public toastService: ToastService, private _translate: TranslateService) {}

  ngOnInit() {
    this._initLang();
  }

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }

  /**
   * Init default interface lang
   * @private
   */
  private _initLang() {
    const lang: Langs = <Langs>this._translate.getBrowserLang();
    if (lang === 'fr') {
      this.interfaceLang = 'fr';
      this._translate.use('fr');
    } else {
      this.interfaceLang = 'en';
      this._translate.use('en');
    }
  }
}
