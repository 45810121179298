import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { PreferencesState, selectInterfaceLang } from '@purplefront/preferences/data-access';
import { environment, Langs } from '@purplefront/shared';
import { map } from 'rxjs/operators';
import { BroadcastMessage, MessageBody } from '../../../../../data-access/src/lib/models/broadcast-message.model';
import {
  closeInfoBanners,
  InfoBannerState,
  loadInfoBanners,
  selectDiscoverBroadcastMessages,
  selectIsInfoBannerOpen,
  selectStudioBroadcastMessages
} from '../../../../../data-access/src/lib/store';
import * as fromAuthDataAccessLib from '@purplefront/auth/data-access';
import { getUserDetails } from '@purplefront/auth/data-access';
import { Router } from '@angular/router';

@Component({
  selector: 'app-info-banner-container',
  template: `<app-info-banner
    *ngIf="infoBannerIsOpen$ | async"
    [message]="messageSubject$ | async"
    (closeInfoBanner)="closeInfoBanner()"
  ></app-info-banner>`,
  styleUrls: ['./info-banner-container.component.scss']
})
export class InfoBannerContainerComponent implements OnInit {
  readonly upgradeAccountUrl = `${environment.dailyUpUrl}/upgrade-account`;
  @Input() currentPage: string;
  messages$: Observable<BroadcastMessage[]>;
  message$: Observable<BroadcastMessage>;
  infoBannerIsOpen$: Observable<boolean>;
  private interfaceLang$: Observable<Langs>;
  private userDetails$: Observable<any[]>;
  public messageSubject$ = new BehaviorSubject(null);

  constructor(
    private _preferencesStore: Store<PreferencesState>,
    private _infoBannerStore: Store<InfoBannerState>,
    private _authStore: Store<fromAuthDataAccessLib.AuthState>,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.userDetails$ = this._authStore.pipe(select(getUserDetails));
    this.getMessages();
    this.getInterfaceLang();
    this.mapMessage();
    this.getIsInfoBannerClosed();
  }

  getMessages(): void {
    this._infoBannerStore.dispatch(loadInfoBanners());
    if (this.currentPage === 'studio') {
      this.messages$ = this._infoBannerStore.pipe(select(selectStudioBroadcastMessages));
    } else if (this.currentPage === 'discover') {
      this.messages$ = this._infoBannerStore.pipe(select(selectDiscoverBroadcastMessages));
    }
    this.getFirstMessage();
  }

  getFirstMessage(): void {
    this.message$ = this.messages$.pipe(map((res) => res[0]));
  }

  getInterfaceLang(): void {
    this.interfaceLang$ = this._preferencesStore.pipe(select(selectInterfaceLang));
  }

  mapMessage(): void {
    combineLatest([this.message$, this.interfaceLang$, this.userDetails$])
      .pipe(
        map(([message, lang, userDetails]: [BroadcastMessage, Langs, any]) => {
          const isDemoUser = userDetails.roles.includes('Demo');
          if (lang === 'en') {
            this.mapENMessage(message, isDemoUser);
          }
          if (lang === 'fr') {
            this.mapFRMessage(message, isDemoUser);
          }
        })
      )
      .subscribe();
  }

  mapENMessage(message: BroadcastMessage, isDemoUser: boolean): void {
    if (isDemoUser) {
      this.messageSubject$.next({
        content: 'Free trial account',
        textLink: 'More Info on Premium',
        url: this.upgradeAccountUrl
      });
    } else if (message) {
      this.messageSubject$.next({
        content: message.content_en,
        textLink: message.link_text_en,
        url: message.link_url_en
      });
    }
  }

  mapFRMessage(message: BroadcastMessage, isDemoUser: boolean): void {
    if (isDemoUser) {
      this.messageSubject$.next({
        content: "Version d'essai gratuite",
        textLink: 'En savoir plus sur le plan Premium',
        url: this.upgradeAccountUrl
      });
    } else if (message) {
      this.messageSubject$.next({
        content: message.content_fr,
        textLink: message.link_text_fr,
        url: message.link_url_fr
      });
    }
  }

  closeInfoBanner(): void {
    this._infoBannerStore.dispatch(closeInfoBanners());
  }

  private getIsInfoBannerClosed() {
    this.infoBannerIsOpen$ = this._infoBannerStore.pipe(select(selectIsInfoBannerOpen));
  }
}
