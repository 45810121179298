<div class="tab">
  <div class="tab-content">
    <div class="catalog-form">
      <form [formGroup]="languageForm">
        <label class="catalog-title">
          <h4>{{ 'LANGUAGE_SELECTION' | translate }}</h4>
        </label>
        <ul class="language">
          <li>
            <label>
              <input type="radio" formControlName="interfaceLang" value="fr" />
              <img src="assets/images/flags/fr.png" alt="Fr" />
            </label>
          </li>
          <li>
            <label>
              <input type="radio" formControlName="interfaceLang" value="en" />
              <img src="assets/images/flags/us.png" alt="Us" />
            </label>
          </li>
        </ul>
      </form>
    </div>
  </div>
  <div class="tab-footer">
    <button class="save-button">{{ 'SAVE' | translate }}</button>
  </div>
</div>
