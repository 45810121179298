import { Injectable } from '@angular/core';
import { environment } from '@purplefront/shared';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Lexicon, LexiconPayload } from '@purplefront/editor/data-access';

@Injectable({
  providedIn: 'root'
})
export class LexiconApiService {
  readonly lexiconUrl: string = `${environment.purpleApi}/lexicons`;

  constructor(private _http: HttpClient) {}

  getLexicons(language: string): Observable<Lexicon[]> {
    let params = new HttpParams();
    params = params.append('language', language);
    return this._http
      .get<Lexicon[]>(`${this.lexiconUrl}?cache_bust=${new Date().getTime()}`, { params: params })
      .pipe(map((res) => res['data']));
  }

  addLexicon(params: LexiconPayload): Observable<Lexicon> {
    return this._http.post<Lexicon>(this.lexiconUrl, params).pipe(map((res) => res['data']));
  }

  deleteLexicon(id: number): Observable<any> {
    const url = `${this.lexiconUrl}/${id}`;
    return this._http.delete(url);
  }

  updateLexicon(params: LexiconPayload, id: number): Observable<Lexicon> {
    const url = `${this.lexiconUrl}/${id}`;
    return this._http.put<Lexicon>(url, params).pipe(map((res) => res['data']));
  }
}
