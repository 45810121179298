import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BookmarksState } from '../reducers';

import * as fromBookmark from '../reducers/bookmarks.reducers';

export const selectBookmarksState = createFeatureSelector<BookmarksState>('bookmarks');

export const selectBookmarkBySlug = (bookmarkSlug: string) =>
  createSelector(selectBookmarksState, fromBookmark.selectAll);

export const selectAllBookmarks = createSelector(selectBookmarksState, fromBookmark.selectAll);

export const selectBookmarksTotal = createSelector(
  selectAllBookmarks,
  (bookmarks) => bookmarks.filter((bookmark) => bookmark).length
);

export const isLoadingBookmarks = createSelector(selectBookmarksState, (bookmarks) => bookmarks.isLoadingBookmarks);

export const selectSearchBookmarks = createSelector(selectAllBookmarks, (bookmarks) =>
  bookmarks.filter((bookmark) => bookmark.context['contentType'] === 'search')
);

export const selectBookmarksIds = createSelector(selectBookmarksState, fromBookmark.selectIds);
