import { createAction, props, union } from '@ngrx/store';
import { SearchEntity } from './search.models';

export enum ActionType {
  SEARCH_QUERY = '[Search] Search query',
  SEARCH_QUERY_SUCCESS = '[Search] Search query success',
  SEARCH_QUERY_FAIL = '[Search] Search query fail',
  TOGGLE_SEARCH_NAV = '[Search] toggle search nav',
  RESET_SEARCH_QUERY = '[Search] Reset Search query'
}

export const loadSearch = createAction('[Search] Load Search');

export const loadSearchSuccess = createAction('[Search] Load Search Success', props<{ search: SearchEntity[] }>());

export const loadSearchFailure = createAction('[Search] Load Search Failure', props<{ error: any }>());

export const searchQuery = createAction(
  ActionType.SEARCH_QUERY,
  props<{ payload: { searchString: string; contentLang } }>()
);

export const searchQuerySuccess = createAction(
  ActionType.SEARCH_QUERY_SUCCESS,
  props<{ payload: { searchResponse: any; searchString: any } }>()
);

export const searchQueryFail = createAction(ActionType.SEARCH_QUERY_FAIL, props<{ payload: { error: any } }>());

export const resetSearchQuery = createAction(ActionType.RESET_SEARCH_QUERY);

const all = union({
  searchQuery,
  searchQuerySuccess,
  searchQueryFail,
  resetSearchQuery
});
