import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { cloneDeep, uniqBy } from 'lodash-es';
import {
  EditorState,
  Lexicon,
  loadCurrentAudioStory,
  openPronunciationModal,
  selectCurrentAudioStory,
  selectUploadedAudio,
  setLastGeneratedFormats,
  Voice,
  VoicePreviewPayload
} from '@purplefront/editor/data-access';
import { ActivatedRoute, Router } from '@angular/router';
import { EditorService } from '../../../../../data-access/src/lib/services';
import {
  AudioStory,
  AudioStoryState,
  generateAudioStoryItem,
  openAudioStoryOverlay,
  resetGeneratedUrls,
  selectGenerationLoaded,
  selectGenerationLoading,
  updateAudioStory
} from '@purplefront/audio-story/data-access';

import { swiperConfig } from '../../../../../utils/src/lib/config/swiper.config';
import { AppRouterFacadeService } from '@purplefront/app-router/data-access';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { EtxIconsRegistryService } from '../../../../../../shared/src/lib/components/etx-icons/etx-icons-registry.service';
import {
  etxIconAddWhite,
  etxIconDownload,
  etxIconPlay
} from '../../../../../../shared/src/lib/components/etx-icons/etx-icons.model';
import {
  addSelectFlashBriefing,
  clearFlashBriefing,
  FlashBriefingPartialState,
  navigatedFromFlashBriefing,
  removeSelectFlashBriefing
} from '@purplefront/flash-briefing/data-access';
import {
  deleteForm,
  FORM_SYNC_CONFIG,
  getFormSyncValue,
  IFormSyncConfig,
  resetForm,
  setForm
} from '@larscom/ngrx-store-storagesync';
import { PersistentStorageService, SelectionRectangle, TrackingService, WINDOW } from '@purplefront/shared';
import { AudioStoryService } from '../../../../../../audio-story/data-access/src/lib/_services/audio-story.service';
import { ConfirmDialogService } from '../../../../../../shared/src/lib/components/delete-confirm-dialog/confirm-dialog.service';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { debounce } from '../../../../../utils/src/lib/functions/debounce';
import { VoicesDropdownComponent } from '../voices-dropdown/voices-dropdown.component';
import { FormatDropdownComponent } from '../format-dropdown/format-dropdown.component';
import { AudioBackgroundDropdownComponent } from '../audio-background-dropdown/audio-background-dropdown.component';
import { TranslatableLang } from '../../../../../data-access/src/lib/_models/translatable-lang.model';
import { TextItemParameters } from '../../../../../data-access/src/lib/_models/textItem';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { SsmlEditorComponent } from '../ssml-editor/ssml-editor.component';
import { SwiperComponent } from 'swiper/angular';

const formSyncConfig: IFormSyncConfig = {
  /* Only sync to the store when submitting the form. */
  syncOnSubmit: false
};

@Component({
  selector: 'app-workspace-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [
    {
      provide: FORM_SYNC_CONFIG,
      useValue: formSyncConfig
    }
  ]
})
export class EditorComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() shouldPatchFlashBriefing: boolean;
  @Input() isLoadingVoices$: Observable<boolean>;
  @Input() isLoadingVideo$: Observable<boolean>;
  @Input() error$: Observable<any>;
  @Input() audioFormatUrl$: Observable<any>;
  @Input() voices$: Observable<any>;
  @Input() selectedArticles$: Observable<any[]>;
  @Input() generationError$: Observable<any>;
  @Input() isTranslateModalOpen$: Observable<boolean>;
  @Input() translateFailAudioStory$: Observable<any>;
  @Input() translateFailFormats$: Observable<string[]>;
  @Input() userPermissions: Array<string>;
  @Input() selectedFormats: Array<string>;
  @Input() lastGeneratedVoices: Voice[];
  @Input() translatableLangs: TranslatableLang[];
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @ViewChild('selfClosingSuccessAlert', { static: false }) selfClosingSuccessAlert: NgbAlert;
  @ViewChild('selfClosingErrorAlert', { static: false }) selfClosingErrorAlert: NgbAlert;
  @ViewChild('itemAudioPreviewErrorAlert', { static: false }) itemAudioPreviewErrorAlert: NgbAlert;
  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>;
  @ViewChild(SsmlEditorComponent, { static: false }) ssmlEditorComponent: SsmlEditorComponent;

  updateEditorElement: any;
  currentBackgroundValue = {
    data: {
      translateKey: 'BACKGROUND_MUSIC_NONE'
    }
  };
  readonly maxItemLength = 8;

  private _success = new Subject<string>();
  private _error = new Subject<string>();
  _itemAudioPreviewError = new Subject<string>();
  successMessage = '';
  errorMessage = '';
  itemAudioPreviewErrorMessage = '';
  editorForm: FormGroup;
  success: boolean;
  article: any;
  showPlayer = 'inactive';
  selectedItem: FormGroup;
  config = swiperConfig;
  showDescription = false;
  expandDescription = false;
  isAddedEntireArticleText = {};
  currentAudioStory: any;
  selectedArticles: any[];
  invalidTextItems: any;
  generationLoading$: Observable<boolean>;
  generationLoaded$: Observable<boolean>;
  displayAudioFormatUrl: Observable<boolean>;
  hostRectangle: SelectionRectangle | null;
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();
  private _formStorage: any;
  selectedText$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  alias$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @ViewChild('storyName') storyName: ElementRef;
  @ViewChild('firstAddItemDropdown') firstAddItemDropdown;
  @ViewChild('articleDescriptionBis') articleDescriptionBis;
  @ViewChild(VoicesDropdownComponent) voicesDropdown: VoicesDropdownComponent;
  @ViewChild(FormatDropdownComponent) formatDropdown: FormatDropdownComponent;
  @ViewChild(AudioBackgroundDropdownComponent) audioBackgroundDropdown: AudioBackgroundDropdownComponent;
  @ViewChild('editor') editor: ElementRef;
  @ViewChild('cta') cta: ElementRef;
  @ViewChildren('addItemDropdown') addItemDropdown;
  parentNode$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  selectedItem$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  parentNodeName$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  timer: any;
  invalidItemsCharacterCount: number[];
  characterCountError = false;
  voiceHover: boolean;
  voiceRectangle: { top: number; left: number };
  voices: Voice[];
  voiceTooltip: { language: string; country: string; label: string };
  invalidAudioItems: any;
  audioStoryMainLang: string;
  ssmlText: '';
  currentSlide: number = 0;
  loaded: boolean[] = [];
  lexicons: Lexicon[];
  observableMedia;
  private gridByBreakpoint = { xl: 'desktop', lg: 'desktop', md: 'tablet', sm: 'tablet', xs: 'tablet' };
  gridCols = 'desktop';
  mode: string;
  constructor(
    private _store: Store<EditorState>,
    private _route: ActivatedRoute,
    private _ref: ChangeDetectorRef,
    public _editorService: EditorService,
    private _formBuilder: FormBuilder,
    private _audioStoryStore: Store<AudioStoryState>,
    private _appRouterFacade: AppRouterFacadeService,
    private _ngxSmartModalService: NgxSmartModalService,
    private _etxIconsRegistry: EtxIconsRegistryService,
    private _flashBriefingStore: Store<FlashBriefingPartialState>,
    private _formStore: Store<any>,
    private _storageService: PersistentStorageService,
    private _audioStoryService: AudioStoryService,
    private _confirmDialogService: ConfirmDialogService,
    private _renderer: Renderer2,
    private _trackingService: TrackingService,
    private _router: Router,
    private mediaObserver: MediaObserver,
    @Inject(WINDOW) private window: Window
  ) {
    this._etxIconsRegistry.registerIcons([etxIconPlay, etxIconDownload, etxIconAddWhite]);
  }

  /**
   * Close Item dropdown menu when click outside.
   * @param targetElement
   */
  @HostListener('document:click', ['$event.target']) public onclick(targetElement) {
    const clickInsideFirstAddItemDropdown =
      this.firstAddItemDropdown?._elementRef.nativeElement.contains(targetElement);
    if (!clickInsideFirstAddItemDropdown) {
      this.firstAddItemDropdown?.close();
    }

    this.addItemDropdown.forEach((el) => {
      const clickInsideAddItemDropdown = el._elementRef.nativeElement.contains(targetElement);
      if (!clickInsideAddItemDropdown) {
        el?.close();
      }
    });
  }

  closeAddItemDropdowns(): void {
    this.firstAddItemDropdown?.close();
    this.addItemDropdown?.forEach((el) => {
      el?.close();
    });
  }

  onContainerClick(event: MouseEvent) {
    const clickInsideArticleDescription = this.articleDescriptionBis?.nativeElement.contains(event.target);
    if (!clickInsideArticleDescription) {
      this.hostRectangle = null;
    }
  }

  onStoryItemContainerClick() {
    this.formatDropdown.close();
    this.voicesDropdown.close();
    this.audioBackgroundDropdown.close();
  }

  onSwiperSlideClick(clickedSlideIndex: number): void {
    // setTimeout to 0 to allow new slides to be created before accessing them
    setTimeout(() => {
      const slides = this.swiper?.swiperRef?.slides;
      if (slides.length && slides[clickedSlideIndex]) {
        // DOM7 iterator, traditionnal forEach / for of approach doesn't work here
        slides.each((el) => el.classList?.remove('etx-active-border'));
        slides[clickedSlideIndex]?.classList?.add('etx-active-border');
      }
    }, 0);
  }

  /**
   * Sync hidden formcontrol textarea with #articleDescriptionBis (contentEditable div)
   * @param target
   */
  @HostListener('document:keyup', ['$event.target'])
  public onArticleTextChange(target) {
    const insideArticleTextDiv = this.articleDescriptionBis?.nativeElement.contains(target);
    if (insideArticleTextDiv) {
      this.selectedItem.patchValue({ articleText: target.innerHTML });
    }
  }

  ngOnInit(): void {
    this.initBaseForm();
    this.initDataFromStore();
    this.closeAudioStoryGenerationPage();
    this.handleSaveMessage();
    this.handleErrorMessage();
    this.handleItemAudioPreviewErrorMessage();
    this.getGenerationError();
    this.setVoices();
    this.observeGrid();
  }

  observeGrid() {
    this.observableMedia = this.mediaObserver.asObservable().subscribe((changes: MediaChange[]) => {
      this.gridCols = this.gridByBreakpoint[changes[0].mqAlias];
    });
  }

  setVoices(): void {
    this.voices$
      .pipe(
        takeUntil(this._ngUnsubscribe$),
        map((voices: Voice[]) => (this.voices = voices))
      )
      .subscribe();
  }

  getGenerationError(): void {
    this.generationError$
      .pipe(
        takeUntil(this._ngUnsubscribe$),
        filter((error) => error != null),
        map(() => {
          this.changeErrorMessage('GENERATION_ERROR');
          this.closeAudioStoryGenerationPage();
          this.updateView();
        })
      )
      .subscribe();
  }

  handleSaveMessage() {
    this._success.pipe(takeUntil(this._ngUnsubscribe$)).subscribe((message) => (this.successMessage = message));
    this._success.pipe(debounceTime(4000), takeUntil(this._ngUnsubscribe$)).subscribe(() => {
      if (this.selfClosingSuccessAlert) {
        this.selfClosingSuccessAlert.close();
      }
    });
  }

  handleErrorMessage() {
    this._error.pipe(takeUntil(this._ngUnsubscribe$)).subscribe((message) => (this.errorMessage = message));
    this._error.pipe(debounceTime(5000), takeUntil(this._ngUnsubscribe$)).subscribe(() => {
      if (this.selfClosingErrorAlert) {
        this.selfClosingErrorAlert.close();
      }
    });
  }

  handleItemAudioPreviewErrorMessage() {
    this._itemAudioPreviewError.pipe(takeUntil(this._ngUnsubscribe$)).subscribe((message) => {
      return (this.itemAudioPreviewErrorMessage = message);
    });
    this._itemAudioPreviewError.pipe(debounceTime(4000), takeUntil(this._ngUnsubscribe$)).subscribe(() => {
      if (this.itemAudioPreviewErrorAlert) {
        this.itemAudioPreviewErrorAlert.close();
      }
    });
  }

  setStoryNameInputFocus(): void {
    this._renderer.selectRootElement(this.storyName.nativeElement).focus();
    this.scrollToTop();
  }

  ngAfterViewInit() {
    this._route.queryParams.pipe(takeUntil(this._ngUnsubscribe$)).subscribe((params) => {
      if (params.audioStoryId) {
        this.initEditForm();
      } else if (params.hasAudio === 'true') {
        this.clearEditorForm();
        this.initEmptyAudioItem(0);
      } else if (params.hasAudio === 'false') {
        this.clearEditorForm();
        this.initEmptyTextItem(0);
      } else if (params.newStory) {
        this.resetForm();
        this.removeUrlParams();
      } else {
        this.initStandAloneArticleAudioForm();
      }
    });
    this.setStoryNameInputFocus();
  }

  removeUrlParams(): void {
    this._router.navigate([], {
      queryParams: {
        newStory: false
      },
      queryParamsHandling: 'merge',
      skipLocationChange: true,
      replaceUrl: true
    });
  }

  public changeSuccessMessage(successMessage: string) {
    this._success.next(successMessage);
  }

  public changeErrorMessage(errorMessage: string) {
    this._error.next(errorMessage);
  }

  public changeItemAudioPreviewError(errorMessage: string) {
    this._itemAudioPreviewError.next(errorMessage);
  }

  onAudioStoryNameChange(name) {
    this.editorForm.patchValue({ name });
  }

  onSuccess(): void {
    this.success = true;
  }

  get form(): { [p: string]: AbstractControl } {
    return this.editorForm.controls;
  }
  get items(): FormArray {
    return this.form.items as FormArray;
  }

  getImages(item): FormArray {
    return item.get('images') as FormArray;
  }

  scrollToTop() {
    this.window.scroll(0, 0);
  }

  selectStoryItem(i: number) {
    this.selectedItem = null;
    this.selectedItem$.next(this.selectedItem);
    this.updateView();
    this.selectedItem = <FormGroup>this.items.at(i);
    this.onSwiperSlideClick(i);
    this.selectedItem$.next(this.selectedItem);
    this.expandDescription = false;
    this.showPlayer = 'active';
    const openTextEditor = true;
    this.toggleDescription(openTextEditor);
    this.expandArticleDescription();
    if (this.selectedItem?.value.type === 'text') {
      this.timer = setTimeout(() => {
        this.bindInnerHTML();
      }, 0);
    }
  }

  bindInnerHTML() {
    if (!this.articleDescriptionBis) {
      return;
    }
    this._renderer.setProperty(
      this.articleDescriptionBis.nativeElement,
      'innerHTML',
      this.selectedItem.value.articleText
    );
  }

  addEntireArticleText() {
    const id = this.selectedItem.value.id;
    this.trackEvent('editor-add-entire-article');
    let displayedText = '';
    if (!this.isAddedEntireArticleText[id]) {
      this.isAddedEntireArticleText[id] = !this.isAddedEntireArticleText[id];
      displayedText = this.selectedItem.value.articleText + '\n' + this.selectedItem.value.description;
      this.selectedItem.patchValue({
        articleText: displayedText
      });
    }
    const editor: HTMLElement = document.getElementById('editor');
    editor.innerHTML = displayedText;
  }

  expandArticleDescription() {
    this.trackEvent('editor-enlarge-edition-area');
    this.expandDescription = !this.expandDescription;
  }

  initBaseForm(): void {
    this.editorForm = this._formBuilder.group({
      voices: [null, Validators.required],
      format: [null, Validators.required],
      title: [null],
      name: [null, Validators.required],
      items: new FormArray([]),
      background: [null]
    });
  }

  initStandAloneArticleAudioForm(): void {
    this._formStore.pipe(take(1), select(getFormSyncValue, { id: 'editorFormID' })).subscribe((formVal) => {
      this._formStorage = { ...formVal };
      if (
        (!formVal && !this.selectedArticles?.length) ||
        (formVal && !formVal.items.length && !this.selectedArticles?.length)
      ) {
        this.initEmptyTextItem(0);
      }

      if (!formVal && this.selectedArticles?.length) {
        this.initFlashBriefingForm();
      }

      if (formVal && !this.selectedArticles?.length) {
        this.patchValueFromStorage(formVal);
      }

      if (formVal && this.selectedArticles?.length) {
        this.patchValueFromStorage(formVal);
        this.initFlashBriefingForm();
      }

      this.updateView();
    });
  }

  updateView(): void {
    this._ref.markForCheck();
    this._ref.detectChanges();
  }

  addArticleToSelection(article): void {
    this._flashBriefingStore.dispatch(addSelectFlashBriefing({ article: article }));
  }

  initFlashBriefingForm(): void {
    if (this.shouldPatchFlashBriefing === false) return;
    this.config.slidesOffsetBefore = 50;
    const firstItem = this.items.value[0];
    const emptyFirstItem = this.isItemEmpty(firstItem);
    if (emptyFirstItem) {
      this.removeControl(0, firstItem, false);
    }
    this.selectedArticles?.map((article) => {
      const image = article.images[0].audiostory;
      const { lang } = article;
      if (!this.isInStoredItems(article)) {
        const textItemPayload: TextItemParameters = {
          text: this._editorService.mapArticleToText(article.header),
          imagesUrls: [image],
          title: article.title,
          description: this._editorService.mapArticleToText(article.content),
          id: article.id,
          jingle: true,
          lang: lang.toUpperCase()
        };
        const textItem = this.createTextItem(textItemPayload);
        if (!textItem) return;
        this.items.push(textItem);
      }
    });

    this._flashBriefingStore.dispatch(navigatedFromFlashBriefing({ navigationFromFlashBriefing: false }));
  }

  isInStoredItems(article): boolean {
    return this.items.value.findIndex((item) => item.id === article.id) !== -1;
  }

  addArticle() {
    this._appRouterFacade.goToFeedPage(['all']);
  }

  initEmptyTextItem(i: number): void {
    this.closeAddItemDropdowns();
    const textItem = this.createTextItem({ id: i });
    if (!textItem) return;
    this.items.insert(i, textItem);
    this.selectStoryItem(i);
  }

  initEmptyAudioItem(i: number): void {
    this.closeAddItemDropdowns();
    const audioItem = this.createAudioItem();
    if (!audioItem) return;
    this.items.insert(i, audioItem);
    this.selectStoryItem(i);
  }

  checkFormItemText(): boolean {
    return this.selectedItem.value.type === 'text';
  }

  checkFormItemAudio(): boolean {
    return this.selectedItem.value.type === 'audio';
  }

  createAudioItem(
    audioUrl: string = null,
    imagesUrls: string[] = [],
    title: string = null,
    id: number = null,
    jingle: boolean = true
  ): FormGroup {
    if (this.items.value.length >= this.maxItemLength) {
      this.changeErrorMessage('ITEM_LIMIT_ERROR');
      return;
    }

    const audioItem = this._formBuilder.group({
      id: id,
      title: [title],
      audio: [audioUrl],
      images: this._formBuilder.array([]),
      type: 'audio',
      jingle: [jingle]
    });

    const control = this.getImages(audioItem);

    if (imagesUrls.length > 0) {
      for (let image of imagesUrls) {
        control.push(this._formBuilder.control(image));
      }
    }
    return audioItem;
  }

  createTextItem({ text, imagesUrls, title, description, id, jingle, lang }: TextItemParameters): FormGroup {
    if (this.items.value.length >= this.maxItemLength) {
      this.changeErrorMessage('ITEM_LIMIT_ERROR');
      return;
    }
    const textItem = this._formBuilder.group({
      id: id ?? null,
      articleText: [text ?? ''],
      description: [description ?? ''],
      images: this._formBuilder.array([]),
      title: [title ?? ''],
      type: 'text',
      jingle: [jingle ?? true],
      lang: lang ?? ''
    });
    const control = this.getImages(textItem);

    if (imagesUrls?.length > 0) {
      for (let image of imagesUrls) {
        control.push(this._formBuilder.control(image));
      }
    }
    return textItem;
  }

  removeControl(i: number, item, replaceItem = true): void {
    this.trackEvent('editor-delete-card');
    const id = item.value?.id;
    this.items.removeAt(i);
    const value = this.editorForm.value;
    this._formStore.dispatch(setForm({ id: 'editorFormID', value }));

    if (id) {
      this._flashBriefingStore.dispatch(removeSelectFlashBriefing({ id: id }));
    }

    if (this.items.value.length === 0 && replaceItem === true) {
      this.initEmptyTextItem(0);
    }
  }

  initEditForm() {
    this.mode = 'edit';
    this._store
      .pipe(
        select(selectCurrentAudioStory),
        takeUntil(this._ngUnsubscribe$),
        filter((val) => val != null)
      )
      .subscribe((res) => {
        this.currentAudioStory = res;
        this.patchValueFromCurrentAudioStory(res);
        this.selectStoryItem(0);
      });
  }

  getFirstVoiceInItems(items) {
    return this._editorService.getFirstVoiceInItems(items);
  }

  patchValueFromCurrentAudioStory(audioStory) {
    const items = audioStory.items;
    let imagesUrls = [];
    const voiceId = this.getFirstVoiceInItems(items);
    this.voices$
      .pipe(
        takeUntil(this._ngUnsubscribe$),
        distinctUntilChanged(),
        filter((voices) => voices != null)
      )
      .subscribe((voices) => {
        const voice = this._editorService.getVoice(voiceId, voices);
        if (voice != null) {
          this.patchSelectedVoice(voice);
        }
      });
    this.clearEditorForm();
    this.onAudioStoryNameChange(audioStory.name);
    const url = audioStory.background?.url || null;
    this.patchBackgroundValue(url);
    this.currentBackgroundValue = { data: url };
    items.map((item) => {
      imagesUrls = item.images;
      const { text, title, description, id, jingle, lang } = item;
      if (item.type === 'text') {
        const textItemPayload: TextItemParameters = {
          text,
          imagesUrls,
          title,
          description,
          id,
          jingle,
          lang
        };
        this.items.push(this.createTextItem(textItemPayload));
      } else {
        this.items.push(this.createAudioItem(item.audio, imagesUrls, item.text, null, item.jingle));
      }
    });
  }

  patchValueFromStorage(formVal): void {
    let items = cloneDeep(formVal.items);
    items = uniqBy(items, (i) => (i.id ? i.id : i.title));
    if (items.length === 1 && this.isItemEmpty(items[0])) {
      this.items.push(this.createTextItem({}));
    }
    if (items.length) {
      items.map((item) => {
        if (!this.isItemEmpty(item)) {
          if (item.type === 'text') {
            const imagesUrls = item.images;
            const { title, description, id, jingle, lang } = item;
            const textItemPayload: TextItemParameters = {
              text: item.articleText,
              imagesUrls,
              title,
              description,
              id,
              jingle,
              lang
            };
            this.items.push(this.createTextItem(textItemPayload));
          } else {
            this.items.push(this.createAudioItem(item.audio, item.images, item.title, null, item.jingle));
          }
        }
      });
    } else {
      this.items.push(this.createTextItem({}));
    }
    this.onAudioStoryNameChange(formVal.name);
  }

  isItemEmpty(item): boolean {
    return item && !item.articleText && item.images?.length <= 0 && !item.title && !item.audio;
  }

  initDataFromStore(): void {
    this.generationLoading$ = this._audioStoryStore.pipe(select(selectGenerationLoading));
    this.generationLoaded$ = this._audioStoryStore.pipe(select(selectGenerationLoaded));

    this.selectedArticles$.pipe(takeUntil(this._ngUnsubscribe$)).subscribe((articles) => {
      this.selectedArticles = articles;
    });

    this.getUploadAudio();

    this.hostRectangle = null;
  }

  getUploadAudio() {
    this._store.pipe(select(selectUploadedAudio)).subscribe((uploadedAudio) => {
      if (uploadedAudio.path) {
        this.setAudioControlValue(uploadedAudio.position, uploadedAudio.path);
      }
    });
  }

  setAudioControlValue(index: number, path: string): void {
    if (this.selectedItem) {
      this.trackEvent('editor-add-audio');
      this.selectedItem.patchValue({ audio: path });
    }
  }

  addSelectedFormat(data) {
    const formats = [...data];
    this._store.dispatch(setLastGeneratedFormats({ formats: formats }));
  }

  patchSelectedVoice(event) {
    this.changeItemAudioPreviewError(null);
    const payload = {
      voiceLang: event.language
    };
    this.trackEvent('editor-select-voice', event.label, payload);
    this.editorForm.patchValue({ voices: event }, { emitEvent: false });
  }

  saveBeforeGenerate() {
    this._store.pipe(select(selectCurrentAudioStory), takeUntil(this._ngUnsubscribe$)).subscribe((audioStory) => {
      if (audioStory?.id) {
        this.currentAudioStory = audioStory;
        this.updateAudioStory();
        this.generateAudioStory(audioStory.id);
      } else {
        this.setCurrentAudioStory();
      }
    });
  }

  generateAudioStory(id) {
    this._audioStoryStore.dispatch(resetGeneratedUrls());
    const translate = this.userPermissions.includes('audiostory.translate');
    const body = this.mapFormToAudioStory(
      this.items.value,
      this.form.voices.value.id,
      this.form.name.value,
      this.form.background.value,
      translate
    );
    const payload = {
      body,
      format: '',
      id: id
    };
    this._audioStoryService.generateAudioStoryFormats(this.selectedFormats, payload);
  }

  saveAudioStory() {
    this.saveCurrentAudioStory();
    this.trackEvent('editor-save-creation');
  }

  submitForm(): void {
    this.ssmlEditorComponent?.refreshHTML();
    if (this.mode === 'edit') {
      this.saveAudioStory();
    }
    this.trackEvent('editor-generate-creation');
    if (!this.userPermissions.includes('app.studio' || 'app.studio.unlimited')) {
      this.openNoStudioModal();
      return;
    }
    this.bindInnerHTML();
    this.invalidTextItems = this._editorService.isTextItemsInvalid(this.items.value);
    this.invalidAudioItems = this._editorService.isAudioItemsInvalid(this.items.value);
    this.invalidItemsCharacterCount = this._editorService.getItemsCharacterCount(this.items.value);

    if (this.invalidItemsCharacterCount.length === 1) {
      this.characterCountError = true;
      this.changeErrorMessage('ITEM_CHARACTER_LIMIT_ERROR');
      return;
    }
    if (this.invalidItemsCharacterCount.length > 1) {
      this.characterCountError = true;
      this.changeErrorMessage('ITEMS_CHARACTER_LIMIT_ERROR');
      return;
    }
    if (this.invalidTextItems.length === 1) {
      this.changeErrorMessage('DESCRIPTION_REQUIRED');
      return;
    }
    if (this.invalidTextItems.length > 1) {
      this.changeErrorMessage('DESCRIPTIONS_REQUIRED');
      return;
    }
    if (this.invalidAudioItems.length === 1) {
      this.changeErrorMessage('MISSING_AUDIO_FILE');
      return;
    }
    if (this.invalidAudioItems.length > 1) {
      this.changeErrorMessage('MISSING_AUDIO_FILES');
      return;
    }
    if (this.form.voices.value === null) {
      this.changeErrorMessage('NO_VOICE_SELECTED');
      return;
    }
    if (!this.currentAudioStory) {
      this.saveBeforeGenerate();
    } else {
      this.updateAudioStory();
      this.generateAudioStory(this.currentAudioStory.id);
    }
    this.setLastGeneratedVoice();
    this.openAudioStoryGenerationPage();
  }

  setLastGeneratedVoice(): void {
    this.audioStoryMainLang = this.form.voices.value.language.substring(0, 2);
    this._editorService.setLastGeneratedVoice(this.form.voices.value).pipe(takeUntil(this._ngUnsubscribe$)).subscribe();
  }

  openAudioStoryGenerationPage(): void {
    const open = true;
    this._audioStoryStore.dispatch(openAudioStoryOverlay({ payload: { open } }));
  }

  closeAudioStoryGenerationPage(): void {
    const open = false;
    this._audioStoryStore.dispatch(openAudioStoryOverlay({ payload: { open } }));
  }

  mapFormToAudioStory(
    items: any,
    voiceId: number,
    name: string,
    background: { url: string; volume: number },
    translate: boolean = false
  ): AudioStory {
    return this._editorService.mapFormToAudioStory(items, voiceId, name, background, translate);
  }

  generateStoryItemAudio(item): void {
    this.ssmlEditorComponent.refreshHTML();
    this.trackEvent('editor-preview-sound');

    if (this.form.voices.value === null) {
      this.changeItemAudioPreviewError('NO_VOICE_SELECTED');
      return;
    }
    const selectedText = document.querySelector('#editor .selected');
    let articleText = selectedText ? this._editorService.getSelectedText() : item.value.articleText;
    const isArticleTooLong = this._editorService.isArticleTooLong(articleText);
    if (isArticleTooLong) {
      this.changeItemAudioPreviewError('CHARACTERS_LIMIT_ERROR');
      return;
    }
    const commonElement: string = 'i';
    let SSML: string = this._editorService.generateSSML(articleText, commonElement, this._editorService.breakOptions);
    const taglessText: string = this._editorService.removeHTMLElements(SSML);
    let strippedText: string = this._editorService.replaceHtmlEntities(taglessText);

    strippedText = strippedText.replaceAll('></break>', ' />');
    const translate = this.userPermissions.includes('audiostory.translate');
    const voiceIdsUnavailableForTranslation = [20, 40, 41];
    const shouldTranslate = translate ? !voiceIdsUnavailableForTranslation.includes(this.form.voices.value.id) : false;
    const lang = this.selectedItem.value.lang;
    const payload: VoicePreviewPayload = {
      voiceId: this.form.voices.value.id,
      text: strippedText,
      translate: shouldTranslate,
      lang: lang
    };
    this._audioStoryStore.dispatch(generateAudioStoryItem({ payload }));
  }

  toggleDescription(open?: boolean) {
    this.showDescription = open ? open : !this.showDescription;
  }

  clearEditorForm(): void {
    this.items.clear();
    this.selectedItem = null;
    this.editorForm.reset();
    this._flashBriefingStore.dispatch(clearFlashBriefing());
    this._formStore.dispatch(resetForm({ id: 'editorFormID' }));
    this._formStore.dispatch(deleteForm({ id: 'editorFormID' }));
    this._storageService.clearEditorForm('formSync');
    this.currentBackgroundValue = {
      data: {
        translateKey: 'BACKGROUND_MUSIC_NONE'
      }
    };
  }

  resetForm(): void {
    this.clearEditorForm();
    this.items.push(this.createTextItem({}));
  }

  saveCurrentAudioStory() {
    this.changeSuccessMessage('SAVED');
    this._store.pipe(select(selectCurrentAudioStory), takeUntil(this._ngUnsubscribe$)).subscribe((res) => {
      if (res) {
        this.currentAudioStory = res;
        this.updateAudioStory();
      } else {
        this.setCurrentAudioStory();
      }
    });
  }

  setCurrentAudioStory() {
    const translate = this.userPermissions.includes('audiostory.translate');
    if (!this.currentAudioStory) {
      const payload = this.mapFormToAudioStory(
        this.items.value,
        this.form.voices.value?.id,
        this.form.name.value,
        this.form.background.value,
        translate
      );
      this._store.dispatch(loadCurrentAudioStory({ payload }));
    }
  }
  updateAudioStory() {
    const translate = this.userPermissions.includes('audiostory.translate');
    const body = this.mapFormToAudioStory(
      this.items.value,
      this.form.voices.value?.id,
      this.form.name.value,
      this.form.background.value,
      translate
    );
    const id = this.currentAudioStory.id;

    const payload = {
      content: body,
      id: id
    };
    this._store.dispatch(updateAudioStory({ payload }));
  }

  mapAudioStoryToFormValues(audioStory) {
    return this._editorService.mapAudioStoryToFormValues(audioStory);
  }
  showDialog() {
    const DELETE_CONFIRM = 'DELETE_CONFIRM_MESSAGE';
    this._confirmDialogService.confirmThis(
      DELETE_CONFIRM,
      () => {
        this.resetForm();
        this.trackEvent('editor-reset-creation');
      },
      () => {}
    );
  }

  public openChangePronunciationModal(selectedText): void {
    this.selectedText$.next(selectedText);
    this._store.dispatch(openPronunciationModal());
    this._ngxSmartModalService.resetModalData('pronunciationModal');
    this._ngxSmartModalService.getModal('pronunciationModal').open();
    this._ngxSmartModalService.get('pronunciationModal').onClose.subscribe(() => this.clearTextSelection());
  }

  public openVoiceModal(selectedText): void {
    this.selectedText$.next(selectedText);
    this._ngxSmartModalService.getModal('voiceModal').open();
  }

  public openJingleModal(index: number) {
    this.selectStoryItem(index);
    this._ngxSmartModalService.getModal('jingleModal').open();
  }

  public openNoStudioModal() {
    this._ngxSmartModalService.getModal('noStudioModal').open();
  }

  public openMultiImageModal() {
    this._ngxSmartModalService.getModal('multiImage').open();
  }

  public listenSelection(text = ''): void {
    const node = this.parentNode$.getValue();
    const textPayload = node?.nodeName === 'VOICE' ? node.outerHTML : text || this.selectedText$.getValue();
    if (this.form.voices.value === null) {
      this.changeItemAudioPreviewError('NO_VOICE_SELECTED');
      return;
    }
    const payload = {
      voiceId: this.form.voices.value.id,
      text: textPayload
    };
    if (payload.text) {
      this._audioStoryStore.dispatch(generateAudioStoryItem({ payload }));
    }
  }

  updateItemTextToSsml(event: any) {
    const pronunciationEvent = event.pronunciationEvent;
    const elemType = pronunciationEvent.elem.type;
    const elemParam = pronunciationEvent.elem.id || pronunciationEvent.elem.alias;
    pronunciationEvent.elem.caseSensitive = event.caseSensitive;
    if (elemType && elemParam) {
      this.updateEditorElement = { ...pronunciationEvent.elem };
    }
  }

  clearTextSelection() {
    document.getSelection().removeAllRanges();
    this.hostRectangle = null;
    this.selectedText$.next('');
    this.alias$.next('');
  }

  @debounce(100)
  trackEvent(type: string, value: string = null, payload: any = null) {
    this._trackingService
      .trackEvent({ type: type, value: value, payload: payload })
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe();
  }

  itemHasImages(item): boolean {
    if (item.value.images == null) {
      return false;
    }
    if (!item.value.images[0]) {
      return false;
    }
    return !!item.value.images?.length;
  }

  onJingleChange(event) {
    if (event.applyGlobally === true) {
      this.items.value.forEach((item, index) => {
        this.items.at(index).patchValue({ jingle: event.jingle });
      });
    } else if (event.applyGlobally === false) {
      this.selectedItem.patchValue({ jingle: event.jingle });
    }
  }

  listenVoicePreview(event: any) {
    const payload = {
      voiceId: event.voice.id,
      text: event.text || this.selectedText$.getValue()
    };
    if (payload.text) {
      this._audioStoryStore.dispatch(generateAudioStoryItem({ payload }));
    }
  }

  onMultiImageChanges(images: string[]) {
    if (!images || !images.length) return;
    const formArrayImages = this.getImages(this.selectedItem);
    formArrayImages.clear();
    for (const image of images) {
      if (formArrayImages?.value?.length >= this._editorService.maxImagesPerItem) {
        break;
      }
      formArrayImages.push(this._formBuilder.control(image));
    }
    this.updateSelectedItem();
    this.updateFormSyncValue();
  }

  updateSelectedItem() {
    const selectedItemClone = cloneDeep(this.selectedItem);
    this.selectedItem$.next(selectedItemClone);
  }

  patchImagesValues(imageUrl: string) {
    const formArrayImages = this.getImages(this.selectedItem);
    if (formArrayImages?.value?.length >= this._editorService.maxImagesPerItem) return;
    formArrayImages.push(this._formBuilder.control(imageUrl));
    this.updateSelectedItem();
  }
  updateFormSyncValue(): void {
    this._formStore.dispatch(setForm({ id: 'editorFormID', value: this.editorForm.value }));
  }

  handleEditorSsml(ssml) {
    this.ssmlText = ssml;
  }

  handleCurrentValue(currentValue) {
    if (this.selectedItem && currentValue) {
      if (currentValue === '!#empty#!') {
        this.selectedItem.patchValue({ articleText: '' });
      } else {
        this.selectedItem.patchValue({ articleText: currentValue });
      }
    }
  }

  updateLexicon(lexicon: Lexicon[]) {
    this.lexicons = lexicon;
  }

  patchBackgroundValue(url: string) {
    const data = {
      url,
      volume: 0.03
    };
    const backgroundPayload = url ? data : null;
    this.form.background.patchValue(backgroundPayload);
  }

  setSelectedTextParentNodeName(event: string) {
    this.parentNodeName$.next(event);
  }
  ngOnDestroy(): void {
    clearTimeout(this.timer);
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
