<div class="container pref-page">
  <div class="row">
    <div class="col-lg-10 text-center">
      <h2>{{ 'PREFERENCES' | translate }}</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-10">
      <h3>{{ 'LANGUAGES' | translate }}</h3>
      <div class="item-box">
        <h4>{{ 'PREFERENCES_SELECT_INTERFACE_LANGUAGE' | translate }}</h4>
        <form [formGroup]="languageForm">
          <div class="form-check form-check-inline">
            <input
              formControlName="interfaceLang"
              value="fr"
              class="form-check-input"
              type="radio"
              name="interfaceLang"
              id="fr"
            />
            <label class="form-check-label" for="fr">{{ 'PREFERENCES_FRENCH' | translate }}</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              formControlName="interfaceLang"
              value="en"
              class="form-check-input"
              type="radio"
              name="interfaceLang"
              id="en"
            />
            <label class="form-check-label" for="en">{{ 'PREFERENCES_ENGLISH' | translate }}</label>
          </div>
          <div></div>
        </form>
      </div>
      <div>
        <h3>{{ 'LEGAL_NOTICE' | translate }}</h3>
        <div class="item-box">
          <div><img src="../assets/images/logos/logo-etx-multicolor.png" /></div>
          <span>{{ version }}</span>
          <p class="copyright-etx">Copyright © 2020 ETX STUDIO - ETX Daily Up</p>
          <a (click)="openCguCgvModal()">{{ 'ACCESS_CGV' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>

<app-cgu-fr-modal></app-cgu-fr-modal>
<app-cgu-en-modal></app-cgu-en-modal>
