import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as PlaylistActions from './playlist.actions';
import { PlaylistEntity } from './playlist.models';

export const PLAYLIST_FEATURE_KEY = 'playlist';

export interface State extends EntityState<PlaylistEntity> {
  selectedId?: string | number; // which Playlist record has been selected
  loaded: boolean; // has the Playlist list been loaded
  error?: string | null; // last known error (if any)
  articles?: any;
  showPlaylist: boolean;
  currentArticlePlayingIndex?: number;
}

export interface PlaylistState {
  readonly [PLAYLIST_FEATURE_KEY]: State;
}

export const playlistAdapter: EntityAdapter<PlaylistEntity> = createEntityAdapter<PlaylistEntity>();

export const initialState: State = playlistAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  showPlaylist: false
});

const playlistReducer = createReducer(
  initialState,
  on(PlaylistActions.loadPlaylist, (state, action) => {
    return {
      ...state,
      articles: action.articles,
      showPlaylist: true
    };
  }),
  on(PlaylistActions.loadPlaylistSuccess, (state, { playlist }) =>
    playlistAdapter.setAll(playlist, { ...state, loaded: true })
  ),
  on(PlaylistActions.loadPlaylistFailure, (state, { error }) => ({ ...state, error })),
  on(PlaylistActions.removePlaylistItem, (state, { id }) => {
    return {
      ...state,
      articles: state.articles.filter((article) => article.id !== id)
    };
  }),
  on(PlaylistActions.loadCurrentPlayingArticleIndex, (state, { index }) => ({
    ...state,
    currentArticlePlayingIndex: index
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return playlistReducer(state, action);
}
