import { createSelector } from '@ngrx/store';
import * as fromAudioStoryReducer from '../reducers/audio-story.reducers';
import * as moment from 'moment';
import { orderBy } from 'lodash-es';

export const selectAllAudioStories = fromAudioStoryReducer.selectAll;

export const selectLastAudioStories = createSelector(fromAudioStoryReducer.selectAll, (stories) => {
  const sortedStories = orderBy(
    stories,
    (o: any) => {
      return moment(o.updated_at).format('YYYYMMDD');
    },
    ['asc']
  );
  return sortedStories.slice(0, 3);
});

export const selectGenerationError = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.generationError
);

export const selectLoadingAudioStories = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.isLoading
);

export const selectGenerationLoading = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.generationLoading
);
export const selectGenerationLoaded = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.generationLoaded
);

export const selectAudioStoryPlaying = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.playing
);

export const selectCurrentGeneratedArticleId = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.currentGeneratedArticleId
);

export const getCurrentStoryItem = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.currentStoryItem
);

export const selectGeneratedStoryItemUrl = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.generatedStoryItemUrl
);

export const selectOpenAudioStoryOverlay = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.openAudioStoryOverlay
);

export const selectError = createSelector(fromAudioStoryReducer.selectAudioStoryState, (state) => state.error);

export const selectAudioStatus = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.audioStatus
);

export const selectAudioFormat = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.audioFormatUrls
);
export const selectMobileFormatStatus = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.mobileFormatStatus
);

export const selectMobileFormatUrls = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.mobileFormatUrls
);
export const selectSquareFormatStatus = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.squareFormatStatus
);
export const selectSquareFormatUrls = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.squareFormatUrls
);

export const selectLandscapeFormatStatus = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.landscapeFormatStatus
);

export const selectLandscapeFormatUrls = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.landscapeFormatUrls
);

export const selectAudioStoriesUpdateStatus = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.isAudioStoryPageUpdated
);

export const selectLastPage = createSelector(fromAudioStoryReducer.selectAudioStoryState, (state) => state.lastPage);

export const selectTotalAudioStories = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.totalAudioStories
);

export const selectVoicePreviewError = createSelector(
  fromAudioStoryReducer.selectAudioStoryState,
  (state) => state.voicePreviewError
);
