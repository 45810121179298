import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EtxIconsRegistryService } from '../../../../../../shared/src/lib/components/etx-icons/etx-icons-registry.service';
import { etxIconUploadSound } from '../../../../../../shared/src/lib/components/etx-icons/etx-icons.model';
import { EditorState, selectUploadedAudio } from '@purplefront/editor/data-access';
import { select, Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { InlineWorker } from '../../../../../utils/src/lib/classes/inlineworker.class';

declare var lamejs;

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploadComponent,
      multi: true
    }
  ]
})
export class FileUploadComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() supportedFileFormat: string[];
  @Input() selectedAudioItem: any;
  @Input() label: string;
  @Input() selectedItemImageUrl: string;
  @Output() submit$ = new EventEmitter<File>();
  @Output() deleteAudio$ = new EventEmitter<File>();
  fileNameFromUrl: string;
  onChange: Function;
  file: File | null = null;
  isLoading: boolean;
  audioBuffer: any;

  @HostListener('change', ['$event.target.files'])
  async emitFiles(event: FileList) {
    this.file = event && event.item(0);
    if (this.file?.name.endsWith('.wav') && this.file?.type === 'audio/wav') {
      this.isLoading = true;
      this.audioBuffer = await this.fileToAudioBuffer(this.file);
      this.initWebWorker(this.audioBuffer, this.file.name);
      this.isLoading = false;
    }
    this.onChange(this.file);
    if (this.file) {
      this.submit$.emit(this.file);
    }
  }

  constructor(
    private _editorStore: Store<EditorState>,
    private host: ElementRef<HTMLInputElement>,
    private _etxIconsRegistry: EtxIconsRegistryService
  ) {
    this._etxIconsRegistry.registerIcons([etxIconUploadSound]);
  }

  ngOnInit() {
    this.setFilename();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.selectedAudioItem &&
      changes.selectedAudioItem.previousValue &&
      changes.selectedAudioItem.previousValue !== changes.selectedAudioItem.currentValue
    ) {
      this.setFilename();
    }
  }

  setFilename() {
    if (this.selectedAudioItem?.value.audio) {
      const url = this.selectedAudioItem.value.audio;
      this.fileNameFromUrl = url.substring(url.lastIndexOf('/') + 1);
      this.file = null;
    } else {
      this.fileNameFromUrl = null;
      this.file = null;
    }
  }

  writeValue(value: null) {
    // clear file input
    this.host.nativeElement.value = '';
  }

  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function) {}

  deleteAudio() {
    this._editorStore.pipe(select(selectUploadedAudio), first()).subscribe((audio) => {
      this.file = null;
      if (audio && audio.data) {
        const id = audio.data.id;
        this.deleteAudio$.emit(id);
      } else {
        this.deleteAudio$.emit(null);
      }
    });
  }

  initWebWorker(audioBuffer: any, fileName: string) {
    const worker = new InlineWorker(() => {
      // START OF WORKER THREAD CODE
      console.log('Start worker thread, wait for postMessage: ');
      const audioBufferToWav = (audioBuffer: any, fileName: string) => {
        // @ts-ignore
        self.importScripts('https://cdnjs.cloudflare.com/ajax/libs/lamejs/1.2.1/lame.all.js');
        const wavToMp3 = (channels, sampleRate, left, right = null) => {
          let mp3buf;
          const buffer = [];
          const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
          let remaining = left.length;
          const samplesPerFrame = 1152;

          for (let i = 0; remaining >= samplesPerFrame; i += samplesPerFrame) {
            if (!right) {
              const mono = left.subarray(i, i + samplesPerFrame);
              mp3buf = mp3enc.encodeBuffer(mono);
            } else {
              const leftChunk = left.subarray(i, i + samplesPerFrame);
              const rightChunk = right.subarray(i, i + samplesPerFrame);
              mp3buf = mp3enc.encodeBuffer(leftChunk, rightChunk);
            }
            if (mp3buf.length > 0) {
              buffer.push(mp3buf); //new Int8Array(mp3buf));
            }
            remaining -= samplesPerFrame;
          }
          const d = mp3enc.flush();
          if (d.length > 0) {
            buffer.push(new Int8Array(d));
          }

          const mp3Blob = new Blob(buffer, { type: 'audio/mp3' });
          let mp3File = new File([mp3Blob], fileName.replace('.wav', '.mp3'), { type: 'audio/mp3' });
          // this is from DedicatedWorkerGlobalScope ( because of that we have postMessage and onmessage methods )
          // and it can't see methods of this class
          // @ts-ignore
          self.postMessage({
            encodedFile: mp3File
          });
          return mp3File;
        };

        const setUint16 = (data) => {
          btwView.setUint16(btwPos, data, true);
          btwPos += 2;
        };

        const setUint32 = (data) => {
          btwView.setUint32(btwPos, data, true);
          btwPos += 4;
        };

        let numOfChan = audioBuffer.numberOfChannels,
          btwLength = audioBuffer.length * numOfChan * 2 + 44,
          btwArrBuff = new ArrayBuffer(btwLength),
          btwView = new DataView(btwArrBuff),
          btwChnls = [],
          btwIndex,
          btwSample,
          btwOffset = 0,
          btwPos = 0;
        setUint32(0x46464952); // "RIFF"
        setUint32(btwLength - 8); // file length - 8
        setUint32(0x45564157); // "WAVE"
        setUint32(0x20746d66); // "fmt " chunk
        setUint32(16); // length = 16
        setUint16(1); // PCM (uncompressed)
        setUint16(numOfChan);
        setUint32(audioBuffer.sampleRate);
        setUint32(audioBuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
        setUint16(numOfChan * 2); // block-align
        setUint16(16); // 16-bit
        setUint32(0x61746164); // "data" - chunk
        setUint32(btwLength - btwPos - 4); // chunk length
        btwChnls = audioBuffer.btwChnls;
        // for (btwIndex = 0; btwIndex < audioBuffer.numberOfChannels; btwIndex++)
        //   //add this data to my clone object
        //   btwChnls.push(audioBuffer.getChannelData(btwIndex));

        while (btwPos < btwLength) {
          for (btwIndex = 0; btwIndex < numOfChan; btwIndex++) {
            // interleave btwChnls
            btwSample = Math.max(-1, Math.min(1, btwChnls[btwIndex][btwOffset])); // clamp
            btwSample = (0.5 + btwSample < 0 ? btwSample * 32768 : btwSample * 32767) | 0; // scale to 16-bit signed int
            btwView.setInt16(btwPos, btwSample, true); // write 16-bit sample
            btwPos += 2;
          }
          btwOffset++; // next source sample
        }

        let wavHdr = lamejs.WavHeader.readHeader(new DataView(btwArrBuff));
        let wavSamples = new Int16Array(btwArrBuff, wavHdr.dataOffset, wavHdr.dataLen / 2);

        let data = new Int16Array(btwArrBuff, wavHdr.dataOffset, wavHdr.dataLen / 2);
        let leftData = [];
        let rightData = [];
        for (let i = 0; i < data.length; i += 2) {
          leftData.push(data[i]);
          rightData.push(data[i + 1]);
        }
        let left = new Int16Array(leftData);
        let right = new Int16Array(rightData);

        //STEREO
        if (wavHdr.channels === 2) return wavToMp3(wavHdr.channels, wavHdr.sampleRate, left, right);
        //MONO
        else if (wavHdr.channels === 1) return wavToMp3(wavHdr.channels, wavHdr.sampleRate, data);
      };

      // @ts-ignore
      const onmessage = (evt) => {
        console.log('Calculation started: ' + new Date());
        audioBufferToWav(evt.data.audioBuffer, evt.data.fileName);
      };
      // END OF WORKER THREAD CODE
    });

    worker.postMessage({ audioBuffer: this.audioBuffer, fileName: fileName });

    worker.onmessage().subscribe((event) => {
      console.log('Calculation done: ', new Date() + ' ' + event.data);
      this.file = event.data.encodedFile;
      worker.terminate();
    });

    worker.onerror().subscribe((data) => {
      console.log(data);
    });
  }

  async fileToAudioBuffer(file) {
    const arrayBuffer = await file.arrayBuffer();
    const audioContext = new AudioContext();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    let btwChnls = [];

    for (let btwIndex = 0; btwIndex < audioBuffer.numberOfChannels; btwIndex++)
      //add this data to my clone object
      btwChnls.push(audioBuffer.getChannelData(btwIndex));
    const audioBufferClone = {
      duration: audioBuffer.duration,
      length: audioBuffer.length,
      numberOfChannels: audioBuffer.numberOfChannels,
      sampleRate: audioBuffer.sampleRate,
      btwChnls: btwChnls
    };
    return audioBufferClone;
  }
}
