import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { uniqBy } from 'lodash-es';

import * as FlashBriefingActions from './flash-briefing.actions';
import { FlashBriefingEntity } from './flash-briefing.models';

export const FLASHBRIEFING_FEATURE_KEY = 'flashBriefing';

export interface State extends EntityState<FlashBriefingEntity> {
  selectedId?: string | number; // which FlashBriefing record has been selected
  loaded: boolean; // has the FlashBriefing list been loaded
  error?: string | null; // last known error (if any)
  articles?: any | null;
  menuToggled: boolean;
  title: string;
  navigatedFromFlashBriefing?: boolean;
}

export interface FlashBriefingPartialState {
  readonly [FLASHBRIEFING_FEATURE_KEY]: State;
}

export const flashBriefingAdapter: EntityAdapter<FlashBriefingEntity> = createEntityAdapter<FlashBriefingEntity>();

export const initialState: State = flashBriefingAdapter.getInitialState({
  loaded: false,
  menuToggled: false,
  articles: [],
  title: null
});

const flashBriefingReducer = createReducer(
  initialState,
  on(FlashBriefingActions.loadFlashBriefing, (state) => ({ ...state, loaded: false, error: null })),
  on(FlashBriefingActions.loadFlashBriefingSuccess, (state, { flashBriefing }) =>
    flashBriefingAdapter.setAll(flashBriefing, { ...state, loaded: true })
  ),
  on(FlashBriefingActions.loadFlashBriefingFailure, (state, { error }) => ({ ...state, error })),
  on(FlashBriefingActions.addSelectFlashBriefing, (state, { article }) => {
    const _articles = [...state.articles, article];
    return {
      ...state,
      articles: uniqBy(_articles, (a) => a.id)
    };
  }),
  on(FlashBriefingActions.toggleFlashSelectMenu, (state) => ({ ...state, menuToggled: !state.menuToggled })),
  on(FlashBriefingActions.removeSelectFlashBriefing, (state, { id }) => ({
    ...state,
    articles: state.articles.filter((article) => article.id !== id)
  })),
  on(FlashBriefingActions.clearFlashBriefing, (state) => ({ ...state, articles: [] })),
  on(FlashBriefingActions.navigatedFromFlashBriefing, (state, action) => ({
    ...state,
    navigatedFromFlashBriefing: action.navigationFromFlashBriefing
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return flashBriefingReducer(state, action);
}
