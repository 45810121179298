import { createAction, props } from '@ngrx/store';
import { FlashBriefingEntity } from './flash-briefing.models';

export const loadFlashBriefing = createAction('[FlashBriefing] Load FlashBriefing');

export const loadFlashBriefingSuccess = createAction(
  '[FlashBriefing] Load FlashBriefing Success',
  props<{ flashBriefing: FlashBriefingEntity[] }>()
);

export const loadFlashBriefingFailure = createAction(
  '[FlashBriefing] Load FlashBriefing Failure',
  props<{ error: any }>()
);

export const addSelectFlashBriefing = createAction('[FlashBriefing] Add flash briefing', props<{ article: any }>());

export const clearFlashBriefing = createAction('[FlashBriefing] Clear all flash briefing articles');

export const removeSelectFlashBriefing = createAction('[FlashBriefing] Remove flash briefing', props<{ id: string }>());

export const toggleFlashSelectMenu = createAction('[FlashBriefing] Toggle Select Menu');

export const navigatedFromFlashBriefing = createAction(
  '[FlashBriefing] Navigated from flash briefing',
  props<{ navigationFromFlashBriefing: boolean }>()
);
