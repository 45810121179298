import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@purplefront/shared';
import { BroadcastMessage } from '../models/broadcast-message.model';

@Injectable({
  providedIn: 'root'
})
export class InfoBannerService {
  readonly apiUrl = environment.purpleApi;
  readonly endpoint = '/broadcast';
  constructor(private _http: HttpClient) {}

  getMessage(): Observable<BroadcastMessage[]> {
    const url = this.apiUrl + this.endpoint;
    return this._http.get<BroadcastMessage[]>(url).pipe(map((res) => res['data']));
  }
}
