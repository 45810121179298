import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CalendarEffects } from './_store/effects';
import { CalendarReducer } from './_store/reducers/';
import { CalendarApi } from './_api';
import { FullCalendarModule } from '@fullcalendar/angular';

@NgModule({
  imports: [
    CommonModule,
    FullCalendarModule,
    StoreModule.forFeature('calendar', CalendarReducer),
    EffectsModule.forFeature([CalendarEffects])
  ],
  providers: [CalendarApi]
})
export class CalendarDataAccessModule {}
