import { Injectable } from '@angular/core';
import { environment } from '../environments';
import { Langs } from '../_models';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { PreferencesState, selectInterfaceLang } from '@purplefront/preferences/data-access';
import { filter, map } from 'rxjs/operators';
import { AuthState } from '../../../../auth/data-access/src/lib/_store/reducers/auth.reducer';
import { getUserId } from '../../../../auth/data-access/src/lib/_store/selectors/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private _apiTrackingUrl: string = environment.purpleApi + '/events/project/relax/event';
  private _userId: number;
  private _interfaceLang: Langs;

  constructor(
    private _http: HttpClient,
    private _prefStore: Store<PreferencesState>,
    private _authStore: Store<AuthState>
  ) {
    this.getInterfaceLang();
    this.getUserId();
  }

  trackEvent({ value, type, payload }: TrackingParameters): Observable<any> {
    const body = {
      userid: this._userId || '',
      lang: this._interfaceLang || '',
      value: value || '',
      project: 'dailyUP',
      type,
      payload
    };

    return this._http.post<any>(this._apiTrackingUrl, body);
  }

  getInterfaceLang(): void {
    this._prefStore
      .pipe(
        select(selectInterfaceLang),
        filter((lang) => lang !== null),
        map((lang: Langs) => (this._interfaceLang = lang))
      )
      .subscribe();
  }

  getUserId(): void {
    this._authStore
      .pipe(
        select(getUserId),
        map((id: number) => (this._userId = id))
      )
      .subscribe();
  }
}

interface TrackingParameters {
  value?: string;
  type: string;
  payload?: any;
}
