import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@purplefront/shared';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { merge, uniq } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class SearchApiService {
  private apiUrl: string = environment.purpleApi;
  private _nbFeeds = 14;

  constructor(private http: HttpClient) {}

  getAutocompleteSuggestions(search: string, size: number, lang: string, feedSlugs): Observable<any> {
    let params = new HttpParams();
    let suggestParams = new HttpParams();

    const completeUrl = `${this.apiUrl}/content/complete`;
    const suggestUrl = `${this.apiUrl}/content/suggest`;

    if (search) {
      params = params.append('q', search.trim());
      suggestParams = suggestParams.append('q', search.trim());
    }
    if (!search) {
      suggestParams = suggestParams.append('to', 'now');
      suggestParams = suggestParams.append('from', 'now-4w');
    }
    params = params.append('size', size.toString());
    params = params.append('lang', lang);

    suggestParams = suggestParams.append('size', size.toString());
    suggestParams = suggestParams.append('lang', lang);

    // add filters[feeds][] params only for others feeds
    if (feedSlugs.length < this._nbFeeds) {
      feedSlugs.forEach((feedSlug) => {
        if (feedSlug !== 'all') {
          //options.params['filters[feeds][]'] = feed;
          const key = `filters[feeds][ ${feedSlug}]`;
          suggestParams = suggestParams.append(key, feedSlug);
        }
      });
    }

    const completeRequest = this.http.get<any>(completeUrl, { params: params });
    const suggestRequest = this.http.get<any>(suggestUrl, { params: suggestParams });
    return forkJoin([completeRequest, suggestRequest]).pipe(
      map((res) => {
        const completeResponse = { keywords: uniq(res[0]['data']) };
        return merge(completeResponse, res[1]['data']);
      })
    );
  }
}
