import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { Observable, of as observableOf, Subject } from 'rxjs';

import { select, Store } from '@ngrx/store';
import { AuthState, isLogged, isAuthLoading, login, logout, loginErrorMsg } from '@purplefront/auth/data-access';
import { TranslateService } from '@ngx-translate/core';
import { Langs, TrackingService } from '@purplefront/shared';
import { AppRouterFacadeService } from '@purplefront/app-router/data-access';
import { etxIconLanguage } from '../../../../../../shared/src/lib/components/etx-icons/etx-icons.model';
import { EtxIconsRegistryService } from '../../../../../../shared/src/lib/components/etx-icons/etx-icons-registry.service';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './login.container.html',
  styleUrls: ['./login.container.scss']
})
export class LoginContainer implements OnInit, OnDestroy {
  public email: string;
  public password: string;
  public loginErrorMsg$: Observable<string>;
  public authTab = 'sign-in';
  /**
   * True if the authentication is loading.
   * @type {boolean}
   */
  public loading$: Observable<boolean>;

  /**
   * True if the authentication is logged.
   * @type {boolean}
   */
  public logged$: Observable<boolean>;

  /**
   * Interface Lang
   */
  public interfaceLang: Langs;
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private authStore: Store<AuthState>,
    private _trackingService: TrackingService,
    private _translate: TranslateService,
    private _appRouterFacade: AppRouterFacadeService,
    private _etxIconsRegistry: EtxIconsRegistryService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this._etxIconsRegistry.registerIcons([etxIconLanguage]);
  }

  ngOnInit() {
    this._initLang();
    this.initTab();
    // set loading
    this.loading$ = this.authStore.pipe(select(isAuthLoading));

    // set loaded
    this.logged$ = this.authStore.pipe(select(isLogged));

    this.loginErrorMsg$ = this.authStore.pipe(select(loginErrorMsg));
  }

  initTab() {
    this._route.queryParams.subscribe((params) => {
      const fromUrlParams = params['fromUrl'];

      if (fromUrlParams && fromUrlParams.indexOf('sign-up') > -1) {
        this.authTab = 'sign-up';
        this._router.navigate(['auth/login'], { queryParams: { fromUrl: fromUrlParams } });
      } else {
        this.authTab = 'sign-in';
        this._router.navigate(['auth/login'], { queryParams: { fromUrl: fromUrlParams } });
      }
    });
  }

  public login(): void {
    this._trackingService.trackEvent({ type: 'auth-login' }).pipe(takeUntil(this._ngUnsubscribe$)).subscribe();
    /*
     * Set payload
     */
    const payload = {
      username: this.email.trim(),
      password: this.password.trim()
    };

    this.authStore.dispatch(login({ payload: payload }));
  }

  public logout(): Observable<boolean> {
    this.authStore.dispatch(logout());
    return observableOf(false);
  }

  /**
   * Init default interface lang
   * @private
   */
  private _initLang() {
    const lang: Langs = <Langs>this._translate.getBrowserLang();
    if (lang === 'fr') {
      this.interfaceLang = 'fr';
      this._translate.use(this.interfaceLang);
    } else {
      this.interfaceLang = 'en';
      this._translate.use(this.interfaceLang);
    }
  }

  /**
   * Change interfaceLang
   * @param lang
   */
  public changeInterfaceLang(lang: Langs) {
    this.interfaceLang = lang;
    this._translate.use(this.interfaceLang);
  }

  setAuthTab(tabId: string) {
    this.authTab = tabId;
    this._router.navigate(['auth/login'], { queryParams: { fromUrl: tabId } });
  }

  isSetAuthTab(tabId: string) {
    return this.authTab === tabId;
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
