<div class="suggest-list" id="header-search-suggest-list" #headerSuggestList>
  <div class="row">
    <div class="col-12" *ngIf="searchLoaded">
      <ul class="list-inline" *ngIf="searchResponse['keywords'] && searchResponse['keywords'].length">
        <h3>{{ 'SEARCH_KEYWORDS' | translate }}</h3>
        <li
          #listItem
          *ngFor="let keywords of searchResponse['keywords']"
          (click)="searchSuggestQuery(keywords, $event, 'keywords')"
          [ngClass]="{ active: listItem['selected'] }"
          [innerHTML]="keywords | highlighted: searchString"
          class="list-inline-item"
        ></li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-12" *ngIf="searchLoaded">
      <ul class="list-inline" *ngIf="searchResponse['people'] && searchResponse['people'].length">
        <h3>{{ 'SEARCH_PEOPLE' | translate }}</h3>
        <li
          #listItem
          *ngFor="let suggestion of searchResponse['people']"
          (click)="searchSuggestQuery(suggestion['name'], $event, 'people')"
          [ngClass]="{ active: listItem['selected'] }"
          [innerHTML]="suggestion['name'] | highlighted: searchString"
          class="list-inline-item"
        ></li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-12" *ngIf="searchLoaded">
      <ul class="list-inline" *ngIf="searchResponse['brands'] && searchResponse['brands'].length">
        <h3>{{ 'SEARCH_BRANDS' | translate }}</h3>
        <li
          #listItem
          *ngFor="let suggestion of searchResponse['brands']"
          (click)="searchSuggestQuery(suggestion['name'], $event, 'brands')"
          [ngClass]="{ active: listItem['selected'] }"
          [innerHTML]="suggestion['name'] | highlighted: searchString"
          class="list-inline-item"
        ></li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-12" *ngIf="searchLoaded">
      <ul class="list-inline" *ngIf="searchResponse['terms'] && searchResponse['terms'].length">
        <h3>{{ 'SEARCH_TERMS' | translate }}</h3>
        <li
          #listItem
          *ngFor="let suggestion of searchResponse['terms']"
          (click)="searchSuggestQuery(suggestion['name'], $event, 'terms')"
          [ngClass]="{ active: listItem['selected'] }"
          [innerHTML]="suggestion['name'] | highlighted: searchString"
          class="list-inline-item"
        ></li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-12" *ngIf="searchLoaded">
      <ul class="list-inline" *ngIf="searchResponse['categories'] && searchResponse['categories'].length">
        <h3>{{ 'SEARCH_CATEGORIES' | translate }}</h3>
        <li
          #listItem
          *ngFor="let suggestion of searchResponse['categories']"
          (click)="searchSuggestQuery(suggestion['name'], $event, 'categories')"
          [ngClass]="{ active: listItem['selected'] }"
          [innerHTML]="suggestion['name'] | highlighted: searchString"
          class="list-inline-item"
        ></li>
      </ul>
    </div>
  </div>
  <app-ghosts-elements *ngIf="!searchLoaded" [nbElements]="nbElements"></app-ghosts-elements>
</div>
