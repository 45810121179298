<ngx-smart-modal identifier="help-modal" #helpModal target="li#help" class="help-modal">
  <div
    class="help-container flex flex-column align-items-center justify-content-center pt-4 pe-4 ps-4 pb-2 text-center"
    tabindex="-1"
    role="dialog"
  >
    <div class="help-body mb-4">
      <h2 class="my-3">{{ 'NEED_HELP' | translate }}</h2>
      <div class="d-flex mb-4">
        <iframe
          class="d-none d-sm-block"
          src="https://player.vimeo.com/video/502616902"
          width="475"
          height="270"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
        ></iframe>
      </div>

      <h6 class="text-start mb-3 pt-3 ms-5">
        <a class="no-style-link" target="_blank" [href]="userGuideURL"
          ><span class="icon"><i class="fa fa-map-o" aria-hidden="true"></i></span
          >{{ 'DOWNLOAD_USER_GUIDE' | translate }}</a
        >
      </h6>

      <h6 (click)="startGuidedTour(); helpModal.close()" class="text-start mb-3 ms-5 c-pointer">
        <span class="icon"><i class="fa fa-map-signs" aria-hidden="true"></i></span>
        {{ 'SEE_GUIDED_TOUR' | translate }}
      </h6>
      <div class="help-footer text-start ms-5">
        <span class="icon"><i class="fa fa-envelope-o" aria-hidden="true"></i></span>
        <h6 class="text-start">Help:</h6>
        <a class="text-start no-style-link ps-1" href="mailto:contact@etxstudio.com">contact@etxstudio.com</a>
      </div>
    </div>
  </div>
</ngx-smart-modal>
