import * as actions from '../actions/article-modal.actions';
import { createReducer, on } from '@ngrx/store';

export interface ArticleModalState {
  articleId: string;
  activeTab: string;
  article: any;
  moreLikeArticles: any;
  relatedArticles: any;
  latestArticles: any;
  modal: boolean;
  loading: boolean;
  loaded: boolean;
  loadingMoreLikeArticles: boolean;
  loadedMoreLikeArticles: boolean;
  loadingRelatedArticles: boolean;
  loadedRelatedArticles: boolean;
}

export const initialState: ArticleModalState = {
  articleId: null,
  activeTab: 'article',
  article: {},
  moreLikeArticles: [],
  relatedArticles: [],
  latestArticles: [],
  modal: false,
  loading: false,
  loaded: false,
  loadingMoreLikeArticles: false,
  loadedMoreLikeArticles: false,
  loadingRelatedArticles: false,
  loadedRelatedArticles: false
};

const articleReducer = createReducer(
  initialState,
  on(actions.openArticleModal, (state, action) => {
    const articleId = action.payload.articleId;
    return {
      ...state,
      articleId: articleId
    };
  }),
  on(actions.closeArticleModal, (state, action) => {
    const articleId = action.payload.articleId;
    return {
      ...state,
      articleId: articleId
    };
  }),
  on(actions.displayModalTab, (state, action) => {
    const activeTab = action.payload.activeTab;
    return {
      ...state,
      activeTab: activeTab
    };
  }),
  on(actions.getArticle, (state, action) => {
    return {
      ...state,
      loading: true,
      loaded: false
    };
  }),
  on(actions.getArticleSuccess, (state, action) => {
    const article = action.payload.article;
    return {
      ...state,
      article: article,
      articleId: article.id,
      loading: false,
      loaded: true
    };
  }),
  on(actions.getMoreLikeArticlesSuccess, (state, action) => {
    const moreLikeArticles = action.payload.moreLikeArticles;
    return {
      ...state,
      moreLikeArticles: moreLikeArticles,
      loadingMoreLikeArticles: false,
      loadedMoreLikeArticles: true
    };
  }),
  on(actions.getRelatedArticlesSuccess, (state, action) => {
    const relatedArticles = action.payload.relatedArticles;
    return {
      ...state,
      relatedArticles: relatedArticles,
      loadingRelatedArticles: false,
      loadedRelatedArticles: true
    };
  }),
  on(actions.getLatestArticlesSuccess, (state, action) => {
    return {
      ...state,
      latestArticles: action.articles
    };
  })
);

export function reducer(state: ArticleModalState, action: actions.ArticleModalActionsUnion) {
  return articleReducer(state, action);
}
