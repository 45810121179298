import { Injectable } from '@angular/core';
import {
  deleteUploadedFileAudio,
  deleteUploadedFileAudioFail,
  deleteUploadedFileAudioSuccess,
  EditorActionsUnion,
  loadCurrentAudioStory,
  loadCurrentAudioStoryFail,
  loadCurrentAudioStorySuccess,
  loadTranslatableLang,
  loadTranslatableLangFail,
  loadTranslatableLangSuccess,
  loadVoices,
  loadVoicesFail,
  loadVoicesSuccess,
  setPlyrAudioSource,
  updateCurrentAudioStory,
  updateCurrentAudioStoryFail,
  updateCurrentAudioStorySuccess
} from '../actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AudioStoryApi, AudioStoryState, playPauseAudio } from '@purplefront/audio-story/data-access';
import { EditorService } from '../../services';
import { UploadService } from '@purplefront/shared';
import { TranslatableLang } from '../../_models/translatable-lang.model';

@Injectable()
export class EditorEffects {
  constructor(
    private _actions$: Actions<EditorActionsUnion>,
    private _audioStoryStore: Store<AudioStoryState>,
    private _audioStoryApi: AudioStoryApi,
    private _editorService: EditorService,
    private _uploadService: UploadService
  ) {}

  loadVoices$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadVoices),
      switchMap(() =>
        this._editorService.getVoices().pipe(
          map((res) => loadVoicesSuccess({ payload: { voices: res } })),
          catchError((err) => of(loadVoicesFail({ payload: { error: err } })))
        )
      )
    )
  );

  loadCurrentAudioStory$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadCurrentAudioStory),
      switchMap((action) =>
        this._audioStoryApi.addAudioStory(action.payload).pipe(
          map((res) => loadCurrentAudioStorySuccess({ payload: res })),
          catchError((err) => of(loadCurrentAudioStoryFail({ error: err })))
        )
      )
    )
  );

  updateCurrentAudioStory$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateCurrentAudioStory),
      switchMap((action) =>
        this._audioStoryApi.editAudioStory(action.payload.data, action.payload.id).pipe(
          map((res) => updateCurrentAudioStorySuccess({ payload: res.data })),
          catchError((err) => of(updateCurrentAudioStoryFail({ error: err })))
        )
      )
    )
  );

  deletedAudio$ = createEffect(() =>
    this._actions$.pipe(
      ofType(deleteUploadedFileAudio),
      map((action) => action.payload.id),
      switchMap((id) =>
        this._uploadService.deleteUploadedFileAudio(id).pipe(
          map((res) => deleteUploadedFileAudioSuccess()),
          catchError((error) => of(deleteUploadedFileAudioFail({ payload: { error } })))
        )
      )
    )
  );

  loadTranslatableLangs$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadTranslatableLang),
      switchMap((action) =>
        this._editorService.getTranslatableLangs().pipe(
          map((res: TranslatableLang[]) => loadTranslatableLangSuccess({ translatableLangs: res })),
          catchError((err) => of(loadTranslatableLangFail({ error: err })))
        )
      )
    )
  );

  setPlyrAudioSource$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(setPlyrAudioSource),
        map((action) => {
          return this._audioStoryStore.dispatch(playPauseAudio({ payload: { playing: true } }));
        })
      ),
    { dispatch: false }
  );
}
