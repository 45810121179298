import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-audio-augmented',
  templateUrl: './home-audio-augmented.component.html',
  styleUrls: ['./home-audio-augmented.component.scss']
})
export class HomeAudioAugmentedComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
