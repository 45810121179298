import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { audioStoryReducer } from './_store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { AudioStoryEffects } from './_store/effects';
import { AudioStoryApi } from './_api';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('audioStory', audioStoryReducer),
    EffectsModule.forFeature([AudioStoryEffects])
  ],
  providers: [AudioStoryApi]
})
export class AudioStoryDataAccessModule {}
