export const environment = {
  production: true,
  name: 'prod',
  relaxApiUrl: 'https://myedito-apirelax-dev.relaxnews.com/v2',
  searchApiUrl: 'https://myedito-apisearch-dev.relaxnews.com/v2',
  purpleApi: 'https://samba.etx.studio/api/v1',
  baseAPI: 'https://samba.etx.studio',
  dailyUpUrl: 'https://dailyup.etxstudio.com',
  baseUrl: {
    purple: 'editorializer',
    mobisation: 'mobisation'
  }
};
