import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromAuthStore from '@purplefront/auth/data-access';
import { AuthService } from '@purplefront/auth/data-access';
import { ActivatedRoute } from '@angular/router';
import { Langs, TrackingService } from '@purplefront/shared';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public resetPasswordForm: FormGroup;
  private _email: string;
  private _token: string;
  public interfaceLang: Langs;
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private _fb: FormBuilder,
    private _store: Store<fromAuthStore.AuthState>,
    private _authService: AuthService,
    private _route: ActivatedRoute,
    private _translate: TranslateService,
    private _trackingService: TrackingService
  ) {}

  ngOnInit(): void {
    this._route.queryParamMap.subscribe((routeData) => {
      this._email = routeData['params'].email;
      this._token = routeData['params'].token;
    });
    this.initResetPasswordForm();
    this._initLang();
  }

  private initResetPasswordForm() {
    this.resetPasswordForm = this._fb.group({
      password: [null, Validators.compose([Validators.required, Validators.minLength(8)])],
      password_confirmation: [
        null,
        Validators.compose([Validators.required, Validators.minLength(8), this._authService.MatchPassword])
      ]
    });
  }

  resetPassword(formValue: any) {
    this._trackingService.trackEvent({ type: 'auth-reset-password' }).pipe(takeUntil(this._ngUnsubscribe$)).subscribe();
    const payload = {
      token: this._token,
      email: this._email,
      password: formValue.password,
      password_confirmation: formValue.password_confirmation
    };
    this._store.dispatch(
      fromAuthStore.resetPassword({
        payload
      })
    );
  }

  /**
   * Init default interface lang
   * @private
   */
  private _initLang() {
    this.interfaceLang = <Langs>this._translate.getBrowserLang();
    if (this.interfaceLang === 'fr') {
      this._translate.use('fr');
    } else {
      this._translate.use('en');
    }
  }
  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
