import { Component, OnInit, OnDestroy, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable, Subject, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import * as fromRouterStore from '@purplefront/app-router/data-access';
import * as fromPreferencesStore from '@purplefront/preferences/data-access';
import { Langs } from '@purplefront/shared';

@Component({
  selector: 'app-preferences-container',
  template: `
    <app-header-mobile class="d-md-none d-lg-block"></app-header-mobile>
    <app-general-tab [interfaceLang$]="interfaceLang$"></app-general-tab>
    <app-language-tab *ngIf="(currentPage$ | async) === 'langs'" [interfaceLang]="interfaceLang$ | async">
    </app-language-tab>
    <app-socials-tab *ngIf="(currentPage$ | async) === 'socials'"> </app-socials-tab>
    <app-version-tab *ngIf="(currentPage$ | async) === 'version'"> </app-version-tab>
    <app-advanced-features-tab
      *ngIf="(currentPage$ | async) === 'advanced'"
      [advancedFeatures]="advancedFeatures$ | async"
    ></app-advanced-features-tab>
  `
})
export class PreferencesContainer implements OnInit, OnDestroy {
  public currentPage$: Observable<string>;
  public catalogs = [];
  @Output() public interfaceLang$: Observable<Langs>;
  @Output() public advancedFeatures$: Observable<string[]>;

  private routeSubscription: Subscription;
  private storeSubscription: Subscription;
  private catalogStoreSubscription: Subscription;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private _route: ActivatedRoute, private store: Store<fromPreferencesStore.PreferencesState>) {}

  ngOnInit() {
    /**
     * Get current advanced features and dispatch it to the store
     */

    this.advancedFeatures$ = this.store.pipe(
      takeUntil(this.ngUnsubscribe$),
      select(fromPreferencesStore.selectAdvancedFeatures)
    );

    /**
     * Get current interface lang and dispatch it to the store
     */

    this.interfaceLang$ = this.store.pipe(
      takeUntil(this.ngUnsubscribe$),
      select(fromPreferencesStore.selectInterfaceLang)
    );
    this.routeSubscription = this._route.queryParams.subscribe((queryParams) => {
      /**
       * Get current route and dispatch it to the store
       */

      this.store.dispatch(fromPreferencesStore.setCurrentPage({ payload: queryParams.section }));

      /**
       * Display selected tab
       */

      this.currentPage$ = this.store.pipe(select(fromRouterStore.selectCurrentSection));
    });
  }

  /**
   * Prevent memory leaks
   */

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
    if (this.catalogStoreSubscription) {
      this.catalogStoreSubscription.unsubscribe();
    }
  }
}
