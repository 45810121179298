export interface TokenCredentials {
  username?: string;
  accessToken: string;
  expiresAt: string;
  tokenType: string;
}

export function isTokenCredentials(obj: any): obj is TokenCredentials {
  return obj['accessToken'] && obj['expiresAt'] && obj['tokenType'];
}
