import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AudioStoryState,
  generateAudioFormat,
  generateLandscapeFormat,
  generateMobileFormat,
  generateSquareFormat
} from '@purplefront/audio-story/data-access';
import { cloneDeep } from 'lodash-es';
import { VoicePreviewPayload } from '@purplefront/editor/data-access';

@Injectable({
  providedIn: 'root'
})
export class AudioStoryService {
  constructor(private _audioStoryStore: Store<AudioStoryState>) {}

  generateAudioStoryFormats(formats, payload) {
    for (const format of formats) {
      if (format.value === 'video_mobile') {
        this.generateMobileFormat(payload);
      }
      if (format.value === 'video') {
        this.generateSquareFormat(payload);
      }
      if (format.value === 'audio') {
        this.generateAudioFormat(payload);
      }
      if (format.value === 'video_16_9') {
        this.generateLandscapeVideo(payload);
      }
    }
  }
  generateAudioFormat(payload) {
    const request = { ...payload };
    request.format = 'audio';
    this._audioStoryStore.dispatch(generateAudioFormat({ payload: request }));
  }

  generateMobileFormat(payload) {
    const request = { ...payload };
    request.format = 'video_mobile';
    this._audioStoryStore.dispatch(generateMobileFormat({ payload: request }));
  }

  generateSquareFormat(payload) {
    const request = { ...payload };
    request.format = 'video';
    this._audioStoryStore.dispatch(generateSquareFormat({ payload: request }));
  }

  generateLandscapeVideo(payload) {
    const request = { ...payload };
    request.format = 'video_16_9';
    this._audioStoryStore.dispatch(generateLandscapeFormat({ payload: request }));
  }

  disableTranslationAudioStoryMapping(audioStory) {
    const audioStoryClone = cloneDeep(audioStory);
    const items = audioStoryClone.body.items;
    for (const item of items) {
      item.translate = false;
    }
    return audioStoryClone;
  }

  disableTranslationItemPreview(payload: VoicePreviewPayload): VoicePreviewPayload {
    let payloadClone = { ...payload };
    payloadClone.translate = false;
    return payloadClone;
  }

  isTranslateError(err: any): boolean {
    if (!err.error?.errors) {
      return false;
    }
    return err?.error?.errors[0][0]?.key === 'translate';
  }

  patchExplicitLangInItems(audioStory, lang: string) {
    const audioStoryClone = cloneDeep(audioStory);
    for (const item of audioStoryClone.body.items) {
      item.lang = lang;
    }

    return audioStoryClone;
  }

  isAudioPreviewTranslateError(err: any): boolean {
    const errorString = JSON.stringify(err);
    return errorString.includes('We were not able to detect language from your text');
  }
}
