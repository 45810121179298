import * as fromCatalogActions from '../actions';

import { Catalog } from '../../_models';
import { Feed } from '../../_models';
import { CatalogStatus } from '../../_models';
import { uniqBy, intersectionBy } from 'lodash-es';
import { createReducer, on } from '@ngrx/store';

export interface CatalogState {
  status: CatalogStatus;
  currentCatalogId: string | null;
  latestCatalogId: string | null;
  catalogs: Catalog[];
  currentFeedSlug: string | null;
  latestFeedSlug: string | null;
  feeds: Feed[];
  userFeeds: any[];
  folders: any;
  currentFolder: any;
}

const feedALL: Feed[] = [
  {
    id: '0',
    slug: 'all',
    name: { en: 'All', fr: 'Tout' },
    possibleSlugs: ['all'],
    backendFeeds: ['all'],
    isActive: false
  }
];

export const initialState: CatalogState = {
  status: CatalogStatus.NOT_INITIALIZED,
  currentCatalogId: null,
  latestCatalogId: null,
  catalogs: [],
  currentFeedSlug: null,
  latestFeedSlug: null,
  feeds: [],
  userFeeds: [],
  folders: [],
  currentFolder: null
};
const catalogReducer = createReducer(
  initialState,
  on(fromCatalogActions.catalogInitialized, (state, action) => {
    return {
      ...state,
      status: CatalogStatus.INITIALIZED,
      catalogs: action.payload.catalogs,
      feeds: [...action.payload.feeds, ...feedALL]
    };
  }),
  on(fromCatalogActions.catalogError, (state, action) => {
    return {
      ...state,
      status: CatalogStatus.ERROR
    };
  }),

  on(fromCatalogActions.getUserFeedsSuccess, (state, action) => {
    let _userFeeds = [...action.payload.userFeeds, ...state.userFeeds, ...feedALL];
    _userFeeds = intersectionBy(state.feeds, _userFeeds, 'slug');
    return {
      ...state,
      userFeeds: uniqBy(_userFeeds, (f) => f.slug)
    };
  }),
  on(fromCatalogActions.getFoldersSuccess, (state, action) => {
    return {
      ...state,
      folders: action.payload.folders
    };
  }),
  on(fromCatalogActions.addFeed, (state, action) => {
    return {
      ...state,
      userFeeds: [action.payload.feed, ...state.userFeeds]
    };
  }),
  on(fromCatalogActions.addFeedAll, (state, action) => {
    return {
      ...state,
      userFeeds: [...state.feeds]
    };
  }),
  on(fromCatalogActions.deleteFeedAll, (state, action) => {
    return {
      ...state,
      userFeeds: []
    };
  }),
  on(fromCatalogActions.deleteFeed, (state, action) => {
    const userFeedsList = state.userFeeds.filter((feed) => feed.id !== action.payload.feed.id);
    return {
      ...state,
      userFeeds: userFeedsList
    };
  }),
  on(fromCatalogActions.setCurrentFolder, (state, action) => {
    return {
      ...state,
      currentFolder: action.folder
    };
  })
);

export function reducer(state: CatalogState, action: fromCatalogActions.CatalogActionsUnion) {
  return catalogReducer(state, action);
}
