import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import * as fromRouterStore from '@purplefront/app-router/data-access';
import * as fromCatalogStore from '@purplefront/catalog/data-access';
import { AppRouterFacadeService } from '@purplefront/app-router/data-access';
import * as fromPreferencesStore from '@purplefront/preferences/data-access';

import * as fromHeaderActions from '../actions/header.actions';

@Injectable()
export class HeaderEffects {
  constructor(
    private actions$: Actions<fromHeaderActions.HeaderActionsUnion>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routerStore: Store<fromRouterStore.AppRouterState>,
    private catalogStore: Store<fromCatalogStore.CatalogState>,
    private _preferencesStore: Store<fromPreferencesStore.PreferencesState>,
    private translate: TranslateService,
    private _routerFacade: AppRouterFacadeService
  ) {}
}
