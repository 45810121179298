import { createAction, props, union } from '@ngrx/store';
import { Bookmark, BookmarkType } from '../../_models';

export enum BookmarkActionTypes {
  LOAD_BOOKMARKS = '[Bookmarks] Load Bookmarks ',
  LOAD_BOOKMARKS_FAILURE = '[Bookmarks] Load Bookmarks Failure',
  LOAD_BOOKMARKS_SUCCESS = '[Bookmarks] Load Bookmarks Success',
  ADD_BOOKMARK = '[Bookmarks] Add Bookmark',
  ADD_BOOKMARK_FAILURE = '[Bookmarks] Add Bookmark Failure',
  ADD_BOOKMARK_SUCCESS = '[Bookmarks] Add Bookmark Success',
  REMOVE_BOOKMARK = '[Bookmarks] Remove Bookmark',
  REMOVE_BOOKMARK_FAILURE = '[Bookmarks] Remove Bookmark fail',
  REMOVE_BOOKMARK_SUCCESS = '[Bookmarks] Remove Bookmark success',
  REMOVE_ALL_BOOKMARK = '[Bookmarks] Remove All Bookmark',
  REMOVE_ALL_BOOKMARK_SUCCESS = '[Bookmarks] Remove All Bookmark success',
  REMOVE_ALL_BOOKMARK_FAIL = '[Bookmarks] Remove All Bookmark Failure'
}

export const loadBookmarks = createAction(
  BookmarkActionTypes.LOAD_BOOKMARKS,
  props<{ payload: { bookmarkType: BookmarkType } }>()
);

export const loadBookmarksSuccess = createAction(
  BookmarkActionTypes.LOAD_BOOKMARKS_SUCCESS,
  props<{ payload: { bookmarks: Bookmark[] } }>()
);

export const loadBookmarksFailure = createAction(
  BookmarkActionTypes.LOAD_BOOKMARKS_FAILURE,
  props<{ payload: { error: any } }>()
);

export const addBookmark = createAction(BookmarkActionTypes.ADD_BOOKMARK, props<{ payload }>());

export const addBookmarkFailure = createAction(
  BookmarkActionTypes.ADD_BOOKMARK_FAILURE,
  props<{ payload: { error: any } }>()
);

export const addBookmarkSuccess = createAction(BookmarkActionTypes.ADD_BOOKMARK_SUCCESS, props<{ payload }>());

export const removeBookmark = createAction(BookmarkActionTypes.REMOVE_BOOKMARK, props<{ payload: any }>());

export const removeBookmarkFail = createAction(
  BookmarkActionTypes.REMOVE_BOOKMARK_FAILURE,
  props<{ payload: { error: any } }>()
);

export const removeBookmarkSuccess = createAction(BookmarkActionTypes.REMOVE_BOOKMARK_SUCCESS);

export const removeAllBookmark = createAction(BookmarkActionTypes.REMOVE_ALL_BOOKMARK);

export const removeAllBookmarkSuccess = createAction(BookmarkActionTypes.REMOVE_ALL_BOOKMARK_SUCCESS);

export const removeAllBookmarkFail = createAction(
  BookmarkActionTypes.REMOVE_ALL_BOOKMARK_FAIL,
  props<{ payload: { error: any } }>()
);

const all = union({
  loadBookmarks,
  loadBookmarkFailure: loadBookmarksFailure,
  loadBookmarkSuccess: loadBookmarksSuccess,
  removeAllBookmark,
  removeAllBookmarkSuccess,
  removeAllBookmarkFail,
  removeBookmark,
  removeBookmarkSuccess,
  removeBookmarkFail,
  addBookmark,
  addBookmarkFailure,
  addBookmarkSuccess
});

export type BookmarksActionsUnion = typeof all;
