import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromFlashBriefing from './flash-briefing.reducer';
import * as FlashBriefingSelectors from './flash-briefing.selectors';

@Injectable()
export class FlashBriefingFacade {
  loaded$ = this.store.pipe(select(FlashBriefingSelectors.getFlashBriefingLoaded));
  allFlashBriefing$ = this.store.pipe(select(FlashBriefingSelectors.getAllFlashBriefing));
  selectedFlashBriefing$ = this.store.pipe(select(FlashBriefingSelectors.getSelected));

  constructor(private store: Store<fromFlashBriefing.FlashBriefingPartialState>) {}

  dispatch(action: Action) {
    this.store.dispatch(action);
  }
}
