import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { lastSelectedVoice, PreferencesState, setLastSelectedVoice } from '@purplefront/preferences/data-access';
import { EditorService } from '../../../../../data-access/src/lib/services';
import { TranslateService } from '@ngx-translate/core';
import { Voice } from '@purplefront/editor/data-access';
import { TranslatableLang } from '../../../../../data-access/src/lib/_models/translatable-lang.model';

@Component({
  selector: 'app-voices-dropdown',
  templateUrl: './voices-dropdown.component.html',
  styleUrls: ['./voices-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VoicesDropdownComponent),
      multi: true
    }
  ]
})
export class VoicesDropdownComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() voices: Voice[];
  @Input() lastGeneratedVoices: Voice[];
  @Input() userPermissions: string[];
  @Input() translatableLangs: TranslatableLang[];
  @Output() onSelectedVoiceChange$ = new EventEmitter<any>();
  @ViewChild('voiceDropdownElement') voiceDropdownElement;
  onChange: (_: any) => {};
  selectedVoice$ = new BehaviorSubject(null);
  searchText: string;
  unavailableTooltip = 'UNAVAILABLE_FOR_TRANSLATION';
  formerlyEmmaTooltip = 'FORMERLY_EMMA';

  constructor(
    private _prefStore: Store<PreferencesState>,
    private _editorService: EditorService,
    private _translate: TranslateService
  ) {}

  ngOnDestroy(): void {}

  ngOnInit() {
    this.getDefaultVoice();
  }

  close(): void {
    this.voiceDropdownElement.close();
  }

  getDefaultVoice() {
    this._prefStore
      .pipe(
        select(lastSelectedVoice),
        filter((voice) => voice != null)
      )
      .subscribe((voice) => {
        this.selectedVoice$.next(voice);
        this.onSelectedVoiceChange$.emit(voice);
      });
  }
  registerOnChange(fn: (_: any) => {}) {
    this.onChange = fn;
  }

  userHasTranslatePermission(): boolean {
    return this.userPermissions.includes('audiostory.translate');
  }

  shouldDisplayTooltip(voice): boolean {
    return this.userHasTranslatePermission() && !this.isVoiceLangTranslatable(voice);
  }

  shouldDisplayTooltipFormerlyEmma(voice): boolean {
    return voice?.label === 'Jacqueline';
  }

  isVoiceLangTranslatable(voice): boolean {
    if (!this.translatableLangs?.length) return;
    for (const translatableLang of this.translatableLangs) {
      const voiceLang = voice?.language?.substring(0, 2).toUpperCase();
      if (voiceLang === translatableLang?.identifier) {
        return true;
      }
    }
    return false;
  }

  changeSelectedOption(voice: any) {
    this.selectedVoice$.next(voice);
    this.onChange(voice);
    this.onSelectedVoiceChange$.emit(voice);
    this._prefStore.dispatch(setLastSelectedVoice({ payload: voice }));
  }

  registerOnTouched() {}

  writeValue(voice: any): void {
    this.selectedVoice$.next(voice);
  }
}
