<section class="latest-stories">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 text-center">
        <h3>Discover the <span class="accent-fluo">latest stories</span> on many themes</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-md-4">
        <div class="last-stories-item">
          <img class="img-fluid" src="assets/images/home/last-stories/istock_49.jpg" />
          <div class="article-info">
            <a class="btn btn-cat" role="button">MODE-BEAUTÉ</a>
            <span class="article-info-date">08 septembre 2020, 13h32</span>
          </div>
          <h5>L'aloe vera, la meilleure alternative naturelle pour une peau hydratée</h5>
        </div>
      </div>
      <div class="col-6 col-md-4">
        <div class="last-stories-item">
          <img class="img-fluid" src="assets/images/home/last-stories/istock_45.jpg" />
          <div class="article-info">
            <a class="btn btn-cat" role="button">FOOD</a>
            <span class="article-info-date">12 septembre 2020, 10h14</span>
          </div>
          <h5>Sauvez les océans et adoptez le thon vegan !</h5>
        </div>
      </div>
      <div class="col-6 col-md-4">
        <div class="last-stories-item">
          <img class="img-fluid" src="assets/images/home/last-stories/istock_50.jpg" />
          <div class="article-info">
            <a class="btn btn-cat" role="button">BIEN-ÊTRE</a>
            <span class="article-info-date">7 septembre 2020, 8h20</span>
          </div>
          <h5>Un week-end pour s'initier gratuitement à des activités bien-être, ça vous dit ?!</h5>
        </div>
      </div>
      <div class="col-6 col-md-4">
        <div class="last-stories-item">
          <img class="img-fluid" src="assets/images/home/last-stories/istock_47.jpg" />
          <div class="article-info">
            <a class="btn btn-cat" role="button">MOBILITÉS</a>
            <span class="article-info-date">15 septembre 2020, 18h20</span>
          </div>
          <h5>Mi-vélo, mi-voiture, voici à quoi ressemblera le véhicule du futur</h5>
        </div>
      </div>
      <div class="col-6 col-md-4">
        <div class="last-stories-item">
          <img class="img-fluid" src="assets/images/home/last-stories/istock_48.jpg" />
          <div class="article-info">
            <a class="btn btn-cat" role="button">JEUX</a>
            <span class="article-info-date">9 septembre 2020, 18h20</span>
          </div>
          <h5>"Minecraft" arrive enfin sur PlayStation VR</h5>
        </div>
      </div>
      <div class="col-6 col-md-4">
        <div class="last-stories-item">
          <img class="img-fluid" src="assets/images/home/last-stories/istock_46.jpg" />
          <div class="article-info">
            <a class="btn btn-cat" role="button">MODE-BEAUTÉ</a>
            <span class="article-info-date">5 septembre 2020, 16h20</span>
          </div>
          <h5>#LeMeilleurEstDevant : le futur de la mode vu par Isabelle Lefort</h5>
        </div>
      </div>
    </div>
  </div>
</section>
