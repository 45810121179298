import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Location } from '@angular/common';
import { AppRouterState } from '../../../../../app-router/data-access/src/lib/_store/reducers';
import { redirectToLogin } from '../../../../../app-router/data-access/src/lib/_store/actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-auth-nagging-modal',
  templateUrl: './auth-nagging-modal.component.html',
  styleUrls: ['./auth-nagging-modal.component.scss']
})
export class AuthNaggingModalComponent implements OnInit {
  constructor(
    private _ngxSmartModal: NgxSmartModalService,
    private _location: Location,
    private _appRouterStore: Store<AppRouterState>
  ) {}

  ngOnInit(): void {}

  redirectToLogin(): void {
    this._appRouterStore.dispatch(
      redirectToLogin({
        payload: {
          fromUrl: this._location.path()
        }
      })
    );
  }
}
