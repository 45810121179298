import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';

import * as moment from 'moment';

import { select, Store } from '@ngrx/store';

import { takeUntil } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

import * as fromAppRouterStore from '../../../../../app-router/data-access/src/lib/_store';
import { Bookmark } from '@purplefront/bookmarks/data-access';

import {
  CalendarEvent,
  CalendarState,
  loadCalendarEvents,
  selectAllEvents,
  selectCurrentEvents,
  selectLoaded,
  selectLoading,
  selectPastEvents,
  selectUpcomingEvents
} from '@purplefront/calendar/data-access';

@Component({
  selector: 'app-calendar-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div *ngIf="events$ | async as events">
      <app-calendar-relaxnews
        [events]="events"
        [isLoaded]="isLoaded$ | async"
        [isLoading]="isLoading$ | async"
        [upComingEvents]="upcomingEvents$ | async"
        [pastEvents]="pastEvents$ | async"
        [currentEvents]="currentEvents$ | async"
        (changeCalendarView)="onCalendarViewChange($event)"
        (openCalendarEventModal)="openEventModal($event)"
      >
      </app-calendar-relaxnews>
    </div>
    <app-article-modal></app-article-modal>
  `
})
export class CalendarContainer implements OnInit, OnDestroy {
  @Input() page: string;
  public events$: Observable<CalendarEvent[]>;
  public upcomingEvents$: Observable<CalendarEvent[]>;
  public pastEvents$: Observable<CalendarEvent[]>;
  public currentEvents$: Observable<CalendarEvent[]>;
  public isLoading$: Observable<boolean>;
  public isLoaded$: Observable<boolean>;
  public searchString: string;
  public bookmarks$: Observable<Bookmark[]>;

  private _ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private _store: Store<CalendarState>, private _route: ActivatedRoute, private _router: Router) {}

  ngOnInit() {
    /**
     * Get calendar month events
     **/

    this.events$ = this._store.pipe(select(selectAllEvents));

    /**
     * Get calendar upcoming events
     */

    this.upcomingEvents$ = this._store.pipe(select(selectUpcomingEvents));

    /**
     *  Get calendar current events
     */

    this.currentEvents$ = this._store.pipe(select(selectCurrentEvents));

    /**
     *  Get current search string
     */

    this._store
      .select(fromAppRouterStore.selectSearchString)
      .pipe(takeUntil(this._ngUnsubscribe))
      .subscribe((search) => (this.searchString = search));

    /**
     * Get calendar past events
     */

    this.pastEvents$ = this.searchString ? this._store.pipe(select(selectPastEvents)) : of([]);

    /**
     * Get loading event state
     */

    this.isLoading$ = this._store.pipe(select(selectLoading));

    /**
     * Get loaded event state
     */

    this.isLoaded$ = this._store.pipe(select(selectLoaded));
  }

  /**
   * Open modal on click event
   **/

  openEventModal($event) {
    this._router.navigate(['.'], {
      relativeTo: this._route,
      queryParams: {
        modal: 'event',
        articleId: $event.articleId,
        articleLang: $event.articleLang,
        articleType: $event.articleType,
        sourceType: $event.sourceType
      }
    });
  }

  onCalendarViewChange($calendarObj) {
    const payload = {
      params: {
        durationUnit: $calendarObj.viewSpec.durationUnit,
        intervalStart: moment($calendarObj.intervalStart).format('YYYY-MM-DD'),
        title: $calendarObj.title,
        type: $calendarObj.type,
        start: moment($calendarObj.start).format('YYYY-MM-DD'),
        end: moment($calendarObj.start).format('YYYY-MM-DD')
      }
    };

    this._store.dispatch(loadCalendarEvents({ payload: payload }));
  }

  /**
   * Prevent memory leaks unsubscribe when destroy component
   */
  ngOnDestroy(): void {
    this._ngUnsubscribe.next();
    this._ngUnsubscribe.complete();
  }
}
