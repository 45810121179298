<div class="import-audio d-flex justify-content-center align-items-center ms-auto me-auto position-relative">
  <form
    *ngIf="formUpload"
    [formGroup]="formUpload"
    (ngSubmit)="submit()"
    class="audio-upload-form text-center w-100 h-100"
  >
    <div class="form-group">
      <app-file-upload
        [label]="label"
        [selectedAudioItem]="selectedItem"
        [supportedFileFormat]="availableFormats"
        (submit$)="submit()"
        (deleteAudio$)="deleteAudio($event)"
        formControlName="audio"
      ></app-file-upload>
      <div class="invalid-feedback" style="display: block">
        <ng-container *ngIf="hasError('audio', 'requiredFileType')">{{
          'EDITOR_UPLOAD_AUDIO_ERROR_MESSAGE' | translate
        }}</ng-container>
        <ng-container *ngIf="hasError('audio', 'maxFileSizeExceeded')">{{ 'SIZE_LIMIT' | translate }} </ng-container>
      </div>
    </div>
    <i *ngIf="success" class="fa fa-check" aria-hidden="true"></i>
  </form>
</div>
