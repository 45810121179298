import { Injectable } from '@angular/core';
import { environment } from '@purplefront/shared';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Voice } from '@purplefront/editor/data-access';
import { TranslatableLang } from '../_models/translatable-lang.model';

@Injectable({
  providedIn: 'root'
})
export class EditorApi {
  readonly audioApiUrl: string = `${environment.purpleApi}/audios`;
  readonly voiceApiUrl: string = `${environment.purpleApi}/voices`;
  readonly translatableLangUrl: string = `${environment.purpleApi}/translation/languages`;

  constructor(private _http: HttpClient) {}

  public loadAudios(): Observable<any> {
    return this._http.get(this.audioApiUrl).pipe(map((res: any) => res.data));
  }

  public addAudios(params): Observable<any> {
    return this._http.post(this.audioApiUrl, params).pipe(map((res: any) => res.data));
  }

  public deleteAudio(id): Observable<any> {
    return this._http.delete(this.audioApiUrl).pipe(map((res: any) => res.data));
  }

  public deleteAllAudios(): Observable<any> {
    return this._http.delete(this.audioApiUrl).pipe(map((res: any) => res.data));
  }

  public updateAudio(id): Observable<any> {
    const url = `${this.audioApiUrl}/${id}`;
    return this._http.post(url, {}).pipe(map((res: any) => res.data));
  }

  public mergeAudio(params): Observable<any> {
    const url = `${this.audioApiUrl}/merge`;
    return this._http.post(url, params).pipe(map((res: any) => res.data));
  }

  public getVoices(): Observable<Voice[]> {
    return this._http.get<Observable<Voice[]>>(this.voiceApiUrl).pipe(map((res: any) => res.data));
  }

  public getTranslatableLangs(): Observable<TranslatableLang[]> {
    return this._http.get(this.translatableLangUrl).pipe(map((res: any) => res.data));
  }
}
