import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';

import { AuthApi, LoginResponse, CheckSessionResponse } from '../_api';
import { SessionService, TokenCredentials } from './session.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { ToastService } from '../../../../../shared/src/lib/_services/toast.service';

export { LoginResponse, CheckSessionResponse, TokenCredentials };

@Injectable()
export class AuthService {
  constructor(
    private authApi: AuthApi,
    private sessionService: SessionService,
    private _location: Location,
    private _router: Router,
    public toastService: ToastService
  ) {}

  public doCheckSession(token: TokenCredentials): Observable<CheckSessionResponse> {
    return this.authApi.checkSession(token);
  }

  public doLogin(username: string, password: string): Observable<LoginResponse> {
    return this.authApi.login(username, password);
  }

  public doLogout(): Observable<LoginResponse> {
    return this.authApi.logout();
  }

  public doLoadCredentials(): TokenCredentials | null {
    return this.sessionService.loadCredentials();
  }

  public doSaveCredentials(credentials: TokenCredentials): void {
    return this.sessionService.saveCredentials(credentials);
  }

  public register(payload): Observable<any> {
    return this.authApi.register(payload);
  }

  public activateRegistrationCode(payload): Observable<any> {
    return this.authApi.activateRegistrationCode(payload);
  }

  public sendEmail(payload): Observable<any> {
    return this.authApi.sendEmail(payload);
  }

  public resetPassword(payload): Observable<any> {
    return this.authApi.resetPassword(payload);
  }

  public MatchPassword(AC: FormControl): Object {
    if (AC.parent) {
      const password = AC.parent.controls['password'].value;
      const confirmPassword = AC.value;
      if (password === confirmPassword) {
        return null;
      } else {
        return { not_match: true };
      }
    }
  }

  // complicated logic to manage redirects on a page requested by the user after login. TODO improve me
  public navigateToAuthPage(): void {
    const path = this._location.path();
    const fromUrl = decodeURIComponent(path.split('fromUrl=')[1]);

    if (
      path.indexOf('auth/activate') !== -1 ||
      path.indexOf('auth/reset') !== -1 ||
      path.indexOf('auth/forgot-pass') !== -1
    ) {
      return;
    }

    if (fromUrl && fromUrl !== 'undefined') {
      if (fromUrl.indexOf('auth/login') === -1) {
        this._router.navigate(['auth/login'], { queryParams: { fromUrl } });
      } else {
        this._router.navigate(['auth/login']);
      }
    } else {
      if (!path) {
        this._router.navigate(['auth/login']);
      } else {
        if (path.indexOf('auth/login') === -1 && path.indexOf('articles/rn') === -1) {
          this._router.navigate(['auth/login'], { queryParams: { fromUrl: path } });
        } else {
          this._router.navigateByUrl(path);
        }
      }

      /* if (path && path.indexOf('auth/login') === -1) {
        this._router.navigate(['auth/login'], { queryParams: { fromUrl: path } });
      } else {
        this._router.navigate(['auth/login']);
      }*/
    }
  }

  showSuccess(message, autohide) {
    this.toastService.show(message.body, {
      classname: 'bg-success text-light',
      autohide: autohide,
      headertext: message.header
    });
  }

  showError(message, autohide) {
    this.toastService.show(message.body, {
      classname: 'bg-danger text-light',
      autohide: autohide,
      headertext: message.header
    });
  }
}
