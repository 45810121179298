import { Injectable } from '@angular/core';

import { ofType, Actions, createEffect } from '@ngrx/effects';

import { catchError, map, switchMap } from 'rxjs/operators';
import { combineLatest, Observable, of as observableOf, of } from 'rxjs';
import { Store } from '@ngrx/store';

import * as moment from 'moment';

import {
  loadCalendarEventsFail,
  loadCalendarEventsSuccess,
  loadCalendarEvents,
  CalendarActionTypes,
  CalendarActionUnion
} from '../actions';

import { CalendarState } from '../reducers';

import { selectSearchString } from '../../../../../../app-router/data-access/src/lib/_store/selectors';
import { Feed } from '../../../../../../catalog/data-access/src/lib/_models';
import { CalendarService } from '../../_services';
import * as fromPreferencesStore from '@purplefront/preferences/data-access';

@Injectable()
export class CalendarEffects {
  contentLang$: Observable<string>;
  feedSlug$: Observable<string | Feed>;
  searchString$: Observable<string>;

  constructor(
    private actions$: Actions<CalendarActionUnion>,
    private calendarService: CalendarService,
    private store: Store<CalendarState>,
    private _preferencesStore: Store<fromPreferencesStore.PreferencesState>
  ) {}

  loadCalendarEvents = createEffect(() =>
    this.actions$.pipe(
      ofType(CalendarActionTypes.LOAD_CALENDAR_EVENTS),
      switchMap((action) => {
        this.feedSlug$ = this._preferencesStore.select(fromPreferencesStore.currentFeed);
        this.contentLang$ = this._preferencesStore.select(fromPreferencesStore.selectContentLang);
        this.searchString$ = this.store.select(selectSearchString);

        return combineLatest([this.feedSlug$, this.contentLang$, this.searchString$]).pipe(
          switchMap(([_feedSlug, _contentLang, _searchString]) => {
            const intervalStart = action.payload.params.intervalStart;
            const startOf = action.payload.params.durationUnit === 'basiweek' ? 'week' : 'month';

            /**
             * set to the first of this month or week, 12:00 am
             */
            const startOfCurrentMonth = moment(intervalStart).startOf(startOf).format('YYYY-MM-DD');

            /**
             * set to the last days of this month or week, 12:00 am
             */
            const endOfCurrentMonth = moment(intervalStart).endOf(startOf).format('YYYY-MM-DD');

            /**
             * if search context ends sixth next month
             */

            const endOfSixMonth = moment(intervalStart).add(6, startOf).endOf(startOf).format('YYYY-MM-DD');

            /**
             * if search context starts six months before
             */

            const startOfSixMonth = moment(intervalStart).subtract(6, startOf).startOf(startOf).format('YYYY-MM-DD');

            const options = {
              feeds: _feedSlug,
              startDate: _searchString ? startOfSixMonth : startOfCurrentMonth,
              endDate: _searchString ? endOfSixMonth : endOfCurrentMonth,
              searchString: _searchString ? _searchString.trim() : undefined,
              lang: _contentLang,
              size: _searchString ? 0 : 30 // 0 to unlock the result number
            };

            return this.calendarService.getEvents(options).pipe(
              catchError((err: any) => {
                observableOf(loadCalendarEventsFail({ payload: { error: err } }));
                return of([]);
              }),
              map((events) =>
                loadCalendarEventsSuccess({
                  payload: { calendarEvents: events }
                })
              )
            );
          })
        );
      })
    )
  );
}
