import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlashBriefingContainer } from './containers/flash-briefing/flash-briefing-container.component';
import { FlashBriefingUiModule } from '@purplefront/flash-briefing/ui';
import { FlashBriefingDataAccessModule } from 'libs/flash-briefing/data-access/src/lib/flash-briefing-data-access.module';

@NgModule({
  imports: [
    CommonModule,
    FlashBriefingUiModule,
    FlashBriefingDataAccessModule,
    RouterModule.forChild([{ path: '', pathMatch: 'full', component: FlashBriefingContainer }])
  ],
  declarations: [FlashBriefingContainer]
})
export class FlashBriefingFeatureShellModule {}
