<ngx-smart-modal #audioStoryModal identifier="audioStoryModal">
  <plyr
    plyrPlaysInline
    [plyrOptions]="options"
    [plyrSources]="sources"
    (plyrInit)="player = $event"
    (plyrPlay)="played($event)"
    (plyrWaiting)="waiting($event)"
    (plyrCanPlayThrough)="canPlayThrough($event)"
  ></plyr>
  <app-loading *ngIf="waiting$ | async"></app-loading>
</ngx-smart-modal>
