import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderFeatureShellModule } from '@purplefront/header/feature-shell';
import { MenuFeatureShellModule } from '@purplefront/menu/feature-shell';
import { SavedSearchUiModule } from '@purplefront/saved-search/ui';
import { StoreModule } from '@ngrx/store';
import * as fromCatalogStore from '../../../data-access/src/lib/_store';
import { EffectsModule } from '@ngrx/effects';
import { CookieBannerFeatureShellModule } from '@purplefront/cookie-banner/feature-shell';
import {
  Catalog404Container,
  CatalogContainer,
  Feed404Container,
  FeedContainer,
  FeedListContainer
} from './containers';
import { FeedResolver } from '../../../data-access/src/lib/_routing';
import { CatalogApi } from '../../../data-access/src/lib/_api';
import { AudioPlayerFeatureShellModule } from '@purplefront/audio-player/feature-shell';
import { MenuRioUiModule } from '@purplefront/menu-rio/ui';
import { SharedModule } from '@purplefront/shared';
import { HeaderRioFeatureShellModule } from '@purplefront/header-rio/feature-shell';
import { FlashBriefingUiModule } from '@purplefront/flash-briefing/ui';
import { ContentFiltersFeatureShellModule } from '@purplefront/content-filters/feature-shell';
import { PlaylistFeatureShellModule } from '@purplefront/playlist/feature-shell';
import { MenuRioFeatureShellModule } from '@purplefront/menu-rio/feature-shell';
import { SearchFeatureShellModule } from '@purplefront/search/feature-shell';
import { AudioPlayerUiModule } from '@purplefront/audio-player/ui';
import { ToasterFeatureShellModule } from '@purplefront/toaster/feature-shell';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HeaderFeatureShellModule,
    MenuFeatureShellModule,
    SavedSearchUiModule,
    StoreModule.forFeature('catalog', fromCatalogStore.reducer),
    EffectsModule.forFeature([fromCatalogStore.CatalogEffects]),
    CookieBannerFeatureShellModule,
    AudioPlayerFeatureShellModule,
    MenuRioUiModule,
    SharedModule,
    HeaderRioFeatureShellModule,
    FlashBriefingUiModule,
    ContentFiltersFeatureShellModule,
    PlaylistFeatureShellModule,
    MenuRioFeatureShellModule,
    SearchFeatureShellModule,
    AudioPlayerUiModule,
    ToasterFeatureShellModule
  ],
  declarations: [CatalogContainer, Catalog404Container, FeedListContainer, FeedContainer, Feed404Container],
  providers: [FeedResolver, CatalogApi],
  exports: [CatalogContainer, Catalog404Container, FeedListContainer, FeedContainer, Feed404Container]
})
export class CatalogFeatureShellModule {}
