import {
  Directive,
  Output,
  EventEmitter,
  ElementRef,
  OnDestroy,
  OnInit,
  HostListener,
  AfterViewInit
} from '@angular/core';
import { debounce } from '../../../../editor/utils/src/lib/functions/debounce';

export interface TextSelectEvent {
  text: string;
  parentNode: any;
  viewportRectangle: SelectionRectangle | null;
  hostRectangle: SelectionRectangle | null;
}

export interface SelectionRectangle {
  left: number;
  top: number;
  width: number;
  height: number;
}

@Directive({
  selector: '[textSelect]',
  outputs: ['textSelectEvent$']
})
export class TextSelectDirective implements OnDestroy {
  private _hasSelection = false;

  @Output() textSelectEvent$ = new EventEmitter<TextSelectEvent>();

  constructor(private _elementRef: ElementRef) {}

  @HostListener('document:selectionchange', ['$event']) handleSelectionchange(e) {
    if (this._hasSelection) {
      this.processSelection();
    }
  }

  /**
   * Get the deepest Element node in the DOM tree that contains the entire range.
   * @param range
   * @private
   */
  private getRangeContainer(range: Range): Node {
    let container = range.commonAncestorContainer;
    while (container.nodeType !== Node.ELEMENT_NODE) {
      container = container.parentNode;
    }
    return container;
  }

  /**
   * Determine if the given range is fully contained within the host element.
   * @param range
   * @private
   */
  private isRangeFullyContained(range: Range): boolean {
    const hostElement = this._elementRef.nativeElement;
    let selectionContainer = range.commonAncestorContainer;
    while (selectionContainer.nodeType !== Node.ELEMENT_NODE) {
      selectionContainer = selectionContainer.parentNode;
    }
    return hostElement.contain(selectionContainer);
  }

  @debounce(50)
  @HostListener('document:mouseup', ['$event.target'])
  processSelection(target?) {
    let inside = false;
    if (target) {
      inside = this._elementRef.nativeElement.contains(target);
    }

    const selection = document.getSelection();
    const parentNode = selection.anchorNode?.parentElement;

    if (this._hasSelection && inside) {
      this._hasSelection = false;
      this.textSelectEvent$.emit({
        parentNode: null,
        text: '',
        viewportRectangle: null,
        hostRectangle: null
      });
    }

    // if the new selection is empty, then there's no new selection event to emit
    if (!selection.rangeCount || !selection.toString()) {
      return;
    }
    const range = selection.getRangeAt(0);
    const rangeContainer = this.getRangeContainer(range);
    const text = selection.toString();

    if (this._elementRef.nativeElement.contains(rangeContainer)) {
      const viewportRectangle = range.getBoundingClientRect();
      const localRectangle = this.viewportToHost(viewportRectangle, rangeContainer);
      this._hasSelection = true;
      this.textSelectEvent$.emit({
        text,
        parentNode,
        viewportRectangle: {
          left: viewportRectangle.left,
          top: viewportRectangle.top,
          width: viewportRectangle.width,
          height: viewportRectangle.height
        },
        hostRectangle: {
          left: localRectangle.left,
          top: localRectangle.top,
          width: localRectangle.width,
          height: localRectangle.height
        }
      });
    }
  }

  private viewportToHost(viewportRectangle: SelectionRectangle, rangeContainer: Node): SelectionRectangle {
    const hostElement = this._elementRef.nativeElement;
    const hostRectangle = hostElement.getBoundingClientRect();

    let localLeft = viewportRectangle.left - hostRectangle.left;
    let localTop = viewportRectangle.top - hostRectangle.top;

    let node = rangeContainer;
    do {
      localLeft += (<Element>node).scrollLeft;
      localTop += (<Element>node).scrollTop;
    } while (node !== hostElement && (node = node.parentNode));

    return {
      left: localLeft,
      top: localTop,
      width: viewportRectangle.width,
      height: viewportRectangle.height
    };
  }

  ngOnDestroy() {}
}
