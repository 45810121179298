import { createFeatureSelector, createSelector } from '@ngrx/store';

import { find, uniqBy, sortBy } from 'lodash-es';

import { CatalogStatus } from '../../_models';
import { CatalogState } from '../reducers';
import { selectFolderId } from '../../../../../../app-router/data-access/src/lib/_store/selectors';

export const catalogState = createFeatureSelector<CatalogState>('catalog');

export const status = createSelector(catalogState, (state: CatalogState) => state.status);

export const initialized = createSelector(status, (_status: CatalogStatus) => _status === CatalogStatus.INITIALIZED);

export const catalogs = createSelector(catalogState, (state: CatalogState) =>
  state.catalogs.filter((catalog) => catalog.id !== undefined)
);

export const currentCatalogId = createSelector(catalogState, (state: CatalogState) => state.currentCatalogId || null);

export const feeds = createSelector(catalogState, (state) => sortFeeds(uniqBy(state.feeds, (f) => f.slug)));

export const userFeeds = createSelector(
  catalogState,
  (state) => [...state.userFeeds].sort((a, b) => a.slug.localeCompare(b.slug)) || null
);

export const activeFeeds = createSelector(feeds, (_feeds) => _feeds.filter((feed) => feed.isActive));

export const topicCount = createSelector(
  catalogState,
  (state) => state.userFeeds.filter((feed) => feed.slug !== 'all').length
);

export const folders = createSelector(catalogState, (state) => state.folders || null);

export const folder = createSelector(folders, selectFolderId, (_folders, _folderId) =>
  find(_folders, { id: _folderId })
);

export const currentFolder = createSelector(catalogState, (state) => state.currentFolder);

export const selectFeedsSlugs: any = createSelector(catalogState, (state) =>
  [...state.userFeeds].map((feed) => feed.slug).filter((slug) => slug !== 'all')
);

function sortFeeds(feeds) {
  return sortBy(feeds, ['slug']);
}
