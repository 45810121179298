import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PLAYLIST_FEATURE_KEY, State, PlaylistState, playlistAdapter } from './playlist.reducer';

// Lookup the 'Playlist' feature state managed by NgRx
export const getPlaylistState = createFeatureSelector<State>(PLAYLIST_FEATURE_KEY);

const { selectAll, selectEntities } = playlistAdapter.getSelectors();

export const getPlaylistLoaded = createSelector(getPlaylistState, (state: State) => state.loaded);

export const getPlaylistError = createSelector(getPlaylistState, (state: State) => state.error);

export const getAllPlaylist = createSelector(getPlaylistState, (state: State) => selectAll(state));

export const getPlaylistEntities = createSelector(getPlaylistState, (state: State) => selectEntities(state));

export const getSelectedId = createSelector(getPlaylistState, (state: State) => state.selectedId);

export const getPlaylistDisplayStatus = createSelector(getPlaylistState, (state: State) => state.showPlaylist);

export const getPlaylistArticles = createSelector(getPlaylistState, (state: State) => state.articles);

export const getCurrentPlayingArticleIndex = createSelector(
  getPlaylistState,
  (state: State) => state.currentArticlePlayingIndex
);

export const getSelected = createSelector(
  getPlaylistEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId]
);
