import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as moment from 'moment';

import * as fromReducer from '../reducers/calendar.reducers';

export const selectCalendarState = createFeatureSelector<fromReducer.CalendarState>('calendar');

export const selectAllEvents = createSelector(selectCalendarState, fromReducer.selectAll);

export const selectEventsIds = createSelector(selectCalendarState, fromReducer.selectIds);

export const selectEventsEntities = createSelector(selectCalendarState, fromReducer.selectEntities);

export const selectEventsTotal = createSelector(selectCalendarState, fromReducer.selectTotal);

export const selectCurrentEventId = createSelector(selectCalendarState, fromReducer.getSelectedEventId);

export const selectCurrentEvent = createSelector(
  selectEventsEntities,
  selectCurrentEventId,
  (eventEntities, eventId) => eventEntities[eventId]
);

/**
 * Returns upcoming events (and in progress)
    which are in the current month or more
 */
export const selectUpcomingEvents = createSelector(selectAllEvents, (events) => {
  const now = moment();
  return events.filter(
    (event) =>
      moment(event.start).isAfter(now) ||
      (moment(event.start).isSame(now, 'month') &&
        (moment(event.end).isSame(now, 'day') || moment(event.end).isAfter(now)))
  );
});

export const selectPastEvents = createSelector(selectAllEvents, (events) => {
  const now = moment();
  return events.filter((event) => moment(event.end).isBefore(now));
});

export const selectCurrentEvents = createSelector(selectAllEvents, (events) => {
  const now = moment();
  return events.filter((event) => moment(event.start).isSame(now, 'day') || moment(event.end).isSame(now, 'day'));
});

export const selectLoading = createSelector(selectCalendarState, fromReducer.getSelectLoading);

export const selectLoaded = createSelector(selectCalendarState, fromReducer.getSelectLoaded);
