import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorComponent } from './components/editor/editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@purplefront/shared';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FormUploadComponent } from './components/form-upload/form-upload.component';
import { EditorItemComponent } from './components/editor-item/editor-item.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProgressAudioComponent } from './components/progress/progress-audio/progress-audio.component';
import { ProgressImageComponent } from './components/progress/progress-image/progress-image.component';
import { VoicesDropdownComponent } from './components/voices-dropdown/voices-dropdown.component';
import { AudioStoryUiModule } from '@purplefront/audio-story/ui';
import { FormatDropdownComponent } from './components/format-dropdown/format-dropdown.component';
import { FormSyncModule } from '@larscom/ngrx-store-storagesync';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FilterVoicesPipe } from '../../../utils/src/lib/pipes/filterVoices.pipe';
import { PronunciationModalComponent } from './components/pronunciation-modal/pronunciation-modal.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NoStudioModalComponent } from './components/no-studio-modal/no-studio-modal.component';
import { JingleModalComponent } from './components/jingle-modal/jingle-modal.component';
import { VoiceModalComponent } from './components/voice-modal/voice-modal.component';
import { MultiImageModalComponent } from './components/multi-image-modal/multi-image-modal.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SsmlEditorComponent } from './components/ssml-editor/ssml-editor.component';
import { AudioBackgroundDropdownComponent } from './components/audio-background-dropdown/audio-background-dropdown.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  TranslationModalComponent,
  TranslationModalDialog
} from './components/translation-modal/translation-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { SwiperModule } from 'swiper/angular';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    SharedModule,
    DragDropModule,
    AudioStoryUiModule,
    FormSyncModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxSmartModalModule,
    FlexLayoutModule,
    MatTooltipModule,
    DragDropModule,
    MatDialogModule,
    MatListModule,
    MatRadioModule,
    SwiperModule
  ],
  declarations: [
    EditorComponent,
    FileUploadComponent,
    FormUploadComponent,
    EditorItemComponent,
    ImageUploadComponent,
    ProgressAudioComponent,
    ProgressImageComponent,
    VoicesDropdownComponent,
    FormatDropdownComponent,
    FilterVoicesPipe,
    PronunciationModalComponent,
    NoStudioModalComponent,
    JingleModalComponent,
    VoiceModalComponent,
    MultiImageModalComponent,
    SsmlEditorComponent,
    AudioBackgroundDropdownComponent,
    TranslationModalComponent,
    TranslationModalDialog
  ],
  exports: [
    EditorComponent,
    FileUploadComponent,
    FormUploadComponent,
    EditorItemComponent,
    ProgressAudioComponent,
    ProgressImageComponent,
    PronunciationModalComponent,
    NoStudioModalComponent,
    JingleModalComponent,
    VoiceModalComponent,
    MultiImageModalComponent,
    TranslationModalComponent,
    TranslationModalDialog
  ],
  providers: [FilterVoicesPipe]
})
export class EditorUiModule {}
