import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ArticleApi } from '../_api';

import { uniqBy } from 'lodash-es';
import { Langs } from '@purplefront/shared';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class ArticleService {
  constructor(private api: ArticleApi) {}

  public getArticle(article): Observable<any> {
    return this.api.getArticle(article);
  }

  public getMoreLikeArticles(article): Observable<any> {
    return this.api.getMoreLikeArticles(article);
  }

  public getRelatedArticles(id): Observable<any> {
    return this.api.getRelatedArticles(id);
  }

  public getLatestArticles(mainCategory: string, lang: Langs, articleId: string): Observable<any> {
    let params = new HttpParams();

    params = params.append('from', 'now-1y');
    params = params.append('to', 'now');
    params = params.append('filters[mainCategory][]', mainCategory);
    params = params.append('lang', lang);
    params = params.append('size', '4');
    params = params.append('sortType', 'date');
    params = params.append('sortOrder', 'desc');
    params = params.append('type', 'rn');
    params = params.append('offset', '0');
    params = params.append('filters[videotypes][]', '-news');
    params = params.append('filters[imageSpecialUses][]', '-Paris Modes Insider');
    return this.api.getLatestArticles(params, articleId).pipe(
      map((articles) => {
        if (articles.length > 3) {
          articles.pop();
        }
        return articles;
      })
    );
  }

  public mailArticle(id, type, name, email): Observable<any> {
    return this.api.mailArticle(id, type, name, email);
  }

  public downloadImage(sourceType, lang, id, index): Observable<any> {
    return this.api.downloadImage(sourceType, lang, id, index);
  }

  public mapSemantics(semantics): any {
    const result = {
      categories: [],
      terms: []
    };

    if (!semantics) {
      return result;
    }

    Object.keys(semantics).map((key) => {
      if (semantics[key]) {
        if (key === 'categories') {
          semantics[key].map((value) => {
            result.categories.push({
              value,
              type: 'cat' // to differentiate the style
            });
          });
        } else {
          semantics[key].map((obj) => {
            result.terms.push({
              value: obj.value,
              type: 'term' // to differentiate the style
            });
          });
        }
      }
    });

    result.categories = uniqBy(result.categories, 'value');
    result.terms = uniqBy(result.terms, 'value');

    return result;
  }

  public getStringWithoutEmbeded(nodeElement: any): Node {
    const twitterEmbed: Array<Node> = nodeElement.querySelectorAll('.twitter-tweet');
    const allEmbed: Array<Node> = [...twitterEmbed];
    allEmbed.forEach((elem: Element) => {
      elem.remove();
    })
    return nodeElement.outerHTML;
  }

  public stringToHTML(str: string): HTMLElement {
    const parser = new DOMParser();
    const doc: Document = parser.parseFromString(str, 'text/html');
    return doc.body;
  };
}
