<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: none">
  <defs>
    <g id="icon-language">
      <path
        id="Fill-1"
        class="cls-3"
        d="M8.39,19.71v0s0,0,0,0c-.15-.26-.31-.51-.44-.78a10.53,
          10.53,0,0,1-.45-1c-.13-.33-.22-.66-.33-1S7,16.35,7,16.11c0-.08-.06-.1-.14-.1H4.62c-.12,
          0-.13,0-.08.13a8.34,8.34,0,0,0,1.15,1.6,8.7,8.7,0,0,0,2.47,1.88l.23.09m7.21,0s.08,0,.11,
          0A8.86,8.86,0,0,0,17,18.89a8.44,8.44,0,0,0,2.43-2.75c.06-.09,0-.13-.08-.13H17.16a.12.12,
          0,0,0-.13.1l-.15.58c-.11.34-.21.68-.33,1a8.55,8.55,0,0,1-.38.95c-.17.35-.37.68-.57,
          1.05m0-15.41,0,0,0,0c.18.32.37.64.53,1s.27.6.39.9.21.66.31,1l.18.67c0,.07,0,
          .1.12.1h2.24c.11,0,.13,0,.07-.13a12.8,12.8,0,0,0-.77-1.15,8.37,8.37,0,0,
          0-2.85-2.33l-.22-.09m-7.2,0-.14,0A8.62,8.62,0,0,0,4.56,7.83c-.08.14-.07.16.1.16h2C7,
          8,7,8,7,7.68a8.19,8.19,0,0,1,.23-.85,8.74,8.74,0,0,1,.33-.9c.14-.34.29-.67.46-1a7.3,
          7.3,0,0,1,.37-.63m4.36,16,.15-.08.07,0a3.71,3.71,0,0,0,1.06-1,5.83,5.83,0,0,0,
          .86-1.51c.19-.51.34-1,.5-1.55,0-.13,0-.17-.13-.17H12.92c-.15,0-.16,0-.16.17v4a1.15,
          1.15,0,0,0,0,.16m-1.55,0c0-.06,0-.1,0-.15v-4c0-.15,0-.16-.16-.16H8.73c-.17,0-.19,0
          -.14.2l.18.6a7.77,7.77,0,0,0,.56,1.48,5.71,5.71,0,0,0,1.07,1.47,2.94,2.94,0,0,0,
          .83.58M12.77,3.66c0,.08,0,.14,0,.2v4c0,.07,0,.11.12.11h2.45c.09,0,.12,0,.1-.12a1.06,
          1.06,0,0,0-.06-.19c-.16-.5-.3-1-.49-1.49a5.58,5.58,0,0,0-1.27-1.94,2.7,2.7,0,0,
          0-.84-.59M11.24,5.82V3.75c0-.06,0-.09-.09-.06a3.82,3.82,0,0,0-.47.31A5.41,5.41,0,0,0,
          9.07,6.28c-.19.49-.33,1-.49,1.52-.05.15,0,.19.15.19h2.32c.19,0,.19,0,.19-.19v-2m7.48,
          3.67H17.4c-.09,0-.14,0-.12.12l.06.46c.06.49.13,1,.17,1.45a10.79,10.79,0,0,1-.14,2.27c0,
          .17-.05.35-.08.53s0,.19.16.19h2.6c.05,0,.12,0,.13-.09.08-.3.16-.6.22-.9s.08-.62.11-.93a6.79,
          6.79,0,0,0-.06-1.8c-.06-.38-.16-.76-.25-1.14,0-.16,0-.16-.21-.16H18.72m-13.44,
          5H6.6c.08,0,.14,0,.12-.11s0-.33-.07-.5c-.06-.58-.14-1.16-.17-1.74a10.57,10.57,0,0,1,
          .15-1.94,5,5,0,0,1,.08-.54c0-.18,0-.19-.16-.19H4c-.05,0-.12,
          0-.13.09-.08.29-.16.59-.22.89s-.08.61-.11.92a6.93,6.93,0,0,0,.06,1.82c.06.38.16.76.25,
          1.14,0,.16,0,.16.21.16H5.28M12.76,12v2.31c0,.2,0,.2.21.2h2.69a.18.18,0,0,0,.11-.1,5,5,
          0,0,0,.08-.54c0-.28.08-.57.11-.86a9.65,9.65,0,0,0,0-2c-.05-.44-.12-.88-.17-1.32,0-.18,
          0-.19-.22-.19H13c-.19,0-.19,0-.19.19V12m-1.52,0V9.7c0-.21,0-.21-.21-.21H8.36c-.07,0-.12,
          0-.13.1s-.06.34-.08.51-.07.57-.1.86a9,9,0,0,0,0,2.08c0,.43.11.86.17,1.28,0,.18,0,
          .19.2.19h2.64c.18,0,.18,0,.18-.18V12M22,12A10,10,0,1,1,12,2,10,10,0,0,1,22,12"
      />
    </g>
  </defs>
</svg>
<header class="header">
  <nav class="navbar navbar-expand-lg navbar-light py-4">
    <div class="container d-flex">
      <div class="lang-option">
        <svg class="icon-language-color" viewBox="-8 -4 32 32">
          <use xlink:href="#icon-language"></use>
        </svg>
        <select class="selectpicker-lang my-select custom-select" data-width="fit">
          <option data-content='<span class=""></span> French'>FR</option>
          <option data-content='<span class=""></span> English'>EN</option>
        </select>
      </div>
      <div class="brand-bar">
        <h1><img class="logo" src="assets/images/logos/etx-dailyup-color-logo.png" alt="ETX DailyUp" /></h1>
        <div class="baseline">#Mieux<span>Différemment</span>Moins</div>
      </div>
      <div class="navbar-actions">
        <a class="login" (click)="login()">Log In</a>
        <a (click)="register()" class="btn btn-purple action-button" role="button">Sign Up</a>
      </div>
    </div>
  </nav>

  <nav class="navbar navbar-expand-lg border">
    <div class="container">
      <div class="search-box"></div>
      <button
        class="navbar-toggler navbar-toggler-right"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span></span><span></span><span></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item"><a class="nav-link">Bien-Être</a></li>
          <li class="nav-item"><a class="nav-link">Conso</a></li>
          <li class="nav-item"><a class="nav-link">Culture</a></li>
          <li class="nav-item"><a class="nav-link">Food</a></li>
          <li class="nav-item"><a class="nav-link">Maison</a></li>
          <li class="nav-item"><a class="nav-link">Mobilités</a></li>
          <li class="nav-item"><a class="nav-link">Mode-Beauté</a></li>
          <li class="nav-item"><a class="nav-link">Jeux</a></li>
          <li class="nav-item"><a class="nav-link">Son</a></li>
          <li class="nav-item"><a class="nav-link">Tech</a></li>
          <li class="nav-item"><a class="nav-link">Tourisme</a></li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="navbar topbar-audio">
    <div class="audio-player">
      <img src="assets/images/pictos/button-play-purple.svg" alt="" width="" />
      <span class="audio-player-txt">Listen to our latest content</span>
    </div>
    <div class="audio-wave">
      <img src="assets/images/pictos/icon-wave-audio.png" alt="" width="" />
    </div>
    <div class="top-feature"></div>
  </div>
</header>
