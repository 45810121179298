import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UploadService } from '../../../../../../../shared/src/lib/_services/upload.service';

@Component({
  selector: 'app-progress-audio',
  templateUrl: './progress-audio.component.html',
  styleUrls: ['./progress-audio.component.scss']
})
export class ProgressAudioComponent implements OnInit {
  progressAudioVal$: Observable<number>;

  constructor(private _uploadService: UploadService) {}

  ngOnInit() {
    this.progressAudioVal$ = this._uploadService.progressAudio$;
  }
}
