import { Pipe, PipeTransform } from '@angular/core';
import { Voice } from '@purplefront/editor/data-access';
import { EditorService } from '../../../../data-access/src/lib/services';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'filterVoices'
})
export class FilterVoicesPipe implements PipeTransform {
  constructor(private _editorService: EditorService, private _translate: TranslateService) {}
  transform(voices: Voice[], searchText: string): Voice[] {
    if (!voices) return [];
    if (searchText === undefined) return voices;

    searchText = searchText.toUpperCase();

    return voices.filter((voice) =>
      Object.keys(voice).some((key) => {
        if (key === 'country') {
          return this._editorService.translateCountry(voice).toUpperCase().includes(searchText);
        } else if (key === 'language') {
          return this._editorService.translateLanguage(voice).toUpperCase().includes(searchText);
        } else if (key === 'label') {
          return voice[key].toUpperCase().includes(searchText);
        }
      })
    );
  }
}
