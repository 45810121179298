import { FormControl } from '@angular/forms';

export function requiredFileType(formats) {
  return function (control: FormControl) {
    const file = control.value;
    if (file) {
      const extension = file.name.split('.').pop().toLowerCase();
      if (!formats.includes(extension)) {
        return {
          requiredFileType: true
        };
      }

      return null;
    }

    return null;
  };
}
