import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import * as fromPreferencesStore from '@purplefront/preferences/data-access';
import { Store } from '@ngrx/store';

@Component({
  selector: 'purplefront-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {
  @Input() hasAcceptedCookies;
  @Output() openTermsOfService = new EventEmitter();
  domNavigationNodesIds = [
    'hamburger-span',
    'hamburger-link',
    'feed-card',
    'feed-card-img',
    'select-nav',
    'on-demand-nav',
    'search-icon',
    'header-search',
    'dropdown-item-en',
    'dropdown-item-fr',
    'dropdown-item-fr-en',
    'header-page-logo',
    'bell-icon',
    'user-name',
    'user-icon'
  ];

  constructor(private _preferencesStore: Store<fromPreferencesStore.PreferencesState>) {}

  ngOnInit() {}
  @HostListener('document:click', ['$event'])
  dismissCookieBanner(eventTarget) {
    if (this.domNavigationNodesIds.includes(eventTarget.target.id)) {
      this.acceptCookies();
    }
  }

  acceptCookies() {
    this._preferencesStore.dispatch(
      fromPreferencesStore.setCookieBanner({
        payload: { hasAcceptedCookies: true }
      })
    );
  }

  openTermsOfServiceModal($event) {
    $event.preventDefault();
    this.openTermsOfService.emit($event);
  }
}
