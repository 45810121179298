import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HeaderFacade, HeaderEffects, reducer } from '@purplefront/header/data-access';
import { HeaderUiModule } from '@purplefront/header/ui';
import { SharedModule, WINDOW_PROVIDERS } from '@purplefront/shared';

import { HeaderContainer } from './containers/header.container';
import { CustomThemeModalComponent } from '../../../../custom-theme-modal/ui/src/lib/components/custom-theme-modal/custom-theme-modal.component';
import { CustomThemeModalUiModule } from '@purplefront/custom-theme-modal/ui';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbModule,
        StoreModule.forFeature('header', reducer),
        EffectsModule.forFeature([HeaderEffects]),
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        HeaderUiModule,
        CustomThemeModalUiModule
    ],
    declarations: [HeaderContainer],
    providers: [HeaderFacade],
    exports: [HeaderContainer],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HeaderFeatureShellModule {}
