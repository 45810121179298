import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { AuthStatus } from '../_models';
import { AuthState } from '../_store/reducers';
import { isLogged, status } from '../_store/selectors';
import { AppRouterFacadeService } from '@purplefront/app-router/data-access';

@Injectable()
export class SessionGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<AuthState>,
    private appRouterFacade: AppRouterFacadeService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(status),
      filter((status: AuthStatus) => status !== AuthStatus.WAITING_FOR_SERVER),
      map(
        (status: AuthStatus) => status === AuthStatus.LOGGED
        // TODO: implement Anonymous auth logic
        // || status === AuthStatus.ANONYMOUS
      ),
      tap((hasSession: boolean) => {
        if (!hasSession) {
          this.appRouterFacade.redirectToLogin(state.url);
        }
        return false;
      })
    );
  }
}
