import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-socials-tab',
  templateUrl: './socials-tab.component.html',
  styleUrls: ['./socials-tab.component.scss']
})
export class SocialTab implements OnInit {
  constructor() {}

  ngOnInit() {}
}
