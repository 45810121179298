import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  PreferencesState,
  setFilterContentType,
  setFilterFrequency,
  setFilterSeason,
  setFilterSortBy,
  setFilterSource,
  setFilterViewMode
} from '@purplefront/preferences/data-access';
import { AuthState, getUserEmail } from '@purplefront/auth/data-access';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-content-filters-container',
  templateUrl: './content-filters.container.html',
  styleUrls: ['./content-filters.container.scss']
})
export class ContentFiltersContainer implements OnInit {
  userEmail$: Observable<string>;

  constructor(private _store: Store<PreferencesState>, private _authStore: Store<AuthState>) {}

  ngOnInit(): void {
    this.getUserEmail();
  }

  getUserEmail(): void {
    this.userEmail$ = this._authStore.pipe(select(getUserEmail));
  }

  setFilter($event: any) {
    switch ($event.key) {
      case 'view-mode':
        this._store.dispatch(setFilterViewMode({ payload: $event }));
        break;
      case 'sortBy':
        this._store.dispatch(setFilterSortBy({ payload: $event }));
        break;
      case 'content':
        this._store.dispatch(setFilterContentType({ payload: $event }));
        break;
      case 'frequency':
        this._store.dispatch(setFilterFrequency({ payload: $event }));
        break;
      case 'season':
        this._store.dispatch(setFilterSeason({ payload: $event }));
        break;
      case 'source':
        this._store.dispatch(setFilterSource({ payload: $event }));
        break;
    }
  }
}
