import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

interface TrackByFunctionCache {
  [propertyName: string]: <T>(index: number, item: T) => any;
}

const cache: TrackByFunctionCache = Object.create(null);

@Pipe({
  name: 'trackByProperty',
  pure: true
})
export class TrackByPropertyPipe implements PipeTransform {
  public transform(propertyName: string): Function {
    // Ensure cached function exists.
    if (!cache[propertyName]) {
      cache[propertyName] = function trackByProperty<T>(index: number, item: T): any {
        return item[propertyName];
      };
    }

    return cache[propertyName];
  }
}
