import { Component, OnInit } from '@angular/core';
import { InterfaceLanguageService } from '@purplefront/preferences/data-access';
import { Langs } from '@purplefront/shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-terms-or-services',
  templateUrl: './terms-or-services.component.html',
  styleUrls: ['./terms-or-services.component.scss']
})
export class TermsOrServicesComponent implements OnInit {
  lang: string;
  constructor(private _translate: TranslateService) {}

  ngOnInit(): void {
    this._initLang();
  }

  /**
   * Init default interface lang
   * @private
   */
  private _initLang() {
    const lang: Langs = <Langs>this._translate.getBrowserLang();
    if (lang === 'fr') {
      this.lang = 'fr';
    } else {
      this.lang = 'en';
    }
  }
}
