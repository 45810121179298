import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface Tag {
  name: string;
  doc: number;
}

@Component({
  selector: 'app-keywords',
  templateUrl: './keywords.component.html',
  styleUrls: ['./keywords.component.scss']
})
export class KeywordsComponent implements OnInit {
  @Input() keywords: Tag[];
  @Output() searchKeywords$ = new EventEmitter<{ query: string; event: any }>();

  constructor() {}

  ngOnInit(): void {}

  searchKeywords(keyword: string, $event): void {
    this.searchKeywords$.emit({ query: keyword, event: $event });
  }
}
