import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomThemeModalComponent } from './components/custom-theme-modal/custom-theme-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbModule],
    declarations: [CustomThemeModalComponent],
    exports: [CustomThemeModalComponent],
    bootstrap: [CustomThemeModalComponent]
})
export class CustomThemeModalUiModule {}
