import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as fromFlashBriefingStore from '@purplefront/flash-briefing/data-access';
import { Store } from '@ngrx/store';
import { EditorState } from '@purplefront/editor/data-access';
import { Subject } from 'rxjs';
import { EditorService } from '../../../../../../editor/data-access/src/lib/services/editor.service';
import { AudioPlayerService } from '../../../../../../audio-player/feature-shell/src/lib/_services';
import { PreferencesState } from '@purplefront/preferences/data-access';
import { removeSelectFlashBriefing } from '@purplefront/flash-briefing/data-access';
import { AppRouterFacadeService } from '@purplefront/app-router/data-access';

@Component({
  selector: 'rio-flash-briefing',
  templateUrl: './flash-briefing.component.html',
  styleUrls: ['./flash-briefing.component.scss']
})
export class FlashBriefingComponent implements OnInit, OnDestroy {
  @Input() selectedArticles: any;
  currentTab = 1;
  voicesData = [];
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private _formBuilder: FormBuilder,
    private _editorStore: Store<EditorState>,
    private _editorService: EditorService,
    public _audioPlayerService: AudioPlayerService,
    private _preferencesStore: Store<PreferencesState>,
    private _flashBriefingStore: Store<fromFlashBriefingStore.State>,
    private _appRouterFacade: AppRouterFacadeService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }

  removeArticleFromSelection(id: string) {
    this._flashBriefingStore.dispatch(removeSelectFlashBriefing({ id }));
  }
}
