import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  AppRouterService,
  AppRouterFacadeService,
  selectTag,
  selectSearchString
} from '@purplefront/app-router/data-access';
import { select, Store } from '@ngrx/store';
import { SearchPartialState } from '@purplefront/search/data-access';
import { debounceTime, distinctUntilChanged, filter, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import { resetForm } from '@larscom/ngrx-store-storagesync';
import {
  etxIconAccount,
  etxIconCloseWhite
} from '../../../../../../shared/src/lib/components/etx-icons/etx-icons.model';
import { EtxIconsRegistryService } from '../../../../../../shared/src/lib/components/etx-icons/etx-icons-registry.service';
import * as fromAuthStore from '@purplefront/auth/data-access';
import * as fromRouterStore from '@purplefront/app-router/data-access';
import { getUserName, isLogged } from '@purplefront/auth/data-access';
import { Langs, TrackingService, UtilsService, WINDOW } from '@purplefront/shared';
import { PreferencesState, selectContentLang, selectMobileMenuToggle } from '@purplefront/preferences/data-access';
import { EditorState, resetCurrentAudioStory, resetGeneratedFormats } from '@purplefront/editor/data-access';
import { Router } from '@angular/router';
import { CatalogState, folders, getFolders, setCurrentFolder, userFeeds } from '@purplefront/catalog/data-access';
import { AudioStoryState, openAudioStoryOverlay, resetGeneratedUrls } from '@purplefront/audio-story/data-access';
import * as fromHeaderStore from '@purplefront/header/data-access';
import * as fromPreferencesStore from '@purplefront/preferences/data-access';
import { Location } from '@angular/common';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { AfpRelaxnewsState } from '@purplefront/afp-relax/data-access';
import { OnDemandPreloadService } from 'libs/shared/src/lib/_services/on-demand-preload-service';

@Component({
  selector: 'app-menu-rio',
  templateUrl: './menu-rio.component.html',
  styleUrls: ['./menu-rio.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuRioComponent implements OnInit, OnDestroy {
  lang: Langs;
  userName$: Observable<any>;
  tags = [
    {
      label: 'green',
      value: 'green'
    },
    {
      label: 'healthy',
      value: 'healthy'
    },
    {
      label: 'perma',
      value: 'perma'
    },
    {
      label: 'trending',
      value: 'trending'
    },
    {
      label: 'useful',
      value: 'useful'
    },
    {
      label: 'aware',
      value: 'aware'
    },
    {
      label: 'audio first',
      value: 'first'
    }
  ];
  currentTag: string;
  feeds: any[];
  search: string;
  tag: string;
  toggleNavBar$: Observable<boolean>;
  mobile$: Observable<boolean>;
  tablet$: Observable<boolean>;
  desktop$: Observable<boolean>;
  _resize$: Observable<number>;
  currentPath: string;
  isLoggedIn: boolean;
  isMobile: boolean = false;
  folders: any;
  @Output() navToggled = new EventEmitter();
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();
  @ViewChild('mainMenu') mainMenu;

  constructor(
    private _formStore: Store<any>,
    public _authStore: Store<fromAuthStore.AuthState>,
    private _afpRelaxStore: Store<AfpRelaxnewsState>,
    private _appRouterFacade: AppRouterFacadeService,
    private _appRouterEffectService: AppRouterService,
    private _editorStore: Store<EditorState>,
    private _searchStore: Store<SearchPartialState>,
    private _etxIconsRegistry: EtxIconsRegistryService,
    public _routerStore: Store<fromRouterStore.AppRouterState>,
    private _prefStore: Store<PreferencesState>,
    private _catalogStore: Store<CatalogState>,
    private _router: Router,
    private _audioStoryStore: Store<AudioStoryState>,
    private _utilService: UtilsService,
    private _headerStore: Store<fromHeaderStore.HeaderState>,
    private _trackingService: TrackingService,
    private _location: Location,
    private _ngxSmartModal: NgxSmartModalService,
    private onDemandPreloadService: OnDemandPreloadService,
    @Inject(WINDOW) private window: Window
  ) {
    this._etxIconsRegistry.registerIcons([etxIconAccount, etxIconCloseWhite]);
  }

  @HostListener('document:click', ['$event.target'])
  clickedOutsideMenu(target) {
    this.mobile$.pipe(takeUntil(this._ngUnsubscribe$)).subscribe((isMobile: boolean) => {
      if (isMobile) {
        const insideMenu = this.mainMenu?.nativeElement.contains(target);
        if (!insideMenu && target.id !== 'hamburger-mobile-menu') {
          this.toggleNavbar(false);
        }
      }
    });
  }

  ngOnInit(): void {
    this._authStore
      .pipe(select(isLogged), takeUntil(this._ngUnsubscribe$))
      .subscribe((loggedIn) => (this.isLoggedIn = loggedIn));
    this._catalogStore
      .pipe(select(userFeeds), takeUntil(this._ngUnsubscribe$))
      .subscribe((feeds) => (this.feeds = feeds));

    this._routerStore
      .pipe(select(selectSearchString), takeUntil(this._ngUnsubscribe$))
      .subscribe((s) => (this.search = s));
    this._routerStore.pipe(select(selectTag), takeUntil(this._ngUnsubscribe$)).subscribe((t) => (this.tag = t));

    this.userName$ = this._authStore.pipe(
      takeUntil(this._ngUnsubscribe$),
      filter(() => this.isLoggedIn),
      select(getUserName)
    );

    this._routerStore
      .pipe(select(selectTag), takeUntil(this._ngUnsubscribe$))
      .subscribe((tag) => (this.currentTag = tag));

    this.getContentLang();
    this.getScreenWidth();
    this.initNav();
    this.setWindowWidth();
    this.getFoldersFromCatalog();
  }

  showMenu() {
    this.currentPath = this._location.path();
    return this.currentPath.indexOf('auth') === -1;
  }

  /**
   * Get screen width
   */
  public getScreenWidth(): void {
    this.mobile$ = this._headerStore.pipe(takeUntil(this._ngUnsubscribe$), select(fromHeaderStore.isMobile));
    this.tablet$ = this._headerStore.pipe(takeUntil(this._ngUnsubscribe$), select(fromHeaderStore.isTablet));
    this.desktop$ = this._headerStore.pipe(takeUntil(this._ngUnsubscribe$), select(fromHeaderStore.isDesktop));
  }

  /**
   * Initialize menu state (open / close)
   */
  initNav(): void {
    this.toggleNavBar$ = this._prefStore.pipe(select(selectMobileMenuToggle));
    combineLatest([this.mobile$, this.tablet$, this.desktop$])
      .pipe(debounceTime(0))
      .subscribe(([mobile, tablet, desktop]) => {
        this.isMobile = mobile;
      });
  }

  /**
   * send the new windowWidth to the store
   */
  setWindowWidth(): void {
    this._resize$ = fromEvent(window, 'resize').pipe(
      takeUntil(this._ngUnsubscribe$),
      debounceTime(200),
      map(() => this.window.innerWidth),
      distinctUntilChanged(),
      startWith(this.window.innerWidth),
      tap((width) => this._headerStore.dispatch(fromHeaderStore.setScreen({ payload: { windowWidth: width } })))
    );
    this.initNav();
    this._resize$.subscribe();
  }

  toggleNavbar(open) {
    this._prefStore.dispatch(fromPreferencesStore.toggleMobileMenu({ payload: { open } }));
  }

  closeGenerationOverlay(): void {
    const open = false;
    this._audioStoryStore.dispatch(openAudioStoryOverlay({ payload: { open } }));
  }

  getContentLang() {
    this._prefStore
      .select(selectContentLang)
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe((lang) => (this.lang = lang));
  }

  getFoldersFromCatalog(): void {
    this._catalogStore
      .pipe(
        select(folders),
        filter((res) => res.length !== 0),
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe((res) => (this.folders = res));
  }

  preloadBundle(routePath) {
    this.onDemandPreloadService.startPreload(routePath);
  }

  goToDiscover() {
    if (!this.isLoggedIn) {
      this.openNaggingModal();
      return;
    }
    this.trackEvent('menu-discover');
    this.closeGenerationOverlay();
    this._router.navigate(['discover', this.lang], { queryParamsHandling: 'merge' });
  }

  goToStudio() {
    if (!this.isLoggedIn) {
      this.openNaggingModal();
      return;
    }
    this.trackEvent('menu-studio');
    this.closeGenerationOverlay();
    this._appRouterFacade.goToWorkspace();
  }

  goToSearchByTag(tag, $event) {
    if (!this.isLoggedIn) {
      this.openNaggingModal();
      return;
    }
    this._trackingService
      .trackEvent({ type: `menu-filter-${tag}` })
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe();
    this.closeGenerationOverlay();
    this._appRouterFacade.goToDiscoverPage(tag);
  }

  goToSearchByFolder(folder: any, $event: MouseEvent) {
    if (!this.isLoggedIn) {
      this.openNaggingModal();
      return;
    }
    this._catalogStore.dispatch(setCurrentFolder({ folder: folder }));
    this._appRouterFacade.goToDiscoverPage(undefined, undefined, folder);
  }
  goToEditor() {
    if (!this.isLoggedIn) {
      this.openNaggingModal();
      return;
    }
    this.trackEvent('menu-editor');
    this.closeGenerationOverlay();
    this._appRouterFacade.goToEditor(this.lang);
  }

  newAudioStory() {
    this.trackEvent('menu-studio-new');
    this._editorStore.dispatch(resetCurrentAudioStory());
    this._editorStore.dispatch(resetGeneratedFormats());
    this._audioStoryStore.dispatch(resetGeneratedUrls());
    this._audioStoryStore.dispatch(openAudioStoryOverlay({ payload: { open: false } }));
    this._router.navigate(['workspace/editor', this.lang], { queryParams: { newStory: true } });
  }

  clearEditorForm(): void {
    this._formStore.dispatch(resetForm({ id: 'editorFormID' }));
  }

  goToMyAudioContents() {
    if (!this.isLoggedIn) {
      this.openNaggingModal();
      return;
    }
    this.trackEvent('menu-creations');
    this._appRouterFacade.goToMyAudioContents(this.lang);
  }

  goToBookmarks() {
    this.trackEvent('menu-bookmark');
    this._appRouterFacade.gotoBookmarksPage();
  }

  goToPreferencesPage(): void {
    if (!this.isLoggedIn) {
      this.openNaggingModal();
      return;
    }
    this.trackEvent('menu-preferences');
    this._appRouterFacade.gotoPreferencesPage(this.lang, {
      section: 'general'
    });
  }

  toggleSearch() {
    if (!this.isLoggedIn) {
      this.openNaggingModal();
      return;
    }
    this.trackEvent('menu-search');
    this._router.navigate(['searchPage', this.lang], { queryParamsHandling: 'merge' });
    if (this.isMobile) {
      this.toggleNavbar(false);
    }
  }

  logout(): void {
    this.trackEvent('menu-log-out');
    this._authStore.dispatch(fromAuthStore.logout());
  }

  trackEvent(type: string) {
    this._trackingService.trackEvent({ type: type }).pipe(takeUntil(this._ngUnsubscribe$)).subscribe();
  }

  openNaggingModal() {
    if (!this.isLoggedIn) {
      this._ngxSmartModal.open('auth-nagging-modal');
      return;
    }
  }

  openHelpModal(): void {
    this._ngxSmartModal.open('help-modal');
  }

  /**
   * check active tag
   * @param tag
   */
  isActiveTag = (tag) => this.currentTag === tag;

  /**
   * check active menu
   * @param menu
   */
  isActiveMenu = (menu: string) => this._router.url.indexOf(menu) !== -1;

  /**
   * Prevent memory leaks unsubscribe when destroy component
   */
  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
