<div *ngIf="currentTab === 1" class="container-fluid">
  <div class="row mt-5">
    <div class="col-sm-12 text-center">
      <h3>Hey, préparons ensemble ton Récap</h3>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-sm-4 offset-sm-3">
      <h6>Saisissez le titre de votre Récap</h6>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-sm-6 offset-sm-3">
      <div class="input-group input-group-lg">
        <input
          placeholder="Titre du Récap"
          type="text"
          class="form-control"
          aria-label="Large"
          aria-describedby="inputGroup-sizing-sm"
        />
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-sm-6 offset-sm-3">
      <h6>Voulez vous bien ces {{ selectedArticles?.length }} articles dans votre Récap ?</h6>
    </div>
  </div>
  <div *ngIf="selectedArticles" class="row mt-3">
    <div class="col-sm-6 offset-sm-3 flash-recap">
      <div *ngFor="let article of selectedArticles" class="card m-4 flash-briefing-card">
        <div class="row no-gutters d-flex">
          <div class="col-auto">
            <img src="{{ article.images[0].s_default }}" class="img-fluid" alt="" />
          </div>
          <div class="col">
            <div class="card-block px-2 d-flex flex-column">
              <h4 class="card-title mt-3">{{ article.title }}</h4>
              <h6 class="card-subtitle mt-auto mb-3">ETX Studio • {{ article.publication_date | date }}</h6>
            </div>
          </div>
          <div class="col-sm-1 mt-auto mb-auto">
            <i (click)="removeArticleFromSelection(article.id)" class="fa fa-trash-o" aria-hidden="true"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selectedArticles" class="row mt-5 mb-5">
    <div class="col-sm-12 d-flex justify-content-center text-center">
      <button (click)="goToEditor()" type="button" class="btn btn-primary next-button btn-lg">Suivant</button>
    </div>
  </div>
</div>
