import { Component, OnInit } from '@angular/core';
import { UtilsService } from '@purplefront/shared';
import * as fromPreferencesStore from '@purplefront/preferences/data-access';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit {
  toggledNavBar = false;
  constructor(public _preferencesStore: Store<fromPreferencesStore.PreferencesState>, private _router: Router) {}

  ngOnInit(): void {}

  toggleNavBar() {
    this._preferencesStore.dispatch(fromPreferencesStore.toggleMobileMenu({ payload: { open: true } }));
  }

  showFilterMenu(): boolean {
    const pagesWithFilerMenu = 'discover';
    const currentUrl = this._router.url.substring(0, this._router.url.lastIndexOf('/'));
    return currentUrl.indexOf(pagesWithFilerMenu) !== -1;
  }
}
