import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nrwl/angular';

import * as fromPlaylist from './playlist.reducer';
import * as PlaylistActions from './playlist.actions';

@Injectable()
export class PlaylistEffects {
  // loadPlaylist$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(PlaylistActions.loadPlaylist),
  //     // API call when playlist will be persistent
  //   )
  // );

  constructor(private actions$: Actions) {}
}
