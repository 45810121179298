<div class="d-flex flex-column justify-content-center align-items-center">
  <img
    width="150px"
    [routerLink]="['../login']"
    src="assets/images/logos/logo-etx-daily-up.svg"
    alt="ETX Daily UP"
    class="col-sm-2 mx-auto mt-4"
  />
  <div class="mt-5">
    <h1>{{ 'AUTH_ACCOUNT_VALIDATION' | translate }}</h1>
    &nbsp;

    <ngb-alert *ngIf="(queryState$ | async) === 'waiting'" type="warning" [dismissible]="false">
      <strong>{{ 'AUTH_WAITING_ACTIVATION' | translate }}</strong></ngb-alert
    >

    <ngb-alert *ngIf="(queryState$ | async) === 'ok'" type="success" [dismissible]="false">
      <strong>OK</strong></ngb-alert
    >

    <ngb-alert *ngIf="(queryState$ | async) === 'invalidCodeError'" type="danger" [dismissible]="false">
      <strong>{{ 'AUTH_INVALID_CODE_ACTIVATION' | translate }}</strong></ngb-alert
    >
  </div>
  <p *ngIf="(queryState$ | async) === 'ok'" class="alert alert-success">
    {{ 'AUTH_REDIRECT_TO_LOGIN_PAGE' | translate }}
  </p>
</div>
