<div id="search-container">
  <app-header-mobile class="d-md-none d-lg-block"></app-header-mobile>
  <section class="modal-search ms-4 me-4">
    <div class="container d-flex flex-column">
      <app-etx-icons name="close" (click)="closeModalSearch($event)" class="search-close"></app-etx-icons>
      <div class="col-lg-12 text-center">
        <h2 class="mt-5 mb-3">{{ 'SEARCH' | translate }}</h2>
        <form (submit)="search(searchInput.value)" class="form-inline d-inline-block mw-100 navbar-search">
          <div class="input-group">
            <input
              #searchInput
              id="header-search-input"
              [value]="searchString"
              name="searchBar"
              placeholder="{{ 'SEARCH_TYPE_TO_SEARCH' | translate }}"
              [formControl]="searchControl"
              autocomplete="off"
              autofocus
              class="bg-light form-control border-0"
              type="text"
            />
            <button
              *ngIf="searchString$ | async"
              (click)="clearSearchTerm()"
              type="button"
              aria-label="Close"
              class="btn-close word-close ng-star-inserted"
            >
            </button>
            <div class="input-group-append">
              <button (click)="search(searchInput.value)" class="btn btn-search py-0" type="button">
                <app-etx-icons name="search_white"></app-etx-icons>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="row mt-5" *ngIf="searchLoaded$ | async">
        <app-keywords
          *ngIf="!(searchString$ | async)"
          class="col-md-8"
          [keywords]="keywords$ | async"
          (searchKeywords$)="search($event.query); trackKeywordClick($event.query, $event.event)"
        ></app-keywords>
        <app-suggest-list
          class="col-md-7"
          *ngIf="searchString$ | async"
          (search$)="search($event.query); trackSuggestClick($event.query, $event.event, $event.searchEntity)"
          [searchResponse]="searchResponse$ | async"
          [searchString]="searchString$ | async"
          [searchLoaded]="searchLoaded$ | async"
        >
        </app-suggest-list>
        <app-latest-articles
          (searchString$)="search($event)"
          (openArticle$)="openArticle($event)"
          [latestArticles]="latestArticles$ | async"
          class="col-md-4 ms-auto"
        ></app-latest-articles>
      </div>
    </div>
  </section>
</div>
<div class="loader" *ngIf="!(searchLoaded$ | async)">
  <app-loading></app-loading>
</div>
