import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@purplefront/shared';

@Injectable()
export class ArticleApi {
  private basicApiUrl: string = environment.purpleApi;

  constructor(private http: HttpClient) {}

  public getArticle(article): Observable<string> {
    const url = `${this.basicApiUrl}/content/documents/${article.sourceType}/${article.articleLang}/${article.articleId}`;
    return this.http.get(url).pipe(
      map((res: any) => {
        if (res && res.data && res.data.length) {
          return Object.keys(res.data[0]).length > 0 ? res.data[0] : of([]);
        }
      })
    );
  }

  public getMoreLikeArticles(payload): Observable<any> {
    if (!payload.article.id) {
      return of([]);
    }
    const url = `${this.basicApiUrl}/content/more-like?like=${payload.article.id}&lang=${payload.article.lang}&&sortOrder=desc&minScore=0.6&size=3&from=now-1y&to=now`;
    return this.http.get(url).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  public getRelatedArticles(id): Observable<any> {
    if (!id) {
      return of([]);
    }
    const url = `${this.basicApiUrl}/content/more-like?like=${id}&lang=en&&sortOrder=desc&minScore=0.6&size=30`;
    return this.http.get(url).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }

  public mailArticle(id, type, name, email): Observable<string> {
    const body = {
      article_id: id,
      index: 'rn_en',
      type: type,
      recipient_name: name,
      recipient_email: email
    };
    const url = `${this.basicApiUrl}/users/send_news_via_email`;
    return this.http.post(url, body).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public downloadImage(sourceType, lang, id, index): Observable<string> {
    const imageDownloadUrl = `https://samba-dev.etx.studio/assets/v1/image/downloadOriginalImage/${sourceType}/${lang}/${id}?index=${index}`;
    return this.http.get(imageDownloadUrl).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public getLatestArticles(params: HttpParams, articleId: string): Observable<any> {
    const url = `${this.basicApiUrl}/content/search`;
    return this.http.get(url, { params: params }).pipe(
      map((res) =>
        res['data'].filter((article) => {
          return article.id !== articleId;
        })
      )
    );
  }

  public getArticleByCategoryObservable(params: any): Array<any> {
    const categories: Array<string> = params.userFeedsSlugs;
    const lang: string = params.lang ?? 'fr';
    const promise = [];
    categories.map((category) => {
      const filters = this.getFilters(category);
      const tag = params.tag ? `&filters[terms][]=${params.tag}` : '';
      const source = params?.source !== 'All' ? `&filters[platformName][]=${params.source}` : '';
      const search = params.search ? `&q=${params.search}` : '';
      const url = `${this.basicApiUrl}/content/search?from=now-1y&to=now&lang=${lang}&size=6&offset=0&sortType=date&sortOrder=desc&type=rn${filters}${tag}${source}${search}&filters[imageSpecialUses][]=-Paris Modes Insider&filters[videotypes][]=-news&=`;
      promise.push({
        observable: this.http.get(url),
        category
      });
    });
    return promise;
  }

  private getFilters(category: string): string {
    let filtersUrl = '';
    filtersUrl += `&filters[feeds][${category}]=${category}`;
    return filtersUrl;
  }
}
