import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-jingle-modal',
  templateUrl: './jingle-modal.component.html',
  styleUrls: ['./jingle-modal.component.scss']
})
export class JingleModalComponent implements OnInit, OnChanges {
  @Input() jingle: boolean;
  @Input() itemIndex: number;
  @Output() onFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  jingleForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.jingle && changes.jingle.previousValue !== changes.jingle.currentValue) {
      this.patchFormValue(changes.jingle.currentValue);
    }
  }

  initForm(): void {
    this.jingleForm = this.fb.group({
      jingle: [true],
      applyGlobally: [false]
    });

    //this.patchFormValue(this.jingle);
  }

  private patchFormValue(jingle) {
    this.jingleForm?.patchValue({
      jingle: jingle
    });
  }

  onCheckboxChange(event) {
    this.jingleForm.patchValue({ applyGlobally: event.target.checked });
  }

  submitForm() {
    this.onFormSubmit.next(this.jingleForm.value);
  }
}
