import { createAction, props, union } from '@ngrx/store';
import { AudioStory, StoryItem } from '../../_models';

export enum AudioStoryActionTypes {
  LOAD_AUDIO_STORIES = '[AudioStories] Load AudioStories ',
  LOAD_AUDIO_STORIES_FAILURE = '[AudioStories] Load AudioStories Failure',
  LOAD_AUDIO_STORIES_SUCCESS = '[AudioStories] Load AudioStories Success',
  ADD_AUDIO_STORY = '[AudioStories] Add AudioStory',
  ADD_AUDIO_STORY_FAILURE = '[AudioStories] Add AudioStory Failure',
  ADD_AUDIO_STORY_SUCCESS = '[AudioStories] Add AudioStory Success',
  REMOVE_AUDIO_STORY = '[AudioStories] Remove AudioStory',
  REMOVE_AUDIO_STORY_FAILURE = '[AudioStories] Remove AudioStory fail',
  REMOVE_AUDIO_STORY_SUCCESS = '[AudioStories] Remove AudioStory success',
  REMOVE_ALL_AUDIO_STORIES = '[AudioStories] Remove All AudioStory',
  REMOVE_ALL_AUDIO_STORIES_SUCCESS = '[AudioStories] Remove All AudioStory success',
  REMOVE_ALL_AUDIO_STORIES_FAIL = '[AudioStories] Remove All AudioStory Failure',
  REMOVE_MULTIPLE_AUDIO_STORIES = '[AudioStories] Remove Multiple AudioStories',
  REMOVE_MULTIPLE_AUDIO_STORIES_SUCCESS = '[AudioStories] Remove Multiple AudioStories Success',
  REMOVE_MULTIPLE_AUDIO_STORIES_FAILURE = '[AudioStories] Remove Multiple AudioStories Failure',
  UPDATE_AUDIO_STORY = '[AudioStories] Update AudioStory',
  UPDATE_AUDIO_STORY_SUCCESS = '[AudioStories] Update AudioStory Success',
  UPDATE_AUDIO_STORY_FAILURE = '[AudioStories] Update AudioStory Failure',
  PLAY_PAUSE_AUDIO = '[AudioStories] Play & Pause article audio',
  SET_CURRENT_STORY_ITEM = '[AudioStories] Set current Story item',
  SET_CURRENT_AUDIO_STORY = '[AudioStories] Set current audio Story ',
  GENERATE_AUDIO_STORY_ITEM = '[AudioStories] Generate audio story item',
  GENERATE_AUDIO_STORY_ITEM_SUCCESS = '[AudioStories] Generate audio story item success',
  GENERATE_AUDIO_STORY_ITEM_FAIL = '[AudioStories] Generate audio story item fail',
  GENERATE_AUDIO_FORMAT = '[AudioStories] Generate Audio Format',
  GENERATE_AUDIO_FORMAT_SUCCESS = '[AudioStories] Generate Audio Format Success',
  GENERATE_AUDIO_FORMAT_FAIL = '[AudioStories] Generate Audio Format Fail',
  GENERATE_MOBILE_FORMAT = '[AudioStories] Generate Mobile Format',
  GENERATE_MOBILE_FORMAT_SUCCESS = '[AudioStories] Generate Mobile Format success',
  GENERATE_MOBILE_FORMAT_FAIL = '[AudioStories] Generate Portrait Format fail',
  GENERATE_SQUARE_FORMAT = '[AudioStories] Generate Square Format',
  GENERATE_SQUARE_FORMAT_SUCCESS = '[AudioStories] Generate Square Format Success',
  GENERATE_SQUARE_FORMAT_FAIL = '[AudioStories] Generate Square Format Fail',
  GENERATE_LANDSCAPE_FORMAT = '[AudioStories] Generate Landscape Format',
  GENERATE_LANDSCAPE_FORMAT_SUCCESS = '[AudioStories] Generate Landscape Format Success',
  GENERATE_LANDSCAPE_FORMAT_FAIL = '[AudioStories] Generate Landscape Format Fail',
  OPEN_AUDIO_STORY_OVERLAY = '[AudioStories] toggle search nav',
  RESET_GENERATED_URLS = '[AudioStories] Reset Generated URLs',
  START_MOBILE_FORMAT_POLLING = '[AudioStories] Start Mobile Format Polling',
  START_SQUARE_FORMAT_POLLING = '[AudioStories] Start Square Format Polling',
  START_AUDIO_FORMAT_POLLING = '[AudioStories] Start Audio Format Polling',
  START_LANDSCAPE_FORMAT_POLLING = '[AudioStories] Start Landscape Format Polling',
  SET_MOBILE_RENDER_STATUS = '[AudioStories] Set Mobile Render Status',
  SET_SQUARE_RENDER_STATUS = '[AudioStories] Set Square Render Status',
  SET_AUDIO_RENDER_STATUS = '[AudioStories] Set Audio Render Status',
  SET_LANDSCAPE_RENDER_STATUS = '[AudioStories] Set Landscape Render Status',
  CLONE_AUDIO_STORY = '[AudioStories] Clone Audio Story',
  CLONE_AUDIO_STORY_SUCCESS = '[AudioStories] Clone Audio Story Success',
  CLONE_AUDIO_STORY_FAIL = '[AudioStories] Clone Audio Story Fail',
  RESET_AUDIOSTORIES_ENTITIES = '[AudioStories] Reset Entities',
  RESET_GENERATION_ERROR = '[AudioStories] Reset Generation Error',
  AUTOMATIC_TRANSLATION_ERROR = '[AudioStories] Automatic Translation Error',
  RETRY_TRANSLATED_GENERATION = '[AudioStories] Retry Translated Generation'
}

export const retryTranslatedGeneration = createAction(
  AudioStoryActionTypes.RETRY_TRANSLATED_GENERATION,
  props<{ payload: any }>()
);

export const resetGeneratedUrls = createAction(AudioStoryActionTypes.RESET_GENERATED_URLS);

export const resetAudiostoriesEntities = createAction(AudioStoryActionTypes.RESET_AUDIOSTORIES_ENTITIES);

export const resetGenerationError = createAction(AudioStoryActionTypes.RESET_GENERATION_ERROR);

export const openAudioStoryOverlay = createAction(
  AudioStoryActionTypes.OPEN_AUDIO_STORY_OVERLAY,
  props<{ payload: { open: boolean } }>()
);

export const startMobileFormatPolling = createAction(
  AudioStoryActionTypes.START_MOBILE_FORMAT_POLLING,
  props<{ payload: any }>()
);

export const startSquareFormatPolling = createAction(
  AudioStoryActionTypes.START_SQUARE_FORMAT_POLLING,
  props<{ payload: any }>()
);

export const startAudioFormatPolling = createAction(
  AudioStoryActionTypes.START_AUDIO_FORMAT_POLLING,
  props<{ payload: any }>()
);

export const startLandscapeFormatPolling = createAction(
  AudioStoryActionTypes.START_LANDSCAPE_FORMAT_POLLING,
  props<{ payload: any }>()
);

export const generateAudioFormat = createAction(AudioStoryActionTypes.GENERATE_AUDIO_FORMAT, props<{ payload: any }>());

export const generateAudioFormatSuccess = createAction(
  AudioStoryActionTypes.GENERATE_AUDIO_FORMAT_SUCCESS,
  props<{ payload: any }>()
);

export const generateAudioFormatFail = createAction(
  AudioStoryActionTypes.GENERATE_AUDIO_FORMAT_FAIL,
  props<{ error: any }>()
);

export const setAudioRenderStatus = createAction(
  AudioStoryActionTypes.SET_AUDIO_RENDER_STATUS,
  props<{ payload: any }>()
);

export const generateMobileFormat = createAction(
  AudioStoryActionTypes.GENERATE_MOBILE_FORMAT,
  props<{ payload: any }>()
);

export const generateMobileFormatSuccess = createAction(
  AudioStoryActionTypes.GENERATE_MOBILE_FORMAT_SUCCESS,
  props<{ payload: any }>()
);

export const generateMobileFormatFail = createAction(
  AudioStoryActionTypes.GENERATE_MOBILE_FORMAT_FAIL,
  props<{ error: any }>()
);

export const setMobileRenderStatus = createAction(
  AudioStoryActionTypes.SET_MOBILE_RENDER_STATUS,
  props<{ payload: any }>()
);

export const generateSquareFormat = createAction(
  AudioStoryActionTypes.GENERATE_SQUARE_FORMAT,
  props<{ payload: any }>()
);
export const generateSquareFormatSuccess = createAction(
  AudioStoryActionTypes.GENERATE_SQUARE_FORMAT_SUCCESS,
  props<{ payload: any }>()
);
export const generateSquareFormatFail = createAction(
  AudioStoryActionTypes.GENERATE_SQUARE_FORMAT_FAIL,
  props<{ error: any }>()
);

export const setSquareRenderStatus = createAction(
  AudioStoryActionTypes.SET_SQUARE_RENDER_STATUS,
  props<{ payload: any }>()
);

export const generateLandscapeFormat = createAction(
  AudioStoryActionTypes.GENERATE_LANDSCAPE_FORMAT,
  props<{ payload: any }>()
);

export const generateLandscapeFormatSuccess = createAction(
  AudioStoryActionTypes.GENERATE_LANDSCAPE_FORMAT_SUCCESS,
  props<{ payload: any }>()
);

export const generateLandscapeFormatFail = createAction(
  AudioStoryActionTypes.GENERATE_LANDSCAPE_FORMAT_FAIL,
  props<{ error: any }>()
);

export const setLandscapeRenderStatus = createAction(
  AudioStoryActionTypes.SET_LANDSCAPE_RENDER_STATUS,
  props<{ payload: any }>()
);

export const loadAudioStories = createAction(
  AudioStoryActionTypes.LOAD_AUDIO_STORIES,
  props<{ page: string | number }>()
);

export const loadAudioStoriesSuccess = createAction(
  AudioStoryActionTypes.LOAD_AUDIO_STORIES_SUCCESS,
  props<{ payload: { audioStories: AudioStory[]; lastPage: number | string; total: number | string } }>()
);

export const loadAudioStoriesFailure = createAction(
  AudioStoryActionTypes.LOAD_AUDIO_STORIES_FAILURE,
  props<{ payload: { error: any } }>()
);

export const cloneAudioStory = createAction(AudioStoryActionTypes.CLONE_AUDIO_STORY, props<{ payload: any }>());

export const cloneAudioStorySuccess = createAction(
  AudioStoryActionTypes.CLONE_AUDIO_STORY_SUCCESS,
  props<{ payload: any }>()
);

export const cloneAudioStoryFail = createAction(
  AudioStoryActionTypes.CLONE_AUDIO_STORY_FAIL,
  props<{ payload: { error: any } }>()
);
export const addAudioStory = createAction(AudioStoryActionTypes.ADD_AUDIO_STORY, props<{ payload: any }>());

export const addAudioStoryFailure = createAction(
  AudioStoryActionTypes.ADD_AUDIO_STORY_FAILURE,
  props<{ payload: { error: any } }>()
);

export const addAudioStorySuccess = createAction(AudioStoryActionTypes.ADD_AUDIO_STORY_SUCCESS, props<{ payload }>());

export const removeAudioStory = createAction(AudioStoryActionTypes.REMOVE_AUDIO_STORY, props<{ payload: any }>());

export const removeAudioStoryFail = createAction(
  AudioStoryActionTypes.REMOVE_AUDIO_STORY_FAILURE,
  props<{ payload: { error: any } }>()
);

export const removeAudioStorySuccess = createAction(
  AudioStoryActionTypes.REMOVE_AUDIO_STORY_SUCCESS,
  props<{ payload: any }>()
);

export const removeAllAudioStories = createAction(AudioStoryActionTypes.REMOVE_ALL_AUDIO_STORIES);

export const removeAllAudioStoriesSuccess = createAction(AudioStoryActionTypes.REMOVE_ALL_AUDIO_STORIES_SUCCESS);

export const removeAllAudioStoriesFail = createAction(
  AudioStoryActionTypes.REMOVE_ALL_AUDIO_STORIES_FAIL,
  props<{ payload: { error: any } }>()
);

export const removeMultipleAudioStories = createAction(
  AudioStoryActionTypes.REMOVE_MULTIPLE_AUDIO_STORIES,
  props<{ payload: number[] }>()
);

export const removeMultipleAudioStoriesSuccess = createAction(
  AudioStoryActionTypes.REMOVE_MULTIPLE_AUDIO_STORIES_SUCCESS,
  props<{ payload: number[] }>()
);

export const removeMultipleAudioStoriesFail = createAction(
  AudioStoryActionTypes.REMOVE_MULTIPLE_AUDIO_STORIES_FAILURE,
  props<{ payload: { error: any } }>()
);

export const updateAudioStory = createAction(AudioStoryActionTypes.UPDATE_AUDIO_STORY, props<{ payload }>());

export const updateAudioStorySuccess = createAction(
  AudioStoryActionTypes.UPDATE_AUDIO_STORY_SUCCESS,
  props<{ payload }>()
);

export const updateAudioStoryFailure = createAction(
  AudioStoryActionTypes.UPDATE_AUDIO_STORY_FAILURE,
  props<{ payload: { error: any } }>()
);

export const generateAudioStoryItem = createAction(
  AudioStoryActionTypes.GENERATE_AUDIO_STORY_ITEM,
  props<{ payload: any }>()
);

export const generateAudioStoryItemSuccess = createAction(
  AudioStoryActionTypes.GENERATE_AUDIO_STORY_ITEM_SUCCESS,
  props<{ payload: any }>()
);

export const generateAudioStoryItemFail = createAction(
  AudioStoryActionTypes.GENERATE_AUDIO_STORY_ITEM_FAIL,
  props<{ payload: any }>()
);

export const playPauseAudio = createAction(AudioStoryActionTypes.PLAY_PAUSE_AUDIO, props<{ payload: any }>());

export const setCurrentStoryItem = createAction(
  AudioStoryActionTypes.SET_CURRENT_STORY_ITEM,
  props<{ payload: StoryItem }>()
);

export const setCurrentAudioStory = createAction(
  AudioStoryActionTypes.SET_CURRENT_AUDIO_STORY,
  props<{ payload: AudioStory }>()
);

export const translateFail = createAction(
  AudioStoryActionTypes.AUTOMATIC_TRANSLATION_ERROR,
  props<{ audioStory: AudioStory }>()
);

const all = union({
  loadAudioStories,
  loadAudioStoriesFailure,
  loadAudioStoriesSuccess,
  removeAllAudioStories,
  removeAllAudioStoriesSuccess,
  removeAllAudioStoriesFail,
  removeAudioStory,
  removeAudioStorySuccess,
  removeAudioStoryFail,
  addAudioStory,
  addAudioStoryFailure,
  addAudioStorySuccess,
  removeMultipleAudioStories,
  removeMultipleAudioStoriesSuccess,
  removeMultipleAudioStoriesFail,
  updateAudioStory,
  updateAudioStorySuccess,
  updateAudioStoryFailure,
  playPauseAudio,
  setCurrentStoryItem,
  setCurrentAudioStory,
  generateAudioStoryItem,
  generateAudioStoryItemSuccess,
  generateAudioStoryItemFail,
  openAudioStoryOverlay,
  startSquareFormatPolling,
  startMobileFormatPolling,
  startAudioFormatPolling,
  cloneAudioStory,
  cloneAudioStorySuccess,
  cloneAudioStoryFail,
  resetAudiostoriesEntities,
  generateLandscapeFormat,
  generateLandscapeFormatSuccess,
  generateLandscapeFormatFail,
  setLandscapeRenderStatus,
  startLandscapeFormatPolling,
  translateFail
});

export type AudioStoryActions = typeof all;
