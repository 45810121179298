import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { BaseUrlService, Langs, UtilsService } from '@purplefront/shared';

import { Catalog, CatalogState, getFolders } from '@purplefront/catalog/data-access';
import { select, Store } from '@ngrx/store';
import { PreferencesState, selectContentLang } from '@purplefront/preferences/data-access';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-catalog-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <rio-flash-briefing-selection></rio-flash-briefing-selection>
    <app-content-filters-container></app-content-filters-container>
    <!--<app-menu-rio-container></app-menu-rio-container>-->
    <div layoutContainer class="layout-container">
      <div>
        <app-header-rio-container></app-header-rio-container>
        <router-outlet></router-outlet>
        <app-cookie-banner></app-cookie-banner>
        <app-search-modal></app-search-modal>
      </div>
    </div>
  `,
  styleUrls: ['./catalog.container.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CatalogContainer implements OnInit, OnDestroy {
  public catalog$: Observable<Catalog>;
  public navOpen: boolean;
  isMobisationMenu: boolean;
  isEditorializerMenu: boolean;
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private _utilsService: UtilsService,
    private _baseUrlService: BaseUrlService,
    private _catalogStore: Store<CatalogState>,
    private _prefStore: Store<PreferencesState>
  ) {}

  navToggledHandler(event: boolean) {
    this.navOpen = event;
    this._utilsService.resizeFn();
  }

  ngOnInit(): void {
    this.getFoldersFromCatalog();
    this.isEditorializerMenu = this._baseUrlService.isEditorializerApp();
    this.isMobisationMenu = this._baseUrlService.isMobisationApp();
  }

  getFoldersFromCatalog(): void {
    this._prefStore
      .pipe(
        select(selectContentLang),
        tap((lang: Langs) => {
          this._catalogStore.dispatch(getFolders({ payload: { contentLang: lang, currentFeed: null } }));
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
