<div class="container page-not-found">
  <img
    class="img-fluid d-block mx-auto mt-5 mb-2"
    width="800px"
    height="300px"
    src="assets/images/feeds/etx-dailyup-404.png"
  />
  <h3>{{ 'PAGE_NOT_FOUND' | translate }}</h3>
  <div class="text-center mt-4">
    <a class="btn btn-etx-darkened btn-xl" role="button" (click)="goToDiscover()"
      ><span>{{ 'PAGE_NOT_FOUND_BACK' | translate }}</span></a
    >
  </div>
</div>
