<ngx-smart-modal #multiImage identifier="multiImage" class="multi-images" [customClass]="'large-modal'">
  <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center" class="header-wrapper position-relative">
    <h2>
      <span class="position-relative">{{ 'EDIT_IMAGES' | translate }}</span
      ><app-loading *ngIf="isLoading$ | async" [inline]="true" class="position-absolute"></app-loading>
    </h2>
  </div>

  <div class="text-center drag-info mb-4">{{ 'DRAG_AND_DROP_ORDER' | translate }}</div>

  <form [formGroup]="multiImageForm">
    <div cdkDropListGroup class="d-flex flex-row flex-wrap image-drop-area">
      <input
        multiple
        (click)="onInputClick($event)"
        (change)="onImageChange($event, null, true)"
        type="file"
        id="multi-image"
      />
      <ng-container *ngFor="let imageControl of images.controls; let i = index">
        <div
          formArrayName="images"
          cdkDropList
          cdkDropListOrientation="horizontal"
          [cdkDropListData]="{ item: imageControl, index: i }"
          (cdkDropListDropped)="drop($event)"
        >
          <div class="image-box" cdkDrag>
            <label for="multi-image{{ i }}" (mouseover)="onImageMouseOver(i)" (mouseout)="onImageMouseLeave(i)">
              <span
                class="edit-multi-img"
                [ngClass]="{
                  'hover-edit-multi-img': isHovered[i] === true
                }"
                ><i class="fa fa-pencil"></i
              ></span>
              <span
                class="edit-upload-img-delete"
                (click)="deleteImage($event, i)"
                [ngClass]="{
                  'hover-edit-upload-img-delete': isHovered[i] === true
                }"
                ><i aria-hidden="true" class="fa fa-times"></i
              ></span>
              <img
                *ngIf="hasImage"
                alt="user-uploaded-img"
                height="250"
                width="250"
                src="{{ (imageControl.valueChanges | async) || imageControl.value }}"
              />
            </label>
            <input
              (click)="onInputClick($event)"
              (change)="onImageChange($event, i)"
              type="file"
              id="multi-image{{ i }}"
            />
          </div>
        </div>
      </ng-container>
      <label class="label-box" *ngIf="images.controls.length < 8" for="multi-image">
        <img
          height="250"
          width="250"
          src="../assets/images/modal-multi/select-add.png"
          onmouseover="this.src='../assets/images/modal-multi/select-add-hover.png'"
          onmouseout="this.src='../assets/images/modal-multi/select-add.png'"
          alt="plus-sign"
        />
      </label>
    </div>
  </form>
  <div class="d-flex flex-column align-items-center">
    <button (click)="saveImageChanges(); multiImage.close()">{{ 'SAVE_CHANGES' | translate }}</button>
  </div>
</ngx-smart-modal>
