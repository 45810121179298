import { createAction, props, union } from '@ngrx/store';

export enum ActionType {
  FILTER_FACETS = '[Header] Filter Facet',
  SET_SCREEN = '[Header] Set screen'
}

export const setScreen = createAction(
  ActionType.SET_SCREEN,
  props<{
    payload: { windowWidth: number };
  }>()
);

export const filterFacets = createAction(ActionType.FILTER_FACETS, props<{ payload: { filter: any } }>());

const all = union({
  filterFacets,
  setScreen
});

export type HeaderActionsUnion = typeof all;
