import { Inject, Injectable } from '@angular/core';

import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseUrlService {
  private baseUrl: string;
  private etxSubDomain: string = environment.baseUrl.purple;

  constructor(@Inject('BASE_URL') baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  /**
   * Check if is an dailyUp app context
   */
  isMobisationApp = () => this.baseUrl.indexOf(this.etxSubDomain) === -1;

  /**
   * Check if is an Editorializer app context
   */
  isEditorializerApp = () => this.baseUrl.indexOf(this.etxSubDomain) !== -1;
}
