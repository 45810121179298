import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducer, storageSyncReducer } from './_store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { EditorEffects } from './_store/effects';
import { EditorService } from './services/editor.service';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature('editor', reducer), EffectsModule.forFeature([EditorEffects])],
  providers: [EditorService]
})
export class EditorDataAccessModule {}
