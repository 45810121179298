import { createSelector, createFeatureSelector } from '@ngrx/store';

import { PreferencesState } from '../reducers';
import { feeds, userFeeds } from '../../../../../../catalog/data-access/src/lib/_store/selectors';

import { find } from 'lodash-es';
import { Feed } from '@purplefront/catalog/data-access';
import { Voice } from '@purplefront/editor/data-access';

export const selectPreferencesState = createFeatureSelector<PreferencesState>('preferences');

export const selectUserPref = createSelector(selectPreferencesState, (state: PreferencesState) => state.userPref);

export const selectCurrentPage = createSelector(selectPreferencesState, (state: PreferencesState) => state.currentPage);

export const selectRadarTimeRange = createSelector(selectPreferencesState, (state) => state.radarTimeRange);

export const currentFeedSlug = createSelector(selectPreferencesState, (state) => state.currentFeedSlug || null);

export const selectContentLang = createSelector(selectPreferencesState, (state) => state.contentLang || 'en');

export const selectSortDates = createSelector(selectPreferencesState, (state: PreferencesState) => state.sortDates);

export const selectSeasons = createSelector(selectPreferencesState, (state: PreferencesState) => state.seasons);

export const selectFrequencies = createSelector(selectPreferencesState, (state: PreferencesState) => state.frequencies);

export const selectViewModes = createSelector(
  selectPreferencesState,
  (state: PreferencesState) => state.viewModes || 'magazine'
);

export const selectInterfaceLang = createSelector(
  selectPreferencesState,
  (state: PreferencesState) => state.interfaceLang
);

export const selectAdvancedFeatures = createSelector(
  selectPreferencesState,
  (state: PreferencesState) => state.features
);

export const selectDefaultVoice = createSelector(selectPreferencesState, (state: PreferencesState) => {
  const arianeVoice: Voice = {
    id: 12,
    label: 'Ariane',
    language: 'fr-CH',
    country: 'switzerland',
    gender: 'female',
    styles: [],
    order: 10
  };

  const guyVoice: Voice = {
    id: 14,
    label: "Guy",
    language: "en-US",
    country: "us",
    gender: "male",
    styles: [],
    order: 134,
  }


  return state.contentLang !== 'fr' ? guyVoice : arianeVoice;
});

export const lastSelectedVoice = createSelector(
  selectPreferencesState,
  (state: PreferencesState) => state.lastSelectedVoice
);

export const selectCookieBanner = createSelector(
  selectPreferencesState,
  (state: PreferencesState) => state.cookieBanner
);

export const selectContentType = createSelector(
  selectPreferencesState,
  (state: PreferencesState) => state.contentTypes
);

export const selectFilterMenuToggle = createSelector(
  selectPreferencesState,
  (state: PreferencesState) => state.filterMenuToggle
);

export const selectMobileMenuToggle = createSelector(
  selectPreferencesState,
  (state: PreferencesState) => state.mobileMenuToggle
);

export const selectSources = createSelector(selectPreferencesState, (state: PreferencesState) => {
  if(!state.sources) {
    return 'All'
  }
  if(state.sources === "etx-studio") {
    return 'ETX Studio'
  }
    return  state.sources;
  }
);

export const currentFeed = createSelector(userFeeds, feeds, currentFeedSlug, (_feeds, _userFeeds, _feedSlug) => {
  let feed: Feed;
  feed = find(_userFeeds, { slug: _feedSlug });
  if (feed) {
    return feed;
  } else {
    feed = find(_feeds, { slug: _feedSlug });
    return feed;
  }
});
