import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { select, Store } from '@ngrx/store';
import {
  AudioStoryState,
  openAudioStoryOverlay,
  selectAudioStatus,
  selectError,
  selectLandscapeFormatStatus,
  selectLandscapeFormatUrls,
  selectMobileFormatStatus,
  selectMobileFormatUrls,
  selectOpenAudioStoryOverlay,
  selectSquareFormatStatus,
  selectSquareFormatUrls
} from '@purplefront/audio-story/data-access';
import { Observable, Subject } from 'rxjs';
import { EditorState, setPlyrAudioSource, setPlyrVideoSource } from '@purplefront/editor/data-access';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { takeUntil } from 'rxjs/operators';
import { EtxIconsRegistryService } from '../../../../../../shared/src/lib/components/etx-icons/etx-icons-registry.service';
import {
  etxIconDownloadWhite,
  etxIconPlayWhite,
  etxIconPortrait,
  etxIconWave,
  etxIconSquare
} from '../../../../../../shared/src/lib/components/etx-icons/etx-icons.model';
import { PreferencesState, selectContentLang } from '@purplefront/preferences/data-access';
import { AppRouterFacadeService } from '@purplefront/app-router/data-access';
import { Langs, TrackingService, WINDOW } from '@purplefront/shared';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from '@purplefront/shared';

@Component({
  selector: 'app-audio-story-generation',
  templateUrl: './audio-story-generation.component.html',
  styleUrls: ['./audio-story-generation.component.scss'],
  animations: [
    trigger('showHide', [
      state(
        'active',
        style({
          position: 'fixed',
          bottom: 0,
          transform: 'translateX(2%)'
        })
      ),
      state(
        'inactive',
        style({
          position: 'fixed',
          bottom: 0,
          transform: 'translateX(105%)'
        })
      ),
      transition('inactive => active', animate('0.3s ease-in')),
      transition('active => inactive', animate('0.3s ease-out'))
    ])
  ]
})
export class AudioStoryGenerationComponent implements OnInit, OnDestroy {
  readonly baseURL = environment.dailyUpUrl;
  @Input() audioStoryMainLang: string;
  @Input() displayAudioFormatUrl: boolean;
  @Input() audioFormatUrl: any;
  @Input() generatedVideoUrl: any;
  @Input() selectedFormats: any;
  modalId = 'audioStoryModal';
  openAudioStoryOverlay$: Observable<boolean>;
  error$: Observable<string>;
  audioStatus$: Observable<number>;
  squareFormatStatus$: Observable<number>;
  mobileFormatStatus$: Observable<number>;
  landscapeFormatStatus$: Observable<number>;
  squareFormatUrls: any;
  mobileFormatUrls: any;
  landscapeFormatUrls: any;

  _success = new Subject<any>();
  private _ngUnsubscribe$ = new Subject<void>();
  private contentLang: Langs;

  constructor(
    private _editorStore: Store<EditorState>,
    private _ngxSmartModalService: NgxSmartModalService,
    private _store: Store<AudioStoryState>,
    private _etxIconsRegistry: EtxIconsRegistryService,
    private _prefStore: Store<PreferencesState>,
    private _appRouterFacade: AppRouterFacadeService,
    private _trackingService: TrackingService,
    private _clipboard: Clipboard,
    @Inject(WINDOW) private window: Window
  ) {
    this._etxIconsRegistry.registerIcons([
      etxIconDownloadWhite,
      etxIconPlayWhite,
      etxIconWave,
      etxIconPortrait,
      etxIconSquare
    ]);
  }

  ngOnInit(): void {
    this.openAudioStoryOverlay$ = this._store.pipe(select(selectOpenAudioStoryOverlay));
    this.error$ = this._store.pipe(select(selectError));
    this.audioStatus$ = this._store.pipe(select(selectAudioStatus));
    this.mobileFormatStatus$ = this._store.pipe(select(selectMobileFormatStatus));
    this.squareFormatStatus$ = this._store.pipe(select(selectSquareFormatStatus));
    this.landscapeFormatStatus$ = this._store.pipe(select(selectLandscapeFormatStatus));
    this.getMobileFormatUrls();
    this.getSquareFormatUrls();
    this.getLandscapeFormatUrls();
    this.getContentLang();
  }

  getContentLang() {
    this._prefStore.pipe(select(selectContentLang), takeUntil(this._ngUnsubscribe$)).subscribe((lang) => {
      this.contentLang = lang;
    });
  }

  close(): void {
    const open = false;
    this._store.dispatch(openAudioStoryOverlay({ payload: { open } }));
  }

  getMobileFormatUrls() {
    this._store.pipe(select(selectMobileFormatUrls), takeUntil(this._ngUnsubscribe$)).subscribe((res) => {
      this.mobileFormatUrls = res;
    });
  }

  getSquareFormatUrls() {
    this._store.pipe(select(selectSquareFormatUrls), takeUntil(this._ngUnsubscribe$)).subscribe((res) => {
      this.squareFormatUrls = res;
    });
  }

  getLandscapeFormatUrls() {
    this._store.pipe(select(selectLandscapeFormatUrls), takeUntil(this._ngUnsubscribe$)).subscribe((res) => {
      this.landscapeFormatUrls = res;
    });
  }

  setAudioPlayerSource() {
    this.trackEvent('all-play-creation', 'generation', { format: 'audio' });
    this._editorStore.dispatch(setPlyrAudioSource({ payload: this.audioFormatUrl.stream }));
  }

  setVideoPlayerSource(url) {
    this._editorStore.dispatch(setPlyrVideoSource({ payload: url }));
  }

  openAudioStoryModal(url, format) {
    this.trackEvent('all-play-creation', 'generation', { format });
    this.setVideoPlayerSource(url);
    this._ngxSmartModalService.getModal(this.modalId).open();
  }

  downloadSource(download: string, format) {
    this.trackEvent('all-download-creation', 'generation', { format });
    this.window.location.href = download;
  }

  isFormatSelected(format) {
    return this.selectedFormats.some((selectedFormat) => selectedFormat.value === format);
  }

  goToMyAudioContents() {
    this._appRouterFacade.goToMyAudioContents(this.contentLang);
  }
  trackEvent(type: string, value: string = null, payload: any = null) {
    this._trackingService
      .trackEvent({ type: type, value: value, payload: payload })
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe();
  }

  copyToClipboard(format: string): void {
    //TODO : figure out if API has tracking set up for this action
    //this.trackEvent(`editor-copy-embed`);
    const codeEmbed = format === 'audio' ? this.generateAudioEmbedCode() : this.generateVideoEmbedCode(format);
    this._clipboard.copy(codeEmbed);
    this.showActionMessage('COPIED_CODE_EMBED', format);
  }

  generateAudioEmbedCode(): string {
    return `<iframe width="100%" height="48" src="${this.baseURL}/player?url=${this.audioFormatUrl.stream}&lang=${this.audioStoryMainLang}" title="ETX video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
  }

  generateVideoEmbedCode(format: string): string {
    let resolution = {
      height: 0,
      width: 0
    };
    let urlStream = null;
    if (format === 'square-video') {
      urlStream = this.squareFormatUrls.stream;
      resolution.width = 500;
      resolution.height = 500;
    } else if (format === 'landscape-video') {
      resolution.width = 704;
      resolution.height = 396;
      urlStream = this.landscapeFormatUrls.stream;
    } else if (format === 'mobile-video') {
      resolution.height = 640;
      resolution.width = 360;
      urlStream = this.mobileFormatUrls.stream;
    }
    return `<iframe width="${resolution.width}" height="${resolution.height}" src="${this.baseURL}/player?url=${urlStream}&lang=${this.audioStoryMainLang}" title="ETX video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
  }

  showActionMessage(message, format) {
    this._success.next({ message, format });
    setTimeout(() => {
      this._success.next('');
    }, 3000);
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
