import { createAction, props, union } from '@ngrx/store';
import { TranslatableLang } from '../../_models/translatable-lang.model';

export enum ActionType {
  LOAD_VOICES = '[Editor] Load voices',
  LOAD_VOICES_FAIL = '[Editor] Load voices fail',
  LOAD_VOICES_SUCCESS = '[Editor] Load voices success',
  UPLOAD_AUDIO_FILE_SUCCESS = '[Editor] Upload Audio file success',
  DELETE_UPLOADED_FILE_AUDIO = '[Editor] Delete uploaded file audio',
  DELETE_UPLOADED_FILE_AUDIO_FAIL = '[Editor] Delete uploaded file audio fail',
  DELETE_UPLOADED_FILE_AUDIO_SUCCESS = '[Editor] Delete uploaded file audio success',
  DELETE_UPLOADED_FILE_IMAGE = '[Editor] Delete uploaded file image',
  LOAD_DEFAULT_VOICE = '[Editor] Load default voice',
  LOAD_DEFAULT_VOICE_SUCCESS = '[Editor] Load default voice success',
  LOAD_DEFAULT_VOICE_FAIL = '[Editor] Load default voice fail',
  LOAD_CURRENT_AUDIOSTORY = '[Editor] Load current audio story',
  LOAD_CURRENT_AUDIOSTORY_SUCCESS = '[Editor] Load current audio story success',
  LOAD_CURRENT_AUDIOSTORY_FAIL = '[Editor] Load current audio story fail',
  UPDATE_CURRENT_AUDIOSTORY = '[Editor] Update current audio story',
  UPDATE_CURRENT_AUDIOSTORY_SUCCESS = '[Editor] Update current audio story success',
  UPDATE_CURRENT_AUDIOSTORY_FAIL = '[Editor] Update current audio story fail',
  SET_PLYR_AUDIO_SOURCE = '[Editor] Set Plyr Audio Source',
  SET_PLYR_VIDEO_SOURCE = '[Editor] Set Plyr Video Source',
  RESET_CURRENT_AUDIOSTORY = '[Editor] Reset current audio story',
  SET_LAST_GENERATED_FORMATS = '[Editor] Set last generated formats',
  RESET_GENERATED_FORMATS = '[Editor] Reset last generated formats',
  LOAD_TRANSLATABLE_LANG = '[Editor] Load translatable voices',
  LOAD_TRANSLATABLE_LANG_FAIL = '[Editor] Load translatable voices Fail',
  LOAD_TRANSLATABLE_LANG_SUCCESS = '[Editor] Load translatable voices Success',
  OPEN_TRANSLATION_MODAL = '[Editor] Open translation modal',
  CLOSE_TRANSLATION_MODAL = '[Editor] Close translation modal',
  OPEN_PRONUNCIATION_MODAL = '[Editor] Open pronunciation modal',
  CLOSE_PRONUNCIATION_MODAL = '[Editor] Close pronunciation modal'
}

export const openPronunciationModal = createAction(ActionType.OPEN_PRONUNCIATION_MODAL);

export const closePronunciationModal = createAction(ActionType.CLOSE_PRONUNCIATION_MODAL);

export const openTranslationModal = createAction(ActionType.OPEN_TRANSLATION_MODAL, props<{ payload: any }>());

export const closeTranslationModal = createAction(ActionType.CLOSE_TRANSLATION_MODAL, props<{ payload: any }>());

export const loadTranslatableLang = createAction(ActionType.LOAD_TRANSLATABLE_LANG);

export const loadTranslatableLangFail = createAction(ActionType.LOAD_TRANSLATABLE_LANG_FAIL, props<{ error: any }>());

export const loadTranslatableLangSuccess = createAction(
  ActionType.LOAD_TRANSLATABLE_LANG_SUCCESS,
  props<{ translatableLangs: TranslatableLang[] }>()
);

export const resetCurrentAudioStory = createAction(ActionType.RESET_CURRENT_AUDIOSTORY);

export const setPlyrVideoSource = createAction(ActionType.SET_PLYR_VIDEO_SOURCE, props<{ payload: any }>());

export const setPlyrAudioSource = createAction(ActionType.SET_PLYR_AUDIO_SOURCE, props<{ payload: any }>());

export const loadCurrentAudioStory = createAction(ActionType.LOAD_CURRENT_AUDIOSTORY, props<{ payload: any }>());

export const loadCurrentAudioStorySuccess = createAction(
  ActionType.LOAD_CURRENT_AUDIOSTORY_SUCCESS,
  props<{ payload: any }>()
);

export const loadCurrentAudioStoryFail = createAction(ActionType.LOAD_CURRENT_AUDIOSTORY_FAIL, props<{ error: any }>());

export const updateCurrentAudioStory = createAction(ActionType.UPDATE_CURRENT_AUDIOSTORY, props<{ payload: any }>());

export const updateCurrentAudioStorySuccess = createAction(
  ActionType.UPDATE_CURRENT_AUDIOSTORY_SUCCESS,
  props<{ payload: any }>()
);

export const updateCurrentAudioStoryFail = createAction(
  ActionType.UPDATE_CURRENT_AUDIOSTORY_FAIL,
  props<{ error: any }>()
);

export const loadVoices = createAction(ActionType.LOAD_VOICES);

export const loadVoicesSuccess = createAction(ActionType.LOAD_VOICES_SUCCESS, props<{ payload: { voices: any } }>());

export const loadVoicesFail = createAction(ActionType.LOAD_VOICES_FAIL, props<{ payload: { error: any } }>());

export const deleteUploadedFileAudio = createAction(
  ActionType.DELETE_UPLOADED_FILE_AUDIO,
  props<{ payload: { id: string } }>()
);
export const deleteUploadedFileAudioFail = createAction(
  ActionType.DELETE_UPLOADED_FILE_AUDIO_FAIL,
  props<{ payload: any }>()
);
export const deleteUploadedFileAudioSuccess = createAction(ActionType.DELETE_UPLOADED_FILE_AUDIO_SUCCESS);

export const uploadFileAudioSuccess = createAction(ActionType.UPLOAD_AUDIO_FILE_SUCCESS, props<{ payload: any }>());

export const deleteUploadedFileImage = createAction(ActionType.DELETE_UPLOADED_FILE_IMAGE);

export const loadDefaultVoice = createAction(ActionType.LOAD_DEFAULT_VOICE);

export const loadDefaultVoiceSuccess = createAction(ActionType.LOAD_DEFAULT_VOICE_SUCCESS, props<{ voice: any }>());

export const loadDefaultVoiceFail = createAction(ActionType.LOAD_DEFAULT_VOICE_FAIL, props<{ error: any }>());

export const setLastGeneratedFormats = createAction(
  ActionType.SET_LAST_GENERATED_FORMATS,
  props<{ formats: string[] }>()
);

export const resetGeneratedFormats = createAction(ActionType.RESET_GENERATED_FORMATS);

const all = union({
  loadVoices,
  loadVoicesSuccess,
  loadVoicesFail,
  uploadFileAudioSuccess,
  deleteUploadedFileAudio,
  deleteUploadedFileAudioFail,
  deleteUploadedFileAudioSuccess,
  deleteUploadedFileImage,
  loadDefaultVoice,
  loadDefaultVoiceSuccess,
  loadDefaultVoiceFail,
  loadCurrentAudioStory,
  loadCurrentAudioStorySuccess,
  loadCurrentAudioStoryFail,
  updateCurrentAudioStory,
  updateCurrentAudioStorySuccess,
  updateCurrentAudioStoryFail,
  setLastGeneratedFormats,
  resetGeneratedFormats,
  loadTranslatableLang,
  loadTranslatableLangSuccess,
  loadTranslatableLangFail,
  openTranslationModal,
  closeTranslationModal,
  openPronunciationModal,
  closePronunciationModal
});
export type EditorActionsUnion = typeof all;
