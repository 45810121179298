import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EtxIconsRegistryService } from '../../../../../../shared/src/lib/components/etx-icons/etx-icons-registry.service';
import { select, Store } from '@ngrx/store';
import {
  PreferencesState,
  selectContentType,
  selectFilterMenuToggle,
  selectFrequencies,
  selectSeasons,
  selectSortDates,
  selectSources,
  selectViewModes,
  toggleFilterMenu
} from '@purplefront/preferences/data-access';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AppRouterState, selectSearchString } from '@purplefront/app-router/data-access';
import {
  etxIconFilter,
  etxIconFilterWhite
} from '../../../../../../shared/src/lib/components/etx-icons/etx-icons.model';
import { Event, NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import { TrackingService } from '@purplefront/shared';

@Component({
  selector: 'app-content-filters',
  templateUrl: './content-filters.component.html',
  styleUrls: ['./content-filters.component.scss'],
  animations: [
    trigger('showHide', [
      state(
        'active',
        style({
          position: 'fixed',
          bottom: 0,
          transform: 'translateX(0%)'
        })
      ),
      state(
        'inactive',
        style({
          position: 'fixed',
          bottom: 0,
          transform: 'translateX(105%)'
        })
      ),
      transition('inactive => active', animate('0.3s ease-in')),
      transition('active => inactive', animate('0.3s ease-out'))
    ])
  ]
})
export class ContentFiltersComponent implements OnInit, OnDestroy {
  @Input() userEmail: string;
  @Output() setFiler$ = new EventEmitter<any>();
  etxDomain = 'etxstudio.com';
  showFilterMenu = 'inactive';

  display: boolean = true;
  filtersForm: FormGroup;
  viewModesData = [];
  sortDatesData = [];
  frequenciesData = [];
  sourcesData = [];
  seasonsData = [];
  contentTypesData = [];
  debounce: number = 300;
  search: string;

  private _viewModes$: Observable<string>;
  private _sortDates$: Observable<string>;
  private _contentTypes$: Observable<string[]>;
  private _frequencies$: Observable<string>;
  private _seasons$: Observable<string[]>;
  private _sources$: Observable<string>;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private _etxIconsRegistry: EtxIconsRegistryService,
    private _store: Store<PreferencesState>,
    private _routerStore: Store<AppRouterState>,
    private _fb: FormBuilder,
    private _router: Router,
    private _trackingService: TrackingService
  ) {
    this._etxIconsRegistry.registerIcons([etxIconFilter, etxIconFilterWhite]);
  }

  ngOnInit(): void {
    this.initFiltersForm();
    this.initFilterMenuToggle();
    this._router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEventAndCLoseFilterMenu(routerEvent);
    });

    this._routerStore
      .pipe(select(selectSearchString), takeUntil(this._ngUnsubscribe$))
      .subscribe((s) => (this.search = s));
  }

  get contentTypesFormArray() {
    return this.filtersForm.get('contentTypes') as FormArray;
  }
  get seasonsFormArray() {
    return this.filtersForm.get('seasons') as FormArray;
  }

  isUserFromEtxStudio() {
    const userEmailDomain = this.userEmail.split('@').pop();
    return userEmailDomain === this.etxDomain;
  }

  initFilterMenuToggle() {
    this._store.pipe(select(selectFilterMenuToggle)).subscribe((menuToggled: boolean) => {
      this.showFilterMenu = menuToggled ? 'active' : 'inactive';
    });
  }

  toggleFilterMenu() {
    this._store.dispatch(toggleFilterMenu());
  }

  initFiltersForm(): void {
    this.filtersForm = this._fb.group({
      viewModes: [''],
      sortDates: [''],
      //contentTypes: new FormArray([]),
      //frequencies: [''],
      //seasons: new FormArray([]),
      sources: ['']
    });

    this.viewModesData = this.getViewModeFilter();
    this.sortDatesData = this.getSortFilter();
    this.frequenciesData = this.getFrequencyFilter();
    this.sourcesData = this.getSourceFilter();
    this.seasonsData = this.getSeasonFilter();
    this.contentTypesData = this.getContentTypeFilter();
    //  this.filtersForm.controls.viewModes.patchValue(this.viewModesData[0].id);
    this.filtersForm.controls.sortDates.patchValue(this.sortDatesData[0].id);
    // this.filtersForm.controls.frequencies.patchValue(this.frequenciesData[0].id);
    this.filtersForm.controls.sources.patchValue(this.sourcesData[0].id);

    //  this.addCheckBoxes();
    this.onChanges();
    this.initFormData();
  }

  initFormData(): void {
    /**
     * Get user preferences
     */

    this._viewModes$ = this._store.pipe(select(selectViewModes));
    this._sortDates$ = this._store.pipe(select(selectSortDates));
    this._contentTypes$ = this._store.pipe(select(selectContentType));
    this._frequencies$ = this._store.pipe(select(selectFrequencies));
    this._seasons$ = this._store.pipe(select(selectSeasons));
    this._sources$ = this._store.pipe(select(selectSources));

    combineLatest([
      this._viewModes$,
      this._sortDates$,
      this._contentTypes$,
      this._frequencies$,
      this._seasons$,
      this._sources$
    ])
      .pipe(debounceTime(0))
      .subscribe(([viewModes, sortDates, contentTypes, frequencies, seasons, sources]) => {
        const formData = {
          viewModes,
          sortDates,
          // contentTypes,
          // frequencies,
          // seasons,
          sources
        };

        this.filtersForm.patchValue(formData, { emitEvent: false });
      });
  }

  onChanges(): void {
    this.filtersForm.controls.viewModes.valueChanges
      .pipe(debounceTime(this.debounce), takeUntil(this._ngUnsubscribe$))
      .subscribe((value) => {
        const payload = {
          key: 'view-mode',
          value
        };
        this.setFilter(payload);
      });
    this.filtersForm.controls.sortDates.valueChanges
      .pipe(debounceTime(this.debounce), takeUntil(this._ngUnsubscribe$))
      .subscribe((value) => {
        const payload = {
          key: 'sortBy',
          value
        };
        this.setFilter(payload);
      });
    /*  this.filtersForm.controls.contentTypes.valueChanges
      .pipe(debounceTime(this.debounce), takeUntil(this._ngUnsubscribe$))
      .subscribe((value) => {
        const selectedContentTypes = value
          .map((checked, i) => (checked ? this.contentTypesData[i].name : null))
          .filter((v) => v !== null);
        const payload = {
          key: 'content',
          value: selectedContentTypes
        };
        console.log(payload);
        this.setFilter(payload);
      });*/
    /*   this.filtersForm.controls.frequencies.valueChanges
      .pipe(debounceTime(this.debounce), takeUntil(this._ngUnsubscribe$))
      .subscribe((value) => {
        const payload = {
          key: 'frequency',
          value
        };
        this.setFilter(payload);
      });*/
    /*   this.filtersForm.controls.seasons.valueChanges
      .pipe(debounceTime(this.debounce), takeUntil(this._ngUnsubscribe$))
      .subscribe((value) => {
        const selectedSeasons = value
          .map((checked, i) => (checked ? this.seasonsData[i].name : null))
          .filter((v) => v !== null);

        const payload = {
          key: 'season',
          value: selectedSeasons
        };
        this.setFilter(payload);
      });*/
    this.filtersForm.controls.sources.valueChanges
      .pipe(debounceTime(this.debounce), takeUntil(this._ngUnsubscribe$))
      .subscribe((value) => {
        const payload = {
          key: 'source',
          value
        };
        this.setFilter(payload);
      });
  }

  setFilter(payload): void {
    let type = '';
    if (payload.key === 'view-mode') {
      type = 'panel-display-view';
    } else if (payload.key === 'source') {
      type = 'panel-sortby';
    }

    this._trackingService
      .trackEvent({ type: type, value: payload.value })
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe();
    this.setFiler$.emit(payload);
  }

  private getContentTypeFilter() {
    return [{ name: 'article' }, { name: 'audio' }, { name: 'video' }];
  }

  private getSeasonFilter() {
    return [{ name: 'autumn' }, { name: 'summer' }, { name: 'winter' }, { name: 'spring' }];
  }

  private getViewModeFilter() {
    return [{ name: 'magazine' }, { name: 'compact' }, { name: 'news feed' }];
  }

  private getSortFilter() {
    return [
      {
        name: 'relevance'
      },
      {
        name: 'date'
      }
    ];
  }

  private getFrequencyFilter() {
    return [
      {
        name: 'day'
      },
      {
        name: 'week'
      },
      {
        name: 'month'
      },
      {
        name: 'year'
      }
    ];
  }

  private getSourceFilter() {
    return [
      {
        name: 'All',
        label: 'All'
      },
      {
        name: 'ETX Studio',
        label: 'ETX Daily Up'
      },
      {
        name: 'AFP',
        label: 'AFP'
      },
      {
        name: 'ETX Studio/Paris Modes Insider',
        label: 'ETX Daily Up/Paris Modes Insider'
      }
    ];
  }

  private addCheckBoxes() {
    this.seasonsData.forEach(() => this.seasonsFormArray.push(new FormControl(false)));
    this.contentTypesData.forEach(() => this.contentTypesFormArray.push(new FormControl(false)));
  }

  reset() {}

  submit() {
    const selectedSeasons = this.filtersForm.value.seasons
      .map((checked, i) => (checked ? this.seasonsData[i].name : null))
      .filter((v) => v !== null);

    const selectedContentTypes = this.filtersForm.value.contentTypes
      .map((checked, i) => (checked ? this.contentTypesData[i].name : null))
      .filter((v) => v !== null);

    const payload = {
      filters: {
        sortBy: this.filtersForm.value.sortDates,
        //content: selectedContentTypes,
        //frequency: this.filtersForm.value.frequencies,
        //season: selectedSeasons,
        source: this.filtersForm.value.sources
      }
    };

    this.setFilter(payload);
  }

  checkRouterEventAndCLoseFilterMenu(routerEvent: Event): void {
    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.showFilterMenu = 'inactive';
    }
  }

  ngOnDestroy() {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
