<div class="pronunciation-modal-container">
  <ngx-smart-modal #pronunciationModal identifier="pronunciationModal" (onAnyCloseEvent)="onClose()">
    <div fxLayout="row" fxLayoutGap="15px" class="modal-container p-2">
      <!-- LEXICON PANEL -->
      <div
        *ngIf="lexicons?.length"
        class="pronunciation-list shadow-right"
        fxLayout="column"
        fxLayoutAlign="start start"
        style="width: 50%; overflow-y: auto; height: 23rem; min-width: 11.25rem; padding: 0.5rem 1rem 0.5rem 0.5rem"
      >
        <ul class="list-group" fxLayout="column" fxLayoutGap="15px" style="height: 25rem">
          <li
            *ngIf="selectedText && !selectedTextIsInLexicon"
            (click)="focusSelectedText()"
            fxLayout="row"
            fxLayoutAlign="start center"
            class="list-group-item cursor-pointer lexicon-list"
            style="height: 2.62rem"
            [ngClass]="selectedTextIsFocused === true ? 'lexicon-item-selected' : 'lexicon-item'"
          >
            <span style="text-overflow: ellipsis; max-width: 9.2rem; overflow: hidden; white-space: nowrap">{{
              selectedText.alias ? selectedText.text : selectedText
            }}</span>
          </li>
          <ng-container *ngFor="let lexicon of sortedByDateLexicons; let i = index">
            <li
              (click)="selectLexiconTerm(lexicon)"
              fxLayout="row"
              fxLayoutAlign="start center"
              class="list-group-item cursor-pointer lexicon-list"
              style="height: 2.62rem"
              [ngClass]="lexicon === selectedLexicon ? 'lexicon-item-selected' : 'lexicon-item'"
            >
              <span style="text-overflow: ellipsis; max-width: 9.2rem; overflow: hidden; white-space: nowrap">{{
                lexicon.grapheme
              }}</span>
              <button
                *ngIf="selectedLexicon === lexicon"
                (click)="deleteLexicon(lexicon.id)"
                type="button"
                class="btn-close btn-close-white ms-auto"
                style="padding: 0.2rem"
                aria-label="Close"
              ></button>
              <button
                *ngIf="selectedLexicon !== lexicon"
                (click)="deleteLexicon(lexicon.id)"
                type="button"
                class="btn-close ms-auto"
                style="padding: 0.2rem"
                aria-label="Close"
              ></button>
            </li>
          </ng-container>
        </ul>
      </div>

      <div class="pronunciation-form d-flex flex-column">
        <span class="danger" *ngIf="itemAudioPreviewError | async as error">{{ error | translate }}</span>
        <span class="danger" *ngIf="!(itemAudioPreviewError | async) && !selectedVoice">{{
          'NO_VOICE_SELECTED' | translate
        }}</span>
        <h1>{{ 'EDITOR_SUGGEST_A_NEW_PRONUNCIATION' | translate }}</h1>
        <form
          class="d-flex flex-column"
          [formGroup]="pronunciationForm"
          (ngSubmit)="submitPronunciationForm($event)"
          method="POST"
        >
          <div class="body">
            <div class="d-flex flex-column">
              <div>
                <span class="pronunciation-label">{{ 'EDITOR_NEW_PRONUNCIATION_FOR' | translate }}</span>
                <strong *ngIf="!selectedLexicon && selectedText"
                  >&laquo; {{ selectedText.alias ? selectedText.text : selectedText }} &raquo;</strong
                >
                <strong *ngIf="selectedLexicon">&laquo; {{ selectedLexicon.grapheme }} &raquo;</strong>
              </div>
              <div class="d-flex flex-row align-items-center justify-content-center" fxLayout fxLayoutGap="12px">
                <input id="selectedText" type="text" formControlName="selectedText" style="flex: 1" />
                <div class="form-check case-sensitive-container">
                  <input
                    (change)="onCaseSensitiveChange($event)"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="caseSensitive"
                    [checked]="selectedLexicon?.caseSensitive === 1"
                  />
                  <label style="font-size: 0.85rem" class="form-check-label" for="caseSensitive">
                    {{ 'CASE_SENSITIVE' | translate }}
                  </label>
                </div>
              </div>
            </div>

            <div class="info d-flex align-items-center mt-2 mb-4 p-4">
              <span class="me-3">
                <i class="fa fa-question-circle-o" aria-hidden="true"></i>
              </span>
              <p>
                {{ 'EDITOR_CHANGE_PRONUNCIATION' | translate }}
                <br />
                <span class="exple"
                  >{{ 'EDITOR_EXAMPLE_LABEL' | translate }}: &laquo; Ohio &raquo;
                  {{ 'EDITOR_BECOMES' | translate }} &laquo; Ohayo &raquo;</span
                >
              </p>
            </div>
          </div>

          <div class="footer col-md-12 d-flex justify-content-end pr-0 mt-auto mb-3">
            <button
              type="button"
              (click)="listenPronunciation()"
              class="btn-listen me-auto"
              [ngClass]="{ disable: !pronunciationForm.valid }"
              [disabled]="!pronunciationForm.valid"
            >
              <i *ngIf="!(generationLoading$ | async)" class="fa fa-volume-up"></i>
              <i *ngIf="generationLoading$ | async" class="fa fa-spinner fa-pulse fa-1x" aria-hidden="true"></i>
              <span spellcheck="false"> {{ 'EDITOR_LISTEN' | translate }}</span>
            </button>
            <button
              type="submit"
              class="btn-add-pronunciation ms-auto"
              [ngClass]="{ disable: !pronunciationForm.valid || isGraphemeAndAliasEqual() || isAliasAlreadyAGrapheme() }"
              [disabled]="!pronunciationForm.valid || isGraphemeAndAliasEqual() || isAliasAlreadyAGrapheme()"
            >
              <span spellcheck="false">{{ 'ADD_PRONUNCIATION' | translate }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </ngx-smart-modal>
</div>
