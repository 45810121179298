import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

import { select, Store } from '@ngrx/store';
import { AudioStoryState, selectGenerationLoaded, selectGenerationLoading } from '@purplefront/audio-story/data-access';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { EditorService } from '../../../../../data-access/src/lib/services';
import { LexiconService } from '../../../../../data-access/src/lib/services/lexicon.service';
import { closePronunciationModal, EditorState, Lexicon, LexiconPayload, Voice } from '@purplefront/editor/data-access';

export interface SsmlTextEvent {
  formattedArticleText: string;
  initialSelectedText: string;
  ssml: string;
  elem: any;
}

@Component({
  selector: 'app-pronunciation-modal',
  templateUrl: './pronunciation-modal.component.html',
  styleUrls: ['./pronunciation-modal.component.scss']
})
export class PronunciationModalComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() selectedText$: BehaviorSubject<string>;
  @Input() alias$: BehaviorSubject<string>;
  @Input() selectedItem: any;
  @Input() parentNode$: Observable<any>;
  @Input() selectedVoice: Voice;
  @Input() selectedText: any;
  @Output() formattedSsmlText$ = new EventEmitter<any>();
  @Output() listenSelectedText$ = new EventEmitter<any>();
  @Output() onLexiconUpdate = new EventEmitter<Lexicon[]>();
  @Input() itemAudioPreviewError: any;
  @Input() parentNodeName: string;
  lexicons: Lexicon[] = [];
  selectedLexicon: Lexicon;
  selectedTextIsFocused = true;
  generationLoading$: Observable<boolean>;
  generationLoaded$: Observable<boolean>;
  pronunciationForm: FormGroup;
  parentNode: any;
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();
  selectedTextIsInLexicon: boolean;
  caseSensitive = 0;
  sortedByDateLexicons: Lexicon[] = [];

  constructor(
    private _ngxSmartModalService: NgxSmartModalService,
    private _audioStoryStore: Store<AudioStoryState>,
    private _editorStore: Store<EditorState>,
    private _formBuilder: FormBuilder,
    private _sanitizer: DomSanitizer,
    private _editorService: EditorService,
    private _lexiconService: LexiconService,
    private _cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.generationLoading$ = this._audioStoryStore.pipe(select(selectGenerationLoading));
    this.generationLoaded$ = this._audioStoryStore.pipe(select(selectGenerationLoaded));
    this.pronunciationForm = this._formBuilder.group({
      selectedText: [null, Validators.required]
    });
    if (this.selectedVoice?.language) {
      this.getLexicons(this.selectedVoice?.language);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.selectedVoice &&
      changes.selectedVoice.currentValue &&
      changes?.selectedVoice?.currentValue?.id !== changes?.selectedVoice?.previousValue?.id
    ) {
      this.getLexicons(this.selectedVoice?.language);
    }
    if (changes?.selectedText) {
      this.focusSelectedText();
    }
  }

  ngAfterViewInit() {
    //TODO : Patch value of grapheme and alias in form and use form values to replace
    combineLatest([this.selectedText$, this.parentNode$])
      .pipe(debounceTime(100))
      .subscribe(([text, node]: [any, any]) => {
        this.selectedTextIsFocused = true;
        this.parentNode = node;
        this.isSelectedTextInLexicon();
        if (text) {
          if (text.alias) {
            const alias = text.alias;
            this.pronunciationForm.patchValue({ selectedText: alias });
          } else if (typeof text === 'string') {
            const preSelectedText = text.replaceAll('"', '');
            const selectedText = preSelectedText.replaceAll('!', '');
            this.selectedText = selectedText;
            this.pronunciationForm.patchValue({ selectedText: selectedText });
            this._cdr.detectChanges();
          }
        }
      });
  }

  listenPronunciation() {
    const text = this.pronunciationForm.value.selectedText;
    if (!text) {
      return;
    }
    this.listenSelectedText$.emit(text);
  }

  addPronunciationText(alias: string) {
    this.selectedText$.pipe(take(1)).subscribe((selectedText) => {
      let text = selectedText.replaceAll('"', '');
      const result = this.textToSsml(text, alias);
      this.formattedSsmlText$.emit({
        pronunciationEvent: result,
        alias,
        caseSensitive: this.caseSensitive
      });
      if (this.parentNodeName === 'voice') return;
      this.addLexicon(text, alias);
    });
  }
  /**
   * Submit form method
   * determines whether it's for creating or updating a lexicon
   * @public
   * @param $event
   */
  submitPronunciationForm($event: any) {
    const selectedText = this.pronunciationForm.value.selectedText;
    const preAlias = selectedText.replaceAll('"', '');
    const alias = preAlias.replaceAll('!', '');

    const language = this.selectedVoice?.language?.substring(0, 2);
    if (!alias || !language) {
      return;
    }
    if (this.selectedLexicon && this.selectedLexicon.grapheme) {
      const { grapheme, id } = this.selectedLexicon;
      this.updateLexicon(grapheme, alias, id);
    } else {
      this.addPronunciationText(alias);
    }

    this.closeModal();
  }

  isGraphemeAndAliasEqual(): boolean {
    let text = this.selectedText?.text ? this.selectedText.text : this.selectedText;
    let selectedText = this.pronunciationForm.value.selectedText;
    let selectedTextHtml = this.pronunciationForm.value.selectedText.innerHTML;
    let caseSensitive = this.caseSensitive;

    // If the caseSensitive option is enabled and the new term differs in case from the alias (e.g., "ÉPI" and "épi"), then return false. This means the user can add the term.
    if (this.selectedTextIsFocused) {
      return (
        text === selectedText ||
        text === selectedTextHtml ||
        (!caseSensitive && text?.toLowerCase() === selectedText?.toLowerCase()) ||
        (!caseSensitive && text?.toLowerCase() === selectedTextHtml?.toLowerCase())
      );
    } else if (this.selectedLexicon) {
      return (
        this.selectedLexicon?.grapheme === selectedText ||
        this.selectedLexicon?.grapheme === selectedTextHtml ||
        (!caseSensitive && this.selectedLexicon?.grapheme?.toLowerCase() === selectedText?.toLowerCase()) ||
        (!caseSensitive && this.selectedLexicon?.grapheme?.toLowerCase() === selectedTextHtml?.toLowerCase())
      );
    }
  }

  isAliasAlreadyAGrapheme(): boolean {
    return !!this.sortedByDateLexicons.find(
      (lexicon) => lexicon.grapheme === this.pronunciationForm.value.selectedText
    );
  }

  /**
   * Convert plaintext to SSML
   * @private
   * @param initialSelectedText
   * @param alias
   */
  private textToSsml(initialSelectedText: string, alias: string): SsmlTextEvent {
    const articleText = this.replaceHtmlEntities(this.selectedItem.value.articleText);
    let ssml = '';

    if (this.parentNode.nodeName === 'SUB') {
      this.parentNode.setAttribute('alias', alias);
      const innerHTMLReplacement =
        this.parentNode.parentNode.nodeName === 'VOICE'
          ? this.parentNode.parentNode.parentNode.innerHTML
          : this.parentNode.parentNode.innerHTML;

      return {
        formattedArticleText: innerHTMLReplacement,
        initialSelectedText,
        ssml,
        elem: { type: 'sub', alias: alias }
      };
    } else {
      ssml = `<sub alias="${alias}">${initialSelectedText}</sub>`;
    }

    return {
      formattedArticleText: this.replaceOccurrences(articleText, initialSelectedText, ssml, alias),
      initialSelectedText,
      ssml,
      elem: { type: 'sub', alias: alias }
    };
  }

  private replaceOccurrences(articleText: string, initialSelectedText, ssml: string, alias: string): string {
    // Match in a case insensitive and global manner (gim)
    const re = new RegExp(`${initialSelectedText}`, 'gim');
    const match = articleText.match(re);

    if (!match) {
      return articleText;
    }
    const replacedValue = articleText.replace(re, ssml);
    return replacedValue;
  }

  private replaceHtmlEntities(str: string): string {
    return this._editorService.replaceHtmlEntities(str);
  }

  private closeModal() {
    this.selectedLexicon = null;
    this._ngxSmartModalService.close('pronunciationModal');
  }

  onClose() {
    this._editorStore.dispatch(closePronunciationModal());
    this.selectedLexicon = null;
  }

  deleteLexicon(id: number) {
    this.lexicons = this.lexicons.filter((lexicon) => lexicon.id !== id);
    return this._lexiconService
      .deleteLexicon(id)
      .pipe(take(1))
      .subscribe((res) => {
        this.getLexicons(this.selectedVoice?.language);
      });
  }

  addLexicon(grapheme: string, alias: string) {
    const language = this.selectedVoice?.language;
    const payload: LexiconPayload = {
      grapheme: grapheme,
      alias: alias,
      language: language,
      caseSensitive: this.caseSensitive
    };
    this._lexiconService
      .addLexicon(payload)
      .pipe(take(1))
      .subscribe((res) => {
        this.getLexicons(language);
      });
  }

  updateLexicon(grapheme: string, alias: string, id: number): void {
    if (this.parentNodeName === 'voice') return;
    this.selectedText$.pipe(take(1)).subscribe((selectedText) => {
      const result = this.textToSsml(selectedText, alias);
      this.formattedSsmlText$.emit({
        pronunciationEvent: result,
        alias,
        caseSensitive: this.caseSensitive
      });
    });
    const lexicon = this._lexiconService.getGraphemeInLexicon(grapheme, this.lexicons);
    lexicon.alias = alias;
    const language = this.selectedVoice?.language;

    const payload: LexiconPayload = {
      grapheme: grapheme,
      alias: alias,
      language: language,
      caseSensitive: this.caseSensitive
    };
    this._lexiconService
      .updateLexicon(payload, id)
      .pipe(take(1))
      .subscribe((res) => {
        this.getLexicons(language);
      });
  }

  getLexicons(lang: string) {
    // TODO: find a way to add a switchMap effect to cancel http call if there is one in progress
    // otherwise we risk calling the API too much
    this._lexiconService
      .getLexicons(lang)
      .pipe(take(1))
      .subscribe((res: Lexicon[]) => {
        this.sortedByDateLexicons = this.sortByDate([...res]);
        this.lexicons = this.sortByCaseSensitive(res);
        this.onLexiconUpdate.emit(res);
        this.isSelectedTextInLexicon();
      });
  }

  isSelectedTextInLexicon(): void {
    if (!this.selectedText) return;
    const text = this.selectedText?.text ? this.selectedText.text : this.selectedText.innerHTML;
    const lexiconContainingGrapheme = this._lexiconService.getGraphemeInLexicon(text, this.lexicons);
    if (lexiconContainingGrapheme) {
      this.selectedTextIsInLexicon = true;
      this.selectLexiconTerm(lexiconContainingGrapheme);
    } else {
      this.selectedTextIsInLexicon = false;
    }
  }

  sortByDate(lexicons: Lexicon[]) {
    return lexicons.sort((a, b) => <any>new Date(b.last_update_date) - <any>new Date(a.last_update_date));
  }

  sortByCaseSensitive(lexicons: Lexicon[]) {
    return lexicons.sort((a, b) => b.caseSensitive - a.caseSensitive);
  }

  selectLexiconTerm(lexicon: Lexicon) {
    this.selectedTextIsFocused = false;
    this.pronunciationForm.patchValue({ selectedText: lexicon.alias });
    this.selectedLexicon = lexicon;
    this._cdr.detectChanges();
  }

  focusSelectedText(): void {
    const text = this.selectedText?.text ? this.selectedText.text : this.selectedText;
    this.selectedLexicon = null;
    this.pronunciationForm?.patchValue({ selectedText: text });
    this.selectedTextIsFocused = true;
  }

  onCaseSensitiveChange(event: Event) {
    const isChecked = event.target['checked'];
    this.caseSensitive = isChecked ? 1 : 0;
  }
  ngOnDestroy() {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
