import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticleComponent } from './components/article/article.component';
import { SharedModule } from '@purplefront/shared';
import { BookmarksUiModule } from '@purplefront/bookmarks/ui';
import { EtxIconsRegistryService } from '../../../../shared/src/lib/components/etx-icons/etx-icons-registry.service';
import {
  etxIconCopy,
  etxIconDownload,
  etxIconEdit
} from '../../../../shared/src/lib/components/etx-icons/etx-icons.model';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { VideoEmbedComponent } from './components/video-embed/video-embed.component';
import { PlyrModule } from 'ngx-plyr';
import { NgbAlertModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { LatestArticlesComponent } from './components/related-articles/latest-articles.component';
import { FormsModule } from '@angular/forms';
import { FileSaverModule } from 'ngx-filesaver';
import { MainCategoryBtnComponent } from './components/main-category-btn/main-category-btn.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BookmarksUiModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PlyrModule,
    NgbAlertModule,
    NgbPopoverModule,
    FormsModule,
    FileSaverModule
  ],
  declarations: [ArticleComponent, VideoEmbedComponent, LatestArticlesComponent, MainCategoryBtnComponent],
  exports: [ArticleComponent, VideoEmbedComponent, MainCategoryBtnComponent]
})
export class ArticleUiModule {
  constructor(private _etxIconsRegistry: EtxIconsRegistryService) {
    this._etxIconsRegistry.registerIcons([etxIconEdit, etxIconDownload, etxIconCopy]);
  }
}
