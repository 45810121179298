import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as preferencesStore from '@purplefront/preferences/data-access';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-language-tab',
  templateUrl: './language-tab.component.html',
  styleUrls: ['./language-tab.component.scss']
})
export class LanguageTab implements OnInit, OnDestroy {
  public languageForm: FormGroup;
  @Input() private interfaceLang;
  private ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder, private preferencesStore: Store<preferencesStore.PreferencesState>) {}

  ngOnInit() {
    this.initForm();
    this.onLanguageFormValueChange();
  }

  initForm() {
    this.languageForm = this.fb.group({
      interfaceLang: ['']
    });

    this.languageForm.setValue({
      interfaceLang: this.interfaceLang
    });
  }

  onLanguageFormValueChange() {
    this.languageForm.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        tap((form) => {
          this.preferencesStore.dispatch(
            preferencesStore.changeInterfaceLang({
              payload: { interfaceLang: form.interfaceLang }
            })
          );
        })
      )
      .subscribe();
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
