import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromArticleModal from '../reducers/article-modal.reducers';
import { ArticleModalState } from '../reducers/article-modal.reducers';

export const getArticleModalState = createFeatureSelector<fromArticleModal.ArticleModalState>('articleModal');

export const activeTab = createSelector(getArticleModalState, (state: ArticleModalState) => state.activeTab);

export const currentArticle = createSelector(getArticleModalState, (state: ArticleModalState) => state.article);

export const getArticleId = createSelector(getArticleModalState, (state: ArticleModalState) => state.articleId);

export const moreLikeArticles = createSelector(
  getArticleModalState,
  (state: ArticleModalState) => state.moreLikeArticles
);

export const relatedArticles = createSelector(
  getArticleModalState,
  (state: ArticleModalState) => state.relatedArticles
);

export const selectLoadingArticle = createSelector(getArticleModalState, (state: ArticleModalState) => state.loading);

export const selectLoadedArticle = createSelector(getArticleModalState, (state: ArticleModalState) => state.loaded);

export const selectLoadingMoreLikeArticles = createSelector(
  getArticleModalState,
  (state: ArticleModalState) => state.loadingMoreLikeArticles
);

export const selectLoadedMoreLikeArticles = createSelector(
  getArticleModalState,
  (state: ArticleModalState) => state.loadedMoreLikeArticles
);

export const selectLoadingRelatedArticles = createSelector(
  getArticleModalState,
  (state: ArticleModalState) => state.loadingRelatedArticles
);

export const selectLoadedRelatedArticles = createSelector(
  getArticleModalState,
  (state: ArticleModalState) => state.loadedRelatedArticles
);

export const selectLatestArticles = createSelector(
  getArticleModalState,
  (state: ArticleModalState) => state.latestArticles
);
