import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FullCalendarModule } from '@fullcalendar/angular';

import {
  CalendarApi,
  CalendarDataAccessModule,
  CalendarEffects,
  CalendarReducer
} from '@purplefront/calendar/data-access';
import { SharedModule } from '@purplefront/shared';
import { CalendarUiModule } from '@purplefront/calendar/ui';
import { ArticleModalFeatureShellModule } from '@purplefront/article-modal/feature-shell';
import { ArticleModalUiModule } from '@purplefront/article-modal/ui';

import { CalendarContainer } from './containers';

const routes: Routes = [{ path: '', pathMatch: 'full', component: CalendarContainer }];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature('calendar', CalendarReducer),
    EffectsModule.forFeature([CalendarEffects]),
    FullCalendarModule,
    SharedModule,
    ArticleModalFeatureShellModule,
    ArticleModalUiModule,

    //libs module
    CalendarUiModule,
    CalendarDataAccessModule
  ],
  declarations: [CalendarContainer],
  providers: [CalendarApi],
  exports: [CalendarContainer]
})
export class CalendarFeatureShellModule {}
