import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AudioPlayerContainer } from './audio-player/audio-player-container.component';
import { PlyrModule } from 'ngx-plyr';
import { MatCardModule } from '@angular/material/card';
import { AudioPlayerService } from './_services';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@purplefront/shared';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PlaylistFeatureShellModule } from '@purplefront/playlist/feature-shell';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    PlaylistFeatureShellModule,
    PlyrModule,
    SharedModule,
    RouterModule.forChild([
      /* {path: '', pathMatch: 'full', component: InsertYourComponentHere} */
    ]),
    MatCardModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [AudioPlayerContainer],
  exports: [AudioPlayerContainer],
  providers: [AudioPlayerService]
})
export class AudioPlayerFeatureShellModule {}
