import { Component, OnInit } from '@angular/core';
import { AppRouterFacadeService } from '@purplefront/app-router/data-access';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent implements OnInit {
  fromUrl = 'home';
  constructor(private _appRouterFacade: AppRouterFacadeService, private _router: Router) {}

  ngOnInit(): void {}

  login() {
    this._appRouterFacade.redirectToLogin(this.fromUrl);
  }

  register() {
    this._router.navigate(['/auth/registration']);
  }
}
