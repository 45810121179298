import { HostListener } from '@angular/core';
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  Output,
  ViewChild,
  EventEmitter,
  SimpleChanges,
  Renderer2,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Lexicon, Voice } from '@purplefront/editor/data-access';
import { EditorService } from 'libs/editor/data-access/src/lib/services';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-ssml-editor',
  templateUrl: './ssml-editor.component.html',
  styleUrls: ['./ssml-editor.component.scss']
})
export class SsmlEditorComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() initialValue: any;
  @Input()
  set update(val: string) {
    this.requestedUpdate = val;
  }

  @Input() voices: Voice[];
  @Input() lexicons: Lexicon[];
  @Output() ssml$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Output() requestOpenVoice: EventEmitter<any> = new EventEmitter<any>(false);
  @Output() requestOpenPronunciation: EventEmitter<any> = new EventEmitter<any>(null);
  @Output() selectedText: EventEmitter<string> = new EventEmitter<string>(null);
  @Output() onParentNodeNameChange$: EventEmitter<string> = new EventEmitter<string>(null);
  @Output() currentValue: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  @ViewChild('editorRef')
  set caretRef(editorRef: any) {
    this.editor = editorRef.nativeElement;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
  }
  listenerFn: () => void;

  editor;

  arrayTextToSpeech: Array<any> = [
    { read: true, type: 'text', textContent: 'Hello la', caret: { position: { start: 0, end: 8 } } },
    { read: true, type: 'text', modifiers: [{ type: 'voice', attribute: 'name', value: '9' }], textContent: 'famille' },
    {
      read: true,
      type: 'text',
      modifiers: [{ type: 'sub', attribute: 'alias', value: 'plutot comme ça' }],
      textContent: 'la pêche'
    },
    { read: true, type: 'text', textContent: '?' }
  ];

  elements: any = {
    voice: {
      className: 'voice',
      attribute: { name: 'name', value: '', valueType: 'number' },
      totalNumber: 0
    },
    sub: {
      className: 'sub',
      attribute: { name: 'alias', value: '', valueType: 'string' },
      totalNumber: 0
    },
    selected: {
      attribute: null,
      totalNumber: 0
    }
  };

  caret = {
    selection: {
      active: false,
      position: {
        start: 0,
        startIndex: 0,
        end: 0,
        endIndex: 0
      },
      selected: {},
      selecting: false,
      firstElement: null
    }
  };

  keyboard: any = {
    key: {
      ControlLeft: false,
      ShiftLeft: false,
      Shift: false
    }
  };

  mouse: any = {
    rightClick: false,
    middleClick: false,
    wheelUp: false,
    wheelDown: false,
    leftClick: false
  };

  activeEditor = false;
  showToolbar = false;
  history: any = {
    currentIndex: 0,
    cursorIndex: 0,
    listLength: 0,
    list: [],
    timeDifferenceString: 0
  };

  extendToolbar = false;
  commonElement = 'i';

  requestedUpdate: any;

  tooltip = { active: false, value: '', left: 0, top: 0, type: '', width: 0, loading: false };

  breakOptions = [
    { value: 'none', text: 'none', realValue: '10ms' },
    {
      value: 'small',
      text: 'small',
      realValue: '250ms'
    },
    {
      value: 'medium',
      text: 'medium',
      realValue: '1000ms'
    },
    {
      value: 'large',
      text: 'large',
      realValue: '2500ms'
    }
  ];

  checkBeforeInsertPause = false;
  caretPos: any;
  btnAction: any;
  timerKeyUp: any;
  timerMouseMove: any;
  allVoices: any = [];

  constructor(
    private _editorService: EditorService,
    private _translateService: TranslateService,
    private renderer: Renderer2,
    private _ref: ChangeDetectorRef
  ) {
    this.listenerFn = this.renderer.listen('window', 'mouseup', (e: any) => {
      if (
        !this.isEditorFocused() &&
        !e.target.classList?.contains('btnAction') &&
        !e.target.parentNode?.classList?.contains('btnAction')
      ) {
        this.onMouseUp(e, 'clickOutside');
      } else {
        if (
          this.btnAction ||
          e.target.classList?.contains('btnAction') ||
          e.target.parentNode?.classList?.contains('btnAction')
        ) {
          let target: any;
          if (e.target.classList?.contains('btnAction')) {
            target = e.target;
          } else if (e.target.parentNode?.classList?.contains('btnAction')) {
            target = e.target.parentNode;
          }
          if (target.classList?.contains('pause')) {
            this.onBtnPause_Click(e, 'medium');
          } else if (target.classList?.contains('undo')) {
            this.editor.focus();
            this.undo();
          } else if (target.classList?.contains('redo')) {
            this.editor.focus();
            this.redo();
          }
        } else {
          this.onMouseUp(e, '');
        }
      }
    });
    this.btnAction = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.update?.currentValue) {
      const update = changes.update.currentValue;
      if (update.type === 'voice') {
        this.setVoice(update.id);
      } else if (update.type === 'sub') {
        this.setPronunciation(update.alias);
      }
    }
    if (changes.voices?.currentValue) {
      const voices = changes.voices.currentValue;
      this.voices = voices;
    }
  }

  ngAfterViewInit(): void {
    const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
    if (this._editorService.isTextSSML(this.initialValue)) {
      this.initialValue = this._editorService.generateFakeSSML(this.initialValue);
    }
    this.refreshEditorHTML(this.initialValue);
    this.refreshHTML();
    if (this.editor.innerHTML === '&nbsp;') {
      this.editor.innerHTML = '';
    }
    this.handleApplyLexicon();
    setInterval(() => {
      this.refreshTimeDifference();
    }, 1000);
  }

  updateView(): void {
    this._ref.markForCheck();
    this._ref.detectChanges();
  }

  getBreakInfo(value) {
    const find = this.breakOptions.find((option) => option.value === value);
    return find ? find : null;
  }

  refreshEditorHTML(html) {
    if (this.editor) {
      this.editor.innerHTML = (html && html.trim()) || '';
    }
  }

  addToHistory(option?: string) {
    const timestamp = new Date().getTime();
    const html = this.editor.innerHTML;
    if (option !== 'text' && this.history.currentIndex !== this.history.list.length - 1) {
      this.history.list =
        this.history.list.filter((elem) => elem.timestamp <= this.history.list[this.history.currentIndex].timestamp) ||
        [];
    }

    if (this.history.list.length === 0 || this.history.list[this.history.currentIndex].html !== html) {
      this.history.list.push({ html, timestamp: timestamp });
    }
    this.history.listLength = this.history.list.length;
    this.history.currentIndex = this.history.list.length - 1;
  }

  undo(option?) {
    let currentIndex = this.history.currentIndex;
    if (currentIndex) {
      currentIndex--;
      if (this.history.list[currentIndex] && this.history.list[currentIndex].html) {
        this.history.currentIndex = currentIndex;
        this.refreshUndoRedo(currentIndex);
      }
    }
  }

  redo(option?) {
    let currentIndex = this.history.currentIndex;
    const historyLength = this.history.list.length;
    if (currentIndex < historyLength) {
      currentIndex++;

      if (this.history.list[currentIndex] && this.history.list[currentIndex].html) {
        this.history.currentIndex = currentIndex;
        this.refreshUndoRedo(currentIndex);
      }
    }
  }

  refreshUndoRedo(index?, option?: string) {
    if (index) {
      this.history.currentIndex = Number(index);
    }
    const currentIndex = this.history.currentIndex;
    if (this.history.list[currentIndex]) {
      this.refreshEditorHTML(this.history.list[currentIndex].html);
      this.updateSSML(option);
    }
    this.refreshTimeDifference();
  }

  refreshTimeDifference() {
    const timestamp = new Date().getTime();
    const currentIndex = this.history.currentIndex;
    if (this.history.list[currentIndex]) {
      this.history.timeDifferenceString = this.timeDifference(timestamp, this.history.list[currentIndex].timestamp);
    }
  }

  timeDifference(current, previous) {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    const elapsed = current - previous;

    if (Math.round(elapsed / 1000) === 0) {
      return 0;
    } else if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + ' seconds ago';
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + ' minutes ago';
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + ' hours ago';
    } else if (elapsed < msPerMonth) {
      return 'approximately ' + Math.round(elapsed / msPerDay) + ' days ago';
    } else if (elapsed < msPerYear) {
      return 'approximately ' + Math.round(elapsed / msPerMonth) + ' months ago';
    } else {
      return 'approximately ' + Math.round(elapsed / msPerYear) + ' years ago';
    }
  }

  handlePaste(e) {
    let clipboardData, pastedData;
    e.stopPropagation();
    e.preventDefault();
    clipboardData = e.clipboardData;
    pastedData = clipboardData.getData('text/plain');
    const sel: any = window.getSelection();
    if (!sel.rangeCount) {
      return;
    }
    const selRange = sel.getRangeAt(0);
    if (selRange) {
      selRange.insertNode(document.createTextNode(pastedData));
      this.editor.normalize();
    }
    selRange.collapse(true);
    sel.removeAllRanges();
    sel.addRange(selRange);
    sel.collapseToStart();
    const selected = document.querySelector('#editor .selected');
    for (const character of pastedData) {
      sel.modify('move', 'forward', 'character');
    }
    if (!selected?.classList?.contains('voice')) {
      this.onRemoveElement();
    }
    this.refreshHTML();
  }

  getSelectedElement() {
    return document.querySelector('#editor .selected, #editor > .selected');
  }

  createRange(node, chars, range?) {
    if (!range) {
      range = document.createRange();
      range.selectNode(node);
      range.setStart(node, 0);
    }

    if (chars.count === 0) {
      range.setEnd(node, chars.count);
    } else if (node && chars.count > 0) {
      if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent.length < chars.count) {
          chars.count -= node.textContent.length;
        } else {
          range.setEnd(node, chars.count);
          chars.count = 0;
        }
      } else {
        for (let lp = 0; lp < node.childNodes.length; lp++) {
          range = this.createRange(node.childNodes[lp], chars, range);

          if (chars.count === 0) {
            break;
          }
        }
      }
    }

    return range;
  }

  /**
   * Unselect selected elements
   */
  unselect(allSelected?: any) {
    if (!allSelected) {
      allSelected = document.querySelectorAll('#editor .selected');
    }
    if (allSelected) {
      allSelected.forEach((elem) => {
        elem.classList.remove('selected');
      });
    }
    this.removeUnusedElem('all');
  }

  /**
   * Handle the keycode that is defined as an 'arrow'.
   */
  onKeyDown_handleIsAnArrowKeyCode(params: { event: any }) {
    this.refreshSelectionPosition({
      eventType: 'keydown',
      conditionName: 'handleIsAnArrowKeyCode',
      $event: params.event
    });
  }

  /**
   * Handle the keycode that is not defined as an 'arrow'.
   */
  onKeyDown_handleIsNotAnArrowKeyCode(params: { event: KeyboardEvent; actionKeyCodes: Array<string> }) {
    const e = params.event;
    const actionKeyCodes = params.actionKeyCodes;
    const isAnActionKeyCode = actionKeyCodes.includes(e.code);
    const isNotAnActionKeyCode = !isAnActionKeyCode;
    if (isAnActionKeyCode) this.onKeyDown_handleIsAnActionKeyCode(params);
    if (isNotAnActionKeyCode) this.onKeyDown_handleIsNotAnActionKeyCode(params);
  }

  preventEditingSub(e) {
    // START : prevent editing a sub element
    const sel = window.getSelection();
    const anchorNode: any = sel.anchorNode;
    if (anchorNode?.parentNode?.classList?.contains('sub')) {
      e.preventDefault();
      e.stopPropagation();
      this.autoSelectElement();
      return true;
    }
    return false;
    // END : prevent editing a sub element
  }

  /**
   * Handle the keycode that is defined as an 'action'. (Enter, Backpace...)
   */
  onKeyDown_handleIsAnActionKeyCode(params: { event: KeyboardEvent }) {
    const e = params.event;
    this.preventEditingSub(params.event);
    if (e.ctrlKey) {
      e.preventDefault();
      e.stopPropagation();
      this.keyboard.key.ControlLeft = true;
    }
    const hasAtLeastOneSelected = this.atLeastOneSelected();
    if (
      (e.code === 'Backspace' || e.code === 'Enter' || e.code === 'Delete') &&
      hasAtLeastOneSelected?.classList?.value === 'selected'
    ) {
      // rule for only text selection
      e.preventDefault();
      e.stopPropagation();
      this.onRemoveElement();
    }

    if (
      (e.code === 'Backspace' || e.code === 'Delete') &&
      hasAtLeastOneSelected?.classList?.contains('selected') &&
      hasAtLeastOneSelected?.classList?.contains('sub')
    ) {
      // authorize subs to be deleted only
      e.preventDefault();
      e.stopPropagation();
      this.onRemoveElement();
    }
    if (e.code === 'Enter' && !hasAtLeastOneSelected?.classList?.contains('sub')) {
      e.preventDefault();
      e.stopPropagation();
      this.insertTextAtSelection(this.editor, '\n'); // prevent firefox from adding div
    }
  }

  /**
   * Handle the keycode that is not defined as an 'action'.
   */
  onKeyDown_handleIsNotAnActionKeyCode(params: { event: KeyboardEvent }) {
    const e: any = params.event;
    const preventEditingSub = this.preventEditingSub(params.event);
    if (preventEditingSub) return;
    let ctrlKey = this.getCtrlKey(e);
    let atLeastOneSelected = this.atLeastOneSelected();

    if (this._editorService.isOS('mac') && ctrlKey && e.key.toLowerCase() === 'a') {
      e.preventDefault();
      e.stopPropagation();
    }

    if (ctrlKey && e.key.toLowerCase() === 'c') {
      e.preventDefault();
      e.stopPropagation();
      const sel = window.getSelection();
      if (atLeastOneSelected) {
        navigator.clipboard
          .writeText(atLeastOneSelected.textContent)
          .then()
          .catch((e) => console.error(e));
      } else if (!atLeastOneSelected && sel.toString()) {
        navigator.clipboard
          .writeText(sel.toString())
          .then()
          .catch((e) => console.error(e));
      }
      this.extendToolbar = false;
    }

    if (ctrlKey && e.key.toLowerCase() === 'x') {
      e.preventDefault();
      e.stopPropagation();
      const sel = window.getSelection();
      const anchorNode: any = sel.anchorNode;
      if (atLeastOneSelected) {
        navigator.clipboard
          .writeText(atLeastOneSelected.textContent)
          .then()
          .catch((e) => console.error(e));
      } else if (!atLeastOneSelected && sel.toString()) {
        navigator.clipboard
          .writeText(sel.toString())
          .then()
          .catch((e) => console.error(e));
      }
      this.onRemoveElement();
      this.extendToolbar = false;
    }

    const hasAtLeastOneSelected = this.atLeastOneSelected();
    if (
      !ctrlKey &&
      params.event.key.length === 1 &&
      hasAtLeastOneSelected &&
      hasAtLeastOneSelected.classList.value === 'selected'
    ) {
      this.onRemoveElement();
    }
    if (ctrlKey && e.key.toLowerCase() === 'z') {
      e.preventDefault();
      e.stopPropagation();
      this.undo();
    }
    if (ctrlKey && e.key.toLowerCase() === 'y') {
      e.preventDefault();
      e.stopPropagation();
      this.redo();
    }
  }

  /**
   * Handle the input event.
   */
  onInput($event: any) {
    $event.preventDefault();
  }

  /**
   * Handle the remove button on click.
   */
  onRemoveElement(allElem?: any) {
    if (!allElem) {
      allElem = document.querySelectorAll('#editor .selected');
    }
    allElem.forEach((elem) => {
      elem.remove();
    });
  }

  /**
   * Handle the mouse down event.
   */
  onMouseDown($event: any, t?: any) {
    const e = $event || window.event;
    const previousElemsSelected = document.querySelectorAll('#editor .selected');
    if (previousElemsSelected?.length) {
      if (!$event.ctrlKey && !this.containAtLeastOneClass(e.target.classList.value)) {
        this.unselect(previousElemsSelected);
      }
    }

    this.refreshSelectionPosition({ eventType: 'mousedown', conditionName: '', $event });
  }

  /**
   * Handle the mouse move event.
   */
  onMouseMove($event: any) {
    setTimeout(() => {
      this.refreshSelectionPosition({ eventType: 'mousemove', conditionName: '', $event });
    });
  }

  /**
   * Handle the mouse up event.
   */
  onMouseUp($event: any, conditionName?) {
    if (conditionName === 'clickOutside') return;
    setTimeout(() => {
      if (!conditionName) {
        conditionName = '';
      }
      const e = $event || window.event;
      this.checkBeforeAddingPause($event.target);
      this.refreshSelectionPosition({ eventType: 'mouseup', conditionName, $event });
    });
  }

  /**
   * Handle the double click event.
   */
  onDoubleClick($event: any) {
    const e = $event || window.event;
  }

  /**
   * Handle the mouse leave event.
   */
  onMouseLeave($event) {
    this.activeEditor = false;
    this.keyboard.key.ControlLeft = false;
  }

  /**
   * Handle the mouse enter event.
   */
  onMouseEnter($event) {
    this.activeEditor = true;
  }

  /**
   * Handle the update of the selected text
   * @param {MouseEvent} $event The event of the mouse.
   * @param {string} repeat Optionnal: 'once'
   */
  setSelectedText($event: MouseEvent, repeat?: string) {
    this.saveEditorScrollTop();
    const sel = window.getSelection();
    if (!sel.rangeCount) {
      return;
    }
    const range = sel.getRangeAt(0);
    if ($event) {
      if (repeat === 'once') {
        const previousElemsSelected = document.querySelectorAll('#editor .selected');
        previousElemsSelected.forEach((elem) => {
          elem.classList.remove('selected');
        });
      }
      const e = $event || window.event;
      const target: any = e.target;
      if (target.nodeName.toLowerCase() === this.commonElement) {
        if (!target.classList.contains('selected')) {
          target.classList.add('selected');
        } else {
          // target.classList.remove('selected');
        }
        if (
          target.textContent.toString().search(' ') !== -1 &&
          target.classList.contains('selected') &&
          target.classList.contains('voice') &&
          !target.classList.contains('sub')
        ) {
          this.unselect();
          this.setTransparentSelection(range);
          this.pasteHtmlAtCaret('selected');
        }
      } else {
        if (this._editorService.isOS('mac')) {
          this.setTransparentSelection(range);
        } else {
          this.setTransparentSelection(range);
        }
        this.pasteHtmlAtCaret('selected');
      }
      this.refreshHTML();
    }
  }

  /**
   * Check if there is at least one element selected and return the list
   * @return {any} The selected element.
   */
  atLeastOneSelected() {
    return document.querySelector('.selected');
  }

  /**
   * Handle voice button on click.
   */
  onBtnVoice_Click(name) {
    let selectedText = document.querySelector('#editor .selected, #editor * > .selected');
    this.requestOpenVoice.emit(this._editorService.generateSSML(selectedText.innerHTML, 'i'));
  }

  scrollTop: any;
  /**
   * Update selected element to a voice.
   */
  setVoice(name) {
    if (this.editor) {
      const atLeastOneSelected = this.editor.querySelector('.selected');
      if (atLeastOneSelected) {
        this.extendToolbar = true;
      }
      this.setTransparentSelection();
      this.updateElement('voice', name);
      this.refreshHTML();
    }
  }

  /**
   * Handle voice button on click.
   */
  onBtnSplitVoice_Click() {
    this.onSplitElement('keep selection');
    this.onBtnVoice_Click('');
  }

  /**
   * Handle pronunciation button on click.
   */
  onBtnPronunciation_Click() {
    let selectedText = document.querySelector('#editor .selected, #editor * > .selected');
    const parentNodeName = selectedText.parentNode['className'];
    this.onParentNodeNameChange$.next(parentNodeName);
    const alias = selectedText.getAttribute('alias');
    const text = selectedText.textContent;
    if (alias && text) {
      this.requestOpenPronunciation.emit({ alias, text });
    } else if (!alias && text) {
      this.requestOpenPronunciation.emit(text);
    }
  }

  /**
   * Update selected element to a pronunciation.
   */
  setPronunciation(alias) {
    if (this.editor) {
      const atLeastOneSelected = this.editor.querySelector('.selected');
      if (atLeastOneSelected) {
        this.extendToolbar = true;
      }
      this.saveCaret();
      this.editor.focus();
      this.setTransparentSelection();
      this.updateElement('sub', alias);
      this.restoreCursor();
    }
  }

  /**
   * Update clusterized elements.
   */
  buildCluster(allElem) {
    if (!allElem) {
      allElem = document.querySelectorAll('#editor *');
    }
    const cluster = [];
    allElem.forEach((elem) => {
      // CHECK if the two previous element are an authorized element
      let previousSibling = null;
      previousSibling = elem.previousSibling ? elem.previousSibling : null;
      if (!previousSibling?.classList?.value) {
        previousSibling = elem.previousSibling;
      }
      if (
        this.isOnlySpacing(
          elem.previousSibling && elem.previousSibling.textContent ? elem.previousSibling.textContent : ''
        ) &&
        elem.previousSibling.previousSibling?.classList?.value
      ) {
        previousSibling = elem.previousSibling.previousSibling;
      }
      if (previousSibling?.textContent === ' ') {
        previousSibling.textContent = ' ';
        previousSibling = elem.previousSibling.previousSibling;
      }
      const data: any = {
        elem,
        innerHTML: elem.innerHTML
      };
      if (previousSibling) {
        data.previousSibling = previousSibling;
      }
      if (previousSibling?.classList?.value) {
        //CLUSTER insert rules here to prevent some element to be in the clusters
        const elemType = this.containSameClass(previousSibling, elem) && !previousSibling.classList.contains('sub');

        const isSameVoice =
          previousSibling.classList.contains('voice') &&
          elem.classList.contains('voice') &&
          previousSibling.getAttribute('name') === elem.getAttribute('name');
        if (elemType && isSameVoice) {
          cluster.push(data);
        }
      }
    });
    return cluster;
  }

  /**
   * Handle clusterized elements.
   */
  handleCluster(cluster: Array<any>) {
    if (cluster?.length) {
      cluster.forEach((data, index) => {
        const innerHTML = `${data.previousSibling.innerHTML} ${data.innerHTML}`;
        data.previousSibling.remove();
        data.elem.innerHTML = innerHTML.trim();
      });
    }
  }

  /**
   * Handle remove style on click.
   */
  onRemoveStyle() {
    let nodeForCaret: any = { focusNode: null, focusOffset: null };
    const allSelected = document.querySelectorAll(`#editor .selected`);
    allSelected.forEach((selected: any, index) => {
      if (selected.parentNode.id === 'editor') {
        if (selected.classList.contains('sub') || selected.classList.contains('voice')) {
          if (
            selected.hasChildNodes() &&
            selected.childNodes.length === 1 &&
            selected.childNodes[0].nodeName === '#text'
          ) {
            this.replaceByTextNode(selected);
          } else if (selected.hasChildNodes() && selected.childNodes.length > 1) {
            this.replaceByInner(selected);
          }
        }
      } else if (!selected.parentNode.id && selected.parentNode.textContent.split(' ').length > 1) {
        if (selected.classList.contains('sub')) {
          this.replaceByTextNode(selected);
        } else {
          this.onSplitElement();
        }
      }
    });
    this.saveCursorAndRefresh();
  }

  /**
   * Prevent user from adding pause inside two elements (SSML don't like that).
   *
   * @param {any} selectedElement The element to replace.
   */
  checkBeforeAddingPause(elem: any) {
    if (elem?.id || elem?.parentNode?.id) {
      this.checkBeforeInsertPause = true;
    } else {
      this.checkBeforeInsertPause = false;
    }
  }

  /**
   * Return the element on the caret.
   *
   * @param {any} selectedElement The element to replace.
   * @return {any} The node on the caret.
   */
  getElementOnCaret() {
    const sel: any = window.getSelection();
    const node = sel.anchorNode;
    return node && node.nodeType == 3 ? node.parentNode : node;
  }

  /**
   * Replace an element by a text node.
   * 1) Remove unwanted classes
   * 2) Remove unwanted element
   * 3 Sort class list
   *
   * @param {any} selectedElement The element to replace.
   */
  replaceByTextNode(selectedElement: any) {
    const selected = selectedElement;
    const newTextNode = document.createTextNode(selected.innerHTML);
    selected.after(newTextNode);
    selected.remove();
  }

  /**
   * Replace element by inner content.
   */
  replaceByInner(selectedElement: any) {
    const selected = selectedElement;
    const childNodes = selectedElement.childNodes;
    [...childNodes].reverse().forEach((node) => {
      selected.after(node);
    });
    selected.remove();
  }

  /**
   * Clean editor [routine].
   * 1) Remove unwanted classes
   * 2) Remove unwanted element
   * 3 Sort class list
   *
   * @param {string} allElem The elements to clean.
   */
  sanitizeEditor(allElem?) {
    if (!allElem) {
      allElem = document.querySelectorAll('#editor *');
    }
    allElem.forEach((elem: any) => {
      this.removeClassCeption();
      this.removeUnusedElem(elem);
      this.sortClassListElem(elem);
    });
  }

  /**
   * Handle the split function on click.
   *
   * @param {string} option Custom option : 'keep selection'.
   */
  onSplitElement(option?) {
    if (this.onlyOneSelected() && document.querySelector('#editor .voice .selected')) {
      const spanInside = document.querySelector('#editor .selected');
      const span = spanInside.parentNode;
      this.handleSplitElement(span, spanInside, option);
    }
  }

  /**
   * Split an element on the selection.
   *
   * @param {number} span The parent element.
   * @param {number} spanInside The selected element.
   * @param {string} option Custom option : 'keep selection'.
   */
  handleSplitElement(span, spanInside, option?) {
    const previousHTML: any = spanInside.previousSibling?.innerHTML || spanInside.previousSibling?.textContent || '';
    const selectedHTML: any = spanInside.innerHTML;
    const nextHTML: any = spanInside.nextSibling?.innerHTML || spanInside.nextSibling?.textContent || '';

    let previousClone = span.cloneNode();
    previousClone.innerHTML = previousHTML.trimEnd();
    const selectedClone = document.createElement(this.commonElement);

    selectedClone.innerHTML = ` ${selectedHTML} `;
    selectedClone.classList.add('selected');
    let nextClone = span.cloneNode();
    nextClone.innerHTML = nextHTML.trimStart();

    const spacing = document.createTextNode(' ');
    if (previousHTML) {
      span.insertAdjacentElement('afterEnd', previousClone);
    } else {
      previousClone.remove();
    }

    if (previousHTML) {
      previousClone.insertAdjacentElement('afterEnd', selectedClone);
      if (selectedClone.innerHTML.charAt(0) === ' ') {
        selectedClone.before(spacing.cloneNode());
      }
    } else {
      span.insertAdjacentElement('afterEnd', selectedClone);
    }
    if (nextHTML) {
      selectedClone.after(nextClone);
      if (selectedClone.innerHTML.charAt(selectedClone.innerHTML.length - 1) === ' ') {
        selectedClone.after(spacing.cloneNode());
      }
    } else {
      nextClone.remove();
    }
    span.remove();
    if (option !== 'keep selection') {
      this.onRemoveStyle();
    }
    selectedClone.innerHTML = selectedClone.innerHTML.trim();
    spacing.remove();
    if (option !== 'keep selection') {
      this.refreshHTML();
    }
    return;
  }

  /**
   * Check if there is more than one element selected.
   * @return {boolean} true if ther is more than one element selected.
   */
  moreThanOneSelected(): boolean {
    const allSelected = document.querySelectorAll('#editor .selected');
    return allSelected?.length > 1;
  }

  /**
   * Check if there is only one element selected.
   * @return {boolean} true if ther is ony one element selected.
   */
  onlyOneSelected(): boolean {
    const allSelected = document.querySelectorAll('#editor .selected');
    return allSelected?.length === 1;
  }

  /**
   * Remove classes that should not be there.
   *
   */
  removeClassCeption() {
    const allSpan = this.editor.querySelectorAll('#editor *');
    allSpan.forEach((span) => {
      const allSpanInside = span.querySelectorAll('*');
      if (!span.classList?.value && allSpan.length === 1) {
        this.refreshEditorHTML(span.innerHTML);
      }
      //Make sure there is space before and after element
      if (span.classList.contains('voice') || span.classList.contains('sub') || span.classList.contains('pause')) {
        this.handleSpaceBeforeAfter({ node: span });
      }

      allSpanInside.forEach((spanInside) => {
        // CLEAN element without class
        if (!span.classList?.value) {
          const newSpan = spanInside.cloneNode();
          newSpan.innerHTML = spanInside.innerHTML;
          span.insertAdjacentElement('afterEnd', newSpan);
          span.remove();
        }

        // CLEAN element without class
        if (span.classList.value && !spanInside.classList.value) {
          span.innerHTML = spanInside.innerHTML;
          spanInside.remove();
        }

        // CLEAN element with the same class than the element within
        if (span.classList.value === spanInside.classList.value && !span.classList.contains('selected')) {
          const newSpan = spanInside.cloneNode();
          newSpan.innerHTML = spanInside.innerHTML;
          span.insertAdjacentElement('afterEnd', newSpan);
          span.remove();
        }

        // CLEAN selected class inside a selected class
        if (span.classList.contains('selected') && spanInside.classList.contains('selected')) {
          const newSpan = spanInside.cloneNode();
          span.classList.remove('selected');
        }

        // CLEAN sub class inside a selected class
        if (
          span.classList.contains('voice') &&
          span.textContent &&
          span.textContent.search(' ') === -1 &&
          spanInside.classList.contains('sub')
        ) {
          const newSpan = spanInside.cloneNode();
          newSpan.innerHTML = spanInside.innerHTML;
          newSpan.classList.add('voice');
          span.insertAdjacentElement('afterEnd', newSpan);
          span.remove();
        }
      });
    });
  }

  /**
   * If not present, add space before or after an element.
   * @param {string} str The string to heck.
   * @param {string} position 'first' or 'last'.
   */
  isCharacterSpace(str: string, position: string) {
    let res = false;
    const spaceChars = [' ', '\u00A0', '&nbsp;'];
    if (str) {
      if (position === 'first') {
        if (spaceChars.includes(str.charAt(0))) {
          res = true;
        }
      }
      if (position === 'last') {
        if (spaceChars.includes(str.charAt(str.length - 1))) {
          res = true;
        }
      }
    }
    return res;
  }

  /**
   * If not present, add space before or after an element.
   * @param {any} element The element to handle.
   */
  handleSpaceBeforeAfter(params: { container?; node? }) {
    const container = params.container;
    const node = params.node;
    const spaceChar = ' ';
    let count = 0;
    if (container) {
      const childNodes = container.childNodes;
      childNodes.forEach((node) => {
        if (node.classList?.value && node.classList?.value !== 'selected') {
          this.handleSpaceBeforeAfter({ node });
        }
      });
    }
    if (!node) return count;
    const previousSibling = node.previousSibling;
    const nextSibling = node.nextSibling;
    if (previousSibling || nextSibling) {
      if (previousSibling?.textContent && !this.isCharacterSpace(previousSibling.textContent, 'last')) {
        count++;
        previousSibling.textContent = `${previousSibling.textContent}${spaceChar}`;
      }
      if (nextSibling?.textContent && !this.isCharacterSpace(nextSibling.textContent, 'first')) {
        count++;
        nextSibling.textContent = `${spaceChar}${nextSibling.textContent}`;
      }
    }
    return count;
  }

  /**
   * Check if there is a selection inside a selection in the editor.
   * @return {boolean} true if there is an inside selection.
   */
  hasInsideSelection(): boolean {
    return !!document.querySelector('#editor .voice .selected');
  }

  /**
   * Restore caret position.
   * @param {any} focusNode The number to raise.
   * @param {number} focusOffset The offset number (relative to the node element).
   */
  restoreCaretPosition(focusNode?: any, focusOffset?: number) {
    if (!focusNode || !focusOffset) {
      focusNode = this.caretPos ? this.caretPos.focusNode : null;
      focusOffset = this.caretPos ? this.caretPos.focusOffset : null;
    }
    if (this.editor) {
      const focusNoodes = this.editor.querySelectorAll('.selected');
      const sel = window.getSelection();
      if (focusNoodes?.length) {
        this.editor.focus();
        if (focusNoodes?.length) {
          focusNoodes.forEach((node, index) => {
            if (index === 0) {
              if (node && node.nextSibling) {
                if (this.caret.selection.active) {
                  sel.collapse(node.nextSibling, 0);
                } else {
                  if (focusNode?.textContent.length >= focusOffset) {
                    sel.collapse(focusNode, focusOffset);
                  } else {
                    sel.collapse(node.nextSibling, 0);
                  }
                }
              } else {
                // if last node
                if (node.parentNode.nextSibling) {
                  sel.collapse(node.parentNode.nextSibling, 0);
                } else {
                  sel.collapse(node, 0);
                }
              }
            }
          });
        }
        this.caretPos = null;
        this.restoreEditorScrollTop();
      } else {
        this.editor.focus();
        sel.collapse(focusNode, focusOffset);
      }
    }
  }

  /**
   * Save the editor scrollbar position.
   */
  saveEditorScrollTop() {
    this.scrollTop = this.editor.scrollTop;
  }

  /**
   * Restore the editor scrollbar position.
   */
  restoreEditorScrollTop() {
    this.editor.scrollTop = this.scrollTop;
  }

  /**
   * Save caret position.
   *
   * @return {number} the node and the offset of the focused element.
   */
  getCaretPosition(): { focusNode; focusOffset } {
    const sel = window.getSelection();
    this.caretPos = { sel, focusNode: sel.focusNode, focusOffset: sel.focusOffset };
    return { focusNode: sel.focusNode, focusOffset: sel.focusOffset };
  }

  /**
   * Remove unused element from editor or from a particular element.
   *
   * @param {any} x The element containing the unused elements.
   */
  removeUnusedElem(elem) {
    if (elem === 'all') {
      const allElements: any = document.querySelectorAll('#editor *');
      let childNodes: any;
      allElements.forEach((elem) => {
        if (!elem.classList.contains('pause')) {
          if (!elem.textContent || !elem.innerHTML) {
            elem.remove();
          }
          if (!elem.classList?.value || (elem.classList?.value && !elem.textContent)) {
            const prevousSibling = elem.previousSibling;
            const nextSibling = elem.nextSibling;
            const sibling = elem.nextSibling || elem.previousSibling;
            childNodes = elem.childNodes;
            if (sibling && sibling.nodeName === '#text' && childNodes?.length === 1) {
              if (elem.nextSibling) {
                sibling.textContent = `${elem.textContent}${sibling.textContent}`;
              } else {
                sibling.textContent = `${sibling.textContent}${elem.textContent}`;
              }
              elem.remove();
            }
            if (prevousSibling && childNodes?.length > 1) {
              if (prevousSibling.nodeName !== '#text') {
                childNodes.forEach((childNode) => {
                  prevousSibling.appendChild(childNode);
                });
              } else {
                [...childNodes].reverse().forEach((childNode) => {
                  prevousSibling.after(childNode);
                });
              }
              elem.remove();
            }
          }
        }
      });
    } else if (!elem.classList?.value || (elem.classList?.value && !elem.textContent)) {
      if (!elem.classList.contains('pause')) {
        const sibling = elem.nextSibling || elem.previousSibling;
        if (sibling) {
          sibling.textContent = `${elem.textContent}${sibling.textContent}`;
          elem.remove();
        }
      }
    }
  }

  /**
   * Sort class list sort alphabetically .
   *
   * @param {any} elem The element you want to sort the class list.
   */
  sortClassListElem(elem) {
    if (elem.classList?.value) {
      elem.classList.value = elem.classList.value.split(' ').sort().join(' ');
    }
  }

  /**
   * Refresh the editor [routine].
   *
   * 1) Sanitize editor.
   * 2) Build and handle clusterized elements.
   * 3) Normalize editor (merge text node).
   * 4) Replace multiple space by one and add a space at the end of th article if needed (TO DO : should be in the sanitize editor function).
   * 5) Update SSML routine.
   * 6) Send selected text to the parent component.
   * 7) Restore the caret position.
   */
  refreshHTML(elemForCaret?: { focusNode; focusOffset }) {
    const editorElem = document.createElement('div');
    editorElem.innerHTML = this.editor.innerHTML;

    const allElem = editorElem.querySelectorAll('*');
    this.sanitizeEditor(allElem);
    const cluster = this.buildCluster(allElem);
    this.handleCluster(cluster);
    editorElem.normalize();
    editorElem.innerHTML = editorElem.innerHTML
      .trimEnd()
      .replace(/ +(?= )/g, '')
      .replace(/&nbsp;/g, ' ');
    this.editor.innerHTML = editorElem.innerHTML;
    this.handleApplyLexicon();
    editorElem.remove();
    this.updateSSML();
    this.addToHistory();
    const selectedText: any = document.querySelector('#editor .selected');
    this.selectedText.emit(selectedText);
    this.restoreCursor();

    if (this.editor.firstChild?.nodeName !== '#text') {
      const specialChar = document.createTextNode('\u00A0');
      this.editor.firstChild?.before(specialChar.cloneNode());
    }
    if (this.editor.firstChild?.nodeName === '#text') {
      this.editor.firstChild?.textContent.trimStart();
    }
    if (this.editor.lastChild?.nodeName !== '#text') {
      const specialChar = document.createTextNode('\u00A0');
      this.editor.appendChild(specialChar.cloneNode());
    }
  }

  handleInvisibleChar() {
    let editorElem = this.editor;
    let editorHtml: string = editorElem.innerHTML;
    editorElem.childNodes.forEach((childNode) => {
      // replace &zwnj; everywhere but in a pause
      if (childNode.nodeName === '#text' && !childNode.classList?.contains('pause')) {
        childNode.textContent = childNode.textContent.replaceAll('\u200c', '');
      }
    });
    const specialChar = document.createTextNode('\u200c');
    if (editorElem.firstChild && editorElem.firstChild.nodeName !== '#text') {
      this.editor.firstChild.before(' ');
      editorHtml = `\u200c${editorElem.innerHTML}`;
    }
    if (editorElem.lastChild && editorElem.lastChild.nodeName !== '#text') {
      this.editor.lastChild.after(' ');

      editorHtml = `${editorElem.innerHTML} \u200c`;
    }
    return editorHtml;
  }

  /**
   * Update SSML [routine].
   *
   * 1) Add to history.
   * 2) Send current editor value to the parent component.
   * 3) Generate SSML and send it to the parent component.
   */
  updateSSML(option?: string) {
    this.currentValue.next(this.editor.innerHTML || '!#empty#!');
    const SSML = this._editorService.generateSSML(
      this.editor.innerHTML,
      this.commonElement,
      this.breakOptions,
      this.lexicons
    );
    this.ssml$.next(SSML);
  }

  getMatchWordExactlyRegex(word: string, caseSensitive: number = 0) {
    const flags = !!caseSensitive ? 'g' : 'gi';
    const punctuation = '[".,#!$%^&*;:{}=\\-_`~()\\n \\u200c\u00A0]';
    return new RegExp(`(?:${punctuation}|^)(${word})(?:${punctuation}|$)`, flags);
  }

  hideAllVoices(container: any) {
    const allVoices = container.querySelectorAll('.voice');
    allVoices.forEach((voice: any, index: number) => {
      this.allVoices.push(voice.outerHTML);
      const replacementText = document.createTextNode(`__*${index}*__`);
      voice.after(replacementText);
      voice.remove();
    });
    return container.innerHTML;
  }

  restoreAllVoices(container: any) {
    this.allVoices.map((voice: any, index: number) => {
      container = container.replaceAll(`__*${index}*__`, voice);
    });
    this.allVoices = [];
    return container;
  }

  transformWordInLexicon(params?: { lexicons?: Lexicon[]; container?: any }) {
    let editorTemp = this.hideAllVoices(this.editor); // HIDE all voices to prevent word replacment inside
    const lexicons = this.lexicons || [];

    for (const lexicon of lexicons) {
      const { alias, grapheme, caseSensitive } = lexicon;

      // const regs = new RegExp(`(?<=alias=)"${grapheme}"`, 'g');  THIS LINE DO NOT WORK WITH SAFARI SO WE WRITE ALL THIS CODE INSTEAD...
      // we use split() with regex to be able to match word in Safari

      const regs = new RegExp(`(?=alias=)("${alias}")`, 'gi'); // PREVENT : previous alias to be replaced
      const regArrayPreviousSub = editorTemp.split(regs).filter(Boolean);
      regArrayPreviousSub.forEach((reg, index) => {
        if (index % 2 !== 0) {
          reg = '__*-*__';
        }
      });
      editorTemp = regArrayPreviousSub.join('');

      const regexMatchWord = this.getMatchWordExactlyRegex(grapheme, caseSensitive); // MATCH word if regex is true (punctuation before and after accepted)
      const regArray = editorTemp.split(regexMatchWord);
      let originalWord;
      regArray.forEach((reg, index) => {
        if (index % 2 !== 0) {
          originalWord = regArray[index];
          regArray[index] = '__-__';
        }
      });
      let match = regexMatchWord.exec(editorTemp);

      let missingPunctuation = '';
      while (match !== null) {
        // HERE we extract the previous punctuation
        if (match[0].charAt(0) !== match[1].charAt(0)) {
          missingPunctuation = match[0].charAt(0);
        }
        match = regexMatchWord.exec(editorTemp);
      }

      editorTemp = regArray.join('');
      editorTemp = editorTemp.replaceAll(
        '__-__',
        `${missingPunctuation}<${this.commonElement} class="sub" alias="${alias}">${originalWord}</${this.commonElement}>` // AND inject it just before the element
      );

      editorTemp = editorTemp.replaceAll('__*-*__', `"${grapheme}"`);
    }
    editorTemp = this.restoreAllVoices(editorTemp);
    const doublonContainer = document.createElement('div');
    doublonContainer.innerHTML = editorTemp;
    const doublons = doublonContainer.querySelectorAll('.sub .sub');
    doublons.forEach((doublon: any) => {
      if (doublon.parentNode.textContent === doublon.textContent) {
        doublon.parentNode.innerHTML = doublon.innerHTML;
        doublon.remove();
      }
    });
    editorTemp = doublonContainer.innerHTML;
    //handle space before and after elements
    const editorTempElem = document.createElement('div');
    editorTempElem.innerHTML = editorTemp;
    const count = this.handleSpaceBeforeAfter({ container: editorTempElem });
    this.caret.selection.position.start += count;
    editorTemp = editorTempElem.innerHTML;
    editorTempElem.remove();
    return editorTemp !== this.editor.innerHTML ? editorTemp : null;
  }

  /**
   * Check if the string is only spaing.
   *
   * @param {str} str The sring to check.
   * @return {boolean} true if editor contain at least one selected element.
   */
  isOnlySpacing(str): boolean {
    let isOnlySpacing: boolean = false;
    if (str) {
      isOnlySpacing = str.replace(/ +(?= )/g, '') === ' ';
    }
    if (isOnlySpacing) {
      str = ' ';
    }
    return isOnlySpacing;
  }

  /**
   * Check if there is at least one valid class in th elem.
   *
   * @param {any} elem The elem to get the class list value.
   * @param {string} notThisClass Exclude this class.
   * @return {boolean} true if editor contain at least one selected element.
   */
  containAtLeastOneClass(elem: any, notThisClass?: string): boolean {
    const list = ['voice', 'sub', 'selected'];
    let rep = false;
    if (elem?.classList?.value) {
      const classListValue = elem.classList.value;
      list.map((elemName) => {
        rep = classListValue.search(elemName) !== -1;
        if (rep && classListValue === notThisClass) {
          rep = false;
        }
      });
    }
    return rep;
  }

  cantUseBtnPrononciation() {
    const selection = document.querySelector('#editor .selected');
    let selectionContainSub: any;
    let selectionContainPause: any;
    let selectionContainVoice: any;
    if (selection) {
      selectionContainSub = selection.querySelector('.sub');
      selectionContainPause = selection.querySelector('.pause');
      selectionContainVoice = selection.querySelector('.voice');
    }
    return (
      this.moreThanOneSelected() || selectionContainSub || selectionContainPause || selectionContainVoice || !selection
    );
  }

  cantUseBtnRemoveStyle() {
    const selection: any = document.querySelector('#editor .selected');
    let parentNodeContainsElements: boolean = false;
    if (
      selection &&
      selection.parentNode &&
      selection.parentNode.classList?.contains('voice') &&
      selection.parentNode.querySelector('.sub, .pause')
    ) {
      parentNodeContainsElements = true;
    }
    return !selection || parentNodeContainsElements;
  }

  cantUseBtnVoice() {
    const selection: any = document.querySelector('#editor .selected');
    let selectionContainVoice: any;
    let parentNodeContainsElements = false;
    if (selection) {
      selectionContainVoice = selection.querySelector('.voice');
    }
    if (
      selection &&
      selection.parentNode &&
      selection.parentNode.classList.contains('voice') &&
      selection.parentNode.querySelector('.sub, .pause')
    ) {
      parentNodeContainsElements = true;
    }
    return selectionContainVoice || parentNodeContainsElements || !selection;
  }

  cantUseBtnPause() {
    const selectionIsASub: any = document.querySelector('#editor .selected.sub');
    let parentNodeContainsElements = false;
    if (
      selectionIsASub &&
      selectionIsASub.parentNode &&
      selectionIsASub.parentNode.classList.contains('voice') &&
      selectionIsASub.parentNode.querySelector('.sub, .pause')
    ) {
      parentNodeContainsElements = true;
    }
    const atLeastOneSelected = this.atLeastOneSelected();
    return (
      selectionIsASub ||
      !this.isEditorFocused() ||
      (atLeastOneSelected && !atLeastOneSelected.classList.contains('voice'))
    );
  }

  /**
   * Compare two class list from two element an return true if the lists are the same
   *
   * @param {string} elem1 The first element to compare.
   * @param {string} elem2 The second element to compare.
   * @return {boolean} true if class list from elem1 is the same than elem2.
   */
  containSameClass(elem1: any, elem2: any): boolean {
    if (!elem1?.classList?.value && !elem2?.classList?.value) {
      return;
    }
    const classList1: string = elem1.classList.value;
    const classList2: string = elem2.classList.value;
    return classList1 && classList2 ? classList1 === classList2 : false;
  }

  /**
   * Replace selection by an element.
   *
   * @param {string} className The class name of the new element.
   * @param {string} attributeValue The attribute value of the new element.
   */
  updateElement(className, attributeValue = null) {
    const allSelected = document.querySelectorAll(`#editor .selected`);
    const type = className;
    const sel = document.getSelection();
    if (!sel.rangeCount) {
      return;
    }
    const range = sel.getRangeAt(0);
    const element = this.elements[type];
    allSelected.forEach((elem: any) => {
      if (elem && elem.nodeName.toLowerCase() === this.commonElement && className) {
        const attributeName = element.attribute.name || null;
        const className = element.className || null;
        if (className) {
          elem.setAttribute(attributeName, attributeValue);
        }
        if (!elem.classList.contains(className)) {
          elem.classList.add(className);
          const warningWord = [this.commonElement, 'name', 'alias', 'selected'];
          if (!warningWord.includes(sel.toString()) && !elem.classList.contains('voice')) {
            let container = this.editor;
            if (elem.parentNode?.classList?.contains('voice')) {
              container = elem.parentNode;
            }
            let editorTemp = this.hideAllVoices(container);
            editorTemp = container.innerHTML.replaceAll(
              this.getMatchWordExactlyRegex(elem.textContent, this.requestedUpdate.caseSensitive),
              `<${this.commonElement} class="${elem.classList.value}" ${attributeName}="${attributeValue}">${elem.textContent}</${this.commonElement}>`
            );
            editorTemp = this.restoreAllVoices(editorTemp);
            container.innerHTML = editorTemp;
            const classes = elem.classList.value
              .split(' ')
              .filter((className) => className !== 'selected')
              .join(' .');
            const inceptions = this.editor.querySelectorAll(`#editor .${classes} .${classes}`);
            inceptions.forEach((inception) => {
              inception.parentNode.innerHTML = elem.textContent;
              elem.remove();
            });
          }
        }
      }
    });
  }

  /**
   * Returns x raised to the n-th power.
   *
   * @param {number} x The number to raise.
   * @param {number} n The power, must be a natural number.
   * @return {number} x raised to the n-th power.
   */
  pasteHtmlAtCaret(type, nodesInSelection?) {
    let el: any;
    if (type) {
      let html = '';
      let sel: any;
      let range: any;
      if (window.getSelection) {
        sel = window.getSelection();
        html = `<${this.commonElement} class="${type}">${sel.toString()}</${this.commonElement}>`; // use this if selection is text only
        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0);
          this.pasteHtmlAtCaret_handleConditions({ nodesInSelection, range, type, html });
        }
      }
    }
    return el;
  }

  pasteHtmlAtCaret_handleConditions({ nodesInSelection, range, type, html }) {
    const atLeastOneElementNotInEditor = this.pasteHtmlAtCaret_if__atLeastOneElementNotInEditor(nodesInSelection);
    if (atLeastOneElementNotInEditor) {
      return;
    }
    this.pasteHtmlAtCaret_if__elementsInSelection({ nodesInSelection, range, type });
    this.pasteHtmlAtCaret_if__noElementsInSelection({ nodesInSelection, range, html });
  }

  pasteHtmlAtCaret_if__atLeastOneElementNotInEditor(nodesInSelection) {
    let res = false;
    if (nodesInSelection) {
      nodesInSelection.forEach((node) => {
        if (node.parentElement.id !== 'editor') {
          res = true;
        }
      });
    }
    return res;
  }

  pasteHtmlAtCaret_if__noElementsInSelection(option?: { nodesInSelection; range; html }) {
    const nodesInSelection = option.nodesInSelection;
    if (!nodesInSelection) {
      const range = option.range;
      const html = option.html;
      range.deleteContents();
      let el = document.createElement('div');
      el.innerHTML = html;
      let frag = document.createDocumentFragment(),
        node,
        lastNode;
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node);
      }
      range.insertNode(frag);
    }
  }

  pasteHtmlAtCaret_if__elementsInSelection(option?: { nodesInSelection; type; range }) {
    const nodesInSelection = option.nodesInSelection;
    if (nodesInSelection) {
      const type = option.type;
      const range = option.range;
      let divTempnNdesInSelection = document.createElement(this.commonElement);
      divTempnNdesInSelection.classList.add(type);
      let beforeFirstElem: any;
      let firstElem: any;
      let afterFirstElem: any;
      let lastElem: any;
      const startOffset = range.startOffset;
      const endOffset = range.endOffset;
      let startElem: any;
      [...nodesInSelection].forEach((nodeInSelection, index) => {
        if (
          nodeInSelection.nodeName === '#text' &&
          nodeInSelection.textContent.charAt(nodeInSelection.textContent.length - 1) !== ' '
        ) {
          nodeInSelection.textContent += ' ';
        }
        if (index === 0) {
          startElem = nodeInSelection.previousSibling;
          beforeFirstElem = nodeInSelection.textContent.substring(0, startOffset);
          firstElem = nodeInSelection.textContent.substring(startOffset, nodeInSelection.textContent.length - 1);
          if (firstElem.charAt(nodeInSelection.textContent.length - 1) !== ' ') {
            firstElem += ' ';
          }
          const startText = document.createTextNode(firstElem);
          divTempnNdesInSelection.appendChild(startText);
        }
        if (index === nodesInSelection.length - 1) {
          lastElem = nodeInSelection.textContent.substring(0, endOffset);
          const endText = document.createTextNode(lastElem);
          divTempnNdesInSelection.appendChild(endText);
          afterFirstElem = nodeInSelection.textContent.substring(endOffset, nodeInSelection.textContent.length - 1);
        }
        if (index && index !== nodesInSelection.length - 1) {
          const element = nodeInSelection.cloneNode();
          element.innerHTML = nodeInSelection.innerHTML;
          divTempnNdesInSelection.appendChild(element);
        }
      });
      nodesInSelection.forEach((node, index) => {
        node.remove();
      });
      const beforeText = document.createTextNode(beforeFirstElem);
      divTempnNdesInSelection.appendChild(beforeText);
      const afterText = document.createTextNode(afterFirstElem + ' ');
      divTempnNdesInSelection.appendChild(afterText);
      if (startElem) {
        startElem.after(divTempnNdesInSelection);
      } else {
        if (this.editor.firstChild) {
          this.editor.firstChild.before(divTempnNdesInSelection);
        } else {
          this.editor.appendChild(divTempnNdesInSelection);
        }
      }
      divTempnNdesInSelection.before(beforeText);
      divTempnNdesInSelection.after(afterText);
    }
  }

  getNextNode(node) {
    // if (node.firstChild) return node.firstChild;
    while (node) {
      if (node.nextSibling) return node.nextSibling;
      node = node.parentNode;
    }
  }

  getNodesInRange(range?) {
    const sel = window.getSelection();
    if (sel.rangeCount) {
      range = sel.getRangeAt(0);
    }
    const start = range.startContainer;
    const end = range.endContainer;
    const commonAncestor = range.commonAncestorContainer;
    let nodes = [];
    let node;

    // walk parent nodes from start to common ancestor
    for (node = start.parentNode; node; node = node.parentNode) {
      nodes.push(node);
      if (node == commonAncestor) break;
    }
    nodes.reverse();

    // walk children and siblings from start until end is found
    for (node = start; node; node = this.getNextNode(node)) {
      nodes.push(node);
      if (node == end) break;
    }

    nodes = nodes.filter(
      (node) =>
        (node.nodeName === '#text' && !node.parentNode.classList.value) || (node.classList && node.classList.value)
    );
    return nodes;
  }

  /**
   * Make an auto-selection on the caret or on the selection range
   *
   * @param {rangeToUse} rangeToUse The actual selection range 'window.getSelection().getRangeAt(0)'
   */
  setTransparentSelection(rangeToUse?) {
    let sel,
      selBak = '';
    if (window.getSelection && (sel = window.getSelection())) {
      if (!sel.rangeCount) {
        return;
      }
      const range = sel.getRangeAt(0);
      selBak = sel;
      if (!sel.toString().length) {
        sel.collapseToStart();
        sel.modify('move', 'backward', 'character');
        sel.modify('extend', 'forward', 'character');
        if (sel.toString() === ' ') {
          sel.modify('move', 'forward', 'character');
          sel.modify('extend', 'forward', 'word');
        } else {
          sel.modify('move', 'backward', 'word');
          sel.modify('extend', 'forward', 'word');
        }
      } else {
        if (rangeToUse && rangeToUse.endContainer && rangeToUse.endOffset) {
          const remainingSelectedtext = this.getRemainingSelectedTextLength(
            rangeToUse.endContainer.textContent,
            rangeToUse.startOffset,
            rangeToUse.endOffset
          );
          // let startOffset = rangeToUse.startOffset - remainingSelectedtext.startOffset;
          let endOffset = rangeToUse.endOffset + remainingSelectedtext.endOffset;
          // range.setStart(rangeToUse.startContainer, startOffset);
          range.setEnd(rangeToUse.endContainer, endOffset);
        } else {
          // POTENTIAL SAFARI FIX
          // sel.modify('extend', 'backward', 'character');
          // sel.modify('extend', 'forward', 'word');
          // const selectedText: any = window.getSelection().toString();
          // if (this.isSpecialChart(selectedText.charAt(selectedText.length - 1))) {
          //   sel.modify('extend', 'backward', 'character');
          // }
          // return;
        }
      }
    }
    return;
  }

  getRemainingSelectedTextLength(text, offsetStart, offsetEnd) {
    let partialTextStart = text.substring(0, offsetStart);
    if (partialTextStart) {
      partialTextStart = this.replaceSpecialChart(partialTextStart);
      partialTextStart = partialTextStart.split('\n');
      partialTextStart = partialTextStart[partialTextStart.length - 1].split(' ');
      partialTextStart = partialTextStart[partialTextStart.length - 1];
    }
    let partialTextEnd = text.substring(offsetEnd, text.length);
    if (partialTextEnd) {
      partialTextEnd = this.replaceSpecialChart(partialTextEnd);
      partialTextEnd = partialTextEnd.split('\n');
      partialTextEnd = partialTextEnd[0].split(' ');
      partialTextEnd = partialTextEnd[0];
    }
    return { startOffset: partialTextStart.length || 0, endOffset: partialTextEnd.length || 0 };
  }

  hasLineBreak(str: string) {
    return (str.match(/\n/g) || []).length;
  }

  snapSelectionToWord() {
    let sel;

    // Check for existence of window.getSelection() and that it has a
    // modify() method. IE 9 has both selection APIs but no modify() method.
    if (window.getSelection) {
      sel = window.getSelection();
      if (!sel.isCollapsed) {
        // Detect if selection is backwards
        const range = document.createRange();
        range.setStart(sel.anchorNode, sel.anchorOffset);
        range.setEnd(sel.focusNode, sel.focusOffset);
        const backwards = range.collapsed;
        range.detach();

        // modify() works on the focus of the selection
        const endNode = sel.focusNode,
          endOffset = sel.focusOffset;
        sel.collapse(sel.anchorNode, sel.anchorOffset);

        let direction = [];
        if (backwards) {
          direction = ['backward', 'forward'];
        } else {
          direction = ['forward', 'backward'];
        }

        sel.modify('move', direction[0], 'character');
        sel.modify('move', direction[1], 'word');
        if (backwards) {
          sel.modify('extend', 'backward', 'character');
          if (this.isSpecialChart(sel.toString())) {
            sel.modify('move', 'backward', 'character');
          } else {
            sel.modify('move', 'forward', 'character');
          }
        }
        sel.extend(endNode, endOffset);
        if (backwards && this.isSpecialChart(sel.toString().charAt(sel.toString().length - 1))) {
          sel.modify('extend', 'forward', 'character');
        }
        sel.modify('extend', direction[1], 'character');

        sel.modify('extend', direction[0], 'word');
        if (this.isSpecialChart(sel.toString().charAt(sel.toString().length - 1))) {
          if (backwards) {
          } else {
            sel.modify('extend', direction[1], 'character');
          }
        }
      }
    }
  }

  isSpecialChart(str) {
    const format = /[!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]+/;
    return format.test(str) || str === ' ' || str === '\n';
  }

  replaceSpecialChart(str: string, replacedBy?) {
    if (!replacedBy) {
      replacedBy = ' ';
    }
    return str ? str.replaceAll(/[!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]+/g, replacedBy) : null;
  }

  resetSelection(sel, range, lastNode?) {
    if (lastNode) {
      range.setStartAfter(lastNode);
    }
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
  }

  getSelectionOnCaret() {
    return document.getSelection();
  }

  insertTextAtSelection(div, txt) {
    const sel = window.getSelection();
    const text = div.innerHTML;
    const before = Math.min(sel.focusOffset, sel.anchorOffset);
    const after = Math.max(sel.focusOffset, sel.anchorOffset);
    //ensure string ends with \n so it displays properly
    let afterStr = text.substring(after);
    if (afterStr === '') afterStr = '\n';
    //insert content
    const selText = sel.anchorNode.textContent;
    const beforeStr = selText.substring(0, before);
    afterStr = selText.substring(after);
    sel.anchorNode.textContent = beforeStr + txt + afterStr;
    //restore cursor at correct position
    const anchorNode = sel.anchorNode;
    sel.removeAllRanges();
    const range = document.createRange();
    //childNodes[0] should be all the text
    try {
      range.setStart(anchorNode, before + txt.length);
      range.setEnd(anchorNode, before + txt.length);
    } catch (e) {
      console.error(e);
    } finally {
      //
    }

    sel.addRange(range);
  }

  onKeyUp($event) {
    this.saveCaret();
    const positionCaret = this.getCaretCharacterOffsetWithin();
    this.caret.selection.position.start = positionCaret;
    const arrowKeyCodes = ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'];
    const isAnArrowKeyCode = arrowKeyCodes.includes($event.code);
    if (isAnArrowKeyCode) {
      this.autoSelectElement();
    }
    const sel = window.getSelection();
    const range = window.getSelection().getRangeAt(0);
    if (
      sel?.toString() &&
      ($event.code === 'ShiftLeft' ||
        $event.code === 'ShiftRight' ||
        $event.code === 'ControlLeft' ||
        $event.code === 'ControlRight')
    ) {
      this.refreshSelectionPosition({
        eventType: 'mouseup',
        conditionName: 'keyboardSelection',
        $event: $event
      });
    }

    let ctrlKey = this.getCtrlKey($event);
    if (ctrlKey && $event.key.toLowerCase() === 'a') {
      this.unselect();
      let range = new Range();
      range.setStart(this.editor.firstChild, 0);
      range.setEnd(this.editor.lastChild, this.editor.lastChild.textContent.length);
      sel.removeAllRanges();
      sel.addRange(range);
    }

    if ((ctrlKey && $event.key.toLowerCase() === 'c') || (ctrlKey && $event.key.toLowerCase() === 'v')) {
      this.handleOnKeyUp();
      return;
    }

    // at this point, every shortcut with CTRL that produce no character should be there to exit the code earlier, we don't want interaction with combination here
    const keysToAvoid = 'c v z y a'.split(' ');
    if (ctrlKey) {
      if (keysToAvoid.includes($event.key.toLowerCase())) {
        $event.preventDefault();
        $event.stopPropagation();
        return;
      }
    }
    this.keyboard.key.ControlLeft = false;
    clearTimeout(this.timerKeyUp);
    if ($event.key !== 'Control') {
      this.timerKeyUp = setTimeout(() => {
        this.handleOnKeyUp();
      }, 500);
    }
  }

  handleOnKeyUp() {
    this.handleApplyLexicon();
    this.handleInvisibleChar();
    this.setCursorAtLastKnownPosition();
    this.updateSSML();
    this.addToHistory('text');
  }

  handleApplyLexicon() {
    const transformWordInLexicon = this.transformWordInLexicon();
    if (transformWordInLexicon) {
      this.editor.innerHTML = transformWordInLexicon;
      this.saveCursorAndRefresh();
    }
  }

  findElementForCaretPosition(parentNode, position: number, offset?: number) {
    let previousCount = 0;
    let actualCount = 0;
    let startContainer: any;
    let startOffset = 0;
    if (!parentNode) {
      parentNode = this.editor.childNodes;
    } else {
      parentNode = parentNode.childNodes;
    }
    for (let node of parentNode) {
      if (node.textContent) {
        actualCount += node.textContent.length;
      }
      if (position >= previousCount && position <= actualCount) {
        startContainer = node;
        startOffset = position - previousCount;
        if (node.childNodes?.length > 1) {
          this.findElementForCaretPosition(node, startOffset);
        } else {
          if (node.childNodes?.length === 1) {
            startContainer = node.childNodes[0];
          }
          const sel = window.getSelection();
          sel.collapse(startContainer, startOffset + (offset || 0));
          break;
        }
      }
      previousCount = actualCount;
    }
  }

  setCursorAtLastKnownPosition(offset?: number) {
    const position = this.caret.selection.position.start;
    this.findElementForCaretPosition(this.editor, position, offset);
  }

  onKeyDown($event: any) {
    const e = $event;
    const arrowKeyCodes = ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'];
    const actionKeyCodes = ['Enter', 'Delete', 'Backspace', 'ControlLeft', 'ShiftLeft', 'ShifRight'];
    const isAnArrowKeyCode = arrowKeyCodes.includes(e.code);
    const isNotAnArrowKeyCode = !isAnArrowKeyCode;
    const params = {
      actionKeyCodes,
      event: e
    };
    if (isAnArrowKeyCode) this.onKeyDown_handleIsAnArrowKeyCode(params);
    if (isNotAnArrowKeyCode) this.onKeyDown_handleIsNotAnArrowKeyCode(params);

    let ctrlKey = this.getCtrlKey($event);
    if (!ctrlKey) {
      if (!this.getElementOnCaret()) {
        this.unselect();
      }
    }
    clearTimeout(this.timerKeyUp); // prevent cursor to jump unexpectedly when pressing Backspace right after adding a character
  }

  getCtrlKey(e): any {
    let ctrlKey = e.ctrlKey;
    if (this._editorService.isOS('mac')) {
      ctrlKey = e.metaKey;
    }
    return ctrlKey;
  }

  autoSelectElement() {
    const elementOnCaret = this.getElementOnCaret();
    if (elementOnCaret.classList.contains('voice') || elementOnCaret.classList.contains('sub')) {
      if (!elementOnCaret.classList.contains('selected')) {
        elementOnCaret.classList.add('selected');
      }
      const elemExceptOnCaret = document.querySelectorAll('#editor .selected');
      const allElemExceptOnCaret = [];
      elemExceptOnCaret.forEach((elem) => {
        if (elem !== elementOnCaret) {
          allElemExceptOnCaret.push(elem);
        }
      });
      this.unselect(allElemExceptOnCaret);
    } else {
      this.unselect();
    }
  }

  getCaretCharacterOffsetWithin() {
    let element: any = document.getElementById('editor');
    let caretOffset = 0;
    const doc = element?.ownerDocument || element?.document;
    const win = doc?.defaultView || doc?.parentWindow;
    if (!doc ?? !win) return 0;
    let sel: any;
    if (typeof win.getSelection != 'undefined') {
      sel = win.getSelection();
      if (sel.rangeCount > 0) {
        const range = win.getSelection().getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(element);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        caretOffset = preCaretRange.toString().length;
      }
    } else if ((sel = doc.selection) && sel.type != 'Control') {
      const textRange = sel.createRange();
      const preCaretTextRange = doc.body.createTextRange();
      preCaretTextRange.moveToElementText(element);
      preCaretTextRange.setEndPoint('EndToEnd', textRange);
      caretOffset = preCaretTextRange.text.length;
    }
    return caretOffset;
  }

  getRangeInfos(): any {
    const rangeSel = window.getSelection();
    const rangeInfos = { length: 0, text: '' };
    if (rangeSel.rangeCount > 0) {
      const range = rangeSel.getRangeAt(0);
      rangeInfos.length = range.toString()?.length ?? 0;
      rangeInfos.text = range.toString();
    }
    return rangeInfos;
  }

  addModifier(index, data: { type: string; attribute?: string; value?: string }) {
    if (this.arrayTextToSpeech[index]) {
      if (!this.arrayTextToSpeech[index].modifiers) this.arrayTextToSpeech[index].modifiers = [];
      const payload: any = {
        type: data.type
      };
      if (data.attribute) payload.attribute = data.attribute;
      if (data.value) payload.value = data.value;
      if (
        this.arrayTextToSpeech[index]?.modifiers?.length >= 0 &&
        !this.arrayTextToSpeech[index].modifiers.find((elem) => elem.type === data.type)
      ) {
        this.arrayTextToSpeech[index].modifiers.push({
          type: data.type
        });
      } else {
        if (this.arrayTextToSpeech[index]?.modifiers?.length) {
          this.arrayTextToSpeech[index].modifiers =
            this.arrayTextToSpeech[index].modifiers.find((elem) => elem.type !== data.type) || [];
          if (!this.arrayTextToSpeech[index].modifiers.length) delete this.arrayTextToSpeech[index].modifiers;
        }
      }
    }
  }

  updateMouse($event, eventType) {
    if (eventType === 'mousedown')
      switch ($event.which) {
        case 1:
          this.mouse.leftClick = true;
          break;
        case 2:
          this.mouse.middleClick = true;
          break;
        case 3:
          this.mouse.rightClick = true;
          break;
      }
    else if (eventType === 'mouseup') {
      switch ($event.which) {
        case 1:
          this.mouse.leftClick = false;
          break;
        case 2:
          this.mouse.middleClick = false;
          break;
        case 3:
          this.mouse.rightClick = false;
          break;
      }
    }
  }

  handleEntireWord(currentElem?) {
    let sentence = this.editor.textContent;
    let selection: any = window.getSelection();
    if (selection.rangeCount > 0) {
      let range: any = selection.getRangeAt(0);
      if (this.isCharacterSpace(selection.toString(), 'first')) {
        // prevent from selecting previous word
        range.setStart(range.startContainer, range.startOffset + 1);
      }
      currentElem = range.startContainer;
      if (range?.startContainer?.parentNode.nodeName === '#text') {
        currentElem = range?.startContainer.parentNode;
      }
      if (range?.startContainer?.parentNode?.parentNode.nodeName === '#text') {
        currentElem = range?.startContainer.parentNode.parentNode;
      }
      const selectionDirection = this.getSelectionDirection();
      let indexPW: number = null;
      if (selectionDirection === 'backward' && !this._editorService.isNavigator('firefox')) {
        indexPW = this.getEntireWord(currentElem.textContent, selection.focusOffset);
      } else if (selectionDirection === 'backward' && this._editorService.isNavigator('firefox')) {
        indexPW = this.getEntireWord(currentElem.textContent, selection.focusOffset);
      } else if (selectionDirection === 'forward') {
        indexPW = this.getEntireWord(currentElem.textContent, selection.anchorOffset);
      }
      if (sentence.length >= indexPW) {
        range.setStart(currentElem, indexPW + 1);
      }
    }
  }

  getSelectionDirection() {
    const selection = window.getSelection();
    const range = document.createRange();
    range.setStart(selection.anchorNode, selection.anchorOffset);
    range.setEnd(selection.focusNode, selection.focusOffset);

    return range.collapsed ? 'backward' : 'forward';
  }

  getEntireWord(sentence, index) {
    let newIndex = undefined;
    for (let i = index - 1; i >= 0; i--) {
      const isSpecialChart = this.isSpecialChart(sentence[i]);
      if (isSpecialChart) {
        newIndex = i;
        break;
      }
    }
    return newIndex;
  }

  isPunctuation(str) {
    return str ? str.match(/^[.,:!?"  *"]/) : false;
  }

  removeSpecialChartFromElemn(elem) {
    elem.textContent = elem.textContent.replace('&zwnj;', '');
  }

  refreshSelectionPosition(fromEvent?: { eventType: string; conditionName: string; $event?: any }) {
    setTimeout(() => {
      const positionCaret = this.getCaretCharacterOffsetWithin();
      this.caret.selection.position.start = positionCaret;
      this.caret.selection.position.end = positionCaret;
      const range = this.getRangeInfos();
      const rangeLength = range.length;
      const caretPositionStart = this.caret.selection.position.end - rangeLength;
      this.caret.selection.position.start = caretPositionStart;
      if (rangeLength) {
        this.caret.selection.active = true;
        if (this.mouse.leftClick) {
          this.caret.selection.selecting = true;
        }
      } else {
        this.caret.selection.active = false;
      }

      if (fromEvent.eventType === 'mousedown') {
        const sel: any = window.getSelection();
        let range: any = sel.getRangeAt(0);
        if (this._editorService.isNavigator('firefox')) {
          if (sel.toString() && range && range.startContainer !== range.endContainer) {
            if (
              range.startContainer.nextSibling?.classList.contains('voice') &&
              range.endContainer.parentNode?.classList.contains('voice')
            ) {
              let element = range.endContainer.parentNode;
              let fisrtWorld = element.textContent.split(' ');
              fisrtWorld = fisrtWorld[0];
              element = element.firstChild;
              range.setStart(element, 0);
              range.setEnd(element, fisrtWorld.length);
            } else if (
              range.startContainer.nextSibling?.classList.contains('voice') &&
              range.endContainer.previousSibling?.classList.contains('voice')
            ) {
              let element = range.startContainer.nextSibling;
              let fisrtWorld = element.textContent.split(' ');
              fisrtWorld = fisrtWorld[0];
              element = element.firstChild;
              range.setStart(element, 0);
              range.setEnd(element, fisrtWorld.length);
            } else if (
              range.startContainer.nextSibling?.classList.contains('voice') &&
              range.endContainer.previousSibling?.classList.contains('voice')
            ) {
              // let element = range.startContainer.nextSibling;
              // let fisrtWorld = element.textContent.split(' ');
              // fisrtWorld = fisrtWorld[0];
              // element = element.firstChild;
              // range.setStart(element, 0);
              // range.setEnd(element, fisrtWorld.length);
            } else {
              range.setEnd(range.startContainer, range.startContainer.textContent.length);
            }
          }
          if (sel.toString() && range && this.isCharacterSpace(sel.toString(), 'last')) {
            range.setEnd(range.endContainer, range.endOffset - 1);
          }
        }
        this.updateMouse(fromEvent.$event, fromEvent.eventType);
        this.caret.selection.firstElement = fromEvent.$event.target;
      } else if (fromEvent.eventType === 'mousemove') {
        clearTimeout(this.timerMouseMove);
        this.timerMouseMove = setTimeout(() => {
          this.handleTooltip(fromEvent);
        }, 2);
      } else if (fromEvent.eventType === 'mouseup') {
        const sel = window.getSelection();
        if (sel.rangeCount) {
          let range: any = sel.getRangeAt(0);
          if (sel.toString() && range && this.isCharacterSpace(sel.toString(), 'last')) {
            // Fix when the last caracter of the selection is a space
            range.setEnd(range.endContainer, range.endOffset - 1);
          }
        }
        if (fromEvent.conditionName === 'actionButton' || !this.isSelectionInEditor()) {
          return;
        }
        this.editor.normalize();

        if (fromEvent.conditionName !== 'clickOutside') {
          this.saveCaret();
        }
        if (this.isSelectionSameContainer()) {
          // if star and the send of the selection is in the same container
          if (!this.caret.selection.active && !fromEvent.$event.ctrlKey && fromEvent.conditionName !== 'clickOutside') {
            this.autoSelectElement();
          }
          this.updateMouse(fromEvent.$event, fromEvent.eventType);

          let ctrlKey = this.getCtrlKey(fromEvent.$event);
          const sel: any = window.getSelection();
          if ((ctrlKey && !this.hasInsideSelection()) || sel.toString()) {
            this.handleEntireWord();
            this.setSelectedText(fromEvent.$event);
          } else {
            this.sanitizeEditor();
            this.setCursorAtLastKnownPosition();
          }
          if (fromEvent.conditionName !== 'clickOutside') {
            this.restoreCaret();
          }
        } else {
          const nodesInSelection = this.getNodesInRange();
          if (
            nodesInSelection?.length &&
            nodesInSelection[0].nodeName === '#text' &&
            nodesInSelection[nodesInSelection.length - 1].nodeName === '#text'
          ) {
            const sel = window.getSelection();
            const range = sel.getRangeAt(0);
            this.handleEntireWord();
            this.setTransparentSelection(range);
            this.pasteHtmlAtCaret('selected', nodesInSelection);
            this.restoreCaretPosition();
          } else {
            this.handleEntireWord();
          }
        }
        this.updateView();
      }
    });
  }

  isSelectionInEditor() {
    const sel = window.getSelection();
    if (sel.rangeCount > 0) {
      const range = sel.getRangeAt(0);
      const focusNode: any = sel.focusNode;
      if (
        focusNode.id === 'editor' ||
        focusNode.parentElement?.id === 'editor' ||
        focusNode.parentElement?.parentElement?.id === 'editor' ||
        focusNode.parentElement?.parentElement?.parentElement?.id === 'editor'
      ) {
        return true;
      }
    }
    return false;
  }

  saveCaret() {
    this.getCaretPosition();
    this.saveEditorScrollTop();
  }

  restoreCaret() {
    this.restoreCaretPosition();
    this.restoreEditorScrollTop();
  }

  saveCursorAndRefresh(offset?: number) {
    this.saveCursor(offset);
  }

  saveCursor(offset?: number) {
    this.setCursorAtLastKnownPosition(offset);
    this.saveEditorScrollTop();
  }

  restoreCursor() {
    this.restoreCaretPosition();
    this.restoreEditorScrollTop();
  }

  isSelectionSameContainer() {
    const sel: any = window.getSelection();
    if (!sel.rangeCount) {
      return;
    }
    const range = sel.getRangeAt(0);
    const previousSiblingisElement =
      range.startContainer.previousSibling?.classList?.contains('sub') ||
      range.startContainer.previousSibling?.classList?.contains('pause');
    const nextSiblingisElement =
      range.startContainer.nextSibling?.classList?.contains('sub') ||
      range.startContainer.nextSibling?.classList?.contains('pause');
    if (
      range.startContainer.nextSibling?.classList.contains('voice') &&
      range.endContainer.parentNode?.classList.contains('voice') &&
      !previousSiblingisElement
    ) {
      // FIX for firefox when selecting an element not entirely also double click on first word
      range.setStart(range.endContainer, 0);
    }
    if (
      range.startContainer.parentNode?.classList?.contains('voice') &&
      range.endContainer.previousSibling?.classList?.contains('voice') &&
      !nextSiblingisElement
    ) {
      // FIX when selecting an element not entirely also double click on last word
      range.setEnd(range.startContainer, range.startContainer.textContent.length);
    }

    return range ? range.startContainer === range.endContainer : false;
  }

  handleTooltip(fromEvent) {
    const attributeName = fromEvent.$event.target.getAttribute('name');
    const attributeAlias = fromEvent.$event.target.getAttribute('alias');
    const attributePause = fromEvent.$event.target.getAttribute('pause');
    const breakInfo = this.getBreakInfo(attributePause);
    if (attributeName || attributeAlias || attributePause) {
      let childPos = fromEvent.$event.target;
      let initialPos = childPos;
      let parentPos = fromEvent.$event.target.parentNode;
      if (childPos && initialPos && parentPos) {
        let tooltipTop = childPos.offsetTop - parentPos.offsetTop + 15 - parentPos.scrollTop;
        let tooltipLeft = childPos.offsetLeft - parentPos.offsetLeft;
        if (!parentPos.id && parentPos.parentNode?.id) {
          childPos = fromEvent.$event.target.parentNode;
          parentPos = fromEvent.$event.target.parentNode.parentNode;
          if (childPos && parentPos) {
            tooltipTop = childPos.offsetTop + initialPos.offsetTop - parentPos.offsetTop + 15 - parentPos.scrollTop;
            tooltipLeft = childPos.offsetLeft + initialPos.offsetLeft - parentPos.offsetLeft;
          }
        } else if (!parentPos.id && !parentPos.parentNode?.id && parentPos.parentNode?.parentNode?.id) {
          // if there is a need to add another inception tooltip just copy this condition and pimp it a little bit (childPosMiddle)
          childPos = fromEvent.$event.target.parentNode.parentNode; // add .parentNode when copy
          parentPos = fromEvent.$event.target.parentNode.parentNode.parentNode; // add .parentNode when copy
          const childPosMiddle = fromEvent.$event.target.parentNode; // here you should get all the elements in between  (add another variable with another .parentNode)
          if (childPos && parentPos) {
            tooltipTop =
              childPos.offsetTop +
              initialPos.offsetTop -
              parentPos.offsetTop +
              (childPosMiddle.offsetTop - 5) +
              15 -
              parentPos.scrollTop;
            tooltipLeft =
              childPos.offsetLeft + childPosMiddle.offsetLeft + initialPos.offsetLeft - parentPos.offsetLeft; // here you should add all the offsetLeft elements in between
          }
        }
        this.tooltip.top = this.getTooltipTop(tooltipTop);
        this.tooltip.left = tooltipLeft;
      }
    }
    if (attributeName && !attributeAlias) {
      const currentVoice = this._editorService.getVoice(attributeName, this.voices);
      if (currentVoice) {
        const language = this._translateService.instant(currentVoice.language.substring(0, 2).toUpperCase());
        const country = this._translateService.instant(currentVoice.country.toUpperCase());
        const label = this._translateService.instant(currentVoice.label);
        if (language && country && label) {
          const tooltipValue = `${language} (${country}) ${label}`;
          this.setTooltipValue(tooltipValue);
          this.tooltip.type = 'voice';
        }
      } else {
        const tooltipValue =
          this._editorService.capitalizeFirstLetter(this._translateService.instant('LOADING')) + '...';
        this.setTooltipValue(tooltipValue);
      }
      this.tooltip.active = true;
    } else if (!attributeName && attributeAlias && fromEvent.$event.target.parentNode?.id) {
      const tooltipValue = attributeAlias;
      this.setTooltipValue(tooltipValue);
      this.tooltip.active = true;
    } else if (
      !attributeName &&
      attributeAlias &&
      fromEvent.$event.target.parentNode &&
      !fromEvent.$event.target.parentNode.id
    ) {
      const currentVoice = this._editorService.getVoice(
        fromEvent.$event.target.parentNode.getAttribute('name'),
        this.voices
      );
      if (currentVoice) {
        const language = this._translateService.instant(currentVoice.language.substring(0, 2).toUpperCase());
        const country = this._translateService.instant(currentVoice.country.toUpperCase());
        const label = this._translateService.instant(currentVoice.label);
        if (language && country && label) {
          const tooltipValue = `${language} (${country}) ${label}: ${attributeAlias}`;
          this.setTooltipValue(tooltipValue);
          this.tooltip.type = 'voice';
        }
      } else {
        const tooltipValue = `${attributeAlias}`;
        this.setTooltipValue(tooltipValue);
        this.tooltip.type = 'sub';
      }
      this.tooltip.active = true;
    } else if (attributeName && attributeAlias) {
      const currentVoice = this._editorService.getVoice(attributeName, this.voices);
      let tooltipValue = '';
      if (currentVoice) {
        const language = this._translateService.instant(currentVoice.language.substring(0, 2).toUpperCase());
        const country = this._translateService.instant(currentVoice.country.toUpperCase());
        const label = this._translateService.instant(currentVoice.label);
        if (language && country && label) {
          tooltipValue = `${language} (${country}) ${label}: ${attributeAlias}`;
          this.setTooltipValue(tooltipValue);
          this.tooltip.type = 'voice';
        }
      } else {
        tooltipValue = this._editorService.capitalizeFirstLetter(this._translateService.instant('LOADING')) + '...';
        this.setTooltipValue(tooltipValue);
      }
      this.tooltip.active = true;
    } else if (attributePause) {
      const tooltipValue = `${this._translateService.instant('DURATION')}: ${breakInfo.realValue}`;
      this.setTooltipValue(tooltipValue);

      this.tooltip.type = 'pause';
      this.tooltip.active = true;
      const select: any = document.querySelector('.btn-pause');
      if (select) {
        select.selectedIndex = 0;
      }
    } else {
      this.tooltip.active = false;
    }
  }

  getTooltipTop(tooltipTop: number): number {
    let resTooltipTop = tooltipTop;
    if (tooltipTop <= this.editor.offsetTop + 15) {
      resTooltipTop = tooltipTop;
      if (tooltipTop + 60 <= this.editor.offsetTop + 15) {
        resTooltipTop = this.editor.offsetTop + 25;
      }
    }
    return resTooltipTop - 38;
  }

  setTooltipValue(str: string) {
    this.tooltip.value = str;
  }

  onScroll() {
    this.tooltip.active = false;
  }

  isElementFocus(elem) {
    return elem ? elem === document.activeElement : false;
  }

  onBtnPause_Click($event, forcedValue?: string) {
    const value = forcedValue || $event.target.value;
    this.insertPause(value);
    const selectedElement = this.getSelectedElement();
    this.saveCursorAndRefresh();
  }

  insertPause(pauseValue) {
    const sel: any = window.getSelection();
    if (sel) {
      if (!sel.rangeCount) {
        return;
      }
      const range = document.getSelection().getRangeAt(0);
      const node = document.createElement(this.commonElement);
      node.classList.add('pause');
      node.setAttribute('pause', pauseValue);
      node.setAttribute('contenteditable', 'false');
      range.surroundContents(node);
      node.innerHTML = '&zwnj;';
      this.handleSpaceBeforeAfter({ node });
    }
  }

  handlePause(pauses: any) {
    pauses.forEach((pause) => {});
  }

  textNodesUnder(node) {
    let all = [];
    for (node = node.firstChild; node; node = node.nextSibling) {
      if (node.nodeType == 3) all.push(node);
      else all = all.concat(this.textNodesUnder(node));
    }
    return all;
  }

  isEditorFocused() {
    const sel: any = window.getSelection();
    const anchorNode = sel.anchorNode;
    let caretIsInEditor = false;
    if (
      anchorNode?.id === 'editor' ||
      anchorNode?.parentNode?.id === 'editor' ||
      anchorNode?.parentNode?.parentNode?.id === 'editor' ||
      anchorNode?.parentNode?.parentNode?.parentNode?.id === 'editor'
    ) {
      caretIsInEditor = true;
    }
    if (
      anchorNode?.id === 'toolbar' ||
      anchorNode?.parentNode?.id === 'toolbar' ||
      anchorNode?.parentNode?.parentNode?.id === 'toolbar' ||
      anchorNode?.parentNode?.parentNode?.parentNode?.id === 'toolbar'
    ) {
      caretIsInEditor = true;
    }
    toolbar;
    return (document.activeElement === this.editor && caretIsInEditor) || this.atLeastOneSelected();
  }

  ngOnDestroy() {
    this.listenerFn();
  }
}
