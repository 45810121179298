import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromCatalogStore from '../../../../../data-access/src/lib/_store';
import { CatalogService } from '../../../../../data-access/src/lib/_services';
import { Feed } from '../../../../../data-access/src/lib/_models';

@Component({
  selector: 'app-feed-list-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <h3>Feed list</h3>
    <ul>
      <li *ngFor="let feed of feeds$ | async">
        <a [routerLink]="['..', 'feed', feed.slug]">{{ feed.name }}</a>
      </li>
    </ul>
  `
})
export class FeedListContainer implements OnInit {
  public feeds$: Observable<Feed[]>;

  constructor(private catalogService: CatalogService, private store: Store<fromCatalogStore.CatalogState>) {}

  public ngOnInit() {
    this.feeds$ = this.store.select(fromCatalogStore.userFeeds);
  }
}
