import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchContainerComponent } from './containers/search-container/search-container.component';
import { SearchUiModule } from '@purplefront/search/ui';
import { SearchDataAccessModule } from '@purplefront/search/data-access';
import { EtxIconsRegistryService } from '../../../../shared/src/lib/components/etx-icons/etx-icons-registry.service';
import {
  etxIconClose,
  etxIconSearch,
  etxIconSearchWhite
} from '../../../../shared/src/lib/components/etx-icons/etx-icons.model';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HighlightedPipe, SharedModule } from '@purplefront/shared';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule, Routes } from '@angular/router';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const routes: Routes = [{ path: '', pathMatch: 'full', component: SearchContainerComponent }];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SearchUiModule,
    SharedModule,
    SearchDataAccessModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [SearchContainerComponent],
  exports: [SearchContainerComponent]
})
export class SearchFeatureShellModule {
  constructor(private _etxIconsRegistry: EtxIconsRegistryService) {
    this._etxIconsRegistry.registerIcons([etxIconSearch, etxIconSearchWhite, etxIconClose]);
  }
}
