<div *ngIf="(modal$ | async) && (articleId$ | async) as articleId">
  <ngx-smart-modal
    #afpRelaxModal
    [identifier]="articleId"
    [autostart]="true"
    (onCloseFinished)="closeModal()"
    (onDismissFinished)="closeModal()"
    (onEscape)="closeModal()"
    customClass="article-modal"
  >
    <div class="article-modal" #modalContainer>
      <div class="modal-container">
        <div class="modal-header">
          <div class="article-source">
            <div class="article-source-logo">
              <img src="assets/images/logos/new-logo-full-purple.svg" alt="Relaxnews" />
            </div>
            <div class="article-source-infos" *ngIf="isLoaded$ | async">
              <span class="article-source-infos-author" *ngIf="article?.publisher; else elseBlock"
                >{{ article?.publisher }}&nbsp;({{ (article?.categories)[0] }})</span
              >
              <ng-template #elseBlock
                >Relaxnews {{ article.start_date | date }} - {{ article.end_date | date }}</ng-template
              >
              <span class="article-source-infos-date" *ngIf="article?.publication_date">{{
                article?.publication_date | date: 'medium'
              }}</span>
            </div>
          </div>
          <ul class="article-cta" *ngIf="isLoaded$">
            <li class="article-cta-bookmark">
              <app-bookmark-button
                [appBookmark]="article"
                [contextType]="contextType"
                [icon]="icon"
                [iconActive]="iconActive"
              ></app-bookmark-button>
            </li>
            <li ngbDropdown class="article-cta-download" *ngIf="articleType">
              <span ngbDropdownToggle><i class="fa fa-download"></i></span>
              <div ngbDropdownMenu>
                <ul>
                  <li (click)="downloadImage(article?.id, articleType, null, null)">
                    <a>{{ 'ORIGINAL_SIZE' | translate }}</a>
                  </li>
                  <li class="submenu">
                    <a>{{ 'FIXED_WIDTH' | translate }}</a>
                    <ul>
                      <li *ngFor="let width of widthSize" (click)="downloadImage(article?.id, articleType, 'w', width)">
                        <a>{{ width }}&nbsp;px</a>
                      </li>
                    </ul>
                  </li>
                  <li class="submenu">
                    <a>{{ 'FIXED_HEIGHT' | translate }}</a>
                    <ul>
                      <li
                        *ngFor="let height of heightSize"
                        (click)="downloadImage(article?.id, articleType, 'h', height)"
                      >
                        <a>{{ height }}&nbsp;px</a>
                      </li>
                    </ul>
                  </li>
                  <li class="submenu">
                    <a>{{ 'SQUARE' | translate }}</a>
                    <ul>
                      <li
                        *ngFor="let square of squareSize"
                        (click)="downloadImage(article?.id, articleType, 'w', square + '&h' + square)"
                      >
                        <a>{{ square }}&nbsp;px</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </li>
            <li ngbDropdown class="article-cta-post">
              <span ngbDropdownToggle><i class="fa fa-pencil"></i></span>
              <div ngbDropdownMenu>
                <ul>
                  <li>
                    <a>{{ 'PUBLISH_LATER' | translate }}</a>
                  </li>
                  <li>
                    <a>{{ 'PUBLISH_NOW' | translate }}</a>
                  </li>
                </ul>
              </div>
            </li>
            <li ngbDropdown class="article-cta-mail">
              <span ngbDropdownToggle><i class="fa fa-envelope"></i></span>
              <div ngbDropdownMenu class="mail-form">
                <form #sendArticleForm="ngForm" (ngSubmit)="sendArticle(sendArticleForm.form, article?.id)">
                  <h3>{{ 'RECIPIENT' | translate }}</h3>
                  <input
                    (click)="preventClose($event)"
                    #name="ngModel"
                    required
                    minlength="3"
                    class="form-control"
                    type="text"
                    name="name"
                    [ngModel]="(user$ | async)?.username"
                  />
                  <input
                    (click)="preventClose($event)"
                    #mail="ngModel"
                    required
                    minlength="3"
                    class="form-control"
                    type="email"
                    name="email"
                    [ngModel]="(user$ | async)?.username"
                  />
                  <button>{{ 'SUBMIT' | translate }}</button>
                </form>
              </div>
            </li>
            <!--<li class="article-cta-close">
              <span (click)="closeModal()"><i class="fa fa-close"></i></span>
            </li>-->
          </ul>
        </div>
        <div class="modal-body">
          <div class="tabs">
            <ul>
              <li>
                <a href [ngClass]="{ active: activeTab === 'article' }" (click)="toggleActiveTab('article', $event)"
                  >Article</a
                >
              </li>
              <li *ngIf="(relatedArticles$ | async).length && !(isLoading$ | async)">
                <a href [ngClass]="{ active: activeTab === 'related' }" (click)="toggleActiveTab('related', $event)">
                  {{ 'RELATED_COVERAGE' | translate }}
                  <span *ngIf="!(isLoading$ | async)" class="related-count">{{
                    (relatedArticles$ | async).length
                  }}</span>
                  <span class="related-count" *ngIf="isLoading$ | async"><i class="fa fa-spinner fa-pulse"></i></span>
                </a>
              </li>
            </ul>
          </div>

          <div *ngIf="(activeTab$ | async) === 'article' && (isLoaded$ | async)" class="article">
            <article>
              <h1>{{ article?.title }}</h1>
              <div class="article-images" *ngIf="article?.images">
                <img
                  src="{{ (article?.images)[0].s_default }}"
                  [srcset]="getImageSrcset()"
                  alt="{{ (article?.images)[0].title }}"
                />
                <div class="caption">
                  <div class="caption-content">
                    <p>{{ (article?.images)[0].title }}</p>
                    <p>© {{ (article?.images)[0].credits }}</p>
                    <p>{{ (article?.images)[0].special_uses }}</p>
                  </div>
                </div>
              </div>
              <h2 [innerHTML]="article?.header"></h2>
              <div class="article-content" [innerHTML]="article?.content | safe: 'html'"></div>
              <div *ngIf="article.signature" class="article-credits">
                <span class="article-credits-signature"
                  ><i class="fa fa-pencil"></i>&nbsp;{{ article?.signature }}</span
                >
                <span *ngIf="article.platform" class="article-credits-author"
                  >&nbsp;-&nbsp;{{ article?.platform }}</span
                >
              </div>
              <div class="article-tag-row">
                <a class="article-collapse-button">
                  <i class="icon rn_cluster-down"></i>
                </a>
                <ul>
                  <li *ngFor="let category of article?.categories" class="article-tag article-tag-category">
                    <a (click)="searchByCat(category)">{{ category }}</a>
                  </li>
                </ul>
                <ul>
                  <li *ngFor="let term of article?.terms" class="article-tag">
                    <a (click)="searchByTag(term)">#{{ term }}</a>
                  </li>
                </ul>
              </div>
            </article>
          </div>
          <div *ngIf="(activeTab$ | async) === 'related' && (isLoaded$ | async)" class="related">
            <h1>{{ article?.title }}</h1>

            <div class="related-article-filters">
              {{ 'SORT_BY' | translate }}: <span>revelancy</span> |
              <span class="current">date</span>
            </div>

            <div class="article-container">
              <div class="related-article-content" *ngFor="let relatedArticle of relatedArticles$ | async">
                <a href="{{ relatedArticle?.source }}" target="_blank" title="{{ relatedArticle?.title }}">
                  <div class="related-article-body-container">
                    <div class="related-article-body">
                      <div class="related-article-publisher">
                        <img
                          src="https://www.google.com/s2/favicons?domain={{ relatedArticle?.publisher }}"
                          alt="{{ relatedArticle?.title }}"
                        />
                        <span class="related-article-label">{{ relatedArticle?.publisher }}</span>
                      </div>
                      <div class="related-article-title">
                        {{ relatedArticle?.title }}
                      </div>
                      <div class="related-articles-separator" *ngIf="relatedArticle?.header">&nbsp;|&nbsp;</div>
                      <div class="related-article-description">
                        {{ relatedArticle?.header }}
                      </div>
                    </div>
                  </div>
                </a>
                <app-bookmark-button
                  [appBookmark]="relatedArticle"
                  [contextType]="contextType"
                  [icon]="icon"
                  [iconActive]="iconActive"
                ></app-bookmark-button>
                <div class="related-article-date">
                  {{ relatedArticle?.publication_date | date: 'mediumDate' }}
                </div>
              </div>
            </div>
          </div>

          <app-ghosts-elements
            *ngIf="(isLoading$ | async) && !isEvent(article)"
            [nbElements]="nbElements"
          ></app-ghosts-elements>
        </div>
        <div class="modal-footer" *ngIf="moreLikeArticles$ | async as moreLikeArticles">
          <div class="more-like" *ngIf="moreLikeArticles.length">
            <h3>AFP Relaxnews - {{ 'RELATED_COVERAGE' | translate }}</h3>
            <ul>
              <li *ngFor="let moreLikeArticle of moreLikeArticles" (click)="changeArticle(moreLikeArticle)">
                <div class="morelike-article-img">
                  <img src="{{ (moreLikeArticle?.images)[0].s_default }}" alt="" />
                </div>
                <div class="morelike-article-body">
                  <span class="morelike-article-date">{{ article?.publication_date | date: 'medium' }}</span>
                  <h4 class="morelike-article-title">
                    {{ moreLikeArticle?.title }}
                  </h4>
                  <h5 class="morelike-article-domain">
                    <img
                      src="https://www.google.com/s2/favicons?domain=editorializer.relaxnews.com"
                      alt=""
                    />&nbsp;|&nbsp; AFP / Relaxnews
                  </h5>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </ngx-smart-modal>
</div>
