import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadingComponent } from './components/loading/loading.component';
import { GhostsElementsComponent } from './components/ghosts-elements/ghosts-elements.component';

import {
  BigNumberPipe,
  GetDecimalNumberPipe,
  HighlightedPipe,
  SafePipe,
  SearchFilterPipe,
  TrackByPropertyPipe,
  TruncateNumberPipe
} from './_pipes';

import { PersistentStorageService, ThemeService, BaseUrlService } from './_services';
import { LazyImagesDirective } from './directives';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LayoutDirective } from './directives/layout.directive';
import { ReactiveFormsModule, RequiredValidator } from '@angular/forms';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { ClickEditDirective } from './directives/click-edit.directive';
import { EtxIconsComponent } from './components/etx-icons/etx-icons.component';
import { ConfirmDialogComponent } from './components/delete-confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './components/delete-confirm-dialog/confirm-dialog.service';
import { ToastsComponent } from './components/toasts/toasts.component';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderMobileComponent } from './components/header-mobile/header-mobile.component';
import { ContentFiltersMenuComponent } from './components/content-filters-menu/content-filters-menu.component';
import { TermsOrServicesComponent } from './components/terms-or-services/terms-or-services.component';
import { AuthNaggingModalComponent } from './components/auth-nagging-modal/auth-nagging-modal.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { EtxIconsRegistryService } from './components/etx-icons/etx-icons-registry.service';
import { etxIconClosePurple } from './components/etx-icons/etx-icons.model';
import { RouterModule } from '@angular/router';
import { TextSelectDirective } from './directives/text-select.directive';
import { WINDOW_PROVIDERS } from './_services/window.service';
import { SessionExpiredModalComponent } from './components/session-expired-modal/session-expired-modal.component';
import { httpInterceptorProviders } from './_interceptors';
import { HelpModalComponent } from './components/help-modal/help-modal.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ReactiveFormsModule,
    NgbToastModule,
    NgxSmartModalModule,
    RouterModule
  ],
  declarations: [
    LoadingComponent,
    GhostsElementsComponent,
    GetDecimalNumberPipe,
    TruncateNumberPipe,
    SearchFilterPipe,
    BigNumberPipe,
    SafePipe,
    TrackByPropertyPipe,
    LazyImagesDirective,
    HighlightedPipe,
    LayoutDirective,
    BackButtonComponent,
    ClickEditDirective,
    EtxIconsComponent,
    ConfirmDialogComponent,
    ToastsComponent,
    HeaderMobileComponent,
    ContentFiltersMenuComponent,
    TermsOrServicesComponent,
    AuthNaggingModalComponent,
    TextSelectDirective,
    SessionExpiredModalComponent,
    HelpModalComponent,
    SkeletonComponent
  ],
  exports: [
    LoadingComponent,
    GhostsElementsComponent,
    GetDecimalNumberPipe,
    TruncateNumberPipe,
    SearchFilterPipe,
    BigNumberPipe,
    SafePipe,
    TrackByPropertyPipe,
    LazyImagesDirective,
    HighlightedPipe,
    LayoutDirective,
    BackButtonComponent,
    ClickEditDirective,
    EtxIconsComponent,
    ConfirmDialogComponent,
    ToastsComponent,
    HeaderMobileComponent,
    ContentFiltersMenuComponent,
    TermsOrServicesComponent,
    TextSelectDirective,
    AuthNaggingModalComponent,
    SessionExpiredModalComponent,
    HelpModalComponent,
    SkeletonComponent
  ],
  providers: [
    httpInterceptorProviders,
    PersistentStorageService,
    ConfirmDialogService,
    ThemeService,
    BaseUrlService,
    RequiredValidator
  ]
})
export class SharedModule {
  constructor(private _etxIconsRegistry: EtxIconsRegistryService) {
    this._etxIconsRegistry.registerIcons([etxIconClosePurple]);
  }
}
