<div class="player-playlist-container">
  <div [ngClass]="{ 'hide-playlist': !playerStatus, 'playlist-transition': playerStatus }">
    <app-playlist-container></app-playlist-container>
  </div>

  <div class="player-container" [@showHide]="showPlayer">
    <div
      mat-card-image
      plyr
      plyrTitle="Audio"
      plyrType="audio"
      (plyrInit)="player = $event"
      [plyrPlaysInline]="true"
      [plyrCrossOrigin]="true"
      [plyrSources]="source$ | async"
      [plyrOptions]="_audioPlayerService.options"
      (plyrPlay)="handlePlay($event)"
      (plyrPause)="handlePause($event)"
      (plyrEnded)="onPlaybackEnded($event)"
    ></div>
    <span class="player-toggle" (click)="togglePlayer()">
      <i
        *ngIf="playerStatus"
        title="{{ 'CLOSE_PLAYER' | translate }}"
        class="fa fa-chevron-down"
        aria-hidden="true"
      ></i>
      <i *ngIf="!playerStatus" title="{{ 'CLOSE_PLAYER' | translate }}" class="fa fa-chevron-up" aria-hidden="true"></i>
    </span>
  </div>
</div>
