<app-menu-rio></app-menu-rio>
<div layoutContainer [ngClass]="{ 'layout-container': showMenu() }">
  <app-loading [inline]="false" *ngIf="isLoading"></app-loading>
  <router-outlet></router-outlet>
  <app-toaster-container></app-toaster-container>
  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</div>

<app-auth-nagging-modal></app-auth-nagging-modal>
<app-session-expired-modal></app-session-expired-modal>
<app-help-modal (initGuidedTour)="initGuidedTour()"></app-help-modal>
<app-audio-player *ngIf="hasValidSource$ | async"></app-audio-player>
