import { createAction, props, union } from '@ngrx/store';
import { Catalog, Feed } from '../../_models';

export enum ActionType {
  CATALOG_INITIALIZED = '[Catalog] Catalog Initialized',
  CATALOG_ERROR = '[Catalog] Catalog Error',
  SET_CURRENT_CATALOG = '[Catalog] Set current catalog',
  UNSET_CURRENT_CATALOG = '[Catalog] Unset current catalog',
  GET_FOLDERS = '[Catalog] Get Folders',
  GET_USER_FEEDS = '[Catalog] Get user Feeds',
  GET_USER_FEEDS_SUCCESS = '[Catalog] Get user Feeds success',
  GET_USER_FEEDS_FAIL = '[Catalog] Get user Feeds fail',
  GET_FOLDERS_SUCCESS = '[Catalog] Get Folders success',
  GET_FOLDERS_FAIL = '[Catalog] Get Folders fail',
  ADD_FEED = '[Catalog] Add Feed',
  ADD_FEED_SUCCESS = '[Catalog] Add Feed success',
  ADD_FEED_FAIL = '[Catalog] Add Feed fail',
  DELETE_FEED = '[Catalog] Remove Feed',
  DELETE_FEED_SUCCESS = '[Catalog] Remove Feed success',
  DELETE_FEED_FAIL = '[Catalog] Remove Feed fail',
  TOGGLE_USER_FEED = '[Catalog] Toggle user Feed',
  ADD_FEED_ALL = '[Catalog] Add Feed All',
  DELETE_FEED_ALL = '[Catalog] delete Feed All',
  SET_CURRENT_FOLDER = '[Catalog] Set current folder'
}

export const catalogInitialized = createAction(
  ActionType.CATALOG_INITIALIZED,
  props<{
    payload: {
      catalogs: Catalog[];
      feeds: Feed[];
    };
  }>()
);

export const catalogError = createAction(ActionType.CATALOG_ERROR, props<{ payload: { error: any } }>());

export const setCurrentCatalog = createAction(
  ActionType.SET_CURRENT_CATALOG,
  props<{
    payload: {
      catalog: Catalog;
    };
  }>()
);

export const unsetCurrentCatalog = createAction(ActionType.UNSET_CURRENT_CATALOG);

export const getUserFeeds = createAction(ActionType.GET_USER_FEEDS);

export const getUserFeedsSuccess = createAction(
  ActionType.GET_USER_FEEDS_SUCCESS,
  props<{ payload: { userFeeds: any[] } }>()
);

export const getUserFeedsFail = createAction(ActionType.GET_USER_FEEDS_FAIL, props<{ payload: { error: any } }>());

export const getFolders = createAction(
  ActionType.GET_FOLDERS,
  props<{ payload: { contentLang: string; currentFeed: string } }>()
);

export const getFoldersSuccess = createAction(
  ActionType.GET_FOLDERS_SUCCESS,
  props<{ payload: { currentFeed: string; folders: any[] } }>()
);

export const setCurrentFolder = createAction(ActionType.SET_CURRENT_FOLDER, props<{ folder: any }>());

export const getFoldersFail = createAction(ActionType.GET_FOLDERS_FAIL, props<{ payload: { error: any } }>());

export const addFeed = createAction(ActionType.ADD_FEED, props<{ payload: { feed: Feed } }>());

export const addFeeds = createAction(ActionType.ADD_FEED, props<{ payload: { feeds: Feed[] } }>());

export const deleteFeedAll = createAction(ActionType.DELETE_FEED_ALL, props<{ payload: { feed: Feed } }>());

export const addFeedAll = createAction(ActionType.ADD_FEED_ALL, props<{ payload: { feed: Feed } }>());

export const addFeedSuccess = createAction(ActionType.ADD_FEED_SUCCESS);

export const addFeedFail = createAction(ActionType.ADD_FEED_FAIL, props<{ payload: { error: any } }>());

export const deleteFeed = createAction(ActionType.DELETE_FEED, props<{ payload: { feed: Feed } }>());

export const deleteFeedSuccess = createAction(ActionType.DELETE_FEED_SUCCESS);

export const deleteFeedFail = createAction(ActionType.DELETE_FEED_FAIL, props<{ payload: { error: any } }>());

export const toggleUserFeed = createAction(ActionType.TOGGLE_USER_FEED, props<{ payload: { feed: Feed } }>());

const all = union({
  catalogInitialized,
  setCurrentCatalog,
  unsetCurrentCatalog,
  getUserFeeds,
  getUserFeedsSuccess,
  getUserFeedsFail,
  getFolders,
  getFoldersSuccess,
  getFoldersFail,
  addFeed,
  addFeedSuccess,
  addFeedFail,
  deleteFeed,
  deleteFeedSuccess,
  deleteFeedFail,
  toggleUserFeed,
  deleteFeedAll,
  addFeedAll
});

export type CatalogActionsUnion = typeof all;
