import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoBannerContainerComponent } from './containers/info-banner-container/info-banner-container.component';
import { InfoBannerUiModule } from '@purplefront/info-banner/ui';
import { InfoBannerDataAccessModule } from '@purplefront/info-banner/data-access';

@NgModule({
  imports: [CommonModule, InfoBannerUiModule, InfoBannerDataAccessModule],
  declarations: [InfoBannerContainerComponent],
  exports: [InfoBannerContainerComponent]
})
export class InfoBannerFeatureShellModule {}
