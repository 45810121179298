import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  FLASHBRIEFING_FEATURE_KEY,
  State,
  FlashBriefingPartialState,
  flashBriefingAdapter
} from './flash-briefing.reducer';

// Lookup the 'FlashBriefing' feature state managed by NgRx
export const getFlashBriefingState = createFeatureSelector<State>(FLASHBRIEFING_FEATURE_KEY);

const { selectAll, selectEntities } = flashBriefingAdapter.getSelectors();

export const getFlashBriefingLoaded = createSelector(getFlashBriefingState, (state: State) => state.loaded);

export const getFlashBriefingError = createSelector(getFlashBriefingState, (state: State) => state.error);

export const getAllFlashBriefing = createSelector(getFlashBriefingState, (state: State) => selectAll(state));

export const getFlashBriefingEntities = createSelector(getFlashBriefingState, (state: State) => selectEntities(state));

export const getSelectedId = createSelector(getFlashBriefingState, (state: State) => state.selectedId);

export const getSelectedArticles = createSelector(getFlashBriefingState, (state: State) => state.articles);

export const getMenuToggled = createSelector(getFlashBriefingState, (state: State) => state.menuToggled);

export const getFlashBriefingTitle = createSelector(getFlashBriefingState, (state: State) => state.title);

export const getNavigationContext = createSelector(
  getFlashBriefingState,
  (state: State) => state.navigatedFromFlashBriefing
);

export const getSelected = createSelector(
  getFlashBriefingEntities,
  getSelectedId,
  (entities, selectedId) => selectedId && entities[selectedId]
);
