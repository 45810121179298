import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { startWith, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { RequestCache, RequestCacheWithMap } from '../_services/request-cache.service';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCacheWithMap) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!isCachable(req)) {
      return next.handle(req);
    }

    const cachedResponse = this.cache.get(req);

    if (req.headers.get('x-refresh')) {
      const results$ = sendRequest(req, next, this.cache);
      return cachedResponse ? results$.pipe(startWith(cachedResponse)) : results$;
    }

    return cachedResponse ? of(cachedResponse) : sendRequest(req, next, this.cache);
  }
}

/** Only GET requests are cachable */
function isCachable(req: HttpRequest<any>) {
  return req.method === 'GET';
}

/**
 * Get server response observable by sending request to `next()`.
 * Will add the response to the cache on the way out.
 */
function sendRequest(req: HttpRequest<any>, next: HttpHandler, cache: RequestCache): Observable<HttpEvent<any>> {
  const noHeaderReq = req.clone();

  return next.handle(noHeaderReq).pipe(
    tap((event) => {
      if (event instanceof HttpResponse) {
        cache.put(req, event); // Update the cache.
      }
    })
  );
}
