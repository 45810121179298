<div class="reset-pass-view">
  <div class="row justify-content-sm-center">
    <div class="reset-pass-view__logo__block col-md-5">
      <img src="assets/images/logos/etx-dailyup-color-logo.png" alt="ETX Daily UP" />
    </div>
  </div>

  <div class="reset-pass-view__reset-pass__block column justify-content-md-center">
    <h2>{{"AUTH_RESET_NEW_PASSWORD" | translate}}</h2>
    <p class="auth-reset-pass-instructions">{{'AUTH_RESET_PASS_INSTRUCTION' | translate}}</p>
    <form [formGroup]="sendEmailForm" (ngSubmit)="sendEmail(sendEmailForm.value)" method="POST">
      <div class="form-group">
        <label for="reset-pass-email"></label
        ><input class="form-control" id="reset-pass-email" type="email" placeholder="Email" formControlName="email" />
        <div class="error" *ngIf="!sendEmailForm.controls['email'].valid && sendEmailForm.controls['email'].touched">
          <div *ngIf="sendEmailForm.controls['email'].errors.required">* Email address is required</div>
          <div *ngIf="sendEmailForm.controls['email'].errors.pattern">* Incorrect format</div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="!sendEmailForm.valid">
        {{"AUTH_SUBMIT_NEW_PASS" | translate}}
      </button>
    </form>
  </div>
</div>
