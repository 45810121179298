import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { OnDemandPreloadStrategy } from '@purplefront/shared';
import { Page404Container } from '@purplefront/app-container/feature-shell';

import { AppShellComponent } from './app-shell/app-shell.component';
import { Catalog404Container, CatalogContainer } from '@purplefront/catalog/feature-shell';
import { SessionGuard } from '@purplefront/auth/data-access';
import { CatalogResolver } from '@purplefront/catalog/data-access';
import { HomeContainerComponent } from '@purplefront/home/feature-shell';

const routes: Routes = [
  { path: 'home', component: HomeContainerComponent },
  {
    path: 'catalog-not-found',
    children: [{ path: '**', component: Catalog404Container }]
  },
  {
    path: 'auth',
    loadChildren: () => import('@purplefront/auth/feature-shell').then((m) => m.AuthFeatureShellModule),
    data: { preload: true }
  },
  {
    path: 'articles/:sourceType/:lang/:id/:mainCategory/:title',
    loadChildren: () => import('@purplefront/article/feature-shell').then((m) => m.ArticleFeatureShellModule)
  },
  {
    path: '',
    component: CatalogContainer,
    canActivate: [SessionGuard],
    resolve: [CatalogResolver],
    children: [
      { path: '', redirectTo: 'discover', pathMatch: 'full' },
      {
        path: 'discover/:lang',
        data: { preload: true },
        loadChildren: () => import('@purplefront/afp-relax/feature-shell').then((m) => m.AfpRelaxFeatureShellModule)
      },
      {
        path: 'preferences/:section/:lang',
        data: { preload: true },
        canActivate: [SessionGuard],
        loadChildren: () =>
          import('@purplefront/preferences/feature-shell').then((m) => m.PreferencesFeatureShellModule)
      },
      {
        path: 'workspace',
        data: { preload: true },
        loadChildren: () => import('@purplefront/workspace/feature-shell').then((m) => m.WorkspaceFeatureShellModule)
      },
      {
        path: 'searchPage/:lang',
        data: { preload: true },
        loadChildren: () => import('@purplefront/search/feature-shell').then((m) => m.SearchFeatureShellModule)
      },
      {
        path: 'upgrade-account',
        data: { preload: true },
        loadChildren: () =>
          import('@purplefront/upgrade-account/feature-shell').then((m) => m.UpgradeAccountFeatureShellModule)
      }
    ]
  },
  { path: 'app-shell-path', component: AppShellComponent },
  { path: 'not-found', component: Page404Container },
  { path: '**', redirectTo: '/not-found' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules // or OnDemandPreloadStrategy
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingMobisation {}
