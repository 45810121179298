import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { InfoBannerActions, loadInfoBanners, loadInfoBannersFailure, loadInfoBannersSuccess } from '../actions';
import { InfoBannerService } from '../../services/info-banner.service';

import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { BroadcastMessage } from '../../models/broadcast-message.model';

@Injectable()
export class InfoBannerEffects {
  constructor(private _actions$: Actions<InfoBannerActions>, private _infoBannerService: InfoBannerService) {}

  loadInfoBanners$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadInfoBanners),
      switchMap(() => {
        return this._infoBannerService.getMessage().pipe(
          map((res: BroadcastMessage[]) => {
            return loadInfoBannersSuccess({ messages: res });
          }),
          catchError((err) => {
            return of(loadInfoBannersFailure({ error: err }));
          })
        );
      })
    )
  );
}
