import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromSearch from './store/search.reducer';
import { SearchEffects } from './store/search.effects';
import { SearchApiService } from './_api';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromSearch.SEARCH_FEATURE_KEY, fromSearch.reducer),
    EffectsModule.forFeature([SearchEffects])
  ],
  providers: [SearchApiService]
})
export class SearchDataAccessModule {}
