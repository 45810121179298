import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as SearchActions from './search.actions';
import { SearchEntity } from './search.models';

export const SEARCH_FEATURE_KEY = 'search';

export interface State extends EntityState<SearchEntity> {
  selectedId?: string | number; // which Search record has been selected
  loaded: boolean; // has the Search list been loaded
  error?: string | null; // last known error (if any)
  searchString: string;
  searchResponse: {};
}

export interface SearchPartialState {
  readonly [SEARCH_FEATURE_KEY]: State;
}

export const searchAdapter: EntityAdapter<SearchEntity> = createEntityAdapter<SearchEntity>();

export const initialState: State = searchAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  searchString: null,
  searchResponse: null
});

const searchReducer = createReducer(
  initialState,
  on(SearchActions.loadSearch, (state) => ({ ...state, loaded: false, error: null })),
  on(SearchActions.loadSearchSuccess, (state, { search }) => searchAdapter.setAll(search, { ...state, loaded: true })),
  on(SearchActions.loadSearchFailure, (state, { error }) => ({ ...state, error })),
  on(SearchActions.searchQuery, (state, action) => {
    return {
      ...state,
      loaded: false
    };
  }),
  on(SearchActions.resetSearchQuery, (state, action) => {
    return {
      ...state,
      loaded: false,
      searchString: ''
    };
  }),
  on(SearchActions.searchQuerySuccess, (state, action) => {
    return {
      ...state,
      searchResponse: action.payload.searchResponse,
      searchString: action.payload.searchString,
      loaded: true
    };
  }),
  on(SearchActions.searchQueryFail, (state, action) => {
    return {
      ...state,
      loaded: true
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return searchReducer(state, action);
}
