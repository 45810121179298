import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { map, switchMap, catchError, mergeMap, tap, take } from 'rxjs/operators';

import { PreferencesState } from '../reducers';
import * as preferencesActions from '../actions/preferences.actions';
import { TranslateService } from '@ngx-translate/core';

import { CatalogState } from '@purplefront/catalog/data-access';
import { AppRouterState } from '@purplefront/app-router/data-access';
import { Feed } from '@purplefront/catalog/data-access';
import { PreferencesApi } from '../../_api/preferences.api';
import { InterfaceLanguageService } from '../../_services/interface-language.service';

@Injectable()
export class PreferencesEffects {
  feed$: Observable<string | Feed>;
  searchString$: Observable<string>;
  page$: Observable<string>;

  constructor(
    private _action$: Actions<preferencesActions.PreferencesActionsUnion>,
    private _preferencesService: PreferencesApi,
    private _store: Store<PreferencesState>,
    private _catalogStore: Store<CatalogState>,
    private _routerStore: Store<AppRouterState>,
    private _translate: TranslateService,
    private _interfaceLangService: InterfaceLanguageService
  ) {}

  loadUserPreferences$ = createEffect(() =>
    this._action$.pipe(
      ofType(preferencesActions.loadUserPreferences),
      switchMap(() => {
        return this._preferencesService.getUserPreferences().pipe(
          map((res) => {
            return preferencesActions.loadUserPreferencesSuccess({
              payload: res
            });
          }),
          catchError((err) => {
            return of(
              preferencesActions.loadUserPreferencesFail({
                payload: { error: err }
              })
            );
          })
        );
      })
    )
  );

  loadUserPreferencesSuccess$ = createEffect(
    () =>
      this._action$.pipe(
        ofType(preferencesActions.loadUserPreferencesSuccess),
        map((action) => action.payload),
        tap((pref) => this._interfaceLangService.init(pref))
      ),
    { dispatch: false }
  );

  changeInterfaceLang$ = createEffect(() =>
    this._action$.pipe(
      ofType(preferencesActions.changeInterfaceLang),
      switchMap((action) => {
        return this._preferencesService.setInterfaceLang(action.payload.interfaceLang).pipe(
          take(1),
          tap((response) => this._translate.use(action.payload.interfaceLang)),
          map((response) => preferencesActions.changeInterfaceLangSuccess()),
          catchError((err) => {
            return of(
              preferencesActions.changeInterfaceLangFail({
                payload: { error: err }
              })
            );
          })
        );
      })
    )
  );

  changeContentLang$ = createEffect(() =>
    this._action$.pipe(
      ofType(preferencesActions.changeContentLang),
      switchMap((action) => {
        return this._preferencesService
          .setContentLang(action.payload.contentLang)
          .pipe(map((response) => preferencesActions.changeContentLangSuccess()));
      })
    )
  );

  changeAdvancedFeatures$ = createEffect(() =>
    this._action$.pipe(
      ofType(preferencesActions.changeAdvancedFeatures),
      mergeMap((action) => {
        return this._preferencesService.setAdvancedFeatures(action.payload.advanced).pipe(
          map((feats) => preferencesActions.changeAdvancedFeaturesSuccess()),
          catchError((err) => {
            return of(
              preferencesActions.changeAdvancedFeaturesFail({
                payload: { error: err }
              })
            );
          })
        );
      })
    )
  );

  setGuidedTour$ = createEffect(() =>
    this._action$.pipe(
      ofType(preferencesActions.setGuidedTourComplete),
      mergeMap((action) => {
        return this._preferencesService.setGuidedTour(action.payload).pipe(
          map((feats) => preferencesActions.setGuidedTourCompleteSuccess()),
          catchError((err: any) => {
            return of(
              preferencesActions.setGuidedTourCompleteFail({
                error: err
              })
            );
          })
        );
      })
    )
  );

  setAdvancedFilers$ = createEffect(() =>
    this._action$.pipe(
      ofType(
        preferencesActions.setFilterSortBy,
        preferencesActions.setFilterViewMode,
        preferencesActions.setFilterContentType,
        preferencesActions.setFilterFrequency,
        preferencesActions.setFilterSeason,
        preferencesActions.setFilterSource
      ),
      mergeMap((action) => {
        return this._preferencesService.setAdvancedFilters(action.payload).pipe(
          map((filters) => preferencesActions.setFilterSuccess({ payload: filters })),
          catchError((err) => {
            return of(
              preferencesActions.setFilterFail({
                payload: { error: err }
              })
            );
          })
        );
      })
    )
  );

  setLastSelectedVoice$ = createEffect(() =>
    this._action$.pipe(
      ofType(preferencesActions.setLastSelectedVoice),
      switchMap((action) =>
        this._preferencesService.setDefaultVoice(action.payload).pipe(
          map((res) => preferencesActions.setLastSelectedVoiceSuccess({ payload: action.payload })),
          catchError((err) => of(preferencesActions.setLastSelectedVoiceFail({ error: err })))
        )
      )
    )
  );

  setCookieBanner$ = createEffect(() =>
    this._action$.pipe(
      ofType(preferencesActions.setCookieBanner),
      switchMap((action) => {
        return this._preferencesService.setCookieBanner(action.payload.hasAcceptedCookies).pipe(
          map((cookies) => preferencesActions.setCookieBannerSuccess()),
          catchError((err) => {
            return of(
              preferencesActions.setCookieBannerFail({
                payload: { error: err }
              })
            );
          })
        );
      })
    )
  );
}
