import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderRioContainerComponent } from './header-rio-container/header-rio-container.component';
import { HeaderRioUiModule } from '@purplefront/header-rio/ui';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { HeaderEffects, reducer } from '@purplefront/header/data-access';
import { EffectsModule } from '@ngrx/effects';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '@purplefront/header/feature-shell';
import { HttpClient } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      /* {path: '', pathMatch: 'full', component: InsertYourComponentHere} */
    ]),
    HeaderRioUiModule,
    StoreModule.forFeature('header', reducer),
    EffectsModule.forFeature([HeaderEffects]),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [HeaderRioContainerComponent],
  exports: [HeaderRioContainerComponent]
})
export class HeaderRioFeatureShellModule {}
