import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@purplefront/shared';
import { SavedSearchUiModule } from '@purplefront/saved-search/ui';
import { MenuUiModule } from '@purplefront/menu/ui';

import { MenuContainer } from './containers/menu/menu.container';
import { MenuRioUiModule } from '@purplefront/menu-rio/ui';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    SavedSearchUiModule,
    SharedModule,
    TranslateModule,
    MenuUiModule,
    MenuRioUiModule
  ],
  declarations: [MenuContainer],
  exports: [MenuContainer]
})
export class MenuFeatureShellModule {}
