import { ActionReducer, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/editor.actions';
import { EditorActionsUnion } from '../actions';
import { FORM_SYNC_STORE_KEY, storageSync } from '@larscom/ngrx-store-storagesync';

import {
  deleteUploadedFileAudio,
  loadCurrentAudioStorySuccess,
  resetCurrentAudioStory,
  setPlyrAudioSource,
  setPlyrVideoSource,
  updateCurrentAudioStorySuccess,
  uploadFileAudioSuccess,
  setLastGeneratedFormats
} from '../actions/editor.actions';
import { TranslatableLang } from '../../_models/translatable-lang.model';

export interface EditorState {
  error: any;
  loadingVoices: boolean;
  voices: any;
  voice: string;
  uploadedAudio: {
    path: string;
    position: number;
    data: any;
  };
  position: number;
  generatedVideoUrl: string;
  generatedVideoId: string;
  currentAudioStory: any;
  plyrAudioSource: any;
  plyrVideoSource: any;
  lastSelectedFormats: string[];
  translatableLangs: TranslatableLang[];
  isTranslationModalOpen: boolean;
  isPronunciationModalOpen: boolean;
  translateFailAudioStory?: any;
  translateFailFormats: any[];
}

export const initialEditorState: EditorState = {
  error: null,
  loadingVoices: false,
  voices: null,
  voice: '',
  uploadedAudio: {
    path: '',
    position: null,
    data: null
  },
  position: 0,
  generatedVideoUrl: '',
  generatedVideoId: null,
  currentAudioStory: null,
  plyrAudioSource: null,
  plyrVideoSource: null,
  lastSelectedFormats: [],
  translatableLangs: null,
  isTranslationModalOpen: false,
  isPronunciationModalOpen: false,
  translateFailFormats: []
};

export function storageSyncReducer(reducer: ActionReducer<any>) {
  // provide all feature states within the features array
  // features which are not provided, do not get synced
  const metaReducer = storageSync<any>({
    features: [{ stateKey: FORM_SYNC_STORE_KEY, storageForFeature: window.sessionStorage }],
    rehydrate: true,
    // defaults to localStorage
    storage: window.localStorage
  });

  return metaReducer(reducer);
}

export const editorReducer = createReducer(
  initialEditorState,
  on(actions.loadVoices, (state, action) => {
    return {
      ...state,
      loadingVoices: true
    };
  }),
  on(actions.loadVoicesFail, (state, action) => {
    return {
      ...state,
      voices: [],
      loadingVoices: false
    };
  }),
  on(actions.loadVoicesSuccess, (state, action) => {
    return {
      ...state,
      voices: action.payload.voices,
      loadingVoices: false
    };
  }),
  on(uploadFileAudioSuccess, (state, action) => {
    return {
      ...state,
      uploadedAudio: {
        path: action.payload.body.data.path,
        position: action.payload.position,
        data: action.payload.body.data
      }
    };
  }),
  on(setPlyrAudioSource, (state, action) => {
    return {
      ...state,
      plyrAudioSource: action.payload
    };
  }),
  on(setPlyrVideoSource, (state, action) => {
    return {
      ...state,
      plyrVideoSource: action.payload
    };
  }),
  on(deleteUploadedFileAudio, (state) => {
    return {
      ...state,
      uploadedAudio: {
        path: '',
        position: null,
        data: null
      }
    };
  }),

  on(loadCurrentAudioStorySuccess, (state, action) => {
    return {
      ...state,
      currentAudioStory: action.payload
    };
  }),
  on(updateCurrentAudioStorySuccess, (state, action) => {
    return {
      ...state,
      currentAudioStory: action.payload
    };
  }),
  on(resetCurrentAudioStory, (state) => {
    return {
      ...state,
      currentAudioStory: null
    };
  }),
  on(setLastGeneratedFormats, (state, action) => {
    return {
      ...state,
      lastSelectedFormats: action.formats
    };
  }),
  on(actions.resetGeneratedFormats, (state) => {
    return {
      ...state,
      lastSelectedFormats: []
    };
  }),
  on(actions.loadTranslatableLangSuccess, (state, action) => {
    return {
      ...state,
      translatableLangs: action.translatableLangs
    };
  }),
  on(actions.openTranslationModal, (state, action) => {
    return {
      ...state,
      isTranslationModalOpen: true,
      translateFailAudioStory: action.payload,
      translateFailFormats: [...state.translateFailFormats, { value: action.payload.format }]
    };
  }),
  on(actions.closeTranslationModal, (state, action) => {
    return {
      ...state,
      isTranslationModalOpen: false,
      translateFailAudioStory: action.payload
    };
  }),
  on(actions.openPronunciationModal, (state) => {
    return {
      ...state,
      isPronunciationModalOpen: true
    };
  }),
  on(actions.closePronunciationModal, (state) => {
    return {
      ...state,
      isPronunciationModalOpen: false
    };
  })
);

export function reducer(state: EditorState, action: EditorActionsUnion): EditorState {
  return editorReducer(state, action);
}
