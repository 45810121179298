import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-latest-articles',
  templateUrl: './latest-articles.component.html',
  styleUrls: ['./latest-articles.component.scss']
})
export class LatestArticlesComponent implements OnInit {
  @Input() latestArticles: any[];
  @Output() openArticle$ = new EventEmitter();
  @Output() searchString$ = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}

  openArticle(article): void {
    this.openArticle$.emit(article);
  }

  search($event: any) {
    this.searchString$.emit($event);
  }
}
