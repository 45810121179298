import { SwiperOptions } from 'swiper';

export const swiperConfig: SwiperOptions = {
  direction: 'horizontal',
  slidesPerView: 4,
  // slideToClickedSlide: false,
  centerInsufficientSlides: false,
  grabCursor: true,
  // mousewheel: false,
  // scrollbar: {
  //   draggable: true
  // },
  // effect: 'slide',
  //navigation: true,
  // watchSlidesProgress: true,
  // keyboard: true,
  // pagination: false,
  // centeredSlides: true,
  // centeredSlidesBounds: true,
  // loop: false,
  // roundLengths: true,
  slidesOffsetBefore: 100,
  slidesOffsetAfter: 420,
  spaceBetween: 50,
  // fadeEffect: {
  //   crossFade: true
  // }
  //Responsive breakpoints
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
      spaceBetween: 20,
      slidesOffsetAfter: 100,

    },
    // when window width is >= 480px
    480: {
      slidesPerView: 3,
      spaceBetween: 30
    }
  }
};
