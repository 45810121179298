import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoBannerComponent } from './components/info-banner/info-banner.component';

@NgModule({
  imports: [CommonModule],
  exports: [InfoBannerComponent],
  declarations: [InfoBannerComponent]
})
export class InfoBannerUiModule {}
