import { Injectable } from '@angular/core';
import { AudioStory, AudioStoryState } from '@purplefront/audio-story/data-access';
import { Observable } from 'rxjs';
import { EditorApi } from '../_api';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Lexicon, Voice } from '@purplefront/editor/data-access';
import { PreferencesApi } from '@purplefront/preferences/data-access';
import { TranslatableLang } from '../_models/translatable-lang.model';

@Injectable({
  providedIn: 'root'
})
export class EditorService {
  readonly maxImagesPerItem = 8;
  readonly commonElement = 'i';
  readonly breakOptions = [
    { value: 'none', text: 'none', realValue: '10ms' },
    {
      value: 'small',
      text: 'small',
      realValue: '250ms'
    },
    {
      value: 'medium',
      text: 'medium',
      realValue: '1000ms'
    },
    {
      value: 'large',
      text: 'large',
      realValue: '2500ms'
    }
  ];

  readonly itemCharacterLimit = 8000;
  constructor(
    private _editorApi: EditorApi,
    private _audioStoryStore: Store<AudioStoryState>,
    private _translate: TranslateService,
    private _prefApi: PreferencesApi
  ) {}

  strip(html): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  removeHTMLElements(text: string) {
    return text.replace(/(<\/?(?:sub|voice|break)[^>]*>)|<[^>]+>/gim, '$1');
  }

  mapArticleToText(text): string {
    const translatedText = this.translateNewLine(text);
    return this.strip(translatedText);
  }

  translateNewLine(text): string {
    return text ? text.replace(/<\s*\/?br\s*[\/]?>/gi, '\n') : null;
  }

  replaceHtmlEntities(str: string): string {
    const translate_re = /&(nbsp|amp|quot|lt|gt|zwnj);/gim;
    const translate = {
      nbsp: ' ',
      amp: '&',
      quot: '"',
      lt: '<',
      gt: '>',
      zwnj: ''
    };
    return str.replace(translate_re, (match, entity) => translate[entity]);
  }

  mapFormToAudioStory(
    items: any,
    voiceId: number,
    name: string,
    background: { url: string; volume: number },
    translate: boolean = false
  ): AudioStory {
    const voiceIdsUnavailableForTranslation = [20, 40, 41];
    const shouldTranslate = translate ? !voiceIdsUnavailableForTranslation.includes(voiceId) : false;
    const audioStory = {
      name: name || 'audioStory name',
      title: name || 'audioStory name',
      background: background || null,
      image: 'image',
      items: []
    };
    for (const item of items) {
      if (item.type === 'audio') {
        audioStory.items.push(this.createAudioItem(item));
      } else if (item.type === 'text') {
        item.articleText = this.generateSSML(item.articleText, this.commonElement, this.breakOptions);
        item.articleText = this.removeHTMLElements(item.articleText);
        item.articleText = this.replaceHtmlEntities(item.articleText);
        audioStory.items.push(this.createTextItem(item, voiceId, shouldTranslate));
      }
    }
    return audioStory;
  }

  mapAudioStoryToFormValues(audioStory) {
    const formValues = {
      name: audioStory.name,
      title: audioStory.title,
      images: [audioStory.image],
      background: audioStory.background,
      items: []
    };
    for (const item of audioStory.items) {
      formValues.items.push(item);
    }

    return formValues;
  }

  createTextItem(item, voice, translate) {
    return {
      type: 'text',
      text: item.articleText,
      title: item.title,
      audio: null,
      jingle: item.jingle,
      voice_id: voice,
      images: item.images,
      history: null,
      lang: item.lang,
      translate: translate
    };
  }

  createAudioItem(item) {
    return {
      type: 'audio',
      title: item.title,
      audio: item.audio,
      jingle: item.jingle,
      images: item.images
    };
  }

  getVoices(): Observable<Voice[]> {
    return this._editorApi.getVoices().pipe(map((voices: Voice[]) => this.sortVoice(voices)));
  }

  getFirstVoiceInItems(items): number | null {
    for (const item of items) {
      if (item.voice_id) {
        return item.voice_id;
      }
    }
    return null;
  }

  getVoice(id, voices): Voice {
    return voices?.find((voice) => String(voice.id) === String(id));
  }

  isTextItemsInvalid(items): number[] {
    let invalidItems = [];
    items.forEach((item, index) => {
      if ((item.type === 'text' && !item.articleText) || item.articleText === '\n') {
        invalidItems.push(index + 1);
      }
    });

    return invalidItems;
  }

  isAudioItemsInvalid(items): number[] {
    let invalidAudioItems = [];
    items.forEach((item, index) => {
      if (item.type === 'audio' && item.audio == null) {
        invalidAudioItems.push(index + 1);
      }
    });
    return invalidAudioItems;
  }

  getItemsCharacterCount(items): number[] {
    let itemsAboveCharacterLimit = [];
    items.forEach((item, index) => {
      if (item.type === 'text' && item.articleText && this.isArticleTooLong(item.articleText)) {
        itemsAboveCharacterLimit.push(index + 1);
      }
    });

    return itemsAboveCharacterLimit;
  }

  translateLanguage(voice: Voice): string {
    if (voice.language.toUpperCase() === 'UK-UA') {
      return this._translate.instant(voice.language.toUpperCase());
    }
    return this._translate.instant(voice.language.substring(0, 2).toUpperCase());
  }

  translateCountry(voice: Voice): string {
    return this._translate.instant(voice.country.toUpperCase());
  }

  sortVoice(voices: Voice[]): Voice[] {
    return voices.sort(
      (a: Voice, b: Voice) =>
        this.translateLanguage(a).localeCompare(this.translateLanguage(b)) ||
        this.translateCountry(a).localeCompare(this.translateCountry(b)) ||
        a.label.localeCompare(b.label)
    );
  }

  setLastGeneratedVoice(voice: Voice) {
    return this._prefApi.setLastUsedVoice(voice);
  }

  getLastGeneratedVoices(): Observable<Voice[]> {
    return this._prefApi.getLastUsedVoices();
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  getBreakInfo(options, value) {
    let find = null;
    if (options) {
      find = options.find((option) => option.value === value);
    }
    return find;
  }

  generateSSML(html: any, commonElement: any, options?: any, lexicons?: Lexicon[]): string {
    options = [
      { value: 'none', text: 'none', realValue: '10ms' },
      {
        value: 'small',
        text: 'small',
        realValue: '250ms'
      },
      {
        value: 'medium',
        text: 'medium',
        realValue: '1000ms'
      },
      {
        value: 'large',
        text: 'large',
        realValue: '2500ms'
      }
    ];

    let SSML = '';
    const editorClone = document.createElement('div');
    editorClone.innerHTML = html;

    // the order is important, caution when moving the code !
    let allSelectedElems = editorClone.querySelectorAll('.selected, * > .selected');
    allSelectedElems.forEach((selected) => {
      selected.classList.remove('selected');
    });

    let allVoiceSub = editorClone.querySelectorAll('.voice.sub[name][alias]');
    allVoiceSub.forEach((inside) => {
      this.replaceELemByContent(inside);
    });

    let allErrorElems = editorClone.querySelectorAll('.voice .sub');
    allErrorElems.forEach((inside) => {
      this.replaceELemByContent(inside);
    });

    let allSubElem = editorClone.querySelectorAll(`.sub[alias]`);
    allSubElem.forEach((elem: any) => {
      this.replaceELemByContent(elem);
    });

    let allVoiceElem = editorClone.querySelectorAll(`.voice[name]`);
    allVoiceElem.forEach((elem: any) => {
      this.replaceELemByContent(elem);
    });

    let breakElems = editorClone.querySelectorAll('.pause');
    breakElems.forEach((breakElem) => {
      const attributePause = breakElem.getAttribute('pause');
      const breakInfo = this.getBreakInfo(options, attributePause);
      if (breakInfo) {
        this.replaceELemByContent(breakElem, 'break', breakInfo.realValue);
      } else {
        this.replaceELemByContent(breakElem, 'break');
      }
    });

    // last rules remove all commonElement and replace by a text node
    let emptyElems = editorClone.querySelectorAll(commonElement);
    emptyElems.forEach((empty) => {
      this.replaceELemByContent(empty, 'text');
    });

    editorClone.normalize();
    SSML = editorClone.innerHTML;
    editorClone.remove();
    return SSML;
  }

  generateFakeSSML(ssml: string) {
    const fakeSsml = document.createElement('div');
    fakeSsml.innerHTML = ssml;

    let attributeLabel = 'name';
    const voices = fakeSsml.querySelectorAll(`voice[${attributeLabel}]`);
    voices.forEach((voice) => {
      const attributeName = voice.getAttribute(attributeLabel);
      const newElement = document.createElement('i');
      newElement.classList.add('voice');
      newElement.setAttribute(attributeLabel, attributeName);
      newElement.innerHTML = voice.innerHTML;
      voice.after(newElement);
      voice.remove();
    });

    attributeLabel = 'alias';
    const subs = fakeSsml.querySelectorAll(`sub[${attributeLabel}]`);
    subs.forEach((sub) => {
      const attributeAlias = sub.getAttribute(attributeLabel);
      const newElement = document.createElement('i');
      newElement.classList.add('sub');
      newElement.setAttribute(attributeLabel, attributeAlias);
      newElement.innerHTML = sub.innerHTML;
      sub.after(newElement);
      sub.remove();
    });

    attributeLabel = 'time';
    const pauses = fakeSsml.querySelectorAll(`break[${attributeLabel}]`);
    pauses.forEach((pause) => {
      const attributePause = pause.getAttribute(attributeLabel);
      const newElement = document.createElement('i');
      const customAttributeLabel = 'pause';
      newElement.classList.add(customAttributeLabel);
      newElement.setAttribute(customAttributeLabel, this.getPauseOptionLabel(attributePause));
      newElement.innerHTML = pause.innerHTML;
      pause.after(newElement);
      pause.remove();
    });

    // remove classception for the voiceSub element
    const ception = fakeSsml.querySelectorAll(`.voice[name]`);
    ception.forEach((cept) => {
      const allSub = cept.querySelectorAll('.sub');
      const sub = allSub[0];
      if (cept?.textContent === sub?.textContent && allSub?.length === 1) {
        cept.classList.add('sub');
        cept.setAttribute('alias', sub.getAttribute('alias'));
        cept.innerHTML = sub.innerHTML;
        sub.remove();
      }
    });
    const fake = fakeSsml.innerHTML;
    fakeSsml.remove();
    return fake || null;
  }

  getPauseOptionLabel(realValue: string): string {
    let match = this.breakOptions.find((option) => option.realValue === realValue);
    return match ? match.text : null;
  }

  replaceELemByContent(elem, type?: string, realValue?: string) {
    const attributeName = elem.getAttribute('name');
    const attributeAlias = elem.getAttribute('alias');
    const attributePause = elem.getAttribute('pause');
    if (type === 'text') {
      const newTextNode = document.createTextNode(elem.innerHTML);
      elem.after(newTextNode);
      elem.remove();
    } else if (type === 'break' && attributePause) {
      const newElement = document.createElement('break');
      if (realValue) {
        newElement.setAttribute('time', realValue);
      } else {
        newElement.setAttribute('time', attributePause);
      }
      elem.after(newElement);
      elem.remove();
    } else if (!type || type === 'voicesub') {
      if ((attributeName && !attributeAlias) || (attributeAlias && !attributeName)) {
        let newElement = null;
        if (elem.classList.contains('voice')) {
          newElement = document.createElement('voice');
          newElement.setAttribute('name', attributeName);
          newElement.innerHTML = elem.innerHTML;
          elem.after(newElement);
          elem.remove();
        }
        if (elem.classList.contains('sub')) {
          newElement = document.createElement('sub');
          newElement.setAttribute('alias', attributeAlias);
          newElement.innerHTML = elem.innerHTML;
          elem.after(newElement);
          elem.remove();
        }
      } else if (attributeName && attributeAlias) {
        let newVoiceElement = null;
        let newSubElement = null;
        newVoiceElement = document.createElement('voice');
        newVoiceElement.setAttribute('name', attributeName);
        newSubElement = document.createElement('sub');
        newSubElement.setAttribute('alias', attributeAlias);
        newSubElement.innerHTML = elem.innerHTML;
        elem.after(newVoiceElement);
        newVoiceElement.appendChild(newSubElement);
        elem.remove();
      }
    }
  }

  isTextSSML(str: string): boolean {
    const div = document.createElement('div');
    div.innerHTML = str;
    const isSSML = div.querySelector('voice, sub, break');
    return !!isSSML;
  }

  generateListenableText(option: { name?: any; alias?: any; text?: any; insideElem?: boolean }): string {
    const name = option.name;
    const alias = option.alias;
    const text = option.text;
    let res: string = null;
    if (name && !alias) {
      const newElement = document.createElement('voice');
      newElement.setAttribute('name', name);
      newElement.innerHTML = text;
      res = newElement.outerHTML;
      newElement.remove();
    } else if (!name && alias) {
      const newElement = document.createElement('sub');
      newElement.setAttribute('alias', alias);
      newElement.innerHTML = text;
      res = newElement.outerHTML;
      newElement.remove();
    } else if (name && alias) {
      if (!option.insideElem) {
        const newElementVoice = document.createElement('voice');
        newElementVoice.setAttribute('name', name);
        const newElementSub = document.createElement('sub');
        newElementSub.setAttribute('alias', alias);
        newElementSub.innerHTML = text;
        newElementVoice.appendChild(newElementSub);
        res = newElementVoice.outerHTML;
        newElementVoice.remove();
        newElementSub.remove();
      } else {
        const newElementVoice = document.createElement('voice');
        newElementVoice.setAttribute('name', name);
        newElementVoice.innerHTML = text;
        res = newElementVoice.outerHTML;
        newElementVoice.remove();
      }
    } else if (!name && !alias) {
      const newElementVoice = document.createElement('voice');
      // newElementVoice.setAttribute('name', name);
      newElementVoice.innerHTML = text;
      res = newElementVoice.outerHTML;
      newElementVoice.remove();
    }
    return res;
  }

  isNavigator(name): boolean {
    let navigatorName: string = null;

    if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) != -1) {
      navigatorName = 'opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      navigatorName = 'chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      navigatorName = 'safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      navigatorName = 'firefox';
    } else {
      navigatorName = 'unknown';
    }
    return navigatorName === name;
  }

  isOS(name) {
    let OSName: string;
    if (navigator.userAgent.indexOf('Mac') != -1) OSName = 'mac';
    if (navigator.userAgent.indexOf('Windows') != -1) OSName = 'win';
    return OSName === name;
  }

  getScreenRatio() {
    return Math.round(window.devicePixelRatio * 100);
  }

  /**
   * Get selected text
   */
  getSelectedText() {
    const selectedText: any = document.querySelector('#editor .selected, #editor > .selected');
    let rep = selectedText.outerHTML;
    let parentNode = selectedText.parentNode;
    const node = parentNode.classList.value ? parentNode : selectedText;
    if (node.classList.contains('selected')) {
      let text = selectedText.innerHTML;
      rep = text;
    }
    if (node.classList.contains('voice') || node.classList.contains('sub')) {
      const isInsideSub = node.querySelector('.sub');
      if (
        node === selectedText &&
        node.classList.contains('voice') &&
        !node.classList.contains('sub') &&
        !isInsideSub
      ) {
        // listen voice alone
        const nameAttribute = node.getAttribute('name');
        rep = this.generateListenableText({ name: nameAttribute, text: selectedText.innerHTML });
      } else if (
        node === selectedText &&
        node.classList.contains('voice') &&
        !node.classList.contains('sub') &&
        isInsideSub
      ) {
        // listen voice element with other elements inside
        const nameAttribute = node.getAttribute('name');
        const aliasAttribute = isInsideSub.getAttribute('alias');
        let text = selectedText.innerHTML;
        text = this.generateSSML(text, 'i');
        rep = this.generateListenableText({
          name: nameAttribute,
          alias: aliasAttribute,
          text,
          insideElem: true
        });
      } else if (!node.classList.contains('voice') && node.classList.contains('sub')) {
        // listen prononciation alone
        const aliasAttribute = node.getAttribute('alias');
        rep = this.generateListenableText({ alias: aliasAttribute, text: selectedText.textContent });
      } else if (
        !selectedText.classList.contains('voice') &&
        selectedText.classList.contains('sub') &&
        node.classList.contains('voice')
      ) {
        // listen prononciation inside a voice
        const nameAttribute = node.getAttribute('name');
        const aliasAttribute = selectedText.getAttribute('alias');
        rep = this.generateListenableText({
          name: nameAttribute,
          alias: aliasAttribute
        });
      } else if (node === selectedText && node.classList.contains('voice') && node.classList.contains('sub')) {
        // listen a voice and prononciation alone
        const nameAttribute = node.getAttribute('name');
        const aliasAttribute = node.getAttribute('alias');
        rep = this.generateListenableText({
          name: nameAttribute,
          alias: aliasAttribute,
          text: selectedText.textContent
        });
      }
    }
    return this.generateSSML(rep, 'i');
  }

  getTextContentFromInnerHTMLString(str: string) {
    const fakeDom = document.createElement('div');
    fakeDom.innerHTML = str;
    const fakeDomValue = fakeDom.textContent;
    fakeDom.remove();
    return fakeDomValue;
  }

  isArticleTooLong(str: string) {
    const itemsValue = this.getTextContentFromInnerHTMLString(str);
    return itemsValue.length > this.itemCharacterLimit;
  }

  getTranslatableLangs(): Observable<TranslatableLang[]> {
    return this._editorApi.getTranslatableLangs();
  }
}
