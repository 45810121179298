<section class="transversal-cat">
  <div class="container">
    <div class="row justify-content-center mb-sm-5">
      <div class="col-12 col-md-5 col-lg-4 card-transversal-cat">
        <h2 class="transverse-title">#Green</h2>
        <div class="card card-editor-item">
          <div class="row no-gutters align-items-center">
            <div class="col-4">
              <img class="corn-round" src="assets/images/home/transverse-cat/news_2071806.jpg" />
            </div>
            <div class="col-8">
              <div class="card-body">
                <a class="cat-title">TECH</a>
                <div class="text-date">01/06/2020, 16h32</div>
                <h5 class="card-title">41 millions d'internautes ont acheté en ligne</h5>
              </div>
            </div>
          </div>
          <div class="row no-gutters align-items-center">
            <div class="col-4">
              <img class="corn-round" src="assets/images/home/transverse-cat/news_2071807.jpg" />
            </div>
            <div class="col-8">
              <div class="card-body">
                <a class="cat-title">JEUX</a>
                <div class="text-date">01/06/2020, 16h32</div>
                <h5 class="card-title">41 millions d'internautes ont acheté en ligne</h5>
              </div>
            </div>
          </div>
          <div class="row no-gutters align-items-center">
            <div class="col-4">
              <img class="corn-round" src="assets/images/home/transverse-cat/news_2071808.jpg" />
            </div>
            <div class="col-8">
              <div class="card-body">
                <a class="cat-title">ENVIRONNEMENT</a>
                <div class="text-date">01/06/2020, 16h32</div>
                <h5 class="card-title">41 millions d'internautes ont acheté en ligne</h5>
              </div>
            </div>
          </div>
          <div class="row no-gutters align-items-center">
            <div class="col-4">
              <img class="corn-round" src="assets/images/home/transverse-cat/news_2071809.jpg" />
            </div>
            <div class="col-8">
              <div class="card-body">
                <a class="cat-title">BIEN-ÊTRE</a>
                <div class="text-date">01/06/2020, 16h32</div>
                <h5 class="card-title">41 millions d'internautes ont acheté en ligne</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-5 col-lg-4 card-transversal-cat">
        <h2 class="transverse-title">#Perma</h2>
        <div class="card card-editor-item">
          <div class="row no-gutters align-items-center">
            <div class="col-4">
              <img class="corn-round" src="assets/images/home/transverse-cat/news_2071806.jpg" />
            </div>
            <div class="col-8">
              <div class="card-body">
                <a class="cat-title">TECH</a>
                <div class="text-date">01/06/2020, 16h32</div>
                <h5 class="card-title">41 millions d'internautes ont acheté en ligne</h5>
              </div>
            </div>
          </div>
          <div class="row no-gutters align-items-center">
            <div class="col-4">
              <img class="corn-round" src="assets/images/home/transverse-cat/news_2071807.jpg" />
            </div>
            <div class="col-8">
              <div class="card-body">
                <a class="cat-title">JEUX</a>
                <div class="text-date">01/06/2020, 16h32</div>
                <h5 class="card-title">41 millions d'internautes ont acheté en ligne</h5>
              </div>
            </div>
          </div>
          <div class="row no-gutters align-items-center">
            <div class="col-4">
              <img class="corn-round" src="assets/images/home/transverse-cat/news_2071808.jpg" />
            </div>
            <div class="col-8">
              <div class="card-body">
                <a class="cat-title">ENVIRONNEMENT</a>
                <div class="text-date">01/06/2020, 16h32</div>
                <h5 class="card-title">41 millions d'internautes ont acheté en ligne</h5>
              </div>
            </div>
          </div>
          <div class="row no-gutters align-items-center">
            <div class="col-4">
              <img class="corn-round" src="assets/images/home/transverse-cat/news_2071809.jpg" />
            </div>
            <div class="col-8">
              <div class="card-body">
                <a class="cat-title">BIEN-ÊTRE</a>
                <div class="text-date">01/06/2020, 16h32</div>
                <h5 class="card-title">41 millions d'internautes ont acheté en ligne</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-5 col-lg-4 card-transversal-cat">
        <h2 class="transverse-title">#Offbeat</h2>
        <div class="card card-editor-item">
          <div class="row no-gutters align-items-center">
            <div class="col-4">
              <img class="corn-round" src="assets/images/home/transverse-cat/news_2071806.jpg" />
            </div>
            <div class="col-8">
              <div class="card-body">
                <a class="cat-title">TECH</a>
                <div class="text-date">01/06/2020, 16h32</div>
                <h5 class="card-title">41 millions d'internautes ont acheté en ligne</h5>
              </div>
            </div>
          </div>
          <div class="row no-gutters align-items-center">
            <div class="col-4">
              <img class="corn-round" src="assets/images/home/transverse-cat/news_2071807.jpg" />
            </div>
            <div class="col-8">
              <div class="card-body">
                <a class="cat-title">JEUX</a>
                <div class="text-date">01/06/2020, 16h32</div>
                <h5 class="card-title">41 millions d'internautes ont acheté en ligne</h5>
              </div>
            </div>
          </div>
          <div class="row no-gutters align-items-center">
            <div class="col-4">
              <img class="corn-round" src="assets/images/home/transverse-cat/news_2071808.jpg" />
            </div>
            <div class="col-8">
              <div class="card-body">
                <a class="cat-title">ENVIRONNEMENT</a>
                <div class="text-date">01/06/2020, 16h32</div>
                <h5 class="card-title">41 millions d'internautes ont acheté en ligne</h5>
              </div>
            </div>
          </div>
          <div class="row no-gutters align-items-center">
            <div class="col-4">
              <img class="corn-round" src="assets/images/home/transverse-cat/news_2071809.jpg" />
            </div>
            <div class="col-8">
              <div class="card-body">
                <a class="cat-title">BIEN-ÊTRE</a>
                <div class="text-date">01/06/2020, 16h32</div>
                <h5 class="card-title">41 millions d'internautes ont acheté en ligne</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
