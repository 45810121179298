import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, of as observableOf, throwError as observableThrow } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment } from '@purplefront/shared';
import { TokenCredentials } from '../_models/token-credentials.model';

export interface LoginResponse {
  loggedIn: boolean;
  error?: any;
  token?: TokenCredentials | null;
}

export interface CheckSessionResponse {
  loggedIn: boolean;
  data?: any;
  token?: TokenCredentials;
}

@Injectable()
export class AuthApi {
  private apiUrl: string = environment.purpleApi + '/auth';

  constructor(private http: HttpClient) {}

  public checkSession(token: TokenCredentials): Observable<CheckSessionResponse> {
    const url = `${this.apiUrl}/user`;
    const bearerAuthToken = 'Bearer ' + token.accessToken;
    const authorizationHeader = new HttpHeaders({
      Authorization: bearerAuthToken
    });
    return this.http.get(url, { headers: authorizationHeader }).pipe(
      map((data: any) => {
        return {
          loggedIn: true,
          data: data,
          token: token
        };
      }),
      catchError((httpError: any) => {
        return httpError.status === 401 // 401 : Unauthorized  (mauvais crédentials dans header)
          ? observableOf({ loggedIn: false })
          : observableThrow(httpError);
      })
    );
  }

  public login(username: string, password: string): Observable<LoginResponse> {
    const payload = { email: username, password: password };
    const url = `${this.apiUrl}/login`;
    return this.http.post(url, payload).pipe(
      map((data: any) => {
        return {
          loggedIn: true,
          token: {
            username: username,
            accessToken: data.access_token,
            expiresAt: data.expires_at,
            tokenType: data.token_type
          }
        };
      }),
      catchError((httpError: any) => {
        return httpError.status === 401 // 401 : Unauthorized (mauvais email/mdp)
          ? observableOf({ loggedIn: false, error: httpError.error })
          : observableThrow(httpError);
      })
    );
  }

  public logout(): Observable<any> {
    const url = `${this.apiUrl}/logout`;
    return this.http.get(url).pipe(
      map((data: any) => {
        return {
          loggedIn: false
        };
      }),
      catchError((httpError: any) => {
        return httpError.status === 401 // 401 : Unauthorized (mauvais email/mdp)
          ? observableOf({ loggedIn: true })
          : observableThrow(httpError);
      })
    );
  }

  public register(payload): Observable<any> {
    const url = `${this.apiUrl}/signup`;
    return this.http.post(url, payload).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public activateRegistrationCode(payload): Observable<any> {
    const url = `${this.apiUrl}/signup/activate/${payload}`;
    return this.http.get(url, payload).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        console.error(error);
        return of(error);
      })
    );
  }

  public sendEmail(payload): Observable<any> {
    const url = `${this.apiUrl}/forgot/password?email=${payload.email}`;
    return this.http.post(url, {}).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  public resetPassword(payload): Observable<any> {
    const url = `${this.apiUrl}/reset/password`;
    return this.http.post(url, payload).pipe(
      map((data: any) => {
        return data;
      })
    );
  }
}
