import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';

import { Langs } from '@purplefront/shared';
import { environment } from '../../../../../shared/src/lib/environments';
import { catchError, map } from 'rxjs/operators';
import { Voice } from '@purplefront/editor/data-access';

@Injectable()
export class PreferencesApi {
  private apiUrl: string = environment.purpleApi;
  private preferencesUrl: string = `${this.apiUrl}/preferences/keys`;
  private voicePrefUrl: string = `${this.apiUrl}/preferences/voices`;

  constructor(private http: HttpClient) {}

  // Setters

  /**
   *
   * @param lang
   */
  setContentLang(lang: Langs): Observable<Langs> {
    const body = {
      key: 'languages.content',
      value: lang
    };
    return this.http.put<Langs>(this.preferencesUrl, body).pipe(catchError((error: any) => throwError(error)));
  }

  /**
   *
   * @param features
   */
  setAdvancedFeatures(features: string[]): Observable<any> {
    const body = {
      key: 'advanced.features',
      value: features
    };
    return this.http.put(this.preferencesUrl, body).pipe(catchError((error: any) => throwError(error)));
  }

  /**
   *
   * @param filerKey
   * @param value
   */
  setAdvancedFilters(payload: any): Observable<any> {
    const body = {
      key: `advanced.filters.${payload.key}`,
      value: payload.value
    };

    return this.http.put(this.preferencesUrl, body).pipe(catchError((error: any) => throwError(error)));
  }

  /**
   *
   * @param lang
   */
  setInterfaceLang(lang: Langs): Observable<Langs> {
    const body = {
      key: 'languages.interface',
      value: lang
    };
    return this.http.put<Langs>(this.preferencesUrl, body).pipe(catchError((error: any) => throwError(error)));
  }

  /**
   *
   * @param currentSlug
   */
  setCurrentSlug(currentSlug: string): Observable<any> {
    const body = {
      key: 'content.realms.relax.current-slug',
      value: currentSlug
    };
    return this.http.put<string>(this.preferencesUrl, body).pipe(catchError((error: any) => throwError(error)));
  }

  /**
   *
   * @param voice
   */
  setDefaultVoice(voice): Observable<any> {
    const body = {
      key: 'advanced.voice',
      value: voice
    };
    return this.http.put(this.preferencesUrl, body).pipe(catchError((error: any) => throwError(error)));
  }

  setLastUsedVoice(voice: Voice): Observable<any> {
    const body = {
      voice_id: voice.id
    };
    return this.http.post(this.voicePrefUrl, body).pipe(catchError((error: any) => throwError(error)));
  }

  getLastUsedVoices(): Observable<Voice[]> {
    return this.http.get<Voice[]>(this.voicePrefUrl).pipe(
      map((res) => res['data']),
      catchError((error: any) => throwError(error))
    );
  }

  /**
   *
   * @param hasAcceptedCookies
   */
  setCookieBanner(hasAcceptedCookies: boolean): Observable<any> {
    const body = {
      key: 'advanced.cookie-banner',
      value: hasAcceptedCookies
    };
    return this.http.put<boolean>(this.preferencesUrl, body).pipe(catchError((error: any) => throwError(error)));
  }

  /**
   *
   * @param alreadySeen
   */
  setGuidedTour(guidedTour: boolean): Observable<any> {
    const body = {
      key: 'advanced.guided-tour',
      value: guidedTour
    };
    return this.http.put<boolean>(this.preferencesUrl, body).pipe(catchError((error: any) => throwError(error)));
  }

  // Getters

  /**
   * Get all user preferences
   */
  getUserPreferences(): Observable<any> {
    return this.http.get(this.preferencesUrl).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
}
