import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-version-tab',
  templateUrl: './version-tab.component.html',
  styleUrls: ['./version-tab.component.scss']
})
export class VersionTab implements OnInit {
  constructor(private _router: Router, private _route: ActivatedRoute) {}

  ngOnInit() {}

  openTermsOfService($event) {
    this._router.navigate(['.'], {
      relativeTo: this._route,
      queryParams: {
        modal: 'terms-of-service'
      }
    });
  }
}
