import { FormControl } from '@angular/forms';

export function fileSize(maxSize) {
  return function (control: FormControl) {
    const file = control.value;
    if (file) {
      const fileSize = file.size;
      if (fileSize > maxSize) {
        return {
          maxFileSizeExceeded: true
        };
      }
      return null;
    }
    return null;
  };
}
