import { Injectable } from '@angular/core';
import {
  addAudioStory,
  addAudioStoryFailure,
  addAudioStorySuccess,
  AudioStoryActions,
  updateAudioStory,
  updateAudioStoryFailure,
  updateAudioStorySuccess,
  loadAudioStories,
  loadAudioStoriesFailure,
  loadAudioStoriesSuccess,
  removeAllAudioStories,
  removeAllAudioStoriesFail,
  removeAllAudioStoriesSuccess,
  removeAudioStory,
  removeAudioStoryFail,
  removeAudioStorySuccess,
  removeMultipleAudioStories,
  removeMultipleAudioStoriesFail,
  removeMultipleAudioStoriesSuccess,
  generateAudioStoryItem,
  generateAudioStoryItemSuccess,
  generateAudioStoryItemFail,
  generateAudioFormat,
  generateAudioFormatSuccess,
  generateAudioFormatFail,
  generateMobileFormat,
  startMobileFormatPolling,
  generateMobileFormatFail,
  generateMobileFormatSuccess,
  generateSquareFormatSuccess,
  setMobileRenderStatus,
  setSquareRenderStatus,
  generateSquareFormat,
  generateSquareFormatFail,
  startSquareFormatPolling,
  playPauseAudio,
  cloneAudioStory,
  cloneAudioStorySuccess,
  cloneAudioStoryFail,
  startAudioFormatPolling,
  setAudioRenderStatus,
  generateLandscapeFormat,
  startLandscapeFormatPolling,
  generateLandscapeFormatFail,
  generateLandscapeFormatSuccess,
  setLandscapeRenderStatus,
  translateFail,
  retryTranslatedGeneration
} from '../actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AudioStoryApi } from '../../_api';
import { catchError, map, mapTo, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EMPTY, interval, of, Subject } from 'rxjs';
import { AudioPlayerService } from '../../../../../../audio-player/feature-shell/src/lib/_services';
import { EditorService } from '../../../../../../editor/data-access/src/lib/services';
import { AppToastService } from '../../../../../../toaster/data-access/src/lib/_services/toaster.service';
import { AudioStoryService } from '../../_services/audio-story.service';
import { EditorState, openTranslationModal } from '@purplefront/editor/data-access';
import { Store } from '@ngrx/store';

@Injectable()
export class AudioStoryEffects {
  private readonly pollingIntervalMs = 2000;
  private mobilePollingUntil$ = new Subject<boolean>();
  private squarePollingUntil$ = new Subject<boolean>();
  private audioPollingUntil$ = new Subject<boolean>();
  private landscapePollingUntil$ = new Subject<boolean>();

  constructor(
    private _audioPlayerService: AudioPlayerService,
    private _actions$: Actions<AudioStoryActions>,
    private _audioStoryApi: AudioStoryApi,
    private _editorService: EditorService,
    private _audioStoryService: AudioStoryService,
    public toasterService: AppToastService,
    private _editorStore: Store<EditorState>
  ) {}

  loadAudioStories$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loadAudioStories),
      switchMap((action) =>
        this._audioStoryApi.getAllAudioStories(action.page).pipe(
          map((res) => {
            return loadAudioStoriesSuccess({
              payload: { audioStories: res.data, lastPage: res.lastPage, total: res.total }
            });
          }),
          catchError((err: any) => {
            return of(loadAudioStoriesFailure({ payload: { error: err } }));
          })
        )
      )
    )
  );

  addAudioStory$ = createEffect(() =>
    this._actions$.pipe(
      ofType(addAudioStory),
      switchMap((audioStory) => {
        return this._audioStoryApi.addAudioStory(audioStory.payload).pipe(
          map((audioStory) => {
            return addAudioStorySuccess({ payload: { audioStory: audioStory } });
          }),
          catchError((err: any) => {
            return of(addAudioStoryFailure({ payload: { error: err } }));
          })
        );
      })
    )
  );

  cloneAudioStory$ = createEffect(() =>
    this._actions$.pipe(
      ofType(cloneAudioStory),
      switchMap((audioStory) => {
        return this._audioStoryApi.addAudioStory(audioStory.payload).pipe(
          map((audioStory) => {
            return cloneAudioStorySuccess({ payload: audioStory });
          }),
          catchError((err: any) => {
            return of(cloneAudioStoryFail({ payload: { error: err } }));
          })
        );
      })
    )
  );

  generateAudioFormat$ = createEffect(() =>
    this._actions$.pipe(
      ofType(generateAudioFormat),
      switchMap((action) => {
        return this._audioStoryApi.renderAudioStory(action.payload).pipe(
          map((res) => {
            return startAudioFormatPolling({ payload: res });
          }),
          catchError((err: any) => {
            if (this._audioStoryService.isTranslateError(err)) {
              return of(translateFail({ audioStory: action.payload }));
            }
            return of(generateAudioFormatFail({ error: err }));
          })
        );
      })
    )
  );

  generateMobileFormat$ = createEffect(() =>
    this._actions$.pipe(
      ofType(generateMobileFormat),
      switchMap((action) => {
        return this._audioStoryApi.renderAudioStory(action.payload).pipe(
          map((res) => {
            return startMobileFormatPolling({ payload: res });
          }),
          catchError((err: any) => {
            if (this._audioStoryService.isTranslateError(err)) {
              return of(translateFail({ audioStory: action.payload }));
            }
            return of(generateMobileFormatFail({ error: err }));
          })
        );
      })
    )
  );

  generateSquareFormat$ = createEffect(() =>
    this._actions$.pipe(
      ofType(generateSquareFormat),
      switchMap((action) => {
        return this._audioStoryApi.renderAudioStory(action.payload).pipe(
          map((res) => {
            return startSquareFormatPolling({ payload: res });
          }),
          catchError((err: any) => {
            if (this._audioStoryService.isTranslateError(err)) {
              return of(translateFail({ audioStory: action.payload }));
            }
            return of(generateSquareFormatFail({ error: err }));
          })
        );
      })
    )
  );

  generateLandscapeFormat$ = createEffect(() =>
    this._actions$.pipe(
      ofType(generateLandscapeFormat),
      switchMap((action) => {
        return this._audioStoryApi.renderAudioStory(action.payload).pipe(
          map((res) => startLandscapeFormatPolling({ payload: res })),
          catchError((err: any) => {
            if (this._audioStoryService.isTranslateError(err)) {
              return of(translateFail({ audioStory: action.payload }));
            }
            return of(generateLandscapeFormatFail({ error: err }));
          })
        );
      })
    )
  );

  startAudioPolling$ = createEffect(() =>
    this._actions$.pipe(
      ofType(startAudioFormatPolling),
      switchMap((pollAction) =>
        interval(this.pollingIntervalMs).pipe(
          takeUntil(this.audioPollingUntil$),
          mapTo(pollAction),
          switchMap((action) => {
            return this._audioStoryApi.getGeneratedAudioStoryUrl(action.payload.render_id).pipe(
              map((pollingRes) => {
                if (pollingRes.render_status === 'done') {
                  this.audioPollingUntil$.next(true);
                }
                return pollingRes;
              })
            );
          }),
          map((res) => {
            if (res.render_status === 'done') {
              this.toasterService.show('AUDIO_FORMAT_GENERATED', 'NEW_GENERATED_CONTENT');
              return generateAudioFormatSuccess({ payload: res });
            } else if (res.render_status === 'failed') {
              this.audioPollingUntil$.next(true);
              return generateAudioFormatFail({ error: 'generation error' });
            }

            return setAudioRenderStatus({ payload: res.render_status });
          }),
          catchError((err) => {
            return of(generateAudioFormatFail({ error: err }));
          })
        )
      )
    )
  );

  startMobilePolling$ = createEffect(() =>
    this._actions$.pipe(
      ofType(startMobileFormatPolling),
      switchMap((pollAction) =>
        interval(this.pollingIntervalMs).pipe(
          takeUntil(this.mobilePollingUntil$),
          mapTo(pollAction),
          switchMap((action) => {
            return this._audioStoryApi.getGeneratedAudioStoryUrl(action.payload.render_id).pipe(
              map((pollingRes) => {
                if (pollingRes.render_status === 'done') {
                  this.mobilePollingUntil$.next(true);
                }
                return pollingRes;
              })
            );
          }),
          map((res) => {
            if (res.render_status === 'done') {
              this.toasterService.show('MOBILE_FORMAT_GENERATED', 'NEW_GENERATED_CONTENT');
              return generateMobileFormatSuccess({ payload: res });
            } else if (res.render_status === 'failed') {
              this.mobilePollingUntil$.next(true);
              return generateAudioFormatFail({ error: 'generation error' });
            }

            return setMobileRenderStatus({ payload: res.render_status });
          }),
          catchError((err) => {
            return of(generateMobileFormatFail({ error: err }));
          })
        )
      )
    )
  );

  startSquarePolling$ = createEffect(() =>
    this._actions$.pipe(
      ofType(startSquareFormatPolling),
      switchMap((pollAction) =>
        interval(this.pollingIntervalMs).pipe(
          takeUntil(this.squarePollingUntil$),
          mapTo(pollAction),
          switchMap((action) => {
            return this._audioStoryApi.getGeneratedAudioStoryUrl(action.payload.render_id).pipe(
              map((pollingRes) => {
                if (pollingRes.render_status === 'done') {
                  this.squarePollingUntil$.next(true);
                }
                return pollingRes;
              })
            );
          }),
          map((res) => {
            if (res.render_status === 'done') {
              this.toasterService.show('SQUARE_FORMAT_GENERATED', 'NEW_GENERATED_CONTENT');
              return generateSquareFormatSuccess({ payload: res });
            } else if (res.render_status === 'failed') {
              this.squarePollingUntil$.next(true);
              return generateAudioFormatFail({ error: 'generation error' });
            }

            return setSquareRenderStatus({ payload: res.render_status });
          }),
          catchError((err) => {
            return of(generateSquareFormatFail({ error: err }));
          })
        )
      )
    )
  );

  startLandscapePolling$ = createEffect(() =>
    this._actions$.pipe(
      ofType(startLandscapeFormatPolling),
      switchMap((pollAction) =>
        interval(this.pollingIntervalMs).pipe(
          takeUntil(this.landscapePollingUntil$),
          mapTo(pollAction),
          switchMap((action) =>
            this._audioStoryApi.getGeneratedAudioStoryUrl(action.payload.render_id).pipe(
              map((pollingRes) => {
                if (pollingRes.render_status === 'done') {
                  this.landscapePollingUntil$.next(true);
                }
                return pollingRes;
              })
            )
          ),
          map((res) => {
            if (res.render_status === 'done') {
              this.toasterService.show('LANDSCAPE_FORMAT_GENERATED', 'NEW_GENERATED_CONTENT');
              return generateLandscapeFormatSuccess({ payload: res });
            } else if (res.render_status === 'failed') {
              this.squarePollingUntil$.next(true);
              return generateAudioFormatFail({ error: 'generation error' });
            }

            return setLandscapeRenderStatus({ payload: res.render_status });
          }),
          catchError((err) => of(generateLandscapeFormatFail({ error: err })))
        )
      )
    )
  );

  generateAudioStoryItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType(generateAudioStoryItem),
      switchMap((action) => {
        return this._audioStoryApi.generateAudio(action.payload).pipe(
          tap(() => this._audioPlayerService.status$.next(true)),
          map((audioStory) => {
            return generateAudioStoryItemSuccess({
              payload: { url: audioStory.audioUrl, articleId: action.payload.articleId }
            });
          }),
          catchError((err: any) => {
            if (this._audioStoryService.isAudioPreviewTranslateError(err)) {
              const disabledTranslationPayload = this._audioStoryService.disableTranslationItemPreview(action.payload);
              return of(generateAudioStoryItem({ payload: disabledTranslationPayload }));
            }
            return of(generateAudioStoryItemFail({ payload: { error: err } }));
          })
        );
      })
    )
  );

  generateAudioStoryItemSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(generateAudioStoryItemSuccess),
      map(() => playPauseAudio({ payload: { playing: true } }))
    )
  );

  removeAllAudioStories$ = createEffect(() =>
    this._actions$.pipe(
      ofType(removeAllAudioStories),
      switchMap(() =>
        this._audioStoryApi.removeAllAudioStories().pipe(
          map(() => {
            return removeAllAudioStoriesSuccess();
          }),
          catchError((err: any) => {
            return of(removeAllAudioStoriesFail({ payload: { error: err } }));
          })
        )
      )
    )
  );

  removeMultipleAudioStories$ = createEffect(() =>
    this._actions$.pipe(
      ofType(removeMultipleAudioStories),
      switchMap((action) =>
        this._audioStoryApi.removeMultipleAudioStories(action.payload).pipe(
          map(() => {
            return removeMultipleAudioStoriesSuccess({ payload: action.payload });
          }),
          catchError((err: any) => {
            return of(removeMultipleAudioStoriesFail({ payload: { error: err } }));
          })
        )
      )
    )
  );

  removeAudioStories$ = createEffect(() =>
    this._actions$.pipe(
      ofType(removeAudioStory),
      switchMap((action) =>
        this._audioStoryApi.removeAudioStory(action.payload).pipe(
          map(() => {
            return removeAudioStorySuccess({ payload: action.payload });
          }),
          catchError((err: any) => {
            return of(removeAudioStoryFail({ payload: { error: err } }));
          })
        )
      )
    )
  );

  editAudioStory$ = createEffect(() =>
    this._actions$.pipe(
      ofType(updateAudioStory),
      switchMap((action) =>
        this._audioStoryApi.editAudioStory(action.payload.content, action.payload.id).pipe(
          map((payload: any) => {
            return updateAudioStorySuccess({ payload });
          }),
          catchError((err: any) => {
            return of(updateAudioStoryFailure({ payload: { error: err } }));
          })
        )
      )
    )
  );

  translateFail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(translateFail),
      map(({ audioStory }) => openTranslationModal({ payload: audioStory }))
    )
  );

  retryTranslatedGeneration$ = createEffect(() =>
    this._actions$.pipe(
      ofType(retryTranslatedGeneration),
      mergeMap((action) => {
        return this._audioStoryApi.editAudioStory(action.payload.audioStory.body, action.payload.audioStory.id).pipe(
          tap((res) => this._audioStoryService.generateAudioStoryFormats(action.payload.formats, res.data)),
          map((res) => updateAudioStorySuccess({ payload: res })),
          catchError((err) => EMPTY)
        );
      })
    )
  );
}
