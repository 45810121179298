import { Injectable } from '@angular/core';
import { LexiconApiService } from '../_api/lexicon.api.service';
import { Lexicon, LexiconPayload } from '@purplefront/editor/data-access';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LexiconService {
  constructor(private _lexiconApi: LexiconApiService) {}

  getLexicons(language: string): Observable<Lexicon[]> {
    return this._lexiconApi.getLexicons(language);
  }

  addLexicon(payload: LexiconPayload): Observable<Lexicon> {
    return this._lexiconApi.addLexicon(payload);
  }

  deleteLexicon(id: number) {
    return this._lexiconApi.deleteLexicon(id);
  }

  updateLexicon(payload: LexiconPayload, id: number): Observable<Lexicon> {
    return this._lexiconApi.updateLexicon(payload, id);
  }

  getGraphemeInLexicon(grapheme: string, lexicons: Lexicon[]): Lexicon {
    return lexicons.find((lexicon) => lexicon.grapheme === grapheme);
  }

  mapToLexiconPayload(lexicon: Lexicon): LexiconPayload {
    const { grapheme, alias, caseSensitive, language } = lexicon;
    return {
      grapheme,
      alias,
      caseSensitive,
      language
    };
  }
}
