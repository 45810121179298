<div *ngIf="message" class="modal" tabindex="-1" role="dialog" style="display: block !important">
  <div class="modal-dialog custom-alert" role="document">
    <div class="modal-content">
      <div *ngIf="message?.type == 'confirm'" class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <p class="text-center confirm-message">{{ message.text | translate }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p class="confirm-button">
              <a class="me-2" (click)="message.yesFn()">
                <button class="btn btn-yes">{{ 'YES' | translate }}</button>
              </a>
              <a (click)="message.noFn()">
                <button class="btn btn-no">{{ 'CANCEL' | translate }}</button>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
