import { Component, OnInit, Inject, PLATFORM_ID, AfterViewInit, OnDestroy } from '@angular/core';
import { isPlatformBrowser, Location } from '@angular/common';

import { select, Store } from '@ngrx/store';

import * as fromAuthDataAccessLib from '@purplefront/auth/data-access';

import { SwUpdate } from '@angular/service-worker';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, Event } from '@angular/router';
import {
  steps as defaultSteps,
  defaultStepOptions,
  ThemeService,
  WINDOW,
  MetaService,
  Langs,
  environment,
  LogUpdateService,
  CheckForUpdateService
} from '@purplefront/shared';
import {
  InterfaceLanguageService,
  PreferencesState,
  selectInterfaceLang,
  selectUserPref,
  setGuidedTourComplete
} from '@purplefront/preferences/data-access';
import { AudioStoryState, selectGeneratedStoryItemUrl } from '@purplefront/audio-story/data-access';
import { EditorState, selectPlyrAudioSource } from '@purplefront/editor/data-access';
import { getPlaylistArticles, PlaylistState } from '@purplefront/playlist/data-access';
import { combineLatest, Subject, tap } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ShepherdService } from 'angular-shepherd';
import { getUserDetails, getUserName, status } from '@purplefront/auth/data-access';
import * as fromHeaderStore from '@purplefront/header/data-access';
import LogRocket from 'logrocket';

@Component({
  selector: 'app-mobisation-root',
  styleUrls: ['./app-mobisation-container.scss'],
  templateUrl: './app-mobisation-container.component.html'
})
export class AppMobisationContainer implements OnInit, OnDestroy, AfterViewInit {
  private readonly _isBrowser: boolean;
  public isLoading: boolean;
  public userName: string;
  private _currentPath: string;
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();
  hasValidSource$: Subject<boolean> = new Subject<boolean>();
  isMobile: boolean;

  constructor(
    private _authStore: Store<fromAuthDataAccessLib.AuthState>,
    private _prefStore: Store<PreferencesState>,
    private _swUpdate: SwUpdate,
    private _themeService: ThemeService,
    private _interfaceLanguageService: InterfaceLanguageService,
    private _router: Router,
    private _location: Location,
    private _audioStoryStore: Store<AudioStoryState>,
    private _editorStore: Store<EditorState>,
    private _playListStore: Store<PlaylistState>,
    private _translateService: TranslateService,
    private _shepherdService: ShepherdService,
    private _metaService: MetaService,
    private _headerStore: Store<fromHeaderStore.HeaderState>,
    private _logUpdate: LogUpdateService,
    private _checkUpdate: CheckForUpdateService,
    @Inject(WINDOW) private window: Window,
    @Inject(PLATFORM_ID) private _platformId
  ) {
    if (environment.name === 'prod') {
      LogRocket.init('tqmdqq/daily-up-dev');
    }
    this._isBrowser = isPlatformBrowser(_platformId);
  }

  ngOnInit(): void {
    this.getIsMobile();
    this.getGeneratedStoryItemUrl();
    this._themeService.setDefaultMobisationTheme();
    this._authStore.dispatch(fromAuthDataAccessLib.initializeSession());
    this._router.events.subscribe((routerEvent: Event) => {
      setTimeout(() => this.checkRouterEvent(routerEvent), 0);
    });

    if (this._swUpdate.isEnabled) {
      this._swUpdate.available.subscribe((event) => {
        console.log('current version in container is', event.current);
        console.log('available version in container is', event.available);
        if (confirm('New version available. Load New Version ?')) {
          if (this._isBrowser) {
            this._swUpdate.activateUpdate().then(() => this.window.location.reload());
          }
        }
      });
    }

    this.initIndexMetaTags();
    if (environment.name === 'prod') {
      this.getUserDetails();
    }
  }

  getUserDetails(): void {
    this._authStore
      .pipe(
        select(getUserDetails),
        takeUntil(this._ngUnsubscribe$),
        filter((userDetails) => userDetails !== null),
        distinctUntilChanged((previous, current) => previous.id === current.id)
      )
      .subscribe((userDetails) => this.identifyLogRocketUserSession(userDetails));
  }

  identifyLogRocketUserSession(userDetails): void {
    LogRocket.identify(userDetails.id.toString(), {
      name: `${userDetails.firstName} ${userDetails.lastName}`,
      email: userDetails.email
    });
  }

  getIsMobile(): void {
    this._headerStore
      .pipe(takeUntil(this._ngUnsubscribe$), select(fromHeaderStore.isMobile))
      .subscribe((isMobile: boolean) => (this.isMobile = isMobile));
  }

  initIndexMetaTags(): void {
    this._prefStore
      .pipe(
        select(selectInterfaceLang),
        takeUntil(this._ngUnsubscribe$),
        tap((interfaceLang: Langs) => {
          const TITLE_FR = 'ETX RevoluSOUND - Découvrez la plateforme qui audio-augmente 100% de votre contenu';
          const DESCRIPTION_FR =
            'ETX RevoluSOUND permet l’automatisation vers l’audio d’un texte, d’une newsletter ou même de votre site web, le tout en plusieurs langues ! Essayer la plateforme gratuitement';
          const TITLE_EN = 'ETX RevoluSOUND : Audio-augmentation - Traduction - Scrapping';
          const DESCRIPTION_EN =
            'A unique platform to read, hear and watch the latest news. To create content and convert text into audio / video in many languages & voices. For brands and media seeking digital acceleration to massively engage in social audio.';

          const tags = {
            INDEX_TITLE: interfaceLang === 'fr' ? TITLE_FR : TITLE_EN,
            INDEX_DESCRIPTION: interfaceLang === 'fr' ? DESCRIPTION_FR : DESCRIPTION_EN
          };

          this._metaService.removeMetaTags();
          this._metaService.addIndexPageTags(tags);
        })
      )
      .subscribe();
  }

  ngAfterViewInit() {
    const pref$ = this._prefStore.pipe(
      select(selectUserPref),
      filter((val) => val !== null)
    );
    const status$ = this._authStore.pipe(select(status));
    combineLatest([pref$, status$])
      .pipe(
        debounceTime(0),
        take(1),
        filter(([_pref, status]) => status === 'LOGGED')
      )
      .subscribe(([pref, status]) => {
        this._translateService.use(pref.languages.interface);
        const alreadyCompleteGuidedTour = pref.advanced['guided-tour'];
        if (!alreadyCompleteGuidedTour && !this.isMobile) {
          setTimeout(() => {
            this.redirectToUpgradeAccount();
            this.onFirstVisit();
          }, 500);
        }
      });
  }

  onFirstVisit() {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.url.includes('first-visit')) {
        this.initGuidedTour();
      }
    });
  }
  redirectToUpgradeAccount() {
    this._router.navigate(['upgrade-account']);
  }
  initGuidedTour(): void {
    this._shepherdService.defaultStepOptions = defaultStepOptions;
    this._shepherdService.modal = true;
    this._shepherdService.confirmCancel = false;
    const step1_translate_text$ = this._translateService.get('GUIDED_TOUR_STEP_1');
    const step2_translate_text$ = this._translateService.get('GUIDED_TOUR_STEP_2');
    const step_translate_title$ = this._translateService.get('WELCOME');
    const step_translate_next$ = this._translateService.get('NAVIGATION_NEXT');
    const step_translate_done$ = this._translateService.get('NAVIGATION_DONE');
    const userName$ = this._authStore.pipe(select(getUserName));
    combineLatest([
      step1_translate_text$,
      step2_translate_text$,
      step_translate_title$,
      step_translate_next$,
      step_translate_done$,
      userName$
    ])
      .pipe(take(1))
      .subscribe(([text1, text2, title, next, done, name]) => {
        const _title = `${title} ${name},`;

        defaultSteps[0].text = text1;
        defaultSteps[0].buttons[0].text = next;
        defaultSteps[1].text = text2;
        defaultSteps[1].buttons[0].text = done;
        defaultSteps[1].buttons[0]['action'] = () => {
          this.setGuidedTourComplete();
        };
        const self = this;
        defaultSteps[0]['when'] = defaultSteps[1]['when'] = {
          cancel() {
            console.log('cancel...');
            self.setGuidedTourComplete();
          }
        };
        defaultSteps[0].title = defaultSteps[1].title = _title;
        this.startGuidedTour(defaultSteps);
      });
  }

  public startGuidedTour(steps): void {
    this._shepherdService.addSteps(steps);
    this._shepherdService.start();
  }

  public setGuidedTourComplete(): void {
    this._prefStore.dispatch(setGuidedTourComplete({ payload: true }));
    this._shepherdService.complete();
  }

  getGeneratedStoryItemUrl() {
    const sources1$ = this._audioStoryStore.pipe(select(selectGeneratedStoryItemUrl));
    const sources2$ = this._editorStore.pipe(select(selectPlyrAudioSource));
    const sources3$ = this._playListStore.pipe(select(getPlaylistArticles));

    combineLatest([sources1$, sources2$, sources3$])
      .pipe(debounceTime(0))
      .subscribe(([source1, source2, source3]) => {
        this.hasValidSource$.next(!!(source1 || source2 || (source3 && source3.length)));
      });
  }

  showMenu() {
    this._currentPath = this._location.path();
    return this._currentPath.indexOf('auth') === -1;
  }

  /**
   * Check router event and
   * handle global loading status
   * @param routerEvent
   */
  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.isLoading = true;
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.isLoading = false;
    }
  }

  /**
   * Prevent memory leaks unsubscribe when destroy component
   */
  ngOnDestroy() {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
