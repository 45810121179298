import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as SearchActions from './search.actions';
import { catchError, debounceTime, filter, map, skip, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import {
  AppRouterService,
  AppRouterFacadeService,
  AppRouterState,
  selectSavedSearchId,
  selectTag
} from '@purplefront/app-router/data-access';
import { select, Store } from '@ngrx/store';
import { selectContentLang } from '@purplefront/preferences/data-access';
import { CatalogState, Feed, userFeeds } from '@purplefront/catalog/data-access';
import * as fromPreferencesStore from '@purplefront/preferences/data-access';
import { Langs } from '@purplefront/shared';
import { Router } from '@angular/router';
import { SearchApiService } from '../_api';
import { AuthState, AuthStatus, status } from '@purplefront/auth/data-access';
import { loadArticlesFail } from '@purplefront/afp-relax/data-access';

@Injectable()
export class SearchEffects {
  public searchString$: Observable<string>;
  public searchId$: Observable<string>;
  public page$: Observable<string>;
  public langFromUrl$: Observable<Langs>;
  public userFeeds$: Observable<Feed[]>;
  public tag$: Observable<string>;
  public currentUrl: string;

  constructor(
    private actions$: Actions,
    private _routerStore: Store<AppRouterState>,
    private _routerFacade: AppRouterFacadeService,
    private _routerEffectsService: AppRouterService,
    private api: SearchApiService,
    private _preferencesStore: Store<fromPreferencesStore.PreferencesState>,
    private _catalogStore: Store<CatalogState>,
    private _authStore: Store<AuthState>,
    private _router: Router
  ) {
    this.currentUrl = this._router.url;
  }

  searchQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.searchQuery),
      debounceTime(300),
      map((action) => action.payload),
      switchMap((query) => {
        const nextSuggest$ = this.actions$.pipe(ofType(SearchActions.searchQuery), skip(1));
        const activeFeeds$ = this._catalogStore.pipe(select(userFeeds));
        const authStatus$ = this._authStore.pipe(select(status));

        return combineLatest([activeFeeds$, authStatus$]).pipe(
          filter(([activeFeeds, status]) => status === AuthStatus.LOGGED),
          switchMap(([activeFeeds, status]) => {
            const activeFeedsSlugs = activeFeeds.map((f) => f.slug);
            const feedSlugs: string[] = [...activeFeedsSlugs];
            return this.api.getAutocompleteSuggestions(query.searchString, 5, query.contentLang, feedSlugs).pipe(
              takeUntil(nextSuggest$),
              map((response) => {
                return SearchActions.searchQuerySuccess({
                  payload: {
                    searchResponse: response,
                    searchString: query.searchString
                  }
                });
              }),
              catchError((error) =>
                of(
                  SearchActions.searchQueryFail({
                    payload: { error }
                  })
                )
              )
            );
          })
        );
      })
    )
  );

  searchQuerySuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SearchActions.searchQuerySuccess),
        map((action) => action.payload.searchString),
        tap((searchString) => {
          this.langFromUrl$ = this._preferencesStore.pipe(select(selectContentLang));
          this.userFeeds$ = this._catalogStore.pipe(select(userFeeds));
          this.searchId$ = this._routerStore.pipe(select(selectSavedSearchId));
          this.searchId$ = this._routerStore.pipe(select(selectSavedSearchId));
          this.tag$ = this._routerStore.pipe(select(selectTag));

          combineLatest([this.langFromUrl$, this.searchId$, this.userFeeds$, this.tag$])
            .pipe(take(1))
            .subscribe(([_lang, _searchId, _userFeeds, _tag]) => {
              if (_userFeeds.length) {
                const feedsSlugs = _userFeeds.map((f) => f.slug);
                const payload = {
                  toParams: {
                    feed: feedsSlugs,
                    lang: _lang,
                    search: searchString,
                    searchId: _searchId,
                    tag: _tag
                  },
                  fromUrl: this.currentUrl,
                  keepQueryParams: true
                };

                //this._routerEffectsService.goToFeedPage(payload.toParams, payload.fromUrl, payload.keepQueryParams);
              }
            });
        })
      ),
    { dispatch: false }
  );
}
