import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { Store } from '@ngrx/store';
import { Effect, Actions, ofType, createEffect } from '@ngrx/effects';

import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { SearchModalState } from '../reducers';
import { ActivatedRoute, Router } from '@angular/router';
import {
  saveSearchModal,
  SearchActionType,
  saveSearchModalSuccess,
  saveSearchModalFailure,
  closeSearchModal,
  deleteSearchModal,
  deleteSearchModalFailure,
  deleteSearchModalSuccess
} from '../actions';

import { addBookmark, BookmarksApi, BookmarksState, removeBookmark } from '@purplefront/bookmarks/data-access';

@Injectable()
export class SearchModalEffects {
  private _renderer: Renderer2;
  constructor(
    private _actions$: Actions,
    private _bookmarksApi: BookmarksApi,
    private _searchModalStore: Store<SearchModalState>,
    private _bookmarkStore: Store<BookmarksState>,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _rendererFactory: RendererFactory2
  ) {
    this._renderer = _rendererFactory.createRenderer(null, null);
  }

  addSearch$ = createEffect(() =>
    this._actions$.pipe(
      ofType(saveSearchModal),
      mergeMap((action) =>
        this._bookmarksApi.saveBookmark(action.payload).pipe(
          tap((bookmark: any) => {
            this._router.navigate([], {
              relativeTo: this._activatedRoute,
              queryParams: { search: bookmark.filter, searchId: bookmark.id }
            });
          }),
          map((bookmark) => {
            this._searchModalStore.dispatch(closeSearchModal({ payload: { open: false } }));
            this._bookmarkStore.dispatch(addBookmark(bookmark));
            return saveSearchModalSuccess();
          }),
          catchError((err: any) => {
            return of(saveSearchModalFailure({ payload: { error: err } }));
          })
        )
      )
    )
  );

  removeSearch$ = createEffect(() =>
    this._actions$.pipe(
      ofType(deleteSearchModal),
      mergeMap((action) =>
        this._bookmarksApi.removeBookmark(action.payload).pipe(
          tap(() => {
            this._router.navigate([], {
              relativeTo: this._activatedRoute,
              queryParams: { search: null, searchId: null }
            });
          }),
          map((bookmarks) => {
            this._searchModalStore.dispatch(closeSearchModal({ payload: { open: false } }));
            this._bookmarkStore.dispatch(removeBookmark({ payload: action.payload }));
            return deleteSearchModalSuccess();
          }),
          catchError((err: any) => {
            return of(deleteSearchModalFailure({ payload: { error: err } }));
          })
        )
      )
    )
  );

  closeModalSearch$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(closeSearchModal),
        map(() => {
          return of(this._renderer.removeClass(document.body, 'open'));
        })
      ),
    { dispatch: false }
  );
}
