import { Action, createAction, props, union } from '@ngrx/store';

export enum SearchActionType {
  OPEN_SEARCH_MODAL = '[Search Modal] Open search modal',
  CLOSE_SEARCH_MODAL = '[Search Modal] Close search modal',
  SAVE_SEARCH_MODAL = '[Search Modal] Save search modal',
  SAVE_SEARCH_MODAL_SUCCESS = '[Search Modal] Save search modal success',
  SAVE_SEARCH_MODAL_FAILURE = '[Search Modal] Save search modal failure',
  DELETE_SEARCH_MODAL = '[Search Modal] Delete search modal',
  DELETE_SEARCH_MODAL_SUCCESS = '[Search Modal] Delete search modal success',
  DELETE_SEARCH_MODAL_FAILURE = '[Search Modal] Delete search modal failure'
}

export const openSearchModal = createAction(
  SearchActionType.OPEN_SEARCH_MODAL,
  props<{ payload: { open: boolean } }>()
);

export const closeSearchModal = createAction(
  SearchActionType.CLOSE_SEARCH_MODAL,
  props<{ payload: { open: boolean } }>()
);

export const saveSearchModal = createAction(SearchActionType.SAVE_SEARCH_MODAL, props<{ payload: {} }>());

export const saveSearchModalSuccess = createAction(SearchActionType.SAVE_SEARCH_MODAL_SUCCESS);

export const saveSearchModalFailure = createAction(
  SearchActionType.SAVE_SEARCH_MODAL_FAILURE,
  props<{ payload: { error: any } }>()
);

export const deleteSearchModal = createAction(SearchActionType.DELETE_SEARCH_MODAL, props<{ payload }>());

export const deleteSearchModalSuccess = createAction(SearchActionType.DELETE_SEARCH_MODAL_SUCCESS);

export const deleteSearchModalFailure = createAction(
  SearchActionType.DELETE_SEARCH_MODAL_FAILURE,
  props<{ payload: { error: any } }>()
);

const all = union({
  openSearchModal,
  closeSearchModal,
  saveSearchModal,
  saveSearchModalSuccess,
  saveSearchModalFailure,
  deleteSearchModal,
  deleteSearchModalSuccess,
  deleteSearchModalFailure
});

export type SearchModalActionsUnion = typeof all;
