import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import interactionPlugin from '@fullcalendar/interaction';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { FullCalendarModule } from '@fullcalendar/angular';
import { BookmarksUiModule } from '@purplefront/bookmarks/ui';
import { SharedModule } from '@purplefront/shared';

import { CalendarRelaxnewsComponent } from './components/calendar-relaxnews/calendar-relaxnews.component';

FullCalendarModule.registerPlugins([dayGridPlugin, timeGridPlugin, momentPlugin, interactionPlugin]);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FullCalendarModule,
    SharedModule,
    RouterModule,
    BookmarksUiModule
  ],
  declarations: [CalendarRelaxnewsComponent],
  exports: [CalendarRelaxnewsComponent],
  providers: [DatePipe]
})
export class CalendarUiModule {}
