import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromHeaderStore from '@purplefront/header/data-access';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as fromRouterStore from '@purplefront/app-router/data-access';
import { filter, take, takeUntil } from 'rxjs/operators';
import { EtxIconsRegistryService } from '../../../../../shared/src/lib/components/etx-icons/etx-icons-registry.service';
import { etxIconLanguage } from '../../../../../shared/src/lib/components/etx-icons/etx-icons.model';
import { WINDOW } from '@purplefront/shared';

@Component({
  selector: 'app-header-rio-container',
  template: `<!--<app-header-rio [lang]="lang"> </app-header-rio>-->`,
  styleUrls: ['./header-rio-container.component.scss']
})
export class HeaderRioContainerComponent implements OnInit {
  public searchTerms: string = null;
  public searchTerms$ = new BehaviorSubject<string>(null);
  private _ngUnsubscribe$: Subject<void> = new Subject<void>();
  public lang: string;
  public isSuggestLoading$: Observable<boolean>;
  public isSuggestLoaded$: Observable<boolean>;

  constructor(
    private _headerStore: Store<fromHeaderStore.HeaderState>,
    private _routerStore: Store<fromRouterStore.AppRouterState>,
    private _etxIconsRegistry: EtxIconsRegistryService,
    @Inject(WINDOW) window: Window
  ) {
    this._etxIconsRegistry.registerIcons([etxIconLanguage]);
  }

  ngOnInit(): void {
    this.getSearchTerms();
    this.getContentLang();
    this.dispatchSearchTerms();
    this.getLoadingState();
  }

  getSearchTerms() {
    this._headerStore
      .pipe(
        select(fromRouterStore.selectSearchString),
        filter((search) => !!search)
      )
      .subscribe((search) => {
        this.searchTerms = search;
      });
  }

  get SuggestResponse() {
    return;
    //
    //return this._headerStore.select(fromHeaderStore.getSuggestResponse);
  }

  getContentLang() {
    this._routerStore
      .select(fromRouterStore.selectContentLang)
      .pipe(takeUntil(this._ngUnsubscribe$))
      .subscribe((lang) => (this.lang = lang));
  }

  getLoadingState() {
    // this.isSuggestLoading$ = this._headerStore.select(fromHeaderStore.getIsSuggestLoading);
    // this.isSuggestLoaded$ = this._headerStore.select(fromHeaderStore.getIsSuggestLoaded);
  }

  dispatchSearchTerms() {
    this.searchTerms$.pipe(take(1)).subscribe((terms) => {
      if (terms) {
        // this._headerStore.dispatch(fromHeaderStore.searchTermsSuccess({ payload: { terms: terms } }));
      }
    });
  }
}
