import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { closeTranslationModal, EditorState, TranslatableLang } from '@purplefront/editor/data-access';
import { MatListOption } from '@angular/material/list';
import { AudioStoryService } from '../../../../../../audio-story/data-access/src/lib/_services/audio-story.service';
import { AudioStoryState, retryTranslatedGeneration } from '@purplefront/audio-story/data-access';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-translation-modal',
  templateUrl: './translation-modal.component.html',
  styleUrls: ['./translation-modal.component.scss']
})
export class TranslationModalComponent implements OnInit {
  @Input() translateFailAudioStory: any;
  @Input() translateFailFormats: string[];
  @Input() translatableLangs: TranslatableLang[];

  constructor(
    public dialog: MatDialog,
    private _editorStore: Store<EditorState>,
    private _audioStoryService: AudioStoryService,
    private _audioStoryStore: Store<AudioStoryState>
  ) {}

  ngOnInit() {
    this.openDialog();
  }

  openDialog() {
    const dialogRef = this.dialog.open(TranslationModalDialog, {
      disableClose: true,
      data: { langs: this.translatableLangs }
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: string) => {
        this.onSubmit(result);
      });
  }

  onSubmit(lang: string) {
    this._editorStore.dispatch(closeTranslationModal({ payload: this.translateFailAudioStory }));
    const mappedAudioStory = this._audioStoryService.patchExplicitLangInItems(this.translateFailAudioStory, lang);
    for (const format of this.translateFailFormats) {
      this._audioStoryStore.dispatch(
        retryTranslatedGeneration({ payload: { audioStory: mappedAudioStory, formats: this.translateFailFormats } })
      );
    }
  }
}

@Component({
  selector: 'dialog-content-example-dialog',
  template: `
    <div class="modal-missing-lang">
      <div style="padding: 20px 20px 5px;">
        <h2>{{ 'SELECT_SOURCE_LANGUAGE' | translate }}</h2>
        <h2 style="font-weight: normal">{{ 'UNDETECTED_LANG' | translate }}</h2>
      </div>

      <div mat-dialog-content style="padding: 0 100px;">
        <mat-selection-list
          #langList
          (selectionChange)="setSelectedLang(langList.selectedOptions.selected)"
          [multiple]="false"
        >
          <ng-container *ngFor="let lang of data.langs">
            <mat-list-option
              *ngIf="lang.type === 'source'"
              [value]="lang?.identifier.substring(0.2).toUpperCase()"
              style="color: var(--base-bgcolor-relaxnews-darkened);"
            >
              <ng-container>{{ lang?.identifier.substring(0.2).toUpperCase() | translate }}</ng-container>
            </mat-list-option>
          </ng-container>
        </mat-selection-list>
      </div>
      <div mat-dialog-actions style="justify-content: center;padding: 15px 0 35px;">
        <button
          style="
        font-size: 0.95rem;
        color: #fff;
        background: linear-gradient(42deg, #342265 45%, #5d2ea6);
        border-color: var(--base-bgcolor-relaxnews-darkened);
        width: 200px;"
          class="btn btn-primary"
          [mat-dialog-close]="selectedLang"
          [disabled]="!langList.selectedOptions.selected?.length"
        >
          {{ 'SAVE' | translate }}
        </button>
      </div>
    </div>
  `
})
export class TranslationModalDialog {
  selectedLang: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data) {}

  setSelectedLang(selected: MatListOption[]) {
    const langValueMap = selected.map((o) => o.value);
    this.selectedLang = langValueMap[0];
  }
}
