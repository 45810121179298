import { createAction, props, union } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';
import { ArticleParams } from '@purplefront/shared';

export enum ActionType {
  REDIRECT_TO_LOGIN = '[AppRouter] Redirect to login',
  REDIRECT_TO_HOME = '[AppRouter] Redirect to home',
  POST_LOGIN_REDIRECT = '[AppRouter] Post-login redirect',
  GO = '[AppRouter] Go',
  GO_TO_FEED = '[AppRouter] Go to feed',
  GO_TO_FEED_LIST = '[AppRouter] Go to feed list',
  GO_TO_FEED_PAGE = '[AppRouter] Go to feed page',
  GO_TO_DASHBOARD_PAGE = '[AppRouter] Go to Dashboard page',
  GO_TO_SEARCH_PAGE = '[AppRouter] Go to search page',
  GO_TO_FOLDER_PAGE = '[AppRouter] Go to folder page',
  GO_TO_PREFERENCES = '[AppRouter] Go to preferences page',
  GO_TO_BOOKMARKS = '[AppRouter] Go to bookmarks page',
  GO_TO_ADD_FEED = '[AppRouter] Go to add-feed page',
  GO_TO_PUBLISH = '[AppRouter] Go to publish page',
  GO_TO_ORDER_ON_DEMAND = '[AppRouter] Go to order-on-demand page',
  GO_TO_WORKSPACE = '[AppRouter] Go to workspace page',
  GO_TO_FLASH_BRIEFING = '[AppRouter] Go to flash briefing',
  GO_TO_MY_AUDIO_CONTENTS = '[AppRouter] Go to my audio contents',
  GO_TO_EDITOR = '[AppRouter] Go to Editor',
  GO_TO_CALENDAR = '[AppRouter] Go to Calendar',
  GO_TO_DISCOVER = '[AppRouter] Go to Discover',
  GO_TO_ARTICLE = '[AppRouter] go to article page'
}

export const redirectToLogin = createAction(ActionType.REDIRECT_TO_LOGIN, props<{ payload: { fromUrl: string } }>());
export const redirectToHome = createAction(ActionType.REDIRECT_TO_HOME, props<{ payload: { fromUrl: string } }>());

export const goToArticlePage = createAction(ActionType.GO_TO_ARTICLE, props<{ payload: ArticleParams }>());

export const go = createAction(
  ActionType.GO,
  props<{
    payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    };
  }>()
);

export const goToFeedList = createAction(
  ActionType.GO_TO_FEED_LIST,
  props<{
    payload: {
      fromUrl: string;
      keepQueryParams: boolean;
    };
  }>()
);

export const goToFeed = createAction(
  ActionType.GO_TO_FEED,
  props<{
    payload: {
      toParams: {
        feed: string;
      };
      fromUrl: string;
      keepQueryParams: boolean;
    };
  }>()
);

export const goToFeedPage = createAction(
  ActionType.GO_TO_FEED_PAGE,
  props<{
    payload: { feedSlug: string[]; tag?: string };
  }>()
);

export const goToCalendar = createAction(
  ActionType.GO_TO_CALENDAR,
  props<{
    payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    };
  }>()
);

export const goToDashboardPage = createAction(
  ActionType.GO_TO_DASHBOARD_PAGE,
  props<{
    payload: { page: string };
  }>()
);

export const goToFolderPage = createAction(
  ActionType.GO_TO_FOLDER_PAGE,
  props<{
    payload: { folderId: string };
  }>()
);

export const goToSearchPage = createAction(
  ActionType.GO_TO_SEARCH_PAGE,
  props<{
    payload: { terms: string };
  }>()
);

export const goToBookmarksPage = createAction(
  ActionType.GO_TO_BOOKMARKS,
  props<{
    payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    };
  }>()
);

export const goToAddFeedPage = createAction(
  ActionType.GO_TO_ADD_FEED,
  props<{
    payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    };
  }>()
);

export const goToPreferencesPage = createAction(
  ActionType.GO_TO_PREFERENCES,
  props<{
    payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    };
  }>()
);

export const goToPublishPage = createAction(
  ActionType.GO_TO_PUBLISH,
  props<{
    payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    };
  }>()
);

export const goToOrderOnDemand = createAction(
  ActionType.GO_TO_ORDER_ON_DEMAND,
  props<{
    payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    };
  }>()
);

export const goToWorkspace = createAction(
  ActionType.GO_TO_WORKSPACE,
  props<{
    payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    };
  }>()
);

export const goToFlashBriefing = createAction(
  ActionType.GO_TO_FLASH_BRIEFING,
  props<{
    payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    };
  }>()
);

export const goToEditor = createAction(
  ActionType.GO_TO_EDITOR,
  props<{
    payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    };
  }>()
);

export const goToDiscover = createAction(
  ActionType.GO_TO_DISCOVER,
  props<{
    payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    };
  }>()
);

export const goToMyAudioContents = createAction(
  ActionType.GO_TO_MY_AUDIO_CONTENTS,
  props<{
    payload: {
      path: any[];
      query?: object;
      extras?: NavigationExtras;
    };
  }>()
);

const all = union({
  redirectToLogin,
  redirectToHome,
  goToFeedPage,
  go,
  goToFeedList,
  goToFeed,
  goToBookmarksPage,
  goToAddFeedPage,
  goToPreferencesPage,
  goToPublishPage,
  goToOrderOnDemand,
  goToSearchPage,
  goToFolderPage,
  goToDashboardPage,
  goToWorkspace,
  goToFlashBriefing,
  goToMyAudioContents,
  goToEditor,
  goToCalendar,
  goToDiscover,
  goToArticlePage
});

export type RouterActionsUnion = typeof all;
