<div class="tab">
  <div class="tab-content">
    <form [formGroup]="advancedFeaturesForm">
      <label class="catalog-title">
        <h4>{{ 'ADVANCED_FEATURES' | translate }}</h4>
      </label>
      <ul class="list-unstyled">
        <li>
          <div class="checkbox">
            <label>
              <input type="checkbox" formControlName="machineLearning" value="machine-learning" /> Machine learning
            </label>
          </div>
        </li>
      </ul>
    </form>
  </div>
</div>
