import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { InterfaceLanguageService } from '@purplefront/preferences/data-access';
import { Langs } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(private _meta: Meta, private _title: Title) {}

  addArticleMetaTags(article: any): void {
    this._meta.addTags(
      [
        { name: 'description', content: article.header },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'robots', content: 'INDEX, FOLLOW' },
        { name: 'author', content: article.publisher },
        {
          name: 'keywords',
          content:
            article.semantics?.categories && article.semantics?.categories.length
              ? article.semantics?.categories.join()
              : article.mainCategory
        },
        { name: 'date', content: article.publication_date, scheme: 'YYYY-MM-DD' },
        { name: 'og:title', content: article.title },
        { name: 'og:image', content: article.images[0].s_large },
        { name: 'og:type', content: article.object_type },
        { name: 'og:local', content: article.lang },

        { name: 'twitter:card', content: article.header },
        { name: 'twitter:site', content: 'dailyup.etxstudio.com' },
        { name: 'twitter:title', content: article.title },
        { name: 'twitter:description', content: article.header },
        { name: 'twitter:image', content: article.images[0].s_large },
        { name: 'twitter:creator', content: article.publisher },
        { charset: 'UTF-8' }
      ],
      true
    );

    this.updateTitle(article.title);
  }

  addIndexPageTags(tags): void {
    this._meta.addTags([
      { name: 'description', content: tags.INDEX_DESCRIPTION },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'robots', content: 'INDEX, FOLLOW' },
      { name: 'author', content: 'Daily Up' }
    ]);
    this.updateTitle(tags.INDEX_TITLE);
  }

  removeMetaTags(): void {
    //Using removeTag
    this._meta.removeTag('name = "description"');
    this._meta.removeTag('name= "keywords"');
    this._meta.removeTag('name = "viewport"');
    this._meta.removeTag('name = "robots"');
    this._meta.removeTag('name = "keywords"');
    this._meta.removeTag('name = "date"');
    this._meta.removeTag('name = "og:title"');
    this._meta.removeTag('name = "og:image"');
    this._meta.removeTag('name = "og:type"');

    this._meta.removeTag('name = "twitter:card"');
    this._meta.removeTag('name = "twitter:site"');
    this._meta.removeTag('name = "twitter:title"');
    this._meta.removeTag('name = "twitter:description"');
    this._meta.removeTag('name = "twitter:image"');
    this._meta.removeTag('name = "twitter:creator"');
  }

  updateTitle(title): void {
    this._title.setTitle(title);
  }
}
