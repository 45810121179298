import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MenuRioContainerComponent } from './containers/menu-rio-container/menu-rio-container.component';
import { MenuRioUiModule } from '../../../ui/src';
import { SharedModule } from '../../../../shared/src';

@NgModule({
  imports: [
    CommonModule,
    MenuRioUiModule,
    SharedModule,
    RouterModule.forChild([
      /* {path: '', pathMatch: 'full', component: InsertYourComponentHere} */
    ])
  ],
  declarations: [MenuRioContainerComponent],
  exports: [MenuRioContainerComponent]
})
export class MenuRioFeatureShellModule {}
