import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromFlashBriefing from './store/flash-briefing.reducer';
import { FlashBriefingFacade } from './store/flash-briefing.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromFlashBriefing.FLASHBRIEFING_FEATURE_KEY, fromFlashBriefing.reducer),
    EffectsModule.forFeature([])
  ],
  providers: [FlashBriefingFacade]
})
export class FlashBriefingDataAccessModule {}
