import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal, NgbModal, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ThemeService } from '@purplefront/shared';

@Component({
  selector: 'app-custom-theme-modal',
  templateUrl: './custom-theme-modal.component.html',
  styleUrls: ['./custom-theme-modal.component.scss'],
  providers: [NgbTooltipConfig]
})
export class CustomThemeModalComponent implements OnInit {
  @Input() activeTheme;
  public themeForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private _themeService: ThemeService,
    private _modalService: NgbModal,
    private _fb: FormBuilder,
    private _config: NgbTooltipConfig
  ) {
    this._config.placement = 'right';
    this._config.tooltipClass = 'wide-tooltip';
  }

  ngOnInit() {
    this.initForm();
    this.setFormValue();
  }

  initForm(): void {
    this.themeForm = this._fb.group({
      name: '',
      logoPath: '',
      properties: this._fb.group({
        '--base-bgcolor-relaxnews': '',
        '--base-bgcolor-relaxnews-darkened': '',
        '--base-bgcolor-relaxnews-lightened': '',
        '--base-bgcolor-relaxnews-active': '',
        '--accent-color-relaxnews': '',
        '--base-color-relaxnews': '',
        '--base-color-orange': '',
        '--base-color-orange-darkened': '',
        '--base-color-black': '',
        '--base-color-grey': '',
        '--base-color-grey-light': '',
        '--base-color-white': '',
        '--base-menu-bgcolor': '',
        '--base-submenu-bgcolor': '',
        '--base-hover-menu-color': '',
        '--base-hover-submenu-color': '',
        '--base-active-menu': '',
        '--facet-color': '',
        '--facet-background-color': '',
        '--facet-border-color': '',
        '--menu-title-color': '',
        '--header-element': ''
      })
    });
  }

  public setFormValue(): void {
    this.themeForm.patchValue({
      name: this.activeTheme.name,
      logoPath: this.activeTheme.logoPath
    });
    Object.keys(this.activeTheme.properties).map((property) => {
      this.themeForm.patchValue({
        properties: { [property]: this.activeTheme.properties[property] }
      });
    });
  }

  public onColorValueChange(key: string, value: string): void {
    this.themeForm.patchValue({
      properties: { [key]: value }
    });
    this._themeService.setActiveTheme(this.themeForm.value);
  }

  onThemeFormSubmit(form: FormGroup, modal: NgbActiveModal): void {
    modal.close();
    this._themeService.setActiveTheme(form.value);
  }
}
