<!-- SIGN-UP PAGE -->

<form
  class="user"
  id="registration-from"
  [formGroup]="registerForm"
  (ngSubmit)="addRegistration(registerForm.value)"
  method="POST"
>
  <div *ngIf="errorMessage$ | async" class="error-message">{{ errorMessage$ | async }}</div>
  <div class="form-group">
    <div class="row">
      <div class="col-xs-6 col-md-6" tabindex="0">
        <input
          id="first_name"
          type="text"
          formControlName="first_name"
          class="form-control input-lg mb-4 mb-md-0"
          placeholder="{{'AUTH_YOUR_FIRST_NAME' | translate}}"
        />
        <div
          class="error"
          *ngIf="!registerForm.controls['first_name'].valid && registerForm.controls['first_name'].touched"
        >
          <div *ngIf="registerForm.controls['first_name'].errors.required">
            * {{'AUTH_FIRSTNAME_IS_REQUIRED' | translate}}
          </div>
          <div *ngIf="registerForm.controls['first_name'].errors.minlength">
            * {{'AUTH_FIRSTNAME_MUST_BE_AT_LEAST_2_CHARACTERS' | translate}}
          </div>
        </div>
      </div>
      <div class="col-xs-6 col-md-6" tabindex="1">
        <input
          id="last_name"
          type="text"
          class="form-control input-lg"
          placeholder="{{'AUTH_YOUR_LAST_NAME' | translate}}"
          formControlName="last_name"
        />
        <div
          class="error"
          *ngIf="!registerForm.controls['last_name'].valid && registerForm.controls['last_name'].touched"
        >
          <div *ngIf="registerForm.controls['last_name'].errors.required">
            * {{'AUTH_LASTNAME_IS_REQUIRED' | translate}}
          </div>
          <div *ngIf="registerForm.controls['last_name'].errors.minlength">
            * {{'AUTH_LASTNAME_MUST_BE_AT_LEAST_2_CHARACTERS' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-6 col-md-6 mb-4" tabindex="2">
      <input
        id="phone"
        class="form-control form-control-user"
        type="tel"
        placeholder="{{'AUTH_PHONE' | translate}}"
        formControlName="phone"
      />
      <div class="error" *ngIf="!registerForm.controls['phone'].valid && registerForm.controls['phone'].touched">
        <div *ngIf="registerForm.controls['phone'].errors.required">
          * {{'AUTH_PHONE_NUMBER_IS_REQUIRED' | translate}}
        </div>
        <div *ngIf="registerForm.controls['phone'].errors.pattern">* {{'AUTH_INCORRECT_FORMAT' | translate}}</div>
      </div>
    </div>
    <div class="col-xs-6 col-md-6 mb-4" tabindex="3">
      <input
        id="email"
        class="form-control form-control-user"
        type="email"
        placeholder="{{'AUTH_EMAIL_ADDRESS' | translate}}"
        formControlName="email"
      />
      <div class="error" *ngIf="!registerForm.controls['email'].valid && registerForm.controls['email'].touched">
        <div *ngIf="registerForm.controls['email'].errors.required">
          * {{'AUTH_EMAIL_ADDRESS_IS_REQUIRED' | translate}}
        </div>
        <div *ngIf="registerForm.controls['email'].errors.pattern">* {{'AUTH_INCORRECT_FORMAT' | translate}}</div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-6 col-md-6 mb-4" tabindex="4">
      <input
        autocomplete="false"
        id="password"
        class="form-control form-control-user"
        type="password"
        placeholder="{{'AUTH_PASSWORD' | translate}}"
        formControlName="password"
      />
      <div class="error" *ngIf="!registerForm.controls['password'].valid && registerForm.controls['password'].touched">
        <div *ngIf="registerForm.controls['password'].errors.required">
          * {{'AUTH_PASSWORD_IS_REQUIRED' | translate}}
        </div>
        <div *ngIf="registerForm.controls['password'].errors.minlength">
          * {{'AUTH_PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS' | translate}}
        </div>
      </div>
    </div>
    <div class="col-xs-6 col-md-6 mb-4" tabindex="5">
      <input
        autocomplete="false"
        id="password_confirmation"
        class="form-control form-control-user"
        type="password"
        placeholder="{{'AUTH_VERIFY_PASSWORD'| translate}}"
        formControlName="password_confirmation"
      />
      <div
        class="error"
        *ngIf="!registerForm.controls['password_confirmation'].valid && registerForm.controls['password_confirmation'].touched"
      >
        <div *ngIf="registerForm.controls['password_confirmation'].errors.required">
          * {{'AUTH_PASSWORD_IS_REQUIRED' | translate}}
        </div>
        <div *ngIf="registerForm.controls['password_confirmation'].errors.minlength">
          * {{'AUTH_PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS' | translate}}
        </div>
        <div *ngIf="registerForm.controls['password_confirmation'].errors.not_match">
          * {{'AUTH_PASSWORDS_NOT_MATCH' | translate}}
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-6 col-md-6" tabindex="6">
      <input
        id="company"
        class="form-control form-control-user"
        type="text"
        placeholder="{{'AUTH_SOCIETY' | translate}}"
        formControlName="company"
      />
      <div class="error" *ngIf="!registerForm.controls['company'].valid && registerForm.controls['company'].touched">
        * {{'THIS_FIELD_IS_REQUIRED' | translate}}
      </div>
    </div>
    <div class="col-xs-6 col-md-6 job" tabindex="7">
      <input
        id="title"
        class="form-control form-control-user"
        type="text"
        placeholder="{{'AUTH_JOB'|translate}}"
        formControlName="title"
      />
      <div class="error" *ngIf="!registerForm.controls['title'].valid && registerForm.controls['title'].touched">
        <div *ngIf="registerForm.controls['title'].errors.required">* {{'THIS_FIELD_IS_REQUIRED' | translate}}</div>
        <div *ngIf="registerForm.controls['title'].errors.minlength">
          * {{'AUTH_TITLE_MUST_BE_AT_LEAST_3_CHARACTERS' | translate}}
        </div>
      </div>
    </div>
  </div>

  <div class="tons-checkbox col-12 mt-2" tabindex="8">

    <label for="tons-checkbox" class="d-flex align-items-baseline mt-4">
      <input type="checkbox" id="tons-checkbox" formControlName="agreedWithTONS" />{{"AGREE_TO_RECEIVE_THE_NEWSLETTER" | translate}} </label>
    <div
      class="error"
      *ngIf="!registerForm.controls['agreedWithTONS'].valid && registerForm.controls['agreedWithTONS'].touched"
    >
      * {{'THIS_FIELD_IS_REQUIRED' | translate}}
    </div>
  </div>

  <div class="tons-checkbox col-12 mt-2" tabindex="9">
    <label for="tos-checkbox" class="d-flex align-items-baseline mt-2 mb-4">
      <input type="checkbox" tabindex="11" id="tos-checkbox" formControlName="agreedWithTOS" /><span class="d-block">{{"TERMS_OF_SERVICE_AGREEMENT" | translate}}&nbsp;<a
            [routerLink]="['/auth/login/terms-of-services/', interfaceLang]">{{"TERMS_OF_SERVICE" | translate}}</a></span></label>
    <div
      class="error"
      *ngIf="!registerForm.controls['agreedWithTOS'].valid && registerForm.controls['agreedWithTOS'].touched"
    >
      * {{'THIS_FIELD_IS_REQUIRED' | translate}}
    </div>
  </div>
  <button
    [disabled]="!registerForm.valid || (loading$ | async)"
    class="btn btn-login btn-block text-white btn-user mt-2"
    type="submit"
    tabindex="10"
  >
    {{"AUTH_TRIAL" | translate}}
    <i *ngIf="loading$ | async" class="fa fa-spinner fa-pulse fa-1x" aria-hidden="true"></i>
  </button>
</form>
<!-- END SIGN-UP PAGE -->
