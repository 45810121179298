<div class="voice-modal-container">
  <ngx-smart-modal #voiceModal identifier="voiceModal">
    <div class="container voice-modal py-4 mt-2">
      <h2>{{ 'USE_DIFFERENT_VOICE' | translate }}</h2>
      <div class="voice-list">
        <ul class="d-flex flex-column">
          <li
            tabindex="{{ i + 1 }}"
            (click)="setSelectedVoice(voice, $event)"
            *ngFor="let voice of voices; let i = index"
          >
            <a
              ><i class="fa fa-volume-up voice-sound" aria-hidden="true"></i>
              <span class="lang-country"
                >{{ voice?.language.substring(0, 2).toUpperCase() | translate }} ({{
                  voice?.country.toUpperCase() | translate
                }})</span
              >
              <span>{{ voice?.label }} </span>
            </a>
          </li>
        </ul>
      </div>
      <div class="modal-button">
        <button
          *ngIf="parentNode.nodeName === 'VOICE'"
          (click)="resetVoiceChange(); voiceModal.close()"
          class="btn btn-voice"
        >
          {{ 'RESET_DEFAULT_VOICE' | translate }}
        </button>
        <button (click)="listenPronunciation()" type="submit" class="btn btn-listen">
          <i class="fa fa-volume-up" aria-hidden="true"></i>{{ 'LISTEN' | translate }}
        </button>
        <button (click)="addVoiceToText(); voiceModal.close()" type="submit" class="btn btn-voice">
          {{ 'USE_VOICE' | translate }}
        </button>
      </div>
    </div>
  </ngx-smart-modal>
</div>
