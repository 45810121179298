import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { FullCalendarComponent, CalendarOptions } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';

import { CalendarEvent } from '@purplefront/calendar/data-access';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-calendar-relaxnews',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar-relaxnews.component.html',
  styleUrls: ['./calendar-relaxnews.component.scss']
})
export class CalendarRelaxnewsComponent implements OnInit {
  calendarOptions: CalendarOptions;
  @Input() events: CalendarEvent[];
  @Input() upComingEvents: CalendarEvent[];
  @Input() currentEvents: CalendarEvent[];
  @Input() pastEvents: CalendarEvent[];
  @Input() isLoading: boolean;
  @Input() isLoaded: boolean;
  @Output() openCalendarEventModal = new EventEmitter();
  @Output() changeCalendarView = new EventEmitter();
  @ViewChild(FullCalendarComponent)
  relaxCalendar: FullCalendarComponent;
  contentLang: string;
  feedSlug: string;
  contextType = 'agenda';
  public nbElements = [1, 2, 3, 4, 5, 6, 7, 8];
  public icon = 'fa-bookmark';
  public iconActive = 'fa-bookmark-o';

  constructor(public datePipe: DatePipe) {}

  ngOnInit() {
    this.calendarOptions = {
      dateClick: this.onDayClick.bind(this),
      eventClick: this.onEventClick.bind(this),
      eventMouseLeave: this.onEventMouseLeave.bind(this),
      eventMouseEnter: this.onEventMouseEnter.bind(this),
      datesSet: this.onViewRender.bind(this),
      eventContent: this.onEventRender.bind(this),
      editable: true,
      dayMaxEventRows: false,
      displayEventTime: false,
      contentHeight: 'auto',
      firstDay: 1,
      headerToolbar: {
        right: 'prev,next today',
        center: 'title',
        left: 'dayGridMonth,dayGridWeek'
      },
      views: {
        dayGridMonth: {
          titleFormat: { year: 'numeric', month: 'short' },
          dayHeaderFormat: 'dddd',
          moreLinkContent: 'more',
          dayMaxEventRows: 4
        },
        basicWeek: {}
      },
      events: this.events,
      plugins: [dayGridPlugin]
    };
  }

  onViewRender(event) {
    this.upComingEvents = [];
    const calendarView = event.view;
    this.changeCalendarView.emit(calendarView);
  }

  onEventClick(eventData) {
    const event = this.events.find((_event) => _event.id === (eventData.id || eventData.event.id));
    this.openCalendarEventModal.emit({
      modal: 'event',
      articleId: event.data.id,
      articleLang: event.data.lang,
      articleType: event.data.object_type,
      sourceType: event.data.object_source_type
    });
  }

  onEventRender(eventData) {
    let title = '';
    const start = this.datePipe.transform(eventData.event.start, 'MMM d, y');
    const end = this.datePipe.transform(eventData.event.end, 'MMM d, y');

    end ? (title = `${start} - ${end} ${eventData.event.title}`) : (title = `${start} ${eventData.event.title}`);

    eventData.el.querySelectorAll('.fc-content')[0].setAttribute('data-tooltip', title);
  }

  onDayClick(event) {}

  onEventMouseEnter(event) {}

  onEventMouseLeave(event) {}
}
