<ngx-smart-modal #cguEnModal identifier="cguEnModal" [customClass]="'cgu-modal'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 mt-5">
        <h2>TERMS OF SALES</h2>
        <p>
          ETX Studio (hereinafter referred to as "ETX") markets application features which are accessible online that it
          owns ("<b>Saas Service</b>").
        </p>
        <p>
          ETX has made available a commercial proposal and/or documentation to the Customer which presents the Service
          that the Customer acknowledges to have read. It is up to the Customer, in particular on the basis of this
          information, to ensure the suitability of the Service to its own needs and constraints.
        </p>
        <p>
          These General Conditions of Sale (hereinafter referred to as "<b>Conditions</b>") apply to the Contract and
          set the rights and obligations of the Parties in relation with any signing of a Purchase Order by the
          Customer. The current version of the Conditions is the only enforceable one against Customers for the
          Contract’s duration and until a new version replaces the latter. ETX reserves the right to modify these
          Conditions at any given time, without notifying the Customer but will keep the Customer informed of the recent
          modifications.
        </p>

        <p>
          ETX reserves the right to derogate from certain clauses present herein or establish specific conditions
          according to Customers. Any deviation from the Conditions is entered on the Order Form. The special conditions
          prevail over the general conditions. Acceptance of the Conditions is obligatory before placing an order on the
          Website. The Customer acknowledges and accepts that any signature of the Order Form constitutes unreserved
          acceptance of the following provisions and conditions as well as of the annexes.
        </p>

        <p>
          Hereunder, the Customer may be referred to individually as "the <b>Party</b>" and collectively as "the
          <b>Parties</b>".
        </p>

        <h3>Article 1 - <span class="text-decoration-underline">Definitions</span></h3>

        <p>In this document, words or expressions beginning with a capital letter will have the following meaning:</p>
        <ul>
          <li>
            "<b>Anomaly</b>": designates any defect in the design or production of the Service, independent of improper
            use, preventing proper functioning of the Service.
          </li>
          <li>
            "<b>Order Form</b>" means the document annexed to this contract upon signature, describing the features of
            the Service provided to the Customer.
          </li>
          <li>
            "<b>Customer</b>" means the legal person or the natural person, co-contractor of ETX Studio, intervening
            within the context of its professional, commercial, industrial, craft or liberal activity.
          </li>
          <li>
            "<b>User Account</b>" means access open to a User. A User Account corresponds to a unique User and a unique
            Identifier.
          </li>
          <li>
            "<b>Content</b>" means the press and editorial content created and/or modified by the Client, as well as the
            Content made available to the Client via the Service and written by the press teams of ETX Studio or AFP.
          </li>
          <li>
            "<b>Contract</b>" means the contractual set made up of several parts and several documents, namely the
            "Order form" section, these General Conditions of Sale, as well as the Annexes.
          </li>
          <li>
            "<b>Effective Date</b>" means the date of entry into force of the Contract as indicated on the Order Form.
          </li>
          <li>
            "<b>Documentation</b>" means the information made available by ETX and describing the terms of use of the
            Service, in the form of user documentation accompanying the Service and/or online help and the list of
            prerequisites that are required for the use of services described on the ETX Studio website and mentioned in
            the Order Form.
          </li>
          <li>"<b>Platform</b>" means the website www.etxstudio.com by which the Customer can use the Services.</li>
          <li>
            "<b>SaaS</b>" (Software as a Service) means the mode of remote provision of the features of the Service
            which uses Internet technologies and accessible via the Internet network.
          </li>
          <li>
            “<b>Service</b>” designates the standard application features delivered online by ETX as well as the
            Updates, in the form of subscription to a subscription.
          </li>
          <li>
            "<b>Additional Service(s)</b>" means any service other than hosting and maintenance of the Service, provided
            by ETX Studio at the Customer's request, mentioned in the Order Form and subject to invoicing additional.
          </li>
          <li>
            "<b>User</b>" means any individual authorized by the Customer who may have access to the Service, within the
            limits provided herein, in particular by the General Conditions of Use and the Order Form.
          </li>
        </ul>

        <h3>Article 2 - <span class="text-decoration-underline">Purpose</span></h3>
        <p>The purpose of these conditions is to set the conditions under which:</p>

        <ul>
          <li>- ETX grants the Customer, who accepts it, a right to use the Service.</li>
          <li>- ETX provides the Customer with associated services.</li>
          <li>- The Customer agrees to pay the contractual fee.</li>
          <li>- The obligations and responsibilities for the Parties.</li>
        </ul>

        <h3>Article 3 - <span class="text-decoration-underline">Contractual documents</span></h3>

        <p>
          The Contract is formed between the Parties by the following contractual documents, presented in hierarchical
          order of decreasing legal value:
        </p>
        <ul>
          <li>- The Purchase Form with the Special Conditions.</li>
          <li>- These General Conditions and their annexes which form an indivisible whole with the T&Cs.</li>
          <li>- Any annexes attached, if applicable, to the Order form.</li>
        </ul>

        <p>
          In the case of a contradiction between one or more provisions in the documents which are mentioned above, the
          higher-ranking document shall prevail.
        </p>

        <h3>Article 4 - <span class="text-decoration-underline">Duration</span></h3>

        <p>
          Unless otherwise specified in the Order Form, the Contract comes into force on the date of its signature by
          the Customer. It is rounded off for the period mentioned in the Purchase Form. In the absence of any mention
          in the Purchase Form, it is concluded for a period of twelve (12) months with a trial period of three (3)
          months (“<b>trial period</b>”). During the Trial Period, the Customer has the option of not extending his
          contract beyond the Trial Period. For this, the Client must notify ETX Studio by registered mail no later than
          15 days before the end of the Trial Period.
        </p>

        <p>
          Beyond the initial period indicated on the purchase form, and unless otherwise stated on the Purchase form,
          the Contract will be renewed through tacit agreement for successive periods of twelve (12) months. The Parties
          have the option of not renewing the Contract, by sending a registered letter with acknowledgment of receipt,
          subject to three (3) months’ notice before the expiry date of the Contract.
        </p>

        <p>
          Any modification of the pricing conditions and of the Services provided to the Client must be the subject of a
          new Purchase Order signed between the Parties.
        </p>

        <h3>Article 5 – <span class="text-decoration-underline">Technical prerequisite</span></h3>
        <h4>5.1 Prior assessment</h4>
        <p>
          By signing these presents, the Client acknowledges and declares to have been able to verify, under his sole
          responsibility and before the rounding off of the Contract, to have all the technical prerequisites, as
          mentioned by ETX Studio, to use the Service, its interface and the technologies implemented or required for
          its use. The latter paid particular attention to verifying that the Service and associated technologies are
          perfectly compatible with the IT environment. ETX cannot be held liable in the event that the Client did not
          carry out these preliminary checks, the Client being unable to lodge a complaint or request for reimbursement
          on this ground.
        </p>

        <h4>5.2 Customer Material</h4>

        <p>
          The connection equipment for the Service used by the Customer is under his entire responsibility. He must take
          all the suitable measures to protect his equipment and his own data. The Customer agrees to access the Service
          using recent equipment, which does not contain viruses and with a recently updated browser.
        </p>

        <p>
          ETX is not responsible for any damage caused to Customers, third parties and/or their equipment as a result of
          their connection or use of the Service and Customers waive any action against ETX as a result.
        </p>

        <h3>Article 6 – <span class="text-decoration-underline">Description of services</span></h3>
        <h4>6.1 Access</h4>
        <p>The Service is accessible on the Internet at the address (URL) via the Website or the API.</p>
        <h4>6.2 Provided service</h4>
        <p>
          The description of the Services provided by ETX are indicated on the Purchase Form and described in the
          “Description and Use of the Services”.
        </p>

        <p>
          Any order changes requested by the Customer will only be taken into account after the Parties have signed a
          new specific Order Form and any adjustment to the Price.
        </p>

        <h4>6.3 Additional services</h4>

        <p>
          At the Customer's request and in keeping with the terms defined in the Order Form, ETX will perform additional
          Services on behalf of the Customer. It is the Customer's responsibility to define their needs precisely (by
          providing, if necessary, detailed specifications validated between the Parties before any signing of a Order
          Order).
        </p>

        <h4>6.4 Availability</h4>

        <p>
          Access to the Service is available 7 days a week and 24 hours a day. For the proper management of the
          Services, ETX may at any time reserve the right to:
        </p>
        <ul>
          <li>
            - Suspend, interrupt or limit access to all or part of the Service, for any reason whatsoever, with or
            without notice and at its sole discretion.
          </li>

          <li>
            - Delete any information that may disrupt its operation, for any reason whatsoever with or without notice
            and at its sole discretion.
          </li>

          <li>- Suspend the Service in order to carry out updates.</li>
        </ul>

        <p>
          ETX will do its best to communicate to the Customer the dates and times of its interventions in advance, but
          the latter cannot be held responsible, in the event of non-prior communication.
        </p>
        <h4>6.4 Internet network</h4>
        <p>
          The Customer is informed that the connection to the Service is made through the Internet network. The latter
          is therefore warned of technical contingencies that may affect this network, in particular making connection
          temporarily impossible. ETX cannot be held responsible for any malfunction of the Internet network, telephone
          lines or computer and telephone equipment related in particular to network congestion preventing access to the
          Service.
        </p>
        <h3>Article 7 - <span class="text-decoration-underline">Support and update</span></h3>
        <h4>7.1 Availability</h4>
        <p>
          ETX's technical team provides support by email : customer@etxstudio.com. This assistance is available Monday
          to Friday from 10 a.m. to 6 p.m. excluding public holidays. In the case that the Customer is located abroad,
          these times correspond to the time slot applicable in the territory of Metropolitan France. This assistance
          makes it possible to provide the Customer with answers to malfunctions and anomalies encountered in his use of
          the Service. This assistance does not cover the Customer's needs related to problems with its hardware and any
          third-party software not supplied by ETX.
        </p>
        <h4>7.2 Chatbot</h4>
        <p>
          Via the Service, a Chatbot is available allowing the Customer to benefit from user assistance in his use of
          the Service. This Chatbot is available during the availability times mentioned in section 7.1.
        </p>
        <h4>7.3 Update</h4>
        <p>
          ETX is committed to providing Updates in order to continually improve the quality and/or functionality of the
          Service for its Clients. The carried-out updates must not affect the use of the Service by the Customer or
          degrade the Service. Otherwise, ETX undertakes to maintain, under the conditions provided herein, the previous
          version of the Service until a new Update is implemented, meeting ETX's quality criteria.
        </p>
        <p>
          ETX will take responsibility for correcting any possible Anomalies identified on the Service. Any Anomaly must
          be reported by the Client to ETX as soon as it is discovered, with sufficient indications to allow the ETX
          technical team to resolve the anomaly.
        </p>

        <h4>7.4 Intervention limits</h4>
        <p>ETX will not provide its support and maintenance services for:</p>
        <ul>
          <li>- Use of the Service by the Customer that does not comply with these terms and with the User Guide.</li>
          <li>- Non-compliance by the Client with the T & Cs.</li>
          <li>- Abnormal use of the Service, for whatever reason.</li>
          <li>- A compatibility issue between the Service and the Customer's computer equipment.</li>
          <li>- Any breach by the Customer of its obligations under the Contract.</li>
        </ul>
        <h4>7.5 Back up</h4>
        <p>
          Customer data as well as its content created/modified via the Platform are subject to daily backup via the
          Cloud by ETX. Except in cases of force majeure, ETX will keep the data and content modified /created via the
          Platform available to the Client.
        </p>

        <h3>Article 8 – <span class="text-decoration-underline">Prices and payment terms</span></h3>
        <h4>8.1 Price of services</h4>
        <p>
          The Services are provided at the rates under the conditions described in the Order Form. ETX invoices
          according to the Services subscribed by the Customer mentioned in the Order Form and according to any
          Additional Services ordered by the Customer.
        </p>
        <p>Unless otherwise indicated on the Order Form:</p>
        <ul class="circle-list">
          <li>
            Access to the Service involves only one access account for a single User. In the event of multiple Users, an
            additional fee calculated according to the tariff in force will be due.
          </li>
          <li>
            Each subscription to the Services is only concluded for a single Customer's Website, with a view to
            transforming the content of said site. In the event of several websites owned by the Customer, the latter
            must conclude several separate subscriptions with ETX;
          </li>
          <li>
            All the Sources and distribution channels of the content created, adapted and/or modified by the Customer
            through the Service are mentioned on the Order Form. Any modification of said distribution methods will be
            subject to a modification of the Order Form and a revision of the Price.
          </li>
        </ul>
        <h4>8.2 Invoicing</h4>
        <p>
          In the event that no indication is mentioned on the Order form, invoicing is carried out according to one or
          the other of the following methods:
        </p>
        <ul>
          <li>
            - <span class="text-decoration-underline">Quarterly invoicing</span>: the Customer agrees to pay ETX the
            fees due quarterly, with a deposit invoice one quarter in advance at the time of conclusion of the Order
            form.
          </li>
          <li>
            - <span class="text-decoration-underline">Monthly invoicing</span>: the Customer agrees to pay ETX fees due
            on a monthly basis.
          </li>
        </ul>
        <p>
          The Set-Up costs are payable as soon as the Order Form is rounded off. Invoices are payable by the Customer,
          by bank transfer, within thirty (30) days maximum from the date of issue of the invoice, to the bank details
          provided by ETX. The prices are quoted exclusive of tax and will therefore be increased by the taxes in force
          at the time of invoicing by ETX.
        </p>
        <h4>8.3 Payment delays</h4>
        <p>
          Any late payment of invoices sent by ETX or any payment incident, from the day following their due date, must
          be corrected within fifteen (15) days of the issuance of the invoice. After this period, any delay will
          automatically entail the application of late payment penalties in an amount equal to the interest rate applied
          by the European Central Bank increased by 10 percentage points. They are payable as of right and without prior
          notice.
        </p>

        <p>
          Moreover, pursuant to <span class="font-italic"> article L. 441-10 of the French Commercial Code</span>, a
          lump sum indemnity for recovery costs of 40.00 € will be payable by either Party to the creditor party, and
          this same in the case of partial payment of the unregulated invoice, without prejudice to any other action
          that the creditor party would be entitled to bring against the debtor party and at the latter's expense for
          the purposes of recovering its invoices and all other damages that may be due to him.
        </p>

        <p>
          In the case of non-payment of invoices, ETX reserves the right to suspend access to the Service, or the
          execution of any other Additional Service, until the actual receipt of the sums due. This suspension of the
          Services cannot be considered as a termination hereof.
        </p>
        <h4>8.4 Reviewing prices</h4>
        <p>
          The Prices charged by ETX will be revised each year by ETX from the anniversary date of the Contract according
          to the evolution of the Syntec index. In accordance with article 1167 of the Civil Code, the Parties, in the
          case of the disappearance of the selected index, will use the closest index. The price will then be calculated
          on the replacement index using the necessary correlation coefficient. In the absence of an agreement on a new
          index, the parties confer jurisdiction on the President of the Paris Commercial Legal Court ruling according
          to the procedure on the merits, in order to define a new index which shall be integrated into the revision
          formula.
        </p>
        <h3>Article 9 – <span class="text-decoration-underline">Obligation of Parties</span></h3>

        <h4>9.1 Obligations of ETX</h4>

        <p>
          Hereunder, ETX undertakes to mobilize all human and technical resources in its possession and adapted to its
          material and financial capacities, in order to provide the Services covered by the Contract under the
          conditions in accordance with these.
        </p>

        <p>
          ETX is bound by a general obligation of advice and information with the Customer, during the execution of the
          Contract and undertakes to provide the latter with all the Documentation allowing the correct use by the
          Customer of the Services.
        </p>

        <p>
          ETX undertakes to ensure the confidentiality of personal data communicated by the Customer under the
          conditions provided for in the Personal Data Annex.
        </p>
        <h4>9.2 Obligations of Client</h4>
        <p>The Client commits to:</p>
        <ul>
          <li>
            - Ensure, prior to the conclusion of the Contract, that the Service is suitable for its needs and its IT
            environment, and to have all the technical prerequisites.
          </li>
          <li>
            - Pass on all the information necessary for the performance of the Contract and guarantee the accuracy of
            this information; Any modification of this information must be reported by the Client to ETX within a
            maximum of twenty-four (24) hours;
          </li>
          <li>
            - Pay the fee due as indicated in the Order Form or, in the event of non-indication on the Order Form, under
            the conditions of Article 8.
          </li>
          <li>- Comply with the provisions relating to ETX's intellectual property.</li>
          <li>
            - Use the Service in accordance with the T & Cs provided by ETX and in accordance with the applicable
            documentation.
          </li>
          <li>
            - Not to undermine the proper functioning or security of the Service, by not storing, in particular, data
            that may contain viruses or are unlawful, contrary to accepted standards of morality or which may affect
            respect for ETX's rights or of third parties.
          </li>
          <li>
            - To fulfill its obligations in regard to the security of Personal Data as indicated in the Personal Data
            Annex;
          </li>
          <li>
            - To have qualified staff allowing the use of the Service and to ensure the training of its personnel in the
            use of the Service;
          </li>
          <li>- Comply with the Confidentiality obligations indicated in the Contract.</li>
          <li>
            - Do not use the Service and in particular the editing tool, for the purposes of spreading Fakes News, for
            the purposes of propaganda or the dissemination of hateful, racist, anti-Semitic, xenophobic, sexist,
            homophobic or any other words that may harm the physical and moral integrity of others. The Customer
            disclaims ETX's liability against any dispute brought in this regard by a third party.
          </li>
        </ul>
        <p>
          The Customer is liable for damage caused to the Service and/ or to ETX and/ or to a third party and guarantees
          ETX against any recourse that could be brought against it by a third party as a result of the Customer's
          violation of these provisions.
        </p>

        <p>
          Unless the Client expressly requests it to the contrary, the Customer authorizes ETX to send the latter
          promotional and informational emails or letters, of a technical and/or commercial nature, throughout the
          duration of the Contract and for two (2) years afterwards. its cessation. The Customer may interrupt this
          communication at any given time by expressly making a request to the ETX Customer service.
        </p>

        <p>
          The Customer does his personal business and under his sole responsibility, to acquire all the required
          equipment as well as to subscribe to the internet access subscriptions necessary for the use of the Services.
          The costs of connecting to the Service are the sole responsibility of the Customer.
        </p>

        <p>
          In order to be able to use the Service, the Customer must create a User Account on the Site. To create their
          account, they will be asked to define a connection identifier (email) as well as a password. The Customer is
          solely responsible for the security and confidentiality of his login details and must immediately notify ETX
          in the case of loss or theft of his login details. ETX cannot be held responsible in the event of loss or
          theft of the Customer's identifiers, resulting in fraudulent use of their Account.
        </p>

        <p>
          Only one User Account must be created per Customer. In the case of use of an Account by several Users, without
          having given rise to prior information to ETX and therefore a reviewal of the Price indicated on the Order
          Form, ETX reserves the right to suspend the carrying out of the Services and to close the Account.
        </p>

        <p>
          The Customer guarantees that all the information which was provided while signing the Order form, is accurate,
          up-to-date, and honest. In this regard, he undertakes to update the information if necessary. ETX cannot be
          held liable for any truncated, inaccurate, or missing data that has resulted in a direct or indirect impact on
          the performance of the Services.
        </p>

        <h3>Article 10 – <span class="text-decoration-underline">Responsibilities</span></h3>

        <p>
          ETX undertakes to perform the Services in accordance with the rules of the art and in the best possible way
          but is only bound by an obligation of means vis-à-vis the Customer and not an obligation of result.
        </p>
        <p>
          However, ETX takes no responsibility for the Content which has been created and distributed by the Customer
          through the Service. The Customer is solely responsible for the content he edits and modifies, whether by a
          transformation of text, images or an audio transformation, whether the content/text is already existing on the
          Service, or used via API or Scraping.
        </p>

        <p>
          ETX makes no commitment with the Customer, as to the visibility of its content modified and created via the
          Service, nor on the audience of said content among the Customer’s public.
        </p>

        <p>
          ETX incurs no liability for any loss of profits, operations, Customers or commercial disturbance or damage,
          oss of brand image, loss of data, files, or software that the Customer would suffer, not more than for any
          increase in internal charges incurred by the Customer in using the Service.
        </p>

        <p>
          ETX cannot be held responsible for damages other than those resulting directly and exclusively from a fault in
          the performance of the Service. Consequently, ETX is not responsible for any use of the Service by the
          Customer in a manner inconsistent with these and the T & Cs.
        </p>

        <p>
          The Customer is solely responsible for his legal, fiscal and administrative obligations, including any
          obligation arising from a law or a Regulation relating to the use of the Services. It is therefore the
          Customer's responsibility to ensure compliance with applicable regulations and releases ETX from liability in
          this area.
        </p>

        <p>
          In any event, ETX's liability is limited, for all reasons, to 30% of the sums excluding tax paid by the
          Customer in return for the Service during the last twelve (12) months preceding the event giving rise to the
          damage.
        </p>

        <h3>Article 11 – <span class="text-decoration-underline">Confidentiality</span></h3>

        <h4>11.1 Confidential information</h4>

        <p>
          During the execution of the Contract, the Parties may be required to disclose to the other Party confidential
          information such as commercial, technical, marketing, financial, industrial, salary information and any other
          confidential information relating to its activity (<b>«Confidential information»</b>).
        </p>

        <p>
          « <b>Confidential information</b> » designate the information of which the Parties become aware in the context
          of the negotiation or execution of the Contract, as soon as this information relates to one or more financial,
          legal, industrial, ethical, economic, technical, commercial elements, is declared as such by the other Party
          or because of their confidential nature. Confidential Information includes in particular, without limitation,
          the Data of the Parties, their Documentation, the elements covered by Business Secrecy or by one or more
          Intellectual Property Rights.
        </p>
        <h4>11.2 Confidential Information Usage</h4>

        <p>
          The Parties will decide at their discretion on the nature of the Confidential Information that they may be
          brought to the other Party for the performance of these Terms. The Contract shall not entail any obligation
          for the Parties to make available or grant any right whatsoever on the Confidential Information.
        </p>

        <p>
          The Parties undertake, throughout the duration of the Contract, that the Confidential Information to which
          they have access:
        </p>
        <ul>
          <li>
            ▪ Are protected and kept strictly confidential and are treated with, at a minimum, the same degree of care
            and protection that the Party grants to its own Confidential Information, without this degree being less
            than a reasonable degree of precaution.
          </li>
          <li>
            ▪ Not be disclosed or left disclosed to a third party, for consideration or free of charge, and in any form
            whatsoever, without the prior written consent of the other Party.
          </li>
          <li>
            ▪ Be used during the execution of the Contract only for the purposes of the execution hereof, to the
            exclusion of any other use or exploitation without the prior written authorization of the other Party.
          </li>
          <li>
            ▪ Be neither copied, nor reproduced, nor duplicated in whole or in part, when such copies, reproductions, or
            duplications (i) are not strictly necessary for the realization of the present or (ii) have not been
            specifically authorized and in writing by the other Party.
          </li>
        </ul>

        <p>
          The Parties agree not to invoke, because of the communication of Confidential Information, any right,
          including any intellectual property right, on Confidential Information other than under the conditions and for
          the period of execution provided within the contract.
        </p>
        <p>
          The confidentiality commitments provided for in this article are binding on the Parties during the term of the
          Contract and for a period of 5 (five) years after the end of the Contract for any reason whatsoever. They also
          undertake to ensure that this obligation is respected by all the members of their personnel concerned, of whom
          they are strongly committed.
        </p>
        <p>The confidentiality commitments provided for in this article do not apply to any information that:</p>
        <ul>
          <li>
            ▪ Was in the public domain prior to its disclosure or has since fallen into the public domain through no
            fault of any of the Parties or a third party, and/or.
          </li>
          <li>
            ▪ Has been lawfully received from a third party, without restriction or violation of any confidentiality
            agreement to which the third party belongs.
          </li>
          <li>
            ▪ Are the result of internal developments undertaken in good faith a Party, not having had access to such
            information, the Party holding documents which justify the latter;
          </li>
          <li>
            ▪ Must necessarily be disclosed by virtue of a specific regulation or an administrative or judicial
            injunction.
          </li>
        </ul>
        <p>
          It is specified that the Contract, its annexes, and amendments are also confidential and cannot be
          communicated to third parties without the agreement of the Parties.
        </p>

        <h3>Article 12 – <span class="text-decoration-underline">Intellectual property</span></h3>

        <h4>12.1 Previous knowledge</h4>

        <p>
          The Parties declare that they hold all the intellectual property rights or authorizations necessary for the
          execution of these presents. As such, each Party guarantees the other party against any action for
          infringement, unfair or parasitic competition and more generally against any disturbance affecting the use of
          prior knowledge, emanating from third parties, and as well as the related costs.
        </p>

        <p>
          The Parties remain owners or holders of their prior knowledge and confidential information. The contract may
          not entail any transfer of property between the Parties or be interpreted as a grant or license of any right
          over prior knowledge or confidential information.
        </p>

        <p>
          Each Party may authorize the other Party to make use of its prior knowledge and confidential information which
          would be made available to it for the sole purposes and for the duration of the contract. This right of use is
          non-exclusive and non-transferable and will end at the end of the Contract.
        </p>

        <p>Each Party undertakes not to infringe the prior knowledge of the other party.</p>

        <h4>12.2 Brands</h4>

        <p>
          ETX's word and visual marks (<span class="font-italic">logos</span>) are protected. Their use without the
          written authorization of ETX, in any medium, for the purposes of promoting products or services, or for
          commercial purposes, is forbidden under penalty of criminal and civil proceedings initiated by ETX against the
          Customer.
        </p>

        <h4>12.3 Content</h4>

        <p>
          The Customer declares and guarantees to ETX that he is the holder and has all the necessary rights and/or
          authorizations, regardless of their nature, for the purposes of using, in any way whatsoever, all the elements
          and their content (<span class="font-italic"
            >in particular all texts, images, logos, graphics, photos, video films</span
          >) used, modified and/or created via the Service.
        </p>

        <p>
          The Customer undertakes to ensure that the restrictions on use relating to the Content are respected by end
          users and/or subscribers of its websites and/or, where applicable, of its newsletters, RSS feeds, social
          networks and any other medium indicated on the Order form.
        </p>

        <p>
          The Customer undertakes not to reproduce or communicate on their sites, social networks and publications on
          paper, elements protected by copyright, registered as a trademark, or more generally protected by any other
          intellectual property right, held by third parties without the prior consent of the holder(s) of such property
          rights.
        </p>

        <p>
          The Customer guarantees ETX against any possible claim by a third party who claims to have a property right,
          of any kind, on any of the elements published and/or used by him (<span class="font-italic">
            in particular all texts, images, logos,graphics, photos, video films</span
          >) on the content used, modified and/or created via the Service.
        </p>

        <p>
          The content from ETX and AFP is usable and freely reproducible by the Customer for the media and distribution
          channels indicated on the Order Form.
        </p>

        <p>
          In the case of termination of the Contract, any content created/modified by the Customer, while carrying out
          the Contract; may be maintained on the media and distribution channels indicated on the Order Form.
        </p>

        <h4>12.4 The Services</h4>

        <p>
          ETX guarantees that it has the necessary intellectual property rights on the Services offered to the Customer
          through the Order Form.
        </p>

        <p>
          By express agreement, no intellectual property is transferred on behalf of the Customer by ETX on the Services
          and in particular on the IT and technical solutions integrated by ETX as well as on the source code of the
          Service, IT and technical solutions being the subject of a license filed and protected by Intellectual
          Property Law.
        </p>

        <p>
          The Customer shall refrain from any act or behavior that could directly or indirectly affect the intellectual
          property rights of ETX.
        </p>

        <p>
          ETX grants the Customer, who accepts it, and the Users, a non-exclusive and non-transferable right to use the
          Service for the sole duration of the Contract and for the sole needs of the Customer. This right is exercised
          by remote access, via the Customer's internet network, from connection to the Service.
        </p>

        <p>
          This use is only authorized for the number of Users and for a single Customer's Website, as indicated on the
          order form. In the absence of indication in the Order Form, use is only granted for one (1) User.
        </p>

        <p>
          The distribution channels and content sources of the Content created, modified and / or adapted by the
          Customer via the Service are expressly indicated on the Order Form. Consequently, the right to Use the Service
          is limited exclusively to the Channels and Sources indicated on the Order Form.
        </p>

        <p>
          This right of use is subject to payment of all amounts due under the conditions applicable herein. Any use of
          the Service in a manner not provided for in the Contract is prohibited, unlawful and may give rise to the
          payment by the Customer of damages.
        </p>

        <p>
          The Customer is prohibited from developing software likely to compete directly or indirectly with the Service.
        </p>

        <h3>Article 13 – <span class="text-decoration-underline">Guarantees</span></h3>

        <p>
          ETX guarantees the compliance of the Services. The warranty is excluded in the event that the Customer has not
          complied with the conditions of use specified by ETX or in the Documentation.
        </p>

        <p>
          ETX guarantees that the Services do not constitute a violation of an intellectual property right or any act of
          unfair or parasitic competition. If all or part of the Services provided are recognized to constitute an
          infringement or other violation of the rights of a third party or can no longer be used, ETX may, at its
          option and at its expense, either replace or modify all or part of the Services or obtain a user license for
          the Customer.
        </p>

        <p>This guarantee will only be acquired by the Customer on the condition:</p>
        <ul>
          <li>
            - That the Customer has notified within a week, in writing, the infringement action or the declaration
            preceding this action; and
          </li>
          <li>
            - That ETX has been able to ensure the defense of its own interests and those of the Customer, and to do so,
            that the Customer has collaborated loyally in defense by providing all the elements, information and
            assistance necessary to carry out such a defense.
          </li>
        </ul>
        <p>
          In the event that the prohibition of use is pronounced as a result of an action for infringement or results
          from a transaction signed with the plaintiff of the action for infringement, ETX will endeavor, at its option
          and at its expense, that is:
        </p>
        <ul>
          <li>- Obtain the right for the Customer to continue using it,</li>
          <li>
            - To replace the infringing element with an equivalent element which is not the subject of an infringement
            action, or
          </li>
          <li>- To modify the Service so as to avoid the so -called infringement.</li>
        </ul>
        <p>
          The above provisions set the limits of ETX's liability for infringement arising from the use of the Services.
        </p>

        <h3>Article 14 – <span class="text-decoration-underline">Termination</span></h3>

        <h4>14.1 Procedure</h4>

        <p>
          In the case of a breach by one of the Parties of one of its essential obligations expressly provided for in
          the contract, the other Party may notify the breach. This notification, constituting formal notice, must refer
          to this clause, specify the breach considered and be sent by registered letter with acknowledgment of receipt
          to the defaulting Party and indicate the fifteen (15) day deadline to be observed by the latter to comply with
          its obligations. Such notification will be irrefutably presumed to have been received on the day of the first
          presentation of the aforementioned registered letter at the domicile or registered office of the Party
          concerned indicated herein.
        </p>

        <p>
          In the case of non-performance by the other party and fifteen (15) days after the formal notice remains
          ineffective or unanswered by the obligor of the obligation, the obligee of the unfulfilled obligation will be
          liable. right to terminate the Contract unilaterally, without prejudice to any damages and any other remedy
          available due to the damage suffered.
        </p>

        <p>
          The termination of the Contract will not release any Party from its past obligations that it may still have
          vis-à-vis the other Party, including in particular the payment obligations provided for herein, nor will it
          remove any liability incurred by a Party before said termination.
        </p>

        <h4>14.2 Provisions in the case of termination</h4>
        <p>the case of a Contract Termination:</p>
        <ul>
          <li>
            ▪ The provisions concerning the confidentiality obligation of the Parties will remain in force for the
            duration and under the conditions mentioned therein.
          </li>
          <li>
            ▪ The Parties will proceed in a contradictory manner to a closing of the accounts in order to determine the
            sums possibly due to ETX on the date of the termination of the contractual relations
          </li>
          <li>
            ▪ It is expressly agreed between the Parties that the debtor of an obligation to pay under the terms of this
            agreement, will be validly put in default by the sole eligibility of the obligation, in accordance with the
            provisions of article 1344 of the Civil Code.
          </li>
        </ul>

        <h3>Article 15 – <span class="text-decoration-underline">Force majeure</span></h3>

        <p>
          Neither ETX nor the Client can be held liable if the non-performance or delay in the performance of any of
          their obligations, as described herein, results from a case of force majeure, within the meaning of article
          1218 of the Civil Code.
        </p>
        <p>
          Each Party shall inform the other party, without delay, of the occurrence of such a case when it considers
          that it is likely to compromise the performance of its contractual obligations.
        </p>
        <p>
          In the event of the occurrence of an event of force majeure, as set by law and the case law of French courts,
          making it impossible for one of the Parties to perform its obligations for more than thirty (30) days, and if
          the case of force majeure persists, each of the Parties will have the right to terminate this contract as of
          right, without any compensation being due by either of the parties, by registered letter with acknowledgment
          of receipt sent to the other party.
        </p>
        <p>
          The Parties may in no case be held liable for any non-performance and/or breach of Contract resulting from a
          case of force majeure and no compensation may be claimed by one Party from the other in this respect.
        </p>
        <p>
          However, upon the disappearance of the force majeure which caused the suspension of their respective
          obligations, the parties must make all possible efforts to resume the normal performance of their contractual
          obligations as quickly as possible. The prevented party must notify the other, by registered letter with
          acknowledgment of receipt, of the resumption of his obligation.
        </p>

        <h3>Article 16 – <span class="text-decoration-underline">Declaration of reciprocal independence</span></h3>

        <p>
          The Parties expressly declare that they are and will remain, throughout the duration of this contract,
          independent business, and professional partners.
        </p>

        <h3>Article 17 – <span class="text-decoration-underline">Insurances</span></h3>
        <p>
          The Parties undertake to take out an insurance policy guaranteeing their professional civil liability for all
          activities and obligations arising from the Contract.
        </p>
        <p>
          They undertake to maintain this policy throughout the duration of the Contract and provide proof on request by
          providing the other Party with a certificate from its insurers, listing the guarantees subscribed, their
          amount and their period of validity.
        </p>

        <h3>Article 18 – <span class="text-decoration-underline">Commercial Reference</span></h3>
        <p>
          The Client authorizes ETX to use its name, acronym, and logo on its website and on any other ETX document for
          commercial reference purposes.
        </p>

        <h3>Article 19 – <span class="text-decoration-underline">End of Contract and Data Destruction</span></h3>
        <p>
          In the event of termination of the contractual relationship and for any reason whatsoever, the right of Use
          conferred on the Customer will end. The Customer will have thirty (30) days to collect and destroy all content
          that he has created through the Service, without requesting the intervention of ETX and without being able to
          claim any compensation whatsoever. After this period, the content shall be deleted.
        </p>

        <p>At the end of the contract:</p>
        <ul class="circle-list">
          <li>
            The "Player" tool installed on the Customer's communication channels and broadcasting media will be removed.
          </li>
          <li>The Customer may keep the MP3s created via the Service.</li>
          <li>
            At the request of the Customer who used the Service via Scraping, the content created/modified by the
            Customer and stored at ETX may be transferred to the Customer.
          </li>
          <li>
            The text content created/modified by the Customer via the Services may be maintained on the Customer's media
            and distribution channels indicated on the Order Form.
          </li>
          <li>
            The Customer will no longer be able to consult the press and editorial content to which he had access under
            the execution of the Contract. ETX-AFP content must be removed within 30 days of termination of the
            Agreement.
          </li>
        </ul>

        <h3>Article 20 – <span class="text-decoration-underline">Miscellaneous</span></h3>

        <p>
          <span class="text-decoration-underline">Divisibility</span>: If one or more stipulations hereof are held to be
          invalid or declared null in application of a law or a regulation or following a court decision authorizing res
          judicata, this does not call into question the other clauses of the Contract.
        </p>

        <p>
          <span class="text-decoration-underline">Non-waiver</span>: The fact that the Parties have not invoked a breach
          by the other party of one of the obligations referred to herein shall not be interpreted in the future as a
          waiver of the obligation in question.
        </p>

        <p>
          <span class="text-decoration-underline">Partial invalidity</span>: In the event of a contradiction between a
          stipulation hereof and any legislative text, law, ordinance, regulation, court order or collective agreement,
          present or future, the latter shall prevail, provided that the stipulation hereof thus affected is limited
          only to the extent necessary and that no other mention is affected.
        </p>

        <p>
          <span class="text-decoration-underline">Lack of affectio societatis</span>: The Parties agree that the
          Contract excludes any desire to create a company, an association or any other structure aimed at pooling
          skills or sharing any benefits in any form whatsoever.
        </p>

        <p>
          <span class="text-decoration-underline"> Non-assignment</span>: The contract is concluded intuitu personae in
          consideration of the identity of the Parties. The Parties therefore refrain from assigning the Contract to a
          third party without the prior written consent of the other Party.
        </p>

        <h3>Article 21 – <span class="text-decoration-underline">Applicable law and dispute resolution</span></h3>

        <p>The Contract is subject to the application of French law.</p>

        <p>
          The Parties undertake to seek an amicable solution to any dispute which may arise from the interpretation or
          execution of the contract. As such, the party wishing to initiate the amicable conciliation procedure must
          notify the other party, by registered letter with request for acknowledgment of receipt, of its intention to
          initiate the said procedure, specifying the difficulties of application. encountered or shortcomings observed.
        </p>

        <p>
          This amicable settlement procedure constitutes an obligatory prerequisite for the initiation of legal action
          between the Parties. Any action brought in violation of this clause would be declared inadmissible.
        </p>
        <p>
          If the parties fail to reach an amicable agreement within thirty (30) calendar days of the first notification,
          each of them will recover their full freedom of action.
        </p>
        <p>
          Failing to reach an amicable resolution despite the diligence undertaken, any dispute relating to the
          execution, interpretation, validity, and resolution of the Contract will be subject to the exclusive
          jurisdiction of the Paris Commercial Court, even in case of multiple defendants, warranty appeal or summary
          proceedings.
        </p>

        <p class="font-weight-bold">Annexes:</p>
        <ul>
          <li>- Annex n°1: Description of Services</li>
          <!-- <li>- Annex n°2: User guide</li>-->
          <li>- Annex n°2: Processing of personal data</li>
          <li>- Annex n°3: General conditions of use of the Website</li>
        </ul>

        <h2>ANNEX N°1 – DESCRIPTION AND CONDITION OF USE OF THE SERVICES</h2>
        <h3>Article 1 – <span class="text-decoration-underline">Access to Press and Editorial Content</span></h3>
        <h4>1.1 Content type</h4>
        <p>The Customer has access to a database of content from:</p>
        <ul class="circle-list">
          <li>Editors from ETX Studio.</li>
          <li>
            AFP under a partnership contract between ETX and the French Press Agency allowing the dissemination of this
            content on the Service.
          </li>
          <li>Various external sources of information.</li>
          <li>Royalty-free image banks.</li>
        </ul>

        <p>The Customer can also import their own Content into the Service in order to make the required changes.</p>

        <p>According to the terms listed on the Order Form, the Customer benefits from:</p>
        <ul class="circle-list">
          <li>A subscription to the Content made available on the Service.</li>
          <li>A consultation of the Content.</li>
          <li>
            Consultation and publication of the Content on the Client's Website and / or social networks and on any
            other distribution channel indicated on the Order Form.
          </li>
          <li>Personalized content on demand (rewriting optimized for SEO, pure content writing).</li>
        </ul>
        <p>The options subscribed to by the Customer for this access are listed on the Order Form.</p>

        <h4>1.2 Publication of the Content on the Customer's communication channels and media</h4>
        <p>
          The Customer may publish the Content to which he has access via the Service on the distribution channels
          indicated on the Order Form. He can publish said content in whole or in part to adapt it to his editorial
          charter. This functionality is excluded for content accessible on the Service and for which ETX does not hold
          the rights (any content excluding content written by ETX and AFP.
        </p>
        <p>The use of this feature must meet the following criteria:</p>

        <ul class="circle-list">
          <li>
            The publication of a video or image must be associated with the original article to maintain its editorial
            context and to illustrate a text in its original context. No other use is authorized by ETX Studio, and the
            latter cannot be held responsible for any use of the Content by the Customer outside of its initial context.
          </li>
          <li>
            In the event that a modification of the Content is permitted, the Customer may do so only subject to not
            infringing the rights of ETX and AFP (or of any third party from which ETX has obtained the rights of
            exploitation) and not to distort the meaning of this content or to make it defamatory and / or infringing
            the intellectual property rights of a third party or any other property right, right to respect for private
            life or right to 'picture.
          </li>
          <li>Compliance with all use restrictions accompanying the content and specified in the Contract;</li>
          <li>Publication only of content whose copyright is held by ETX and / or AFP;</li>
          <li>
            The Customer must have all the required authorizations and all intellectual property rights while using
            images imported by him and/or not provided by ETX.
          </li>
        </ul>
        <p>
          The partial or total rebroadcasting of the content free of charge or against payment on any medium and by any
          means regardless of any third party is strictly prohibited, except with the express prior written consent of
          ETX. The Customer agrees to use the Service only as part of its websites, social networks and web,
          technological and IT media.
        </p>

        <p>
          The Customer further agrees not to integrate videos, images or articles on sites or services belonging to the
          following categories:
        </p>
        <ul class="circle-list">
          <li>
            Sites promoting discrimination, hatred or violence on the basis of race, ethnicity, origin, religion,
            disability, sexual orientation, political opinions, etc.
          </li>
          <li>
            Sites infringing the rights of persons and goods, in particular the intellectual property rights of third
            parties.
          </li>
          <li>
            Sites promoting narcotic products, the alcohol industry targeting minors and/or not complying with
            advertising, tobacco, or weapons regulations.
          </li>
          <li>Sites inciting crimes and/or misdemeanors;</li>
          <li>Sites that contravene public order and good morals;</li>
          <li>Pornographic and child pornography websites.</li>
        </ul>
        <h4>1.3 Usage restrictions</h4>
        <p>The Customer further agrees to:</p>
        <ul class="circle-list">
          <li>
            To maintain the photo credit of ETX and that of its partners as well as the copyright, as indicated on the
            Service under each photo.
          </li>
          <li>
            Indicate the mention “ETX Source” or “AFP Source” when publishing texts on the distribution and
            communication media indicated on the Order Form.
          </li>
          <li>
            To indicate, on each page of the Customer's sites or other media indicated on the Order Form where the
            content is published, the source: "@name of the author / owner of the rights”.
          </li>
          <li>To publish content only on the internet when the @webonly mention is indicated.</li>
          <li>
            Ensure that the content shared on the Customer's social networks and indicated on the Order Form refers to
            the Customer's website in accordance with the conditions listed above.
          </li>
          <li>o To respect the restrictions related to the coverage of certain events, mainly cultural events.</li>
        </ul>

        <h3>
          Article 2 – <span class="text-decoration-underline">Studio - Converting a text file to audio/video</span>
        </h3>
        <h4>2.1 Features</h4>
        <p>
          Via the Service, the Customer can have access to a feature enabling their text content to be transformed, as
          well as the content made available by ETX or AFP, into an audio file or video.
        </p>
        <p>The number of transformations that can be carried out by the Customer is indicated on the Order Form.</p>
        <p>Within the studio, the Client must follow the following steps:</p>
        <ul class="circle-list">
          <li>Insert its text, image and sound elements (jingle, interview, etc.).</li>
          <li>Select a main voice.</li>
          <li>Select the expected formats (audio, square video, portrait video, landscape video);</li>
          <li>Click on the button “generate”.</li>
        </ul>
        <p>During the design phase, it is possible:</p>
        <ul class="circle-list">
          <li>Insert several visuals.</li>
          <li>
            Modify any incorrect pronunciation of words (and thus build up a pronunciation lexicon that helps improve
            the overall experience)
          </li>
          <li>
            To give rhythm to the cadence of the audio by adding "pauses" or by alternating the synthetic voices
            according to the paragraphs or the quotes.
          </li>
        </ul>
        <p>
          The formats generated in the studio can be downloaded, previewed, copied and pasted in a third-party tool (via
          embed code) and are saved in the “creations” section, so that they can be re-used later, and/or cloned,
          modified, viewed, downloaded.
        </p>

        <h4>2.1 Publication of Content on Customer's communication channels and media</h4>

        <p>
          The conditions described in article 1.2 of this annex are applicable to any audio/video file converted by the
          Customer via the Solution.
        </p>

        <h4>2.3 Restrictions of Use</h4>

        <p>
          The conditions described in article 1.3 of this annex are applicable to any audio/video file converted by the
          Customer through the Solution.
        </p>

        <h3>Article 3 – <span class="text-decoration-underline">Free access to Services</span></h3>

        <p>
          ETX may provide, at its sole discretion, free and limited access to User Services upon request. The duration
          of the Service use is limited, as part of this trial to 14 days.
        </p>

        <p>
          The provisions applicable to Articles 1 and 2 hereof are also applicable to any use of the Services in the
          case of a free trial.
        </p>

        <p>
          If at the end of the trial period, the User wishes to extend the use of the Services, he will have to sign a
          Purchase Order with ETX. In the event that the User does not wish to extend the use of the Services, he has a
          period of 3 (days) to remove all the content that he has created/modified through the Service. After this
          period, all User data and creations shall be deleted.
        </p>

        <h2>ANNEX N°2 – PERSONAL DATA</h2>

        <p>Access to the Service requires the collection and processing of the Customer's personal data.</p>

        <p>
          ETX's data protection policy has been established in accordance with the recommendations of the National
          Commission for Information Technology and Freedom. The aim is to inform users of the Service and Customers of
          how their personal data is processed and of the commitments and measures we have taken to ensure compliance
          with the personal data of Customers and Users.
        </p>

        <p>
          It was established in accordance with the
          <span class="fst-italic"
            >provisions of the Data Protection Act of January 6, 1978, the European Regulation on Personal Data (RGPD)
            of May 23, 2018</span
          >and the
          <span class="fst-italic">
            Act transposing the European Regulation on the Protection of Personal Data (RGPD) of June 20, 2018.
          </span>
        </p>

        <p>
          The current version is the current version of this policy. ETX reserves the right to modify it at any time in
          order to comply with the legal obligations in force. If necessary, ETX will send a new copy to the Client. Any
          question relating to this policy can be addressed to the following address: dataprivacy@etxstudio.com.
        </p>

        <h3>Article 1 - <span class="text-decoration-underline">Definitions</span></h3>

        <p>The terms below have the meaning assigned to them within the meaning of R.G.P.D. (art.4):</p>
        <ul class="circle-list">
          <li>
            <span class="fw-bold">Consent</span>:
            <span class="fst-italic"
              >« of the data subject, any free, specific, informed and unambiguous expression of will by which the data
              subject accepts, by a declaration or by a clear positive act, that personal data concerning him/her may be
              processed; »</span
            >
          </li>

          <li>
            <span class="fw-bold">Data controller</span>:
            <span class="fst-italic"
              >« the natural or legal person, public authority, service or other body which, alone or jointly with
              others, determines the purposes and means of processing; when the purposes and means of this processing
              are determined by Union law or the law of a Member State, the controller may be appointed or the specific
              criteria applicable to his appointment may be provided for by the law of the 'Union or by the law of a
              Member State; »</span
            >
          </li>

          <li>
            <span class="fw-bold">Subcontractor</span>:
            <span class="fst-italic"
              >« the natural or legal person, public authority, department or other body which processes personal data
              on behalf of the controller; »</span
            >
          </li>

          <li>
            <span class="fw-bold">Processing</span>:
            <span class="fst-italic"
              >« any operation or set of operations carried out or not using automated processes and applied to data or
              sets of personal data, such as collection, recording, organization, structuring, conservation, adaptation
              or modification, extraction, consultation, use, communication by transmission, distribution or any other
              form of making available, reconciliation or interconnection, limitation, erasure or destruction; »</span
            >
          </li>

          <li>
            <span class="fw-bold">Personal data breach</span>:
            <span class="fst-italic"
              >« A breach of security resulting, accidentally or unlawfully, in the destruction, loss, alteration,
              unauthorized disclosure of personal data transmitted, stored or otherwise processed, or unauthorized
              access to such data; ».</span
            >
          </li>
        </ul>

        <h3>Article 2 - <span class="text-decoration-underline">What is personal data? </span></h3>

        <p>
          Personal data (<span class="fw-bold">“Personal Data”</span>) means any information that can directly or
          indirectly identify an individual (last name, first name, e-mail, telephone number, postal address).
        </p>

        <p>
          As such, the GDPR defines personal data as: «
          <span class="fst-italic"
            >any information relating to an identified or identifiable natural person (hereinafter referred to as“ data
            subject ”); is deemed to be an "identifiable natural person" a natural person who can be identified,
            directly or indirectly, in particular by reference to an identifier, such as a name, an identification
            number, location data, an online identifier, or to one or more specific elements specific to its physical,
            physiological, genetic, psychological, economic, cultural or social identity; “.
          </span>
          ».
        </p>

        <h3>Article 3 - <span class="text-decoration-underline">Collection of personal data</span></h3>

        <p class="text-decoration-underline">We collect your consent.</p>

        <p>
          By using our Services, the Customer and the User consent to the collection and processing of the personal data
          they provide. This consent serves as the legal basis for the processing of the data collected.
        </p>

        <p>
          ETX collects and processes the data strictly necessary for the purposes for which they are processed. We make
          a commitment to our Customers not to process data for purposes other than those mentioned below. If we were to
          offer a new service, requiring the collection and processing of data, we undertake to obtain the agreement of
          our Customers once again before offering them the new service in question.
        </p>

        <p>
          Whenever ETX processes personal data, it takes all reasonable measures to ensure the accuracy and relevance of
          the personal data for the purposes for which it processes them.
        </p>

        <h3>Article 4 - <span class="text-decoration-underline">Purpose of processing</span></h3>

        <p>We collect Customer data only for the following processing purposes:</p>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Collection channel</th>
              <th scope="col">Data collected</th>
              <th scope="col">Purpose</th>
              <th scope="col">Legal processing base</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Creation of a Customer account</th>
              <td>Name, first name, e-mail</td>
              <td>Allows to provide Customers with access to the Service</td>
              <td>Execution of a Contract</td>
            </tr>
            <tr>
              <th scope="row">Creation of a Customer account</th>
              <td>Name, first name, e-mail</td>
              <td>
                Allows you to create and administer the User Account and add more if a mention is made on the Order
                Form.
              </td>
              <td>Execution of a Contract</td>
            </tr>
            <tr>
              <th scope="row">Assistance</th>
              <td>Name, first name, e-mail</td>
              <td>
                We collect data for the purpose of providing support to our Service in accordance with Article 7 of the
                Conditions.
              </td>
              <td>User consent Execution of a Contract</td>
            </tr>
            <tr>
              <th scope="row">Navigation on our Website</th>
              <td>Cookies</td>
              <td>
                Our website collects your browsing information for the sole purpose of statistical studies and other
                purposes mentioned in our cookie policy. We invite you to refer to our corresponding cookie policy.
              </td>
              <td>
                The processing is necessary for the purposes of the legitimate interests pursued by the Company, in
                particular to offer users a smooth and improved browsing experience.
              </td>
            </tr>
            <tr>
              <th scope="row">
                Prevent and fight against computer fraud (<span class="fst-italic">spamming, hacking, etc.</span>).
              </th>
              <td>Computer equipment used for navigation, IP address, password.</td>
              <td>Prevent and fight against computer fraud.</td>
              <td>The response to a legal obligation.</td>
            </tr>
          </tbody>
        </table>
        <h3>Article 5 - <span class="text-decoration-underline">Data retention period</span></h3>

        <p>
          We do not exceed the legal data retention periods and your data is kept only for the time necessary to process
          the purposes for which it was collected. In establishing our data processing policy, we established a
          reference grid for the retention period of data, developed using the recommendations of the CNIL.
        </p>

        <p>
          Moreover, ETX may retain certain personal data in order to fulfill its legal or regulatory obligations, to
          allow the exercise of user rights, or for statistical purposes.
        </p>

        <p>At the end of the retention period for personal data, they will be deleted or anonymized.</p>

        <h3>Article 6 - <span class="text-decoration-underline">Access to data</span></h3>

        <p>The data collected on the Site are intended exclusively:</p>
        <ul>
          <li>▪ <span class="fw-bold">Authorized ETX staff </span> in the context of the provision of the Services.</li>

          <li>
            ▪ <span class="fw-bold">To the designated and authorized staff of the host – (AWS) </span> - within the
            limits of their respective powers, for <span class="text-decoration-underline">hosting the Services</span>.
            The latter have limited access to Customer and User data, in the context of the performance of these
            services and have a contractual obligation to use them in accordance with the provisions of the applicable
            data protection regulations. staff.
          </li>
        </ul>
        <p>
          The data may also be transmitted by ETX to third parties and competent authorities to meet legal, judicial,
          fiscal, or regulatory obligations. ETX guarantees the Client that no transfer of personal data will be made to
          an unauthorized third party without the prior, voluntary, informed, express and written consent of the Client.
        </p>
        <p>
          ETX refrains from processing, hosting, or transferring the Information collected on its users to a country
          located outside the European Union or recognized as "<span class="fst-italic">unsuitable</span>" by the
          European Commission without first informing the owner of the data and without having collected his agreement.
        </p>
        <h3>Article 7 - <span class="text-decoration-underline">Data protection</span></h3>

        <p>
          ETX has taken all useful and necessary precautions, with regard to the state of the art in the matter, to
          protect the information in a secure environment in order to avoid any destruction, loss, alteration,
          distribution, or unauthorized access. No matter how hard you try, no method of transmission over the Internet
          or method of electronic storage is completely secure. We cannot therefore guarantee absolute security. If we
          became aware of a security breach, we would notify affected users so that they can take appropriate action.
          Our incident notification procedures take into account our legal obligations, whether at national or European
          level.
        </p>

        <p>
          In the case that the integrity and confidentiality of your data are compromised, the data controller
          undertakes to comply with the procedures put in place under the
          <span class="fw-bold">Data Protection Act of January 6, 1978 </span> and the
          <span class="fw-bold">European Regulation on the Protection of Personal Data. Data ("GDPR")</span>.
        </p>

        <p>ETX does not sell or outsource the data of its Customers and users.</p>

        <h3>Article 8 - <span class="text-decoration-underline">Rights of individuals</span></h3>

        <p>
          With regard to the legal provisions of the
          <span class="fst-italic">Data Protection Act of January 6, 1978 </span> and the
          <span class="fst-italic">European Data Protection Regulation ("GDPR") </span>, Users of our Service have the
          following rights:
        </p>
        <ul>
          <li>
            a. right of <span class="fw-bold">access</span> (article 15 RGPD) and rectification (article 16 RGPD),
            updating, completeness of User data, right to block or erase User personal data (article 17 of the RGPD),
            when they are inaccurate, incomplete, equivocal, out of date, or the collection, use, communication or
            conservation of which is prohibited
          </li>
          <li>b. right to <span class="fw-bold">withdraw </span> consent at any time (article 13-2c RGPD)</li>
          <li>c. right to <span class="fw-bold">limit </span> the processing of User data (article 18 RGPD)</li>
          <li>d. right to <span class="fw-bold">oppose </span> the processing of User data (article 21 GDPR)</li>
          <li>
            e. right to <span class="fw-bold">portability </span> of data that Users have provided, when this data is
            subject to automated processing based on their consent or on a contract (article 20 GDPR)
          </li>
          <li>
            f. right to <span class="fw-bold">define </span> the fate of Users' data after their death and to choose to
            whom ETX must communicate (or not) its data to a third party that it has previously designated.
          </li>
        </ul>
        <p>Our site and ETX cannot oppose users' requests to exercise their rights.</p>
        <p>To exercise any of your rights, you just need:</p>
        <ul>
          <li>- Write an ETX letter to the <span class="fw-bold">head office address;</span> ;</li>
          <li>- Or write an email to the following address: dataprivacy@etxstudio.com.</li>
        </ul>
        <p>By indicating your name, first name, address and attaching proof of identity.</p>
        <p>
          Requests will be processed within one month, unless there is an overriding reason put forward and justified by
          ETX justifying an extension of the deadline.
        </p>

        <h3>Article 9 - <span class="text-decoration-underline">Responsible for data processing</span></h3>
        <ul>
          <li>
            a) <span class="text-decoration-underline">At ETX</span> <br />The person responsible for processing your
            ETX data can be contacted at the following address: dataprivacy@etxstudio.com or by mail to the address of
            the Company by sending your Mail to Mr/Mrs The Data Controller. As responsible for processing the data it
            collects, it undertakes to comply with the framework of the legal provisions in force. It is their
            responsibility in particular to provide users with complete information on the processing of their personal
            data and to maintain a record of processing in accordance with reality.
          </li>
          <li>
            b) <span class="text-decoration-underline">Referral to CNIL </span><br />
            If ETX does not meet the user's request, the user has the right to contact the
            <span class="fst-italic"
              >CNIL (Commission Nationale de l'Informatique et des Libertés, https://www.cnil.fr)</span
            >
            in order to assert their rights.
          </li>
        </ul>
        <h3>Article 10 - <span class="text-decoration-underline">Modification of the privacy policy</span></h3>

        <p>
          ETX reserves the right to modify this Policy at any time. If a modification is made to this Policy, the new
          version will be published immediately on the Website. If the User does not agree with the terms of the new
          wording of the policy, he or she has the option of no longer using the Website Services and no longer browsing
          the Website.
        </p>

        <h2>ANNEX N°3 – CONDITIONS OF USE FOR THE PLATFORM</h2>

        <p>
          Continued navigation on the Platform (hereinafter the "<span class="fw-bold">Platform</span>") implies
          unreserved acceptance by users of the following terms and conditions of use (hereinafter referred to as "<span
            class="fw-bold"
            >C.G.U.</span
          >"). Before any use of the Platform's features, the User acknowledges having read these T & Cs. and have
          accepted them.
        </p>

        <p>
          The currently online version of our GCS. is the only one enforceable for the duration of use of the Platform
          and until we replace it with a new version.
        </p>

        <p>
          The Platform is managed by the <span class="fst-italic">ETX Studio Company</span> which reserves the right to
          modify these conditions at any time, it is therefore the users' responsibility to consult the T & Cs.
          regularly. Any use of our Platform after modification of the T & Cs. constitutes irrevocable acceptance by the
          user of the new version. In case of disagreement with our GCS, the user is invited to no longer use our
          Platform
        </p>

        <h3>Article 1 – <span class="text-decoration-underline">Access to website</span></h3>
        <p>
          Access to the Platform and its use are reserved for strictly personal use, by any User visiting the Platform
          or any Customer who has signed a contract with ETX or who has a free trial. The User agrees not to use the
          Platform and the information or data contained therein for commercial, political, advertising purposes and for
          any form of commercial solicitation and in particular the sending of unsolicited e-mails.
        </p>
        <p>
          ETX cannot be held responsible in the event of access to the Platform by minors nor can it be held responsible
          in the event of erroneous or false information provided by users of the Platform.
        </p>

        <h3>Article 2 – <span class="text-decoration-underline">Description of the services provided</span></h3>

        <p>
          We strive to provide as accurate information as possible, but we are not responsible for any omissions,
          inaccuracies, or deficiencies in updating the information. All the information provided on our Platform is
          given for information only and is not exhaustive, it is subject to modifications which have been made, since
          it was put online.
        </p>
        <p>The User is also informed that the photographs on the Platform are not contractual and do not bind ETX.</p>

        <h3>Article 3 – <span class="text-decoration-underline">Intellectual property</span></h3>

        <h4>3.1 Our Platform</h4>

        <p>
          All brands, photographs, texts, comments, illustrations, images, animated or not, video sequences, sounds, as
          well as all computer applications that could be used to technically operate the Platform and more generally
          all the elements reproduced or used on the Platform are protected by the laws in force in respect of
          intellectual property.
        </p>
        <p>
          They are the full and entire property of ETX or its business partners. Any reproduction, representation, use
          or adaptation, in any form whatsoever, of all or part of these elements, including computer applications,
          without the prior written consent of the publisher, is strictly prohibited. The fact that the publisher does
          not initiate proceedings upon becoming aware of these unauthorized uses does not constitute acceptance of said
          uses and waiver of prosecution.
        </p>
        <p>It is strictly forbidden for the user to:</p>
        <ul class="fst-italic">
          <li>
            - Copy, reproduce, represent, disseminate, distribute, publish, permanently or temporarily, all or part of
            the engineering of the Platform by any means and on any medium known or unknown to date.
          </li>
          <li>- Create derivative works from the content and / or the Platform.</li>
          <li>- Modify, translate, adapt, arrange, all or part of the Platform.</li>
          <li>- Disassemble, decompile, reverse engineer the Platform.</li>
        </ul>
        <p>In any event, the User is solely responsible for the use he makes of the Platform</p>
        <p>
          Any unauthorized use of the site or any of the elements it contains will be considered as constituting an
          infringement and prosecuted in accordance with the provisions of Articles L.335-2 and following of the
          Intellectual Property Code
        </p>
        <p>
          These provisions apply subject to the Conditions applicable to the Use of ETX Services, in particular with
          regard to intellectual property relating to the Content accessible by our Customers and/or created by the
          Customers following the use of the Services.
        </p>

        <h4>3.2 Our brand</h4>
        <p>
          The verbal and visual marks (logos) of ETX are protected. Their use without written authorization from the
          Company or its partners in any medium, for the purposes of promoting products or services, or for commercial
          purposes, is forbidden under penalty of criminal and civil proceedings instituted by our Company against of
          the user and/or responsible third party.
        </p>
        <h4>3.3 Photos</h4>
        <p>
          The photos and logos on the Platform are the exclusive property of ETX and its business partners, with the
          exception of royalty-free images. It is therefore forbidden for any user to reuse these images without the
          express, prior and written permission of their owner. No user or Client may take action(s) incompatible with
          respect for copyright and image rights. ETX and its partners reserve the right to sue any User for any
          violation of these provisions.
        </p>

        <h3>Article 4 – <span class="text-decoration-underline">Platform management</span></h3>
        <p>
          Our Platform is normally accessible to users at all times. For the proper management of the Platform, the
          publisher may at any time reserve the right to:
        </p>
        <ul class="fst-italic">
          <li>
            - Suspend, interrupt or limit access to all or part of the Platform, for any reason whatsoever, with or
            without notice and at its sole discretion.
          </li>
          <li>
            - Delete any information that may disrupt its operation, for any reason whatsoever with or without notice
            and at its sole discretion.
          </li>
          <li>- Suspend the Platform in order to carry out updates.</li>
        </ul>
        <p>
          ETX will do its best to communicate to users beforehand the dates and times of the intervention, but it cannot
          be held liable in the event of non-prior communication.
        </p>
        <h3>Article 5 – <span class="text-decoration-underline">Responsibilities</span></h3>

        <h4>5.1 Access Platform</h4>

        <p>
          ETX undertakes to do everything possible to ensure that the Platform is accessible 24 hours a day, 7 days a
          week. However, our Company does not make any commitment as to the permanent availability and accessibility of
          the Platform and does not guarantee that the Platform is free from errors. The publisher cannot be held liable
          in the event of failure, breakdown, difficulty, or interruption of operation, preventing access to the
          Platform or to one of its functions.
        </p>

        <p>
          ETX and the host cannot be held responsible for any malfunction of the Internet network, telephone lines or
          computer and telephone equipment linked in particular to network congestion preventing access to the server.
        </p>

        <p>The publisher cannot be held responsible in the event of legal proceedings against users:</p>
        <ul class="fst-italic">
          <li>- Due to the use of the Platform or any other service accessible via the Internet.</li>
          <li>- Due to non-compliance by the user with these T & Cs.</li>
        </ul>

        <h4>5.2 Material used</h4>

        <p>
          The connection material to the Platform used by the User is under his sole responsibility. He must take all
          appropriate measures to protect his equipment and his own data. The User agrees to access the Platform using
          recent equipment, which does not contain viruses and with a recently updated browser.
        </p>
        <p>
          ETX is not responsible for any damage caused to Users, third parties and/or their equipment as a result of
          their connection or use to the Platform and Users waive any action against the publisher accordingly.
        </p>
        <p>
          The publisher can also not be held liable for indirect damages (such as for example a loss of market or loss
          of opportunity) resulting from the use of the Platform.
        </p>

        <h3>Article 6 – <span class="text-decoration-underline">Hypertext links</span></h3>

        <p>
          The establishment by users of any hypertext link to all or part of the Platform is authorized, subject to the
          prior written authorization of ETX, requested by email at the following address: contact@etxstudio.com.
        </p>

        <p>
          ETX is free to refuse this authorization without having to justify its decision in any way. In the event that
          ETX grants its authorization, it is in any case only temporary and can be withdrawn at any time, without
          obligation of justification. In all cases, any link must be removed at the brand's request.
        </p>

        <p>
          The Platform contains a number of hypertext links to other sites, set up with the permission of the publisher.
          However, ETX does not have the ability to verify the content of the sites thus visited and therefore assumes
          no liability for this fact.
        </p>

        <h3>Article 7 – <span class="text-decoration-underline">Applicable law</span></h3>

        <p>
          These conditions of use of the Platform are governed by French law and are - in the event of a dispute -
          subject to the exclusive jurisdiction of the competent courts of common law, which is subject to a specific
          attribution of jurisdiction arising from a specific law or regulation.
        </p>
      </div>
    </div>
  </div>
</ngx-smart-modal>
