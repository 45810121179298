import * as actions from '../actions/header.actions';
import { createReducer, on } from '@ngrx/store';

const MOBILE_MAX_WIDTH = 480;
const TABLET_MAX_WIDTH = 1024;

export interface HeaderState {
  screen: {
    mobile: boolean;
    tablet: boolean;
    desktop: boolean;
  };
}

export const initialState: HeaderState = {
  screen: {
    mobile: false,
    tablet: false,
    desktop: false
  }
};

const headerReducer = createReducer(
  initialState,

  on(actions.setScreen, (state, action) => {
    const width = action.payload.windowWidth;
    const isMobile = width <= MOBILE_MAX_WIDTH;
    const isTablet = width <= TABLET_MAX_WIDTH && width > MOBILE_MAX_WIDTH;
    const payload = {
      screen: {
        mobile: isMobile,
        tablet: isTablet,
        desktop: !isMobile && !isTablet
      }
    };
    return {
      ...state,
      ...payload
    };
  })
);

export function reducer(state: HeaderState, action: actions.HeaderActionsUnion) {
  return headerReducer(state, action);
}
