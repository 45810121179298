import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss']
})
export class InfoBannerComponent {
  @Input() message: any;
  @Output() closeInfoBanner = new EventEmitter();

  constructor() {}

  closeBanner(): void {
    this.closeInfoBanner.emit();
  }

  navigateToUrl(url: string) {
    window.location.href = url;
  }
}
